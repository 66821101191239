import React, { useEffect, useState, useLayoutEffect } from 'react'
import { List, Avatar, Skeleton, Button } from 'antd'
import { connect } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import action from 'redux/timeLine/action'
import provider from 'providers/provider'
import url from 'constants/url'
import translator from 'i18next'
import { error, success } from 'utils/notification'
import get from 'lodash/get'
import getAcronym from 'components/Acronym'
import { BASE_TITLE } from 'constants/constant'

const JoinRequest = (props) => {
    const { findEpustaka, setTimeLine, getMemberlist } = props
    const history = useHistory()

    const [memberEpustaka, setMemberEpustaka] = useState([])
    const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(true)

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 460

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    //to submit request join member
    const handleSubmit = async ({ membership_id }) => {
        await provider
            .update(url.webhookURL_epustaka_member_join_verification, {
                membership_id: `${membership_id}`,
                status: 1,
            })
            .then(() => {
                getMember()
                getMemberlist()
                setTimeLine({ refresh: true })
                success({
                    description: `${translator.t(
                        'message.success_join_epustaka'
                    )}`,
                    placement: 'topRight',
                })
            })
            .catch((err) =>
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            )
    }

    //To get all query memberlist with status = 0 -> meminta bergabung
    const getMember = async () => {
        setLoading(true)
        await provider
            .list(
                url.webhookURL_epustaka_member_list +
                    `?limit=${limit}&offset=0&&status=0&epustaka_id=${findEpustaka}`
            )
            .then((res) => {
                setMemberEpustaka(res.data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        getMember()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, findEpustaka])

    const loadMoreClick = () => {
        setLoading(true)
        setLimit(limit + 10)
    }

    return (
        <div>
            <List
                className="membership-list"
                loading={false}
                itemLayout="horizontal"
                loadMore={loadMoreClick}
                dataSource={memberEpustaka}
                renderItem={(item) => {
                    const { name, id } = item
                    const { color, acronym } = getAcronym(name || '', id)
                    return (
                        <List.Item
                            actions={[
                                loading ? (
                                    <Skeleton.Input
                                        style={{
                                            width: isMobile() ? 80 : 100,
                                            borderRadius: 5,
                                        }}
                                        active={false}
                                        size="default"
                                        shape="square"
                                    />
                                ) : (
                                    <Button
                                        type="primary"
                                        className="btn-member-join"
                                        onClick={() => handleSubmit(item)}
                                    >
                                        + {translator.t('action.add')}
                                    </Button>
                                ),
                            ]}
                        >
                            <List.Item.Meta
                                style={{
                                    marginLeft: isMobile() ? '10px' : '',
                                }}
                                avatar={
                                    loading ? (
                                        <Skeleton.Avatar
                                            active={true}
                                            size="large"
                                            shape="circle"
                                        />
                                    ) : (
                                        <Avatar
                                            style={{
                                                backgroundImage: color,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                history.push(
                                                    `/profil/user/${id}`
                                                )
                                            }
                                            size="large"
                                            alt={`${BASE_TITLE} image preview - Platform Perpustakaan Indonesia`}
                                            src={item.avatar_url}
                                        >
                                            {acronym}
                                        </Avatar>
                                    )
                                }
                                title={
                                    loading ? (
                                        <Skeleton.Input
                                            style={{ width: 160 }}
                                            active={true}
                                            size="small"
                                        />
                                    ) : (
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                history.push(
                                                    `/profil/user/${id}`
                                                )
                                            }
                                            className="member-name"
                                        >
                                            {item.name}
                                        </div>
                                    )
                                }
                            />
                        </List.Item>
                    )
                }}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    stateTimeLine: get(state, 'timeLine', {}),
})

const mapDispatchToProps = () => ({
    setTimeLine: action.setTimeLine,
})

export default connect(
    mapDispatchToProps,
    mapStateToProps
)(withRouter(JoinRequest))
