import store from '../store'
import * as ActionType from './contant'

export default {
    setReadingDuration: (value) => {
        const action = { type: ActionType.SET_READING_DURATION, value }
        store.dispatch(action)
    },
    clearReadingDuration: () => {
        const action = { type: ActionType.CLEAR_READING_DURATION }
        store.dispatch(action)
    },
}
