export const DATE_FORMAT = 'DD-MM-YYYY'

export const DATE_WITH_FULLSTRING_FORMAT = 'dddd DD-MM-YYYY'

export const YEAR_DATE_FORMAT = 'YYYY-MM-DD'

export const DATE_WITH_FULL_FORMAT = 'dddd, DD MMMM YYYY'

export const YEAR_FORMAT = 'YYYY'

export const DAY_MONTH_STRING_FULL_FORMAT = 'LLLL'

export const TIME_FORMAT = 'HH:mm'

export const TIME_FULL_FRACTIONAL_FORMAT = 'HH:mm:ss.SSS'

export const TIME_FULL_GMT_FORMAT = 'HH:mm:ss Z'

export const TIME_FULL_ISO_FORMAT = 'HH:mm:ss A Z'

export const YEAR_DATETIME_FULL_OFFSET_FORMAT = 'YYYY-MM-DD HH:mm:ss Z'

export const YEAR_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm'

export const YEAR_DATETIME_FULL_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export const DATE_FULLSTRING_FORMAT = 'DD MMMM YYYY'

export const YEAR_DATETIME_FULLSTRING_FORMAT = 'DD MMMM YYYY HH:mm'

export const YEAR_DATETIME_IDN_FORMAT = 'DD-MM-YYYY HH:mm'

export const TIME_GMT_FORMAT = 'THH:mm:ssZ'
