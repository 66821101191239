/* eslint-disable no-loop-func */
import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
    fromPromise,
    concat,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import state from '../redux'
import { onError } from '@apollo/client/link/error'
import funcRefreshToken from 'helpers/refreshToken'
import { GRAPHQL_BASE_URL } from 'helpers/constants'

const httpLink = createHttpLink({
    uri: GRAPHQL_BASE_URL,
})

let isRefreshingApollo = false
let pendingRequests = []

const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback())
    pendingRequests = []
}

const errorLink = onError(({ operation, forward, networkError }) => {
    if (networkError && networkError?.statusCode === 401) {
        const accessToken = state.getState()?.auth?.access_token
        const refreshToken = state.getState()?.auth?.refresh_token
        if (accessToken && refreshToken) {
            let forward$
            if (!isRefreshingApollo) {
                isRefreshingApollo = true
                forward$ = fromPromise(
                    funcRefreshToken()
                        .then((res) => {
                            const tempAccessToken = res
                            if (tempAccessToken) {
                                resolvePendingRequests()
                            }
                            return tempAccessToken
                        })
                        .catch(() => {
                            pendingRequests = []
                            return
                        })
                        .finally(() => {
                            isRefreshingApollo = false
                        })
                ).filter((value) => {
                    return Boolean(value)
                })
            } else {
                forward$ = fromPromise(
                    new Promise((resolve) => {
                        //@ts-ignore
                        pendingRequests.push(() => resolve())
                    })
                )
            }
            return forward$.flatMap(() => {
                return forward(operation)
            })
        }
    }
})

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const accessToken = state.getState()?.auth?.access_token
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
    }
})

const client = new ApolloClient({
    link: concat(errorLink, authLink.concat(httpLink)),
    cache: new InMemoryCache(),
})

export default client
