import { Typography, Tooltip, Card } from 'antd'
import { FallbackImage } from 'components'
import React, { useState, useLayoutEffect } from 'react'
import translator from 'i18next'
import { useHistory, withRouter } from 'react-router-dom'
import action from 'redux/timeLine/action'
import { connect } from 'react-redux'
import Meta from 'antd/lib/card/Meta'
import { nobook } from '../../../../assets/icons'
import { isUndefined } from 'lodash'
const { Text } = Typography

const BookCardComponent = (props) => {
    const history = useHistory()
    const {
        data,
        setTimeLine,
        dataEpustaka,
        fromEpustakaBookCategory,
        key,
        fromBookDetail,
        onRedirect,
    } = props

    const {
        cover_url,
        book_author,
        author_name,
        id,
        book_title,
        available_qty,
        using_drm,
        epustaka_id,
    } = data

    const isMobile = () => state.width <= 420

    const [state, setState] = useState({
        collapsed: false,
        width: window.innerWidth,
    })

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const fromBookEpustakaComponent = () => {
        // if (dataEpustaka?.epustaka_isprivate) {
        //     if (
        //         !dataEpustaka?.has_join ||
        //         (dataEpustaka?.has_join && dataEpustaka?.member_status !== 1)
        //     )
        //         if (dataEpustaka?.member_status === 3) {
        //             message.warning(translator.t('message.notifExpand'))
        //         } else {
        //             message.warning(translator.t('message.notif'))
        //         }
        //     else {
        //         history.push({
        //             pathname: `/book/${id}/${dataEpustaka?.id || ''}`,
        //             state: {
        //                 epustaka_id: dataEpustaka?.id,
        //                 bookEpustaka: data,
        //                 fromEpustakaBookCategory: {
        //                     fromEpustakaBookCategory,
        //                 },
        //             },
        //         })
        //     }
        // } else {
        //     if (
        //         !dataEpustaka?.has_join ||
        //         (dataEpustaka?.has_join && dataEpustaka?.member_status !== 1)
        //     ) {
        //         message.warning(translator.t('message.notif'))
        //     } else {
        //         history.push({
        // pathname: `/book/${id}/${dataEpustaka?.id || ''}`,
        //             state: {
        //                 epustaka_id: dataEpustaka?.id,
        //                 bookEpustaka: data,
        //                 fromEpustakaBookCategory: {
        //                     fromEpustakaBookCategory,
        //                 },
        //             },
        //         })
        //     }
        // }

        history.push({
            pathname: `/book/${id}/${dataEpustaka?.id || ''}`,
            state: {
                epustaka_id: dataEpustaka?.id,
                bookEpustaka: data,
                fromEpustakaBookCategory: {
                    fromEpustakaBookCategory,
                },
            },
        })
    }

    const handleRedirect = () => {
        setTimeLine({ refresh: true })
        // history.push('/')
        if (fromBookDetail && onRedirect) onRedirect()
        history.push({
            pathname: `/book/${id}/${epustaka_id || ''}`,
            state: {
                epustaka_id: epustaka_id,
            },
        })
    }

    return (
        <div className="books-section-grid" data-value={id} key={key}>
            <Card
                style={{
                    width: 160,
                    background: 'none',
                    minHeight: isMobile() ? '310px' : '300px',
                }}
                cover={
                    <div className="container-book">
                        <div className="book" style={{ cursor: 'pointer' }}>
                            <div className="front">
                                <div
                                    className="cover"
                                    onClick={
                                        fromEpustakaBookCategory
                                            ? fromBookEpustakaComponent
                                            : handleRedirect
                                    }
                                >
                                    <FallbackImage
                                        src={cover_url ? cover_url : nobook}
                                        style={{
                                            cursor: 'pointer',
                                            width: isMobile() ? 153 : 150,
                                            height: isMobile() ? 220 : 210,
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="left-side">
                                <h2
                                    style={{
                                        height: 20,
                                        width: isMobile() ? 220 : 210,
                                        marginLeft: -15,
                                        backgroundImage: `url(${cover_url})`,
                                    }}
                                >
                                    <span>
                                        <Text
                                            style={{
                                                width: 155,
                                                fontSize: 10,
                                                color: 'white',
                                                paddingRight: 10,
                                            }}
                                            ellipsis={true}
                                        >
                                            {book_author || author_name}
                                        </Text>
                                    </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                }
            >
                <Meta
                    title={
                        <Tooltip
                            title={book_title}
                            color="#575757"
                            placement="topLeft"
                        >
                            <span
                                style={{
                                    fontWeight: 600,
                                }}
                                onClick={
                                    fromEpustakaBookCategory
                                        ? fromBookEpustakaComponent
                                        : handleRedirect
                                }
                            >
                                {book_title}
                            </span>
                        </Tooltip>
                    }
                    description={[
                        <Text
                            style={{
                                width: 150,
                                fontSize: 13,
                                color: 'gray',
                            }}
                            ellipsis={true}
                        >
                            <Tooltip
                                title={book_author || author_name}
                                color="#575757"
                                placement="topLeft"
                            >
                                {book_author || author_name}
                            </Tooltip>
                        </Text>,
                        <h4
                            style={{
                                fontSize: 13,
                                display: isUndefined(using_drm)
                                    ? 'none'
                                    : 'block',
                            }}
                        >
                            {using_drm
                                ? available_qty +
                                  ' ' +
                                  translator.t('message.availableCopy')
                                : translator.t('message.unlimitedCopy')}
                        </h4>,
                    ]}
                />
            </Card>
        </div>
    )
}

const mapDispatchToProps = () => ({
    setTimeLine: action.setTimeLine,
})
export default connect(mapDispatchToProps)(withRouter(BookCardComponent))
