import * as ActionType from './contant'
import ls from 'utils/secureLocalStorage'

const STORAGE = 'prevPathname'
const initState = {}

const initializeState = () => {
    const config = ls.get(STORAGE)
    const state = config ? config : initState
    return state
}

const reducer = (state = initializeState(), action) => {
    const { type, value } = action

    switch (type) {
        case ActionType.SET_PREV_PATHNAME:
            ls.set(STORAGE, value)
            return value
        case ActionType.UPDATE_PREV_PATHNAME:
            ls.set(STORAGE, value)
            return value
        case ActionType.CLEAR_PREV_PATHNAME:
            return ''
        default:
            return state
    }
}

export default reducer
