import React, { useEffect, useState } from 'react'
import provider from 'providers/provider'
import url from 'constants/url'
import translator from 'i18next'
import { useHistory } from 'react-router-dom'
import { Card, Col, Empty, Row, Tooltip } from 'antd'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { FallbackImage, SkeletonBook } from 'components'
import Meta from 'antd/lib/card/Meta'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Lazy } from 'swiper/core'
import { BASE_TITLE, COLORS } from 'constants/constant'
import { error } from 'utils/notification'
import Text from 'antd/lib/typography/Text'

SwiperCore.use([Pagination, Lazy])

const Review = () => {
    const history = useHistory()
    const [review, setReview] = useState([])
    const [loading, setLoading] = useState(false)

    const getReviewData = async () => {
        setLoading(true)
        await provider
            .list(url.webhookUrl_review_shelf)
            .then((res) => {
                setReview(res.data)
                setLoading(false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
            })
    }

    useEffect(() => {
        getReviewData()
    }, [])

    return (
        <React.Fragment>
            {loading ? (
                <SkeletonBook />
            ) : isEmpty(review) ? (
                <div>
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span style={{ color: COLORS.primaryColor }}>
                                {translator.t('bookshelf.notifReview')}
                            </span>
                        }
                    />
                </div>
            ) : (
                <>
                    <div className="bs__containerBorrow">
                        <p
                            style={{
                                color: 'rgb(87, 87, 87)',
                                fontWeight: 600,
                            }}
                        >
                            {translator.t('menu.book')}
                        </p>
                        <p
                            onClick={() =>
                                history.push({
                                    pathname: '/more-book-review',
                                })
                            }
                            style={{
                                cursor: 'pointer',
                                color: COLORS.primaryColor,
                            }}
                        >
                            {translator.t('action.fully')}
                        </p>
                    </div>
                    <Row className="bs__conte">
                        <div className="swiper_container">
                            <Swiper
                                slidesPerView={1.2}
                                spaceBetween={10}
                                preloadImages={false}
                                lazy={true}
                                pagination={{
                                    el: '.swiper-pagination',
                                    clickable: true,
                                }}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1.9,
                                    },
                                    360: {
                                        slidesPerView: 2.1,
                                    },
                                    411: {
                                        slidesPerView: 2.4,
                                    },
                                    420: {
                                        slidesPerView: 2.5,
                                    },
                                    540: {
                                        slidesPerView: 3.6,
                                    },
                                    640: {
                                        slidesPerView: 3.8,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                    },
                                    884: {
                                        slidesPerView: 4.1,
                                    },
                                    1024: {
                                        slidesPerView: 4.2,
                                    },
                                    1087: {
                                        slidesPerView: 4.5,
                                    },
                                }}
                                className="swiper_container"
                            >
                                {map(review, (book, key) => {
                                    const {
                                        cover_url,
                                        book_title,
                                        id,
                                        book_author,
                                    } = book
                                    return (
                                        <SwiperSlide key={key}>
                                            <Col key={key}>
                                                <div className="bs__containerComp">
                                                    <Card
                                                        style={{
                                                            width: 180,
                                                            backgroundColor:
                                                                'transparent',
                                                        }}
                                                        className="bs__containerCompCard"
                                                        cover={
                                                            <div className="container-book">
                                                                <div className="book">
                                                                    <div className="front">
                                                                        <div className="cover">
                                                                            <FallbackImage
                                                                                alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                                                                src={
                                                                                    cover_url
                                                                                }
                                                                                onClick={() =>
                                                                                    history.push(
                                                                                        `/book/${id}`
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    width: 135,
                                                                                    height: 185,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    >
                                                        <Meta
                                                            title={
                                                                <Tooltip
                                                                    title={
                                                                        book_title
                                                                    }
                                                                    color="#575757"
                                                                    placement="topLeft"
                                                                >
                                                                    <span
                                                                        style={{
                                                                            marginLeft: 8,
                                                                            fontWeight: 600,
                                                                        }}
                                                                        onClick={() =>
                                                                            history.push(
                                                                                `/book/${id}`
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            book_title
                                                                        }
                                                                    </span>
                                                                </Tooltip>
                                                            }
                                                            description={
                                                                <Text
                                                                    style={{
                                                                        width: 140,
                                                                        fontSize: 13,
                                                                        color: 'gray',
                                                                        marginLeft:
                                                                            '8px',
                                                                    }}
                                                                    ellipsis={
                                                                        true
                                                                    }
                                                                >
                                                                    <Tooltip
                                                                        title={
                                                                            book_author
                                                                        }
                                                                        color="#575757"
                                                                        placement="topLeft"
                                                                    >
                                                                        {
                                                                            book_author
                                                                        }
                                                                    </Tooltip>
                                                                </Text>
                                                            }
                                                        />
                                                    </Card>
                                                </div>
                                            </Col>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>
                    </Row>
                </>
            )}
        </React.Fragment>
    )
}

export default Review
