import { isEmpty, toLower } from 'lodash'

const displayBio = (bio) => {
    let bioString
    switch (bio) {
        case isEmpty(bio):
            bioString = '-'
            break
        case toLower(bio) === 'null':
            bioString = '-'
            break
        default:
            bioString = bio
            break
    }

    return bioString
}

export default displayBio
