import {
    Button,
    Col,
    message,
    Row,
    Space,
    Typography,
    Tooltip as Tooltips,
    Dropdown,
    Radio,
    List,
    Modal,
} from 'antd'
import React, { useEffect, useState, useCallback, useLayoutEffect } from 'react'
import axios from 'axios'
import {
    LoadingOutlined,
    CopyOutlined,
    AppstoreOutlined,
    FontColorsOutlined,
    SortAscendingOutlined,
    SearchOutlined,
    CloseCircleOutlined,
    CaretUpOutlined,
    CaretDownOutlined,
} from '@ant-design/icons'
import debounce from 'lodash/debounce'
// import { Logo } from 'components'
import toBuffer from 'blob-to-buffer'
import { useHotkeys } from 'react-hotkeys-hook'
import { API_STORAGE_URL } from 'helpers/constants'
import animationData from './animationLoading.gif'
// import { useMutation } from '@apollo/client'
import errorData from './error.svg'
import { useDay } from 'utils/useDay'
import translator from 'i18next'
import dayjs from 'dayjs'
import {
    Worker,
    Viewer,
    ProgressBar,
    Tooltip,
    Position,
    LocalizationContext,
    MinimalButton,
} from '@react-pdf-viewer/core'
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import { highlightPlugin } from '@react-pdf-viewer/highlight'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import '@react-pdf-viewer/highlight/lib/styles/index.css'
import '@react-pdf-viewer/bookmark/lib/styles/index.css'
import '@react-pdf-viewer/thumbnail/lib/styles/index.css'
import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { useWasm } from 'utils/LoadWasm/useWasm'
import url from 'constants/url'
import { error } from 'utils/notification'
import provider from 'providers/provider'
import { bookmarkPlugin } from '@react-pdf-viewer/bookmark'
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail'
import action from 'redux/readProgress/action'
import actionBookmark from 'redux/bookmark/action'
import reactPDFLang from './reactPDFLang.json'
import { searchPlugin } from '@react-pdf-viewer/search'
import BookmarkIcon from 'components/BookmarkIcon'
import ic_menu from 'assets/icons/ic_menu.png'
// import mutationDeleteBookmark from '../queries/mutationDeleteBookmark'
import { useSelector } from 'react-redux'
import actionReadingDuration from 'redux/readingDuration/action'
import actionFromReader from 'redux/fromReader/action'
// import mutationReadingDuration from '../queries/mutationReadingDuration'
import actionLastReadingDuration from 'redux/lastReadingDuration/action'
// import mutationBookCitation from '../queries/mutationBookCitation'
import ls from 'utils/secureLocalStorage'
import Encryption from 'utils/encryption'
import * as configModule from 'constants/constant'

const configApp = { ...configModule }

const { Paragraph } = Typography

const BookReadPdf = (props) => {
    const { bookData } = props.location.state
    const { loaded } = useWasm('_wasm/main.wasm')
    // const [saveReadingDuration] = useMutation(mutationReadingDuration)
    // const [createBookCitations] = useMutation(mutationBookCitation)

    const {
        bookId,
        bookTitle,
        borrow_key,
        userId,
        epustakaId,
        url_file,
        isDrm,
        bookDetails,
        lastpage,
        dataBookmark,
        dataReadingDuration,
        readingId,
    } = bookData

    const storageReadingProgress = ls.get(
        Encryption.encryptWithMD5('readProgress')
    )

    let currentPage = 0

    const [loading, setLoading] = useState(false)
    const [errorPage, setErrorPage] = useState(false)
    const [isOpenSearch, setIsOpenSearch] = useState(false)
    const [file, setFile] = useState()
    const [citationLoading, setCitationLoading] = useState(false)
    const [block, setBlock] = useState(false)
    const [totalPage, setTotalPage] = useState(0)
    const { dateFormatter } = useDay()
    const pageNavigationPluginInstance = pageNavigationPlugin()
    const bookmarkPluginInstance = bookmarkPlugin()
    const thumbnailPluginInstance = thumbnailPlugin({
        renderSpinner: () => <div className="square-spinner" />,
        thumbnailWidth: 150,
    })
    const { Thumbnails } = thumbnailPluginInstance
    const { Bookmarks } = bookmarkPluginInstance
    const searchPluginInstance = searchPlugin()
    const { Search } = searchPluginInstance
    const { jumpToPage, CurrentPageLabel } = pageNavigationPluginInstance
    const [sidebarOpened, setSidebarOpened] = useState(false)
    const [thumbnailOpen, setThumbnailOpen] = useState(false)
    const [l10n, setL10n] = React.useState(reactPDFLang)
    const [menuReader, setmenuReader] = useState('toc')
    const [previousBookmark, setPreviousBookmark] = useState([])
    const fromReader = useSelector((state) => state.fromReader)
    const [modalClose, setModalClose] = useState(false)
    const [stateDataBookmark, setStateDataBookmark] = useState([])
    const [dataDuration, setDataDuration] = useState([])

    // const [deleteBookmark] = useMutation(mutationDeleteBookmark)
    const [loadingSave, setLoadingSave] = useState(false)
    const [stateDuration, setStateDuration] = useState({
        init: false,
        lastTime: null,
        lastPage: 0,
    })

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const showConfirm = () => {
        Modal.warning({
            content: translator.t('message.reading_book_confirm_message'),
            title: translator.t('message.reading_book_confirm_title'),
            onOk() {
                actionFromReader.clearFromReader()
                setModalClose(false)
                props.history.goBack()
            },
            okText: translator.t('action.close'),
            visible: modalClose,
        })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    const isMobile = () => state.width <= 641

    const localizationContext = { l10n, setL10n }

    // const getPassword = (hash) => {
    //     const encrypted = crypto
    //         .createHash('sha256')
    //         .update(hash)
    //         .digest('hex')
    //         .substring(7, 23)
    //     // eslint-disable-next-line no-undef
    //     const cipherbuf = Buffer.from(borrow_key, 'base64')

    //     const iv = cipherbuf.slice(0, 16)

    //     const data = cipherbuf.slice(16, cipherbuf.length)

    //     const decipher = crypto.createDecipheriv('aes-128-cbc', encrypted, iv)
    //     const buf1 = decipher.update(data)
    //     const buf2 = decipher?.final()
    //     // eslint-disable-next-line no-undef
    //     const pass = Buffer.concat([buf1, buf2]).toString()

    //     const resultPass = crypto
    //         .createHash('sha256')
    //         .update(pass)
    //         .digest('hex')
    //         .substring(3, 57)
    //     return resultPass
    // }

    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
    }

    const fetchRetry = async (url, payload, retries = 20) => {
        setLoading(true)
        return await axios({
            method: 'post',
            url: url,
            data: payload,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                if (res) return res
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    if (retries > 0) {
                        return fetchRetry(url, options, retries - 1)
                    } else {
                        throw new Error(error)
                    }
                }
            })
    }

    const getFile = async (payload) => {
        await fetchRetry(`${API_STORAGE_URL}/streamobject`, payload, 30)
            .then(async (response) => {
                let responseData = new Uint8Array(
                    await response.data.arrayBuffer()
                )
                const wasmFile = window.readResponse(
                    userId,
                    bookId,
                    epustakaId,
                    borrow_key,
                    responseData
                )
                let decriptedFile = new Blob([wasmFile], { type: 'text/plain' })

                toBuffer(decriptedFile, async function (err, buffer) {
                    if (err) message.error('toBuffer failed' + err)
                    else {
                        const decrypted = Buffer.from(buffer)
                        const file = new Blob([decrypted], {
                            type: 'text/plain',
                        })
                        await new Response(file).arrayBuffer().then((res) => {
                            setLoading(false)
                            setFile(res)
                        })
                    }
                })
            })
            .catch(() => {
                setLoading(false)
                setErrorPage(true)
            })
    }

    const getDataBook = () => {
        const payloadData = {
            bookId: bookId,
            userid: userId,
            epustakaid: epustakaId,
            borrowkey: borrow_key,
            url: url_file,
            timestamp: 1623688214000,
        }

        getFile(payloadData)
    }

    const handleSaveReadingProgress = async () => {
        try {
            const data = {
                book_id: bookId,
                total_page: totalPage,
                last_page: currentPage + 1,
                platform_info: 'web',
            }
            await provider.insert(url.webhookURL_book_reading_progress, data)
            action.clearReadingProgress()
        } catch (err) {
            error({
                description: err.message,
                placement: 'bottomRight',
            })
        }
    }

    const handleSaveBookmark = async () => {
        const data = stateDataBookmark?.map((e) =>
            omit(e, ['id', '__typename'])
        )
        if (!isEmpty(stateDataBookmark)) {
            try {
                await provider.insert(url.webhookURL_sync_bookmark, { data })
                actionBookmark.clearBookmark()
                return
            } catch (err) {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            }
        } else {
            // TODO change delete method
            return previousBookmark
            // if (!isEmpty(previousBookmark)) {
            //     try {
            //         await deleteBookmark({
            //             variables: {
            //                 where: {
            //                     id: {
            //                         _in: previousBookmark?.map(
            //                             (item) => item?.id
            //                         ),
            //                     },
            //                 },
            //             },
            //         })
            //         actionBookmark.clearBookmark()
            //     } catch (err) {
            //         error({
            //             description: err.message,
            //             placement: 'bottomRight',
            //         })
            //     }
            // } else {
            //     return
            // }
        }
    }

    const handleSaveReadingDuration = async () => {
        const targetPage = currentPage + 1
        const currentTime = dayjs()

        if (stateDuration.init) {
            const currentItem =
                dataDuration?.find((e) => e?.page === targetPage) || {}
            const indexCurrent = dataDuration?.findIndex(
                (e) => e?.page === targetPage
            )
            const duration = currentTime.diff(stateDuration?.lastTime, 's')
            const tempData = dataDuration

            if (currentItem['duration']) {
                currentItem['duration'] = currentItem['duration'] + duration
                currentItem['page'] = targetPage
                currentItem['user_id'] = userId
                currentItem['book_id'] = bookId
            } else {
                if (duration >= 5) {
                    currentItem['duration'] = duration
                    currentItem['page'] = targetPage
                    currentItem['user_id'] = userId
                    currentItem['book_id'] = bookId
                }
            }

            if (indexCurrent !== -1) {
                tempData.splice(indexCurrent, 1)
            }

            try {
                if (!isEmpty(dataDuration)) {
                    const page_duration = !isEmpty(currentItem)
                        ? [...tempData, { ...currentItem }]
                        : dataDuration
                    await provider.insert(
                        url.webhookURL_book_reading_durations,
                        {
                            book_id: bookId,
                            page_duration: page_duration?.map((e) => ({
                                ...e,
                                book_id: undefined,
                                user_id: undefined,
                            })),
                        }
                    )
                    // await saveReadingDuration({
                    //     variables: {
                    //         whereDelete: {
                    //             book_id: { _eq: bookId },
                    //             user_id: { _eq: userId },
                    //         },
                    //         input: !isEmpty(currentItem)
                    //             ? [...tempData, { ...currentItem }]
                    //             : dataDuration,
                    //     },
                    // })
                }
                actionReadingDuration.clearReadingDuration()
                actionLastReadingDuration.clearLastReadingDuration()
            } catch (err) {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            }
        }
    }

    const handleBack = async () => {
        setLoadingSave(true)
        try {
            await handleSaveReadingProgress()
            await handleSaveBookmark()
            await handleSaveReadingDuration()
            actionFromReader.setFromReader(false)
            setLoadingSave(false)
            props.history.goBack()
        } catch (err) {
            setLoadingSave(false)
        }
    }

    const handleBlock = debounce(() => setBlock(false), 2000)

    useEffect(() => {
        const clearClipboard = (e) => {
            e?.clipboardData?.setData('text/plain', '')
            e?.clipboardData?.setData('text/html', '')
            e?.preventDefault()
        }
        document.addEventListener('copy', clearClipboard)
        return () => {
            document.removeEventListener('copy', clearClipboard)
        }
    }, [])

    useEffect(() => {
        const clearClipboard = (e) => {
            const keyCode = e?.keyCode ? e?.keyCode : e?.which
            if (keyCode == 44) {
                setBlock(true)
                handleBlock()
                navigator?.clipboard?.writeText('')
            }
        }
        document.addEventListener('keyup', clearClipboard)
        return () => {
            document.removeEventListener('keyup', clearClipboard)
        }
    }, [])

    useHotkeys('*', (e) => {
        if (e?.key === 'Meta') {
            setBlock(true)
            handleBlock()
        }
    })

    const ctrlShiftKey = (e, keyCode) => {
        return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0)
    }

    const onKeydown = (e) => {
        const key = e.keyCode || e.key
        const isDisable =
            key === 123 ||
            ctrlShiftKey(e, 'I') ||
            ctrlShiftKey(e, 'J') ||
            ctrlShiftKey(e, 'C') ||
            (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))

        if (isDisable) {
            e.preventDefault()
            return false
        }
    }

    const toBlur = () => {
        document.body.style.cssText =
            '-webkit-filter: blur(5px);-moz-filter: blur(5px);-ms-filter: blur(5px);-o-filter: blur(5px);filter: blur(5px);'
    }

    const toClear = () => {
        document.body.style.cssText =
            '-webkit-filter: blur(0px);-moz-filter: blur(0px);-ms-filter: blur(0px);-o-filter: blur(0px);filter: blur(0px);'
    }

    useEffect(() => {
        window.addEventListener('keydown', onKeydown, false)
        document.addEventListener('blur', toBlur, false)
        document.addEventListener('mouseleave', toBlur, false)
        document.addEventListener('click', toClear, false)
        document.addEventListener('mouseenter', toClear, false)

        return () => {
            window.removeEventListener('keydown', onKeydown, false)
            document.removeEventListener('blur', toBlur, false)
            document.removeEventListener('mouseleave', toBlur, false)
            document.removeEventListener('click', toClear, false)
            document.removeEventListener('mouseenter', toClear, false)
            document.body.removeAttribute('style')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        document.addEventListener('contextmenu', (e) => e.preventDefault())
    }, [])

    useEffect(() => {
        if (!isEmpty(storageReadingProgress)) {
            if (
                JSON.parse(storageReadingProgress)?.book_id !== bookId ||
                JSON.parse(storageReadingProgress)?.reading_id !== readingId
            ) {
                if (!modalClose) {
                    setModalClose(true)
                    showConfirm()
                }
            } else {
                if (!fromReader) {
                    setStateDataBookmark(dataBookmark)
                    setPreviousBookmark(dataBookmark)
                    if (!isEmpty(dataReadingDuration)) {
                        setDataDuration(
                            dataReadingDuration?.map((e) =>
                                omit(e, ['__typename'])
                            )
                        )
                    }
                    actionFromReader.setFromReader(true)
                }
                action.setReadingProgress({
                    last_page: currentPage + 1,
                    book_id: bookId,
                    total_page: totalPage,
                    platform_info: 'web',
                    reading_id: readingId,
                    book_title: bookTitle,
                })
                getReadDuration(currentPage)
            }
        } else {
            if (!fromReader) {
                setStateDataBookmark(dataBookmark)
                setPreviousBookmark(dataBookmark)
                if (!isEmpty(dataReadingDuration)) {
                    setDataDuration(
                        dataReadingDuration?.map((e) => omit(e, ['__typename']))
                    )
                }
                actionFromReader.setFromReader(true)
            }
            action.setReadingProgress({
                last_page: currentPage + 1,
                book_id: bookId,
                total_page: totalPage,
                platform_info: 'web',
                reading_id: readingId,
                book_title: bookTitle,
            })
            getReadDuration(currentPage)
        }
    }, [currentPage])

    const getReadDuration = (currentPage) => {
        const targetPage = currentPage + 1
        const currentTime = dayjs()

        setStateDuration({
            init: true,
            lastTime: currentTime,
            lastPage: targetPage,
        })

        actionLastReadingDuration.setLastReadingDuration({
            init: true,
            lastTime: currentTime,
            lastPage: targetPage,
        })

        if (stateDuration.init) {
            const currentItem =
                dataDuration?.find((e) => e?.page === stateDuration.lastPage) ||
                {}
            const indexCurrent = dataDuration?.findIndex(
                (e) => e?.page === stateDuration.lastPage
            )
            const duration = currentTime.diff(stateDuration?.lastTime, 's')
            const tempData = dataDuration

            if (currentItem['duration']) {
                currentItem['duration'] = currentItem['duration'] + duration
                currentItem['page'] = stateDuration.lastPage
                currentItem['user_id'] = userId
                currentItem['book_id'] = bookId
            } else {
                if (duration >= 5) {
                    currentItem['duration'] = duration
                    currentItem['page'] = stateDuration.lastPage
                    currentItem['user_id'] = userId
                    currentItem['book_id'] = bookId
                }
            }

            if (indexCurrent !== -1) {
                tempData.splice(indexCurrent, 1)
            }

            if (!isEmpty(currentItem)) {
                setDataDuration([...tempData, { ...currentItem }])
                actionReadingDuration.setReadingDuration([
                    ...tempData,
                    { ...currentItem },
                ])
            }
        }
    }

    useEffect(() => {
        isDrm && loaded && getDataBook()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookData, loaded])

    const handleRefresh = () => {
        setErrorPage(false)
        setLoading(true)
        isDrm && loaded && getDataBook()
    }

    const handleAskPassword = (e) => {
        const password =
            loaded &&
            window.pdfPasswordCentralized(
                userId,
                bookId,
                epustakaId,
                borrow_key
            )

        e.verifyPassword(password)
    }

    const tabMenuReader = () => {
        switch (menuReader) {
            case 'toc':
                return (
                    <div
                        style={{
                            padding: 0,
                            overflow: 'hidden',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <LocalizationContext.Provider
                            value={localizationContext}
                        >
                            <Bookmarks />
                        </LocalizationContext.Provider>
                    </div>
                )

            case 'bookmark':
                return (
                    <div style={{ padding: '5px', overflowY: 'scroll' }}>
                        {isEmpty(stateDataBookmark) ? (
                            <p style={{ textAlign: 'center' }}>
                                {translator.t('message.no_bookmark')}
                            </p>
                        ) : (
                            <List
                                itemLayout="horizontal"
                                dataSource={stateDataBookmark}
                                renderItem={(item, index) => (
                                    <div>
                                        <List.Item
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                cursor: 'pointer',
                                            }}
                                            key={index}
                                            onClick={() =>
                                                jumpToPage(item?.page - 1)
                                            }
                                        >
                                            <Paragraph
                                                style={{
                                                    margin: 0,
                                                    textAlign: 'justify',
                                                }}
                                                ellipsis={{ rows: 2 }}
                                            >{`Page ${item?.page} | ${item?.title}`}</Paragraph>
                                        </List.Item>
                                        <hr
                                            style={{ height: 2, width: '100%' }}
                                        />
                                    </div>
                                )}
                            />
                        )}
                    </div>
                )

            // case 'highlight':
            //   return (
            //     <div style={{ padding: '5px', overflowY: 'scroll' }}>
            //       <p style={{ textAlign: 'center' }}>There is no Memo</p>
            //     </div>
            //   )

            default:
                return (
                    <div
                        style={{
                            padding: 0,
                            overflow: 'hidden',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <LocalizationContext.Provider
                            value={localizationContext}
                        >
                            <Bookmarks />
                        </LocalizationContext.Provider>
                    </div>
                )
        }
    }
    const menuBookReader = () => {
        return (
            <div className="menu-book-reader-container">
                <div className="menu-book-reader-header">
                    <Radio.Group
                        buttonStyle="solid"
                        onChange={(e) => setmenuReader(e?.target?.value)}
                        value={menuReader}
                        style={{ marginBottom: 8 }}
                    >
                        <Radio.Button
                            style={{
                                width: '140px',
                                borderRadius: '9px 0px 0px 9px',
                                border: `2px solid ${configApp?.COLORS?.primaryColor}`,
                            }}
                            value="toc"
                        >
                            TOC
                        </Radio.Button>
                        <Radio.Button
                            style={{
                                width: '140px',
                                borderRadius: '0px 9px 9px 0',
                                border: `2px solid ${configApp?.COLORS?.primaryColor}`,
                            }}
                            value="bookmark"
                        >
                            Bookmark
                        </Radio.Button>
                    </Radio.Group>
                </div>
                <div
                    style={{
                        display: 'flex',
                        maxHeight: '400px',
                        flexDirection: 'column',
                    }}
                >
                    {tabMenuReader()}
                </div>
            </div>
        )
    }

    const handleBookmark = () => {
        const targetPage = currentPage + 1
        if (
            !isEmpty(
                stateDataBookmark?.filter((item) => item?.page === targetPage)
            )
        ) {
            const tempDataBookmark = [...stateDataBookmark]
            const getIndex = tempDataBookmark?.findIndex(
                (item) => item?.page === targetPage
            )
            if (getIndex > -1) {
                tempDataBookmark.splice(getIndex, 1)
                setStateDataBookmark(tempDataBookmark)
                actionBookmark.setBookmark(tempDataBookmark)
            }
        } else {
            setStateDataBookmark(
                stateDataBookmark.concat({
                    book_id: bookId,
                    bookmark_label: 'bookmark_label',
                    description: `Page ${targetPage} | ${bookTitle}`,
                    page: targetPage,
                    title: bookTitle,
                    user_id: userId,
                })
            )
            actionBookmark.setBookmark(
                stateDataBookmark.concat({
                    book_id: bookId,
                    bookmark_label: 'bookmark_label',
                    description: `Page ${targetPage} | ${bookTitle}`,
                    page: targetPage,
                    title: bookTitle,
                    user_id: userId,
                })
            )
        }
    }

    const renderToolbar = (Toolbar) => {
        return (
            <Toolbar>
                {(slots) => {
                    const { ZoomIn, ZoomOut } = slots
                    return (
                        <div
                            className="toolbar-pdf-reader"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingLeft: 20,
                                paddingRight: 20,
                                width: '100%',
                            }}
                        >
                            {isMobile() ? (
                                <div
                                    className="toolbar-pdf-reader-column2"
                                    style={{ width: '80%' }}
                                >
                                    <Tooltips
                                        placement="bottom"
                                        title={bookTitle}
                                    >
                                        <span
                                            style={{
                                                fontSize: 16,
                                                fontWeight: 500,
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            {bookTitle}
                                        </span>
                                    </Tooltips>
                                </div>
                            ) : (
                                <>
                                    <div className="toolbar-pdf-reader-column1">
                                        <div>
                                            <CurrentPageLabel>
                                                {(props) => {
                                                    localStorage.setItem(
                                                        'current',
                                                        props.currentPage
                                                    )
                                                    return <></>
                                                }}
                                            </CurrentPageLabel>
                                            <input id="test" hidden />
                                        </div>
                                        <Button
                                            loading={loadingSave}
                                            className="toolbar-button-back"
                                            onClick={handleBack}
                                        >
                                            {translator.t('action.back')}
                                        </Button>
                                        <Dropdown
                                            overlay={menuBookReader}
                                            trigger={['click']}
                                            placement="bottom"
                                        >
                                            <Tooltips
                                                placement="bottom"
                                                title="Menu"
                                            >
                                                <div className="toolbar-icon-menu">
                                                    <img
                                                        src={ic_menu}
                                                        style={{
                                                            height: 15,
                                                            width: 30,
                                                        }}
                                                    />
                                                </div>
                                            </Tooltips>
                                        </Dropdown>
                                        <div className="toolbar-action-menu">
                                            <ZoomIn />
                                        </div>
                                        <div className="toolbar-action-menu">
                                            <ZoomOut />
                                        </div>
                                    </div>
                                    <div
                                        className="toolbar-pdf-reader-column2"
                                        style={{ width: '20%' }}
                                    >
                                        <Tooltips
                                            placement="bottom"
                                            title={bookTitle}
                                        >
                                            <span
                                                style={{
                                                    fontSize: 16,
                                                    fontWeight: 500,
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                {bookTitle}
                                            </span>
                                        </Tooltips>
                                    </div>
                                    <div className="toolbar-pdf-reader-column3">
                                        <div
                                            onClick={() => {
                                                if (sidebarOpened) {
                                                    setSidebarOpened(
                                                        !sidebarOpened
                                                    )
                                                    setThumbnailOpen(
                                                        !thumbnailOpen
                                                    )
                                                } else {
                                                    setThumbnailOpen(
                                                        !thumbnailOpen
                                                    )
                                                }
                                            }}
                                            className="toolbar-icon-menu"
                                        >
                                            <Tooltips
                                                placement="bottom"
                                                title="Thumbnails"
                                            >
                                                <AppstoreOutlined
                                                    style={{ color: '#616161' }}
                                                />
                                            </Tooltips>
                                        </div>
                                        <div className="toolbar-icon-menu">
                                            <Tooltips
                                                placement="bottom"
                                                title="Bookmarked"
                                            >
                                                <BookmarkIcon
                                                    onClick={handleBookmark}
                                                    fill={
                                                        isEmpty(
                                                            stateDataBookmark?.filter(
                                                                (item) =>
                                                                    item?.page -
                                                                        1 ===
                                                                    currentPage
                                                            )
                                                        )
                                                            ? 'false'
                                                            : 'true'
                                                    }
                                                />
                                            </Tooltips>
                                        </div>
                                        <Dropdown
                                            overlay={renderSearch}
                                            trigger={['click']}
                                            placement="bottom"
                                            onVisibleChange={(e) =>
                                                setIsOpenSearch(e)
                                            }
                                        >
                                            <Tooltips
                                                placement="bottom"
                                                title="Search"
                                            >
                                                <div className="toolbar-icon-menu">
                                                    <SearchOutlined
                                                        style={{
                                                            color: '#616161',
                                                        }}
                                                    />
                                                </div>
                                            </Tooltips>
                                        </Dropdown>
                                    </div>
                                </>
                            )}
                        </div>
                    )
                }}
            </Toolbar>
        )
    }
    const renderSearch = useCallback(() => {
        return (
            <div className="menu-book-reader-search">
                <Search>
                    {(renderSearchProps) => {
                        const [readyToSearch, setReadyToSearch] =
                            useState(false)
                        return (
                            <>
                                <div className="container-search">
                                    <input
                                        style={{
                                            background: '#FFF',
                                            border: 'none',
                                            padding: '4px 15px',
                                            width: '85vw',
                                        }}
                                        placeholder="Enter to search"
                                        type="text"
                                        value={renderSearchProps.keyword}
                                        onChange={(e) => {
                                            setReadyToSearch(false)
                                            renderSearchProps.setKeyword(
                                                e.target.value
                                            )
                                        }}
                                        onKeyDown={(e) => {
                                            if (
                                                e.keyCode === 13 &&
                                                renderSearchProps.keyword
                                            ) {
                                                setReadyToSearch(true)
                                                renderSearchProps.search()
                                            }
                                        }}
                                    />
                                    <Tooltip
                                        position={Position.BottomCenter}
                                        target={
                                            <button
                                                style={{
                                                    background: '#EEEEEE',
                                                    border: 'none',
                                                    borderBottom: `2px solid ${
                                                        renderSearchProps.matchCase
                                                            ? 'blue'
                                                            : 'transparent'
                                                    }`,
                                                    height: '100%',
                                                    padding: '0 2px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                    renderSearchProps.changeMatchCase(
                                                        !renderSearchProps.matchCase
                                                    )
                                                }
                                            >
                                                <FontColorsOutlined />
                                            </button>
                                        }
                                        content={() => 'Match case'}
                                        offset={{ left: 0, top: 8 }}
                                    />
                                    <Tooltip
                                        position={Position.BottomCenter}
                                        target={
                                            <button
                                                style={{
                                                    background: '#EEEEEE',
                                                    border: 'none',
                                                    borderBottom: `2px solid ${
                                                        renderSearchProps.wholeWords
                                                            ? 'blue'
                                                            : 'transparent'
                                                    }`,
                                                    height: '100%',
                                                    padding: '0 2px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                    renderSearchProps.changeWholeWords(
                                                        !renderSearchProps.wholeWords
                                                    )
                                                }
                                            >
                                                <SortAscendingOutlined />
                                            </button>
                                        }
                                        content={() => 'Match whole word'}
                                        offset={{ left: 0, top: 8 }}
                                    />
                                </div>
                                {readyToSearch && renderSearchProps.keyword && (
                                    <>
                                        <div style={{ padding: '0 2px' }}>
                                            <Tooltip
                                                position={Position.BottomCenter}
                                                target={
                                                    <MinimalButton
                                                        onClick={
                                                            renderSearchProps.jumpToPreviousMatch
                                                        }
                                                    >
                                                        <CaretUpOutlined />
                                                    </MinimalButton>
                                                }
                                                content={() => 'Previous match'}
                                                offset={{ left: 0, top: 8 }}
                                            />
                                        </div>
                                        <div style={{ padding: '0 2px' }}>
                                            <Tooltip
                                                position={Position.BottomCenter}
                                                target={
                                                    <MinimalButton
                                                        onClick={
                                                            renderSearchProps.jumpToNextMatch
                                                        }
                                                    >
                                                        <CaretDownOutlined />
                                                    </MinimalButton>
                                                }
                                                content={() => 'Next match'}
                                                offset={{ left: 0, top: 8 }}
                                            />
                                        </div>
                                    </>
                                )}
                                {readyToSearch &&
                                    renderSearchProps.keyword &&
                                    renderSearchProps.numberOfMatches === 0 && (
                                        <div style={{ padding: '0 2px' }}>
                                            Not found
                                        </div>
                                    )}
                                {readyToSearch &&
                                    renderSearchProps.keyword &&
                                    renderSearchProps.numberOfMatches > 0 && (
                                        <div style={{ padding: '0 2px' }}>
                                            {renderSearchProps.currentMatch} of{' '}
                                            {renderSearchProps.numberOfMatches}
                                        </div>
                                    )}
                                {readyToSearch && renderSearchProps.keyword && (
                                    <div
                                        style={{
                                            padding: '0 2px',
                                            marginLeft: 4,
                                            cursor: 'pointer',
                                            fontSize: 16,
                                        }}
                                        onClick={renderSearchProps.clearKeyword}
                                    >
                                        <Tooltips
                                            placement="bottom"
                                            title="Clear"
                                        >
                                            <CloseCircleOutlined
                                                style={{ color: '#FF0000' }}
                                            />
                                        </Tooltips>
                                    </div>
                                )}
                            </>
                        )
                    }}
                </Search>
            </div>
        )
    }, [file, sidebarOpened, thumbnailOpen])

    const renderThumbnailItem = (props) => (
        <div
            key={props.pageIndex}
            style={{
                backgroundColor:
                    props.pageIndex === props.currentPage
                        ? 'rgba(0, 0, 0, 0.3)'
                        : '#fff',
                cursor: 'pointer',
                padding: '0.5rem',
                width: '100%',
            }}
        >
            <div
                style={{ marginBottom: '0.5rem' }}
                onClick={props.onJumpToPage}
            >
                {props.renderPageThumbnail}
            </div>
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '0 auto',
                    width: '100px',
                }}
            >
                <div style={{ marginRight: 'auto' }}>
                    Page {props.pageIndex + 1}
                </div>
            </div>
        </div>
    )

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        toolbarPlugin: {
            searchPlugin: {
                keyword: ['document'],
                OnHighlightKeyword: (props) => {
                    props.highlightEle.style.outline = '1px dashed blue'
                    props.highlightEle.style.backgroundColor =
                        'rgba(0, 0, 0, .1)'
                },
            },
        },
        renderToolbar,
        sidebarTabs: () => [],
    })

    const handleCitation = async (props) => {
        const textValue = props?.selectedText?.substr(0, 525)
        setCitationLoading(true)
        await provider
            .insert(url.webhookURL_book_citation, {
                book_id: bookId,
                page: currentPage + 1,
                text: textValue,
                epub_read_locator: '-',
                platform_info: 'web',
            })
            .then(() => {
                const today = dayjs().format('DD/MM/YYYY')
                const timezoneMap = {
                    'GMT+7': 'WIB',
                    'GMT+8': 'WITA',
                    'GMT+9': 'WIT',
                }
                const timezone = timezoneMap[dateFormatter(dayjs(), 'z')]
                const timenow = `${dayjs().format('HH:mm')} ${timezone}`

                const fullText = translator.t('message.citationMessage', {
                    content: textValue,
                    judul: bookTitle,
                    tanggal: today,
                    jam: timenow,
                    penulis: !isUndefined(bookDetails?.book_author)
                        ? bookDetails?.book_author
                        : '',
                    penerbit: !isUndefined(
                        bookDetails?.catalog_info?.organization_name
                    )
                        ? bookDetails?.catalog_info?.organization_name
                        : '',
                    tahun: !isUndefined(bookDetails?.publish_date)
                        ? bookDetails?.publish_date
                        : '',
                    halaman: currentPage + 1,
                })
                navigator.clipboard.writeText(fullText)
                message.info('Teks berhasil dikutip.')
            })
            .catch((err) =>
                // eslint-disable-next-line no-console
                console.log(err)
            )
        setCitationLoading(false)
    }

    const renderHighlightTarget = (props) => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    left: `${props.selectionRegion.left}%`,
                    top: `${
                        props.selectionRegion.top + props.selectionRegion.height
                    }%`,
                    transform: 'translate(0, 8px)',
                    zIndex: 1,
                    gap: 12,
                }}
            >
                <Tooltip
                    position={Position.TopCenter}
                    target={
                        <Button onClick={() => handleCitation(props)}>
                            {citationLoading ? (
                                <LoadingOutlined />
                            ) : (
                                <CopyOutlined />
                            )}
                        </Button>
                    }
                    content={() => <div style={{ width: '100px' }}>Kutip</div>}
                    offset={{ left: 0, top: -8 }}
                />
                {/* <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 5,
            }}
          >
            <Button style={{ backgroundColor: '#F8E71C', borderRadius: '50%' }}>
              {' '}
            </Button>
            <Button style={{ backgroundColor: '#50E3C2', borderRadius: '50%' }}>
              {' '}
            </Button>
            <Button style={{ backgroundColor: '#F87585', borderRadius: '50%' }}>
              {' '}
            </Button>
            <Button style={{ backgroundColor: '#7356AC', borderRadius: '50%' }}>
              {' '}
            </Button>
          </div>
          <hr
            style={{
              marginTop: 20,
              height: 3,
              width: '80%',
              backgroundColor: '#000',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            gap: 5,
            background: '#eee',
            alignItems: 'center',
          }}
        >
          <Button onClick={() => handleCitation(props)}>
            {citationLoading ? <LoadingOutlined /> : <CopyOutlined />}
          </Button>
          <span>Kutip</span>

        </div> */}
            </div>
        )
    }

    // const renderHighlights = (props) => (
    //   <div>
    //     {areas
    //       .filter((area) => area.pageIndex === props.pageIndex)
    //       .map((area, idx) => (
    //         <div
    //           key={idx}
    //           style={Object.assign(
    //             {},
    //             {
    //               background: '#f00',
    //               opacity: 0.4,
    //             },
    //             props.getCssProperties(area, props.rotation)
    //           )}
    //         />
    //       ))}
    //   </div>
    // )

    const highlightPluginInstance = highlightPlugin({
        renderHighlightTarget,
        // renderHighlights,
    })

    return loading ? (
        <div className="loading-book">
            <img src={animationData} width={200} alt="" />
        </div>
    ) : (
        <div className="rb-container">
            <Row>
                <Col span={24}>
                    {loading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <LoadingOutlined />
                        </div>
                    ) : (
                        <div>
                            {errorPage ? (
                                <div className="error-book">
                                    <img src={errorData} width={300} alt="" />
                                    <br />
                                    <h3 style={{ color: '#2B303A' }}>
                                        Terjadi kesalahan pada sistem
                                        internal...
                                    </h3>
                                    <p style={{ fontSize: 12, color: 'grey' }}>
                                        Silahkan muat ulang atau coba kembali
                                        nanti :(
                                    </p>
                                    <Space>
                                        <Button
                                            type="default"
                                            className="btn-default"
                                        >
                                            Laporkan Masalah
                                        </Button>
                                        <Button
                                            type="primary"
                                            className="btn-primary"
                                            onClick={handleRefresh}
                                        >
                                            Muat Ulang
                                        </Button>
                                    </Space>
                                </div>
                            ) : (
                                <div className="pdf-reader">
                                    {(!isDrm || (isDrm && file)) && (
                                        <LocalizationContext.Provider
                                            value={localizationContext}
                                        >
                                            <Worker workerUrl="/js/pdf.worker.min.js">
                                                <div
                                                    className={
                                                        isOpenSearch
                                                            ? 'rpv-core__viewer wrapper-search'
                                                            : 'rpv-core__viewer'
                                                    }
                                                    style={{
                                                        display: block
                                                            ? 'none'
                                                            : 'flex',
                                                        flexDirection: 'column',
                                                        border: '1px solid rgba(0, 0, 0, 0.3)',
                                                        height: '100vh',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flex: 1,
                                                            overflow: 'hidden',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                flex: 1,
                                                            }}
                                                        >
                                                            <Viewer
                                                                fileUrl={
                                                                    isDrm
                                                                        ? new Uint8Array(
                                                                              file
                                                                          )
                                                                        : url_file
                                                                }
                                                                defaultScale={
                                                                    isMobile()
                                                                        ? 0.5
                                                                        : 1.8
                                                                }
                                                                renderLoader={(
                                                                    percentages
                                                                ) => (
                                                                    <div
                                                                        style={{
                                                                            width: '240px',
                                                                        }}
                                                                    >
                                                                        <ProgressBar
                                                                            progress={Math.round(
                                                                                percentages
                                                                            )}
                                                                        />
                                                                    </div>
                                                                )}
                                                                onDocumentLoad={(
                                                                    e
                                                                ) => {
                                                                    setTotalPage(
                                                                        e?.doc
                                                                            ?._pdfInfo
                                                                            ?.numPages
                                                                    )
                                                                    if (
                                                                        lastpage &&
                                                                        lastpage !==
                                                                            1
                                                                    ) {
                                                                        if (
                                                                            lastpage >
                                                                            e
                                                                                ?.doc
                                                                                ?._pdfInfo
                                                                                ?.numPages
                                                                        ) {
                                                                            currentPage = 0
                                                                        } else {
                                                                            jumpToPage(
                                                                                lastpage -
                                                                                    1
                                                                            )
                                                                        }
                                                                    } else {
                                                                        currentPage = 0
                                                                    }
                                                                }}
                                                                onDocumentAskPassword={
                                                                    handleAskPassword
                                                                }
                                                                onPageChange={(
                                                                    e
                                                                ) => {
                                                                    currentPage =
                                                                        e?.currentPage
                                                                }}
                                                                plugins={[
                                                                    defaultLayoutPluginInstance,
                                                                    pageNavigationPluginInstance,
                                                                    bookmarkPluginInstance,
                                                                    thumbnailPluginInstance,
                                                                    searchPluginInstance,
                                                                    ...(configApp?.BOOK_CITATION
                                                                        ? [
                                                                              highlightPluginInstance,
                                                                          ]
                                                                        : []),
                                                                ]}
                                                            />
                                                        </div>

                                                        <div
                                                            style={{
                                                                borderRight:
                                                                    thumbnailOpen
                                                                        ? '1px solid rgba(0, 0, 0, 0.3)'
                                                                        : 'none',
                                                                overflow:
                                                                    'auto',
                                                                transition:
                                                                    'width 400ms ease-in-out',
                                                                width: thumbnailOpen
                                                                    ? '20%'
                                                                    : '0%',
                                                            }}
                                                        >
                                                            <Thumbnails
                                                                renderThumbnailItem={
                                                                    renderThumbnailItem
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Worker>
                                        </LocalizationContext.Provider>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    )
}

export default BookReadPdf
