import { Card, Dropdown, Menu, message, Tooltip } from 'antd'
import React, { useLayoutEffect, useState } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import Text from 'antd/lib/typography/Text'
import { BASE_TITLE } from 'constants/constant'
import translator from 'i18next'
import { MoreOutlined } from '@ant-design/icons'
import { ModalShare } from 'components'
import ModalReturnBook from 'views/bookshelf/components/ModalReturnBook'
import { ic_headphone, noimages } from '../../../../assets/icons'

const AudioCardComponent = (props) => {
    const {
        data,
        source,
        epustakaId,
        dataEpustaka,
        frombookself,
        getMedia,
        fromIndex,
        // userId,
    } = props

    const history = useHistory()
    const {
        media_thumbnail_url,
        // media_duration,
        media_title,
        created_at,
        id,
        collection_id,
        epustaka_id,
        progress,
    } = data

    const idFilter =
        source === 'audio' || source === 'userProfil' ? collection_id : id

    // const { profile = {} } = reduxState.getState()

    const [popShare, setPopShare] = useState(false)
    const [visibleDelete, setVisibleDelete] = useState(false)
    const [mediaItem, setMediaItem] = useState({})

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 420

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const handleLinkToDetail = () => {
        if (
            !dataEpustaka?.has_join ||
            (dataEpustaka?.has_join && dataEpustaka?.member_status !== 1)
        ) {
            if (dataEpustaka?.member_status === 3) {
                message.warning(translator.t('message.notifExpand'))
            } else {
                message.warning(translator.t('message.notif'))
            }
        } else {
            history.push(`/audio/${idFilter}/${epustakaId}`)
        }
    }

    const handleSourceLink = () => {
        if (source === 'audio') {
            history.push({
                pathname: `/audio/${idFilter}/${epustaka_id}/${progress || 0}`,
            })
        } else if (source === 'userProfil') {
            history.push({
                pathname: `/audio/${idFilter}/${epustaka_id}`,
            })
        } else {
            handleLinkToDetail()
        }
    }

    const handleClickShare = (value) => {
        setMediaItem(value)
        setPopShare(true)
    }

    const handleClickDelete = (value) => {
        setMediaItem(value)
        setVisibleDelete(true)
    }

    const MenuComponent = (
        <Menu className="share-menu">
            <Menu.ItemGroup>
                {source !== 'userProfil' && (
                    <Menu.Item key="1" onClick={() => handleClickDelete(data)}>
                        {translator.t('feed.delete')}
                    </Menu.Item>
                )}

                <Menu.Item key="2" onClick={() => handleClickShare(data)}>
                    {translator.t('title.share')}
                </Menu.Item>
            </Menu.ItemGroup>
        </Menu>
    )

    return (
        <div className="audio-grid" key={id} data-value={id}>
            <Card
                className="audio-card"
                onClick={() => handleSourceLink()}
                style={{ cursor: 'pointer' }}
            >
                {frombookself && (
                    <div
                        className="dropdownMore"
                        style={{
                            backgroundColor: fromIndex && 'rgba(0, 0, 0, 0.5)',
                            position: fromIndex && 'absolute',
                            right: fromIndex && '7px',
                            top: fromIndex && '5px',
                            borderRadius: fromIndex && '3px',
                            padding: fromIndex && '0 3px',
                            zIndex: fromIndex && 2,
                        }}
                    >
                        <Dropdown overlay={MenuComponent}>
                            <span>
                                <MoreOutlined
                                    rotate={90}
                                    style={{ color: 'white', width: 25 }}
                                />
                            </span>
                        </Dropdown>
                    </div>
                )}
                <img
                    className="cover"
                    src={
                        media_thumbnail_url === null
                            ? noimages
                            : media_thumbnail_url
                    }
                    alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                />
                <div className="card-overlay card-overlay-dark">
                    <div
                        className="card-overlay-content"
                        style={{ marginTop: isMobile() && '3.5rem' }}
                    >
                        <div
                            className={
                                isMobile() ? 'play-icon-mobile' : 'play-icon'
                            }
                        >
                            <img
                                className="icon"
                                src={ic_headphone}
                                alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                            />
                        </div>
                    </div>
                </div>
            </Card>
            <h2 className="audio-title">
                <Text
                    className="au-title"
                    style={{
                        width: 200,
                    }}
                    ellipsis={true}
                >
                    <Tooltip
                        title={media_title}
                        placement="topLeft"
                        color="#404040"
                    >
                        {media_title}
                    </Tooltip>
                </Text>
            </h2>
            <p className="audio-subtitle">
                {moment(created_at).format('DD MMMM YYYY')}
            </p>

            <ModalShare
                urlFrom="audio"
                epustakaId={mediaItem?.epustaka_id}
                collectionId={mediaItem?.collection_id}
                visibleModal={popShare}
                setVisibleModal={setPopShare}
            />
            <ModalReturnBook
                visibleModal={visibleDelete}
                setVisibleModal={setVisibleDelete}
                mediaData={mediaItem}
                getMedia={getMedia}
                frombookself={frombookself}
            />
        </div>
    )
}

export default AudioCardComponent
