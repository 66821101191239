import React from 'react'
import translator from 'i18next'
import map from 'lodash/map'
import omit from 'lodash/omit'
import { Form, Select, Skeleton } from 'antd'

//eslint-disable-next-line
export default (props) => {
    const {
        onChange,
        name,
        schema = {},
        data = [],
        formItemProps = {},
        loading = false,
    } = props
    const { Option } = Select

    const handleChange = (value, e) => {
        if (onChange) {
            onChange(value, e)
        }
    }

    let Component = (
        <>
            {props.text ? <strong>{translator.t(props.text)}</strong> : ''}
            <Select
                showSearch
                {...omit(props, ['loading'])}
                style={{ width: '100%' }}
                onChange={handleChange}
                filterOption={(input, option) =>
                    option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
            >
                {data.map((m, idx) => (
                    <Option key={idx} value={m.value} disabled={m.disabled}>
                        {m.text}
                    </Option>
                ))}
            </Select>
        </>
    )
    let formProps = {}

    if (name && schema[name]) {
        const fieldSchema = schema[name]
        const label = props.text || fieldSchema.label

        Component = (
            <Select
                showSearch
                {...omit(props, ['loading'])}
                style={{ width: '100%' }}
                onChange={handleChange}
                filterOption={(input, option) =>
                    option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
            >
                {data.map((m, idx) => (
                    <Option key={idx} value={m.value} disabled={m.disabled}>
                        {m.text}
                    </Option>
                ))}
            </Select>
        )
        formProps = {
            name,
            label: translator.t(label),
            rules: map(fieldSchema.rules, (rule) => ({
                ...rule,
                message: translator.t(rule.message),
            })),
        }
    }

    if (loading) Component = <Skeleton.Input active />

    return (
        <Form.Item className="form-item" {...formProps} {...formItemProps}>
            {Component}
        </Form.Item>
    )
}
