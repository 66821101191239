import React, { useState } from 'react'
import translator from 'i18next'
import map from 'lodash/map'
import debounce from 'lodash/debounce'
import join from 'lodash/join'
import { Form, Select, Skeleton, Tooltip } from 'antd'
import { COLORS } from 'constants/constant'

export default function InputSearchSelect(props) {
    const {
        name,
        schema = {},
        data = [],
        formItemProps = {},
        loading = false,
        limit,
        limitincrease = 25,
        setLimit,
        setSearch,
        disabled = false,
        time = 500,
        defaultLimit = 25,
        onChange,
        allowClear = false,
        single = false,
        tooltipOption = false,
        styleForm,
        styleSelect,
        classNameProps,
        noFormItem = false,
        propsSelect = {},
        defaultValue,
    } = props

    const showSearch = setSearch ? true : false

    const classNameDefault = noFormItem ? [] : ['form-item']

    const arrayClassName = classNameProps
        ? [...classNameDefault, ...classNameProps]
        : [...classNameDefault]
    const classNameForm = join(arrayClassName, ' ')

    const { Option } = Select
    const [loadingSkeleton, setLoadingSkeleton] = useState(true)

    const listenScroll = (e) => {
        const isEndOfList = e.target.scrollTop + e.target.clientHeight
        if (isEndOfList === e.target.scrollHeight && data.length >= limit) {
            setLoadingSkeleton(false)
            setLimit && setLimit(limit + limitincrease)
        }
    }

    const listenSearch = debounce((value) => {
        setLoadingSkeleton(false)
        let toLower = value.toLocaleLowerCase()
        setLimit && setLimit(defaultLimit)
        setSearch && setSearch(toLower)
    }, time)

    const optionSelect = (value) => {
        return tooltipOption ? <Tooltip title={value}>{value}</Tooltip> : value
    }

    let Component = (
        <>
            {props.text ? <strong>{translator.t(props.text)}</strong> : ''}
            {showSearch ? (
                <Select
                    {...propsSelect}
                    showSearch={showSearch && showSearch}
                    style={styleSelect ? styleSelect : { width: '100%' }}
                    onPopupScroll={(e) => listenScroll(e)}
                    onSearch={(value) => showSearch && listenSearch(value)}
                    filterOption={false}
                    loading={loading}
                    disabled={disabled}
                    onChange={(value) => onChange && onChange(value)}
                    allowClear={allowClear && allowClear}
                    size="large"
                    defaultValue={defaultValue}
                >
                    {data.map((m, idx) => (
                        <Option key={idx} value={m.value} disabled={m.disabled}>
                            {optionSelect(m.text)}
                            <div style={{ color: COLORS.basegrey }}>
                                {optionSelect(m.regency)},{' '}
                                {optionSelect(m.province)}
                            </div>
                        </Option>
                    ))}
                </Select>
            ) : (
                <Select
                    {...propsSelect}
                    style={styleSelect ? styleSelect : { width: '100%' }}
                    onPopupScroll={(e) => listenScroll(e)}
                    filterOption={false}
                    loading={loading}
                    disabled={disabled}
                    onChange={(value) => onChange && onChange(value)}
                    allowClear={allowClear && allowClear}
                    size="large"
                    defaultValue={defaultValue}
                >
                    {data.map((m, idx) => (
                        <Option key={idx} value={m.value} disabled={m.disabled}>
                            {optionSelect(m.text)}
                        </Option>
                    ))}
                </Select>
            )}
        </>
    )
    let formProps = {}

    if (name && schema[name]) {
        const fieldSchema = schema[name]
        const label = props.text || fieldSchema.label

        Component = showSearch ? (
            <Select
                {...propsSelect}
                showSearch={showSearch && showSearch}
                style={styleSelect ? styleSelect : { width: '100%' }}
                onPopupScroll={(e) => listenScroll(e)}
                onSearch={(value) => showSearch && listenSearch(value)}
                filterOption={false}
                loading={loading}
                disabled={disabled}
                onChange={(value) => onChange && onChange(value)}
                allowClear={allowClear && allowClear}
                placeholder={single && translator.t(label)}
                size="large"
                defaultValue={defaultValue}
            >
                {data.map((m, idx) => (
                    <Option key={idx} value={m.value} disabled={m.disabled}>
                        {optionSelect(m.text)}
                    </Option>
                ))}
            </Select>
        ) : (
            <Select
                {...propsSelect}
                style={styleSelect ? styleSelect : { width: '100%' }}
                onPopupScroll={(e) => listenScroll(e)}
                filterOption={false}
                loading={loading}
                disabled={disabled}
                onChange={(value) => onChange && onChange(value)}
                allowClear={allowClear && allowClear}
                placeholder={single && translator.t(label)}
                size="large"
                defaultValue={defaultValue}
            >
                {data.map((m, idx) => (
                    <Option key={idx} value={m.value} disabled={m.disabled}>
                        {optionSelect(m.text)}
                    </Option>
                ))}
            </Select>
        )

        formProps = {
            name,
            label: !single && translator.t(label),
            rules: map(fieldSchema.rules, (rule) => ({
                ...rule,
                message: translator.t(rule.message),
            })),
        }
    }

    if (loadingSkeleton && loading) Component = <Skeleton.Input active />

    return (
        <Form.Item
            className={classNameForm}
            {...formProps}
            {...formItemProps}
            style={styleForm && styleForm}
        >
            {Component}
        </Form.Item>
    )
}
