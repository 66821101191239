import React from 'react'
import { Row, Col } from 'antd'
import Post from 'views/timeline/components/Post'
import ActivityPost from 'views/timeline/components/ActivityPost'
import get from 'lodash/get'
import Comment from 'views/timeline/components/CommentPost'
import translator from 'i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const DetailTimeline = (props) => {
    const { feed, epustakaDetail } = props

    const timelineDetail = props.match.path

    const fromEpustakaDetail = get(feed, 'fromEpustakaDetail', false)

    const id = get(feed, 'id', '')
    const feed_type = get(feed, 'feed_type', '')
    const author = get(feed, 'author', '')
    const sender_type = get(feed, 'sender_type', '')
    const senderId = get(feed, 'senderId', '')
    const userId = get(feed, 'userId', '')
    const username = get(feed, 'username', '')
    const has_like = get(feed, 'has_like', '')
    const has_comment = get(feed, 'has_comment', '')
    const avatar_url = get(feed, 'avatar_url', '')
    const message = get(feed, 'message', '')
    const timestamp = get(feed, 'timestamp', '')
    const imageBook = get(feed, 'imageBook', '')
    const bookTitle = get(feed, 'bookTitle', '')
    const bookId = get(feed, 'bookId', '')
    const bookRating = get(feed, 'bookRating', '')
    const total_comment = get(feed, 'total_comment', '')
    const total_like = get(feed, 'total_like', '')
    const review = get(feed, 'review', '')
    const imagePost = get(feed, 'imagePost', '')
    const description = get(feed, 'description', '')
    const recommendation = get(feed, 'recommendation', '')

    const BOOK_BORROW = 'BOOK_BORROW'
    const BOOK_REVIEW = 'BOOK_REVIEW'
    const VISUAL_POST = 'VISUAL_POST'
    const BOOK_RECOMMENDATION = 'BOOK_RECOMMENDATION'

    return (
        <Row type="flex" style={{ alignItems: 'center' }} justify="center">
            <Col xs={24} sm={19} md={20} lg={14} xl={14}>
                <div>
                    {feed_type === BOOK_BORROW ? (
                        <Post
                            id={id}
                            has_like={has_like}
                            feed_type={feed_type}
                            sender_type={sender_type}
                            senderId={senderId}
                            userId={userId}
                            avatar_url={avatar_url}
                            message={message}
                            timestamp={timestamp}
                            username={username}
                            imageBook={imageBook}
                            bookTitle={bookTitle}
                            bookRating={bookRating}
                            total_comment={total_comment}
                            total_like={total_like}
                            fromDetails
                            bookId={bookId}
                            author={author}
                        />
                    ) : (
                        ''
                    )}
                    {feed_type === BOOK_REVIEW ? (
                        <Post
                            id={id}
                            has_like={has_like}
                            feed_type={feed_type}
                            sender_type={sender_type}
                            senderId={senderId}
                            userId={userId}
                            avatar_url={avatar_url}
                            username={username}
                            message={message}
                            timestamp={timestamp}
                            imageBook={imageBook}
                            bookTitle={bookTitle}
                            bookRating={bookRating}
                            review={review}
                            total_comment={total_comment}
                            total_like={total_like}
                            fromDetails
                            bookId={bookId}
                            author={author}
                        />
                    ) : (
                        ''
                    )}
                    {feed_type === BOOK_RECOMMENDATION ? (
                        <Post
                            id={id}
                            has_like={has_like}
                            feed_type={feed_type}
                            sender_type={sender_type}
                            senderId={senderId}
                            userId={userId}
                            avatar_url={avatar_url}
                            message={translator.t('feed.book_recommendation')}
                            timestamp={timestamp}
                            username={username}
                            recommendation={recommendation.map((name, i) => {
                                const length = recommendation.length
                                return i === length - 1 ? `${name}` : `${name} `
                            })}
                            description={message}
                            imageBook={imageBook}
                            bookTitle={bookTitle}
                            bookRating={bookRating}
                            total_comment={total_comment}
                            total_like={total_like}
                            bookId={bookId}
                            author={author}
                            fromDetails
                        />
                    ) : (
                        ''
                    )}
                    {feed_type === VISUAL_POST ? (
                        <ActivityPost
                            id={id}
                            has_like={has_like}
                            has_comment={has_comment}
                            feed_type={feed_type}
                            sender_type={sender_type}
                            senderId={senderId}
                            userId={userId}
                            avatar_url={avatar_url}
                            username={username}
                            timestamp={timestamp}
                            message={message}
                            total_comment={total_comment}
                            total_like={total_like}
                            imagePost={imagePost}
                            description={description}
                            fromDetails
                            fromEpustakaDetail={fromEpustakaDetail}
                            timelineDetail={timelineDetail}
                        />
                    ) : (
                        ''
                    )}
                </div>
                <div
                    style={{
                        background: '#ebebeb',
                        marginTop: -18,
                    }}
                >
                    <Comment
                        fromEpustakaDetail={fromEpustakaDetail}
                        dataEpustaka={epustakaDetail}
                        sender_type={sender_type}
                    />
                </div>
            </Col>
        </Row>
    )
}

const mapStateToProps = (state) => {
    return {
        feed: get(state, 'feed', {}),
        epustakaDetail: get(state, 'epustakaDetail.dataEpustaka', []),
    }
}

export default connect(mapStateToProps)(withRouter(DetailTimeline))
