import React, { useState, useEffect } from 'react'
import { Modal, Button, Row, Col, Avatar, message } from 'antd'
import get from 'lodash/get'
import translator from 'i18next'
import provider from 'providers/provider'
import url from 'constants/url'
import { error, success } from 'utils/notification'
import getAcronym from 'components/Acronym'

const ModalDownloadBook = ({
    data = {},
    visibleModal,
    setVisibleModal,
    profile,
    bookData,
    epustakaId,
    setCheckBorrowStatus,
}) => {
    const notJoin =
        (bookData?.epustaka?.has_join &&
            bookData?.epustaka?.member_status !== 1 &&
            bookData?.epustaka?.member_status !== 3) ||
        (data?.has_join &&
            data?.member_status !== 1 &&
            data?.member_status !== 3) ||
        data?.has_join === false ||
        bookData?.epustaka?.has_join === false

    const expiredData =
        (bookData?.epustaka?.has_join &&
            bookData?.epustaka?.member_status === 3) ||
        (data?.has_join && data?.member_status === 3)

    const [loading, setLoading] = useState(false)
    const [waitingReq, setWaitingReq] = useState(false)

    const name = bookData?.epustaka?.epustaka_name || data?.epustaka_name || ''

    const { acronym } = getAcronym(name, epustakaId)

    const handleBorrow = async () => {
        setLoading(true)
        const dataReg = {
            epustaka_id: epustakaId || data?.id,
            user_ids: [profile.id],
        }

        const dataBorrow = {
            epustaka_id: epustakaId || data?.id,
            user_id: profile?.id,
            book_id: bookData?.id,
            organization_id: profile?.organizations?.id,
        }

        if (notJoin || expiredData) {
            await provider
                .insert(url.webhookURL_join_epustaka_member, dataReg)
                .then(async () => {
                    if (
                        bookData?.epustaka?.epustaka_isprivate ||
                        data?.epustaka_isprivate
                    ) {
                        message.success(translator.t('epustka.notifPrivat'))
                        setWaitingReq(true)
                        setVisibleModal(false)
                    } else {
                        await provider
                            .borrow(url.webhookURL_borrow, dataBorrow)
                            .then((res) => {
                                setCheckBorrowStatus({
                                    status: true,
                                    data: res.data,
                                })
                                setVisibleModal(false)
                                setLoading(false)
                            })
                            .catch(() => {
                                setLoading(false)
                                setVisibleModal(false)
                            })
                    }
                })
                .catch((err) => {
                    setLoading(false)
                    error({
                        description: err.message,
                        placement: 'bottomRight',
                    })
                })
        } else {
            await provider
                .borrow(url.webhookURL_borrow, dataBorrow)
                .then((res) => {
                    if (res.code === 'SUCCESS') {
                        setCheckBorrowStatus({ status: true, data: res.data })

                        success({
                            description: res.message,
                            placement: 'bottomRight',
                        })
                    } else {
                        error({
                            description: res.message || res.messages,
                            placement: 'bottomRight',
                        })
                    }
                    setVisibleModal(false)
                    setLoading(false)
                })
                .catch(() => {
                    setLoading(false)
                    setVisibleModal(false)
                })
        }
        setLoading(false)
    }

    useEffect(() => {
        if (data?.member_status === 0) setWaitingReq(true)
    }, [data])

    useEffect(() => {
        if (
            bookData?.epustaka?.member_status == 0 &&
            bookData?.epustaka?.epustaka_isprivate
        )
            setWaitingReq(true)
    }, [bookData])

    return (
        <Modal
            centered
            visible={visibleModal}
            onOk={() => setVisibleModal(!visibleModal)}
            onCancel={() => setVisibleModal(!visibleModal)}
            footer={null}
            className="modal-popup"
            width={500}
        >
            <Row justify="center">
                <Col
                    className="mt-lg"
                    span={20}
                    style={{ textAlign: 'center' }}
                >
                    <h2 className="epustaka-name">{name}</h2>
                    <Avatar
                        size={100}
                        shape="square"
                        src={
                            data?.epustaka_logo ||
                            bookData?.epustaka?.epustaka_logo
                        }
                    >
                        {acronym}
                    </Avatar>
                    <div className="title" style={{ textAlign: 'center' }}>
                        {notJoin
                            ? translator.t('message.notJoinEpustaka')
                            : expiredData
                            ? translator.t('message.expiredJoinEpustaka')
                            : `${translator.t('title.availabelity_copy')} : ${
                                  !bookData.using_drm
                                      ? translator.t('message.unlimitedCopy')
                                      : data?.available_qty ||
                                        bookData?.available_qty
                              }`}
                    </div>

                    <Button
                        loading={loading}
                        className="btn-popup-modal"
                        size="large"
                        onClick={handleBorrow}
                        disabled={
                            waitingReq ||
                            (bookData.using_drm &&
                                get(bookData, 'available_qty', 0) <= 0)
                        }
                    >
                        {waitingReq
                            ? translator.t('title.pending_approval')
                            : translator.t(
                                  notJoin
                                      ? bookData?.epustaka?.epustaka_isprivate
                                          ? 'title.askJoin'
                                          : 'epustka.join'
                                      : expiredData
                                      ? 'title.extend'
                                      : 'action.borrow'
                              )}
                    </Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default ModalDownloadBook
