import * as ActionType from './contant'
import ls from 'utils/secureLocalStorage'
import Encryption from 'utils/encryption'

const STORAGE = Encryption.encryptWithMD5('bookmark')
const initState = []

const initializeState = () => {
    const bookmarkData = ls.get(STORAGE)
    const state = bookmarkData ? JSON.parse(bookmarkData) : initState
    return state
}

const reducer = (state = initializeState(), action) => {
    const { type, value } = action

    switch (type) {
        case ActionType.SET_BOOKMARK:
            ls.set(STORAGE, JSON.stringify(value))
            return value
        case ActionType.CLEAR_BOOKMARK:
            ls.remove(STORAGE)
            return initState
        default:
            return state
    }
}

export default reducer
