import React, { useState, useLayoutEffect } from 'react'
import { Card } from 'antd'
import { useHistory } from 'react-router-dom'
import { BASE_TITLE } from 'constants/constant'
import { ic_pustaka_holder } from 'assets/icons'

const MoreEpustakaComponent = (props) => {
    const { data, fromMoreEpustaka } = props
    const { epustaka_name, epustaka_logo, id } = data
    const history = useHistory()

    const [state, setState] = useState({
        loading: true,
        pagination: true,
    })

    const isSmallMobile = () => state.width <= 375

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    return (
        <div
            className="epustaka-slider"
            key={id}
            data-value={id}
            onClick={() => history.push(`/epustaka/${id}`)}
            style={{ cursor: 'pointer' }}
        >
            <div className="epustaka-grid" style={{ marginLeft: '5px' }}>
                <Card
                    className="epustaka-card"
                    style={{
                        width: fromMoreEpustaka && isSmallMobile() && '12em',
                    }}
                    cover={
                        <img
                            alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                            src={epustaka_logo || ic_pustaka_holder}
                            onError={(e) => {
                                e.currentTarget.src = ic_pustaka_holder
                            }}
                        />
                    }
                >
                    <h4 className="names">{epustaka_name}</h4>
                </Card>
            </div>
        </div>
    )
}

export default MoreEpustakaComponent
