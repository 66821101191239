import React, { useState, useEffect, useReducer } from 'react'
import {
    Button,
    Col,
    Collapse,
    Divider,
    Image,
    Modal,
    Row,
    Skeleton,
    Space,
    Typography,
    Timeline,
} from 'antd'
import translator from 'i18next'
import notificationHandler from 'utils/notificationHandler'
import dayjs from 'dayjs'
import provider from 'providers/provider'
import url from 'constants/url'
import NumberFormat from 'react-number-format'
import reduxState from '../../../redux'
import isNull from 'lodash/isNull'
import { BASE_TITLE, COLORS } from 'constants/constant'
import { ic_cancel_info, ic_donate, ic_status } from 'assets/icons'
import {
    DownOutlined,
    RightOutlined,
    UpOutlined,
    LoadingOutlined,
} from '@ant-design/icons'
import { success } from 'utils/notification'
import includes from 'lodash/includes'

const { Panel } = Collapse
const { Paragraph } = Typography
const ModalConfirmPayment = (props) => {
    const {
        visible,
        setVisible,
        orderBoxId,
        source = '',
        transactionType = null,
    } = props

    const { profile = {} } = reduxState.getState()

    const profile_name = profile?.name

    const [dataOrder, setDataOrder] = useState([])
    const [dataInvoice, setDataInvoice] = useState({})
    const [loadingAll, setLoadingAll] = useState(false)
    const [collasible, setCollasible] = useState(false)
    const [labelStatus, setLabelStatus] = useState({})

    const defaultWaybillState = {
        isActive: false,
        loading: false,
        courier: '',
        receipt_number: '',
        data: null,
        activeKey: null,
    }

    const [waybillState, dispatchWaybill] = useReducer(
        (current, accumulate) => ({
            ...current,
            ...accumulate,
        }),
        defaultWaybillState
    )

    const defaultReceivedState = {
        visible: false,
        loading: false,
    }

    const [receivedState, dispatchReceived] = useReducer(
        (current, accumulate) => ({
            ...current,
            ...accumulate,
        }),
        defaultReceivedState
    )

    // KONDISI STATUS TRANSAKSI

    const generateLabelStyle = (dataInvoice) => {
        switch (dataInvoice?.payment_status) {
            case 1:
                return setLabelStatus({
                    labelStatus: translator.t('payment.waiting_payment'),
                    styleStatus: {
                        color: COLORS.primaryColor,
                        border: `1px solid ${COLORS.primaryColor}`,
                        padding: '0px 6px',
                        borderRadius: '4px',
                    },
                })

            case 2:
                setLabelStatus({
                    labelStatus: translator.t('payment.verifikasi_process'),
                    styleStatus: {
                        color: COLORS.primaryColor,
                        border: `1px solid ${COLORS.primaryColor}`,
                        padding: '0px 6px',
                        borderRadius: '4px',
                    },
                })

                break

            case 3:
                return setLabelStatus({
                    labelStatus: translator.t('payment.finishPayment'),
                    styleStatus: {
                        color: COLORS.primaryColor,
                        border: `1px solid ${COLORS.primaryColor}`,
                        padding: '0px 6px',
                        borderRadius: '4px',
                    },
                })

            case 4:
                return setLabelStatus({
                    labelStatus: translator.t('payment.paymentFailed'),
                    styleStatus: {
                        color: COLORS.basered,
                        border: `1px solid ${COLORS.basered}`,
                        padding: '0px 6px',
                        borderRadius: '4px',
                    },
                })
            case 5:
                return setLabelStatus({
                    labelStatus: translator.t('payment.cancel_payment'),
                    styleStatus: {
                        color: COLORS.basered,
                        border: `1px solid ${COLORS.basered}`,
                        padding: '0px 6px',
                        borderRadius: '4px',
                    },
                })

            case 6:
                return setLabelStatus({
                    labelStatus: translator.t('payment.verify_payment'),
                    styleStatus: {
                        color: COLORS.primaryColor,
                        border: `1px solid ${COLORS.primaryColor}`,
                        padding: '0px 6px',
                        borderRadius: '4px',
                    },
                })

            case 7:
                return setLabelStatus({
                    labelStatus:
                        dataInvoice?.courier_info?.code === 'taken_directly'
                            ? translator.t('payment.waiting_pickup')
                            : translator.t('payment.on_delivery'),
                    styleStatus: {
                        color: COLORS.primaryColor,
                        border: `1px solid ${COLORS.primaryColor}`,
                        padding: '0px 6px',
                        borderRadius: '4px',
                    },
                })

            default:
                break
        }
    }

    // GET COUNTDOWN PAYMENT
    const GetCountDown = ({ end_time }) => {
        const millisToMinutesAndSeconds = (millis) => {
            let hours = Math.floor(
                (millis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            )
            let minutes = Math.floor((millis % (1000 * 60 * 60)) / (1000 * 60))
            let seconds = Math.floor((millis % (1000 * 60)) / 1000)
            return millis < 0
                ? '0:00'
                : hours +
                      ' : ' +
                      minutes +
                      ' : ' +
                      (seconds < 10 ? '0' : '') +
                      seconds +
                      ' ' +
                      translator.t('title.second')
        }

        const [time, setTime] = useState(
            millisToMinutesAndSeconds(dayjs(end_time).diff(dayjs()))
        )

        const updateTime = () => {
            const duration = dayjs(end_time).diff(dayjs())

            setTime(millisToMinutesAndSeconds(duration))

            // if (duration < 0) {
            //   setVisibleFinish(true)
            // }
        }

        useEffect(() => {
            const token = setTimeout(updateTime, 1000)

            return function cleanUp() {
                clearTimeout(token)
            }
        })
        return <div>{!loadingAll ? time : '00 : 00 : 00'}</div>
    }

    const TimeComponent = () => {
        return <div>{<GetCountDown end_time={dataInvoice?.expired_at} />}</div>
    }

    const getOrderBoxDetail = async () => {
        const filter = isNull(transactionType) ? 'DONATION' : transactionType
        await provider
            .singleWebHook(
                `${
                    url.webhookURL_order_box_detail
                }?order_box_id=${orderBoxId}&limit=${9999}&category=${filter}`
            )
            .then((res) => {
                const { data } = res
                setDataOrder(data)
            })
            .catch((err) => {
                notificationHandler('dev2', err)
                return
            })
    }

    const getInvoiceStatus = async () => {
        await provider
            .singleWebHook(
                `${url.webhookURL_invoice_status}?order_box_id=${orderBoxId}`
            )
            .then((res) => {
                const { data } = res
                if (data?.receipt_number) {
                    dispatchWaybill({
                        isActive: true,
                        loading: true,
                        courier: data?.courier_info?.code,
                        receipt_number: data?.receipt_number,
                    })
                }
                setDataInvoice(data)
                generateLabelStyle(data)
            })
    }

    const getWaybill = async () => {
        const payload = {
            courier: waybillState?.courier,
            receipt_number: waybillState?.receipt_number,
        }
        await provider
            .insert(`${url.webhookURL_shipping_waybill}`, payload)
            .then(async (resWaybill) => {
                dispatchWaybill({ loading: false, data: resWaybill?.data })
            })
            .catch((err) => {
                dispatchWaybill({ loading: false, isActive: false })
                notificationHandler('dev2', err)
                return
            })
    }

    const getAllData = async () => {
        setLoadingAll(true)
        await Promise.allSettled([
            getOrderBoxDetail(),
            getInvoiceStatus(),
        ]).then(() => setLoadingAll(false))
    }

    useEffect(() => {
        orderBoxId && visible && getAllData()
    }, [orderBoxId, visible])

    useEffect(() => {
        if (!visible) dispatchWaybill(defaultWaybillState)
    }, [visible])

    useEffect(() => {
        if (waybillState?.isActive) getWaybill()
    }, [waybillState?.isActive])

    const generateContent = (item) => {
        switch (item?.order_type) {
            case 'BOOK':
            case 'BOOK_PACKAGE':
                return (
                    <div>
                        {`${
                            (item?.order_type === 'BOOK' || 'BOOK_PACKAGE') &&
                            translator.t('title.book')
                        } (${item?.order_object?.book_title}) ${
                            item?.number_of_copy
                        } Copy`}{' '}
                        <span>
                            <NumberFormat
                                value={item?.price - item?.discount_amount}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'@ Rp'}
                                suffix=",-"
                            />
                        </span>
                        <span>{`${translator.t('title.purpose')} : ${
                            item?.epustaka?.epustaka_name
                        }`}</span>
                    </div>
                )

            case 'TRYOUT':
                return (
                    <div>
                        <span>{`TRYOUT - (${item?.order_object?.title})`}</span>
                    </div>
                )

            case 'QR':
                return (
                    <div>
                        <span>{`QR - (${item?.order_object?.name}) ${item?.order_object?.quota} item QR`}</span>
                    </div>
                )

            case 'STORAGE':
                return (
                    <div>
                        <span>{`STORAGE - (${item?.order_object?.package_name}) ${item?.order_object?.quota} GB`}</span>
                    </div>
                )
            case 'SOUVENIR':
                return (
                    <div>
                        <span>{`SOUVENIR - (${item?.order_object?.name})`}</span>
                    </div>
                )

            default:
                return null
        }
    }

    const handleOnConfirm = async () => {
        const payload = { transaction_id: dataInvoice?.transaction_id }

        await provider
            .update(url.webhookURL_transaction_status, payload)
            .then(() => {
                success({
                    description: translator.t(
                        'payment.success_update_transaction'
                    ),
                    placement: 'bottomRight',
                })
                window.location.reload()
            })
            .catch((err) => {
                notificationHandler('dev2', err)
            })
        dispatchReceived({
            visible: false,
            loading: false,
        })
    }

    const handleComplaint = (recepient) => {
        if (recepient === 'admin') {
            window.open(`https://wa.me/6287837311151`, '_blank')
        } else {
            window.open(
                `https://wa.me/${dataInvoice?.courier_info?.store_phone}`,
                '_blank'
            )
        }
    }

    const renderFooter = () => {
        switch (dataInvoice?.payment_status) {
            case 7:
                return (
                    <Button
                        style={{
                            margin: '10px 0',
                        }}
                        type="primary"
                        onClick={() => {
                            dispatchReceived({
                                visible: true,
                            })
                        }}
                    >
                        {translator.t('payment.order_received')}
                    </Button>
                )

            case 2:
            case 3:
            case 5:
            case 6:
                return null

            case 4:
                return (
                    <div
                        style={{
                            gap: 20,
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            style={{
                                margin: '10px 0',
                            }}
                            onClick={() => {
                                handleComplaint('admin')
                                setVisible(false)
                            }}
                        >
                            {translator.t('payment.complaint')}
                        </Button>
                        <Button
                            style={{
                                margin: '10px 0',
                            }}
                            type="primary"
                            onClick={() => {
                                setVisible(false)
                            }}
                        >
                            <a
                                href={dataInvoice?.invoice_url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {translator.t('payment.pay_now')}
                            </a>
                        </Button>
                    </div>
                )

            default:
                return (
                    <Button
                        style={{
                            margin: '10px 0',
                        }}
                        type="primary"
                        onClick={() => {
                            setVisible(false)
                        }}
                    >
                        <a
                            href={dataInvoice?.invoice_url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {translator.t('payment.pay_now')}
                        </a>
                    </Button>
                )
        }
    }

    const generateTopInfo = (status) => {
        switch (status) {
            case 3:
                return dayjs(dataInvoice?.created_at).format('LLLL')
            case 5:
                return (
                    <div
                        style={{
                            color: COLORS.basered,
                        }}
                    >
                        00 : 00 : 00 {translator.t('title.second')}
                    </div>
                )
            case 7:
                return `${
                    dataInvoice?.courier_info?.etd !== 0
                        ? dataInvoice?.courier_info?.etd
                        : '-'
                } Hari`
            case 6:
                if (dataInvoice?.pre_order) {
                    return `${
                        dataInvoice?.shipping_duration !== 0
                            ? dataInvoice?.shipping_duration
                            : '-'
                    } Hari`
                } else {
                    return `${
                        dataInvoice?.courier_info?.etd !== 0
                            ? dataInvoice?.courier_info?.etd
                            : '-'
                    } Hari`
                }

            default:
                return <TimeComponent />
        }
    }

    return (
        <Modal
            title={labelStatus?.labelStatus}
            visible={visible}
            centered
            onCancel={() => setVisible(!visible)}
            className="modal-xendit-donation-wrapper"
            maskClosable={false}
            footer={renderFooter()}
        >
            <React.Fragment>
                <div className="xendit-detail-wrapper">
                    {/* INFO COUNTDOWN AND ID TRANSACTION */}
                    <Row align="top" className="xendit-info-top">
                        <Col span={12} className="xendit-info-top-countdown">
                            {loadingAll ? (
                                <Skeleton.Input
                                    size="large"
                                    style={{ width: '95%' }}
                                />
                            ) : (
                                dataInvoice?.payment_status !== 2 && (
                                    <React.Fragment>
                                        <h4 className="remaining-payment">
                                            {includes(
                                                [3],
                                                dataInvoice?.payment_status
                                            )
                                                ? translator.t(
                                                      'payment.payment_date'
                                                  )
                                                : includes(
                                                      [6, 7],
                                                      dataInvoice?.payment_status
                                                  )
                                                ? dataInvoice?.pre_order
                                                    ? translator.t(
                                                          'message.estimation_sent'
                                                      )
                                                    : translator.t(
                                                          'message.estimation_accepted'
                                                      )
                                                : translator.t(
                                                      'payment.remaining_payment'
                                                  )}
                                        </h4>
                                        <h4 className="payment-timer">
                                            {generateTopInfo(
                                                dataInvoice?.payment_status
                                            )}
                                        </h4>
                                    </React.Fragment>
                                )
                            )}
                        </Col>

                        <Col span={12} className="xendit-info-top-transaction">
                            {loadingAll ? (
                                <Skeleton.Input
                                    size="large"
                                    style={{ width: '95%' }}
                                />
                            ) : (
                                <>
                                    <h4 className="id-transaction">
                                        {translator.t('title.id_transaction')}
                                    </h4>
                                    <div className="id-transaction-value">
                                        {dataInvoice?.transaction_code}
                                    </div>
                                </>
                            )}
                        </Col>
                    </Row>
                    {dataInvoice?.payment_status === 5 && !loadingAll && (
                        <div className="notif-transaction-cancel">
                            <div className="notif-label">
                                {translator.t('title.sorry')} :({' '}
                            </div>
                            <div className="notif-title">{`${profile_name}, ${translator.t(
                                'payment.canceled_info'
                            )}`}</div>
                            <p className="notif-desc">
                                {translator.t('payment.crossed_message')}
                            </p>
                        </div>
                    )}
                    {waybillState?.isActive && (
                        <div className="xendit-info-shipping">
                            <Skeleton loading={loadingAll}>
                                <React.Fragment>
                                    <div className="xendit-info-shipping-list">
                                        <Image
                                            src={ic_status}
                                            alt={`${BASE_TITLE} Preview Images`}
                                            preview={false}
                                            className="info-image"
                                        />
                                        <div className="info-content">
                                            <div className="info-title">
                                                <h4>
                                                    {translator.t(
                                                        'title.status_shipping'
                                                    )}
                                                </h4>
                                            </div>
                                            <div className="info-collapse">
                                                <Collapse
                                                    expandIcon={() => <></>}
                                                    bordered={false}
                                                    activeKey={
                                                        waybillState?.activeKey
                                                    }
                                                >
                                                    <Panel
                                                        header={
                                                            waybillState?.loading ? (
                                                                <LoadingOutlined />
                                                            ) : waybillState?.activeKey ===
                                                              1 ? (
                                                                <div
                                                                    className="info-action"
                                                                    onClick={() =>
                                                                        dispatchWaybill(
                                                                            {
                                                                                activeKey:
                                                                                    null,
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    <UpOutlined
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <div
                                                                        style={{
                                                                            fontWeight:
                                                                                '600',
                                                                        }}
                                                                    >
                                                                        {waybillState
                                                                            ?.data
                                                                            ?.delivery_status
                                                                            ?.status +
                                                                            ' - ' +
                                                                            waybillState
                                                                                ?.data
                                                                                ?.delivery_status
                                                                                ?.pod_date}
                                                                    </div>
                                                                    <div>
                                                                        {
                                                                            waybillState
                                                                                ?.data
                                                                                ?.delivery_status
                                                                                ?.pod_receiver
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        key={1}
                                                    >
                                                        <div>
                                                            <Timeline
                                                                reverse={true}
                                                                style={{
                                                                    marginTop: 10,
                                                                }}
                                                            >
                                                                {waybillState?.data?.manifest?.map(
                                                                    (
                                                                        data,
                                                                        idx
                                                                    ) => {
                                                                        const currentState =
                                                                            waybillState
                                                                                ?.data
                                                                                ?.manifest
                                                                                ?.length -
                                                                            1
                                                                        return (
                                                                            <Timeline.Item
                                                                                key={
                                                                                    idx
                                                                                }
                                                                                color={
                                                                                    idx ===
                                                                                    currentState
                                                                                        ? '#22cec0'
                                                                                        : '#dadada'
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className={`timeline-item ${
                                                                                        idx ===
                                                                                        currentState
                                                                                            ? 'active'
                                                                                            : ''
                                                                                    }`}
                                                                                >
                                                                                    <div className="timeline-item-date">
                                                                                        {dayjs(
                                                                                            data?.manifest_date +
                                                                                                data?.manifest_time
                                                                                        ).format(
                                                                                            'lll'
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="timeline-item-desc">
                                                                                        {
                                                                                            data?.manifest_description
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Timeline.Item>
                                                                        )
                                                                    }
                                                                )}
                                                            </Timeline>
                                                        </div>
                                                    </Panel>
                                                </Collapse>
                                            </div>
                                            {waybillState?.activeKey !== 1 && (
                                                <div
                                                    className="info-action"
                                                    onClick={() =>
                                                        dispatchWaybill({
                                                            activeKey: 1,
                                                        })
                                                    }
                                                    style={{
                                                        marginTop: '12px',
                                                    }}
                                                >
                                                    <DownOutlined
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </React.Fragment>
                            </Skeleton>
                        </div>
                    )}
                    <div className="xendit-info-bottom">
                        <Skeleton loading={loadingAll}>
                            <Space
                                size={[12, 12]}
                                className="xendit-info-bottom-list"
                            >
                                <Image
                                    src={ic_status}
                                    alt={`${BASE_TITLE} Preview Images`}
                                    preview={false}
                                    className="info-image"
                                />
                                <div className="info-title">
                                    <h4 className="info-title-label">
                                        {translator.t(
                                            'title.status_transaction'
                                        )}
                                    </h4>

                                    <div
                                        className="payment-status"
                                        style={labelStatus?.styleStatus}
                                    >
                                        {labelStatus?.labelStatus}
                                    </div>
                                </div>
                            </Space>

                            <div className="xendit-info-bottom-list">
                                <Image
                                    src={ic_donate}
                                    alt={`${BASE_TITLE} Preview Images`}
                                    preview={false}
                                    className="info-image"
                                />
                                <div
                                    className="info-title"
                                    style={{
                                        marginLeft: 12,
                                        width: '100%',
                                    }}
                                >
                                    <h4 className="info-title-label">
                                        {translator.t('title.detail_payment')}
                                    </h4>

                                    <Collapse
                                        ghost
                                        className="total-payment"
                                        onChange={() =>
                                            setCollasible(!collasible)
                                        }
                                    >
                                        <Panel
                                            showArrow={false}
                                            header={
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'space-between',
                                                        userSelect: 'none',
                                                    }}
                                                >
                                                    <div className="total-payment-label">
                                                        {translator.t(
                                                            'payment.paymnet_price_item'
                                                        )}{' '}
                                                        <span
                                                            style={{
                                                                color:
                                                                    dataInvoice?.payment_status ===
                                                                    5
                                                                        ? COLORS.basered
                                                                        : COLORS.primaryColor,
                                                            }}
                                                        >
                                                            {translator.t(
                                                                'payment.details'
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div
                                                        className="total-payment-price"
                                                        style={{
                                                            color: '#939393',
                                                            marginLeft: 5,
                                                        }}
                                                    >
                                                        <NumberFormat
                                                            value={
                                                                dataInvoice?.amount
                                                            }
                                                            displayType={'text'}
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            prefix={'Rp'}
                                                            suffix=",-"
                                                        />
                                                    </div>
                                                </div>
                                            }
                                            extra={
                                                <div>
                                                    {collasible ? (
                                                        <DownOutlined
                                                            style={{
                                                                fontSize: 13,
                                                                color: COLORS.primaryColor,
                                                            }}
                                                        />
                                                    ) : (
                                                        <RightOutlined
                                                            style={{
                                                                fontSize: 13,
                                                                color: COLORS.primaryColor,
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            }
                                        >
                                            {dataOrder?.map((item, key) => {
                                                return (
                                                    <Row
                                                        key={key}
                                                        style={{
                                                            marginBottom: 5,
                                                        }}
                                                    >
                                                        <Col
                                                            span={16}
                                                            style={{
                                                                display: 'flex',
                                                                fontSize:
                                                                    '0.8rem',
                                                                color: '#939393',
                                                            }}
                                                        >
                                                            {generateContent(
                                                                item
                                                            )}
                                                        </Col>
                                                        <Col
                                                            span={8}
                                                            style={{
                                                                textAlign:
                                                                    'right',
                                                                fontSize:
                                                                    '0.8rem',
                                                                color: '#939393',
                                                            }}
                                                        >
                                                            <NumberFormat
                                                                value={
                                                                    item?.number_of_copy *
                                                                    item?.price
                                                                }
                                                                displayType={
                                                                    'text'
                                                                }
                                                                thousandSeparator={
                                                                    true
                                                                }
                                                                prefix={'Rp'}
                                                                suffix=",-"
                                                            />
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                        </Panel>
                                    </Collapse>
                                    <Row
                                        style={{
                                            marginBottom: 5,
                                        }}
                                    >
                                        <Col
                                            span={16}
                                            style={{
                                                display: 'flex',
                                                fontSize: '0.8rem',
                                                color: '#939393',
                                            }}
                                        >
                                            {translator.t(
                                                'payment.discount_amount'
                                            )}
                                        </Col>
                                        <Col
                                            span={8}
                                            style={{
                                                textAlign: 'right',
                                                fontSize: '0.8rem',
                                                color: '#939393',
                                            }}
                                        >
                                            <NumberFormat
                                                value={
                                                    dataInvoice?.discount_amount
                                                }
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'Rp'}
                                                suffix=",-"
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginBottom: 5,
                                        }}
                                    >
                                        <Col
                                            span={16}
                                            style={{
                                                display: 'flex',
                                                fontSize: '0.8rem',
                                                color: '#939393',
                                            }}
                                        >
                                            {translator.t('payment.serviceFee')}
                                        </Col>
                                        <Col
                                            span={8}
                                            style={{
                                                textAlign: 'right',
                                                fontSize: '0.8rem',
                                                color: '#939393',
                                            }}
                                        >
                                            <NumberFormat
                                                value={
                                                    dataInvoice?.service_fee_amount
                                                }
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'Rp'}
                                                suffix=",-"
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginBottom: 5,
                                        }}
                                    >
                                        <Col
                                            span={16}
                                            style={{
                                                display: 'flex',
                                                fontSize: '0.8rem',
                                                color: '#939393',
                                            }}
                                        >
                                            {translator.t('payment.taxAmount')}
                                        </Col>
                                        <Col
                                            span={8}
                                            style={{
                                                textAlign: 'right',
                                                fontSize: '0.8rem',
                                                color: '#939393',
                                            }}
                                        >
                                            <NumberFormat
                                                value={dataInvoice?.tax_amount}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'Rp'}
                                                suffix=",-"
                                            />
                                        </Col>
                                    </Row>
                                    {/* <Row
                                        style={{
                                            marginBottom: 5,
                                        }}
                                    >
                                        <Col
                                            span={16}
                                            style={{
                                                display: 'flex',
                                                fontSize: '0.8rem',
                                                color: '#939393',
                                            }}
                                        >
                                            {translator.t(
                                                'title.shipping_cost'
                                            )}
                                        </Col>
                                        <Col
                                            span={8}
                                            style={{
                                                textAlign: 'right',
                                                fontSize: '0.8rem',
                                                color: '#939393',
                                            }}
                                        >
                                            <NumberFormat
                                                value={
                                                    dataInvoice?.shipping_fee_amount
                                                }
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'Rp'}
                                                suffix=",-"
                                            />
                                        </Col>
                                    </Row> */}

                                    <Divider style={{ margin: '15px 0' }} />
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <h4>
                                            {translator.t(
                                                'payment.boxordertitle'
                                            )}
                                        </h4>
                                        <h4>
                                            <NumberFormat
                                                value={
                                                    dataInvoice?.amount -
                                                    dataInvoice?.discount_amount +
                                                    dataInvoice?.service_fee_amount +
                                                    dataInvoice?.tax_amount
                                                    // dataInvoice?.shipping_fee_amount
                                                }
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'Rp'}
                                                suffix=",-"
                                            />
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="xendit-info-bottom-list">
                                <div>
                                    <Image
                                        src={ic_status}
                                        alt={`${BASE_TITLE} Preview Images`}
                                        preview={false}
                                        className="info-image"
                                    />
                                </div>
                                <div
                                    className="info-title"
                                    style={{
                                        width: '100%',
                                        marginLeft: 12,
                                    }}
                                >
                                    <h4 className="info-title-label">
                                        {translator.t(
                                            'payment.payment_purpose'
                                        )}
                                    </h4>

                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: 'rgba(0, 0, 0, 0.85)',
                                                fontWeight: 600,
                                                fontSize: '18px',
                                            }}
                                        >
                                            {dataInvoice?.display_name}
                                        </div>
                                        <div>
                                            <Image
                                                src={dataInvoice?.icon_url}
                                                alt={`${BASE_TITLE} Preview Images`}
                                                preview={false}
                                                className="payment-icon"
                                                width={50}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {dataInvoice?.payment_status === 1 &&
                                source !== 'methodCash' && (
                                    <Space
                                        size={[12, 12]}
                                        className="xendit-info-bottom-list"
                                    >
                                        <Image
                                            src={ic_cancel_info}
                                            alt={`${BASE_TITLE} Preview Images`}
                                            preview={false}
                                            className="info-image"
                                        />
                                        <div className="info-title">
                                            <h4 className="info-title-label">
                                                {translator.t(
                                                    'payment.cancel_info'
                                                )}
                                            </h4>
                                            <p className="info-description">
                                                {translator.t(
                                                    'message.cancel_information'
                                                )}
                                            </p>
                                        </div>
                                    </Space>
                                )}
                            {dataInvoice?.payment_status === 2 && (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: '#F9F9F9',
                                        padding: 10,
                                    }}
                                >
                                    <Paragraph
                                        ellipsis={{ rows: 2 }}
                                        style={{
                                            fontSize: 12,
                                            margin: 0,
                                            textAlign: 'center',
                                        }}
                                    >
                                        {translator.t('message.payment_tips')}
                                    </Paragraph>
                                </div>
                            )}
                        </Skeleton>
                    </div>
                </div>
            </React.Fragment>
            <Modal
                visible={receivedState?.visible}
                title={translator.t('payment.received_confirm_title')}
                onCancel={() => {
                    dispatchReceived({
                        visible: false,
                    })
                }}
                maskClosable={false}
                footer={
                    <div>
                        <Button
                            type="primary"
                            onClick={handleOnConfirm}
                            loading={receivedState?.loading}
                        >
                            {translator.t('action.confirm')}
                        </Button>
                        <Button
                            danger
                            onClick={() => handleComplaint('store')}
                            disabled={receivedState?.loading}
                        >
                            {translator.t('payment.complaint')}
                        </Button>
                        <Button
                            onClick={() => {
                                dispatchReceived({
                                    visible: false,
                                })
                            }}
                            disabled={receivedState?.loading}
                        >
                            {translator.t('action.close')}
                        </Button>
                    </div>
                }
            >
                <React.Fragment>
                    <div>
                        {translator.t('payment.received_confirm_message')}
                    </div>
                </React.Fragment>
            </Modal>
        </Modal>
    )
}

export default ModalConfirmPayment
