import React, { useEffect, useState, useLayoutEffect } from 'react'
import {
    Card,
    Col,
    Row,
    Rate,
    Space,
    Divider,
    Tabs,
    Skeleton,
    Tooltip,
    message,
} from 'antd'
import { Helmet } from 'react-helmet'
import { FallbackImage } from 'components'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import isNull from 'lodash/isNull'
import provider from 'providers/provider'
import url from 'constants/url'
import translator from 'i18next'
import ReadMore from 'utils/ReadMore'
import {
    ic_reader_list,
    ic_open_free,
    ic_open_free_skeleton,
    ic_drm,
} from 'assets/icons'
import { LoadingOutlined } from '@ant-design/icons'
import Paragraph from 'antd/lib/typography/Paragraph'
import { BASE_TITLE } from 'constants/constant'
import capitalize from 'lodash/capitalize'

const { TabPane } = Tabs

const bookDetailPublic = (props) => {
    const bookId = get(props?.match?.params, 'uuid', '')

    const [state, setState] = useState({
        width: window.innerWidth,
    })
    const [loading, setLoading] = useState(false)
    const [bookDetailData, setBookDetailData] = useState({})

    const isMobile = () => state.width <= 360
    const isMiddle = () => state.width <= 485

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    const getData = async () => {
        setLoading(true)
        await provider
            .singleWebHook(
                `${url.webhookURL_public_book_detail}?book_id=${bookId}`
            )
            .then((res) => {
                setBookDetailData(res?.data)
            })
            .catch(() => {
                message?.warning(translator.t('notification.bookNotFound'))
                props?.history?.replace('/')
            })
        setLoading(false)
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    useEffect(() => {
        if (bookId) {
            getData()
        }
    }, [bookId])

    const DrmImage = () => {
        if (loading) {
            return (
                <img
                    style={{ width: '2.2rem' }}
                    src={ic_open_free_skeleton}
                    alt={`${BASE_TITLE} preview images - platform edukasi indonesia`}
                />
            )
        } else {
            return (
                <img
                    style={{ width: '2.2rem' }}
                    src={
                        get(bookDetailData, 'using_drm', true) === false
                            ? ic_open_free
                            : ic_drm
                    }
                    alt={`${BASE_TITLE} preview images - platform edukasi indonesia`}
                />
            )
        }
    }

    return (
        <div className="db-container">
            <Helmet>
                <title>
                    {capitalize(get(bookDetailData, 'book_title', ''))} -{' '}
                    {BASE_TITLE}
                    Website
                </title>
                <meta
                    name="og:title"
                    content={`${BASE_TITLE} Website - Platform Edukasi Indonesia`}
                />
                <meta
                    name="description"
                    content="Book Page - Mari bergabung dan berkolaborasi mengembangkan pendidikan Indonesia bersama anak bangsa."
                />
                <meta
                    property="image"
                    content={get(bookDetailData, 'cover_url', '')}
                />
            </Helmet>
            <Card
                className="detail-card"
                title={translator.t('title.book_detail')}
            >
                <div style={{ display: 'flex' }}>
                    <div className="cover-image">
                        {!loading ? (
                            <FallbackImage
                                src={get(bookDetailData, 'cover_url', '')}
                                className="cover-book"
                            />
                        ) : (
                            <Skeleton.Input
                                loading={loading}
                                active
                                style={{
                                    height: '220px',
                                    width: '150px',
                                    borderRadius: '15px',
                                }}
                                size="default"
                            />
                        )}
                    </div>
                    <div className="description-book">
                        <Skeleton loading={loading} active>
                            <h2 className="book-title">
                                <Paragraph
                                    style={{ marginBottom: 0 }}
                                    ellipsis={
                                        true
                                            ? {
                                                  rows: isMobile()
                                                      ? 2
                                                      : isMiddle()
                                                      ? 3
                                                      : 2,
                                              }
                                            : false
                                    }
                                >
                                    <Tooltip
                                        title={get(
                                            bookDetailData,
                                            'book_title',
                                            ''
                                        )}
                                    >
                                        {get(bookDetailData, 'book_title', '')}
                                    </Tooltip>
                                </Paragraph>
                            </h2>
                            <Tooltip
                                title={get(
                                    bookDetailData,
                                    'book_author',
                                    'Voluptas atque iusto distinctio odit'
                                )}
                            >
                                <Paragraph
                                    className="book-author"
                                    ellipsis={
                                        true
                                            ? {
                                                  rows: 2,
                                              }
                                            : false
                                    }
                                >
                                    {get(
                                        bookDetailData,
                                        'book_author',
                                        'Voluptas atque iusto distinctio odit'
                                    )}
                                </Paragraph>
                            </Tooltip>
                            <div>
                                <Rate
                                    className="rating-book"
                                    disabled
                                    defaultValue={get(
                                        bookDetailData,
                                        'avg_rating',
                                        0
                                    )}
                                    value={get(bookDetailData, 'avg_rating', 0)}
                                    allowHalf
                                />
                                <span className="rating">
                                    {!isNull(
                                        get(bookDetailData, 'avg_rating', 67.56)
                                    ) &&
                                        get(
                                            bookDetailData,
                                            'avg_rating',
                                            67.56
                                        ).toFixed(1)}
                                </span>
                            </div>
                            <br />
                        </Skeleton>
                    </div>
                </div>
                <Row className="specification-book" justify="space-around">
                    <Col
                        lg={8}
                        md={8}
                        sm={8}
                        xs={8}
                        className="book-specification-detail"
                    >
                        <Space>
                            <DrmImage />
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginLeft: isMiddle() ? 0 : 12,
                                }}
                            >
                                <span className="file-size-title">
                                    {translator.t('title.file_size')}
                                </span>
                                <br />
                                {loading ? (
                                    <LoadingOutlined />
                                ) : (
                                    <span
                                        className="file-size"
                                        style={{
                                            fontSize: isMiddle() && '9pt',
                                        }}
                                    >
                                        {get(
                                            bookDetailData,
                                            'file_size_info',
                                            '0 MB'
                                        ) ?? '0 MB'}
                                    </span>
                                )}
                            </div>
                        </Space>
                    </Col>
                    <Col
                        lg={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className="book-specification-detail"
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <Space>
                            <img
                                src={
                                    loading
                                        ? ic_open_free_skeleton
                                        : ic_reader_list
                                }
                                alt={`${BASE_TITLE} preview images - platform edukasi indonesia`}
                            />
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginLeft: !isMiddle() && 12,
                                }}
                            >
                                <span className="file-size-title">
                                    {translator.t('title.readed_by')}
                                </span>
                                <br />
                                <span className="file-size">
                                    {bookDetailData.all_user_borrow_count}{' '}
                                    {translator.t('title.users')}
                                </span>
                            </div>
                        </Space>
                    </Col>
                </Row>
                <Divider />
                <Row className="description-book-tabs">
                    <Col className="tab" span={24}>
                        <Tabs defaultActiveKey="1" centered>
                            <TabPane
                                tab={translator.t('title.description')}
                                style={{ marginTop: 15 }}
                                key="1"
                            >
                                {loading ? (
                                    <Skeleton.Input active />
                                ) : (
                                    <ReadMore
                                        text={get(
                                            bookDetailData,
                                            'book_description',
                                            'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
                                        )}
                                    />
                                )}
                            </TabPane>
                            <TabPane
                                tab={translator.t('title.detail')}
                                key="2"
                                style={{ marginTop: 15 }}
                            >
                                <table className="table-detail-book">
                                    <tbody>
                                        {/* <tr>
                                            <th className="table-title">
                                                {translator.t(
                                                    'message.book_citation'
                                                )}
                                            </th>
                                            <th className="table-title-value active-color">
                                                {translator.t(
                                                    'message.data_citation',
                                                    {
                                                        count: get(
                                                            bookDetailData,
                                                            'citation_count',
                                                            '0'
                                                        ),
                                                        user: get(
                                                            bookDetailData,
                                                            'citation_user_count',
                                                            '0'
                                                        ),
                                                    }
                                                )}
                                            </th>
                                        </tr> */}
                                        <tr>
                                            <th className="table-title">
                                                {translator.t('filter.author')}
                                            </th>
                                            <th className="table-title-value active-color">
                                                {get(
                                                    bookDetailData,
                                                    'book_author',
                                                    'Lorem ipsum dolor'
                                                )}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="table-title">
                                                {translator.t(
                                                    'filter.publisher'
                                                )}
                                            </th>
                                            <th className="table-title-value active-color">
                                                {get(
                                                    bookDetailData,
                                                    'catalog_info.organization_name',
                                                    '-'
                                                )}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="table-title">
                                                {translator.t('filter.year')}
                                            </th>
                                            <th className="table-title-value active-color">
                                                {isNull(
                                                    get(
                                                        bookDetailData,
                                                        'publish_date',
                                                        '-'
                                                    )
                                                )
                                                    ? '-'
                                                    : get(
                                                          bookDetailData,
                                                          'publish_date',
                                                          '-'
                                                      )}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="table-title">
                                                ISBN
                                            </th>
                                            <th className="table-title-value">
                                                {isNull(
                                                    get(
                                                        bookDetailData,
                                                        'book_isbn',
                                                        '-'
                                                    )
                                                ) |
                                                (get(
                                                    bookDetailData,
                                                    'book_isbn',
                                                    '-'
                                                ) ===
                                                    '')
                                                    ? '-'
                                                    : get(
                                                          bookDetailData,
                                                          'book_isbn',
                                                          '-'
                                                      )}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="table-title">
                                                {translator.t(
                                                    'filter.category'
                                                )}
                                            </th>
                                            <th className="table-title-value active-color">
                                                {get(
                                                    bookDetailData,
                                                    'category_name',
                                                    '-'
                                                )}
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>

                <div
                    style={{
                        display: !isMiddle() && 'none',
                    }}
                    className="fixed"
                >
                    <Row
                        wrap
                        gutter={bookDetailData?.using_drm ? [8, 8] : []}
                    ></Row>
                </div>
            </Card>
        </div>
    )
}

export default withRouter(bookDetailPublic)
