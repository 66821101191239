import React, { useState } from 'react'
import { Row, Col, Collapse } from 'antd'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import howto from './howto.json'

const { Panel } = Collapse

const Help = () => {
    const [state, setState] = useState([''])

    const callback = (key) => {
        setState(key)
    }

    return (
        <Row justify="center">
            <Col xs={24} sm={24} md={20} lg={16} xl={16} className="howto">
                <Collapse
                    defaultActiveKey={['1']}
                    onChange={callback}
                    className="howto-collapse"
                    expandIconPosition="right"
                    activeKey={state}
                    expandIcon={(e) =>
                        e?.isActive ? <MinusOutlined /> : <PlusOutlined />
                    }
                >
                    {howto.data.map((item) => (
                        <Panel header={item?.title} key={item?.id}>
                            <iframe
                                src={item?.document}
                                width="870"
                                height="530"
                                className="frameHowto"
                            />
                        </Panel>
                    ))}
                </Collapse>
            </Col>
        </Row>
    )
}

export default Help
