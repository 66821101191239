import getEnv from 'utils/getEnv.js'

export const API_BASE_URL = getEnv('REACT_APP_BASE_URL')

export const API_BASE_URL_2 = getEnv('REACT_APP_BASE_URL_2')

export const BASE_URL_WEB = getEnv('REACT_APP_BASE_URL_WEB')

export const API_MCCP = getEnv('REACT_APP_MCCP_URL')

export const GRAPHQL_BASE_URL = `${getEnv('REACT_APP_BASE_URL')}/graphql`

export const API_STORAGE_URL = getEnv('REACT_APP_STORAGE_URL')

export const MAX_REQUESTS_COUNT = 1

export const INTERVAL_MS = 10

export const ORGANIZATION_ID = getEnv('REACT_APP_ORGANIZATION_ID')

export const GOOGLE_CLIENT_ID = getEnv('REACT_APP_GOOGLE_CLIENT_ID')

export const FIREBASE_VALIDKEY = getEnv('REACT_APP_VALID_APP_FIREBASE')
