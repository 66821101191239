import {
    Button,
    Checkbox,
    Switch,
    Col,
    Form,
    Row,
    List,
    Spin,
    Input,
} from 'antd'
import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import translator from 'i18next'
import switchAction from 'redux/bookFilterSwitch/action'
import isEmpty from 'lodash/isEmpty'

const FilterBookDropdown = (props) => {
    const {
        handleFinish,
        form,
        totalData,
        reseltFilter,
        hasFilter,
        fromHome,
        switchActive,
        handleSearch,
        onClearSearch,
        dataCategories,
        categoryPagination,
        hasSearch,
        loadMoreCategory,
        loading,
        dataOptionAuthor,
        hasMoreAuthor,
        loadingAuthor,
        loadMoreAuthor,
        dataOptionYear,
    } = props

    return (
        <div className="modal-filter">
            <Row>
                <Col lg={8} md={8} sm={8} xs={11}>
                    <Input
                        placeholder={translator.t('action.searchGenre')}
                        onPressEnter={(e) => handleSearch('category', e)}
                        suffix={<SearchOutlined />}
                        allowClear
                        onChange={(e) => {
                            onClearSearch('category', e)
                        }}
                    />
                </Col>
                <Col lg={8} md={8} sm={8} xs={13}>
                    <Input
                        placeholder={translator.t('action.searchAuthor')}
                        onPressEnter={(e) => handleSearch('author', e)}
                        suffix={<SearchOutlined />}
                        allowClear
                        onChange={(e) => onClearSearch('author', e)}
                    />
                </Col>
                <Col lg={8} md={8} sm={8} xs={11}>
                    <Input
                        placeholder={translator.t('action.searchYear')}
                        onPressEnter={(e) => handleSearch('year', e)}
                        suffix={<SearchOutlined />}
                        allowClear
                        onChange={(e) => onClearSearch('year', e)}
                    />
                </Col>
            </Row>
            <Form onFinish={handleFinish} form={form}>
                <Row>
                    <Col lg={8} md={8} sm={8} xs={11}>
                        <Form.Item name="category_id">
                            <Checkbox.Group className="checkbox-group">
                                <List
                                    bordered
                                    dataSource={dataCategories?.list}
                                    renderItem={(item, key) => {
                                        return (
                                            <List.Item key={key}>
                                                <Checkbox value={item?.id}>
                                                    {item?.category_name}
                                                </Checkbox>
                                            </List.Item>
                                        )
                                    }}
                                    footer={
                                        categoryPagination?.loadMore &&
                                        !isEmpty(dataCategories?.list) &&
                                        dataCategories?.total >
                                            dataCategories?.list?.length ? (
                                            <div
                                                style={{
                                                    display:
                                                        !hasSearch && 'none',
                                                }}
                                            >
                                                <Button
                                                    type="primary"
                                                    onClick={loadMoreCategory}
                                                    loading={loading}
                                                >
                                                    Load More
                                                </Button>
                                            </div>
                                        ) : (
                                            ''
                                        )
                                    }
                                    className="list-scroll"
                                    loadMore
                                ></List>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                    <Col lg={8} md={8} sm={8} xs={13}>
                        <Form.Item name="author_name">
                            <Checkbox.Group className="checkbox-group">
                                <List
                                    bordered
                                    dataSource={dataOptionAuthor}
                                    renderItem={(item, key) => (
                                        <List.Item key={key}>
                                            <Checkbox value={item.text}>
                                                {item.text}
                                            </Checkbox>
                                        </List.Item>
                                    )}
                                    footer={
                                        hasMoreAuthor &&
                                        !loadingAuthor &&
                                        !isEmpty(dataOptionAuthor) ? (
                                            <div>
                                                <Button
                                                    type="primary"
                                                    onClick={loadMoreAuthor}
                                                >
                                                    Load More
                                                </Button>
                                            </div>
                                        ) : (
                                            ''
                                        )
                                    }
                                    className="list-scroll"
                                    loadMore
                                >
                                    {loadingAuthor && (
                                        <div className="demo-loading-container">
                                            <Spin />
                                        </div>
                                    )}
                                </List>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>

                    <Col lg={8} md={8} sm={8} xs={11}>
                        <Form.Item name="catalog_publish_date">
                            <Checkbox.Group className="checkbox-group">
                                <List
                                    bordered
                                    dataSource={dataOptionYear}
                                    renderItem={(item, key) => (
                                        <List.Item key={key}>
                                            <Checkbox value={item}>
                                                {item}
                                            </Checkbox>
                                        </List.Item>
                                    )}
                                    className="list-scroll"
                                    loadMore
                                ></List>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <div className="entry" style={{ textAlign: 'start' }}>
                            <Form.Item
                                name="entry"
                                label={
                                    <h4 style={{ margin: 0 }}>
                                        {translator.t(
                                            'action.borrowWithoutEntry'
                                        )}
                                    </h4>
                                }
                                valuePropName="checked"
                            >
                                <Switch
                                    defaultChecked={
                                        switchActive?.entry || false
                                    }
                                    onChange={(e) => {
                                        switchAction.setSwitchActive(e)
                                    }}
                                />
                            </Form.Item>
                            <span className="subtitle">
                                {translator.t('action.borrowWithoutEntryDesc')}
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row align="middle">
                    <Col span={12} style={{ textAlign: 'start' }}>
                        {translator.t('filter.result')}:{' '}
                        {totalData === 0 || (!hasFilter && fromHome)
                            ? '0'
                            : `${totalData} ${translator.t(
                                  'action.resultFound'
                              )}`}
                    </Col>
                    <Col
                        span={12}
                        style={{
                            textAlign: 'end',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <div
                            type="text"
                            size="large"
                            onClick={reseltFilter}
                            className="btn-reset"
                        >
                            {translator.t('action.clear')}
                        </div>
                        <Form.Item>
                            <Button
                                type="primary"
                                style={{ borderRadius: 20, width: 120 }}
                                size="large"
                                htmlType="submit"
                            >
                                OK
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default FilterBookDropdown
