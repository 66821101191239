import authAction from 'redux/auth/action'
import request from 'helpers/request'
import { error } from 'utils/notification'
import Cookies from 'js-cookie'
import HandleLogin from 'utils/handleLogin'
import axios from 'axios'
import url from 'constants/url'
import { ORGANIZATION_ID } from 'helpers/constants'

const base_url = window.location.origin
const CALLBACK_URL = `${base_url}/auth-socmed`

export default {
    login: async (params, type = '') => {
        if (type !== 'socmed') {
            //clear cached data previous user
            localStorage.clear()
        }

        const tempParams = { ...params, email: params.email.toLowerCase() }
        const res = await request.post(`${url.login}`, {
            ...tempParams,
        })
        await HandleLogin(res)
    },

    register: async (params) => {
        const option = {
            provider: 'local',
            data: {
                email: params.email.toLowerCase(),
                password: params.password,
                extras: {
                    name: params.name,
                    address: params.address,
                    phone: params.phone,
                    district_id: params.district_id,
                    organization_id: ORGANIZATION_ID,
                },
            },
        }
        const res = await request.post(url.register, option)
        authAction.setAuth(res.data)
    },

    register_school: async (params, url) => {
        const result = await axios.post(url, params, {
            'Content-Type': 'application/json',
        })
        return result
    },

    validateEmail: async (email) => {
        const url = 'services/auth/account'

        const reg = await request.get(`${url}?email=${email}`)

        return reg
    },

    validateNPSN: async (npsn) => {
        const response = await request.getnotoken(
            `${url.webhookURL_school}?npsn=${npsn}`
        )

        return response
    },

    socmed: async (params) => {
        const { data } = await request.get(
            `services/auth?provider=${params}&callback=${CALLBACK_URL}`
        )
        return data
    },

    resendVerification: async (params) => {
        const data = {
            email: params,
        }
        await request
            .post(url.resendVerification, data)
            .then((res) => {
                return res
            })
            .catch((err) =>
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            )
    },

    loginSocmed: async (params) => {
        const url = 'services/auth'
        return await request.post(url, params)
    },

    logout: async () => {
        Cookies.remove('ewAccessToken')
        await authAction.clearAuth()
    },
}
