import React, { useState } from 'react'
import { Input, Image, Button, Typography } from 'antd'
import translator from 'i18next'

import { BASE_TITLE } from 'constants/constant'
import NumberFormat from 'react-number-format'
import ModalBookDonation from 'views/donation/components/ModalBookDonation'

const { Paragraph } = Typography
const { Search } = Input
const BookDonationProgram = (props) => {
  const {
    limit,
    setLimit,
    setBookSearch,
    bookDonation,
    setVisible,
    getOrderList,
  } = props

  const [visibleBookDonation, setVisibleBookDonation] = useState(false)
  const [bookDetailData, setBookDetailData] = useState({})

  const loadMore = () => {
    if (bookDonation.total > limit) {
      return (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Button
            loading={bookDonation.loading}
            onClick={() => {
              bookDonation.total > limit && setLimit((e) => e + 10)
            }}
          >
            {translator.t('action.more')}
          </Button>
        </div>
      )
    } else {
      return <></>
    }
  }

  return (
    <div className="book-donation-program-wrapper">
      <div>
        <Search
          className="donation-list-search"
          placeholder={translator.t('Cari donasi')}
          loading={bookDonation.loading}
          enterButton
          onSearch={(e) => setBookSearch(e)}
          allowClear
        />
        <div className="filter-sorter-book-donation">
          <div>
            {translator.t('Total koleksi buku') + ' ' + bookDonation.total}
          </div>
          {/* <div>Urutkan</div> */}
        </div>
      </div>

      {bookDonation.data?.map((item, key) => {
        return (
          <div key={key} className="book-list-donation">
            <Image
              src={item?.cover_url}
              preview={false}
              alt={`${BASE_TITLE} Preview Images`}
              className="image-book-donation"
              width={110}
            />

            <div className="book-info">
              <div className="book-list-desc">
                <h4 className="book-list-title">
                  <Paragraph
                    ellipsis={true ? { rows: 2, expandable: false } : false}
                    style={{ marginBottom: 0 }}
                  >
                    {item?.book_title}
                  </Paragraph>
                </h4>
                <div className="book-list-author">{item?.author_name}</div>
              </div>
              <div className="book-list-action">
                <div className="book-price">
                  <NumberFormat
                    value={item?.price}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'Rp'}
                    suffix=",-"
                  />
                </div>
                <Button
                  type="primary"
                  onClick={() => {
                    setBookDetailData(item)
                    setVisibleBookDonation(true)
                    setVisible(false)
                  }}
                >
                  + Donasi
                </Button>
              </div>
            </div>
          </div>
        )
      })}
      {loadMore()}

      <ModalBookDonation
        visible={visibleBookDonation}
        setVisible={setVisibleBookDonation}
        bookDetailData={bookDetailData}
        setVisibleDonationProgram={setVisible}
        getOrderList={getOrderList}
      />
    </div>
  )
}

export default BookDonationProgram
