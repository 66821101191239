import React, { useEffect, useState } from 'react'
import { Button, List, Modal } from 'antd'
import translator from 'i18next'
import provider from 'providers/provider'
import url from 'constants/url'
import Avatar from 'antd/lib/avatar/avatar'
import { useHistory } from 'react-router'
import getAcronym from 'components/Acronym'
import { ArrowDownOutlined } from '@ant-design/icons'

const ModalMemberEpustka = ({ epustakaId, visible, setVisible }) => {
    const history = useHistory()
    const [limit, setLimit] = useState(20)
    const [loadingLoadMore, setLoadingLoadMore] = useState(false)
    const [totalData, setTotalData] = useState(0)
    const [dataMember, setDataMember] = useState([])
    const getMember = async () => {
        setLoadingLoadMore(true)
        await provider
            .list(
                url.webhookURL_epustaka_member_list +
                    `?limit=${limit}&status=1&epustaka_id=${epustakaId}`
            )
            .then((res) => {
                setTotalData(res.meta.total)
                setDataMember(res.data)
                setLoadingLoadMore(false)
            })
    }

    useEffect(() => {
        getMember()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit])

    useEffect(() => {
        setLimit(20)
    }, [])

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={loadingLoadMore}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 25)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }

    return (
        <>
            <Modal
                visible={visible}
                onOk={() => setVisible(!visible)}
                onCancel={() => setVisible(!visible)}
                title={translator.t('title.epustakaMember')}
                className="modal-detail-epustaka"
                footer={null}
            >
                <List
                    style={{ maxHeight: '55vh', overflow: 'auto' }}
                    itemLayout="horizontal"
                    dataSource={dataMember}
                    renderItem={(item) => {
                        const { name, avatar_url, id } = item
                        const { color, acronym } = getAcronym(name || '', id)
                        return (
                            <List.Item>
                                <List.Item.Meta
                                    avatar={
                                        <>
                                            <div className="modal-detail-epustaka-item">
                                                <Avatar
                                                    size="large"
                                                    style={{
                                                        cursor: 'pointer',
                                                        backgroundImage: color,
                                                    }}
                                                    src={avatar_url}
                                                    onClick={() =>
                                                        history.push(
                                                            `/profil/user/${id}`
                                                        )
                                                    }
                                                >
                                                    <span>{acronym}</span>
                                                </Avatar>
                                                <p>{name}</p>
                                            </div>
                                        </>
                                    }
                                />
                            </List.Item>
                        )
                    }}
                >
                    <div className="display-btn">
                        <DispalyButton />
                    </div>
                </List>
            </Modal>
        </>
    )
}

export default ModalMemberEpustka
