import React, { useState, useLayoutEffect } from 'react'
import { Row } from 'antd'
import translator from 'i18next'
import { BookCardComponent } from 'views/home/books'
import map from 'lodash/map'
import { NavigationHome, SkeletonBook } from 'components'
import Carousel from 'nuka-carousel'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Lazy } from 'swiper/core'

SwiperCore.use([Pagination, Lazy])

const BookSliderSection = (props) => {
    const {
        loading,
        data,
        detailUrl,
        title,
        style,
        fromBookDetail,
        onRedirect,
    } = props

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 420
    const is540s = () => state.width <= 540
    const isTablet = () => state.width <= 768
    const isDesktop = () => state.width <= 1024
    const S10inch = () => state.width <= 1270
    const xlDesktop = () => state.width <= 1440
    const xxlDesktop = () => state.width <= 1920
    const desktop4K = () => state.width <= 2560
    const UHD4k = () => state.width >= 2561

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const responsiveSlider = {
        320: {
            slidesPerView: 1.4,
        },
        360: {
            slidesPerView: 2,
        },
        411: {
            slidesPerView: 2.1,
        },
        420: {
            slidesPerView: 2.1,
        },
        540: {
            slidesPerView: 2.6,
        },
        640: {
            slidesPerView: 3.2,
        },
        768: {
            slidesPerView: 3.8,
        },
        884: {
            slidesPerView: 4,
        },
        1024: {
            slidesPerView: 4.9,
        },
    }

    const fromDetailBook = isMobile()
        ? 0.55
        : is540s()
        ? 0.4
        : isTablet()
        ? 0.31
        : isDesktop()
        ? 0.27
        : S10inch()
        ? 0.29
        : xlDesktop()
        ? 0.23
        : xxlDesktop()
        ? 0.23
        : desktop4K()
        ? 0.25
        : UHD4k()
        ? 0.25
        : 0.25

    return (
        <div className="bs-container" style={style}>
            {loading ? (
                <SkeletonBook />
            ) : (
                <div>
                    {fromBookDetail ? (
                        <NavigationHome
                            title={translator.t(title)}
                            detailUrl={detailUrl}
                            fromBookDetail
                        />
                    ) : (
                        <NavigationHome
                            title={translator.t(title)}
                            detailUrl={detailUrl}
                            // fromBookDetail
                        />
                    )}

                    <br />
                    <Row
                        style={{
                            marginBottom: 20,
                            marginTop: -10,
                        }}
                    >
                        {fromBookDetail ? (
                            <Carousel
                                speed={50}
                                withoutControls={true}
                                disableEdgeSwiping={false}
                                slidesToScroll="auto"
                                scrollMode="remainder"
                                slideWidth={fromDetailBook}
                                cellAlign="left"
                            >
                                {map(data, (item, id) => {
                                    return (
                                        <BookCardComponent
                                            key={id}
                                            data={item}
                                            fromBookDetail={fromBookDetail}
                                            onRedirect={onRedirect}
                                        />
                                    )
                                })}
                            </Carousel>
                        ) : (
                            <div className="swiper_container">
                                <Swiper
                                    slidesPerView={1.2}
                                    spaceBetween={10}
                                    preloadImages={false}
                                    lazy={true}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                    }}
                                    breakpoints={responsiveSlider}
                                    className="swiper_container"
                                >
                                    {map(data, (item, id) => {
                                        return (
                                            <SwiperSlide key={id}>
                                                <BookCardComponent
                                                    key={id}
                                                    data={item}
                                                />
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            </div>
                        )}
                    </Row>
                </div>
            )}
        </div>
    )
}

export default BookSliderSection
