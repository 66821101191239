import get from 'lodash/get'
import store from '../redux/store'

export default (source = '', fileType = '', data = {}, mccp) => {
    const { auth = {} } = store.getState()
    const headers = {
        Accept: 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'Access-Control-Allow-Origin': true,
    }
    if (mccp) {
        headers['Accept'] = 'application/json'
        delete headers['Content-Type']
    }

    if (source === 'upload' || source === 'storageclient') {
        delete headers['Accept']
        delete headers['Access-Control-Allow-Origin']
        headers['Content-Type'] = fileType
    }
    if (
        //dont add Authorization on header if refreshing token
        auth.access_token &&
        source !== 'upload' &&
        !mccp &&
        source !== 'storageclient' &&
        !get(data, 'refresh_token', '')
    ) {
        headers.Authorization = `Bearer ${auth.access_token}`
    }

    return headers
}
