import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { bg_white, ic_no_book } from '../assets/icons'

const handleDragStart = (e) => e.preventDefault()

const FallbackImage = (props) => {
    const {
        src,
        alt,
        height = 150,
        width = 150,
        fallbackWidth = 150,
        fallbackHeight = 90,
        onClick,
        style,
        className = '',
        type = 'rectangle',
    } = props
    return isEmpty(props.src) ? (
        <div onClick={onClick}>
            <img
                onDragStart={handleDragStart}
                height={height}
                width={width}
                alt={alt}
                src={type === 'rectangle' ? ic_no_book : bg_white}
                className={className}
                style={{
                    ...style,
                    backgroundImage: `url(${
                        type === 'rectangle' && ic_no_book
                    })`,
                    backgroundSize: 'contain',
                    cursor: 'pointer',
                }}
                onError={(e) => {
                    e.target.src = type === 'rectangle' ? ic_no_book : bg_white
                    e.target.height = fallbackHeight
                    e.target.width = fallbackWidth

                    return e
                }}
            />
        </div>
    ) : (
        <div onClick={onClick}>
            <img
                onDragStart={handleDragStart}
                height={height}
                width={width}
                alt={alt}
                src={src}
                placeholder={type === 'rectangle' ? ic_no_book : bg_white}
                onClick={onClick}
                className={className}
                style={{
                    ...style,
                    backgroundImage: `url(${
                        type === 'rectangle' && ic_no_book
                    })`,
                    backgroundSize: 'contain',
                    cursor: 'pointer',
                }}
                onError={(e) => {
                    e.target.src = type === 'rectangle' && ic_no_book
                    e.target.height = fallbackHeight
                    e.target.width = fallbackWidth

                    return e
                }}
            />
        </div>
    )
}

export default FallbackImage
