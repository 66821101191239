import React from 'react'
import { Row, Skeleton, Col, Space } from 'antd'

export default function SkeletonAudio(props) {
    const source = props.source
    if (source === 'fullskeleton') {
        return (
            <>
                <Row style={{ marginTop: 30 }} justify="center">
                    <Space
                        size={[20, 10]}
                        wrap
                        style={{ justifyContent: 'center' }}
                    >
                        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                            <Skeleton.Input
                                style={{
                                    width: 150,
                                    height: 150,
                                    borderRadius: 10,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{ width: 150, height: 20 }}
                                    active
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                            <Skeleton.Input
                                style={{
                                    width: 150,
                                    height: 150,
                                    borderRadius: 10,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{ width: 150, height: 20 }}
                                    active
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                            <Skeleton.Input
                                style={{
                                    width: 150,
                                    height: 150,
                                    borderRadius: 10,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{ width: 150, height: 20 }}
                                    active
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                            <Skeleton.Input
                                style={{
                                    width: 150,
                                    height: 150,
                                    borderRadius: 10,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{ width: 150, height: 20 }}
                                    active
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                            <Skeleton.Input
                                style={{
                                    width: 150,
                                    height: 150,
                                    borderRadius: 10,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{ width: 150, height: 20 }}
                                    active
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                            <Skeleton.Input
                                style={{
                                    width: 150,
                                    height: 150,
                                    borderRadius: 10,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{ width: 150, height: 20 }}
                                    active
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                            <Skeleton.Input
                                style={{
                                    width: 150,
                                    height: 150,
                                    borderRadius: 10,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{ width: 150, height: 20 }}
                                    active
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                            <Skeleton.Input
                                style={{
                                    width: 150,
                                    height: 150,
                                    borderRadius: 10,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{ width: 150, height: 20 }}
                                    active
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                            <Skeleton.Input
                                style={{
                                    width: 150,
                                    height: 150,
                                    borderRadius: 10,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{ width: 150, height: 20 }}
                                    active
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                            <Skeleton.Input
                                style={{
                                    width: 150,
                                    height: 150,
                                    borderRadius: 10,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{ width: 150, height: 20 }}
                                    active
                                />
                            </div>
                        </Col>
                    </Space>
                </Row>
            </>
        )
    }
    return (
        <Row gutter={[48, 48]} style={{ marginTop: 5 }}>
            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Skeleton.Input style={{ width: 180, height: 200 }} active />
                <div style={{ marginTop: 10 }}>
                    <Skeleton.Input style={{ width: 170, height: 20 }} active />
                </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Skeleton.Input style={{ width: 180, height: 200 }} active />
                <div style={{ marginTop: 10 }}>
                    <Skeleton.Input style={{ width: 170, height: 20 }} active />
                </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Skeleton.Input style={{ width: 180, height: 200 }} active />
                <div style={{ marginTop: 10 }}>
                    <Skeleton.Input style={{ width: 170, height: 20 }} active />
                </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Skeleton.Input style={{ width: 180, height: 200 }} active />
                <div style={{ marginTop: 10 }}>
                    <Skeleton.Input style={{ width: 170, height: 20 }} active />
                </div>
            </Col>
        </Row>
    )
}
