import React from 'react'
import { Form, Input } from 'antd'

import translator from 'i18next'
import map from 'lodash/map'

const InputPassword = (props) => {
    const { onChange, name, schema = {}, formItemProps = {} } = props

    const handleChange = (e) => {
        if (name && onChange) {
            onChange(name, e.target.value, e)
        }
    }

    const fieldSchema = schema[name]
    const label = props.text || fieldSchema.label

    return (
        <Form.Item
            className="form-item"
            name={name}
            label={translator.t(label)}
            rules={map(fieldSchema.rules, (rule) => ({
                ...rule,
                message: translator.t(rule.message),
            }))}
            {...formItemProps}
        >
            {/* 
                cant place label here, will break form function such as setFieldsValue from Form.useForm 
                can only place one children 
            */}
            <Input.Password {...props} onChange={handleChange} />
        </Form.Item>
    )
}

export default InputPassword
