const getEnv = (key, defaultValue = null) => {
    const value = (function (raw) {
        try {
            return JSON.parse(raw)
        } catch (err) {
            return raw || defaultValue
        }
    })(process.env[key])
    return value
}

export default getEnv
