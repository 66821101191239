import React, { useState } from 'react'
import translator from 'i18next'
import formatDuration from 'utils/formatDuration'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { DATE_FULLSTRING_FORMAT } from 'constants/formats'
import { ic_play_video, noimages } from 'assets/icons'
import { BASE_TITLE } from 'constants/constant'
import { Dropdown, Menu, message } from 'antd'
import { ModalShare } from 'components'
import ModalReturnBook from 'views/bookshelf/components/ModalReturnBook'
import { MoreOutlined } from '@ant-design/icons'

const VideoCardCategory = (props) => {
    const {
        data,
        source,
        epustakaId,
        dataEpustaka,
        frombookself,
        getMedia,
        // userId,
    } = props
    const history = useHistory()
    const {
        media_thumbnail_url,
        media_duration,
        media_title,
        created_at,
        id,
        collection_id,
        epustaka_id,
        progress,
    } = data

    const idFilter =
        source === 'video' || source === 'userProfil' ? collection_id : id

    const validateMore =
        source !== 'homevideo' || source !== 'epustakadetail' || frombookself

    const [popShare, setPopShare] = useState(false)
    const [visibleDelete, setVisibleDelete] = useState(false)
    const [mediaItem, setMediaItem] = useState({})

    const handleLinkToDetail = () => {
        if (
            !dataEpustaka?.has_join ||
            (dataEpustaka?.has_join && dataEpustaka?.member_status !== 1)
        ) {
            if (dataEpustaka?.member_status === 3) {
                message.warning(translator.t('message.notifExpand'))
            } else {
                message.warning(translator.t('message.notif'))
            }
        } else {
            history.push(`/video/${idFilter}/${epustakaId}`)
        }
    }

    const handleSourceLink = () => {
        if (source === 'video') {
            history.push({
                pathname: `/video/${idFilter}/${epustaka_id}/${progress || 0}`,
            })
        } else if (source === 'userProfil') {
            history.push({
                pathname: `/video/${idFilter}/${epustaka_id}`,
            })
        } else if (source === 'homevideo') {
            history.push(`/video/${id}`)
        } else {
            handleLinkToDetail()
        }
    }

    const handleClickShare = (value) => {
        setMediaItem(value)
        setPopShare(true)
    }

    const handleClickDelete = (value) => {
        setMediaItem(value)
        setVisibleDelete(true)
    }

    const MenuComponent = (
        <Menu className="share-menu">
            <Menu.ItemGroup>
                {source !== 'userProfil' && (
                    <Menu.Item key="1" onClick={() => handleClickDelete(data)}>
                        {translator.t('feed.delete')}
                    </Menu.Item>
                )}

                <Menu.Item key="2" onClick={() => handleClickShare(data)}>
                    {translator.t('title.share')}
                </Menu.Item>
            </Menu.ItemGroup>
        </Menu>
    )

    return (
        <div className="container-video-frame">
            <div className="row">
                <div className="card">
                    {validateMore &&
                        location.pathname !== '/' &&
                        source !== 'epustakadetail' && (
                            <Dropdown
                                overlay={MenuComponent}
                                className="dropdownMore"
                            >
                                <span>
                                    <MoreOutlined
                                        rotate={90}
                                        style={{ color: 'white', fontSize: 20 }}
                                    />
                                </span>
                            </Dropdown>
                        )}
                    <div
                        className="card-image"
                        onClick={() => handleSourceLink()}
                        style={{
                            background: `url(${
                                media_thumbnail_url === null
                                    ? noimages
                                    : media_thumbnail_url
                            })`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                        }}
                    >
                        <div
                            className="ic-play-icon"
                            style={{
                                left:
                                    source === 'homevideo' ||
                                    source === 'epustakadetail'
                                        ? '0px'
                                        : validateMore
                                        ? '8%'
                                        : '0px',
                            }}
                        >
                            <span>
                                <img
                                    src={ic_play_video}
                                    alt={`${BASE_TITLE} Preview Images`}
                                />
                            </span>
                        </div>

                        {media_duration !== 0 && (
                            <div
                                className="duration"
                                style={{
                                    right:
                                        validateMore &&
                                        location.pathname ===
                                            '/more-history-video'
                                            ? '-44px'
                                            : !validateMore &&
                                              location.pathname ===
                                                  '/list-video-epustaka'
                                            ? '-61px'
                                            : validateMore
                                            ? '-40px'
                                            : null,
                                }}
                            >
                                <span>{formatDuration(media_duration)}</span>
                            </div>
                        )}
                    </div>

                    <div className="card-content">
                        <h3 onClick={() => handleSourceLink()}>
                            {media_title}
                        </h3>
                        <p>
                            {dayjs(created_at).format(DATE_FULLSTRING_FORMAT)}
                        </p>
                    </div>
                </div>
            </div>
            <ModalShare
                urlFrom="video"
                epustakaId={mediaItem?.epustaka_id}
                collectionId={mediaItem?.collection_id}
                visibleModal={popShare}
                setVisibleModal={setPopShare}
            />
            <ModalReturnBook
                visibleModal={visibleDelete}
                setVisibleModal={setVisibleDelete}
                mediaData={mediaItem}
                getMedia={getMedia}
                frombookself={frombookself}
            />
        </div>
    )
}

export default VideoCardCategory
