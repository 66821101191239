import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Row, Col, Tooltip, Card } from 'antd'
import provider from 'providers/provider'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import url from 'constants/url'
import reduxState from '../../../redux'
import { FallbackImage, ModalShare, SkeletonBook } from 'components'
import translator from 'i18next'
import { useHistory } from 'react-router-dom'
import NoActivityPost from 'views/timeline/components/NoActivityPost'
import Text from 'antd/lib/typography/Text'
import Meta from 'antd/lib/card/Meta'
import { BASE_TITLE, COLORS } from 'constants/constant'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Lazy } from 'swiper/core'
import { ShareAltOutlined } from '@ant-design/icons'

SwiperCore.use([Pagination, Lazy])

const HistoryBook = (props) => {
    const userId = props.userId.id
    const source = props.userId.source
    const history = useHistory()
    const { profile = {} } = reduxState.getState()
    const id = get(profile, 'id', '')
    const defaultPagination = `?limit=5`
    const filter =
        source === 'userProfil' ? `&user_id=${userId}` : `&user_id=${id}`

    const [historyBookLoading, setHistoryBookLoading] = useState(false)
    const [historyBookData, setHistoryBookData] = useState([])
    const [popShare, setPopShare] = useState(false)
    const [bookDataShare, setBookDataShare] = useState({})

    const isMobile = () => state.width <= 420

    const [state, setState] = useState({
        collapsed: false,
        width: window.innerWidth,
    })

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getHistoryBook = async () => {
        let isMounted = true // note this flag denote mount status

        setHistoryBookLoading(true)
        await provider
            .list(
                url.webhookUrl_book_history_shelf + defaultPagination + filter
            )
            .then((res) => {
                const { data } = res
                if (isMounted) {
                    setHistoryBookData(data)
                    setHistoryBookLoading(false)
                }
            })
        return () => {
            isMounted = false
        }
    }

    useEffect(() => {
        getHistoryBook()
        // disable warning missing dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClickShare = () => {
        setPopShare(true)
    }

    return (
        <React.Fragment>
            {historyBookLoading ? (
                <SkeletonBook />
            ) : isEmpty(historyBookData) ? (
                <div style={{ marginTop: '20px' }}>
                    <NoActivityPost />
                </div>
            ) : (
                <>
                    <Row>
                        <Col span={12}>
                            <div className="pb__containerLink">
                                {translator.t('column.read')}
                            </div>
                        </Col>
                        <Col
                            span={12}
                            style={{
                                textAlign: 'end',
                            }}
                        >
                            <div
                                className="pb__containerMore"
                                onClick={() =>
                                    history.push({
                                        pathname: '/book-history-list',
                                        state: { userId, source },
                                    })
                                }
                            >
                                {translator.t('action.fully')}
                            </div>
                        </Col>
                    </Row>

                    <div className="swiper_container">
                        <Swiper
                            slidesPerView={1.2}
                            spaceBetween={10}
                            preloadImages={false}
                            lazy={true}
                            pagination={{
                                el: '.swiper-pagination',
                                clickable: true,
                            }}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1.5,
                                },
                                360: {
                                    slidesPerView: 2,
                                },
                                411: {
                                    slidesPerView: 2.1,
                                },
                                420: {
                                    slidesPerView: 2.2,
                                },
                                540: {
                                    slidesPerView: 2.6,
                                },
                                640: {
                                    slidesPerView: 2.8,
                                },
                                768: {
                                    slidesPerView: 3.5,
                                },
                                884: {
                                    slidesPerView: 3.6,
                                },
                                1024: {
                                    slidesPerView: 4.5,
                                },
                                1087: {
                                    slidesPerView: 4.7,
                                },
                            }}
                            className="swiper_container"
                        >
                            {map(historyBookData, (book, key) => {
                                const {
                                    cover_url,
                                    book_author,
                                    book_title,
                                    book_id: id,
                                } = book

                                return (
                                    <SwiperSlide key={key}>
                                        <div
                                            key={key}
                                            className="pb__containerGrid"
                                        >
                                            <Card
                                                style={{
                                                    width: isMobile()
                                                        ? 160
                                                        : 150,
                                                    background: 'none',
                                                    minHeight: isMobile()
                                                        ? '310px'
                                                        : '280px',
                                                }}
                                                className="pb__containerGridCard"
                                                cover={
                                                    <div className="container-book">
                                                        <div className="book">
                                                            <div className="front">
                                                                <div className="cover">
                                                                    <FallbackImage
                                                                        alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                                                        src={
                                                                            cover_url
                                                                        }
                                                                        onClick={() =>
                                                                            history.push(
                                                                                `/book/${id}`
                                                                            )
                                                                        }
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            width: isMobile()
                                                                                ? 153
                                                                                : 141,
                                                                            height: isMobile()
                                                                                ? 220
                                                                                : 190,
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="left-side">
                                                                <h2
                                                                    style={{
                                                                        height: 20,
                                                                        width: isMobile()
                                                                            ? 220
                                                                            : 190,
                                                                        marginLeft:
                                                                            -15,
                                                                        backgroundImage: `url(${cover_url})`,
                                                                    }}
                                                                >
                                                                    <span>
                                                                        <Text
                                                                            style={{
                                                                                width: 155,
                                                                                fontSize: 10,
                                                                                color: 'white',
                                                                                paddingRight: 10,
                                                                            }}
                                                                            ellipsis={
                                                                                true
                                                                            }
                                                                        >
                                                                            {
                                                                                book_author
                                                                            }
                                                                        </Text>
                                                                    </span>
                                                                </h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <Meta
                                                    title={
                                                        <Tooltip
                                                            title={book_title}
                                                            color="#575757"
                                                            placement="topLeft"
                                                        >
                                                            <span
                                                                style={{
                                                                    fontWeight: 600,
                                                                }}
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/book/${id}`
                                                                    )
                                                                }
                                                            >
                                                                {book_title}
                                                            </span>
                                                        </Tooltip>
                                                    }
                                                    description={
                                                        <Text
                                                            style={{
                                                                width: 140,
                                                                fontSize: 13,
                                                                color: 'gray',
                                                            }}
                                                            ellipsis={true}
                                                        >
                                                            <Tooltip
                                                                title={
                                                                    book_author
                                                                }
                                                                color="#575757"
                                                                placement="topLeft"
                                                            >
                                                                {book_author}
                                                            </Tooltip>
                                                        </Text>
                                                    }
                                                />
                                            </Card>
                                            <ShareAltOutlined
                                                key="share"
                                                onClick={() =>
                                                    handleClickShare(
                                                        setBookDataShare(book)
                                                    )
                                                }
                                                style={{
                                                    color: COLORS.basegrey,
                                                }}
                                            />
                                            <br />
                                            <br />
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>

                    <ModalShare
                        urlFrom="book"
                        data={bookDataShare}
                        visibleModal={popShare}
                        setVisibleModal={setPopShare}
                    />
                </>
            )}
        </React.Fragment>
    )
}

export default HistoryBook
