import React from 'react'
import { Modal } from 'antd'
import { BASE_TITLE } from 'constants/constant'

const PreviewImage = (props) => {
    const { visible, title, handleCancel, imgSrc } = props
    return (
        <Modal
            visible={visible}
            title={title}
            footer={null}
            onCancel={handleCancel}
        >
            <img
                alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                width="100%"
                src={imgSrc}
            />
        </Modal>
    )
}

export default PreviewImage
