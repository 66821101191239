import ls from 'utils/secureLocalStorage'
import request from 'helpers/request'
import encryption from 'utils/encryption'
import url from 'constants/url'
import profileAction from 'redux/profile/action'
import tabKeyAction from 'redux/tabKey/action'
import authAction from 'redux/auth/action'
import provider from 'providers/provider'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import menuAction from 'redux/menu/action'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import { firebaseCloudMessaging } from 'utils/firebase'
import { ORGANIZATION_ID } from 'helpers/constants'

import * as configModule from 'constants/constant'

const configApp = { ...configModule }

const {
    webhookURL_profile: urlProfile,
    webhookURL_menu: urlMenu,
    webhookURL_fcm_token,
} = url

const HandleLogin = async (res) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function (resolve, reject) {
        const { data = {} } = res || {}
        if (
            !configApp?.MULTI_ORGANIZATION &&
            data?.organization_id !== ORGANIZATION_ID
        ) {
            const error = `Mohon maaf akun anda tidak terdaftar di ${configApp?.BASE_TITLE}`
            return reject({
                response: {
                    data: {
                        error: {
                            message: error,
                        },
                    },
                },
            })
        }
        authAction.setAuth(data)
        tabKeyAction.setDataTabKey('1')
        const decode = jwt_decode(data?.access_token)

        Cookies.set('ewAccessToken', data?.access_token, {
            expires: decode?.exp,
        })

        await request
            .get(`${urlProfile}`)
            .then((resProfile) => {
                const { data = {} } = resProfile || {}
                profileAction.setDataProfile(data)
            })
            .catch((err) => {
                return reject(err)
            })
        ls.set(encryption.encryptWithMD5('isChange'), false)
        ls.set(encryption.encryptWithMD5('popupModal'), true)
        ls.set(encryption.encryptWithMD5('verified'), data?.verified)
        // to get menu api list
        await provider
            .list(urlMenu, 'menu')
            .then((res) => {
                let tempMenu = [] // menampung menu android yang sudah di filter by array kosong
                let androidMenu = [] // menggabungkan list array

                get(res, 'menu', []).forEach((e) => {
                    if (!isEmpty(get(e, 'android_menu', []))) {
                        tempMenu.push(e)
                    }
                })

                if (!isEmpty(tempMenu)) {
                    tempMenu.forEach((e) => {
                        get(e, 'android_menu', []).forEach((menu) =>
                            androidMenu.push(menu)
                        )
                    })
                }
                menuAction.setMenuApps(androidMenu)
            })
            .catch((err) => {
                return reject(err)
            })

        firebaseCloudMessaging.setFCM().then((fcm_token) => {
            if (fcm_token) {
                provider.update(webhookURL_fcm_token, { fcm_token })
            }
        })

        resolve()
    })
}

export default HandleLogin
