import store from '../store'
import * as ActionType from './contant'

export default {
    setFilterOptions: (value) => {
        const action = { type: ActionType.SET_BOOK_FILTER_OPTIONS, value }
        store.dispatch(action)
    },
    clearFilterOptions: () => {
        const action = { type: ActionType.CLEAR_BOOK_FILTER_OPTIONS }
        store.dispatch(action)
    },
}
