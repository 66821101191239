import React, { useEffect, useState } from 'react'
import translator from 'i18next'
import get from 'lodash/get'
import isNull from 'lodash/isNull'
import { useHistory, useParams } from 'react-router-dom'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import { Helmet } from 'react-helmet'
import url from 'constants/url'
import provider from 'providers/provider'
import moment from 'moment'
import { SmallDashOutlined } from '@ant-design/icons'
import { ic_placeholder_audio } from 'assets/icons'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { Card, Col, Row, Tabs, Tooltip, Typography, Image, message } from 'antd'
import { BASE_TITLE, LAYOUT } from 'constants/constant'

dayjs.extend(duration)

const { TabPane } = Tabs
const { Paragraph } = Typography

const AudioDetail = () => {
    const history = useHistory()
    const params = useParams()
    const videoId = params?.uuid

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const [ellipsis, setEllipsis] = useState(true)

    const str = get(data, 'media_description', '')
    const characterLength = str && str.length

    const getData = async () => {
        setLoading(true)
        await provider
            .singleWebHook(
                `${url.webhookURL_public_multimedia_detail}?media_id=${videoId}`
            )
            .then((res) => {
                setData(res?.data)
            })
            .catch(() => {
                message.warning(translator.t('notification.item_doesnt_exist'))
                history.replace('/')
            })
        setLoading(false)
    }

    useEffect(() => {
        if (videoId) getData()
    }, [videoId])

    const skeletonAudio2 = (
        <div className="skeleton" style={{ width: 200 }}>
            <p style={{ color: 'transparent' }}>skeleton</p>
        </div>
    )

    return (
        // detail audio container
        <div className="da-body-container">
            <Helmet>
                <title>
                    {capitalize(data?.media_title)} - {BASE_TITLE} Website
                </title>
                <meta property="og:image" content={data.media_thumbnail_url} />
                <meta
                    name="og:title"
                    content={`${BASE_TITLE} Website - Platform Edukasi Indonesia`}
                />
                <meta
                    name="description"
                    content="Audio Page - Mari bergabung dan berkolaborasi mengembangkan pendidikan Indonesia bersama anak bangsa."
                />
            </Helmet>
            <Row
                type="flex"
                style={{
                    alignItems: 'center',
                    marginTop: '2rem',
                }}
                justify="center"
            >
                <Col
                    xs={24}
                    sm={19}
                    md={20}
                    lg={24}
                    xl={24}
                    className="da-container"
                    style={{
                        maxWidth: LAYOUT.maxWidth,
                    }}
                >
                    <Card
                        className="detail-card"
                        title={translator.t('title.audio_detail')}
                    >
                        <Row>
                            <Col>
                                <div
                                    style={{ display: 'flex', marginLeft: 24 }}
                                    className="post-image-book"
                                >
                                    <Image
                                        preview={false}
                                        className="ad-cover-audio"
                                        alt={`${BASE_TITLE} Preview Images - Platform Edukasi Indonesia`}
                                        src={
                                            isEmpty(data.media_thumbnail_url)
                                                ? ic_placeholder_audio
                                                : get(
                                                      data,
                                                      'media_thumbnail_url',
                                                      ''
                                                  )
                                        }
                                    />

                                    <div
                                        style={{ marginLeft: 15 }}
                                        className="descrition"
                                    >
                                        <div
                                            style={{
                                                fontSize: 17,
                                                lineHeight: 1.4,
                                                fontWeight: 600,
                                                marginRight: 40,
                                            }}
                                        >
                                            <Tooltip
                                                color="#696969"
                                                title={get(
                                                    data,
                                                    'media_title',
                                                    ''
                                                )}
                                            >
                                                <Paragraph
                                                    ellipsis={
                                                        ellipsis
                                                            ? {
                                                                  rows: 2,
                                                              }
                                                            : false
                                                    }
                                                >
                                                    {get(
                                                        data,
                                                        'media_title',
                                                        ''
                                                    )}
                                                </Paragraph>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row
                            className="description-audio-tabs"
                            style={{ borderTop: '1px solid #eff2f5' }}
                        >
                            <Col className="tab" span={24}>
                                {loading ? (
                                    skeletonAudio2
                                ) : (
                                    <Tabs defaultActiveKey="1" centered>
                                        <TabPane
                                            className="tab-info"
                                            tab={translator.t(
                                                'title.description'
                                            )}
                                            key="1"
                                        >
                                            <Paragraph
                                                ellipsis={
                                                    ellipsis
                                                        ? {
                                                              rows: 3,
                                                          }
                                                        : false
                                                }
                                            >
                                                {data.media_description ===
                                                null ? (
                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                            color: 'gray',
                                                        }}
                                                    >
                                                        <SmallDashOutlined
                                                            style={{
                                                                fontSize: 20,
                                                            }}
                                                        />
                                                        <p>
                                                            {translator.t(
                                                                'message.no_description'
                                                            )}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    data.media_description
                                                )}
                                            </Paragraph>
                                            {characterLength > 241 ? (
                                                <span
                                                    className="read-more-less"
                                                    onClick={() =>
                                                        setEllipsis(!ellipsis)
                                                    }
                                                >
                                                    {ellipsis
                                                        ? translator.t(
                                                              'action.fully'
                                                          )
                                                        : translator.t(
                                                              'action.readLess'
                                                          )}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </TabPane>
                                        <TabPane
                                            tab={translator.t('title.detail')}
                                            key="2"
                                        >
                                            <table className="table-detail-video">
                                                <tbody>
                                                    <tr>
                                                        <th className="table-title">
                                                            {translator.t(
                                                                'filter.category'
                                                            )}
                                                        </th>
                                                        <th className="table-title-value active-color">
                                                            {isNull(
                                                                get(
                                                                    data,
                                                                    'category_name',
                                                                    '-'
                                                                )
                                                            )
                                                                ? '-'
                                                                : get(
                                                                      data,
                                                                      'category_name',
                                                                      '-'
                                                                  )}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th className="table-title">
                                                            {translator.t(
                                                                'filter.published_by'
                                                            )}
                                                        </th>
                                                        <th className="table-title-value">
                                                            -
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th className="table-title">
                                                            {translator.t(
                                                                'filter.year'
                                                            )}
                                                        </th>
                                                        <th className="table-title-value active-color">
                                                            {moment(
                                                                get(
                                                                    data,
                                                                    'created_at',
                                                                    '-'
                                                                )
                                                            ).format('LLLL')}
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </TabPane>
                                    </Tabs>
                                )}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default AudioDetail
