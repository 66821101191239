import { RightOutlined } from '@ant-design/icons'
import { Avatar, List, Modal, Skeleton, Typography } from 'antd'
import React, { useState } from 'react'
import ModalSummaryPayment from './ModalSummaryPayment'
import translator from 'i18next'
import { isEmpty } from 'lodash'
import notificationHandler from 'utils/notificationHandler'
import provider from 'providers/provider'
import url from 'constants/url'
import ModalConfirmPayment from './ModalConfirmPayment'
import { useHistory } from 'react-router-dom'

const PaymentMethodList = ({
  data,
  headerText,
  onClickItem,
  methodPayment,
  setMethodPayment,
  loadingSubmit,
}) => {
  return (
    <div style={{ backgroundColor: '#fff', marginTop: '10px' }}>
      {data && (
        <List
          header={
            <div className="header-list-payment-method">{headerText}</div>
          }
          size="large"
          dataSource={data}
          loading={methodPayment === 'cash' && loadingSubmit}
          renderItem={(item) => (
            <List.Item
              key={item?.payment_type}
              className="list-item-payment-method"
              onClick={() => {
                onClickItem(item)
                setMethodPayment(methodPayment)
              }}
            >
              <List.Item.Meta
                style={{
                  alignItems: 'center',
                }}
                avatar={<Avatar shape="square" src={item?.icon_url} />}
                title={
                  <Typography.Text style={{ userSelect: 'none' }}>
                    {item?.display_name || '-'}
                  </Typography.Text>
                }
              />
              <div>
                <RightOutlined />
              </div>
            </List.Item>
          )}
        />
      )}
    </div>
  )
}

const ModalPaymentMethodList = ({
  visible,
  setVisible,
  dataPaymentMethod,
  orderBoxId,
  fromTryout,
}) => {
  const router = useHistory()
  const categoryOrder = fromTryout ? 'TRYOUT' : 'DONATION'
  const [visibleSummaryPayment, setVisibleSummaryPayment] = useState(false)
  const [dataSummaryPayment, setDataSummaryPayment] = useState({})
  const [methodPayment, setMethodPayment] = useState('')
  const [visibleConfirmPayment, setVisibleConfirmPayment] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const handleSelectPayment = (paymentMethod) => {
    setVisible(!visible)
    setVisibleSummaryPayment(true)
    setDataSummaryPayment(paymentMethod)
  }

  const handleSubmitPayment = async () => {
    setLoadingSubmit(true)
    const payloadData = {
      category: categoryOrder,
      payment_type: 'cash',
    }
    await provider
      .insert(url.webhookURL_create_transaction, payloadData)
      .then(() => {
        router.replace('/transaction')
        setVisibleConfirmPayment(true)
        setVisible(!visible)
        setLoadingSubmit(false)
      })
      .catch((err) => {
        setLoadingSubmit(false)
        setVisible(!visible)
        notificationHandler('dev2', err)
      })
  }

  const displayContent = () => {
    if (dataPaymentMethod?.loading) {
      return (
        <div
          style={{
            padding: 15,
            backgroundColor: 'white',
          }}
        >
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      )
    }

    if (isEmpty(dataPaymentMethod?.data)) {
      return (
        <List
          style={{ backgroundColor: 'white' }}
          dataSource={[]}
          header="Belum Terdapat Metode Pembayaran"
        />
      )
    }

    return (
      <div>
        {/* Cash */}
        <PaymentMethodList
          data={dataPaymentMethod?.data?.cash}
          headerText={translator.t('title.transfer_manual')}
          onClickItem={handleSubmitPayment}
          methodPayment="cash"
          setMethodPayment={setMethodPayment}
          loadingSubmit={loadingSubmit}
        />

        {/* Bank Transfer */}
        <PaymentMethodList
          data={dataPaymentMethod?.data?.bank_transfers}
          headerText={translator.t('title.bank_transfer')}
          onClickItem={handleSelectPayment}
          methodPayment="bank_transfers"
          setMethodPayment={setMethodPayment}
          loadingSubmit={loadingSubmit}
        />

        {/* E-Wallets */}
        <PaymentMethodList
          data={dataPaymentMethod?.data?.ewallets}
          headerText={translator.t('title.ewallet')}
          onClickItem={handleSelectPayment}
          methodPayment="ewallets"
          setMethodPayment={setMethodPayment}
          loadingSubmit={loadingSubmit}
        />

        {/* Ritel */}
        {dataPaymentMethod?.data?.cstores && (
          <PaymentMethodList
            data={dataPaymentMethod?.data?.cstores}
            headerText={translator.t('title.retail')}
            onClickItem={handleSelectPayment}
            methodPayment="cstores"
            setMethodPayment={setMethodPayment}
            loadingSubmit={loadingSubmit}
          />
        )}
      </div>
    )
  }

  return (
    <React.Fragment>
      <Modal
        visible={visible}
        onCancel={() => setVisible(!visible)}
        title={translator.t('title.choose_payment_method')}
        className="modal-payment-method-wrapper"
        width={420}
        centered
        footer={<></>}
      >
        {displayContent()}
      </Modal>

      <ModalSummaryPayment
        visible={visibleSummaryPayment}
        setVisible={setVisibleSummaryPayment}
        dataSummaryPayment={dataSummaryPayment}
        orderBoxId={orderBoxId}
        methodPayment={methodPayment}
        fromTryout={fromTryout}
      />

      <ModalConfirmPayment
        visible={visibleConfirmPayment}
        setVisible={setVisibleConfirmPayment}
        orderBoxId={orderBoxId}
        source="methodCash"
      />
    </React.Fragment>
  )
}

export default ModalPaymentMethodList
