import React, { useState, useEffect } from 'react'
import { Row, Col, Tabs, Skeleton } from 'antd'
import tabKeyAction from 'redux/tabKey/action'
import translator from 'i18next'
import ModalChangePhoto from './components/ModalChangePhoto'
import ChangeProfile from './ChangeProfile'
import Identity from './Identity'
import Avatar from 'antd/lib/avatar/avatar'
import { BASE_TITLE, COLORS, LAYOUT } from 'constants/constant'
import { CameraFilled } from '@ant-design/icons'
import getAcronym from 'components/Acronym'
import reduxState from '../../../redux'
import provider from 'providers/provider'
import url from 'constants/url'

const { TabPane } = Tabs

const Setting = () => {
    const { profile = {} } = reduxState.getState()

    const [visibleModal, setVisibleModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState({})

    useEffect(() => {
        refreshUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const refreshUser = async () => {
        setIsLoading(true)
        await provider
            .singleWebHook(`${url.webhookURL_profile}`)
            .then((res) => {
                setState(res?.data)
            })
        setIsLoading(false)
    }

    const callback = (key) => {
        const selectedKey = key.split('home-')
        tabKeyAction.setDataTabKey(selectedKey[1])
    }

    const name = state?.name || ''
    const id = state?.id || null
    const { color, acronym } = getAcronym(name, id)

    return (
        <>
            <Row
                type="flex"
                style={{ alignItems: 'center', margin: '1.7rem 0' }}
                justify="center"
            >
                <Col
                    span={24}
                    style={{
                        maxWidth: LAYOUT.maxWidth,
                    }}
                    className="setting__container"
                >
                    <div
                        className="setting__containerTop"
                        style={{
                            marginBottom: 45,
                            padding: '25px 0',
                        }}
                    >
                        {isLoading ? (
                            <Skeleton.Avatar
                                active={true}
                                size={100}
                                shape="circle"
                            />
                        ) : (
                            <>
                                <Avatar
                                    className="avatar"
                                    size={100}
                                    alt={`${BASE_TITLE} image preview - Platform Perpustakaan Indonesia`}
                                    style={{
                                        cursor: 'pointer',
                                        backgroundImage: color,
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                    }}
                                    src={state?.avatar_url}
                                >
                                    <span className="avatar-name">
                                        {acronym}
                                    </span>
                                </Avatar>
                                <span onClick={() => setVisibleModal(true)}>
                                    <Avatar
                                        size="small"
                                        style={{
                                            backgroundColor:
                                                COLORS.primaryColor,
                                            marginLeft: '-1.5rem',
                                            marginBottom: '-4rem',
                                            cursor: 'pointer',
                                        }}
                                        alt={`${BASE_TITLE} image preview - Platform Perpustakaan Indonesia`}
                                    >
                                        <CameraFilled />
                                    </Avatar>
                                </span>
                            </>
                        )}
                    </div>
                    <Tabs
                        defaultActiveKey={`home-1`}
                        onChange={callback}
                        centered
                        className="tab-setting"
                    >
                        <TabPane
                            tab={translator.t('action.indentity')}
                            key="home-1"
                        >
                            <Identity isLoading={isLoading} state={state} />
                        </TabPane>
                        <TabPane
                            tab={translator.t('action.changeProfile')}
                            key="home-2"
                        >
                            <ChangeProfile
                                state={state}
                                refreshUser={refreshUser}
                            />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>

            <ModalChangePhoto
                visibleModal={visibleModal}
                setVisibleModal={setVisibleModal}
                dataUser={profile}
            />
        </>
    )
}

export default Setting
