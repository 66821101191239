import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Button, Row } from 'antd'

import { AudioCardCategory } from 'views/home/audio/components'
import provider from 'providers/provider'
import url from 'constants/url'
import translator from 'i18next'
import { MultimediaSorter, SkeletonAudio } from 'components'
import { ArrowDownOutlined } from '@ant-design/icons'
import { LAYOUT } from 'constants/constant'

const AudioCategory = () => {
    const [audioCategoryData, setAudioCategoryData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [limit, setLimit] = useState(20)
    const [loading, setLoading] = useState(false)
    const [sortingAudio, setSortingAudio] = useState('')

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 420

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getAudioData = async () => {
        setLoading(true)
        await provider
            .list(
                encodeURI(url.webhookURL_multimedia_list) +
                    `?limit=${limit}` +
                    `&sort=${sortingAudio}` +
                    `&type=audio`
            )
            .then((res) => {
                const { data, meta } = res
                setTotalData(meta.total)
                setAudioCategoryData(data)
                setLoading(false)
            })
    }

    useEffect(() => {
        getAudioData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, sortingAudio])

    const handleSorting = (e) => {
        setSortingAudio(e === 'latest' ? '-created_at' : 'media_title')
    }

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={loading}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 15)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }

    return (
        <div className="ac-container">
            <MultimediaSorter
                categoryName={translator.t('action.recommendation')}
                handleSorting={handleSorting}
                fromHome
            />

            <div
                style={{ marginTop: 20 }}
                className="as-container category-container"
            >
                {loading ? (
                    <SkeletonAudio source="fullskeleton" />
                ) : (
                    <>
                        <Row
                            style={{
                                justifyContent: isMobile() ? '' : 'start',
                                maxWidth: LAYOUT.maxWidth,
                            }}
                            className="audio-wrapper-home"
                        >
                            {audioCategoryData.map((item, id) => (
                                <AudioCardCategory key={id} data={item} />
                            ))}
                        </Row>
                        <div className="loadmore-btn">
                            <DispalyButton />
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default AudioCategory
