import store from '../store'
import * as ActionType from './contant'

export default {
    setReadingProgress: (value) => {
        const action = { type: ActionType.SET_READING_PROGRESS, value }
        store.dispatch(action)
    },
    clearReadingProgress: () => {
        const action = { type: ActionType.CLEAR_READING_PROGRESS }
        store.dispatch(action)
    },
}
