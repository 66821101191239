import React from 'react'
import translator from 'i18next'
import map from 'lodash/map'
import omit from 'lodash/omit'
import { Form, Input, Skeleton } from 'antd'

const InputComponent = (props) => {
    const {
        onChange,
        name,
        schema = {},
        formitemprops = {},
        loading = false,
    } = props

    const handleChange = (e) => {
        if (name && onChange) {
            onChange(name, e.target.value, e)
        }
    }

    let Component = (
        <>
            <strong>{translator.t(props.text)}&nbsp;</strong>
            <Input {...omit(props, ['loading'])} onChange={handleChange} />{' '}
        </>
    )
    let formProps = {}

    if (name && schema[name]) {
        const fieldSchema = schema[name]
        const label = props.text || fieldSchema.label

        Component = (
            <Input {...omit(props, ['loading'])} onChange={handleChange} />
        )
        formProps = {
            name,
            label: translator.t(label),
            rules: map(fieldSchema.rules, (rule) => ({
                ...rule,
                message: translator.t(rule.message),
            })),
        }
    }

    if (loading) Component = <Skeleton.Input active />

    return (
        <Form.Item className="form-item" {...formProps} {...formitemprops}>
            {Component}
        </Form.Item>
    )
}
export default InputComponent
