import React from 'react'
import translator from 'i18next'
import { AudioCardComponent } from 'views/home/audio/components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Lazy } from 'swiper/core'
import { useHistory } from 'react-router'
import { COLORS } from 'constants/constant'

SwiperCore.use([Pagination, Lazy])

const BorrowAudio = (props) => {
    const getMedia = props?.getMedia
    const audioData = props?.audioData
    const id = audioData?.id

    const history = useHistory()

    return (
        <div className="a-container" style={{ marginBottom: 20 }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 10,
                    fontSize: 16,
                    marginTop: 10,
                }}
            >
                <p
                    style={{
                        color: 'rgb(87, 87, 87)',
                        fontWeight: 700,
                    }}
                >
                    {translator.t('bookshelf.borrowAudio')}
                </p>
                <p
                    onClick={() =>
                        history.push({
                            pathname: '/more-history-audio',
                            state: { id },
                        })
                    }
                    style={{ cursor: 'pointer', color: COLORS.primaryColor }}
                >
                    {translator.t('action.fully')}
                </p>
            </div>

            <div className="swiper_container">
                <Swiper
                    slidesPerView={1.2}
                    spaceBetween={10}
                    preloadImages={false}
                    lazy={true}
                    pagination={{
                        el: '.swiper-pagination',
                        clickable: true,
                    }}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        360: {
                            slidesPerView: 1.5,
                        },
                        411: {
                            slidesPerView: 1.7,
                        },
                        420: {
                            slidesPerView: 1.7,
                        },
                        540: {
                            slidesPerView: 2.2,
                        },
                        640: {
                            slidesPerView: 2.2,
                        },
                        768: {
                            slidesPerView: 2.4,
                        },
                        884: {
                            slidesPerView: 2.8,
                        },
                        1024: {
                            slidesPerView: 3.1,
                        },
                        1087: {
                            slidesPerView: 3.2,
                        },
                    }}
                    className="swiper_container"
                >
                    {audioData.map((item, key) => (
                        <SwiperSlide key={key}>
                            <AudioCardComponent
                                data={item}
                                source="audio"
                                frombookself="bookself"
                                getMedia={getMedia}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default BorrowAudio
