import React from 'react'
import HistoryAudio from './HistoryAudio'

const ProfileAudio = (props) => {
    const userId = props
    return (
        <div className="pa__container">
            <HistoryAudio userId={userId} />
        </div>
    )
}

export default ProfileAudio
