import { Card } from 'antd'
import React, { useState, useLayoutEffect } from 'react'
import formatDuration from 'utils/formatDuration'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'
import Meta from 'antd/lib/card/Meta'
import Paragraph from 'antd/lib/typography/Paragraph'
import { BASE_TITLE } from 'constants/constant'
import { ic_video, noimages } from '../../../../assets/icons'

const VideoCardComponent = (props) => {
    const { data, source } = props
    const history = useHistory()
    const {
        media_thumbnail_url,
        media_duration,
        media_title,
        created_at,
        id,
        media_id,
    } = data

    const idFilter = source === 'video' ? media_id : id

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 420
    const isWidthMobile = () => state.width <= 360

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])
    return (
        <>
            {isMobile() ? (
                <Card
                    className="video-card-mobile"
                    onClick={() => history.push(`/video/${idFilter}`)}
                    style={{
                        width: isWidthMobile() ? 315 : 340,
                        marginBottom: 10,
                        cursor: 'pointer',
                    }}
                    cover={
                        <div
                            className="cover-container"
                            style={{ width: '100%' }}
                        >
                            <div className="cover">
                                <img
                                    style={{
                                        height: '12rem',
                                        width: '100%',
                                    }}
                                    className="cover-mobile"
                                    src={
                                        media_thumbnail_url === null
                                            ? noimages
                                            : media_thumbnail_url
                                    }
                                    alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                />
                                <div className="play-icon">
                                    <img
                                        src={ic_video}
                                        alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                    />
                                </div>
                            </div>

                            <div className="time">
                                <span className="time-video">
                                    {formatDuration(media_duration)}
                                </span>
                            </div>
                        </div>
                    }
                >
                    <Meta
                        title={
                            <Link
                                style={{
                                    color: 'black',
                                    textTransform: 'capitalize',
                                }}
                                to={`/video/${idFilter}`}
                            >
                                <div style={{ fontWeight: 600 }}>
                                    {media_title}
                                </div>
                            </Link>
                        }
                        description={moment(created_at).format('DD MMMM YYYY')}
                    />
                </Card>
            ) : (
                <div className="video-grid" key={id} data-value={id}>
                    <Card
                        className="video-card"
                        onClick={() => history.push(`/video/${idFilter}`)}
                        style={{ cursor: 'pointer' }}
                    >
                        <img
                            className="cover"
                            src={
                                media_thumbnail_url === null
                                    ? noimages
                                    : media_thumbnail_url
                            }
                            alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                        />
                        <div className="card-overlay card__overlay--dark">
                            <div className="card-overlay-content">
                                <div className="play-icon">
                                    <img
                                        src={ic_video}
                                        alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                    />
                                </div>
                                <div className="time">
                                    <span className="time-video">
                                        {formatDuration(media_duration)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <h1 className="video-title">
                        <Link
                            style={{ color: 'black' }}
                            to={`/video/${idFilter}`}
                        >
                            <Paragraph
                                style={{
                                    fontSize: 18,
                                    color: '#464646',
                                    fontWeight: 600,
                                }}
                                ellipsis={{
                                    rows: 1,
                                    expandable: false,
                                }}
                            >
                                {media_title}
                            </Paragraph>
                        </Link>
                    </h1>
                    <p className="video-subtitle">
                        {moment(created_at).format('DD MMMM YYYY')}
                    </p>
                </div>
            )}
        </>
    )
}

export default VideoCardComponent
