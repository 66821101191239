import React, { useRef, useEffect, useState } from 'react'
import translator from 'i18next'
import get from 'lodash/get'
import isNull from 'lodash/isNull'
import concat from 'lodash/concat'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import url from 'constants/url'
import { error } from 'utils/notification'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import action from 'redux/timeLine/action'
import provider from 'providers/provider'
import moment from 'moment'
import CommentList from '../components/CommentList'
import getPagination from 'constants/getPagination'
import { ModalNoAccess } from 'components'
import { ModalShare } from 'components'
import MusicPlayer from 'views/home/audio/components/MusicPlayer'
import { SmallDashOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet'
import { ic_placeholder_audio } from 'assets/icons'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

import {
    Card,
    Col,
    Row,
    Tabs,
    Button,
    Avatar,
    Form,
    Input,
    Tooltip,
    Typography,
    List,
    Dropdown,
    Menu,
    Modal,
    Radio,
    Image,
} from 'antd'
import { ic_more } from 'assets/icons'
import getAcronym from 'components/Acronym'
import { BASE_TITLE, LAYOUT } from 'constants/constant'
import ModalContent from 'components/ModalContent'
import { isNil } from 'lodash'

const { TabPane } = Tabs
const { TextArea } = Input
const { Paragraph } = Typography

const AudioDetail = (props) => {
    const epustakaId = props?.match?.params?.epustaka
    const audioProgress = props?.match?.params?.d

    const { setTimeLine, stateTimeLine, history } = props
    const inputRef = useRef(null)
    const [form] = Form.useForm()
    const audioId = props.match.params.uuid

    const [audioData, setAudioData] = useState({})
    const [userProfil, setUserProfile] = useState([])
    const [dataComment, setDataComment] = useState([])
    const [audioLoading, setAudioLoading] = useState(false)
    const [isModalVisibleShare, setIsModalVisibleShare] = useState(false)
    const [totalComment, setTotalComment] = useState(0)
    const [popupModal, setPopupModal] = useState(false)
    const [popupNoAccess, setPopupNoAccess] = useState(false)
    const [contentPrivate, setContentPrivate] = useState(false)
    const [epustakaAvailableData, setEpustakaAvailableData] = useState({})
    const [epustakaID, setEpustakaID] = useState(epustakaId)

    const limit = 10
    const [limitComment, setLimitComment] = useState(1)
    const pagination = getPagination(limitComment, limit, 'webhook')

    const object_type = '&object_type=AUDIO'
    const objId = `&object_id=${audioId}`

    const senderId = get(userProfil, 'id', '')
    const userAvatar = get(userProfil, 'avatar_url', '')

    const name = userProfil?.name || ''
    const id = userProfil?.id || ''
    const { color, acronym } = getAcronym(name, id)

    const str = get(audioData, 'media_description', '')
    const characterLength = str && str.length

    const [postComment, setPostComment] = useState('')
    const [reportComment, setReportComment] = useState('')
    const [previewComment, setPreviewComment] = useState(false)
    const [loading, setLoading] = useState(false)

    const [confirmLoading, setConfirmLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [item, setItem] = useState({})
    const [durationAudio, setDurationAudio] = useState(0)

    const [OpenReply, setOpenReply] = useState(false)

    const [paginationComment, setPaginationComment] = useState({
        loading: true,
        pagination: true,
    })
    const [ellipsis, setEllipsis] = React.useState(true)

    const skeletonAudio2 = (
        <div className="skeleton" style={{ width: 200 }}>
            <p style={{ color: 'transparent' }}>skeleton</p>
        </div>
    )

    const getProfile = async () => {
        await provider
            .singleWebHook(`${url.webhookURL_profile}`)
            .then((res) => {
                setUserProfile(res.data)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    useEffect(() => {
        getProfile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // To Post a comment in comment form
    const submitData = async (url, data) => await provider.insert(url, data)
    const handleSave = async () => {
        setLoading(true)
        await submitData(url.webhookURL_comment, {
            object_type: 'AUDIO',
            object_id: `${audioId}`,
            comment: `${postComment}`,
        })
            .then(async () => {
                setTimeLine({ refresh: true })
                setPreviewComment(!previewComment)
                setOpenReply(!OpenReply)
                form.setFieldsValue({
                    senderTextarea: '',
                })
                setLoading(false)
            })
            .catch((err) => {
                Promise.reject(err)
                setLoading(false)
            })
        setPostComment('')
    }
    const handleFinish = async () => {
        await handleSave()
    }

    const radioStyle = {
        display: 'flex',
        height: '30px',
        lineHeight: '22px',
        marginBottom: '10px',
    }

    const handleOk = async (value) => {
        setConfirmLoading(true)
        return await provider
            .put(url.webhookURL_comment_report, {
                comment_id: `${value.id}`,
                reason: `${reportComment}`,
            })
            .then(() => {
                setTimeLine({ refreshLike: true })
                setConfirmLoading(false)
                setIsModalVisible(false)
                value.has_report === true
                    ? Modal.warning({
                          title: `${translator.t('message.warningReport')}`,
                          width: 550,
                          content: (
                              <div>
                                  <p>
                                      {translator.t(
                                          'message.message_warning_report'
                                      )}
                                  </p>
                              </div>
                          ),
                          onOk() {},
                      })
                    : Modal.info({
                          title: `${translator.t('message.successReport')}`,
                          width: 700,
                          content: (
                              <div>
                                  <p>
                                      {translator.t(
                                          'message.message_success_report'
                                      )}
                                  </p>
                              </div>
                          ),
                          onOk() {},
                      })
            })
            .catch((err) => {
                setIsModalVisible(false)
                setConfirmLoading(false)
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const onReportComment = (value) => {
        setIsModalVisible(true)
        setItem(value)
    }

    const dataMessageSender = [
        {
            avatar: (
                <Avatar
                    style={{
                        cursor: 'pointer',
                        backgroundImage: color,
                        display: 'inline-flex',
                        alignItems: 'center',
                    }}
                    src={userAvatar}
                >
                    <span>{acronym}</span>
                </Avatar>
            ),

            description: (
                <div>
                    <Form form={form}>
                        <div
                            style={{
                                marginRight: '10px',
                                marginLeft: '-5px',
                            }}
                        >
                            <div>
                                <Form.Item name="senderTextarea">
                                    <TextArea
                                        bordered={false}
                                        onFocus={() => {
                                            setPreviewComment(!previewComment)
                                        }}
                                        className="textArea-add"
                                        placeholder={`${translator.t(
                                            'feed.addComment'
                                        )}`}
                                        autoSize
                                        value={postComment}
                                        onChange={(e) =>
                                            setPostComment(e.target.value)
                                        }
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        {previewComment && (
                            <div
                                ref={inputRef}
                                style={{
                                    textAlign: 'end',
                                    marginTop: '-8px',
                                    marginRight: '10px',
                                }}
                            >
                                <div className="btn-comment-edit">
                                    <Button
                                        style={{
                                            marginRight: 5,
                                            fontWeight: 'bold',
                                            color: '#777f8f',
                                            border: 'none',
                                            marginTop: '10px',
                                        }}
                                        onClick={() => {
                                            setPreviewComment(!previewComment)
                                            form.setFieldsValue({
                                                senderTextarea: '',
                                            })
                                        }}
                                        type="text"
                                    >
                                        {translator.t('action.cancell')}
                                    </Button>
                                    <Button
                                        onClick={handleFinish}
                                        disabled={!postComment}
                                        loading={loading}
                                        type="primary"
                                    >
                                        {translator.t('action.comment')}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Form>
                </div>
            ),
        },
    ]

    const handleHistoryAudio = async () => {
        const data = {
            epustaka_collection_id: isNil(
                epustakaAvailableData.epustaka_collection_id
            )
                ? audioData?.epustaka_collection_id
                : epustakaAvailableData.epustaka_collection_id,
            user_id: senderId,
            progress: parseInt(durationAudio),
        }
        await provider.insert(
            url.webhookURL_collection_access_history,
            data,
            'webhook'
        )
    }

    const getDetailAudio = async (data) => {
        const filter = epustakaId || data?.id
        setAudioLoading(true)
        await provider
            .singleWebHook(
                `${url.webhookURL_multimedia_detail}?type=audio&media_id=${audioId}` +
                    `&epustaka_id=${filter}`
            )
            .then((res) => {
                const { data } = res
                isNil(epustakaId) && setEpustakaID(data?.epustaka?.id)
                const notJoin =
                    data?.epustaka?.has_join &&
                    data?.epustaka?.member_status !== 1

                if (!data?.epustaka.has_join || notJoin) {
                    // setPopupModal(true)
                    setContentPrivate(true)
                } else {
                    setContentPrivate(false)
                }
                setAudioData(data)
                setAudioLoading(false)
            })
            .catch((err) => {
                history.replace('/')
                error({
                    description: err.response?.data?.error,
                    placement: 'bottomRight',
                })
            })
    }

    const epustakaAvailable = async () => {
        await provider
            .singleWebHook(
                `${url.webhookURL_epustaka_available}?collection_id=${audioId}`
            )
            .then(({ data }) => {
                setEpustakaAvailableData(data)
                const notJoin = !data?.has_join && data?.member_status !== 1
                if (notJoin) {
                    // setPopupModal(true)
                    setContentPrivate(true)
                } else if (data?.has_join && data?.member_status !== 1) {
                    // setPopupModal(true)
                    setContentPrivate(true)
                } else {
                    setContentPrivate(false)
                }
                getDetailAudio(data)
            })
            .catch(() => {
                // setPopupNoAccess(true)
                setContentPrivate(true)
                getDetailAudio()
            })
    }

    useEffect(() => {
        isNil(epustakaId) ? epustakaAvailable() : getDetailAudio()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioId])

    const onLoadMoreComment = async () => {
        setPaginationComment({
            ...paginationComment,
            loading: true,
        })
        setLimitComment(limitComment + 1)
    }

    const getCommentList = async () => {
        setLoading(true)
        await provider
            .list(
                `${
                    url.webhookURL_comment_list +
                    pagination.result +
                    object_type +
                    objId
                }`
            )
            .then((results) => {
                const { data, meta } = results

                limitComment === 1
                    ? setDataComment(data)
                    : setDataComment(concat(dataComment, data))

                if (pagination.offset >= meta.total || meta.total <= limit) {
                    setPaginationComment({
                        loading: false,
                        pagination: false,
                    })
                } else {
                    setPaginationComment({
                        ...paginationComment,
                        loading: false,
                    })
                }

                setTotalComment(meta.total)
                setLoading(false)
                setTimeLine({ refresh: false })
            })
            .catch((err) => {
                setLoading(false)
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })

                return
            })
    }

    useEffect(() => {
        getCommentList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limitComment])

    useEffect(() => {
        if (stateTimeLine.refresh) {
            limitComment === 1 ? getCommentList() : setLimitComment(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateTimeLine.refresh])

    const handleClickShare = () => {
        setIsModalVisibleShare(true)
    }

    const menuCard = (
        <Menu style={{ width: 136, borderRadius: 0, left: 13, top: -35 }}>
            <Menu.Item className="drp-menu-item">
                <div onClick={() => handleClickShare(audioData)}>Bagikan</div>
            </Menu.Item>
        </Menu>
    )

    return (
        // detail audio container
        <div className="da-body-container">
            <Helmet>
                <title>
                    {capitalize(audioData.media_title)} - {BASE_TITLE} Website
                </title>
                <meta
                    property="og:image"
                    content={audioData.media_thumbnail_url}
                />
                <meta
                    name="og:title"
                    content={`${BASE_TITLE} Website - Platform Perpustakaan Indonesia`}
                />
                <meta
                    name="description"
                    content="Audio Page - Mari bergabung dan berkolaborasi mengembangkan pendidikan Indonesia bersama anak bangsa."
                />
            </Helmet>
            <Row
                type="flex"
                style={{
                    alignItems: 'center',
                    marginTop: '2rem',
                }}
                justify="center"
            >
                <Col
                    xs={24}
                    sm={19}
                    md={20}
                    lg={24}
                    xl={24}
                    className="da-container"
                    style={{
                        maxWidth: LAYOUT.maxWidth,
                    }}
                >
                    <Card
                        className="detail-card"
                        title={translator.t('title.audio_detail')}
                        // extra={<img src={EllipsisOutlined} alt="" />}
                        extra={
                            <Dropdown
                                overlay={menuCard}
                                placement="bottomRight"
                                trigger={['click']}
                                style={{ cursor: 'pointer' }}
                            >
                                <Image
                                    src={ic_more}
                                    preview={false}
                                    alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                />
                            </Dropdown>
                        }
                    >
                        <Row>
                            <Col>
                                <div
                                    style={{ display: 'flex', marginLeft: 24 }}
                                    className="post-image-book"
                                >
                                    <Image
                                        preview={false}
                                        className="ad-cover-audio"
                                        alt={`${BASE_TITLE} Preview Images - Platform Perpustakaan Indonesia`}
                                        src={
                                            isEmpty(
                                                audioData.media_thumbnail_url
                                            )
                                                ? ic_placeholder_audio
                                                : get(
                                                      audioData,
                                                      'media_thumbnail_url',
                                                      ''
                                                  )
                                        }
                                    />

                                    <div
                                        style={{ marginLeft: 15 }}
                                        className="descrition"
                                    >
                                        <div
                                            style={{
                                                fontSize: 17,
                                                lineHeight: 1.4,
                                                fontWeight: 600,
                                                marginRight: 40,
                                            }}
                                        >
                                            <Tooltip
                                                color="#696969"
                                                title={get(
                                                    audioData,
                                                    'media_title',
                                                    ''
                                                )}
                                            >
                                                <Paragraph
                                                    ellipsis={
                                                        ellipsis
                                                            ? {
                                                                  rows: 2,
                                                              }
                                                            : false
                                                    }
                                                >
                                                    {get(
                                                        audioData,
                                                        'media_title',
                                                        ''
                                                    )}
                                                </Paragraph>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <MusicPlayer
                                    data={audioData}
                                    handleHistoryAudio={handleHistoryAudio}
                                    setDurationAudio={setDurationAudio}
                                    audioProgress={audioProgress}
                                    contentPrivate={contentPrivate}
                                    requestData={getDetailAudio}
                                    epustakaId={epustakaID}
                                    // fromQR={fromQR}
                                />
                            </Col>
                        </Row>

                        <br />

                        <Row
                            className="description-audio-tabs"
                            style={{ borderTop: '1px solid #eff2f5' }}
                        >
                            <Col className="tab" span={24}>
                                {audioLoading ? (
                                    skeletonAudio2
                                ) : (
                                    <Tabs defaultActiveKey="1" centered>
                                        <TabPane
                                            className="tab-info"
                                            tab={translator.t(
                                                'title.description'
                                            )}
                                            key="1"
                                        >
                                            <Paragraph
                                                ellipsis={
                                                    ellipsis
                                                        ? {
                                                              rows: 3,
                                                          }
                                                        : false
                                                }
                                            >
                                                {audioData.media_description ===
                                                null ? (
                                                    <div
                                                        style={{
                                                            textAlign: 'center',
                                                            color: 'gray',
                                                        }}
                                                    >
                                                        <SmallDashOutlined
                                                            style={{
                                                                fontSize: 20,
                                                            }}
                                                        />
                                                        <p>
                                                            {translator.t(
                                                                'message.no_description'
                                                            )}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="submessage"
                                                        dangerouslySetInnerHTML={{
                                                            __html: audioData.media_description,
                                                        }}
                                                    />
                                                )}
                                            </Paragraph>
                                            {characterLength > 241 ? (
                                                <span
                                                    className="read-more-less"
                                                    onClick={() =>
                                                        setEllipsis(!ellipsis)
                                                    }
                                                >
                                                    {ellipsis
                                                        ? translator.t(
                                                              'action.fully'
                                                          )
                                                        : translator.t(
                                                              'action.readLess'
                                                          )}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </TabPane>
                                        <TabPane
                                            tab={translator.t('title.detail')}
                                            key="2"
                                        >
                                            <table className="table-detail-video">
                                                <tbody>
                                                    <tr>
                                                        <th className="table-title">
                                                            {translator.t(
                                                                'filter.category'
                                                            )}
                                                        </th>
                                                        <th className="table-title-value active-color">
                                                            {isNull(
                                                                get(
                                                                    audioData,
                                                                    'category_name',
                                                                    '-'
                                                                )
                                                            )
                                                                ? '-'
                                                                : get(
                                                                      audioData,
                                                                      'category_name',
                                                                      '-'
                                                                  )}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th className="table-title">
                                                            {translator.t(
                                                                'filter.published_by'
                                                            )}
                                                        </th>
                                                        <th className="table-title-value">
                                                            -
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th className="table-title">
                                                            {translator.t(
                                                                'filter.year'
                                                            )}
                                                        </th>
                                                        <th className="table-title-value active-color">
                                                            {moment(
                                                                get(
                                                                    audioData,
                                                                    'created_at',
                                                                    '-'
                                                                )
                                                            ).format('LLLL')}
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </TabPane>
                                        <TabPane
                                            tab={
                                                dataComment.length === 0
                                                    ? `${translator.t(
                                                          'filter.comment'
                                                      )}`
                                                    : `${
                                                          translator.t(
                                                              'filter.comment'
                                                          ) +
                                                          ' ' +
                                                          `(${totalComment})`
                                                      }`
                                            }
                                            key="3"
                                        >
                                            <List
                                                className="ad-comment"
                                                itemLayout="horizontal"
                                                dataSource={dataMessageSender}
                                                renderItem={(item, id) => (
                                                    <div>
                                                        {!contentPrivate && (
                                                            <List.Item
                                                                style={{
                                                                    paddingLeft: 10,
                                                                }}
                                                            >
                                                                <List.Item.Meta
                                                                    avatar={
                                                                        item.avatar
                                                                    }
                                                                    title={
                                                                        item.title
                                                                    }
                                                                    description={
                                                                        item.description
                                                                    }
                                                                />
                                                            </List.Item>
                                                        )}

                                                        <div className="container-comment-list">
                                                            <div
                                                                key={id}
                                                                style={{
                                                                    borderTop:
                                                                        '1px solid #eff2f5',
                                                                    marginTop:
                                                                        -7,
                                                                }}
                                                            />
                                                            <CommentList
                                                                data={
                                                                    dataComment
                                                                }
                                                                onLoadMore={
                                                                    onLoadMoreComment
                                                                }
                                                                paginationComment={
                                                                    paginationComment
                                                                }
                                                                getCommentList={
                                                                    getCommentList
                                                                }
                                                                audioId={
                                                                    audioId
                                                                }
                                                                onReportComment={
                                                                    onReportComment
                                                                }
                                                                contentPrivate={
                                                                    contentPrivate
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </TabPane>
                                    </Tabs>
                                )}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Modal
                className="modal-report"
                title={`${translator.t('title.report_comment')}`}
                visible={isModalVisible}
                onOk={() => handleOk(item)}
                onCancel={handleCancel}
                okButtonProps={{ disabled: !reportComment }}
                confirmLoading={confirmLoading}
                okText={`${translator.t('action.report')}`}
                cancelText={`${translator.t('action.cancell')}`}
            >
                <div className="radio">
                    <span>
                        <Radio.Group
                            onChange={(e) => {
                                setReportComment(e.target.value)
                            }}
                        >
                            <Radio
                                style={radioStyle}
                                value="Materi pornografi atau seksual vulgar"
                            >
                                Materi pornografi atau seksual vulgar
                            </Radio>
                            <Radio
                                style={radioStyle}
                                value="Perkataan yang mendorong kebencian atau
                                kekerasan"
                            >
                                Perkataan yang mendorong kebencian atau
                                kekerasan
                            </Radio>
                            <Radio
                                style={radioStyle}
                                value="Konten komersial atau spam yang tidak diinginkan"
                            >
                                Konten komersial atau spam yang tidak diinginkan
                            </Radio>
                            <Radio
                                style={radioStyle}
                                value="Pelecehan atau bullying"
                            >
                                Pelecehan atau bullying
                            </Radio>
                        </Radio.Group>
                    </span>
                </div>
            </Modal>

            <ModalShare
                urlFrom="audio"
                collectionId={audioData?.id}
                visibleModal={isModalVisibleShare}
                setVisibleModal={setIsModalVisibleShare}
            />

            <ModalContent
                visibleModal={popupModal}
                setVisibleModal={setPopupModal}
                data={epustakaAvailableData}
                dataRefresh={epustakaAvailable}
            />

            <ModalNoAccess
                visibleModal={popupNoAccess}
                setVisibleModal={setPopupNoAccess}
                title="title.audio_lowercase"
                contentPrivate={contentPrivate}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    stateTimeLine: get(state, 'timeLine', {}),
})

const mapDispatchToProps = () => ({
    setTimeLine: action.setTimeLine,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AudioDetail))
