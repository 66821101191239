import store from '../store'
import * as ActionType from './contant'

export default {
    setNotifReq: (value) => {
        const action = { type: ActionType.SET_NOTIFREQ, value }
        store.dispatch(action)
    },
    clearNotifReq: () => {
        const action = { type: ActionType.CLEAR_NOTIFREQ }
        store.dispatch(action)
    },
}
