import React, { useState, useLayoutEffect, useEffect } from 'react'
import FilterMembership from './FilterMembership'
import MembershipList from './MembershipList'
import provider from 'providers/provider'
import JoinRequest from './JoinRequest'
import { Row, Col, Tabs } from 'antd'
import reduxState from '../../redux'
import translator from 'i18next'
import url from 'constants/url'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { error } from 'utils/notification'

const { TabPane } = Tabs
const Membership = (props) => {
    const { profile = {} } = reduxState.getState()
    const [epustaka, setEpustaka] = useState(get(profile, 'epustaka', ''))
    const tabsKey = props?.location?.state?.type

    const defaultEpustaka = get(epustaka, '0.id', '')
    const [findEpustaka, setFindEpustaka] = useState(`${defaultEpustaka}`)
    const [visibleShow, setVisibleShow] = useState(false)

    const [memberEpustaka, setMemberEpustaka] = useState([])
    const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(true)
    const [loadMoreLoading, setLoadMoreLoading] = useState(false)
    const [hasMore, setHasMore] = useState(true)

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 460

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getProfile = async () => {
        await provider
            .singleWebHook(`${url.webhookURL_profile}`)
            .then((res) => {
                setEpustaka(res?.data?.epustaka)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    useEffect(() => {
        getProfile()
    }, [])

    //To get all query memberlist with status = 1 -> sudah jadi anggota
    const getMember = async () => {
        setLoading(true)
        await provider
            .list(
                url.webhookURL_epustaka_member_list +
                    `?limit=${limit}&offset=0&&status=1&epustaka_id=${findEpustaka}`
            )
            .then((res) => {
                setMemberEpustaka(res.data)
                // setTimeLine({ refresh: false })
                if (limit >= res.meta.total) {
                    setHasMore(false)
                } else {
                    setHasMore(true)
                }
                setLoadMoreLoading(false)
            })
            .finally(() => {
                // setTimeLine({ refresh: false })
                setLoading(false)
                setLoadMoreLoading(false)
            })
    }

    useEffect(() => {
        getMember()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, findEpustaka])

    return (
        <div>
            <Row type="flex" justify="center" className="container-membership">
                <Col
                    xs={24}
                    sm={19}
                    md={20}
                    lg={16}
                    xl={16}
                    style={{
                        borderTopLeftRadius: isMobile() ? '' : '15px',
                        borderTopRightRadius: isMobile() ? '' : '15px',
                    }}
                    className="container-filter-epustaka"
                >
                    <FilterMembership
                        dataOption={epustaka}
                        setFindEpustaka={setFindEpustaka}
                        findEpustaka={findEpustaka}
                        defaultEpustaka={defaultEpustaka}
                        setVisibleShow={setVisibleShow}
                        visibleShow={visibleShow}
                        getMemberlist={getMember}
                    />
                </Col>
                <Col
                    xs={24}
                    sm={19}
                    md={20}
                    lg={16}
                    xl={16}
                    style={{
                        borderBottomLeftRadius: isMobile() ? '' : '15px',
                        borderBottomRightRadius: isMobile() ? '' : '15px',
                    }}
                    className="container-list-epustaka"
                >
                    <Tabs
                        centered={isMobile() ? true : false}
                        tabPosition={isMobile() ? 'top' : 'left'}
                        className="tabs-list"
                        defaultActiveKey={tabsKey === 'join' ? '2' : ''}
                    >
                        <TabPane
                            tab={translator.t('title.epustaka_member')}
                            key="1"
                        >
                            <MembershipList
                                epustaka={epustaka}
                                setFindEpustaka={setFindEpustaka}
                                findEpustaka={findEpustaka}
                                memberEpustaka={memberEpustaka}
                                setLimit={setLimit}
                                limit={limit}
                                loading={loading}
                                hasMore={hasMore}
                                loadMoreLoading={loadMoreLoading}
                                setLoadMoreLoading={setLoadMoreLoading}
                                getMember={getMember}
                            />
                        </TabPane>
                        <TabPane tab={translator.t('title.askJoin')} key="2">
                            <JoinRequest
                                epustaka={epustaka}
                                setFindEpustaka={setFindEpustaka}
                                findEpustaka={findEpustaka}
                                getMemberlist={getMember}
                            />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = (state) => ({
    stateRouteHistory: get(state, 'routeHistory', {}),
})

export default connect(mapStateToProps)(Membership)
