import Axios from 'axios'
import interceptor from 'helpers/interceptor'
import options from 'helpers/options'
import isEmpty from 'lodash/isEmpty'

const https = require('https')

const request = {
    get: async (url, source = '') => {
        const option = options('get', url, {}, source)
        const promiseResult = await interceptor.request(option)
        return promiseResult ? promiseResult.data : []
    },
    getnotoken: async (url, source = '') => {
        const option = options('get', url, {}, source)
        const promiseResult = await Axios.request(option)
        return promiseResult ? promiseResult.data : []
    },
    post: async (url, params, source = '') => {
        const option = options('post', url, params, source)
        const promiseResult = await interceptor.request(option)
        return promiseResult ? promiseResult.data : []
    },
    borrow: async (url, params) => {
        const option = options('post', url, params, '', true)

        const promiseResult = await interceptor.request(option, {
            httpsAgent: new https.Agent({
                rejectUnauthorized: false,
            }),
        })
        return promiseResult ? promiseResult.data : []
    },
    patch: async (url, params, source = '') => {
        const option = options('patch', url, params, source)
        const promiseResult = await interceptor.request(option)
        return promiseResult ? promiseResult.data : []
    },
    put: async (url, params, source, fileType, extraParams) => {
        const option = options(
            'put',
            url,
            params,
            source,
            fileType,
            extraParams
        )
        const promiseResult = await interceptor.request({
            ...option,
            type: 'upload',
        })
        return promiseResult ? promiseResult.data : []
    },
    deleteData: async (url, params, type = '') => {
        const option = options('DELETE', url, params, type)
        const promiseResult = await interceptor.request(option)
        return promiseResult ? promiseResult.data : []
    },
    delete: async (url) => {
        const option = options('delete', url)
        const promiseResult = await interceptor.request(option)
        return promiseResult ? promiseResult.data : []
    },
    getStorage: async ({ url, headers = {}, body = {}, source = '' }) => {
        const option = options('get', url, {}, source)
        const promiseResult = await interceptor.request(
            isEmpty(headers)
                ? {
                      ...option,
                      type: 'external',
                      data: body,
                  }
                : isEmpty(body)
                ? {
                      ...option,
                      type: 'external',
                      headers: headers,
                  }
                : {
                      ...option,
                      type: 'external',
                      headers: headers,
                      data: body,
                  }
        )
        return promiseResult ? promiseResult.data : {}
    },
    postStorage: async ({
        url,
        body = {},
        source = '',
        type = '',
        extraParams = {},
    }) => {
        const option = options('post', url, body, source, type, extraParams)
        const promiseResult = await interceptor.request({
            ...option,
            type: 'external',
            data: body,
        })
        return promiseResult ? promiseResult.data : {}
    },
    deleteStorage: async ({ url, body = {}, source = '' }) => {
        const option = options('delete', url, body, source)
        const promiseResult = await interceptor.request({
            ...option,
            type: 'external',
        })
        return promiseResult ? promiseResult.data : {}
    },
}

export default request
