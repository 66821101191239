import React, { useEffect, useState, useLayoutEffect } from 'react'
import provider from 'providers/provider'
import url from 'constants/url'
import { Row, Input, Col, Button } from 'antd'
import isEmpty from 'lodash/isEmpty'
import translator from 'i18next'
import { ArrowDownOutlined, SearchOutlined } from '@ant-design/icons'
import MoreEpustakaComponent from '../components/MoreEpustakaComponent'
import { error } from 'utils/notification'
import { SkeletonBook } from 'components'
import { illus_emptyfeeds } from '../../../../../assets/icons'

const MoreEpustaka = () => {
    const [totalData, setTotalData] = useState(0)
    const [limit, setLimit] = useState(20)
    const [epustakaData, setEpustakaData] = useState([])
    const [paramName, setParamName] = useState('')
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isSmallMobile = () => state.width <= 360
    const isMobile = () => state.width <= 420
    const isTablet = () => state.width <= 768

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getEpustakaData = async () => {
        setLoading(true)
        await provider
            .list(encodeURI(url.webhookURL_epustaka_list + `?limit=${limit}`))
            .then((res) => {
                const { data, meta } = res
                setEpustakaData(data)
                setTotalData(meta.total)
                setLoading(false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
                return
            })
    }

    const handleSearch = async (e) => {
        if (e.key === 'Enter') {
            await provider
                .list(url.webhookURL_epustaka_list + `?q=${paramName}`)
                .then((res) => {
                    setEpustakaData(res.data)
                    setTotalData(res.meta.total)
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        getEpustakaData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit])

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={state.loading}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 15)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }
    const EmptyDataEpustaka = () => (
        <div style={{ textAlign: 'center' }}>
            <img
                src={illus_emptyfeeds}
                alt="Mari bergabung dan berkolaborasi mengembangkan pendidikan Indonesia bersama anak bangsa."
                style={{ width: '200px' }}
            />
            <p style={{ fontWeight: 600 }}>
                {translator.t('title.emptyEpustaka')}
            </p>
        </div>
    )

    return (
        <div
            style={{
                marginTop: '-15px',
                maxWidth: 1030,
                margin: '0 auto',
            }}
        >
            <div>
                {loading ? (
                    <Row justify="center" align="middle">
                        <SkeletonBook source="fullskeleton" />
                    </Row>
                ) : (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div
                                className="d-epustaka-container-title"
                                // style={{
                                //     maxWidth: 1050,
                                //     margin: !isMobile() && '5px 1rem',
                                // }}
                            >
                                {translator.t('column.epustaka')}
                            </div>
                            <div className="epustaka-input">
                                <Input
                                    placeholder="Cari Epustaka"
                                    suffix={<SearchOutlined />}
                                    onChange={(e) =>
                                        setParamName(e.target.value)
                                    }
                                    onKeyPress={handleSearch}
                                />
                            </div>
                        </div>

                        <div
                            className="epustaka-container"
                            style={{
                                padding: !isMobile() && '8px',
                            }}
                        >
                            {isEmpty(epustakaData) ? (
                                <EmptyDataEpustaka />
                            ) : (
                                <Row
                                    style={{
                                        justifyContent: isTablet()
                                            ? 'center'
                                            : 'flex-start',
                                        maxWidth: 1050,
                                        marginBottom: 20,
                                    }}
                                    gutter={
                                        isSmallMobile()
                                            ? [8, 8]
                                            : isMobile()
                                            ? [16, 16]
                                            : [32, 32]
                                    }
                                >
                                    {epustakaData.map((item, id) => (
                                        <Col span={3.5}>
                                            <MoreEpustakaComponent
                                                fromMoreEpustaka
                                                data={item}
                                                key={id}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            )}

                            <div className="loadmore-btn">
                                <DispalyButton />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default MoreEpustaka
