import React, { useState, useEffect } from 'react'
import Modal from 'antd/lib/modal/Modal'
import Dragger from 'antd/lib/upload/Dragger'
import { InboxOutlined } from '@ant-design/icons'
import { Button, Progress, Spin, Form } from 'antd'
import provider from 'providers/provider'
import { error, success } from 'utils/notification'
import { PreviewImage } from 'components'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import getBase64 from 'utils/getBase64'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import translator from 'i18next'
import { v4 as uuidv4 } from 'uuid'
import action from 'redux/timeLine/action'
import url from 'constants/url'
import { COLORS } from 'constants/constant'

const ModalChangePhoto = ({
    visibleModal,
    setVisibleModal,
    dataUser,
    fileListData,
}) => {
    const [isLoadingUpload, setIsLoadingUpload] = useState(false)
    const [progress, setProgress] = useState(0)
    const [fileUrl, setFileUrl] = useState('')
    const [fileList, setFileList] = useState([])
    const [previewImage, setPreviewImage] = useState('')
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewTitle, setPreviewTitle] = useState('')
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const organization_id = get(dataUser, 'organizations.id', '')

    const updateData = async (data) => {
        setLoading(true)
        await provider
            .update(url.authURL_auth_account, { ...data })
            .then(() => {
                setVisibleModal()
                window.location.reload()

                success({
                    description: 'Berhasil menyimpan data',
                    placement: 'bottomRight',
                })
                setLoading(false)
            })
            .catch((err) => {
                Promise.reject(err)
                setLoading(false)
            })
    }

    const handleSave = async () => {
        setLoading(true)
        updateData({
            scope: 'profile',
            data: {
                avatar_file_id: fileUrl,
            },
        })
    }

    const onUploadProgress = (progressEvent) => {
        if (progressEvent) {
            setProgress(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
        }
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }

        setPreviewImage(file.url || file.preview)
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        )
        setPreviewVisible(true)
    }

    const handleChange = ({ fileList }) => {
        const fileSize = get(fileList, '0.size')

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-1)

        // 2. Read from response and show file link
        fileList = fileList.map((file) => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url
            }
            return file
        })

        if (!isEmpty(fileList)) {
            const isLt2M = fileSize / 1024 / 1024 < 2
            if (!isLt2M) {
                error({
                    description: translator.t('validation.maxImage'),
                    placement: 'bottomRight',
                })
            } else {
                setFileList(fileList)
            }
        } else {
            setFileList(fileList)
        }
    }

    useEffect(() => {
        setFileList(fileListData)
    }, [fileListData])

    const customRequest = async ({ onSuccess, onError, file }) => {
        const fileSize = file.size

        const isLt2M = fileSize / 1024 / 1024 < 2
        if (isLt2M) {
            setIsLoadingUpload(true)

            const data = new FormData()
            const dataBody = [
                {
                    name: 'bucketName',
                    value: organization_id,
                },
                {
                    name: 'storagePath',
                    value: 'images/avatars',
                },
                {
                    name: 'file',
                    value: file,
                },
                {
                    name: 'fileName',
                    value: uuidv4(),
                },
            ]

            dataBody.map((item) => data.append(item.name, item.value))

            await provider
                .uploadStorage({
                    url: url.storageURL_upload_object,
                    body: data,
                    extraParams: {
                        onUploadProgress: onUploadProgress,
                    },
                })
                .then((data) => {
                    setFileUrl(data.objId)
                    setIsLoadingUpload(false)
                    setProgress(0)
                    onSuccess()
                })
                .catch((err) => {
                    const statusCode = err.response?.status
                    const message = err.response?.data?.errorMessage
                    if (
                        statusCode === 403 &&
                        message === 'Quota capacity is exceed'
                    ) {
                        error({
                            description:
                                'Maaf, Anda tidak dapat mengunggah file, karena kapasitas penyimpanan telah habis.',
                            placement: 'bottomRight',
                        })
                    } else {
                        error({
                            description: err.message,
                            placement: 'bottomRight',
                        })
                    }
                    setIsLoadingUpload(false)
                    setProgress(0)
                    onError()
                })
        }
    }

    return (
        <>
            <Form form={form} name="inputForm">
                <Modal
                    centered
                    visible={visibleModal}
                    onOk={() => setVisibleModal(!visibleModal)}
                    onCancel={() => setVisibleModal(!visibleModal)}
                    footer={null}
                >
                    <Form.Item name="avatar_file_id">
                        <Dragger
                            style={{ border: 'none', backgroundColor: 'white' }}
                            // accept=".jpg, .png, .jpeg"
                            accept="image/png, image/jpeg, image/gif, image/jpg"
                            customRequest={customRequest}
                            onRemove={true}
                            fileList={fileList ?? []}
                            listType="picture"
                            name="avatar_file_id"
                            multiple={false}
                            onPreview={handlePreview}
                            onChange={handleChange}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">
                                Klik atau seret file ke area ini untuk diunggah
                            </p>
                        </Dragger>
                    </Form.Item>

                    {/* progress bar */}
                    {isLoadingUpload &&
                        (progress === 100 ? (
                            <Form.Item>
                                <div>
                                    {translator.t('action.finishing')}
                                    <Spin
                                        style={{
                                            marginTop: '5px',
                                            marginLeft: '5px',
                                        }}
                                    />
                                </div>
                            </Form.Item>
                        ) : (
                            <Form.Item>
                                <Progress
                                    strokeColor={{
                                        from: '#e2f3ff',
                                        to: COLORS.primaryColor,
                                    }}
                                    status="active"
                                    percent={progress}
                                />
                            </Form.Item>
                        ))}

                    <PreviewImage
                        visible={previewVisible}
                        title={previewTitle}
                        handleCancel={() => setPreviewVisible(false)}
                        imgSrc={previewImage}
                    />

                    <div style={{ textAlign: 'center' }}>
                        <Form onFinish={handleSave}>
                            <Form.Item>
                                <Button
                                    disabled={!fileUrl}
                                    className="btn-modal"
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    style={{
                                        borderRadius: '25px',
                                        color: 'white',
                                        backgroundColor: COLORS.primaryColor,
                                        borderColor: COLORS.primaryColor,
                                        marginTop: '15px',
                                    }}
                                >
                                    {translator.t('action.save')}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Modal>
            </Form>
        </>
    )
}

const mapDispatchToProps = () => ({
    setTimeLine: action.setTimeLine,
})

export default connect(mapDispatchToProps)(withRouter(ModalChangePhoto))
