import store from '../store'
import * as ActionType from './contant'

export default {
    setLastReadingDuration: (value) => {
        const action = { type: ActionType.SET_LAST_READING_DURATION, value }
        store.dispatch(action)
    },
    clearLastReadingDuration: () => {
        const action = { type: ActionType.CLEAR_LAST_READING_DURATION }
        store.dispatch(action)
    },
}
