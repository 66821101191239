import find from 'lodash/find'
import forEach from 'lodash/forEach'
import isArray from 'lodash/isArray'
import keys from 'lodash/keys'
import map from 'lodash/map'

export const toList = (data, included) =>
    map(data, (d) => {
        const attributes = d.attributes || d

        const relationships = {
            ...d.relationships,
        }

        //mapping relationships data from `include`
        forEach(keys(relationships), (key) => {
            let relationData = relationships[key].data

            const findIncludeData = relationData
                ? find(included, (incl) => incl.id === relationData.id)
                : undefined

            //if relationship is array
            if (isArray(relationData)) {
                relationData = map(relationData, (rel) => {
                    const findIncludeData = rel
                        ? find(included, (incl) => incl.id === rel.id)
                        : undefined

                    if (findIncludeData) {
                        return {
                            ...findIncludeData.attributes,
                            id: findIncludeData.id,
                        }
                    }

                    return rel
                })
            }

            relationships[key] = {
                ...relationData,
            }

            if (findIncludeData) {
                relationships[key] = {
                    ...findIncludeData.attributes,
                    id: findIncludeData.id,
                }
            }
        })

        return {
            ...attributes,
            ...relationships,
            id: d.id,
        }
    })

export const toSingle = (data, included) => {
    const { attributes, id } = data

    const relationships = {
        ...data.relationships,
    }

    //mapping relationships data from `include`
    forEach(keys(relationships), (key) => {
        let relationData = relationships[key].data

        const findIncludeData = relationships[key].data
            ? find(included, (incl) => incl.id === relationships[key].data.id)
            : undefined

        //if relationship is array
        if (isArray(relationData)) {
            relationData = map(relationData, (rel) => {
                const findIncludeData = rel
                    ? find(included, (incl) => incl.id === rel.id)
                    : undefined

                if (findIncludeData) {
                    return {
                        ...findIncludeData.attributes,
                        id: findIncludeData.id,
                    }
                }

                return rel
            })
        }

        relationships[key] = {
            ...relationships[key].data,
        }

        if (findIncludeData) {
            relationships[key] = {
                ...findIncludeData.attributes,
                id: findIncludeData.id,
            }
        }
    })

    return {
        ...attributes,
        ...relationships,
        id,
    }
}
