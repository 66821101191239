import * as ActionType from './contant'


const initState = false

const reducer = (state = initState, action) => {
    const { type, value } = action

    switch (type) {
        case ActionType.SET_FROM_READER:
            return value
        case ActionType.CLEAR_FROM_READER:
            return initState
        default:
            return state
    }
}

export default reducer
