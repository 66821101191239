import { lazy } from 'react'
import Search from 'views/search/Search'
import {
    BooksListByCategory,
    BookDetail,
    BookReadEpub,
    BookReadPdfDRM,
    BookDetailPublic,
} from 'views/home/books'
import { VideoDetail, VideoDetailPublic } from 'views/home/video'
import { AudioDetail, AudioDetailPublic } from 'views/home/audio'
import Profile from 'views/profile'
import BooksProfile from 'views/profile/books'
import MoreHistoryRead from 'views/profile/books/MoreHistoryShelf'
import Video from 'views/profile/video'
import MoreHistoryVideo from 'views/profile/video/MoreHistoryVideo'
import Audio from 'views/profile/audio'
import MoreHistoryAudio from 'views/profile/audio/MoreHistoryAudio'
import DetailNotifications from 'views/notifications'
import Friendship from 'views/friendship'
import Membership from 'views/membership'
import Timeline from 'views/timeline/index'
import DetailTimeline from 'views/timeline/feed/DetailTimeline'
import Setting from 'views/profile/setting'
import MoreEpustaka from 'views/home/beranda/epustaka/moreEpustaka'
import DetailEpustaka from 'views/home/beranda/epustaka/detailEpustaka'
import EpustakaCategoryBook from 'views/home/beranda/epustaka/epustakaCategory'
import BookShelf from 'views/bookshelf'
import MoreBookShelf from 'views/bookshelf/borrow/book/MoreBookShelf'
import MoreBookQueue from 'views/bookshelf/queue/MoreBookQueue'
import MoreBookReview from 'views/bookshelf/review/MoreBookReview'
import MoreBookHistory from 'views/bookshelf/history/MoreBookHistory'
import ProfileUser from 'views/profile/userProfil/index'
// import DetailQuiz from 'views/quiz/detailQuiz'
// import QuizPlayground from 'views/quiz/playground'
// import EndResult from 'views/quiz/end-result'
// import TryoutExplanation from 'views/quiz/tryout-explanation'
import Payment from 'views/payment/Payment'
import DetailOrderBox from 'views/payment/components/DetailBoxOrder'
import DetailPayment from 'views/payment/components/DetailPayment'
import NewsList from 'views/home/beranda/news/NewsLists'
import MoreNewsHistory from 'views/bookshelf/history/article/MoreNewsHistory'
import NewsDetail from 'views/home/beranda/news/NewsDetail'
// import eseb from 'views/quiz/playground/Eseb'
import Donation from 'views/donation'
import MoreBookDonation from 'views/bookshelf/donation/MoreBookDonation'
import ListArticle from 'views/home/beranda/epustaka/detailEpustaka/components/ListArticleEpustaka'
import ListVideoEpustaka from 'views/home/beranda/epustaka/detailEpustaka/components/ListVideoEpustaka'
import ListAudioEpustak from 'views/home/beranda/epustaka/detailEpustaka/components/ListAudioEpustak'
import Help from 'views/help/Help'
const DetailEpustakaPublic = lazy(() =>
    import('views/home/beranda/epustaka/detailEpustaka/DetailEpustakaPublic')
)
const ArticleDetailPublic = lazy(() =>
    import('views/home/beranda/news/ArticleDetailPublic')
)
const Cart = lazy(() => import('views/cart'))

const Home = lazy(() => import('views/home/Home'))
const Error = lazy(() => import('views/error'))
const LandingQR = lazy(() => import('views/qrcode'))

var routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/error',
        name: 'Error',
        component: Error,
    },
    {
        path: '/search',
        name: 'Search',
        component: Search,
    },
    {
        path: `/qr/:uuid/:location?`,
        name: 'Landing QRCode',
        component: LandingQR,
        publicComponent: LandingQR,
    },
    {
        path: '/books',
        name: 'Book',
        component: BooksListByCategory,
    },
    {
        path: '/book/:uuid/:epustaka?/:d?',
        name: 'Detail Book',
        component: BookDetail,
        publicComponent: BookDetailPublic,
    },

    {
        path: '/read-book',
        name: 'Read Book',
        component: BookReadPdfDRM,
    },
    {
        path: '/read-book-epub',
        name: 'Read Book Epub',
        component: BookReadEpub,
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
    },
    {
        path: '/books-profile',
        name: 'Books',
        component: BooksProfile,
    },
    {
        path: '/book-history-list',
        name: 'More History Read',
        component: MoreHistoryRead,
    },
    {
        path: '/video',
        name: 'Video',
        component: Video,
    },
    {
        path: '/video/:uuid/:epustaka?/:d?',
        name: 'Detail Video',
        component: VideoDetail,
        publicComponent: VideoDetailPublic,
    },
    {
        path: '/more-history-video',
        name: 'More History Video',
        component: MoreHistoryVideo,
    },
    {
        path: '/audio',
        name: 'Audio',
        component: Audio,
    },

    {
        path: '/audio/:uuid/:epustaka?/:d?',
        name: 'Detail Audio',
        component: AudioDetail,
        publicComponent: AudioDetailPublic,
    },
    {
        path: '/more-history-audio',
        name: 'More History Audio',
        component: MoreHistoryAudio,
    },
    {
        path: '/notifications-detail',
        name: 'Detail Notifications',
        component: DetailNotifications,
    },
    {
        path: '/friendship',
        name: 'Friendship',
        component: Friendship,
    },
    {
        path: '/membership',
        name: 'Membership',
        component: Membership,
    },

    {
        path: '/timeline',
        name: 'Home',
        component: Timeline,
    },
    {
        path: `/timeline/detail/:detailId`,
        name: 'User Activity',
        component: DetailTimeline,
    },
    {
        path: `/setting`,
        name: 'Setting',
        component: Setting,
    },
    {
        path: `/more-epustaka`,
        name: 'MoreEpustaka',
        component: MoreEpustaka,
    },
    {
        path: `/epustaka/:uuid`,
        name: 'DetailEpustaka',
        component: DetailEpustaka,
        publicComponent: DetailEpustakaPublic,
    },
    {
        path: `/epustaka-book-category`,
        name: 'EpustakaCategoryBook',
        component: EpustakaCategoryBook,
    },

    {
        path: `/shelf`,
        name: 'BookShelf',
        component: BookShelf,
    },
    {
        path: `/more-book-shelf`,
        name: 'MoreBookShelf',
        component: MoreBookShelf,
    },
    {
        path: `/more-book-queue`,
        name: 'MoreBookQueue',
        component: MoreBookQueue,
    },
    {
        path: `/more-book-review`,
        name: 'MoreBookReview',
        component: MoreBookReview,
    },
    {
        path: `/more-book-history`,
        name: 'MoreBookHistory',
        component: MoreBookHistory,
    },
    {
        path: `/profil/user/:uuid`,
        name: 'ProfileUser',
        component: ProfileUser,
    },
    // {
    //     path: `/quiz/detail/:uuid`,
    //     name: 'DetailQuiz',
    //     component: DetailQuiz,
    // },
    // {
    //     path: `/quiz/detail/:uuid`,
    //     name: 'DetailQuiz',
    //     component: DetailQuiz,
    // },
    // {
    //     path: `/quiz/play/:uuid`,
    //     name: 'QuizPlayground',
    //     component: QuizPlayground,
    // },
    // {
    //     path: `/end-result`,
    //     name: 'EndResult',
    //     component: EndResult,
    // },
    // {
    //     path: `/tryout-explanation`,
    //     name: 'TryoutExplanation',
    //     component: TryoutExplanation,
    // },
    {
        path: `/payment`,
        name: 'Payment',
        component: Payment,
    },
    // {
    //     path: `/history-activity`,
    //     name: 'HistoryActivity',
    //     component: HistoryActivity,
    // },
    {
        path: `/detail-order-box`,
        name: 'DetailOrderBox',
        component: DetailOrderBox,
    },
    {
        path: `/detail-order-box/payment`,
        name: 'DetailPayment',
        component: DetailPayment,
    },
    {
        path: `/article`,
        name: 'News List',
        component: NewsList,
    },
    {
        path: `/more-article-history`,
        name: 'More News History',
        component: MoreNewsHistory,
    },
    {
        path: `/article/:uuid/:epustaka?`,
        name: 'News Detail',
        component: NewsDetail,
        publicComponent: ArticleDetailPublic,
    },
    // {
    //     path: `/eseb/:uuid`,
    //     name: 'ESEB',
    //     component: eseb,
    // },
    {
        path: `/donation`,
        name: 'Donation',
        component: Donation,
    },
    {
        path: `/more-book-donation`,
        name: 'More Book Donation',
        component: MoreBookDonation,
    },
    {
        path: `/list-article-epustaka`,
        name: 'List Article',
        component: ListArticle,
    },
    {
        path: `/list-video-epustaka`,
        name: 'List Video Epustaka',
        component: ListVideoEpustaka,
    },
    {
        path: `/list-audio-epustaka`,
        name: 'List Audio Epustaka',
        component: ListAudioEpustak,
    },
    {
        path: `/help`,
        name: 'Help',
        component: Help,
    },
    {
        path: '/cart/:uuid/:category',
        name: 'Keranjang',
        component: Cart,
    },
]
export default routes
