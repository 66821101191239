import React, { useState, useEffect, useLayoutEffect } from 'react'
import translator from 'i18next'
import { Card, Col, Empty, Modal, Row, Tooltip } from 'antd'
import provider from 'providers/provider'
import url from 'constants/url'
import { useHistory } from 'react-router-dom'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { FallbackImage, SkeletonBook } from 'components'
import { ModalShare } from 'components'
import getPagination from 'constants/getPagination'
import reduxState from '../../../redux'
import ModalRecommendation from 'views/home/books/bookDetail/ModalRecommendation'

// Jangan dihapus dulu

// import DELETE_BOOK_QUEUE from 'views/bookshelf/queue/mutation/deleteQueueById'
// import { useMutation } from '@apollo/client'
// import { COLORS } from 'constants/constant'

import Carousel from 'nuka-carousel'
import Slider from 'react-slick'
import Text from 'antd/lib/typography/Text'
import Meta from 'antd/lib/card/Meta'
import {
    ExclamationCircleOutlined,
    RetweetOutlined,
    RollbackOutlined,
    ShareAltOutlined,
    // RollbackOutlined,
    // ExclamationCircleOutlined,
} from '@ant-design/icons'
import { BASE_TITLE, COLORS } from 'constants/constant'
import { error } from 'utils/notification'

const { confirm } = Modal
const Queue = () => {
    const history = useHistory()
    const { profile = {} } = reduxState.getState()
    const organization_id = profile.organizations.id
    const [bookData, setBookData] = useState([])
    const [loading, setLoading] = useState(false)
    const [userFollower, setUserFollower] = useState({})
    const [currentPageFollower, setCurrentPageFollower] = useState(1)
    const [paginationFollower, setPaginationFollower] = useState({
        loading: true,
        pagination: true,
    })
    const [querySearchFollower, setQuerySearchFollower] = useState()
    const [dataFollowerChanged, setDataFollowerChanged] = useState(true)
    const [visibleModalRecommendation, setVisibleModalRecommendation] =
        useState(false)
    const [popShare, setPopShare] = useState(false)
    const [itemBook, setItemBook] = useState({})
    const [bookShare, setBookShare] = useState([])

    // const [deleteBookQueue] = useMutation(DELETE_BOOK_QUEUE)

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    // const isSmallMobile = () => state.width <= 360
    const isMobile = () => state.width <= 420
    const isTablet = () => state.width <= 768
    const isDesktop = () => state.width <= 1024
    const xlDesktop = () => state.width <= 1440
    const xxlDesktop = () => state.width <= 1920
    const desktop4K = () => state.width <= 2560
    const UHD4k = () => state.width >= 2561

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const settings = {
        infinite: false,
        speed: 400,
        initialSlide: 0,
        dots: false,
        arrows: false,
        slidesToShow: 2,
        adaptiveHeight: false,
        swipeToSlide: true,
    }

    const getBookData = async () => {
        setLoading(true)
        await provider
            .list(url.webhookUrl_queue_shelf)
            .then((res) => {
                setBookData(res.data)
                setLoading(false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
            })
    }

    const getDataFollower = async () => {
        const limit = 10
        const pagination = getPagination(currentPageFollower, limit, 'webhook')
        const filterQuery = isEmpty(querySearchFollower)
            ? ''
            : {
                  'users.name': { ilike: `%${querySearchFollower}%` },
              }
        await provider
            .list(
                encodeURI(
                    `${url.webhookURL_follower}${
                        pagination.result
                    }&filter=${JSON.stringify(filterQuery)}&q=&user_id=${
                        profile.id
                    }`
                )
            )
            .then((res) => {
                const { data, meta } = res
                const filterData = filter(data, (e) => e.id !== profile.id)
                if (dataFollowerChanged) {
                    setUserFollower(filterData)
                } else {
                    setUserFollower(
                        isEmpty(userFollower)
                            ? filterData
                            : concat(userFollower, filterData)
                    )
                }

                setPaginationFollower({
                    ...paginationFollower,
                    loading: false,
                })
                if (pagination.offset >= meta.total || meta.total <= limit) {
                    setPaginationFollower({
                        loading: false,
                        pagination: false,
                    })
                }
            })
    }

    useEffect(() => {
        getDataFollower()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [querySearchFollower])

    useEffect(() => {
        getBookData()
        // disable warning missing dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onLoadMoreFollower = async () => {
        setPaginationFollower({
            ...paginationFollower,
            loading: true,
        })
        setCurrentPageFollower(currentPageFollower + 1)
    }

    // Jangan dihapus dulu

    // const onDeleteQueue = async (value) => {
    //     confirm({
    //         title: (
    //             <h4 style={{ color: COLORS.primaryColor }}>{`${translator.t(
    //                 'Keluar Antrian'
    //             )}`}</h4>
    //         ),
    //         icon: <ExclamationCircleOutlined />,
    //         content: (
    //             <div>
    //                 {translator.t(
    //                     'Anda yakin ingin keluar dari antrian, Anda harus mengantri kembali jika ingin mendapatkan pemberitahuan ketersediaan buku ini?'
    //                 )}
    //             </div>
    //         ),
    //         okText: `${translator.t('message.yes')}`,
    //         cancelText: `${translator.t('message.no')}`,
    //         okType: 'danger',

    //         onOk: async () => {
    //             await deleteBookQueue({
    //                 variables: {
    //                     ids: value.id,
    //                 },
    //             })
    //                 .then(() => {
    //                     getBookData()
    //                 })
    //                 .catch((err) =>
    //                     error({
    //                         description: err.message,
    //                         placement: 'bottomLeft',
    //                     })
    //                 )
    //         },
    //         onCancel() {},
    //     })
    // }

    const onDeleteQueue = async (value) => {
        confirm({
            title: (
                <h4 style={{ color: COLORS.primaryColor }}>{`${translator.t(
                    'navigation.outQueue'
                )}`}</h4>
            ),
            icon: <ExclamationCircleOutlined />,
            content: <div>{translator.t('bookshelf.out_queue')}</div>,
            okText: `${translator.t('message.yes')}`,
            cancelText: `${translator.t('message.no')}`,
            okType: 'danger',

            onOk: async () => {
                try {
                    await provider.delete(
                        url.webhookURL_book_queue + `?queue_id=${value?.id}`
                    )

                    getBookData()
                } catch (err) {
                    error({
                        description: translator.t('message.error'),
                        placement: 'bottomRight',
                    })
                }
            },
        })
    }

    const handleClickShare = () => {
        setPopShare(true)
    }

    const handleClickRecommend = () => {
        setVisibleModalRecommendation(true)
    }

    return (
        <React.Fragment>
            {loading ? (
                <SkeletonBook />
            ) : isEmpty(bookData) ? (
                <div>
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span style={{ color: COLORS.primaryColor }}>
                                {translator.t('bookshelf.notifQueue')}
                            </span>
                        }
                    />
                </div>
            ) : (
                <>
                    <div className="bs__containerBorrow">
                        <p
                            style={{
                                color: 'rgb(87, 87, 87)',
                                fontWeight: 600,
                            }}
                        >
                            {translator.t('menu.book')}
                        </p>
                        {bookData.length > 5 && (
                            <p
                                onClick={() =>
                                    history.push({
                                        pathname: '/more-book-queue',
                                    })
                                }
                                style={{
                                    cursor: 'pointer',
                                    color: COLORS.primaryColor,
                                }}
                            >
                                {translator.t('action.fully')}
                            </p>
                        )}
                    </div>
                    <Row className="bs__conte">
                        {isMobile() ? (
                            <Col span={24}>
                                <Slider
                                    className={
                                        bookData.length === 1
                                            ? 'single-react-slick'
                                            : 'react-slick'
                                    }
                                    {...settings}
                                >
                                    {map(bookData, (book, key) => {
                                        const {
                                            cover_url,
                                            book_title,
                                            book_author,
                                            book_id: id,
                                            epustaka_id,
                                        } = book
                                        return (
                                            <Col key={key}>
                                                <div className="bs__containerComp">
                                                    <Card
                                                        style={{ width: 160 }}
                                                        className="bs__containerCompCardQueue"
                                                        cover={
                                                            <div className="container-book">
                                                                <div className="book">
                                                                    <div className="front">
                                                                        <div className="cover">
                                                                            <FallbackImage
                                                                                alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                                                                src={
                                                                                    cover_url
                                                                                }
                                                                                onClick={() =>
                                                                                    history.push(
                                                                                        {
                                                                                            pathname: `/book/${id}/${
                                                                                                epustaka_id ||
                                                                                                ''
                                                                                            }`,
                                                                                            state: {
                                                                                                epustaka_id,
                                                                                                fromQueue: true,
                                                                                            },
                                                                                        }
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    width: 135,
                                                                                    height: 185,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="left-side">
                                                                        <h2
                                                                            style={{
                                                                                height: 20,
                                                                                width: 185,
                                                                                marginLeft:
                                                                                    -15,
                                                                                backgroundImage: `url(${cover_url})`,
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                <Text
                                                                                    style={{
                                                                                        width: 155,
                                                                                        fontSize: 10,
                                                                                        color: 'white',
                                                                                        paddingRight: 10,
                                                                                    }}
                                                                                    ellipsis={
                                                                                        true
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        book_author
                                                                                    }
                                                                                </Text>
                                                                            </span>
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        actions={[
                                                            <Tooltip
                                                                title={translator.t(
                                                                    'action.recommendation'
                                                                )}
                                                                color="#575757"
                                                            >
                                                                <RetweetOutlined
                                                                    key="recommendation"
                                                                    onClick={() =>
                                                                        handleClickRecommend(
                                                                            setItemBook(
                                                                                book
                                                                            )
                                                                        )
                                                                    }
                                                                />
                                                            </Tooltip>,
                                                            <Tooltip
                                                                title={translator.t(
                                                                    'title.share'
                                                                )}
                                                                color="#575757"
                                                            >
                                                                <ShareAltOutlined
                                                                    key="share"
                                                                    onClick={() =>
                                                                        handleClickShare(
                                                                            setBookShare(
                                                                                book
                                                                            )
                                                                        )
                                                                    }
                                                                />
                                                            </Tooltip>,

                                                            // Jangan dihapus dulu

                                                            // <Tooltip
                                                            //     title={translator.t(
                                                            //         'title.share'
                                                            //     )}
                                                            //     color="#575757"
                                                            // >
                                                            //     <RollbackOutlined
                                                            //         key="exit"
                                                            //         onClick={() =>
                                                            //             onDeleteQueue(
                                                            //                 book
                                                            //             )
                                                            //         }
                                                            //     />
                                                            // </Tooltip>,
                                                        ]}
                                                    >
                                                        <Meta
                                                            title={
                                                                <Tooltip
                                                                    title={
                                                                        book_title
                                                                    }
                                                                    color="#575757"
                                                                    placement="topLeft"
                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontWeight: 600,
                                                                        }}
                                                                        onClick={() =>
                                                                            history.push(
                                                                                {
                                                                                    pathname: `/book/${id}/${
                                                                                        epustaka_id ||
                                                                                        ''
                                                                                    }`,
                                                                                    state: {
                                                                                        epustaka_id,
                                                                                        fromQueue: true,
                                                                                    },
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            book_title
                                                                        }
                                                                    </span>
                                                                </Tooltip>
                                                            }
                                                            description={
                                                                <Text
                                                                    style={{
                                                                        width: 140,
                                                                        fontSize: 13,
                                                                        color: 'gray',
                                                                    }}
                                                                    ellipsis={
                                                                        true
                                                                    }
                                                                >
                                                                    <Tooltip
                                                                        title={
                                                                            book_author
                                                                        }
                                                                        color="#575757"
                                                                        placement="topLeft"
                                                                    >
                                                                        {
                                                                            book_author
                                                                        }
                                                                    </Tooltip>
                                                                </Text>
                                                            }
                                                        />
                                                    </Card>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Slider>
                            </Col>
                        ) : (
                            <Carousel
                                speed={50}
                                withoutControls={true}
                                disableEdgeSwiping={false}
                                slidesToScroll="auto"
                                scrollMode="remainder"
                                slideWidth={
                                    isMobile()
                                        ? 0.55
                                        : isTablet()
                                        ? 0.35
                                        : isDesktop()
                                        ? 0.25
                                        : xlDesktop()
                                        ? 0.2
                                        : xxlDesktop()
                                        ? 0.2
                                        : desktop4K()
                                        ? 0.2
                                        : UHD4k()
                                        ? 0.2
                                        : 0.2
                                }
                                cellAlign="left"
                            >
                                {map(bookData, (book, key) => {
                                    const {
                                        cover_url,
                                        book_title,
                                        book_author,
                                        book_id: id,
                                        epustaka_id,
                                    } = book

                                    return (
                                        <Col key={key}>
                                            <div className="bs__containerComp">
                                                <Card
                                                    style={{ width: 160 }}
                                                    className="bs__containerCompCardQueue"
                                                    cover={
                                                        <div className="container-book">
                                                            <div className="book">
                                                                <div className="front">
                                                                    <div className="cover">
                                                                        <FallbackImage
                                                                            alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                                                            src={
                                                                                cover_url
                                                                            }
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    {
                                                                                        pathname: `/book/${id}/${
                                                                                            epustaka_id ||
                                                                                            ''
                                                                                        }`,
                                                                                        state: {
                                                                                            epustaka_id,
                                                                                            fromQueue: true,
                                                                                        },
                                                                                    }
                                                                                )
                                                                            }
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                width: 135,
                                                                                height: 185,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="left-side">
                                                                    <h2
                                                                        style={{
                                                                            height: 20,
                                                                            width: 185,
                                                                            marginLeft:
                                                                                -15,
                                                                            backgroundImage: `url(${cover_url})`,
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            <Text
                                                                                style={{
                                                                                    width: 155,
                                                                                    fontSize: 10,
                                                                                    color: 'white',
                                                                                    paddingRight: 10,
                                                                                }}
                                                                                ellipsis={
                                                                                    true
                                                                                }
                                                                            >
                                                                                {
                                                                                    book_author
                                                                                }
                                                                            </Text>
                                                                        </span>
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    actions={[
                                                        <Tooltip
                                                            title={translator.t(
                                                                'action.recommendation'
                                                            )}
                                                            color="#575757"
                                                        >
                                                            <RetweetOutlined
                                                                key="recommendation"
                                                                onClick={() =>
                                                                    handleClickRecommend(
                                                                        setItemBook(
                                                                            book
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                        </Tooltip>,
                                                        <Tooltip
                                                            title={translator.t(
                                                                'title.share'
                                                            )}
                                                            color="#575757"
                                                        >
                                                            <ShareAltOutlined
                                                                key="share"
                                                                onClick={() =>
                                                                    handleClickShare(
                                                                        setBookShare(
                                                                            book
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                        </Tooltip>,
                                                        // Jangan dihapus dulu

                                                        <Tooltip
                                                            title={translator.t(
                                                                'Keluar Antrian'
                                                            )}
                                                            color="#575757"
                                                        >
                                                            <RollbackOutlined
                                                                key="exit"
                                                                onClick={() =>
                                                                    onDeleteQueue(
                                                                        book
                                                                    )
                                                                }
                                                            />
                                                        </Tooltip>,
                                                    ]}
                                                >
                                                    <Meta
                                                        title={
                                                            <Tooltip
                                                                title={
                                                                    book_title
                                                                }
                                                                color="#575757"
                                                                placement="topLeft"
                                                            >
                                                                <span
                                                                    onClick={() =>
                                                                        history.push(
                                                                            {
                                                                                pathname: `/book/${id}/${
                                                                                    epustaka_id ||
                                                                                    ''
                                                                                }`,
                                                                                state: {
                                                                                    epustaka_id,
                                                                                    fromQueue: true,
                                                                                },
                                                                            }
                                                                        )
                                                                    }
                                                                >
                                                                    {book_title}
                                                                </span>
                                                            </Tooltip>
                                                        }
                                                        description={
                                                            <Text
                                                                style={{
                                                                    width: 140,
                                                                    fontSize: 13,
                                                                    color: 'gray',
                                                                }}
                                                                ellipsis={true}
                                                            >
                                                                <Tooltip
                                                                    title={
                                                                        book_author
                                                                    }
                                                                    color="#575757"
                                                                    placement="topLeft"
                                                                >
                                                                    {
                                                                        book_author
                                                                    }
                                                                </Tooltip>
                                                            </Text>
                                                        }
                                                    />
                                                </Card>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Carousel>
                        )}

                        <ModalRecommendation
                            visibleModal={visibleModalRecommendation}
                            setVisibleModal={setVisibleModalRecommendation}
                            bookData={itemBook}
                            data={userFollower}
                            userId={profile.id}
                            organization_id={organization_id}
                            currentPageFollower={currentPageFollower}
                            paginationFollower={paginationFollower}
                            onLoadMore={onLoadMoreFollower}
                            getDataFollower={getDataFollower}
                            setDataFollowerChanged={setDataFollowerChanged}
                            setQuerySearchFollower={setQuerySearchFollower}
                            source="bookshelf"
                        />

                        <ModalShare
                            data={bookShare}
                            urlFrom="book"
                            visibleModal={popShare}
                            setVisibleModal={setPopShare}
                        />
                    </Row>
                </>
            )}
        </React.Fragment>
    )
}

export default Queue
