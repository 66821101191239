import axios from 'axios'
import state from '../redux'
import authAction from '../redux/auth/action'
import Cookies from 'js-cookie'
import ls from 'utils/secureLocalStorage'
import routes from 'router/router'
import { matchPath } from 'react-router-dom'
import url from 'constants/url'
import { API_BASE_URL_2 } from 'helpers/constants'

let isRefreshing = false
let pendingRequests = []

const BASE_URL = API_BASE_URL_2

const headers = {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
    'Access-Control-Allow-Origin': true,
}

const processQueue = (error, token = null) => {
    pendingRequests.forEach((prom) => {
        if (error) {
            prom.reject(error)
        } else {
            prom.resolve(token)
        }
    })

    pendingRequests = []
}

const funcRefreshToken = async () => {
    const { auth = {} } = state.getState()
    const { refresh_token } = auth
    if (isRefreshing) {
        try {
            const token = await new Promise(function (resolve, reject) {
                pendingRequests.push({ resolve, reject })
            })
            return token
        } catch (err) {
            return await Promise.reject(err)
        }
    }
    isRefreshing = true

    return new Promise(function (resolve, reject) {
        axios
            .post(
                `${BASE_URL}/${url.refresh_token_url}`,
                { refresh_token },
                { headers }
            )
            .then((res) => {
                authAction.validatingToken(false)
                // authAction.setToken(res.data.data.attributes)
                // const tempAccesToken = res.data.data.attributes.accessToken
                // processQueue(null, res.data.data.attributes.accessToken)
                authAction.setToken(res.data)
                authAction.setAuth({ ...auth, ...res?.data })
                const tempAccesToken = res.data.access_token
                processQueue(null, res.data.access_token)
                resolve(tempAccesToken)
            })
            .catch(async (err) => {
                processQueue(err, null)
                authAction.validatingToken(false)
                authAction.clearAuth()

                reject(err)

                if (err.response.status === 401) {
                    let publicComponent = false
                    const routerPublic = routes?.filter(
                        (e) => e?.publicComponent
                    )
                    routerPublic?.forEach((e) => {
                        if (
                            matchPath(window.location.pathname, {
                                path: e?.path,
                            })?.isExact
                        )
                            publicComponent = true
                    })

                    if (publicComponent) {
                        await authAction.clearAuth()
                        window?.location?.replace(window.location.pathname)
                    } else {
                        window?.location?.replace('/login')
                    }

                    processQueue(err, null)
                    authAction.validatingToken(false)
                    authAction.clearAuth()
                    ls.clear()
                    Cookies.remove('ewAccessToken')

                    reject(err)
                } else {
                    processQueue(err, null)
                    authAction.validatingToken(false)
                    authAction.clearAuth()

                    reject(err)
                }
            })
            .then(() => {
                isRefreshing = false
            })
    })
}

export default funcRefreshToken
