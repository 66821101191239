import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Divider, Empty, Spin } from 'antd'
import translator from 'i18next'
import Avatar from 'antd/lib/avatar/avatar'
import url from 'constants/url'
import provider from 'providers/provider'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import map from 'lodash/map'
import reduxState from '../../redux'
import FilterFriendship from './FilterFriendship'
import ModalSuggestFriends from 'views/home/beranda/suggestFriends/ModalSuggestFriends'
import { useHistory } from 'react-router'
import { ModalConfirmFollowUnfollow } from 'components'
import getAcronym from 'components/Acronym'
import { COLORS, LAYOUT } from 'constants/constant'

const Friendship = () => {
    const history = useHistory()
    const { profile = {} } = reduxState.getState()
    const follower_id = get(profile, 'id', '')
    const user_id = get(profile, 'id', '')

    const [sortingFollower, setSortingFollower] = useState('')
    const [search, setSearch] = useState('')
    const [visibleConfirmFollowUnfollow, setVisibleConfirmFollowUnfollow] =
        useState(false)
    const [item, setItem] = useState({})
    const [suggestFriend, setSuggestFriend] = useState(false)

    const [stateFollow, setStateFollow] = useState({
        follower: true,
        dataList: [],
        loading: false,
    })
    const defaultLimit = 10
    const [limit, setLimit] = useState(defaultLimit)
    const [limitFollowing, setLimitFollowing] = useState(defaultLimit)
    const [totalUser, setTotalUser] = useState(0)
    const [followAction, setFollowAction] = useState(false)

    const getFollower = async () => {
        setStateFollow({ ...stateFollow, loading: true })

        const filterQuery = isEmpty(search)
            ? ''
            : `&filter=${JSON.stringify({
                  'users.name': { ilike: `%${search}%` },
              })}&user_id=${user_id}`

        await provider
            .list(
                encodeURI(
                    `${
                        stateFollow.follower
                            ? url.webhookURL_follower
                            : url.webhookUrl_following
                    }?limit=${
                        stateFollow.follower ? limit : limitFollowing
                    }&offset=0&sort=${sortingFollower}` + filterQuery
                )
            )
            .then((res) => {
                setFollowAction(false)
                const { data, meta } = res
                const filterData = filter(data, (e) => e.id !== follower_id)
                setTotalUser(meta?.total)
                setStateFollow({
                    ...stateFollow,
                    loading: false,
                    dataList: filterData,
                })
            })
    }

    useEffect(() => {
        getFollower()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortingFollower, stateFollow.follower, search, limit, limitFollowing])

    const handleSorting = (e) => {
        setSortingFollower(e === 'latest' ? 'users.created_at' : 'users.name')
    }

    const loadMore = () => {
        if (stateFollow?.follower) {
            setLimit(limit + totalUser)
        } else {
            setLimitFollowing(limitFollowing + totalUser)
        }
    }

    const DisplayData = () => {
        if (isEmpty(stateFollow.dataList)) {
            return (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                        <span style={{ color: COLORS.primaryColor }}>
                            {translator.t(
                                stateFollow.follower
                                    ? 'friendship.firstNotifFollower'
                                    : 'friendship.firstNotifFollow'
                            )}

                            <p
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setSuggestFriend(true)
                                }}
                            >
                                {translator.t(
                                    stateFollow.follower
                                        ? 'friendship.secondNotifFollower'
                                        : 'friendship.secondNotifFollow'
                                )}
                            </p>
                            {translator.t('friendship.thirdNotifFollow')}
                        </span>
                    }
                />
            )
        } else {
            return (
                <div className="follow__itemRight">
                    {map(stateFollow.dataList, (data) => {
                        const { name, avatar_url, id } = data
                        const { color, acronym } = getAcronym(name || '', id)
                        return (
                            <div key={id}>
                                <div
                                    className="follow__itemRightContent"
                                    onClick={() =>
                                        history.push(`/profil/user/${id}`)
                                    }
                                >
                                    <Avatar
                                        size="large"
                                        style={{
                                            cursor: 'pointer',
                                            backgroundImage: color,
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                        }}
                                        src={avatar_url}
                                    >
                                        <span>{acronym}</span>
                                    </Avatar>
                                    <p>{data.name}</p>
                                </div>
                                <Button
                                    className={
                                        data.followed
                                            ? 'follow__itemRightButtonFollowing'
                                            : 'follow__itemRightButtonFollower'
                                    }
                                    onClick={() => {
                                        setVisibleConfirmFollowUnfollow(true)
                                        setItem(data)
                                    }}
                                >
                                    {`${translator.t(
                                        data.followed
                                            ? 'action.cancel_follow'
                                            : 'action.follow'
                                    )}`}
                                </Button>
                                <Divider />
                            </div>
                        )
                    })}
                    {stateFollow?.loading && <Spin />}
                    {stateFollow?.dataList?.length >= totalUser
                        ? ''
                        : !stateFollow?.loading &&
                          !isEmpty(stateFollow?.dataList) && (
                              <div>
                                  <Button type="primary" onClick={loadMore}>
                                      {translator.t('filter.more')}
                                  </Button>
                              </div>
                          )}
                </div>
            )
        }
    }

    return (
        <Row type="flex" style={{ alignItems: 'center' }} justify="center">
            <Col
                span={24}
                style={{ maxWidth: LAYOUT.maxWidth }}
                className="follow__ant"
            >
                <FilterFriendship
                    handleSorting={handleSorting}
                    stateFollow={stateFollow}
                    setStateFollow={setStateFollow}
                    setSearch={setSearch}
                    getFollower={getFollower}
                    followAction={followAction}
                />
                <div className="follow__item">
                    <div className="follow__itemLeft"></div>
                    <DisplayData />
                </div>
            </Col>

            <ModalConfirmFollowUnfollow
                visibleModal={visibleConfirmFollowUnfollow}
                setVisibleModal={setVisibleConfirmFollowUnfollow}
                item={item}
                getFollower={getFollower}
                source="friendship"
                setAction={setFollowAction}
            />

            <ModalSuggestFriends
                visibleModal={suggestFriend}
                setVisibleModal={setSuggestFriend}
            />
        </Row>
    )
}

export default Friendship
