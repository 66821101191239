import React, { useEffect, useState, useLayoutEffect } from 'react'
import { FilterSorterBookCategory, BookCardComponent } from 'views/home/books'
import provider from 'providers/provider'
import url from 'constants/url'
import { Row, Form, Col, Typography, Select, Button, Space } from 'antd'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import translator from 'i18next'
import { ArrowDownOutlined } from '@ant-design/icons'
import { SkeletonBook } from 'components'
import { COLORS, LAYOUT } from 'constants/constant'
import { connect } from 'react-redux'
import dataCategoryBookFilterAction from 'redux/dataCategoryBookFilter/action'
import dataSwitchActiveBookFilter from 'redux/bookFilterSwitch/action'
import bookFilterOptions from 'redux/bookFilterOptions/action'
import switchAction from 'redux/bookFilterSwitch/action'

const { Text } = Typography
const { Option } = Select

const BookCategory = (props) => {
    const { switchActive } = props
    const [form] = Form.useForm()
    const [visibleModal, setVisibleModal] = useState(false)
    const [hasFilter, setHasFilter] = useState(false)
    const [filterBook, setFilterBook] = useState({})
    const [sortingBook, setSortingBook] = useState('')
    const [totalData, setTotalData] = useState(0)
    const [bookCategoryData, setBookCategoryData] = useState([])
    const [limit, setLimit] = useState(25)
    const [loading, setLoading] = useState(false)

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 420
    const isTablet = () => state.width <= 576

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    useEffect(() => {
        if (switchActive) {
            handleFlter(switchActive)
        }
    }, [])

    useEffect(() => {
        const htmlDiv = document.getElementsByClassName('category-container')
        if (!loading) {
            get(htmlDiv, '0.classList', '').remove('skeleton')
        }
    }, [loading])

    const handleFlter = (values) => {
        const dataFilter = JSON.parse(JSON.stringify(values))
        switchAction.setSwitchActive(dataFilter)
        setLoading(true)
        setHasFilter(true)
        setBookCategoryData([])
        const filter = {
            and: [],
        }
        if (values.author_name) {
            filter?.and?.push({
                'b.catalog_authors': { in: values.author_name },
            })
        }
        if (values.category_id) {
            filter?.and?.push({
                'c.id': { in: values.category_id },
            })
        }
        if (
            !isUndefined(values.catalog_publish_date) &&
            !isEmpty(values.catalog_publish_date)
        ) {
            filter?.and?.push({
                'b.catalog_publish_date': {
                    in: values.catalog_publish_date,
                },
            })
        }
        if (
            !values.author_name &&
            !values.category_id &&
            !values?.catalog_publish_date &&
            !values.entry
        ) {
            return reseltFilter()
        }

        if (!isEmpty(filter)) {
            setFilterBook(filter)
            setVisibleModal(false)
            setLoading(false)
        } else {
            setVisibleModal(false)
            setLoading(false)
        }
    }

    const getDataBook = async () => {
        setLoading(true)
        const filterByCategory = {
            ...filterBook,
        }

        const urls =
            url.webhookURL_book_list +
            '?limit=' +
            limit +
            (!isEmpty(filterBook)
                ? '&filter=' + JSON.stringify(filterByCategory)
                : '') +
            '&sort=' +
            sortingBook +
            `&available=${switchActive?.entry ? switchActive?.entry : false}`

        await provider.list(encodeURI(urls)).then((res) => {
            const { data, meta } = res
            setBookCategoryData(data)
            setTotalData(meta.total)
            setLoading(false)
        })
    }

    useEffect(() => {
        const filtered =
            !isEmpty(switchActive?.author_name) ||
            !isEmpty(switchActive?.category_id) ||
            !isEmpty(switchActive?.catalog_publish_date)

        if (filtered) {
            if (!isEmpty(filterBook)) {
                getDataBook()
            }
        } else {
            getDataBook()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, sortingBook, filterBook])

    const reseltFilter = () => {
        form.resetFields()
        setTotalData(0)
        setFilterBook({})
        setBookCategoryData([])
        dataCategoryBookFilterAction.clearDataCategoryBookFilter()
        dataSwitchActiveBookFilter.clearSwitchActive()
        bookFilterOptions.clearFilterOptions()
        setVisibleModal(false)
        setLoading(false)
        setHasFilter(false)
    }

    const handleSorting = (e) => {
        const tempSorting = e === 'latest' ? '-b.created_at' : 'b.catalog_title'
        if (tempSorting !== sortingBook) setBookCategoryData([])
        setSortingBook(tempSorting)
    }

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={loading}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 25)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }

    return (
        <Row justify="center">
            <Col span={24}>
                <div
                    className="bl-container"
                    style={{
                        marginBottom: 30,
                    }}
                >
                    {loading ? (
                        <Row
                            justify="center"
                            align="middle"
                            style={{ marginTop: 30 }}
                        >
                            <SkeletonBook source="fullskeleton" />
                        </Row>
                    ) : (
                        // book section container (from section book)
                        <div
                            style={{
                                marginTop: 30,
                            }}
                            className="bs-container category-container"
                        >
                            <FilterSorterBookCategory
                                visibleModal={visibleModal}
                                setVisibleModal={setVisibleModal}
                                categoryName={
                                    isEmpty(filterBook)
                                        ? translator.t('action.recommendation')
                                        : translator.t('action.searchResult')
                                }
                                handleFlter={handleFlter}
                                totalData={totalData}
                                reseltFilter={reseltFilter}
                                form={form}
                                handleSorting={handleSorting}
                                hasFilter={hasFilter}
                                fromHome
                            />

                            {!sortingBook && isEmpty(bookCategoryData) ? (
                                <div className="book-search-not-found">
                                    <h3>
                                        {translator.t(
                                            'message.filter_not_matches'
                                        )}
                                    </h3>
                                    <p className="text">
                                        {translator.t(
                                            'message.search_not_matches'
                                        )}
                                    </p>
                                    <Button
                                        type="primary"
                                        className="btn-edit-filter"
                                        onClick={reseltFilter}
                                    >
                                        {translator.t('action.change_filter')}
                                    </Button>
                                </div>
                            ) : (
                                <>
                                    {!isTablet() && (
                                        <div
                                            style={{
                                                marginTop: '3em',
                                                justifyContent: 'space-between',
                                                display: 'flex',
                                            }}
                                        >
                                            <div
                                                className="category-name"
                                                style={{ paddingLeft: 10 }}
                                            >
                                                <Text
                                                    ellipsis={true}
                                                    className="name"
                                                    style={{
                                                        color: COLORS.primaryColor,
                                                        fontWeight: 500,
                                                        fontSize: 17,
                                                        marginBottom: '5%',
                                                    }}
                                                >
                                                    {isEmpty(filterBook)
                                                        ? translator.t(
                                                              'action.recommendation'
                                                          )
                                                        : translator.t(
                                                              'filter.result'
                                                          )}
                                                </Text>
                                            </div>

                                            <Select
                                                size="small"
                                                style={{
                                                    width: 100,
                                                }}
                                                bordered={false}
                                                placeholder={translator.t(
                                                    'action.sort'
                                                )}
                                                onChange={handleSorting}
                                                value={
                                                    sortingBook ===
                                                    '-b.created_at'
                                                        ? 'latest'
                                                        : sortingBook ===
                                                          'b.catalog_title'
                                                        ? 'abjad'
                                                        : null
                                                }
                                            >
                                                <Option value="latest">
                                                    {translator.t(
                                                        'sort.latest'
                                                    )}
                                                </Option>
                                                <Option value="abjad">
                                                    {translator.t('sort.abjad')}
                                                </Option>
                                            </Select>
                                        </div>
                                    )}
                                    <Space
                                        wrap
                                        size={[18, 18]}
                                        style={{
                                            maxWidth: LAYOUT.maxWidth,
                                            justifyContent: isMobile()
                                                ? 'space-evenly'
                                                : 'flex-start',
                                        }}
                                        className="book-wrapper-home"
                                    >
                                        {bookCategoryData.map((item, id) => (
                                            <div key={id}>
                                                <BookCardComponent
                                                    data={item}
                                                    key={id}
                                                    fromHome
                                                />
                                            </div>
                                        ))}
                                    </Space>
                                </>
                            )}
                            {!isEmpty(bookCategoryData) && (
                                <div className="loadmore-btn">
                                    <DispalyButton />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    )
}

const mapStateToProps = (state) => ({
    switchActive: state.switchActive,
})

export default connect(mapStateToProps)(BookCategory)
