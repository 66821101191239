import React, { useState, useEffect } from 'react'
import { List, Avatar, Form } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { InputSelect } from 'components'
import ModalFilterEpustaka from './components/ModalFilterEpustaka'
import { BASE_TITLE } from 'constants/constant'

const FilterMembership = (props) => {
    const {
        dataOption,
        setFindEpustaka,
        findEpustaka,
        defaultEpustaka,
        setVisibleShow,
        visibleShow,
        getMemberlist,
    } = props

    const [form] = Form.useForm()
    const [dataFilter, setDataFilter] = useState([])

    useEffect(() => {
        dataOption.map(() => {
            return setDataFilter(
                dataOption.map((epustaka) => ({
                    value: epustaka.id,
                    text: epustaka.epustaka_name,
                }))
            )
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataOption])

    const onChangeEpustaka = (value) => {
        setFindEpustaka(value)
    }

    const data = [
        {
            title: 'data dummy', //dont to remove
        }, // to display filter ui
    ]
    return (
        <div>
            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={() => {
                    return (
                        <List.Item className="filter-membership">
                            <List.Item.Meta
                                avatar={
                                    <div
                                        onClick={() =>
                                            setVisibleShow(!visibleShow)
                                        }
                                    >
                                        <Avatar
                                            className="add-logo"
                                            alt={`${BASE_TITLE} image preview - Platform Perpustakaan Indonesia`}
                                            icon={<PlusOutlined />}
                                        />
                                    </div>
                                }
                                title={
                                    <InputSelect
                                        defaultValue={defaultEpustaka}
                                        className="filter-input"
                                        showSearch={false}
                                        data={dataFilter}
                                        placeholder="Filter Epustaka"
                                        onChange={onChangeEpustaka}
                                        size="default"
                                    />
                                }
                            />
                        </List.Item>
                    )
                }}
            />
            <ModalFilterEpustaka
                setVisibleShow={setVisibleShow}
                visibleShow={visibleShow}
                form={form}
                epustaka_id={findEpustaka}
                getMemberlist={getMemberlist}
            />
        </div>
    )
}

export default FilterMembership
