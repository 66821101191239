import Modal from 'antd/lib/modal/Modal'
import React from 'react'
import translator from 'i18next'
import { Button, message } from 'antd'
import provider from 'providers/provider'
import url from 'constants/url'
import { useState } from 'react'
import { error } from 'utils/notification'

export default function ModalReturnBook({
    visibleModal,
    setVisibleModal,
    bookData,
    getDataBook,
    frombookself,
    getMedia,
    mediaData,
}) {
    const [loading, setLoading] = useState(false)

    const deleteBook = async () => {
        setLoading(true)
        const data = {
            borrow_book_id: bookData?.id,
        }
        await provider
            .put(url.webhookUrl_book_return, data, 'webhook')
            .then(() => {
                getDataBook()
                setVisibleModal(false)
                setLoading(false)
                message.success(translator.t('bookshelf.returnBook'))
            })
            .catch(() => {
                setLoading(false)
                error({
                    description: translator.t('message.error'),
                    placement: 'bottomRight',
                })
            })
    }

    const deleteMedia = async () => {
        setLoading(true)
        await provider
            .deleteData(`${url.webhookURL_content_access_history}`, {
                id: mediaData?.id,
            })
            .then(() => {
                getMedia()
                setLoading(false)
                setVisibleModal(!visibleModal)
            })
            .catch(() => {
                setLoading(false)
                error({
                    description: translator.t('message.error'),
                    placement: 'bottomRight',
                })
            })
    }

    return (
        <Modal
            centered
            visible={visibleModal}
            onOk={() => setVisibleModal(!visibleModal)}
            onCancel={() => setVisibleModal(!visibleModal)}
            footer={null}
            className="m__container__return__book"
        >
            <div className="m__container">
                {frombookself ? (
                    <b>{translator.t('action.deleteItem')}</b>
                ) : (
                    <b>{translator.t('action.returnBook')}</b>
                )}

                {frombookself ? (
                    <div className="m__containerMessage">
                        {translator.t('message.messageReturnBook')}
                    </div>
                ) : (
                    <div className="m__containerMessage">
                        {translator.t('action.messageDelete')}
                    </div>
                )}
                <div className="m__containerBtn">
                    <Button
                        className="m__containerBtnOk"
                        htmlType="submit"
                        type="primary"
                        loading={loading}
                        onClick={() =>
                            frombookself ? deleteMedia() : deleteBook()
                        }
                    >
                        {frombookself
                            ? translator.t('feed.delete')
                            : translator.t('title.deleteBook')}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}
