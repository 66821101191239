import React, { useState, useEffect } from 'react'
import './MessageSender.scss'
import translator from 'i18next'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import UploadImage from 'components/editor/UploadImage'
import PreviewImage from 'components/PreviewImage'
import ls from 'utils/secureLocalStorage'
import url from 'constants/url'
import { error } from 'utils/notification'
import getBase64 from 'utils/getBase64'
import { v4 as uuidv4 } from 'uuid'
import provider from 'providers/provider'
import encryption from 'utils/encryption'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import action from 'redux/timeLine/action'
import {
    Avatar,
    Image,
    Form,
    Modal,
    Button,
    Card,
    Input,
    Col,
    Row,
    Progress,
    Spin,
} from 'antd'
import getAcronym from 'components/Acronym'
import { BASE_TITLE } from 'constants/constant'
import { friends, ic_upload_image } from '../../../assets/icons'

const { TextArea } = Input
const MessageSender = (props) => {
    const { setTimeLine, fileListData, fromEpustakaDetail, dataEpustaka } =
        props

    const closeModalPopup = () => {
        ls.set(encryption.encryptWithMD5('popupModal'), false)
        setPopupModal(false)
    }
    const initialPopupModal = ls.get(
        encryption.encryptWithMD5('popupModal, value')
    )
    const [popupModal, setPopupModal] = useState(initialPopupModal)
    const [loading, setLoading] = useState(false)
    const [post, setPost] = useState('')

    const [isLoadingUpload, setIsLoadingUpload] = useState(false)
    const [progress, setProgress] = useState(0)
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const [previewTitle, setPreviewTitle] = useState('')
    const [fileUrl, setFileUrl] = useState('')
    const [fileList, setFileList] = useState([])
    const [userProfil, setUserProfile] = useState([])

    const [form] = Form.useForm()

    const epustaka_id = get(dataEpustaka, 'id', '')

    const organization_id = get(userProfil, 'organizations.id', '')

    const userId = get(userProfil, 'id', '')

    const userName = get(userProfil, 'name', '')

    const { color, acronym } = getAcronym(userName, userId)

    const getProfile = async () => {
        await provider
            .singleWebHook(`${url.webhookURL_profile}`)
            .then((res) => {
                setUserProfile(res.data)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    useEffect(() => {
        getProfile()
    }, [])

    const submitData = async (url, data) => await provider.insert(url, data)
    const handleSave = async (value) => {
        setLoading(true)
        await submitData(url.webhookURL_posting, value)
            .then(async () => {
                setTimeLine({ refresh: true })
                setPopupModal(false)
                setLoading(false)
                setFileList([])
                form.setFieldsValue({
                    textarea: '',
                })
            })
            .catch((err) => {
                Promise.reject(err)
                setLoading(false)
            })
        setPost('')
    }
    const handleFinish = async () => {
        await handleSave(
            !isEmpty(fileUrl)
                ? {
                      message: post,
                      image_file_id: fileUrl,
                      sender_type: fromEpustakaDetail ? 'EPUSTAKA' : 'USER',
                      sender_id: fromEpustakaDetail ? epustaka_id : userId,
                  }
                : {
                      message: post,
                      sender_type: fromEpustakaDetail ? 'EPUSTAKA' : 'USER',
                      sender_id: fromEpustakaDetail ? epustaka_id : userId,
                  }
        )
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }

        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true)
    }

    const handleChange = ({ fileList }) => {
        const fileSize = get(fileList, '0.size')

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-1)

        // 2. Read from response and show file link
        fileList = fileList.map((file) => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url
            }
            return file
        })

        if (!isEmpty(fileList)) {
            const isLt2M = fileSize / 1024 / 1024 < 2
            if (!isLt2M) {
                error({
                    description: translator.t('validation.maxImage'),
                    placement: 'bottomRight',
                })
            } else {
                setFileList(fileList)
            }
        } else {
            setFileList(fileList)
        }
    }

    useEffect(() => {
        setFileList(fileListData)
    }, [fileListData])

    const onUploadProgress = (progressEvent) => {
        if (progressEvent) {
            setProgress(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
        }
    }

    const customRequest = async ({ onSuccess, onError, file }) => {
        const fileSize = file.size

        const isLt2M = fileSize / 1024 / 1024 < 2
        if (isLt2M) {
            setIsLoadingUpload(true)
            const data = new FormData()
            const dataBody = [
                {
                    name: 'bucketName',
                    value: organization_id,
                },
                {
                    name: 'storagePath',
                    value: 'images/posts',
                },
                {
                    name: 'file',
                    value: file,
                },
                {
                    name: 'fileName',
                    value: uuidv4(),
                },
            ]
            dataBody.map((e) => data.append(e.name, e.value))
            await provider
                .uploadStorage({
                    url: url.storageURL_upload_object,
                    body: data,
                    extraParams: {
                        onUploadProgress: onUploadProgress,
                    },
                })
                .then((data) => {
                    setFileUrl(data.objId)
                    setPreviewTitle(data.objName)
                    setIsLoadingUpload(false)
                    setProgress(0)
                    onSuccess()
                })
                .catch((err) => {
                    setIsLoadingUpload(false)
                    setProgress(0)
                    error({
                        description: err.message,
                        placement: 'bottomRight',
                    })
                    onError()
                })
        }
    }

    return (
        <div className="messageSender">
            <div className="messageSender-top">
                <Avatar
                    size="large"
                    alt={`${BASE_TITLE} image preview - Platform Perpustakaan Indonesia`}
                    style={{
                        cursor: 'pointer',
                        backgroundImage: color,
                        display: 'inline-flex',
                        alignItems: 'center',
                    }}
                    src={
                        fromEpustakaDetail
                            ? get(dataEpustaka, 'epustaka_logo', '')
                            : get(userProfil, 'avatar_url', '')
                    }
                >
                    <span style={{ fontSize: 15 }}>{acronym}</span>
                </Avatar>
                <form>
                    <input
                        readOnly
                        onClick={() => {
                            setPopupModal(true)
                        }}
                        className="messageSender-input"
                        placeholder={
                            fromEpustakaDetail
                                ? `${translator.t('feed.epustaka_post')}`
                                : `${translator.t(
                                      'feed.yourMind'
                                  )}, ${userName} ?`
                        }
                    />
                    <div className="messageSender-upload">
                        <Image
                            preview={false}
                            src={ic_upload_image}
                            onClick={() => {
                                setPopupModal(true)
                            }}
                        />
                    </div>
                    <Row align="middle" justify="center" type="flex">
                        <Col>
                            <Modal
                                // centered
                                visible={popupModal}
                                onOk={closeModalPopup}
                                onCancel={closeModalPopup}
                                footer={null}
                                className="modal-popupSender"
                            >
                                <div>
                                    <Form form={form} name="inputForm">
                                        <Card
                                            className="modal-card"
                                            bordered={false}
                                            title={`${translator.t(
                                                'feed.createPost'
                                            )}`}
                                        >
                                            <div className="containerSender">
                                                <div className="post-top">
                                                    <Avatar
                                                        className="post-avatar"
                                                        size="large"
                                                        alt={`${BASE_TITLE} image preview - Platform Perpustakaan Indonesia`}
                                                        style={{
                                                            cursor: 'pointer',
                                                            backgroundImage:
                                                                color,
                                                            display:
                                                                'inline-flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                        src={
                                                            fromEpustakaDetail
                                                                ? get(
                                                                      dataEpustaka,
                                                                      'epustaka_logo',
                                                                      ''
                                                                  )
                                                                : get(
                                                                      userProfil,
                                                                      'avatar_url',
                                                                      ''
                                                                  )
                                                        }
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: 15,
                                                            }}
                                                        >
                                                            {acronym}
                                                        </span>
                                                    </Avatar>
                                                    <div className="post-info">
                                                        <b>
                                                            {fromEpustakaDetail ? (
                                                                <span
                                                                    style={{
                                                                        textTransform:
                                                                            'capitalize',
                                                                    }}
                                                                >
                                                                    {get(
                                                                        dataEpustaka,
                                                                        'epustaka_name',
                                                                        ''
                                                                    )}
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    style={{
                                                                        textTransform:
                                                                            'capitalize',
                                                                    }}
                                                                >
                                                                    {userName}
                                                                </span>
                                                            )}
                                                        </b>
                                                        <p className="post-info-time">
                                                            {' '}
                                                            <Avatar
                                                                alt={`${BASE_TITLE} image preview - Platform Perpustakaan Indonesia`}
                                                                size={13}
                                                                src={friends}
                                                            />{' '}
                                                            {`${translator.t(
                                                                'feed.shareTitle'
                                                            )}`}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="textArea">
                                                    <Form.Item name="textarea">
                                                        <TextArea
                                                            id="foo"
                                                            className="placeholder"
                                                            placeholder={
                                                                fromEpustakaDetail
                                                                    ? `${translator.t(
                                                                          'feed.epustaka_post'
                                                                      )}`
                                                                    : `${translator.t(
                                                                          'feed.yourMind'
                                                                      )}, ${userName} ?`
                                                            }
                                                            bordered={false}
                                                            rows={4}
                                                            value={post}
                                                            onChange={(e) =>
                                                                setPost(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="upload-container">
                                                    <Form.Item name="upload">
                                                        <UploadImage
                                                            accept="image/png, image/jpeg, image/gif"
                                                            customRequest={
                                                                customRequest
                                                            }
                                                            onRemove={true}
                                                            fileList={
                                                                fileList ?? []
                                                            }
                                                            listType="picture"
                                                            name="cover_thumbnail"
                                                            multiple={false}
                                                            onPreview={
                                                                handlePreview
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </Form.Item>
                                                    {/* progress bar */}
                                                    {isLoadingUpload &&
                                                        (progress === 100 ? (
                                                            <Form.Item>
                                                                <div>
                                                                    {translator.t(
                                                                        'action.finishing'
                                                                    )}
                                                                    <Spin
                                                                        style={{
                                                                            marginTop:
                                                                                '5px',
                                                                            marginLeft:
                                                                                '5px',
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Form.Item>
                                                        ) : (
                                                            <Form.Item>
                                                                <Progress
                                                                    strokeColor={{
                                                                        '0%': '#108ee9',
                                                                        '100%': '#87d068',
                                                                    }}
                                                                    percent={
                                                                        progress
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        ))}
                                                    <PreviewImage
                                                        visible={previewVisible}
                                                        title={previewTitle}
                                                        handleCancel={() =>
                                                            setPreviewVisible(
                                                                false
                                                            )
                                                        }
                                                        imgSrc={previewImage}
                                                    />
                                                </div>

                                                <Button
                                                    onClick={handleFinish}
                                                    disabled={
                                                        !post &&
                                                        isEmpty(fileList)
                                                    }
                                                    loading={loading}
                                                    className="btn-modal"
                                                    type="primary"
                                                >
                                                    <h3>
                                                        <b>Kirim</b>
                                                    </h3>
                                                </Button>
                                            </div>
                                        </Card>
                                    </Form>
                                </div>
                            </Modal>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    stateTimeLine: get(state, 'timeLine', {}),
})

const mapDispatchToProps = () => ({
    setTimeLine: action.setTimeLine,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MessageSender))
