import * as ActionType from './contant'

const initState = null

const reducer = (state = initState, action) => {
    const { type, value } = action
    switch (type) {
        case ActionType.SET_NOTIFREQ:
            return value
        case ActionType.CLEAR_NOTIFREQ:
            return initState
        default:
            return state
    }
}

export default reducer
