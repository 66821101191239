const getPagination = (currentPage, totalPage, queryType = 'tables') => {
    const limitType = queryType === 'tables' ? 'page[limit]' : 'limit'
    const offsetType = queryType === 'tables' ? 'page[offset]' : 'offset'
    const offset = (currentPage - 1) * totalPage
    let result = `?${limitType}=${totalPage}`

    if (currentPage) result += `&${offsetType}=${offset}`

    return { result, offset }
}

export default getPagination
