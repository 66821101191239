import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Row, Col, message } from 'antd'
import provider from 'providers/provider'
import url from 'constants/url'
import { SkeletonBook } from 'components'
import translator from 'i18next'
import { useHistory } from 'react-router-dom'
import Modal from 'antd/lib/modal/Modal'
import ModalNotif from '../components/ModalNotif'
import Slider from 'react-slick'
import { BookCardComponent } from 'views/home/books'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Lazy } from 'swiper/core'

SwiperCore.use([Pagination, Lazy])

const EpustakaBookCollection = (props) => {
    const epustakaId = props.id
    const dataEpustaka = props?.dataEpustaka
    const history = useHistory()

    const defaultPagination = `?limit=6&offset=0&epustaka_id=${epustakaId}`

    const [bookLoading, setBookLoading] = useState(false)
    const [bookData, setBookData] = useState([])

    const [popNotif, setPopNotif] = useState(false)

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isSmallMobile = () => state.width <= 360
    const isMobile = () => state.width <= 420

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const settings = {
        infinite: false,
        speed: 400,
        initialSlide: 0,
        dots: false,
        arrows: false,
        slidesToShow: isSmallMobile() ? 2 : 2.1,
        adaptiveHeight: false,
        swipeToSlide: true,
    }

    const getBookData = async () => {
        let isMounted = true // note this flag denote mount status
        setBookLoading(true)
        await provider
            .list(url.webhookURL_book_list + defaultPagination)
            .then((res) => {
                const { data } = res
                if (isMounted) {
                    setBookData(data)
                    setBookLoading(false)
                }
            })
        return () => {
            isMounted = false
        }
    }

    useEffect(() => {
        getBookData()
        // disable warning missing dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /* Block access when viewing epustaka conditions */
    const handleLinkMore = () => {
        if (dataEpustaka?.epustaka_isprivate) {
            if (
                !dataEpustaka?.has_join ||
                (dataEpustaka?.has_join && dataEpustaka?.member_status !== 1)
            )
                if (dataEpustaka?.member_status === 3) {
                    message.warning(translator.t('message.notifExpand'))
                } else {
                    message.warning(translator.t('message.notif'))
                }
            else {
                history.push({
                    pathname: '/epustaka-book-category',
                    state: { epustakaId, dataEpustaka },
                })
            }
        } else {
            history.push({
                pathname: '/epustaka-book-category',
                state: { epustakaId, dataEpustaka },
            })
        }
    }

    return (
        <div className="pb__container">
            <Row
                style={{
                    justifyContent: 'space-between',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px 0',
                }}
            >
                <Col>
                    <div className="pb__containerLink">
                        {translator.t('column.book')}
                    </div>
                </Col>
                {bookData.length > 5 && (
                    <Col>
                        <div
                            className="pb__containerMore"
                            onClick={() => handleLinkMore(dataEpustaka)}
                        >
                            {translator.t('action.fully')}
                        </div>
                    </Col>
                )}
            </Row>
            {bookLoading ? (
                <SkeletonBook />
            ) : (
                <>
                    {isMobile() ? (
                        <Col
                            span={24}
                            style={{
                                paddingLeft: 15,
                            }}
                        >
                            <Slider
                                className={
                                    bookData.length === 1
                                        ? 'react-slick single-react-slick'
                                        : 'react-slick'
                                }
                                {...settings}
                            >
                                {bookData.map((item, key) => (
                                    <BookCardComponent
                                        fromEpustakaBookCategory
                                        dataEpustaka={dataEpustaka}
                                        key={key}
                                        data={item}
                                    />
                                ))}
                            </Slider>
                        </Col>
                    ) : (
                        <div className="swiper_container">
                            <Swiper
                                slidesPerView={1.2}
                                spaceBetween={10}
                                preloadImages={false}
                                lazy={true}
                                pagination={{
                                    el: '.swiper-pagination',
                                    clickable: true,
                                }}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1.5,
                                    },
                                    360: {
                                        slidesPerView: 1.8,
                                    },
                                    411: {
                                        slidesPerView: 2,
                                    },
                                    420: {
                                        slidesPerView: 2.5,
                                    },
                                    540: {
                                        slidesPerView: 2.5,
                                    },
                                    640: {
                                        slidesPerView: 3,
                                    },
                                    768: {
                                        slidesPerView: 3.7,
                                    },
                                    884: {
                                        slidesPerView: 3.8,
                                    },
                                    1024: {
                                        slidesPerView: 5,
                                    },
                                }}
                                className="swiper_container"
                            >
                                {bookData.map((book, key) => (
                                    <SwiperSlide>
                                        <BookCardComponent
                                            fromEpustakaBookCategory
                                            dataEpustaka={dataEpustaka}
                                            key={key}
                                            data={book}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    )}
                </>
            )}

            <Modal
                visible={popNotif}
                onOk={() => setPopNotif(false)}
                onCancel={() => setPopNotif(false)}
                setVisibleModal={setPopNotif}
                footer={null}
            >
                <ModalNotif />
            </Modal>
        </div>
    )
}

export default EpustakaBookCollection
