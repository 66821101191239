import store from '../store'
import * as ActionType from './contant'

export default {
    setRoute: (value) => {
        const action = { type: ActionType.SET_ROUTE, value }
        store.dispatch(action)
    },
    updateRoute: (value) => {
        const action = { type: ActionType.UPDATE_ROUTE, value }
        store.dispatch(action)
    },
    clearRoute: () => {
        const action = { type: ActionType.CLEAR_ROUTE }
        store.dispatch(action)
    },
}
