import React, { useEffect, useState } from 'react'
import translator from 'i18next'
import { Avatar, Tabs, Row, Col, Image, Button } from 'antd'
import filter from 'lodash/filter'
import get from 'lodash/get'
import reduxState from '../../../redux'
import provider from 'providers/provider'
import url from 'constants/url'
import { connect } from 'react-redux'
import tabKeyAction from 'redux/epustakaTabActive/action'
import BookProfil from 'views/profile/books'
import VideoProfil from 'views/profile/video'
import AudioProfil from 'views/profile/audio'
import NewsHistory from 'views/bookshelf/history/article/index'
import ModalFollower from '../components/ModalFollower'
import ModalFollowing from '../components/ModalFollowing'
import Feed from 'views/timeline/feed/Feed'
import getAcronym from 'components/Acronym'
import ModalConfirmFollowUnfollow from 'components/ModalConfirmFollowUnfollow'
import { BASE_TITLE, LAYOUT } from 'constants/constant'
import { error } from 'utils/notification'
import { isEmpty } from 'lodash'

const { TabPane } = Tabs

const mapStateToProps = (state) => ({
    tabActive: state.tabActive,
})

const ProfileUser = (props) => {
    const { tabActive } = props

    const userId = get(props.match.params, 'uuid', '')
    const { profile = {} } = reduxState.getState()
    const callback = (key) => {
        const selectedKey = key.split('profile-')
        tabKeyAction.setTabActive(selectedKey[1])
    }

    const [followerCount, setFollowerCount] = useState(0)
    const [followingCount, setFollowingCount] = useState(0)
    const [dataFollower, setDataFollower] = useState([])
    const [dataFollowing, setDataFollowing] = useState([])
    const [visibleFollower, setVisibleFollower] = useState(false)
    const [visibleFollowing, setVisibleFollowing] = useState(false)
    const [visibleConfirm, setVisibleConfirm] = useState(false)
    const [userProfil, setUserProfile] = useState([])
    const [item, setItem] = useState({})
    const [videoData, setVideoData] = useState([])
    const [audioData, setAudioData] = useState([])
    const [newsData, setNewsData] = useState([])
    const [actionFollow, setActionFollow] = useState(false)

    const follower_id = get(userProfil, 'id', '')
    const schoolName = get(userProfil, 'organizations.organization_name', '')

    const getProfile = async () => {
        await provider
            .singleWebHook(`${url.webhookURL_profile}?user_id=${userId}`)
            .then((res) => {
                setUserProfile(res.data)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    useEffect(() => {
        getProfile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const name = userProfil?.name || ''
    const id = userProfil?.id || null
    const { color, acronym } = getAcronym(name, id)

    const getFollowing = async () => {
        await provider
            .list(
                url.webhookUrl_following +
                    `?user_id=${userId}&limit=9999&offset=0`
            )
            .then((res) => {
                const { data } = res
                const filterData = filter(data, (e) => e.id !== follower_id)
                setDataFollowing(filterData)
                setActionFollow(false)
                setFollowingCount(filterData.length)
            })
    }

    useEffect(() => {
        getFollowing()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [followingCount, actionFollow])

    const getFollower = async () => {
        await provider
            .list(
                url.webhookURL_follower +
                    `?user_id=${userId}&limit=9999&offset=0`
            )
            .then((res) => {
                const { data } = res
                const filterData = filter(data, (e) => e.id !== follower_id)
                setDataFollower(filterData)
                setFollowerCount(filterData.length)
                setActionFollow(false)
            })
    }

    useEffect(() => {
        getFollower()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [followerCount, actionFollow])

    const getVideoData = async () => {
        await provider
            .list(
                url.webhookURL_multimedia_history_list +
                    `?user_id=${userId}&type=video`
            )
            .then((res) => {
                const { data } = res
                setVideoData(data)
            })
    }

    useEffect(() => {
        getVideoData()
    }, [])

    const getAudioData = async () => {
        await provider
            .list(
                url.webhookURL_multimedia_history_list +
                    `?user_id=${userId}&type=audio`
            )
            .then((res) => {
                const { data } = res
                setAudioData(data)
            })
    }

    useEffect(() => {
        getAudioData()
    }, [])

    const getNewsHistory = async () => {
        await provider
            .list(url.webhookURL_news_history_list + `?user_id=${userId}`)
            .then((res) => {
                const { data } = res

                setNewsData(data)
            })
    }

    useEffect(() => {
        getNewsHistory()
        // disable warning missing dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="pContainer" style={{ maxWidth: LAYOUT.maxWidth }}>
            <Row
                type="flex"
                style={{
                    alignItems: 'center',
                    marginTop: '1.3rem',
                }}
                justify="center"
            >
                <Col
                    xs={24}
                    sm={19}
                    md={24}
                    lg={24}
                    xl={24}
                    className="profile"
                >
                    <Row className="content-top">
                        <Col span={24} className="box-center">
                            {userProfil.avatar_url === null ? (
                                <Avatar
                                    className="avatar"
                                    size="large"
                                    style={{
                                        cursor: 'pointer',
                                        backgroundImage: color,
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                    }}
                                    src={get(userProfil, 'avatar_url', '')}
                                >
                                    <span className="avatar-name">
                                        {acronym}
                                    </span>
                                </Avatar>
                            ) : (
                                <Image
                                    style={{
                                        width: 120,
                                        height: 120,
                                        objectFit: 'cover',
                                    }}
                                    src={userProfil.avatar_url}
                                    alt={`${BASE_TITLE} Preview Images`}
                                />
                            )}

                            <div className="name">
                                {get(userProfil, 'name', '')}
                            </div>
                            <div className="school">{schoolName}</div>
                        </Col>
                    </Row>
                    <Row
                        gutter={[12, 12]}
                        className="content-middle"
                        type="flex"
                        style={{ alignItems: 'center' }}
                        justify="center"
                    >
                        <Col
                            className="follower"
                            onClick={() => {
                                setVisibleFollower(true)
                            }}
                        >
                            <Col className="follower-count">
                                {followerCount}
                            </Col>
                            <Col>{translator.t('action.followers')}</Col>
                        </Col>
                        <Col
                            className="follow"
                            onClick={() => {
                                setVisibleFollowing(true)
                            }}
                        >
                            <Col className="follow-count">{followingCount}</Col>
                            <Col>{translator.t('action.following')}</Col>
                        </Col>
                    </Row>
                    <div className="btn-followed">
                        {profile?.id !== userId && (
                            <Button
                                onClick={() => {
                                    setVisibleConfirm(true)
                                    setItem(userProfil)
                                }}
                                className={
                                    userProfil.followed === true
                                        ? 'ant-btn-following'
                                        : 'ant-btn-followed'
                                }
                            >
                                {userProfil.followed === true
                                    ? translator.t('action.cancel_follow')
                                    : translator.t('action.follow')}
                            </Button>
                        )}
                    </div>
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            {get(userProfil, 'bio', '')}
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row type="flex" style={{ alignItems: 'center' }} justify="center">
                <Col xs={24} sm={19} md={24} lg={24} xl={24}>
                    <Tabs
                        defaultActiveKey={`profile-${tabActive}`}
                        onChange={callback}
                        centered
                    >
                        <TabPane
                            tab={translator.t('menu.timeline')}
                            key="profile-1"
                        >
                            <div style={{ marginTop: '10px' }}>
                                <Feed
                                    source="userProfil"
                                    userProfilId={userId}
                                />
                            </div>
                        </TabPane>
                        <TabPane
                            tab={translator.t('menu.book')}
                            key="profile-2"
                        >
                            <BookProfil source="userProfil" id={userId} />
                        </TabPane>
                        {!isEmpty(videoData) && (
                            <TabPane
                                tab={translator.t('menu.video')}
                                key="profile-3"
                            >
                                <VideoProfil source="userProfil" id={userId} />
                            </TabPane>
                        )}
                        {!isEmpty(audioData) && (
                            <TabPane
                                tab={translator.t('menu.audio')}
                                key="profile-4"
                            >
                                <AudioProfil source="userProfil" id={userId} />
                            </TabPane>
                        )}
                        {!isEmpty(newsData) && (
                            <TabPane
                                tab={translator.t('Artikel')}
                                key="profile-5"
                            >
                                <br />

                                <NewsHistory data={newsData} userId={userId} />
                            </TabPane>
                        )}
                    </Tabs>
                </Col>
            </Row>

            <ModalFollower
                dataFollower={dataFollower}
                visibleModal={visibleFollower}
                setVisibleModal={setVisibleFollower}
                getFollowing={getFollowing}
                getFollower={getFollower}
                userProfil
            />

            <ModalFollowing
                dataFollowing={dataFollowing}
                visibleModal={visibleFollowing}
                setVisibleModal={setVisibleFollowing}
                getFollowing={getFollowing}
                getFollower={getFollower}
                userProfil
            />

            <ModalConfirmFollowUnfollow
                visibleModal={visibleConfirm}
                setVisibleModal={setVisibleConfirm}
                item={item}
                getProfile={getProfile}
                source="userProfile"
                setAction={setActionFollow}
            />
        </div>
    )
}

export default connect(mapStateToProps)(ProfileUser)
