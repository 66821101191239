import {
    ic_friends,
    ic_help,
    ic_shelf,
    ic_donate_express,
    ic_manage_member,
} from 'assets/icons'
import { BASE_URL_WEB } from 'helpers/constants'
import getEnv from 'utils/getEnv.js'

export const COLORS = {
    primaryColor: getEnv('REACT_APP_PRIMARY_COLOR', ''),
    secondaryColor: getEnv('REACT_APP_SECONDARY_COLOR', ''),
    backgroundcontent: '#F9F9F9',
    baseblack: '#000000',
    basegrey: 'rgb(116, 116, 116)',
    baseorange: '#f58c36',
    baseorangebtn: '#FA6400',
    basepurple: '#f368e0',
    baseblue: '#4492dc',
    basered: '#ff0000',
    basebluelight: '#4E8EE9',
    basepurplelight: '#BD53B0',
    basegreylight: '#AEAEB2',
    mintgreen: '#6dd400',
    red: '#e02020',
}

export const BASE_TITLE = getEnv('REACT_APP_NAME', '')
export const PLAYSTORE_URL = `https://play.google.com/store/apps/details?id=mam.reader.${getEnv(
    'REACT_APP_PACKAGE_NAME',
    ''
)}`

export const LAYOUT = {
    maxWidth: '900px',
}

export const IS_ACTIVE_LOGO_BANNER = getEnv('REACT_APP_LOGO_BANNER', false) //handle logo dibawah slider halaman login & register

export const SERVICE = {
    term: `${BASE_URL_WEB}/term/toc.html`,
    policy: `${BASE_URL_WEB}/term/policy.html`,
}

export const colorPallet = [
    'linear-gradient(to top, #439bfd, #6dd5ed)', //gradient blue
    'linear-gradient(to top, #11998e, #38ef7d)', //gradient green
    'linear-gradient(to top, #fc4a1a, #f7b733)', //gradient orange
    'linear-gradient(to top, #ff9966, #ff5e62)', //gradient black orange
    'linear-gradient(to top, #7f00ff, #e100ff)', //gradient purple
    'linear-gradient(to top, #0cebeb, #20e3b2, #29ffc6)', //gradient bright green
    'linear-gradient(to top, #D387AB, #E899DC)', //gradient pink
    getEnv('REACT_APP_GRADIENT_LOGIN', ''), //gradient Login screen
]

export const colorAchievement = ['#F4244A', '#34306F', '#44D7B6', '#EAB70C']

export const categoryOrderBox = [
    { key: 'P', value: 'PRODUCT', text: 'Produk', order_type: 'SOUVENIR' },
    { key: 'D', value: 'DONATION', text: 'Donasi', order_type: 'BOOK' },
    { key: 'T', value: 'TRYOUT', text: 'Tryout', order_type: 'TRYOUT' },
    { key: 'S', value: 'STORAGE', text: 'Storage', order_type: 'STORAGE' },
    { key: 'Q', value: 'QR', text: 'QR', order_type: 'QR' },
    {
        key: 'BP',
        value: 'DONATION',
        text: 'Donasi',
        order_type: 'BOOK_PACKAGE',
    },
]

export const appMenu = [
    {
        icon: `${ic_shelf}`,
        title: 'menu.borrowed_shelf',
        id: 'shelf',
    },
    {
        icon: `${ic_friends}`,
        title: 'menu.friendship',
        id: 'friendship',
    },
    {
        icon: `${ic_donate_express}`,
        title: 'menu.donation',
        id: 'donation',
    },
    {
        icon: `${ic_help}`,
        title: 'menu.help',
        id: 'help',
    },
    {
        icon: `${ic_manage_member}`,
        title: 'menu.membership',
        id: 'membership',
    },
]

export const MULTI_ORGANIZATION = getEnv('REACT_APP_MULTI_ORGANIZATION', false)

export const BOOK_CITATION = getEnv('REACT_APP_BOOK_CITATION', false)
