import * as ActionType from './contant'

const initState = {
    refresh: false,
    refreshLike: false,
}

const reducer = (state = initState, action) => {
    const { type, value } = action
    switch (type) {
        case ActionType.SET_TIMELINE:
            return {
                ...state,
                ...value,
            }
        case ActionType.CLEAR_TIMELINE:
            return initState
        default:
            return state
    }
}

export default reducer
