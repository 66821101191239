import React, { useState, useLayoutEffect } from 'react'
import { Col, Row, Skeleton, Space } from 'antd'

export default function SkeletonBook(props) {
    const isMobile = () => state.width <= 420

    const [state, setState] = useState({
        collapsed: false,
        width: window.innerWidth,
    })

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])
    const source = props.source
    if (source === 'fullskeleton') {
        return (
            <>
                <Row style={{ marginTop: 5 }}>
                    <Space size={[35, 20]} wrap>
                        <Col xs={12} sm={12} md={8} lg={5} xl={5}>
                            <Skeleton.Input
                                style={{
                                    width: isMobile() ? 153 : 141,
                                    height: isMobile() ? 220 : 190,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{
                                        width: isMobile() ? 153 : 141,
                                        height: 20,
                                    }}
                                    active
                                />
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={8} lg={5} xl={5}>
                            <Skeleton.Input
                                style={{
                                    width: isMobile() ? 153 : 141,
                                    height: isMobile() ? 220 : 190,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{
                                        width: isMobile() ? 153 : 141,
                                        height: 20,
                                    }}
                                    active
                                />
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={8} lg={5} xl={5}>
                            <Skeleton.Input
                                style={{
                                    width: isMobile() ? 153 : 141,
                                    height: isMobile() ? 220 : 190,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{
                                        width: isMobile() ? 153 : 141,
                                        height: 20,
                                    }}
                                    active
                                />
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={8} lg={5} xl={5}>
                            <Skeleton.Input
                                style={{
                                    width: isMobile() ? 153 : 141,
                                    height: isMobile() ? 220 : 190,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{
                                        width: isMobile() ? 153 : 141,
                                        height: 20,
                                    }}
                                    active
                                />
                            </div>
                        </Col>

                        <Col xs={12} sm={12} md={8} lg={5} xl={5}>
                            <Skeleton.Input
                                style={{
                                    width: isMobile() ? 153 : 141,
                                    height: isMobile() ? 220 : 190,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{
                                        width: isMobile() ? 153 : 141,
                                        height: 20,
                                    }}
                                    active
                                />
                            </div>
                        </Col>
                    </Space>
                </Row>

                <Row style={{ marginTop: 5 }}>
                    <Space size={[35, 20]} wrap>
                        <Col xs={12} sm={12} md={8} lg={5} xl={5}>
                            <Skeleton.Input
                                style={{
                                    width: isMobile() ? 153 : 141,
                                    height: isMobile() ? 220 : 190,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{
                                        width: isMobile() ? 153 : 141,
                                        height: 20,
                                    }}
                                    active
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={5} xl={5}>
                            <Skeleton.Input
                                style={{
                                    width: isMobile() ? 153 : 141,
                                    height: isMobile() ? 220 : 190,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{
                                        width: isMobile() ? 153 : 141,
                                        height: 20,
                                    }}
                                    active
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={5} xl={5}>
                            <Skeleton.Input
                                style={{
                                    width: isMobile() ? 153 : 141,
                                    height: isMobile() ? 220 : 190,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{
                                        width: isMobile() ? 153 : 141,
                                        height: 20,
                                    }}
                                    active
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={5} xl={5}>
                            <Skeleton.Input
                                style={{
                                    width: isMobile() ? 153 : 141,
                                    height: isMobile() ? 220 : 190,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{
                                        width: isMobile() ? 153 : 141,
                                        height: 20,
                                    }}
                                    active
                                />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={8} lg={5} xl={5}>
                            <Skeleton.Input
                                style={{
                                    width: isMobile() ? 153 : 141,
                                    height: isMobile() ? 220 : 190,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{
                                        width: isMobile() ? 153 : 141,
                                        height: 20,
                                    }}
                                    active
                                />
                            </div>
                        </Col>
                    </Space>
                </Row>
            </>
        )
    }
    return (
        <Row gutter={[48, 48]} style={{ marginTop: 5, textAlign: 'center' }}>
            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Skeleton.Input
                    style={{
                        width: isMobile() ? 153 : 141,
                        height: isMobile() ? 220 : 190,
                    }}
                    active
                />
                <div style={{ marginTop: 10 }}>
                    <Skeleton.Input
                        style={{ width: isMobile() ? 153 : 141, height: 20 }}
                        active
                    />
                </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Skeleton.Input
                    style={{
                        width: isMobile() ? 153 : 141,
                        height: isMobile() ? 220 : 190,
                    }}
                    active
                />
                <div style={{ marginTop: 10 }}>
                    <Skeleton.Input
                        style={{ width: isMobile() ? 153 : 141, height: 20 }}
                        active
                    />
                </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Skeleton.Input
                    style={{
                        width: isMobile() ? 153 : 141,
                        height: isMobile() ? 220 : 190,
                    }}
                    active
                />
                <div style={{ marginTop: 10 }}>
                    <Skeleton.Input
                        style={{ width: isMobile() ? 153 : 141, height: 20 }}
                        active
                    />
                </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Skeleton.Input
                    style={{
                        width: isMobile() ? 153 : 141,
                        height: isMobile() ? 220 : 190,
                    }}
                    active
                />
                <div style={{ marginTop: 10 }}>
                    <Skeleton.Input
                        style={{ width: isMobile() ? 153 : 141, height: 20 }}
                        active
                    />
                </div>
            </Col>
        </Row>
    )
}
