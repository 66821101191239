import React, { useState, useEffect } from 'react'
import translator from 'i18next'
import { Card, Col, Row, Tooltip } from 'antd'
import provider from 'providers/provider'
import url from 'constants/url'
import { useHistory } from 'react-router-dom'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { FallbackImage, SkeletonBook } from 'components'
import { ModalShare } from 'components'
import getPagination from 'constants/getPagination'
import reduxState from '../../../redux'
import ModalRecommendation from 'views/home/books/bookDetail/ModalRecommendation'
import Text from 'antd/lib/typography/Text'
import Meta from 'antd/lib/card/Meta'
import { RetweetOutlined, ShareAltOutlined } from '@ant-design/icons'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Lazy } from 'swiper/core'
import { BASE_TITLE, COLORS } from 'constants/constant'
import { error } from 'utils/notification'

SwiperCore.use([Pagination, Lazy])

const BorrowBook = () => {
    const history = useHistory()
    const { profile = {} } = reduxState.getState()
    const organization_id = profile.organizations.id
    const userId = get(profile, 'id')
    const [bookData, setBookData] = useState({})
    const [loading, setLoading] = useState(false)
    const [userFollower, setUserFollower] = useState({})
    const [currentPageFollower, setCurrentPageFollower] = useState(1)
    const [paginationFollower, setPaginationFollower] = useState({
        loading: true,
        pagination: true,
    })
    const [querySearchFollower, setQuerySearchFollower] = useState()
    const [dataFollowerChanged, setDataFollowerChanged] = useState(true)

    const [visibleModalRecommendation, setVisibleModalRecommendation] =
        useState(false)
    const [popShare, setPopShare] = useState(false)
    const [itemBook, setItemBook] = useState({})
    const [bookShare, setBookShare] = useState([])

    const getDataBook = async () => {
        setLoading(true)
        await provider
            .list(url.webhookUrl_book_history_shelf + `?user_id=${userId}`)
            .then((res) => {
                setBookData(res.data)
                setLoading(false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
            })
    }

    const getDataFollower = async () => {
        const limit = 10
        const pagination = getPagination(currentPageFollower, limit, 'webhook')
        const filterQuery = isEmpty(querySearchFollower)
            ? ''
            : {
                  'users.name': { ilike: `%${querySearchFollower}%` },
              }
        await provider
            .list(
                encodeURI(
                    `${url.webhookURL_follower}${
                        pagination.result
                    }&filter=${JSON.stringify(filterQuery)}&q=&user_id=${
                        profile.id
                    }`
                )
            )
            .then((res) => {
                const { data, meta } = res
                const filterData = filter(data, (e) => e.id !== profile.id)
                if (dataFollowerChanged) {
                    setUserFollower(filterData)
                } else {
                    setUserFollower(
                        isEmpty(userFollower)
                            ? filterData
                            : concat(userFollower, filterData)
                    )
                }

                setPaginationFollower({
                    ...paginationFollower,
                    loading: false,
                })
                if (pagination.offset >= meta.total || meta.total <= limit) {
                    setPaginationFollower({
                        loading: false,
                        pagination: false,
                    })
                }
            })
    }

    useEffect(() => {
        getDataFollower()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [querySearchFollower])

    useEffect(() => {
        getDataBook()
        // disable warning missing dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onLoadMoreFollower = async () => {
        setPaginationFollower({
            ...paginationFollower,
            loading: true,
        })
        setCurrentPageFollower(currentPageFollower + 1)
    }

    const handleClickShare = () => {
        setPopShare(true)
    }

    const handleClickRecommend = () => {
        setVisibleModalRecommendation(true)
    }

    return (
        <React.Fragment>
            {loading ? (
                <SkeletonBook />
            ) : (
                <div
                    className="book-history-wrapper"
                    style={{ marginBottom: 20 }}
                >
                    <div className="bs__containerBorrow">
                        <p
                            style={{
                                color: 'rgb(87, 87, 87)',
                                fontWeight: 600,
                            }}
                        >
                            {translator.t('menu.book')}
                        </p>
                        {/* {bookData.length > 5 && ( */}
                        <p
                            onClick={() =>
                                history.push({
                                    pathname: '/more-book-history',
                                })
                            }
                            style={{
                                cursor: 'pointer',
                                color: COLORS.primaryColor,
                            }}
                        >
                            {translator.t('action.fully')}
                        </p>
                        {/* )} */}
                    </div>

                    <Row className="bs__conte">
                        <div className="swiper_container">
                            <Swiper
                                slidesPerView={1.2}
                                spaceBetween={10}
                                preloadImages={false}
                                lazy={true}
                                pagination={{
                                    el: '.swiper-pagination',
                                    clickable: true,
                                }}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1.9,
                                    },
                                    360: {
                                        slidesPerView: 2.1,
                                    },
                                    411: {
                                        slidesPerView: 2.4,
                                    },
                                    420: {
                                        slidesPerView: 2.5,
                                    },
                                    540: {
                                        slidesPerView: 3.6,
                                    },
                                    640: {
                                        slidesPerView: 3.8,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                    },
                                    884: {
                                        slidesPerView: 4.1,
                                    },
                                    1024: {
                                        slidesPerView: 4.2,
                                    },
                                    1087: {
                                        slidesPerView: 4.5,
                                    },
                                }}
                                className="swiper_container"
                            >
                                {map(bookData, (book, key) => {
                                    const {
                                        cover_url,
                                        book_title,
                                        book_author,
                                        book_id: id,
                                    } = book
                                    return (
                                        <SwiperSlide key={key}>
                                            <Col key={key}>
                                                <div className="bs__containerComp">
                                                    <Card
                                                        style={{
                                                            width: 160,
                                                        }}
                                                        className="bs__containerCompCard"
                                                        cover={
                                                            <div className="container-book">
                                                                <div className="book">
                                                                    <div className="front">
                                                                        <div className="cover">
                                                                            <FallbackImage
                                                                                alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                                                                src={
                                                                                    cover_url
                                                                                }
                                                                                onClick={() =>
                                                                                    history.push(
                                                                                        `/book/${id}`
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    width: 135,
                                                                                    height: 185,
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="left-side">
                                                                        <h2
                                                                            style={{
                                                                                height: 20,
                                                                                width: 185,
                                                                                marginLeft:
                                                                                    -15,
                                                                                backgroundImage: `url(${cover_url})`,
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                <Text
                                                                                    style={{
                                                                                        width: 155,
                                                                                        fontSize: 10,
                                                                                        color: 'white',
                                                                                        paddingRight: 10,
                                                                                    }}
                                                                                    ellipsis={
                                                                                        true
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        book_author
                                                                                    }
                                                                                </Text>
                                                                            </span>
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        actions={[
                                                            <Tooltip
                                                                title={translator.t(
                                                                    'action.recommendation'
                                                                )}
                                                                color="#575757"
                                                            >
                                                                <RetweetOutlined
                                                                    key="recommendation"
                                                                    onClick={() =>
                                                                        handleClickRecommend(
                                                                            setItemBook(
                                                                                book
                                                                            )
                                                                        )
                                                                    }
                                                                />
                                                            </Tooltip>,
                                                            <Tooltip
                                                                title={translator.t(
                                                                    'title.share'
                                                                )}
                                                                color="#575757"
                                                            >
                                                                <ShareAltOutlined
                                                                    key="share"
                                                                    onClick={() =>
                                                                        handleClickShare(
                                                                            setBookShare(
                                                                                book
                                                                            )
                                                                        )
                                                                    }
                                                                />
                                                            </Tooltip>,
                                                        ]}
                                                    >
                                                        <Meta
                                                            title={
                                                                <Tooltip
                                                                    title={
                                                                        book_title
                                                                    }
                                                                    color="#575757"
                                                                    placement="topLeft"
                                                                >
                                                                    <span
                                                                        style={{
                                                                            fontWeight: 600,
                                                                        }}
                                                                        onClick={() =>
                                                                            history.push(
                                                                                `/book/${id}`
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            book_title
                                                                        }
                                                                    </span>
                                                                </Tooltip>
                                                            }
                                                            description={
                                                                <Text
                                                                    style={{
                                                                        width: 130,
                                                                        fontSize: 13,
                                                                        color: 'gray',
                                                                    }}
                                                                    ellipsis={
                                                                        true
                                                                    }
                                                                >
                                                                    <Tooltip
                                                                        title={
                                                                            book_author
                                                                        }
                                                                        color="#575757"
                                                                        placement="topLeft"
                                                                    >
                                                                        {
                                                                            book_author
                                                                        }
                                                                    </Tooltip>
                                                                </Text>
                                                            }
                                                        />
                                                    </Card>
                                                </div>
                                            </Col>
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                        </div>

                        <ModalRecommendation
                            visibleModal={visibleModalRecommendation}
                            setVisibleModal={setVisibleModalRecommendation}
                            bookData={itemBook}
                            data={userFollower}
                            userId={profile.id}
                            organization_id={organization_id}
                            currentPageFollower={currentPageFollower}
                            paginationFollower={paginationFollower}
                            onLoadMore={onLoadMoreFollower}
                            getDataFollower={getDataFollower}
                            setDataFollowerChanged={setDataFollowerChanged}
                            setQuerySearchFollower={setQuerySearchFollower}
                            source="bookshelf"
                        />

                        <ModalShare
                            data={bookShare}
                            urlFrom="book"
                            visibleModal={popShare}
                            setVisibleModal={setPopShare}
                        />
                    </Row>
                </div>
            )}
        </React.Fragment>
    )
}

export default BorrowBook
