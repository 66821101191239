import React from 'react'
import HistoryRead from './HistoryRead'

const ProfileBooks = (props) => {
    const userId = props
    return (
        <div className="pb__container">
            <HistoryRead userId={userId} />
        </div>
    )
}

export default ProfileBooks
