import React, { useLayoutEffect, useState } from 'react'
import { List, Avatar, Skeleton, Button, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import provider from 'providers/provider'
import url from 'constants/url'
import isEmpty from 'lodash/isEmpty'
import translator from 'i18next'
import { success, error } from 'utils/notification'
import action from 'redux/timeLine/action'
import { connect } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import getAcronym from 'components/Acronym'
import { BASE_TITLE } from 'constants/constant'

const { confirm } = Modal
const MembershipList = (props) => {
    const {
        // findEpustaka,
        memberEpustaka,
        limit,
        setLimit,
        loading,
        loadMoreLoading,
        setLoadMoreLoading,
        hasMore,
        getMember,
        setTimeLine,
    } = props

    const history = useHistory()

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 460

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    //to delete memberlist
    const handleSubmit = (epustaka) => {
        confirm({
            title: (
                <span style={{ fontWeight: 600 }}>
                    {translator.t('action.out_member')}
                </span>
            ),
            icon: <ExclamationCircleOutlined />,
            content: `${translator.t('action.is_out_member')} ${
                epustaka.name
            } ${translator.t('action.is_next_out_member')}`,
            width: 550,
            okText: translator.t('action.out_member'),
            okType: 'danger',
            cancelText: <span>{translator.t('action.undo')}</span>,
            onOk: async () => {
                await provider
                    .delete(
                        `${url.tablseURL_memberships_mccp}/${epustaka.membership_id}`
                    )
                    .then(() => {
                        getMember()
                        setTimeLine({ refresh: true })
                        success({
                            description: `${translator.t(
                                'action.success_remove_member'
                            )}`,
                            placement: 'topRight',
                        })
                    })
                    .catch((err) =>
                        error({
                            description: err.message,
                            placement: 'bottomLeft',
                        })
                    )
            },
            onCancel() {},
        })
    }

    const loadMoreClick = () => {
        setLoadMoreLoading(true)
        setLimit(limit + 10)
    }

    const loadMore =
        !loadMoreLoading && hasMore && !isEmpty(memberEpustaka) ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    marginBottom: 10,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={loadMoreClick}>
                    {translator.t('action.fully')}
                </Button>
            </div>
        ) : (
            ''
        )

    return (
        <div>
            <List
                className="membership-list"
                loading={false}
                itemLayout="horizontal"
                loadMore={loadMore}
                dataSource={memberEpustaka}
                renderItem={(item) => {
                    const { name, id } = item
                    const { color, acronym } = getAcronym(name || '', id)
                    return (
                        <List.Item
                            actions={[
                                loading ? (
                                    <Skeleton.Input
                                        style={{
                                            width: isMobile() ? 80 : 100,
                                            borderRadius: 5,
                                        }}
                                        active={false}
                                        size="default"
                                        shape="square"
                                    />
                                ) : (
                                    <Button
                                        onClick={() => handleSubmit(item)}
                                        className="btn-member-out"
                                    >
                                        x {translator.t('action.take_out')}
                                    </Button>
                                ),
                            ]}
                        >
                            <List.Item.Meta
                                style={{ marginLeft: isMobile() ? '10px' : '' }}
                                avatar={
                                    loading ? (
                                        <Skeleton.Avatar
                                            active={true}
                                            size="large"
                                            shape="circle"
                                        />
                                    ) : (
                                        <Avatar
                                            style={{
                                                backgroundImage: color,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() =>
                                                history.push(
                                                    `/profil/user/${id}`
                                                )
                                            }
                                            size="large"
                                            alt={`${BASE_TITLE} image preview - Platform Perpustakaan Indonesia`}
                                            src={item.avatar_url}
                                        >
                                            {acronym}
                                        </Avatar>
                                    )
                                }
                                title={
                                    loading ? (
                                        <Skeleton.Input
                                            style={{ width: 160 }}
                                            active={true}
                                            size="small"
                                        />
                                    ) : (
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                history.push(
                                                    `/profil/user/${id}`
                                                )
                                            }
                                            className="member-name"
                                        >
                                            {item.name}
                                        </div>
                                    )
                                }
                            />
                        </List.Item>
                    )
                }}
            />
        </div>
    )
}

const mapDispatchToProps = () => ({
    setTimeLine: action.setTimeLine,
})

export default connect(mapDispatchToProps)(withRouter(MembershipList))
