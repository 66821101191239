import React, { useEffect } from 'react'
import { Card, Col, Dropdown, Menu, Row, Skeleton, Typography } from 'antd'
import { FallbackImage, Loading, ModalShare } from 'components'
import { useHistory } from 'react-router'
import { BASE_TITLE } from 'constants/constant'
import { ic_more, nobook } from 'assets/icons'
import { LoadingOutlined } from '@ant-design/icons'
import translator from 'i18next'
import { useState } from 'react'
import ModalRecommendation from 'views/home/books/bookDetail/ModalRecommendation'
import provider from 'providers/provider'
import getPagination from 'constants/getPagination'
import { concat, filter, isEmpty } from 'lodash'
import url from 'constants/url'
const { Text } = Typography

const SearchBook = (props) => {
    const {
        searchResult,
        empty,
        search,
        loadingData,
        profile,
        organization_id,
        loadMore,
    } = props

    const history = useHistory()

    const [isModalVisibleShare, setIsModalVisibleShare] = useState(false)
    const [bookDetailData, setBookDetailData] = useState({})
    const [visibleModalRecommendation, setVisibleModalRecommendation] =
        useState(false)
    const [dataFollowerChanged, setDataFollowerChanged] = useState(true)
    const [userFollower, setUserFollower] = useState({})
    const [currentPageFollower, setCurrentPageFollower] = useState(1)
    const [paginationFollower, setPaginationFollower] = useState({
        loading: true,
        pagination: true,
    })
    const [bookRating, setBookRating] = useState(0)

    const [querySearchFollower, setQuerySearchFollower] = useState()

    const onLoadMoreFollower = async () => {
        setPaginationFollower({
            ...paginationFollower,
            loading: true,
        })
        setCurrentPageFollower(currentPageFollower + 1)
    }

    const getDataFollower = async () => {
        const limit = 10
        const pagination = getPagination(currentPageFollower, limit, 'webhook')
        const filterQuery = isEmpty(querySearchFollower)
            ? ''
            : JSON.stringify({
                  'users.name': { ilike: `%${querySearchFollower}%` },
              })

        await provider
            .list(
                encodeURI(
                    `${url.webhookURL_follower}${pagination.result}&filter=${filterQuery}&q=&user_id=${profile.id}`
                )
            )
            .then((res) => {
                const { data, meta } = res
                const filterData = filter(data, (e) => e.id !== profile.id)
                if (dataFollowerChanged) {
                    setUserFollower(filterData)
                } else {
                    setUserFollower(
                        isEmpty(userFollower)
                            ? filterData
                            : concat(userFollower, filterData)
                    )
                }

                setPaginationFollower({
                    ...paginationFollower,
                    loading: false,
                })
                if (pagination.offset >= meta.total || meta.total <= limit) {
                    setPaginationFollower({
                        loading: false,
                        pagination: false,
                    })
                }
            })
    }

    const getReviewData = async (item) => {
        await provider
            .list(
                `${url.webhookURL_book_rating}?book_id=${item?.id}&limit=99999`
            )
            .then(async (res) => {
                const { data: dataReview } = res
                let average_rating = 0
                if (!isEmpty(dataReview)) {
                    average_rating =
                        dataReview.reduce(
                            (total, next) => total + next?.rating_level,
                            0
                        ) / dataReview?.length
                }
                setBookRating(average_rating)
                setVisibleModalRecommendation(true)
            })
    }

    const menuAction = (item) => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClickShare(item)}
                    >
                        {translator.t('title.share')}
                    </div>
                </Menu.Item>
                <Menu.Item key="1">
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            getReviewData(item)
                            handleClickRecommend(item)
                        }}
                    >
                        {translator.t('action.recommendation')}
                    </div>
                </Menu.Item>
            </Menu>
        )
    }

    const handleClickShare = (item) => {
        setBookDetailData(item)
        setIsModalVisibleShare(true)
    }

    const handleClickRecommend = (item) => {
        setBookDetailData(item)
    }

    useEffect(() => {
        getDataFollower()
    }, [querySearchFollower])

    return (
        <div className="search-tab">
            {loadingData ? (
                <div className="loading">
                    <Loading />
                </div>
            ) : empty ? (
                <div style={{ textAlign: 'center' }}>
                    <div>
                        <div>.......</div>
                        <b>{translator.t('message.filter_not_matches')}</b>
                    </div>
                </div>
            ) : (
                <div>
                    <Row
                        justify="center"
                        className="title-result"
                        style={{
                            marginBottom: 10,
                            marginTop: -5,
                        }}
                    >
                        <Col span={24}>
                            {search && (
                                <span>
                                    {translator.t('column.searchBook')}
                                    <b>{`"${search}"`}</b>
                                </span>
                            )}
                        </Col>
                    </Row>
                    <div>
                        {searchResult.map((item, i) => (
                            <Row
                                type="flex"
                                style={{
                                    alignItems: 'center',
                                    // backgroundColor: 'tomato',
                                }}
                                justify="center"
                                key={i}
                            >
                                <Col span={24}>
                                    <Card className="search-list-container">
                                        <Row>
                                            <Col span={22}>
                                                <div
                                                    className="search-list"
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        const { id } = item

                                                        history.replace(
                                                            `/book/${id}`
                                                        )
                                                    }}
                                                >
                                                    {loadingData ? (
                                                        <Skeleton.Input
                                                            active
                                                            style={{
                                                                width: 105,
                                                                height: 155,
                                                                borderRadius: 5,
                                                            }}
                                                            size="default"
                                                        />
                                                    ) : (
                                                        <FallbackImage
                                                            className="media-cover"
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            src={
                                                                item.cover_url ===
                                                                null
                                                                    ? nobook
                                                                    : item.cover_url
                                                            }
                                                            alt="cover-book"
                                                        />
                                                    )}

                                                    <div className="media-title">
                                                        {loadingData ? (
                                                            <Skeleton.Input
                                                                // active
                                                                style={{
                                                                    width: 155,
                                                                    height: 20,
                                                                }}
                                                                size="default"
                                                            />
                                                        ) : (
                                                            <Text
                                                                className="title"
                                                                onClick={() => {
                                                                    const {
                                                                        id,
                                                                    } = item

                                                                    history.replace(
                                                                        `/book/${id}`
                                                                    )
                                                                }}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                {item.book_title ===
                                                                null
                                                                    ? '-'
                                                                    : item.book_title}
                                                            </Text>
                                                        )}
                                                        <br />
                                                        {loadingData ? (
                                                            <Skeleton.Input
                                                                // active
                                                                style={{
                                                                    width: 100,
                                                                    height: 10,
                                                                }}
                                                                size="default"
                                                            />
                                                        ) : (
                                                            <p className="subtitle">
                                                                {
                                                                    item.author_name
                                                                }
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col span={2}>
                                                {loadingData ? (
                                                    <LoadingOutlined />
                                                ) : (
                                                    <Dropdown
                                                        overlay={menuAction(
                                                            item
                                                        )}
                                                        trigger={['click']}
                                                        placement="bottomRight"
                                                        overlayStyle={{
                                                            position:
                                                                'absolute',
                                                            zIndex: 0,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                float: 'right',
                                                            }}
                                                        >
                                                            <img
                                                                src={ic_more}
                                                                alt={`${BASE_TITLE} preview images - platform edukasi indonesia`}
                                                            />
                                                        </div>
                                                    </Dropdown>
                                                )}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        ))}
                        {loadMore}
                    </div>
                </div>
            )}
            <ModalShare
                urlFrom="book"
                data={bookDetailData}
                visibleModal={isModalVisibleShare}
                setVisibleModal={setIsModalVisibleShare}
            />

            <ModalRecommendation
                visibleModal={visibleModalRecommendation}
                setVisibleModal={setVisibleModalRecommendation}
                bookDetailData={{ ...bookDetailData, avg_rating: bookRating }}
                data={userFollower}
                userId={profile.id}
                organization_id={organization_id}
                currentPageFollower={currentPageFollower}
                paginationFollower={paginationFollower}
                onLoadMore={onLoadMoreFollower}
                getDataFollower={getDataFollower}
                setDataFollowerChanged={setDataFollowerChanged}
                setQuerySearchFollower={setQuerySearchFollower}
            />
        </div>
    )
}

export default SearchBook
