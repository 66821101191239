import React, { useState, useLayoutEffect, useEffect } from 'react'
import { BASE_TITLE, COLORS, LAYOUT } from 'constants/constant'
import {
    Image,
    List,
    Button,
    Skeleton,
    Collapse,
    Divider,
    Radio,
    Row,
    Col,
    Typography,
    Input,
} from 'antd'
import { FilterFilled, SearchOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import translator from 'i18next'
// import NEWS_CATEGORIES from 'views/home/beranda/news/queries/getAllNewsCategory'
// import { gql, useQuery } from '@apollo/client'
// import { error } from 'utils/notification'
import reduxState from '../../../../redux'
import isEmpty from 'lodash/isEmpty'
import provider from 'providers/provider'
import url from 'constants/url'

const { Search } = Input
const { Paragraph } = Typography
const { Panel } = Collapse
const NewsList = () => {
    const { profile = {} } = reduxState.getState()
    const organization_id = profile?.organizations?.id

    const [showSearch, setShowSearch] = useState(false)
    const [loadingNews, setLoadingNews] = useState(false)
    const [selectCategory, setSelectCategory] = useState('')
    const [searchNews, setSearchNews] = useState('')
    const [open, setOpen] = useState([])
    const [limit, setLimit] = useState(25)

    const [newsList, setNewsList] = useState([])
    const [totalNews, setTotalNews] = useState(0)

    const [dataCategory, setDataCategory] = useState({
        list: [],
        total: 0,
    })

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 768

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const loadMore = () => {
        if (totalNews > limit) {
            return (
                <div
                    style={{
                        textAlign: 'center',
                        padding: '12px 0',
                        lineHeight: '32px',
                    }}
                >
                    <Button
                        loading={loadingNews}
                        onClick={() => {
                            totalNews > limit && setLimit((e) => e + 5)
                        }}
                    >
                        {translator.t('action.more')}
                    </Button>
                </div>
            )
        } else {
            return null
        }
    }

    const getNewsList = async () => {
        setLoadingNews(true)

        const filter = !isEmpty(selectCategory)
            ? `&filter=${JSON.stringify({
                  'n.category_id': selectCategory,
              })}`
            : `&filter=${JSON.stringify({
                  'n.news_status': 1,
              })}`

        const search = !isEmpty(searchNews) ? `&q=${searchNews}` : null

        const urls = encodeURI(
            url.webhookURL_news_list +
                '?limit=' +
                limit +
                (!isEmpty(filter) ? filter : '') +
                (!isEmpty(search) ? search : '')
        )

        await provider.list(urls).then((res) => {
            const { data, meta } = res

            setNewsList(data)
            setTotalNews(meta.total)
            setLoadingNews(false)
        })
    }

    const getNewsCategoryList = async () => {
        await provider
            .list(
                url.webhookURL_news_category_list +
                    `?organization_id=${organization_id}&limit=9999`
            )
            .then((res) => {
                const { data, meta } = res

                setDataCategory({
                    list: data,
                    total: meta.total,
                })
            })
    }

    useEffect(() => {
        getNewsList()
        getNewsCategoryList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, selectCategory, searchNews])

    // const resultNewsCategory = (res) => {
    //     const { data, count } = res?.allNewsCategoriesList
    //     setDataCategory({
    //         list: data,
    //         total: count,
    //     })
    // }

    // useQuery(
    //     gql`
    //         ${NEWS_CATEGORIES}
    //     `,
    //     {
    //         variables: {
    //             orderBy: { category_name: 'ASC' },
    //             where: {
    //                 is_active: {
    //                     _eq: true,
    //                 },
    //                 organization_id: {
    //                     _eq: organization_id,
    //                 },
    //             },
    //             limit: 9999,
    //             offset: 0,
    //         },
    //         onCompleted: (data) => {
    //             resultNewsCategory(data)
    //         },
    //         onError: (err) =>
    //             error({
    //                 description: err.message,
    //                 placement: 'bottomRight',
    //             }),
    //     }
    // )

    return (
        <div
            style={{
                maxWidth: LAYOUT.maxWidth,
                margin: '0px auto',
            }}
            className="news-list-container"
        >
            {showSearch ? (
                <div
                    style={{
                        position: 'fixed',
                        top: '64px',
                        left: '0px',
                        right: '0px',
                        zIndex: 1,
                        padding: '20px 5px',
                        backgroundColor: '#f0f2f5',
                        userSelect: 'none',
                        maxWidth: LAYOUT.maxWidth,
                        margin: '0 auto',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Search
                        placeholder={translator.t('action.find')}
                        enterButton={translator.t('action.find')}
                        size="middle"
                        allowClear
                        onSearch={(e) => setSearchNews(e)}
                        // loading
                    />
                    <Button
                        style={{ borderRadius: 4, marginLeft: 10 }}
                        onClick={() => {
                            setShowSearch(false)
                            setSearchNews('')
                        }}
                    >
                        {translator.t('action.back')}
                    </Button>
                </div>
            ) : (
                <Collapse
                    ghost
                    activeKey={open}
                    onChange={() => {
                        if (isEmpty(open)) {
                            setOpen(() => [1])
                        } else {
                            setOpen([])
                        }
                    }}
                >
                    <Panel
                        style={{
                            position: 'fixed',
                            top: '64px',
                            left: '0px',
                            right: '0px',
                            zIndex: 1,
                            padding: '0px',
                            backgroundColor: '#f0f2f5',
                            userSelect: 'none',
                            maxWidth: LAYOUT.maxWidth,
                            margin: '0 auto',
                        }}
                        showArrow={false}
                        header={
                            <div className="navigation-news">
                                <div className="navigation-news-title">
                                    {translator.t('title.today_news')}
                                </div>

                                <div className="navigation-news-filter">
                                    Filter
                                    <span style={{ marginLeft: 7 }}>
                                        <FilterFilled
                                            onClick={() => {
                                                setOpen(() => [1])
                                            }}
                                        />
                                    </span>
                                    <div style={{ marginLeft: 15 }}>
                                        <SearchOutlined
                                            onClick={() => setShowSearch(true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        key="1"
                        onChange={() => setOpen(() => [1])}
                    >
                        <h3>{translator.t('title.select_news_category')}</h3>
                        <Divider style={{ margin: '5px 0px' }} />
                        <div
                            style={{
                                margin: '20px 0',
                            }}
                        >
                            <Radio.Group
                                style={{
                                    width: '100%',
                                    maxHeight: 300,
                                    overflow: 'scroll',
                                }}
                                onChange={(e) =>
                                    setSelectCategory(e?.target?.value)
                                }
                                value={selectCategory}
                            >
                                <Row>
                                    {dataCategory?.list?.map((item, key) => (
                                        <Col
                                            span={isMobile() ? 6 : 5}
                                            key={key}
                                            style={{
                                                margin: isMobile() ? 14 : 12,
                                            }}
                                        >
                                            <Radio value={item?.id}>
                                                {item?.category_name}
                                            </Radio>
                                        </Col>
                                    ))}
                                </Row>
                            </Radio.Group>
                        </div>
                        <div>
                            <Row
                                wrap
                                gutter={[24, 8]}
                                justify="end"
                                align="middle"
                                style={{ marginRight: 10 }}
                            >
                                <Col>
                                    <Button
                                        style={{
                                            fontWeight: 600,
                                            color: COLORS.primaryColor,
                                            cursor: 'pointer',
                                            borderColor: 'transparent',
                                        }}
                                        onClick={() => {
                                            setSelectCategory('')
                                            getNewsList()
                                            setOpen([])
                                        }}
                                    >
                                        {translator.t('title.reset')}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button
                                        type="primary"
                                        style={{
                                            borderRadius: 20,
                                            backgroundColor:
                                                COLORS?.primaryColor,
                                            color: 'white',
                                            borderColor: COLORS?.primaryColor,
                                            width: 120,
                                            height: 35,
                                        }}
                                        loading={loadingNews}
                                        onClick={() => {
                                            getNewsList()
                                            setOpen([])
                                            setLoadingNews(false)
                                        }}
                                    >
                                        {translator.t('title.apply')}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Panel>
                </Collapse>
            )}

            <div
                className="news-content-wrapper"
                style={{ backgroundColor: !isEmpty(newsList) && 'white' }}
            >
                {loadingNews ? (
                    <div
                        style={{
                            padding: '10px 24px',
                            backgroundColor: 'white',
                            borderRadius: 10,
                        }}
                    >
                        <Skeleton active />
                        <Divider />
                        <Skeleton active />
                        <Divider />
                        <Skeleton active />
                    </div>
                ) : (
                    <>
                        {isEmpty(newsList) ? (
                            <div className="news-search-not-found">
                                <h3>
                                    {translator.t('message.filter_not_matches')}
                                </h3>
                                <p className="text">
                                    {translator.t('message.search_not_matches')}
                                </p>
                                {!showSearch && (
                                    <Button
                                        type="primary"
                                        className="btn-edit-filter"
                                        onClick={() => {
                                            setSelectCategory('')
                                            getNewsList()
                                            setOpen([])
                                        }}
                                    >
                                        {translator.t('action.change_filter')}
                                    </Button>
                                )}
                            </div>
                        ) : (
                            <List
                                dataSource={newsList}
                                loadMore={loadMore()}
                                renderItem={(item) => {
                                    return (
                                        <List.Item key={item?.id}>
                                            <Skeleton
                                                title={false}
                                                loading={loadingNews}
                                                active
                                            >
                                                <List.Item.Meta
                                                    avatar={null}
                                                    title={
                                                        <Link
                                                            to={{
                                                                pathname: `/article/${item?.id}`,
                                                            }}
                                                        >
                                                            <Paragraph
                                                                ellipsis={
                                                                    true
                                                                        ? {
                                                                              rows: 2,
                                                                              expandable: false,
                                                                          }
                                                                        : false
                                                                }
                                                            >
                                                                {
                                                                    item?.news_title
                                                                }
                                                            </Paragraph>
                                                        </Link>
                                                    }
                                                    description={
                                                        item?.news_author || '-'
                                                    }
                                                />

                                                <Link
                                                    to={{
                                                        pathname: `/article/${item?.id}`,
                                                    }}
                                                >
                                                    <Image
                                                        src={
                                                            item?.news_thumbnail_url
                                                        }
                                                        alt={`${BASE_TITLE} Preview Images - Platform Perpustakaan Indonesia`}
                                                        width={150}
                                                        className="image-news-card"
                                                        preview={false}
                                                    />
                                                </Link>
                                            </Skeleton>
                                        </List.Item>
                                    )
                                }}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default NewsList
