import * as ActionType from './contant'
import ls from 'utils/secureLocalStorage'
import Encryption from 'utils/encryption'

const STORAGE = Encryption.encryptWithMD5('switch_active')
const initState = false

const initializeState = () => {
    const switchActive = ls.get(STORAGE)
    const state = switchActive ? switchActive : initState
    return state
}

const reducer = (state = initializeState(), action) => {
    const { type, value } = action

    switch (type) {
        case ActionType.SET_SWITCH_ACTIVE:
            ls.set(STORAGE, value)
            return value
        case ActionType.CLEAR_SWITCH_ACTIVE:
            ls.remove(STORAGE)
            return initState
        default:
            return state
    }
}

export default reducer
