import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Col, Card, Tooltip, Space, Button } from 'antd'
import provider from 'providers/provider'
import url from 'constants/url'
import { FallbackImage } from 'components'
import translator from 'i18next'
import { useHistory } from 'react-router-dom'
import { ModalShare } from 'components'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import getPagination from 'constants/getPagination'
import reduxState from '../../../redux'
import ModalRecommendation from 'views/home/books/bookDetail/ModalRecommendation'
import {
    ArrowDownOutlined,
    RetweetOutlined,
    ShareAltOutlined,
} from '@ant-design/icons'
import Meta from 'antd/lib/card/Meta'
import { BASE_TITLE } from 'constants/constant'
import { error } from 'utils/notification'
import Text from 'antd/lib/typography/Text'

const DetailBookQueue = () => {
    const history = useHistory()
    const { profile = {} } = reduxState.getState()
    const organization_id = profile.organizations.id
    const [bookData, setBookData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [limit, setLimit] = useState(25)
    const [userFollower, setUserFollower] = useState({})
    const [currentPageFollower, setCurrentPageFollower] = useState(1)
    const [paginationFollower, setPaginationFollower] = useState({
        loading: true,
        pagination: true,
    })
    const [querySearchFollower, setQuerySearchFollower] = useState()
    const [dataFollowerChanged, setDataFollowerChanged] = useState(true)
    const [visibleModalRecommendation, setVisibleModalRecommendation] =
        useState(false)
    const [popShare, setPopShare] = useState(false)
    const [loading, setLoading] = useState(false)
    const [itemBook, setItemBook] = useState({})
    const [bookShare, setBookShare] = useState([])

    const [stateBook, setStateBook] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => stateBook.width <= 420

    const updateDimensions = () => {
        setStateBook({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getBookData = async () => {
        await provider
            .list(url.webhookUrl_queue_shelf + `?limit=${limit}`)
            .then((res) => {
                const { data, meta } = res
                setBookData(data)
                setTotalData(meta.total)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
            })
    }

    const getDataFollower = async () => {
        const limit = 10
        const pagination = getPagination(currentPageFollower, limit, 'webhook')
        const filterQuery = isEmpty(querySearchFollower)
            ? ''
            : {
                  'users.name': { ilike: `%${querySearchFollower}%` },
              }
        await provider
            .list(
                encodeURI(
                    `${url.webhookURL_follower}${
                        pagination.result
                    }&filter=${JSON.stringify(filterQuery)}&q=&user_id=${
                        profile.id
                    }`
                )
            )
            .then((res) => {
                const { data, meta } = res
                const filterData = filter(data, (e) => e.id !== profile.id)
                if (dataFollowerChanged) {
                    setUserFollower(filterData)
                } else {
                    setUserFollower(
                        isEmpty(userFollower)
                            ? filterData
                            : concat(userFollower, filterData)
                    )
                }

                setPaginationFollower({
                    ...paginationFollower,
                    loading: false,
                })
                if (pagination.offset >= meta.total || meta.total <= limit) {
                    setPaginationFollower({
                        loading: false,
                        pagination: false,
                    })
                }
            })
    }

    useEffect(() => {
        getDataFollower()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [querySearchFollower])

    useEffect(() => {
        getBookData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit])

    const onLoadMoreFollower = async () => {
        setPaginationFollower({
            ...paginationFollower,
            loading: true,
        })
        setCurrentPageFollower(currentPageFollower + 1)
    }

    const handleClickRecommend = () => {
        setVisibleModalRecommendation(true)
    }

    const handleClickShare = () => {
        setPopShare(true)
    }

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={loading}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 25)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }

    return (
        <div
            style={{
                marginTop: '-15px',
                maxWidth: 1030,
                margin: '0 auto',
            }}
        >
            <div className="historyMoreself">
                <div
                    className="historyMoreself-title"
                    style={{
                        textAlign: isMobile() && 'start',
                        marginLeft: !isMobile() && '67px',
                    }}
                >
                    {translator.t('action.queue')}
                </div>
            </div>
            <div className="pb__container">
                <Space
                    style={{ justifyContent: 'flex-start' }}
                    wrap
                    size={[0, 10]}
                >
                    {map(bookData, (book, key) => {
                        const {
                            cover_url,
                            book_title,
                            book_id: id,
                            epustaka_id,
                            book_author,
                        } = book
                        return (
                            <Col
                                key={key}
                                className="pb__containerGrid"
                                style={{
                                    margin: !isMobile()
                                        ? '0px 20px 0px 20px'
                                        : '0px 10px 0px 10px',
                                }}
                            >
                                <div>
                                    <Card
                                        style={{
                                            width: isMobile() ? 160 : 150,
                                            background: 'none',
                                            marginBottom: 20,
                                            minHeight: isMobile()
                                                ? '310px'
                                                : '280px',
                                        }}
                                        bodyStyle={{ marginTop: 0 }}
                                        className="pb__containerGridCard"
                                        cover={
                                            <div className="container-book">
                                                <div className="book">
                                                    <div className="front">
                                                        <div className="cover">
                                                            <FallbackImage
                                                                alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                                                src={cover_url}
                                                                onClick={() =>
                                                                    history.push(
                                                                        {
                                                                            pathname: `/book/${id}/${
                                                                                epustaka_id ||
                                                                                ''
                                                                            }`,
                                                                            state: {
                                                                                epustaka_id,
                                                                                fromQueue: true,
                                                                            },
                                                                        }
                                                                    )
                                                                }
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    width: isMobile()
                                                                        ? 153
                                                                        : 141,
                                                                    height: isMobile()
                                                                        ? 220
                                                                        : 190,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        actions={[
                                            <Tooltip
                                                title={translator.t(
                                                    'action.recommendation'
                                                )}
                                                color="#575757"
                                            >
                                                <RetweetOutlined
                                                    key="recommendation"
                                                    onClick={() =>
                                                        handleClickRecommend(
                                                            setItemBook(book)
                                                        )
                                                    }
                                                />
                                            </Tooltip>,
                                            <Tooltip
                                                title={translator.t(
                                                    'title.share'
                                                )}
                                                color="#575757"
                                            >
                                                <ShareAltOutlined
                                                    key="share"
                                                    onClick={() =>
                                                        handleClickShare(
                                                            setBookShare(book)
                                                        )
                                                    }
                                                />
                                            </Tooltip>,
                                        ]}
                                    >
                                        <Meta
                                            title={
                                                <Tooltip
                                                    title={book_title}
                                                    color="#575757"
                                                    placement="topLeft"
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: 600,
                                                            marginLeft: '12px',
                                                        }}
                                                        onClick={() =>
                                                            history.push({
                                                                pathname: `/book/${id}/${
                                                                    epustaka_id ||
                                                                    ''
                                                                }`,
                                                                state: {
                                                                    epustaka_id,
                                                                    fromQueue: true,
                                                                },
                                                            })
                                                        }
                                                    >
                                                        {book_title}
                                                    </span>
                                                </Tooltip>
                                            }
                                            description={
                                                <Text
                                                    style={{
                                                        width: 140,
                                                        fontSize: 13,
                                                        color: 'gray',
                                                        marginLeft: '12px',
                                                    }}
                                                    ellipsis={true}
                                                >
                                                    <Tooltip
                                                        title={book_author}
                                                        color="#575757"
                                                        placement="topLeft"
                                                    >
                                                        {book_author}
                                                    </Tooltip>
                                                </Text>
                                            }
                                        />
                                    </Card>
                                </div>
                            </Col>
                        )
                    })}
                    <ModalRecommendation
                        visibleModal={visibleModalRecommendation}
                        setVisibleModal={setVisibleModalRecommendation}
                        bookData={itemBook}
                        data={userFollower}
                        userId={profile.id}
                        organization_id={organization_id}
                        currentPageFollower={currentPageFollower}
                        paginationFollower={paginationFollower}
                        onLoadMore={onLoadMoreFollower}
                        getDataFollower={getDataFollower}
                        setDataFollowerChanged={setDataFollowerChanged}
                        setQuerySearchFollower={setQuerySearchFollower}
                        source="bookshelf"
                    />
                    <ModalShare
                        urlFrom="book"
                        data={bookShare}
                        visibleModal={popShare}
                        setVisibleModal={setPopShare}
                    />
                </Space>
                <div className="loadmore-btn">
                    <DispalyButton />
                </div>
            </div>
        </div>
    )
}

export default DetailBookQueue
