import auth from './auth/reducer'
import lang from './lang/reducer'
import profile from './profile/reducer'
import feed from './feed/reducer'
import tabKey from './tabKey/reducer'
import tabActive from './epustakaTabActive/reducer'
import timeLine from './timeLine/reducer'
import search from './search/reducer'
import config from './config/reducer'
import roles from './roles/reducer'
import schools from './schools/reducer'
import epustakaDetail from './epustakaDetail/reducer'
import menu from './menu/reducer'
import routeHistory from './routeHistory/reducer'
import prevPath from './prevPathame/reducer'
import dataCategoryBookFilter from './dataCategoryBookFilter/reducer'
import filterTryout from './filterTryout/reducer'
import switchActive from './bookFilterSwitch/reducer'
import bookFilterOptions from './bookFilterOptions/reducer'
import readProgress from './readProgress/reducer'
import bookmarkData from './bookmark/reducer'
import fromReader from './fromReader/reducer'
import lastReadingDuration from './lastReadingDuration/reducer'
import readingDuration from './readingDuration/reducer'
import notifReq from './notifReq/reducer'
import menuActiveDonasi from './donasiMenuActive/reducer'

export default {
    auth,
    dataCategoryBookFilter,
    lang,
    profile,
    tabKey,
    tabActive,
    timeLine,
    feed,
    search,
    config,
    roles,
    schools,
    epustakaDetail,
    menu,
    routeHistory,
    prevPath,
    filterTryout,
    switchActive,
    bookFilterOptions,
    readProgress,
    bookmarkData,
    fromReader,
    readingDuration,
    lastReadingDuration,
    notifReq,
    menuActiveDonasi,
}
