import React, { useRef, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import translator from 'i18next'
import { PlayCircleOutlined, ShareAltOutlined } from '@ant-design/icons'
import get from 'lodash/get'
import isNull from 'lodash/isNull'
import concat from 'lodash/concat'
import capitalize from 'lodash/capitalize'
import url from 'constants/url'
import { error } from 'utils/notification'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import action from 'redux/timeLine/action'
import provider from 'providers/provider'
import moment from 'moment'
import CommentList from '../components/CommentList'
import getPagination from 'constants/getPagination'
import { ModalShare } from 'components'
import { Helmet } from 'react-helmet'
import getAcronym from 'components/Acronym'

import {
    Card,
    Col,
    Row,
    Tabs,
    List,
    Button,
    Avatar,
    Form,
    Input,
    Modal,
    Radio,
    Image,
    Skeleton,
} from 'antd'
import { BASE_TITLE } from 'constants/constant'
import ModalContent from 'components/ModalContent'
import { ModalNoAccess, ModalJoinEpustaka } from 'components'
import { isEmpty, isNil } from 'lodash'
import reduxState from '../../../../redux'

const { TabPane } = Tabs
const { TextArea } = Input

const VideoDetail = (props) => {
    const epustakaId = props?.match?.params?.epustaka
    const videoProgress = props?.match?.params?.d
    const { profile = {} } = reduxState.getState()

    const { setTimeLine, stateTimeLine, history } = props
    const videoRef = useRef(null)
    const inputRef = useRef(null)
    const intersectionRef = useRef(null)
    const [form] = Form.useForm()
    const videoId = props.match.params.uuid

    const [videoData, setVideoData] = useState([])
    const [userProfil, setUserProfile] = useState([])
    const [dataComment, setDataComment] = useState([])
    const [videoLoading, setVideoLoading] = useState(false)
    const [isModalVisibleShare, setIsModalVisibleShare] = useState(false)
    const [totalComment, setTotalComment] = useState(0)
    const [popupModal, setPopupModal] = useState(false)
    const [popupNoAccess, setPopupNoAccess] = useState(false)
    const [contentPrivate, setContentPrivate] = useState(false)
    const [epustakaAvailableData, setEpustakaAvailableData] = useState({})
    const [visibleModal, setVisibleModal] = useState(false)
    const [epustakaID, setEpustakaID] = useState(epustakaId)

    const limit = 10
    const [limitComment, setLimitComment] = useState(1)
    const pagination = getPagination(limitComment, limit, 'webhook')

    const object_type = '&object_type=VIDEO'
    const objId = `&object_id=${videoId}`

    const senderId = get(userProfil, 'id', '')
    const userAvatar = get(userProfil, 'avatar_url', '')

    const id = userProfil?.id || ''
    const name = userProfil?.name || ''
    const { color, acronym } = getAcronym(name, id)

    const [postComment, setPostComment] = useState('')
    const [reportComment, setReportComment] = useState('')
    const [previewComment, setPreviewComment] = useState(false)
    const [loading, setLoading] = useState(false)
    const [OpenReply, setOpenReply] = useState(false)

    const [isVisible, setIsVisible] = useState(null)

    const [progress, setProgress] = useState(0)
    const [preload, setPreload] = useState(false)

    const [confirmLoading, setConfirmLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [item, setItem] = useState({})

    const [paginationComment, setPaginationComment] = useState({
        loading: true,
        pagination: true,
    })

    const [config, setConfig] = useState({
        muted: false,
        playing: false,
    })

    // handle function video player

    const onDuration = () => {
        !isEmpty(videoProgress) && videoRef.current.seekTo(videoProgress)
    }

    const onProgress = (progress) => {
        setProgress(progress.playedSeconds)
    }

    const onPause = () => {
        handleHistoryVideo(videoData)
    }

    const intersectCallback = (entries) => {
        const [entry] = entries
        setIsVisible(entry.isIntersecting)
    }

    useEffect(() => {
        const observer = new IntersectionObserver(intersectCallback, {
            root: null,
            threshold: 0.1,
            rootMargin: '0px',
        })
        intersectionRef.current && observer.observe(intersectionRef.current)
        return () =>
            intersectionRef.current &&
            observer.unobserve(intersectionRef.current)
    }, [intersectionRef])

    const getProfile = async () => {
        await provider
            .singleWebHook(`${url.webhookURL_profile}`)
            .then((res) => {
                setUserProfile(res.data)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    useEffect(() => {
        getProfile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // To Post a comment in comment form
    const submitData = async (url, data) => await provider.insert(url, data)
    const handleSave = async () => {
        setLoading(true)
        await submitData(url.webhookURL_comment, {
            object_type: 'VIDEO',
            object_id: `${videoId}`,
            comment: `${postComment}`,
        })
            .then(async () => {
                setTimeLine({ refresh: true })
                setPreviewComment(!previewComment)
                form.setFieldsValue({
                    senderTextarea: '',
                })
                setOpenReply(!OpenReply)
                setLoading(false)
                // setFileList([])
            })
            .catch((err) => {
                Promise.reject(err)
                setLoading(false)
            })
        setPostComment('')
    }
    const handleFinish = async () => {
        await handleSave()
    }

    const radioStyle = {
        display: 'flex',
        height: '30px',
        lineHeight: '22px',
        marginBottom: '10px',
    }

    const handleOk = async (value) => {
        setConfirmLoading(true)
        return await provider
            .put(url.webhookURL_comment_report, {
                comment_id: `${value.id}`,
                reason: `${reportComment}`,
            })
            .then(() => {
                setTimeLine({ refreshLike: true })
                setConfirmLoading(false)
                setIsModalVisible(false)
                value.has_report === true
                    ? Modal.warning({
                          title: `${translator.t('message.warningReport')}`,
                          width: 550,
                          content: (
                              <div>
                                  <p>
                                      {translator.t(
                                          'message.message_warning_report'
                                      )}
                                  </p>
                              </div>
                          ),
                          onOk() {},
                      })
                    : Modal.info({
                          title: `${translator.t('message.successReport')}`,
                          width: 700,
                          content: (
                              <div>
                                  <p>
                                      {translator.t(
                                          'message.message_success_report'
                                      )}
                                  </p>
                              </div>
                          ),
                          onOk() {},
                      })
            })
            .catch((err) => {
                setIsModalVisible(false)
                setConfirmLoading(false)
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const onReportComment = (value) => {
        setIsModalVisible(true)
        setItem(value)
    }

    const dataMessageSender = [
        {
            avatar: (
                <Avatar
                    style={{
                        cursor: 'pointer',
                        backgroundImage: color,
                        display: 'inline-flex',
                        alignItems: 'center',
                    }}
                    src={userAvatar}
                >
                    <span>{acronym}</span>
                </Avatar>
            ),
            description: (
                <div>
                    <Form form={form}>
                        <div
                            style={{
                                marginRight: '10px',
                                marginLeft: '-5px',
                            }}
                        >
                            <div>
                                <Form.Item name="senderTextarea">
                                    <TextArea
                                        bordered={false}
                                        onFocus={() => {
                                            setPreviewComment(!previewComment)
                                        }}
                                        className="textArea-add"
                                        placeholder={`${translator.t(
                                            'feed.addComment'
                                        )}`}
                                        autoSize
                                        value={postComment}
                                        onChange={(e) =>
                                            setPostComment(e.target.value)
                                        }
                                    />
                                </Form.Item>
                            </div>
                        </div>
                        {previewComment && (
                            <div
                                ref={inputRef}
                                style={{
                                    textAlign: 'end',
                                    marginTop: '-8px',
                                    marginRight: '10px',
                                }}
                            >
                                <div className="btn-comment-edit">
                                    <Button
                                        style={{
                                            marginRight: 5,
                                            fontWeight: 'bold',
                                            color: '#777f8f',
                                            border: 'none',
                                            marginTop: '10px',
                                        }}
                                        onClick={() => {
                                            setPreviewComment(!previewComment)
                                            form.setFieldsValue({
                                                senderTextarea: '',
                                            })
                                        }}
                                        // loading={loadingEditComment}
                                        type="text"
                                    >
                                        {translator.t('action.cancell')}
                                    </Button>
                                    <Button
                                        onClick={handleFinish}
                                        disabled={!postComment}
                                        loading={loading}
                                        // loading={
                                        //     loadingEditComment
                                        // }
                                        type="primary"
                                    >
                                        {translator.t('action.comment')}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Form>
                </div>
            ),
        },
    ]

    const handleHistoryVideo = async () => {
        const data = {
            epustaka_collection_id: videoData?.epustaka_collection_id,
            user_id: senderId,
            progress: parseInt(progress),
        }
        await provider.insert(
            url.webhookURL_collection_access_history,
            data,
            'webhook'
        )
    }
    const getDetailVideo = async (data) => {
        const filter = epustakaId || data?.id
        setVideoLoading(true)
        await provider
            .singleWebHook(
                `${url.webhookURL_multimedia_detail}?type=video&media_id=${videoId}` +
                    `&epustaka_id=${filter}`
            )
            .then((res) => {
                const { data } = res
                isNil(epustakaId) && setEpustakaID(data?.epustaka?.id)
                if (
                    data?.epustaka?.has_join &&
                    data?.epustaka?.member_status === 1
                ) {
                    setConfig({
                        muted: true,
                        playing: true,
                    })
                    setContentPrivate(false)
                } else {
                    setContentPrivate(true)
                }
                setVideoData(data)
                setVideoLoading(false)
            })
            .catch((err) => {
                history.replace('/')
                error({
                    description: err.response?.data?.error,
                    placement: 'bottomRight',
                })
            })
    }

    const epustakaAvailable = async () => {
        await provider
            .singleWebHook(
                `${url.webhookURL_epustaka_available}?collection_id=${videoId}`
            )
            .then(({ data }) => {
                setEpustakaAvailableData(data)

                const notJoin = data?.has_join && data?.member_status !== 1

                if (!data?.has_join || notJoin) {
                    setContentPrivate(true)
                }
                getDetailVideo(data)
            })
            .catch(() => {
                // setPopupNoAccess(true)
                setContentPrivate(true)
                getDetailVideo()
            })
    }

    useEffect(() => {
        isNil(epustakaId) ? epustakaAvailable() : getDetailVideo()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoId])

    const onLoadMoreComment = async () => {
        setPaginationComment({
            ...paginationComment,
            loading: true,
        })
        setLimitComment(limitComment + 1)
    }

    const getCommentList = async () => {
        setLoading(true)
        await provider
            .list(
                `${
                    url.webhookURL_comment_list +
                    pagination.result +
                    object_type +
                    objId
                }`
            )
            .then((results) => {
                const { data, meta } = results

                limitComment === 1
                    ? setDataComment(data)
                    : setDataComment(concat(dataComment, data))

                if (pagination.offset >= meta.total || meta.total <= limit) {
                    setPaginationComment({
                        loading: false,
                        pagination: false,
                    })
                } else {
                    setPaginationComment({
                        ...paginationComment,
                        loading: false,
                    })
                }

                setTotalComment(meta.total)
                setLoading(false)
                setTimeLine({ refresh: false })
            })
            .catch((err) => {
                setLoading(false)
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })

                return
            })
    }

    useEffect(() => {
        getCommentList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limitComment])

    useEffect(() => {
        if (stateTimeLine.refresh) {
            limitComment === 1 ? getCommentList() : setLimitComment(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateTimeLine.refresh])

    const handleClickShare = () => {
        setIsModalVisibleShare(true)
    }

    const dataDescription = [
        {
            // avatar: <Avatar src={logo_edoo} size={45} />,
            title: videoData.media_title,
            description: (
                <span>
                    Di Unggah {moment(videoData.created_at).format('ll')}
                </span>
            ),
        },
    ]

    const onPlay = () => {}

    const mediaPlayer = (e) => {
        if (contentPrivate) {
            return (
                <div
                    className={videoLoading ? 'test' : 'content-private-video'}
                >
                    {videoLoading ? (
                        <Skeleton.Input
                            style={{
                                width: '100%',
                                paddingTop: '56.25%',
                            }}
                            active={true}
                            size="large"
                        />
                    ) : (
                        <Image
                            src={e?.media_thumbnail_url}
                            style={{ width: '100%', maxHeight: '56vh' }}
                            preview={{
                                visible: false,
                                onVisibleChange: (v) => {
                                    setVisibleModal(v)
                                },
                                mask: (
                                    <>
                                        <PlayCircleOutlined
                                            style={{ fontSize: 48 }}
                                        />
                                    </>
                                ),
                            }}
                        />
                    )}
                </div>
            )
        } else {
            if (e?.media_extension === 'youtube') {
                return (
                    <div>
                        {videoLoading ? (
                            <Skeleton.Input
                                style={{
                                    width: '100%',
                                    paddingTop: '56.25%',
                                }}
                                active={true}
                                size="large"
                            />
                        ) : (
                            <div className="yt-section-wrapper">
                                <ReactPlayer
                                    ref={videoRef}
                                    url={videoData.media_file_url}
                                    className="react-player"
                                    width="100%"
                                    height="100%"
                                    playsinline
                                    controls
                                    muted
                                    onProgress={onProgress}
                                    onDuration={onDuration}
                                    style={{ outline: 'none' }}
                                    // Disable download button
                                    config={{
                                        file: {
                                            attributes: {
                                                controlsList: 'nodownload',
                                            },
                                        },
                                    }}
                                    // Disable right click
                                    onContextMenu={(e) => e.preventDefault()}
                                    onPause={onPause}
                                    // onPlay={onPlay}
                                />
                            </div>
                        )}
                    </div>
                )
            } else {
                return (
                    <div className={videoLoading ? 'test' : 'cover-image'}>
                        {videoLoading ? (
                            <Skeleton.Input
                                style={{
                                    width: '100%',
                                    paddingTop: '56.25%',
                                }}
                                active={true}
                                size="large"
                            />
                        ) : (
                            <ReactPlayer
                                key={preload}
                                ref={videoRef}
                                url={videoData.media_file_url}
                                className="react-player"
                                width="100%"
                                height="100%"
                                playsinline
                                controls
                                onProgress={onProgress}
                                onDuration={onDuration}
                                style={{ outline: 'none' }}
                                // Disable download button
                                onError={() => {
                                    setPreload(true)
                                }}
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: 'nodownload',
                                            ...(preload && {
                                                preload: null,
                                            }),
                                        },
                                    },
                                }}
                                // Disable right click
                                onContextMenu={(e) => e.preventDefault()}
                                onPause={onPause}
                                onPlay={onPlay}
                                {...(!preload && config)}
                            />
                        )}
                    </div>
                )
            }
        }
    }

    return (
        // detail video container
        <div className="dv-body-container" ref={inputRef}>
            <Helmet>
                <title>
                    {capitalize(videoData.media_title)} - {BASE_TITLE} Website
                </title>
                <meta
                    property="og:image"
                    content={videoData.media_thumbnail_url}
                />
                <meta
                    name="og:title"
                    content={`${BASE_TITLE} Website - Platform Perpustakaan Indonesia`}
                />
                <meta
                    name="description"
                    content="Video Page - Mari bergabung dan berkolaborasi mengembangkan pendidikan Indonesia bersama anak bangsa."
                />
            </Helmet>
            <Row
                type="flex"
                style={{ alignItems: 'center', marginTop: '2rem' }}
                justify="center"
            >
                <Col
                    xs={24}
                    sm={24}
                    md={20}
                    lg={15}
                    xl={15}
                    className="dv-container"
                >
                    <Card
                        className="detail-card"
                        title={translator.t('title.video_detail')}
                    >
                        <div
                            ref={intersectionRef}
                            className={
                                videoData?.media_extension !== 'youtube' &&
                                'video-wrapper'
                            }
                        >
                            {/* ReactPlayer Section */}
                            <Row
                                type="flex"
                                style={{ alignItems: 'center' }}
                                justify="center"
                                className={
                                    isVisible ? 'video-visible' : 'pip-video'
                                }
                            >
                                <Col span={24}>{mediaPlayer(videoData)}</Col>
                            </Row>
                        </div>

                        <div
                            className={
                                videoData?.media_extension !== 'youtube' &&
                                'description-wrapper'
                            }
                        >
                            {/* Share Section */}
                            <Row>
                                <Col span={24} className="vd-description">
                                    <List
                                        className="vd-description-title"
                                        itemLayout="horizontal"
                                        dataSource={dataDescription}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    // avatar={item.avatar}
                                                    title={
                                                        <div className="vd-title">
                                                            {videoLoading ? (
                                                                <Skeleton.Input
                                                                    style={{
                                                                        width: 210,
                                                                    }}
                                                                    active={
                                                                        true
                                                                    }
                                                                    size="small"
                                                                />
                                                            ) : (
                                                                item.title
                                                            )}
                                                        </div>
                                                    }
                                                    description={
                                                        <div className="vd-subtitle">
                                                            {videoLoading ? (
                                                                <Skeleton.Input
                                                                    style={{
                                                                        width: 150,
                                                                        height: 15,
                                                                    }}
                                                                    active={
                                                                        true
                                                                    }
                                                                    size="small"
                                                                />
                                                            ) : (
                                                                item.description
                                                            )}
                                                        </div>
                                                    }
                                                />
                                                <div className="vd-btn-follow">
                                                    {videoLoading ? (
                                                        <Skeleton.Input
                                                            style={{
                                                                width: 85,
                                                                borderRadius: 10,
                                                            }}
                                                            active={true}
                                                            size="default"
                                                        />
                                                    ) : (
                                                        <Button
                                                            onClick={() =>
                                                                handleClickShare(
                                                                    videoData
                                                                )
                                                            }
                                                        >
                                                            <ShareAltOutlined />{' '}
                                                            {translator.t(
                                                                'title.share'
                                                            )}
                                                        </Button>
                                                    )}
                                                </div>
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                            {/* Description Section */}
                            <Row
                                className="description-video-tabs"
                                style={{ borderTop: '1px solid #eff2f5' }}
                            >
                                <Col className="tab" span={24}>
                                    {/* {videoLoading ? (
                                    skeletonVideo
                                ) : ( */}

                                    <Tabs defaultActiveKey="1" centered>
                                        <TabPane
                                            tab={translator.t(
                                                'title.description'
                                            )}
                                            className="tab-info"
                                            key="1"
                                        >
                                            {videoLoading ? (
                                                <>
                                                    <Skeleton.Input
                                                        style={{
                                                            width: 200,
                                                            height: 15,
                                                        }}
                                                        active={true}
                                                        size="small"
                                                    />
                                                    <Skeleton.Input
                                                        style={{
                                                            width: 250,
                                                            height: 15,
                                                        }}
                                                        active={true}
                                                        size="small"
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    {!isEmpty(
                                                        videoData.media_description
                                                    ) && (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: videoData.media_description,
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </TabPane>
                                        <TabPane
                                            tab={translator.t('title.detail')}
                                            key="2"
                                        >
                                            <table className="table-detail-video">
                                                <tbody>
                                                    <tr>
                                                        <th className="table-title">
                                                            {translator.t(
                                                                'filter.category'
                                                            )}
                                                        </th>
                                                        <th className="table-title-value active-color">
                                                            {isNull(
                                                                get(
                                                                    videoData,
                                                                    'category_name',
                                                                    '-'
                                                                )
                                                            )
                                                                ? '-'
                                                                : get(
                                                                      videoData,
                                                                      'category_name',
                                                                      '-'
                                                                  )}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th className="table-title">
                                                            {translator.t(
                                                                'filter.published_by'
                                                            )}
                                                        </th>
                                                        <th className="table-title-value">
                                                            -
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th className="table-title">
                                                            {translator.t(
                                                                'filter.year'
                                                            )}
                                                        </th>
                                                        <th className="table-title-value active-color">
                                                            {moment(
                                                                get(
                                                                    videoData,
                                                                    'created_at',
                                                                    '-'
                                                                )
                                                            ).format('LLLL')}
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </TabPane>
                                        <TabPane
                                            tab={
                                                dataComment.length === 0
                                                    ? `${translator.t(
                                                          'filter.comment'
                                                      )}`
                                                    : `${
                                                          translator.t(
                                                              'filter.comment'
                                                          ) +
                                                          ' ' +
                                                          `(${totalComment})`
                                                      }`
                                            }
                                            key="3"
                                        >
                                            <List
                                                className="vd-comment"
                                                itemLayout="horizontal"
                                                dataSource={dataMessageSender}
                                                renderItem={(item) => (
                                                    <div>
                                                        <List.Item>
                                                            <List.Item.Meta
                                                                avatar={
                                                                    item.avatar
                                                                }
                                                                title={
                                                                    item.title
                                                                }
                                                                description={
                                                                    item.description
                                                                }
                                                            />
                                                        </List.Item>

                                                        <div className="container-comment-list">
                                                            <div
                                                                style={{
                                                                    borderTop:
                                                                        '1px solid #eff2f5',
                                                                    marginTop:
                                                                        -7,
                                                                }}
                                                            />
                                                            <CommentList
                                                                data={
                                                                    dataComment
                                                                }
                                                                onLoadMore={
                                                                    onLoadMoreComment
                                                                }
                                                                paginationComment={
                                                                    paginationComment
                                                                }
                                                                getCommentList={
                                                                    getCommentList
                                                                }
                                                                dataId={videoId}
                                                                onReportComment={
                                                                    onReportComment
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            />
                                        </TabPane>
                                    </Tabs>
                                    {/* )} */}
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Modal
                className="modal-report"
                title={`${translator.t('title.report_comment')}`}
                visible={isModalVisible}
                onOk={() => handleOk(item)}
                onCancel={handleCancel}
                okButtonProps={{ disabled: !reportComment }}
                confirmLoading={confirmLoading}
                okText={`${translator.t('action.report')}`}
                cancelText={`${translator.t('action.cancell')}`}
            >
                <div className="radio">
                    <span>
                        <Radio.Group
                            onChange={(e) => {
                                setReportComment(e.target.value)
                            }}
                        >
                            <Radio
                                style={radioStyle}
                                value="Materi pornografi atau seksual vulgar"
                            >
                                Materi pornografi atau seksual vulgar
                            </Radio>
                            <Radio
                                style={radioStyle}
                                value="Perkataan yang mendorong kebencian atau
                                kekerasan"
                            >
                                Perkataan yang mendorong kebencian atau
                                kekerasan
                            </Radio>
                            <Radio
                                style={radioStyle}
                                value="Konten komersial atau spam yang tidak diinginkan"
                            >
                                Konten komersial atau spam yang tidak diinginkan
                            </Radio>
                            <Radio
                                style={radioStyle}
                                value="Pelecehan atau bullying"
                            >
                                Pelecehan atau bullying
                            </Radio>
                        </Radio.Group>
                    </span>
                </div>
            </Modal>

            <ModalShare
                collectionId={videoData?.id}
                urlFrom="video"
                visibleModal={isModalVisibleShare}
                setVisibleModal={setIsModalVisibleShare}
            />

            <ModalContent
                visibleModal={popupModal}
                setVisibleModal={setPopupModal}
                data={epustakaAvailableData}
                dataRefresh={epustakaAvailable}
            />

            <ModalJoinEpustaka
                epustakaId={epustakaID}
                visibleModal={visibleModal}
                setVisibleModal={setVisibleModal}
                profile={profile}
                contentData={videoData}
                requestData={getDetailVideo}
            />

            <ModalNoAccess
                visibleModal={popupNoAccess}
                setVisibleModal={setPopupNoAccess}
                title="title.video_lowercase"
                contentPrivate={contentPrivate}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    stateTimeLine: get(state, 'timeLine', {}),
})

const mapDispatchToProps = () => ({
    setTimeLine: action.setTimeLine,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(VideoDetail))
