import React from 'react'
import translator from 'i18next'
import { COLORS } from 'constants/constant'
const ModalNotif = () => {
    return (
        <div
            style={{
                color: COLORS.primaryColor,
                padding: '10px',
                textAlign: 'center',
            }}
        >
            {translator.t('message.notif')}
        </div>
    )
}

export default ModalNotif
