import React, { useState, useEffect } from 'react'
import { Modal, Button, Row, Col, Avatar, message } from 'antd'
import get from 'lodash/get'
import translator from 'i18next'
import provider from 'providers/provider'
import url from 'constants/url'
import { error } from 'utils/notification'

const ModalJoinEpustaka = ({
    visibleModal,
    setVisibleModal,
    profile,
    contentData,
    epustakaId,
    requestData,
}) => {
    const notJoin =
        (contentData?.epustaka?.has_join &&
            contentData?.epustaka?.member_status !== 1) ||
        contentData?.epustaka?.has_join === false

    const expiredData =
        contentData?.epustaka?.has_join &&
        contentData?.epustaka?.member_status === 3

    const [loading, setLoading] = useState(false)
    const [waitingReq, setWaitingReq] = useState(false)

    const name = contentData?.epustaka?.epustaka_name

    const handleBorrow = async () => {
        setLoading(true)
        const dataReg = {
            epustaka_id: epustakaId,
            user_ids: [profile.id],
        }

        if (notJoin || expiredData) {
            await provider
                .insert(url.webhookURL_join_epustaka_member, dataReg)
                .then(() => {
                    if (contentData?.epustaka?.epustaka_isprivate) {
                        message.success(translator.t('epustka.notifPrivat'))
                        setWaitingReq(true)
                        setVisibleModal(false)
                    } else {
                        setVisibleModal(false)
                        setLoading(false)
                    }
                    requestData({ id: epustakaId })
                })

                .catch((err) => {
                    setLoading(false)
                    error({
                        description: err.message,
                        placement: 'bottomRight',
                    })
                })
        }

        setLoading(false)
    }

    useEffect(() => {
        if (
            contentData?.epustaka?.member_status == 0 &&
            contentData?.epustaka?.epustaka_isprivate
        )
            setWaitingReq(true)
    }, [contentData])

    return (
        <Modal
            centered
            open={visibleModal}
            onOk={() => setVisibleModal(!visibleModal)}
            onCancel={() => setVisibleModal(!visibleModal)}
            footer={null}
            width={500}
            className="modal-popup"
            style={{
                borderRadius: '20px',
            }}
        >
            <Row justify="center">
                <Col
                    className="mt-lg"
                    span={20}
                    style={{ textAlign: 'center' }}
                >
                    <h2 className="epustaka-name">{name}</h2>
                    <Avatar
                        size={100}
                        shape="square"
                        src={contentData?.epustaka?.epustaka_logo}
                    />
                    <div className="title" style={{ textAlign: 'center' }}>
                        {waitingReq
                            ? translator.t('message.waitingJoin')
                            : expiredData
                            ? translator.t('message.expiredJoinEpustaka')
                            : translator.t('message.notJoinEpustaka')}
                    </div>

                    <Button
                        loading={loading}
                        size="large"
                        // style={{
                        //     padding: '6px 80px 6px 80px',
                        //     borderRadius: '20px',
                        //     marginBottom: '3vh',
                        // }}
                        className={
                            waitingReq ||
                            (contentData.using_drm &&
                                get(contentData, 'available_qty', 0) <= 0)
                                ? 'btn-popup-modal-disabled'
                                : 'btn-popup-modal'
                        }
                        onClick={handleBorrow}
                        disabled={
                            waitingReq ||
                            (contentData.using_drm &&
                                get(contentData, 'available_qty', 0) <= 0)
                        }
                    >
                        {waitingReq
                            ? translator.t('title.pending_approval')
                            : translator.t(
                                  notJoin
                                      ? contentData?.epustaka
                                            ?.epustaka_isprivate
                                          ? 'title.askJoin'
                                          : 'epustka.join'
                                      : expiredData
                                      ? 'title.extend'
                                      : 'action.borrow'
                              )}
                    </Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default ModalJoinEpustaka
