import { Col, Row, Select, Space, Tooltip, Typography } from 'antd'

import React, { useState, useLayoutEffect } from 'react'
import translator from 'i18next'

const { Text } = Typography
const { Option } = Select

const MultimediaSorter = (props) => {
    const { categoryName, handleSorting } = props

    const isMobile = () => state.width <= 370
    const isMiddle = () => state.width <= 470

    const [state, setState] = useState({
        collapsed: false,
        width: window.innerWidth,
    })

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    return (
        <Row className="container-sorter-home">
            <Col span={isMiddle() ? 11 : 12} className="category-name">
                <Tooltip
                    title={
                        categoryName === 'recommendation'
                            ? translator.t('action.recommendation')
                            : categoryName
                    }
                >
                    <Text ellipsis={true} className="name">
                        {categoryName === 'recommendation'
                            ? translator.t('action.recommendation')
                            : categoryName}
                    </Text>
                </Tooltip>
            </Col>
            <Col
                span={12}
                className="order-component"
                style={{ textAlign: 'end' }}
            >
                <Space>
                    <Select
                        size="small"
                        style={{
                            width: isMobile() ? 100 : isMiddle() ? 110 : 150,
                        }}
                        bordered={false}
                        placeholder={translator.t('action.sort')}
                        onChange={handleSorting}
                    >
                        <Option value="latest">
                            {translator.t('sort.latest')}
                        </Option>
                        <Option value="abjad">
                            {translator.t('sort.abjad')}
                        </Option>
                    </Select>
                </Space>
            </Col>
        </Row>
    )
}

export default MultimediaSorter
