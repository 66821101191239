const webhook = 'webhook'
// const tables = 'tables'
const authentication = 'auth'

export default {
    login: `${authentication}/login`,
    auth_google_token: `${authentication}/google/token`,
    authURL_auth_account: `${authentication}/account`,
    refresh_token_url: `${authentication}/refresh-token`,
    reset_password: `${authentication}/reset-password`,
    reset_password_ticket: `${authentication}/reset-password-ticket`,
    account: `${authentication}/account`,
    register: `${authentication}/register`,
    resendVerification: `${authentication}/resend-verification`,

    webhookURL_banners: `${webhook}/banners`,
    webhookURL_profile: `${webhook}/profile`,
    webhookURL_book_borrow_shelf: `${webhook}/book-borrow-shelf`,
    webhookURL_book_recommendation: `${webhook}/book-recommendation`,
    webhookURL_book_home: `${webhook}/book-home`,
    webhookURL_suggest_friends: `${webhook}/suggested-friends`,
    webhookURL_epustaka_list: `${webhook}/epustaka-list`,
    webhookURL_multimedia_home: `${webhook}/multimedia-home`,
    webhookURL_multimedia_list: `${webhook}/multimedia-list`,
    webhookURL_multimedia_detail: `${webhook}/multimedia-detail`,
    webhookURL_multimedia_history_list: `${webhook}/multimedia-history-list`,
    webhookURL_personal_event_detail: `${webhook}/personal-event-detail`,
    webhookURL_personal_event_participant_list: `${webhook}/personal-event-participant-list`,
    webhookURL_district_list: `${webhook}/district-list`,
    webhookURL_book_author_list: `${webhook}/book-author-list`,
    webhookURL_book_list: `${webhook}/book-list`,
    webhookURL_book_detail: `${webhook}/book-detail`,
    webhookURL_book_rating: `${webhook}/book-rating`,
    webhookURL_book_borrower_list: `${webhook}/book-borrower-list`,
    webhookURL_book_queue: `${webhook}/book-queue`,
    webhookURL_borrow: `${webhook}/borrow`,
    webhookURL_join_epustaka_member: `${webhook}/join-epustaka-member`,
    webhookURL_epustaka_borrow: `${webhook}/epustaka-borrow`,
    webhookURL_check_borrow_status: `${webhook}/check-borrow-status`,
    webhookURL_feeds: `${webhook}/feeds`,
    webhookURL_feed: `${webhook}/feed`,
    webhookURL_posting: `${webhook}/posting`,
    webhookURL_like: `${webhook}/like`,
    webhookURL_follower: `${webhook}/follower`,
    webhookUrl_book_history_shelf: `${webhook}/book-history-shelf`,
    webhookUrl_self_donation_list: `${webhook}/self-donation-list`,
    webhookUrl_following: `${webhook}/following`,
    webhookURL_notifications: `${webhook}/notifications`,
    webhookURL_notification: `${webhook}/notification`,
    webhookURL_comment_list: `${webhook}/comment-list`,
    webhookURL_comment_detail: `${webhook}/comment-detail`,
    webhookURL_comment_report: `${webhook}/comment-report`,
    webhookURL_epustaka_detail: `${webhook}/epustaka-detail`,
    webhookURL_epustaka_book_collection: `${webhook}/epustaka-book-collection`,
    webhookURL_epustaka_member_list: `${webhook}/epustaka-member-list`,
    webhookURL_search_book: `${webhook}/search-book`,
    webhookURL_search_multimedia: `${webhook}/search-multimedia`,
    webhookURL_search_user: `${webhook}/search-user`,
    webhookUrl_book_return: `${webhook}/book-return`,
    webhookUrl_queue_shelf: `${webhook}/book-queue-shelf`,
    webhookUrl_review_shelf: `${webhook}/book-review-shelf`,
    webhookURL_book_rating_report: `${webhook}/book-rating-report`,
    webhookURL_epustaka_member_join_verification: `${webhook}/epustaka-member-join-verification`,
    webhookURL_multimedia_history: `${webhook}/multimedia-history`,
    webhookURL_collection_access_history: `${webhook}/collection-access-history`,
    webhookURL_book_rating_detail: `${webhook}/book-rating-detail`,
    webhookURL_open_book: `${webhook}/open-book`,
    webhookURL_epustaka_feeds: `${webhook}/epustaka-feeds`,
    webhookURL_menu: `${webhook}/menu`,
    webhookURL_schedule_list: `${webhook}/schedule-list`,
    webhookURL_schedule_detail_student: `${webhook}/schedule-detail-student`,
    webhookURL_schedule_detail_teacher: `${webhook}/schedule-detail-teacher`,
    webhookURL_schedule_student_list: `${webhook}/schedule-student-list`,
    webhookURL_subject_material_list: `${webhook}/subject-material-list`,
    webhookURL_mandatory_book_list: `${webhook}/mandatory-book-list`,
    webhookURL_assignment_delivery_list: `${webhook}/assignment-delivery-list`,
    webhookURL_quiz_detail: `${webhook}/quiz-detail`,
    webhookURL_quiz_participant_list: `${webhook}/quiz-participant-list`,
    webhookURL_quiz_check: `${webhook}/quiz-check`,
    webhookURL_quiz_question_list: `${webhook}/quiz-question-list`,
    webhookURL_quiz_user_answer: `${webhook}/quiz-user-answer`,
    webhookURL_schedule_today: `${webhook}/schedule-today`,
    webhookURL_province_list: `${webhook}/province-list`,
    webhookURL_regency_list: `${webhook}/regency-list`,
    webhookURL_school_join_request: `${webhook}/school-join-request`,
    webhookURL_tryout_list: `${webhook}/tryout-list`,
    webhookURL_tryout_detail: `${webhook}/tryout-detail`,
    webhookURL_tryout_participant_list: `${webhook}/tryout-participant-list`,
    webhookURL_quiz_rating_list: `${webhook}/quiz-rating-list`,
    webhookURL_quiz_rating_detail: `${webhook}/quiz-rating-detail`,
    webhookURL_tryout_achievement: `${webhook}/tryout-achievements`,
    webhookURL_tryout_explanation: `${webhook}/tryout-explanation`,
    webhookURL_quiz_rating_report: `${webhook}/quiz-rating-report`,
    webhookURL_add_order_box: `${webhook}/add-order-box`,
    webhookURL_order_box_detail: `${webhook}/order-box-detail`,
    webhookURL_order_box_details: `${webhook}/order-box-details`,
    webhookURL_xnd_invoice: `${webhook}/xnd-invoice`,
    webhookURL_xnd_create_transaction: `${webhook}/xnd-create-transaction`,
    webhookURL_order_box_list: `${webhook}/order-box-list`,
    webhookURL_question_reference_materials: `${webhook}/question-reference-materials`,
    webhookURL_register_school: `${webhook}/school-register`,
    webhookURL_redeem_voucher: `${webhook}/redeem-voucher`,
    webhookURL_collection_owner: `${webhook}/collection-owner`,
    webhookURL_has_epustaka: `${webhook}/has-epustaka`,
    webhookURL_donation_epustaka_list: `${webhook}/donation-epustaka-list`,
    webhookURL_donation_book_list: `${webhook}/donation-book-list`,
    webhookURL_fcm_token: `${webhook}/fcm-token`,
    webhookURL_epustaka_available: `${webhook}/epustaka-available`,
    webhookURL_news_list: `${webhook}/news-list`,
    webhookURL_news_detail: `${webhook}/news-detail`,
    webhookURL_news_history_list: `${webhook}/news-history-list`,
    webhookURL_book_reading_progress: `${webhook}/book-reading-progress`,
    webhookURL_sync_bookmark: `${webhook}/sync-bookmark`,
    webhookURL_epustaka_nonmember_list: `${webhook}/epustaka-nonmember-list`,
    webhookURL_qr_info: `${webhook}/qr-info`,
    webhookURL_public_book_detail: `${webhook}/public-book-detail`,
    webhookURL_public_multimedia_detail: `${webhook}/public-multimedia-detail`,
    webhookURL_public_news_detail: `${webhook}/public-news-detail`,
    webhookURL_public_epustaka_detail: `${webhook}/public-epustaka-detail`,
    webhookURL_check_password: `${webhook}/check-account`,
    webhookURL_payment_method_list: `${webhook}/payment-method-list`,
    webhookURL_create_transaction: `${webhook}/create-transaction`,
    webhookURL_invoice_status: `${webhook}/invoice-status`,

    // refactor
    webhookURL_comment: `${webhook}/comment`,
    webhookURL_user_follow: `${webhook}/user-follow`,
    webhookURL_news_category_list: `${webhook}/news-category-list`,
    // TODO (searching)
    webhookURL_book_category_list: `${webhook}/book-category-list`,
    webhookURL_bookmark_list: `${webhook}/bookmark-list`,
    webhookURL_book_reading_durations: `${webhook}/book-reading-durations`,
    webhookURL_book_citation: `${webhook}/book-citation`,
    webhookURL_book_reading_progress_list: `${webhook}/book-reading-progress-list`,
    webhookURL_book_reading_duration_list: `${webhook}/book-reading-duration-list`,
    webhookURL_organization_dropdown: `${webhook}/organization-dropdown`,
    webhookURL_content_access_history: `${webhook}/content-access-history`,

    // tables_user_followers: `${tables}/user_followers`,
    // tables_notifications: `${tables}/notifications`,
    // tables_category_mccp: `${tables}/categories_mccp`,
    // tables_comments: `${tables}/comments`,
    // tables_feeds: `${tables}/feeds`,
    // tables_users: `${tables}/users`,
    // tablesURL_roles: `${tables}/roles`,
    // tablesURL_user_roles: `${tables}/user_roles`,
    // tablesURL_school_year_academics: `${tables}/school_year_academics`,
    // tablesURL_epustaka_mccp: `${tables}/epustaka_mccp`,
    // tablesURL_schools: `${tables}/schools`,
    // tablseURL_memberships_mccp: `${tables}/memberships_mccp`,
    // tablesURL_storage_file_metadata: `${tables}/storage_file_metadata`,
    // tablesURL_school_students_attendance_lists: `${tables}/school_students_attendance_lists`,
    // tablesURL_school_students_assignment_deliveries: `${tables}/school_students_assignment_deliveries`,
    // tablesURL_personal_events: `${tables}/personal_events`,
    // tablesURL_personal_event_participants: `${tables}/personal_event_participants`,
    // tablesURL_school_subject_materials: `${tables}/school_subject_materials`,
    // tablesURL_order_box_details: `${tables}/order_box_details`,
    // tablesURL_epustaka_collection_access_historis: `${tables}/epustaka_collection_access_histories`,

    //storageclient
    storageURL_upload_object: `uploadobject`,
    storageURL_remove_object: `removeobject`,
}
