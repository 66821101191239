import {
    Avatar,
    Button,
    Col,
    Rate,
    Row,
    Space,
    Modal,
    message,
    Input,
} from 'antd'
import translator from 'i18next'

import capitalize from 'lodash/capitalize'
import filter from 'lodash/filter'
import get from 'lodash/get'
import map from 'lodash/map'
import slice from 'lodash/slice'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
    MessageOutlined,
    ExclamationCircleOutlined,
    SendOutlined,
} from '@ant-design/icons'
import reduxState from '../../../../redux'
import provider from 'providers/provider'
import url from 'constants/url'
import RandomString from 'utils/randomString'
import getAcronym from 'components/Acronym'
const { confirm } = Modal

const ChildReview = ({
    data: dataComment,
    handleShowReplyInput,
    setCommentReply,
}) => {
    const data = filter(dataComment, (e) => !e.has_deleted && !e.has_report)
    const { profile = {} } = reduxState.getState()

    const [collapseDisplay, setCollapseDisplay] = useState(false)

    const [displayData, setDisplayData] = useState([])
    const fullyReview = () => {
        if (!collapseDisplay) {
            setDisplayData(slice(data, 0, 0))
        } else {
            setDisplayData(data)
        }
    }
    useEffect(() => {
        fullyReview()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataComment, collapseDisplay])

    const handleDelete = (id) => {
        let successDelete = filter(displayData, (e) => e.id !== id)
        confirm({
            title: 'Apakah Anda ingin menghapus ulasan ini?',
            icon: <ExclamationCircleOutlined />,
            onOk: async () => {
                await provider
                    .delete(`${url.webhookURL_book_rating}?rating_id=${id}`)
                    .then(() => {
                        setDisplayData(successDelete)
                        message.success('Ulasan terhapus.')
                    })
            },
            onCancel() {},
        })
    }
    return (
        <div style={{ marginTop: 20 }}>
            {map(displayData, (rep, key) => {
                const id = rep?.sender?.id
                const name = rep?.sender?.name || ''
                const { color, acronym } = getAcronym(name, id)
                return (
                    <Row className="r-container" key={key}>
                        <Col
                            span={2}
                            style={{
                                alignSelf: 'center',
                            }}
                        >
                            <Avatar
                                size={40}
                                style={{
                                    color: '#f56a00',
                                    backgroundImage: color,
                                }}
                                src={rep.sender.avatar_url}
                            >
                                {acronym}
                            </Avatar>
                        </Col>
                        <Col
                            span={10}
                            style={{
                                alignSelf: 'center',
                                paddingLeft: 13,
                            }}
                        >
                            <p className="comment-name">
                                {capitalize(rep.sender.name)}
                            </p>
                        </Col>
                        <Col
                            span={12}
                            style={{
                                textAlign: 'end',
                            }}
                        >
                            <p className="comment-date">
                                {moment(rep.updated_at).format('LL')} -{' '}
                                {moment(rep.updated_at).format('LT')} WIB
                            </p>
                        </Col>
                        <Col span={12}>{get(rep, 'rating_comment', '')}</Col>
                        {profile.id === rep.sender.id && (
                            <Col span={12} style={{ textAlign: 'end' }}>
                                <Space>
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            setCommentReply({
                                                text: get(
                                                    rep,
                                                    'rating_comment',
                                                    ''
                                                ),
                                                id: rep.id,
                                            })
                                            handleShowReplyInput(key)
                                        }}
                                    >
                                        Ubah
                                    </Button>{' '}
                                    <Button
                                        type="link"
                                        onClick={() => handleDelete(rep.id)}
                                    >
                                        Hapus
                                    </Button>
                                </Space>
                            </Col>
                        )}
                    </Row>
                )
            })}
            {data.length > displayData.length && (
                <b
                    className="collapse-review"
                    style={{ marginLeft: 50 }}
                    onClick={() => setCollapseDisplay(!collapseDisplay)}
                >
                    Lihat {data.length} Balasan
                </b>
            )}
            {collapseDisplay && (
                <b
                    className="collapse-review"
                    style={{ marginLeft: 50 }}
                    onClick={() => setCollapseDisplay(!collapseDisplay)}
                >
                    Lebih Sedikit
                </b>
            )}
        </div>
    )
}

const CommentReview = ({ data, bookId, setIsChangeReview }) => {
    const [collapseDisplay, setCollapseDisplay] = useState(false)
    const [commentReply, setCommentReply] = useState({
        text: '',
        id: '',
    })
    const [displayData, setDisplayData] = useState([])
    const [state, setState] = useState({
        loadings: [],
    })
    const fullyReview = () => {
        if (!collapseDisplay) {
            setDisplayData(
                slice(
                    filter(data, (e, key) => {
                        setState(({ loadings }) => {
                            const newLoadings = [...loadings]
                            newLoadings[key] = false

                            return {
                                loadings: newLoadings,
                            }
                        })
                        return !e.has_deleted && !e.has_report
                    }),
                    0,
                    1
                )
            )
        } else {
            setDisplayData(
                filter(data, (e, key) => {
                    setState(({ loadings }) => {
                        const newLoadings = [...loadings]
                        newLoadings[key] = false

                        return {
                            loadings: newLoadings,
                        }
                    })
                    return !e.has_deleted && !e.has_report
                })
            )
        }
    }
    useEffect(() => {
        fullyReview()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapseDisplay, data])

    const handleReply = async (e, comment, key) => {
        const dataSave = {
            book_id: bookId,
            rating_comment: e,
            rating_level: comment.rating_level,
            parent_id: comment.id,
        }

        if (commentReply.id !== '') {
            await provider
                .put(url.webhookURL_book_rating, {
                    rating_id: commentReply.id,
                    ...dataSave,
                })
                .then(() => {
                    setCommentReply({
                        text: '',
                        id: '',
                    })
                    setIsChangeReview(RandomString(3))
                    setState(({ loadings }) => {
                        const newLoadings = [...loadings]
                        newLoadings[key] = false

                        return {
                            loadings: newLoadings,
                        }
                    })
                })
        } else {
            await provider
                .insert(url.webhookURL_book_rating, dataSave)
                .then(() => {
                    setIsChangeReview(RandomString(3))

                    setState(({ loadings }) => {
                        const newLoadings = [...loadings]
                        newLoadings[key] = false

                        return {
                            loadings: newLoadings,
                        }
                    })
                })
        }
    }

    const handleShowReplyInput = (index) => {
        setState(({ loadings }) => {
            const newLoadings = [...loadings]
            newLoadings[index] = true

            return {
                loadings: newLoadings,
            }
        })
    }
    return (
        <div>
            {map(displayData, (comment, key) => {
                const { id, name } = comment
                const { color, acronym } = getAcronym(name || '', id)

                return (
                    <div key={key}>
                        <Row className="cm-container">
                            <Col
                                span={2}
                                style={{
                                    alignSelf: 'center',
                                }}
                            >
                                <Avatar
                                    size={45}
                                    style={{
                                        color: '#f56a00',
                                        backgroundImage: color,
                                    }}
                                    src={comment.sender.avatar_url}
                                >
                                    {acronym}
                                </Avatar>
                            </Col>
                            <Col
                                span={10}
                                style={{
                                    alignSelf: 'center',
                                    paddingLeft: 13,
                                }}
                            >
                                <p className="comment-name">
                                    {capitalize(comment.sender.name)}
                                </p>
                                <Rate
                                    disabled
                                    defaultValue={get(
                                        comment,
                                        'rating_level',
                                        0
                                    )}
                                    value={get(comment, 'rating_level', 0)}
                                    className="star"
                                    allowHalf
                                />
                            </Col>
                            <Col span={12} style={{ textAlign: 'end' }}>
                                <p className="comment-date">
                                    {moment(comment.updated_at).format('LL')} -{' '}
                                    {moment(comment.updated_at).format('LT')}{' '}
                                    WIB
                                </p>
                            </Col>
                            <Col span={12}>
                                {get(comment, 'rating_comment', '')}
                            </Col>
                            <Col
                                span={12}
                                style={{
                                    textAlign: 'end',
                                }}
                            >
                                <Button
                                    onClick={() => handleShowReplyInput(key)}
                                >
                                    <MessageOutlined />
                                </Button>
                            </Col>
                            {state.loadings[key] && (
                                <Col span={24} style={{ marginTop: 25 }}>
                                    <Input.Search
                                        placeholder={`${translator.t(
                                            'feed.addComment'
                                        )}`}
                                        defaultValue={commentReply.text}
                                        bordered={false}
                                        enterButton={<SendOutlined />}
                                        onSearch={(e) =>
                                            handleReply(e, comment, key)
                                        }
                                    />
                                </Col>
                            )}
                        </Row>
                        {comment.reply && (
                            <ChildReview
                                data={comment.reply}
                                handleShowReplyInput={handleShowReplyInput}
                                setCommentReply={setCommentReply}
                            />
                        )}
                    </div>
                )
            })}
            {data.length > displayData.length && (
                <b
                    className="collapse-review"
                    onClick={() => setCollapseDisplay(!collapseDisplay)}
                >
                    Selengkapnya
                </b>
            )}
            {collapseDisplay && (
                <b
                    className="collapse-review"
                    onClick={() => setCollapseDisplay(!collapseDisplay)}
                >
                    Lebih Sedikit
                </b>
            )}
        </div>
    )
}

export default CommentReview
