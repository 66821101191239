import React, { useState, useLayoutEffect } from 'react'
import Modal from 'antd/lib/modal/Modal'
import translator from 'i18next'
import { Button, Card, Empty, Spin, List } from 'antd'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import ModalSuggestFriends from 'views/home/beranda/suggestFriends/ModalSuggestFriends'
import Text from 'antd/lib/typography/Text'
import Avatar from 'antd/lib/avatar/avatar'
import { useHistory } from 'react-router'
import reduxState from '../../../redux'
import { ModalConfirmFollowUnfollow } from 'components'
import getAcronym from 'components/Acronym'
import { COLORS } from 'constants/constant'

export default function ModalFollowerFollowing({
    visibleModal,
    setVisibleModal,
    dataFollower,
    getFollower,
    getFollowing,
    userProfil,
}) {
    const history = useHistory()
    const [suggestFriend, setSuggestFriend] = useState(false)
    const [visibleConfirmFollowUnfollow, setVisibleConfirmFollowUnfollow] =
        useState(false)

    const [item, setItem] = useState({})
    const { profile = {} } = reduxState.getState()
    const profileId = get(profile, 'id', '')

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 420

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    return (
        <>
            <Modal
                visible={visibleModal}
                onOk={() => setVisibleModal(!visibleModal)}
                onCancel={() => setVisibleModal(!visibleModal)}
                footer={null}
                className="modal-follow"
                title={`${translator.t('filter.listFollowers')}`}
                bodyStyle={{ padding: 0 }}
                width={600}
            >
                <Card bordered={false} style={{ border: 'none' }}>
                    {isUndefined(dataFollower) ? (
                        <Spin />
                    ) : isEmpty(dataFollower) ? (
                        <div>
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                    <span
                                        style={{
                                            color: COLORS.primaryColor,
                                        }}
                                    >
                                        {translator.t(
                                            'friendship.firstNotifFollower'
                                        )}
                                        <p
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setSuggestFriend(true)
                                            }}
                                        >
                                            {translator.t(
                                                'friendship.secondNotifFollower'
                                            )}
                                        </p>
                                        {translator.t(
                                            'friendship.thirdNotifFollow'
                                        )}
                                    </span>
                                }
                            />
                        </div>
                    ) : (
                        <>
                            <List
                                style={{ maxHeight: '55vh', overflow: 'auto' }}
                                itemLayout="horizontal"
                                dataSource={dataFollower}
                                renderItem={(item) => {
                                    const { name, avatar_url, id } = item
                                    const { color, acronym } = getAcronym(
                                        name || '',
                                        id
                                    )
                                    return (
                                        <List.Item
                                            actions={[
                                                <Button
                                                    style={{
                                                        width: isMobile()
                                                            ? 90
                                                            : 130,
                                                        padding: 0,
                                                        display:
                                                            profileId === id &&
                                                            'none',
                                                    }}
                                                    type={
                                                        !item.followed &&
                                                        'primary'
                                                    }
                                                    className={
                                                        item.followed
                                                            ? 'btn-following'
                                                            : 'btn-follow'
                                                    }
                                                    onClick={(e) => {
                                                        e?.currentTarget?.blur()
                                                        setVisibleConfirmFollowUnfollow(
                                                            true
                                                        )
                                                        setItem(item)
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            fontSize:
                                                                isMobile() &&
                                                                11,
                                                        }}
                                                    >
                                                        {`${translator.t(
                                                            item.followed
                                                                ? 'action.cancel_follow'
                                                                : 'action.follow'
                                                        )}`}
                                                    </span>
                                                </Button>,
                                            ]}
                                        >
                                            <List.Item.Meta
                                                avatar={
                                                    <Avatar
                                                        size="large"
                                                        style={{
                                                            cursor: 'pointer',
                                                            backgroundImage:
                                                                color,
                                                            display:
                                                                'inline-flex',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                        src={avatar_url}
                                                        onClick={() => {
                                                            if (userProfil) {
                                                                window.location.replace(
                                                                    `/profil/user/${id}`
                                                                )
                                                            }
                                                            history.push(
                                                                `/profil/user/${id}`
                                                            )
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: 13,
                                                            }}
                                                        >
                                                            {acronym}
                                                        </span>
                                                    </Avatar>
                                                }
                                                title={
                                                    <Text
                                                        style={{
                                                            width:
                                                                isMobile() &&
                                                                110,
                                                        }}
                                                        ellipsis={{
                                                            tooltip: item?.name,
                                                        }}
                                                    >
                                                        {item?.name === null
                                                            ? 'unknown'
                                                            : item?.name}
                                                    </Text>
                                                }
                                            />
                                        </List.Item>
                                    )
                                }}
                            >
                                {/* <div style={{ textAlign: 'center' }}>
                        <DispalyButton />
                    </div> */}
                            </List>
                        </>
                    )}
                </Card>
            </Modal>

            <ModalConfirmFollowUnfollow
                visibleModal={visibleConfirmFollowUnfollow}
                setVisibleModal={setVisibleConfirmFollowUnfollow}
                item={item}
                getFollower={getFollower}
                getFollowing={getFollowing}
                source="profil"
            />

            <ModalSuggestFriends
                visibleModal={suggestFriend}
                setVisibleModal={setSuggestFriend}
            />
        </>
    )
}
