import React, { lazy, Suspense, useEffect } from 'react'
import { Loading } from 'components'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Layout from 'layouts/Layout'
import routes from 'router/router'
// import { Button, Result } from 'antd'
import { COLORS, colorPallet } from 'constants/constant'

const Login = lazy(() => import('views/auth/Login'))
const Register = lazy(() => import('views/auth/Register'))
const NotFound = lazy(() => import('views/NotFound'))
const ForgotPassword = lazy(() => import('views/auth/ForgotPassword'))
const EmailRedirect = lazy(() => import('views/auth/EmailRedirect'))
const ResetPassword = lazy(() => import('views/auth/ResetPassword'))
const AuthSocmed = lazy(() => import('views/auth/authSocmed'))
const LinkSocmed = lazy(() => import('views/auth/LinkSocmed'))
const NotActivated = lazy(() => import('views/auth/activate/NotActived'))

const App = () => {
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            return (
                <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                    exact
                />
            )
        })
    }

    const ProtectedLayout = () => {
        return (
            <Layout>
                <Switch>
                    {getRoutes(routes)}
                    <Route component={NotFound} path="*" />
                </Switch>
            </Layout>
        )
    }

    const FrontAndLayoutContainer = () => {
        return (
            <Switch>
                <Route component={Login} exact path="/login" />
                <Route component={Register} exact path="/register" />
                <Route
                    component={ForgotPassword}
                    exact
                    path="/forgot-password"
                />
                <Route
                    component={ResetPassword}
                    exact
                    path="/auth/reset-password"
                />
                <Route component={EmailRedirect} exact path="/email-verified" />
                <Route component={NotActivated} exact path="/not-activated" />
                <Route component={AuthSocmed} exact path="/auth-socmed" />
                <Route component={LinkSocmed} exact path="/link-socmed" />
                <Route component={ProtectedLayout} path="/" />
            </Switch>
        )
    }

    useEffect(() => {
        const root = document.documentElement
        root?.style.setProperty('--primary-color', COLORS.primaryColor)
        root?.style.setProperty('--secondary-color', COLORS.secondaryColor)
        root?.style.setProperty('--base-gradient', colorPallet[7])
    }, [])

    return (
        <BrowserRouter>
            <Suspense fallback={<Loading />}>
                <Route component={FrontAndLayoutContainer} path="/" />
                <Route path="/" />
            </Suspense>
            {/* {navigator.onLine === false ? (
                <Result
                    status="500"
                    title="500"
                    subTitle="Sorry, something went wrong."
                    extra={<Button type="primary">Back Home</Button>}
                />
            ) : (
                <Suspense fallback={<Loading />}>
                    <Route component={FrontAndLayoutContainer} path="/" />
                    <Route path="/" />
                </Suspense>
            )} */}
        </BrowserRouter>
    )
}

export default App
