import React, { useEffect, useState, useLayoutEffect } from 'react'
import translator from 'i18next'
import {
    Modal,
    Row,
    Col,
    Rate,
    Form,
    Input,
    Button,
    message,
    Tooltip,
} from 'antd'
import { FallbackImage } from 'components'
import get from 'lodash/get'
import isUndefined from 'lodash/isUndefined'
import toInteger from 'lodash/toInteger'
import provider from 'providers/provider'
import url from 'constants/url'
import { COLORS } from 'constants/constant'
import { error } from 'utils/notification'
import Paragraph from 'antd/lib/typography/Paragraph'

export default function ModalRecommendation({
    visibleModal,
    setVisibleModal,
    bookDetailData,
    getBookDetail,
    hasReview,
    getReviewData,
}) {
    const [form] = Form.useForm()
    const [rating, setRating] = useState(0)
    const [loading, setLoading] = useState(false)
    const [hasRated, setHasRated] = useState(false)

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 420
    // const isMobilePad = () => state.width <= 1024

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    useEffect(() => {
        if (
            get(bookDetailData?.user_rating, 'rating_level', '') &&
            get(bookDetailData?.user_rating, 'rating_comment', '')
        ) {
            form.setFieldsValue({
                level: get(bookDetailData?.user_rating, 'rating_level', ''),
                review: get(bookDetailData?.user_rating, 'rating_comment', ''),
            })
            setRating(bookDetailData?.user_rating?.rating_level)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookDetailData?.user_rating])

    useEffect(() => {
        if (hasReview) {
            setHasRated(true)
        }
    }, [hasReview])

    const handleReview = async (e) => {
        setLoading(true)
        const data = {
            book_id: bookDetailData.id,
            rating_level: toInteger(rating),
            rating_comment: e.review,
        }
        if (!hasReview) {
            await provider
                .insert(url.webhookURL_book_rating, data, 'webhook')
                .then(() => {
                    getBookDetail()
                    setVisibleModal()
                    getReviewData()
                    message.success(translator.t('message.successReview'))
                    setLoading(false)
                })
                .catch((err) => {
                    error({
                        description: err.message,
                        placement: 'bottomRight',
                    })
                })
        } else {
            await provider
                .put(url.webhookURL_book_rating, {
                    rating_id: bookDetailData?.user_rating?.id,
                    rating_level: toInteger(rating),
                    rating_comment: e.review,
                })
                .then(() => {
                    getBookDetail()
                    setVisibleModal()
                    getReviewData()
                    message.success(translator.t('message.successReview'))
                    setLoading(false)
                    form.resetFields()
                })
        }
    }

    return (
        <Modal
            // centered
            visible={visibleModal}
            onOk={() => setVisibleModal(!visibleModal)}
            onCancel={() => {
                setVisibleModal(!visibleModal)
                form.resetFields()
            }}
            footer={null}
            className="modal-popup"
            width={isMobile() ? '100%' : 600}
        >
            <Form form={form} onFinish={handleReview}>
                <Row justify="center">
                    <Col
                        span={24}
                        style={{
                            textAlign: 'center',
                            marginTop: !isMobile() && '-1em',
                        }}
                    >
                        <h2 className="epustaka-name">
                            {translator.t('action.giveReview')}
                        </h2>
                    </Col>
                </Row>
                <Row className="mt-md detail-book-modal">
                    <Col lg={8} md={9} sm={11} xs={11}>
                        <div className="cover-image">
                            <FallbackImage
                                src={get(bookDetailData, 'cover_url', '')}
                                className="cover-book"
                            />
                        </div>
                    </Col>
                    <Col
                        lg={16}
                        md={15}
                        sm={13}
                        xs={13}
                        className="description-book"
                    >
                        <h3 className="book-title">
                            <Paragraph
                                ellipsis={
                                    true
                                        ? {
                                              rows: 2,
                                          }
                                        : false
                                }
                            >
                                <Tooltip
                                    title={get(
                                        bookDetailData,
                                        'book_title',
                                        '-'
                                    )}
                                >
                                    {get(
                                        bookDetailData,
                                        'book_title',
                                        'Lorem ipsum, dolor sit amet consectetur adipisicing elit.'
                                    )}
                                </Tooltip>
                            </Paragraph>
                        </h3>
                        <p
                            className="book-author"
                            style={{ marginTop: '-5px' }}
                        >
                            <Paragraph
                                ellipsis={
                                    true
                                        ? {
                                              rows: 2,
                                          }
                                        : false
                                }
                            >
                                <Tooltip
                                    title={get(
                                        bookDetailData,
                                        'book_author',
                                        'Voluptas atque iusto distinctio odit'
                                    )}
                                >
                                    {get(
                                        bookDetailData,
                                        'book_author',
                                        'Voluptas atque iusto distinctio odit'
                                    )}
                                </Tooltip>
                            </Paragraph>
                        </p>
                        <Form.Item
                            name="level"
                            style={{ marginTop: '-9px' }}
                            form={form}
                        >
                            <Rate
                                className="star"
                                allowHalf
                                value={rating}
                                onChange={(e) => setRating(e)}
                            />
                        </Form.Item>
                        <p
                            style={{
                                marginTop: 10,
                                color: COLORS.primaryColor,
                                fontWeight: 'bold',
                            }}
                        >
                            {translator.t('action.addReview')}
                        </p>
                    </Col>
                </Row>

                <Form.Item
                    name="review"
                    rules={[
                        {
                            required: true,
                            message: translator.t('message.writeReview'),
                        },
                    ]}
                >
                    <Input
                        placeholder={translator.t('action.writeReview')}
                        bordered={false}
                        style={{ marginTop: 25, marginBottom: 25 }}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        disabled={!hasRated && rating === 0}
                        // disabled={rating === 0 ? true : false}
                        type="primary"
                        style={{ width: '100%' }}
                        loading={loading}
                        htmlType="submit"
                    >
                        {translator.t(
                            isUndefined(hasReview)
                                ? 'action.save'
                                : 'action.edit'
                        )}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}
