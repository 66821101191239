import { Col, Row } from 'antd'
import translator from 'i18next'
import React from 'react'
import { useHistory } from 'react-router'

const NavigationHome = ({ title, detailUrl, fromBookDetail }) => {
    const { url, category_name, category_id } = detailUrl

    const history = useHistory()
    return (
        <Row className="nh-container">
            <Col>
                <h1 className="title">{title}</h1>
            </Col>
            <Col>
                {!fromBookDetail && (
                    <button
                        className={`link link-${title
                            ?.replace(' ', '')
                            .toLowerCase()}`}
                        onClick={() =>
                            history.push({
                                pathname: url,
                                state: { category_name, category_id },
                            })
                        }
                    >
                        {translator.t('action.fully')}
                    </button>
                )}
            </Col>
        </Row>
    )
}

export default NavigationHome
