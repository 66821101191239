import React, { useEffect, useState } from 'react'
import { LAYOUT, BASE_TITLE } from 'constants/constant'
import { Typography, Input, Row, Col, Spin, Dropdown, Menu, Button } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { FallbackImage } from 'components'
import translator from 'i18next'
import { isEmpty, filter } from 'lodash'
import { error } from 'utils/notification'
import url from 'constants/url'
import provider from 'providers/provider'
import { useHistory } from 'react-router-dom'
import ModalRecommendation from 'views/home/books/bookDetail/ModalRecommendation'
import { ModalShare } from 'components'
import reduxState from '../../../redux'

const { Text, Paragraph } = Typography
const { Search } = Input
const MoreBookDonation = () => {
    const history = useHistory()
    const { profile = {} } = reduxState.getState()
    const organization_id = profile?.organizations?.id

    const [donationShelf, setDonationShelf] = useState({
        data: [],
        loading: false,
        total: 0,
    })
    const [itemBook, setItemBook] = useState({})
    const [bookShare, setBookShare] = useState([])
    const [querySearch, setQuerySearch] = useState('')

    const [visibleModalRecommendation, setVisibleModalRecommendation] =
        useState(false)
    const [popShare, setPopShare] = useState(false)
    const [limitBook, setLimitBook] = useState(20)
    const [limitFollower, setLimitFollower] = useState(15)
    const [totalFollower, setTotalFollower] = useState(0)
    const [querySearchFollower, setQuerySearchFollower] = useState('')
    const [userFollower, setUserFollower] = useState({})
    const [loadingFollowe, setLoadingFollower] = useState(false)
    const [hasClick, setHasClick] = useState(false)

    const getDonationShelf = async () => {
        setDonationShelf({ ...donationShelf, loading: true })
        const filterQuery = isEmpty(querySearch)
            ? ''
            : {
                  'b.catalog_title': { ilike: `%${querySearch}%` },
              }
        await provider
            .list(
                encodeURI(
                    url.webhookUrl_self_donation_list +
                        `?filter=${JSON.stringify(
                            filterQuery
                        )}&limit=${limitBook}&`
                )
            )
            .then((res) => {
                const { data, meta } = res
                setDonationShelf({
                    data: data,
                    loading: false,
                    total: meta.total,
                })
            })
            .catch((err) => {
                setDonationShelf({ ...donationShelf, loading: false })
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    const getDataFollower = async () => {
        setLoadingFollower(true)
        const filterQuery = isEmpty(querySearchFollower)
            ? ''
            : {
                  'users.name': { ilike: `%${querySearchFollower}%` },
              }
        await provider
            .list(
                encodeURI(
                    `${
                        url.webhookURL_follower
                    }?limit=${limitFollower}&filter=${JSON.stringify(
                        filterQuery
                    )}&q=&user_id=${profile.id}`
                )
            )
            .then((res) => {
                const { data, meta } = res
                const filterData = filter(data, (e) => e.id !== profile.id)
                setUserFollower(filterData)
                setTotalFollower(meta.total)
                setLoadingFollower(false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    useEffect(() => {
        getDonationShelf()
    }, [querySearch, limitBook])

    useEffect(() => {
        getDataFollower()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [querySearchFollower, limitFollower])

    const onLoadMoreFollower = () => {
        if (totalFollower > limitFollower) {
            setLimitFollower((e) => e + 15)
        }
    }

    const onLoadMoreBook = () => {
        if (donationShelf.total > limitBook) {
            return (
                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <Button
                        loading={donationShelf.loading}
                        onClick={() => {
                            donationShelf.total > limitBook &&
                                setLimitBook((e) => e + 20)
                            setHasClick(true)
                        }}
                    >
                        {translator.t('action.more')}
                    </Button>
                </div>
            )
        } else {
            return <></>
        }
    }

    const handleClickShare = () => {
        setPopShare(true)
    }

    const handleClickRecommend = () => {
        setVisibleModalRecommendation(true)
    }

    const menuAction = (item) => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClickShare(setBookShare(item))}
                    >
                        {translator.t('title.share')}
                    </div>
                </Menu.Item>
                <Menu.Item key="1">
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClickRecommend(setItemBook(item))}
                    >
                        {translator.t('action.recommendation')}
                    </div>
                </Menu.Item>
            </Menu>
        )
    }

    return (
        <div
            style={{
                maxWidth: LAYOUT.maxWidth,
                margin: '0 auto',
            }}
        >
            <div className="search-book-donation-shelf">
                <Row align="center" className="bookshelf-search">
                    <Col xs={4} sm={4} md={12} lg={12}>
                        <h3>{translator.t('menu.book')}</h3>
                    </Col>
                    <Col xs={20} sm={20} md={12} lg={12}>
                        <Search
                            className="bookshelf-donation-search"
                            placeholder={translator.t('action.input_keyword')}
                            // loading={donationShelf.loading}
                            enterButton
                            onSearch={(e) => setQuerySearch(e)}
                            allowClear
                        />
                    </Col>
                </Row>
            </div>

            <div style={{ marginTop: 80 }}>
                {donationShelf.loading && !hasClick ? (
                    <div
                        style={{
                            minHeight: 400,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Spin />
                    </div>
                ) : (
                    donationShelf.data?.map((item, key) => {
                        return (
                            <div
                                key={key}
                                className="more-book-donation-wrapper"
                            >
                                <div className="container-book">
                                    <div className="book">
                                        <div className="front">
                                            <div className="cover">
                                                <FallbackImage
                                                    alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                                    src={item?.cover_url}
                                                    onClick={() =>
                                                        history.push({
                                                            pathname: `/book/${
                                                                item?.id
                                                            }/${
                                                                item?.epustaka
                                                                    ?.id || ''
                                                            }`,
                                                            state: {
                                                                epustaka_id:
                                                                    item
                                                                        ?.epustaka
                                                                        ?.id,
                                                            },
                                                        })
                                                    }
                                                    style={{
                                                        cursor: 'pointer',
                                                        width: 135,
                                                        height: 185,
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="left-side">
                                            <h2
                                                style={{
                                                    height: 20,
                                                    width: 185,
                                                    marginLeft: -15,
                                                    backgroundImage: `url(${item?.cover_url})`,
                                                }}
                                            >
                                                <span>
                                                    <Text
                                                        style={{
                                                            width: 155,
                                                            fontSize: 10,
                                                            color: 'white',
                                                            paddingRight: 10,
                                                        }}
                                                        ellipsis={true}
                                                    >
                                                        {item?.author_name}
                                                    </Text>
                                                </span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="more-boo-donation-info"
                                    onClick={() =>
                                        history.push({
                                            pathname: `/book/${item?.id}/${
                                                item?.epustaka?.id || ''
                                            }`,
                                            state: {
                                                epustaka_id: item?.epustaka?.id,
                                            },
                                        })
                                    }
                                >
                                    <h3>
                                        <Paragraph
                                            ellipsis={
                                                true
                                                    ? {
                                                          rows: 2,
                                                          expandable: false,
                                                      }
                                                    : false
                                            }
                                        >
                                            {item?.book_title}
                                        </Paragraph>
                                    </h3>
                                    <div className="total-copy">
                                        <h4>{item?.author_name}</h4>
                                        <div className="total-copy-desc">
                                            <span>{`Total ${
                                                item?.all_qty || 0
                                            } Copy - ${translator.t(
                                                'title.available'
                                            )} ${
                                                item?.available_qty || 0
                                            } Copy - `}</span>

                                            <span>
                                                {item?.queue_count <= 0 ? (
                                                    translator.t(
                                                        'title.no_queue'
                                                    )
                                                ) : (
                                                    <span>
                                                        {translator.t(
                                                            'title.there_is'
                                                        )}{' '}
                                                        {item?.queue_count}{' '}
                                                        {translator.t(
                                                            'title.queue'
                                                        )}
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="more-action"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Dropdown
                                        overlay={menuAction(item)}
                                        trigger={['click']}
                                        placement="bottomRight"
                                    >
                                        <MoreOutlined />
                                    </Dropdown>
                                </div>
                            </div>
                        )
                    })
                )}
                {onLoadMoreBook()}
            </div>

            <ModalRecommendation
                visibleModal={visibleModalRecommendation}
                setVisibleModal={setVisibleModalRecommendation}
                bookData={itemBook}
                data={userFollower}
                userId={profile.id}
                organization_id={organization_id}
                onLoadMore={onLoadMoreFollower}
                getDataFollower={getDataFollower}
                setQuerySearchFollower={setQuerySearchFollower}
                source="bookshelfdonation"
                loadingFollowe={loadingFollowe}
                totalFollower={totalFollower}
                limitFollower={limitFollower}
            />

            <ModalShare
                data={bookShare}
                urlFrom="book"
                visibleModal={popShare}
                setVisibleModal={setPopShare}
            />
        </div>
    )
}

export default MoreBookDonation
