import { COLORS } from 'constants/constant'
import React from 'react'

const BookmarkIcon = (props) => {
    const { height = '16px', width = '13px', fill = 'true' } = props
    return (
        <svg
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.741 0C.784 0 0 .784 0 1.741v13.932l6.095-2.612 6.095 2.612V1.741C12.19.784 11.407 0 10.45 0H1.741Z"
                fill={fill === 'true' ? COLORS.primaryColor : '#EEEEEE'}
                stroke={fill === 'true' ? 'none' : '#000'}
                fillRule="evenodd"
            />
        </svg>
    )
}

export default BookmarkIcon
