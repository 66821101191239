import { message } from 'antd'
import axios from 'axios'
import blobToBuffer from 'blob-to-buffer'
import { API_STORAGE_URL } from 'helpers/constants'

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
}

const getUrlFromUrlBook = async (payload, setFile) => {
    const fetchRetry = async (url, payload, retries = 20) => {
        return await axios({
            method: 'post',
            url: url,
            data: payload,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                if (res) return res
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    if (retries > 0) {
                        return fetchRetry(url, options, retries - 1)
                    } else {
                        throw new Error(error)
                    }
                }
            })
    }

    await fetchRetry(`${API_STORAGE_URL}/streamobject`, payload, 30).then(
        async (response) => {
            let responseData = new Uint8Array(await response.data.arrayBuffer())
            const wasmFile = window.readResponse(
                payload.userid,
                payload.bookId,
                payload.epustakaid,
                payload.borrowkey,
                responseData
            )
            let decriptedFile = new Blob([wasmFile], { type: 'text/plain' })

            blobToBuffer(decriptedFile, async function (err, buffer) {
                if (err) message.error('toBuffer failed' + err)
                else {
                    const decrypted = Buffer.from(buffer)
                    const file = new Blob([decrypted], { type: 'text/plain' })
                    await new Response(file).arrayBuffer().then((res) => {
                        const file = new Uint8Array(res)
                        setFile(file)
                    })
                }
            })
        }
    )
}

export default getUrlFromUrlBook
