import { createStore, combineReducers } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import reducers from './reducers'
import getEnv from 'utils/getEnv.js'

const isDebugging = getEnv('REACT_APP_DEBUG', false)

const store = isDebugging
    ? createStore(combineReducers({ ...reducers }), composeWithDevTools())
    : createStore(combineReducers({ ...reducers }))

export default store
