import React from 'react'
import { Row, Col } from 'antd'
import HeaderBookself from './components/HeaderBookself'
import { LAYOUT } from 'constants/constant'

export default function BookSelf() {
    return (
        <Row
            type="flex"
            style={{
                alignItems: 'center',
                margin: '0px auto',
                maxWidth: LAYOUT.maxWidth,
            }}
            justify="center"
        >
            <Col
                xs={24}
                sm={19}
                md={20}
                lg={24}
                xl={24}
                className="bs__container"
                style={{ marginBottom: 30 }}
            >
                <HeaderBookself />
            </Col>
        </Row>
    )
}
