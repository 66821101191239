import {
    Col,
    Row,
    Select,
    Space,
    Tooltip,
    Image,
    Typography,
    Collapse,
} from 'antd'
import React, { useState, useLayoutEffect, useEffect } from 'react'
import translator from 'i18next'
import { ic_filter } from 'assets/icons'
import ModalFIlterSorting from './ModalFIlterSorting'
import FilterBookDropdown from './FilterBookDropdown'
import { DownOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
// import { gql, useQuery } from '@apollo/client'
// import CATEGORY_QUERY from 'views/home/books/queries/queryAllCategoriesMccpList'
import provider from 'providers/provider'
import url from 'constants/url'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import toLower from 'lodash/toLower'
import getPagination from 'constants/getPagination'
import { error } from 'utils/notification'
import getPaginationGraphQL from 'utils/getPaginationGraphQL'
import dataCategoryBookFilterAction from 'redux/dataCategoryBookFilter/action'
import bookFilterOptionsAction from 'redux/bookFilterOptions/action'
// import reduxState from '../../../../redux'

const { Text } = Typography
const { Option } = Select
const { Panel } = Collapse

const mapStateToProps = (state) => ({
    switchActive: state.switchActive,
    bookFilterOptions: state.bookFilterOptions,
    dataCategoryBookFilter: state.dataCategoryBookFilter,
})

let arrayYear = []

for (let index = new Date().getFullYear(); index >= 1950; index--) {
    arrayYear.push(index)
}

const FilterSorterBookCategory = (props) => {
    const {
        categoryName,
        handleFlter,
        visibleModal,
        setVisibleModal,
        totalData = null,
        reseltFilter,
        form,
        hasFilter,
        handleSorting,
        fromHome,
        dataCategoryBookFilter,
        bookFilterOptions,
        switchActive,
    } = props

    const isMobile = () => state.width <= 370
    const isMiddle = () => state.width <= 470
    const isTablet = () => state.width <= 576

    const limit = 20
    const [currentPageAuthor, setCurrentPageAuthor] = useState(1)
    const [loadingAuthor, setLoadingAuthor] = useState(false)
    const [hasMoreAuthor, setHasMoreAuthor] = useState(true)

    // const { profile = {} } = reduxState.getState()
    // const organization_id = profile?.organizations?.id

    const [hasSearch, setHasSearch] = useState(true)

    const [dataOptionAuthor, setDataOptionAuthor] = useState([])
    const [dataOptionYear, setDataOptionYear] = useState(arrayYear)
    const [dataCategories, setDataCategories] = useState({
        list: dataCategoryBookFilter,
        total: 0,
        loading: true,
    })

    const [search, setSearch] = useState('')

    const [state, setState] = useState({
        collapsed: false,
        width: window.innerWidth,
    })

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getAuthorOption = async () => {
        setLoadingAuthor(true)
        const pagination = getPagination(currentPageAuthor, limit, 'webhook')

        await provider
            .list(url.webhookURL_book_author_list + pagination.result)
            .then((res) => {
                const { data: authorResData, meta: authorResMeta } = res

                if (pagination.offset === authorResMeta.total) {
                    setHasMoreAuthor(false)
                }
                const dataFilter = filter(
                    authorResData,
                    (e) => !isEmpty(e.name)
                )
                const resultData = concat(
                    dataOptionAuthor,
                    map(dataFilter, (item) => ({
                        text: item.name,
                    }))
                )
                setDataOptionAuthor(resultData)
                bookFilterOptionsAction.setFilterOptions({
                    ...bookFilterOptions,
                    author: resultData,
                })
                setLoadingAuthor(false)
            })
    }

    useEffect(() => {
        getAuthorOption()
        return () => {
            setDataOptionAuthor([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPageAuthor])

    // const defaultWhere = {
    //     where: {
    //         catalogs_mccp_category_id_list: {
    //             epustaka_catalogs_mccp_catalog_id_list: {
    //                 epustaka_id_data: {
    //                     epustaka_deleted: { _is_null: true },
    //                     epustaka_isactive: { _eq: 1 },
    //                     organization_id: {
    //                         _eq: organization_id,
    //                     },
    //                 },
    //                 catalog_id_data: { deleted_at: { _is_null: true } },
    //             },
    //             deleted_at: { _is_null: true },
    //         },
    //         ...(!isEmpty(search) && {
    //             category_name: {
    //                 _ilike: `%${search}%`,
    //             },
    //         }),
    //     },
    // }

    // const resultCategoriesList = (res) => {
    //     const { data, count } = res?.allCategoriesMccpList
    //     dataCategoryBookFilterAction.setDataCategoryBookFilter(
    //         concat(dataCategories?.list, data)
    //     )

    //     const dataFilter = {
    //         list: search
    //             ? data
    //             : categoryPagination?.offset !== 0
    //             ? concat(dataCategories?.list, data)
    //             : data,
    //         total: count,
    //         loading: false,
    //     }

    //     setDataCategories(dataFilter)
    //     bookFilterOptionsAction.setFilterOptions({
    //         ...bookFilterOptions,
    //         category: dataFilter,
    //     })
    // }

    const [categoryPagination, setCategoryPagination] = useState({
        currentPage: 0,
        offset: 0,
        loadMore: true,
    })
    const [loadingCategory, setLoadingCategory] = useState(false)
    const [totalCategory, setTotalCategory] = useState(0)

    const getBookCategory = async () => {
        setLoadingCategory(true)
        await provider
            .list(
                url.webhookURL_book_category_list +
                    `?limit=20&offset=${categoryPagination?.offset}&q=${
                        search || ''
                    }`
            )
            .then((res) => {
                const { data, meta } = res
                setTotalCategory(meta?.total)
                setCategoryPagination({
                    ...categoryPagination,
                    currentPage: categoryPagination?.currentPage + 1,
                })

                dataCategoryBookFilterAction.setDataCategoryBookFilter(
                    concat(dataCategories?.list, data)
                )

                const dataFilter = {
                    list: search
                        ? data
                        : categoryPagination?.offset !== 0
                        ? concat(dataCategories?.list, data)
                        : data,
                    total: meta?.total,
                    loading: false,
                }

                setDataCategories(dataFilter)
                bookFilterOptionsAction.setFilterOptions({
                    ...bookFilterOptions,
                    category: dataFilter,
                })
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
        setLoadingCategory(false)
    }

    // const { data, loading, refetch } = useQuery(
    //     gql`
    //         ${CATEGORY_QUERY}
    //     `,
    //     {
    //         variables: {
    //             limit: 20,
    //             offset: categoryPagination?.offset,
    //             ...defaultWhere,
    //         },
    //         skip: !isEmpty(bookFilterOptions?.category),
    //         onCompleted: (data) => {
    //             resultCategoriesList(data)
    //             setCategoryPagination({
    //                 ...categoryPagination,
    //                 currentPage: categoryPagination?.currentPage + 1,
    //             })
    //         },
    //         onError: (err) =>
    //             error({
    //                 description: err.message,
    //                 placement: 'bottomRight',
    //             }),
    //     }
    // )

    // TODO implement searching
    // useEffect(() => {
    //     refetch().then((res) => {
    //         resultCategoriesList(res?.data)
    //     })
    // }, [search])

    useEffect(() => {
        if (categoryPagination?.loadMore) {
            getBookCategory()
        }
    }, [categoryPagination?.offset, search])

    const loadMoreAuthor = () => {
        setLoadingAuthor(true)

        setCurrentPageAuthor(currentPageAuthor + 1)
    }

    const loadMoreCategory = () => {
        const offset = getPaginationGraphQL(
            categoryPagination?.currentPage + 1,
            20,
            totalCategory
        )
        setCategoryPagination({
            ...offset,
            currentPage: categoryPagination?.currentPage + 1,
        })
    }

    const handleSearch = async (type = '', value) => {
        const searchQuery = toLower(value.target.value)

        if (type === 'author') {
            setDataOptionAuthor([])
            setLoadingAuthor(true)
            await provider
                .list(url.webhookURL_book_author_list + `?limit=9999`)
                .then((res) => {
                    const { data } = res
                    const resultData = filter(data, (item) =>
                        includes(toLower(item.name), searchQuery)
                    )
                    setDataOptionAuthor(
                        map(resultData, (item) => ({
                            text: item.name,
                        }))
                    )
                    bookFilterOptionsAction.setFilterOptions({
                        ...bookFilterOptions,
                        author: map(resultData, (item) => ({
                            text: item.name,
                        })),
                    })
                    setLoadingAuthor(false)
                })
        } else if (type === 'year') {
            const resultData = filter(dataOptionYear, (item) =>
                includes(toLower(item), searchQuery)
            )
            setDataOptionYear(resultData)
        } else if (type === 'category') {
            bookFilterOptionsAction.clearFilterOptions()
            setSearch(searchQuery)
            setHasSearch(false)
        }
    }

    const onClearSearch = (type = '', e) => {
        if (e.target.value === '') {
            if (type === 'author') {
                setHasMoreAuthor(true)
                setCurrentPageAuthor(1)
                getAuthorOption()
            } else if (type === 'year') {
                setDataOptionYear(arrayYear)
            } else if (type === 'category') {
                setSearch()
                setDataCategories({ ...dataCategories, list: [] })
                setHasSearch(true)
            }
        }
    }

    useEffect(() => {
        switchActive && form.setFieldsValue(switchActive)
    }, [])

    useEffect(() => {
        bookFilterOptions?.category &&
            setDataCategories(bookFilterOptions?.category)
        bookFilterOptions?.author &&
            setDataOptionAuthor(bookFilterOptions?.author)
    }, [])

    return (
        <>
            {isTablet() ? (
                <Row
                    className={
                        fromHome ? 'filter-component-home' : 'filter-component'
                    }
                    style={{ width: '100%' }}
                >
                    <Col span={isMiddle() ? 11 : 12} className="category-name">
                        <Tooltip
                            title={
                                categoryName === 'recommendation'
                                    ? translator.t('action.recommendation')
                                    : categoryName
                            }
                        >
                            <Text ellipsis={true} className="name">
                                {categoryName === 'recommendation'
                                    ? translator.t('action.recommendation')
                                    : categoryName}
                            </Text>
                        </Tooltip>
                    </Col>
                    <Col
                        span={12}
                        className="order-component"
                        style={{ textAlign: 'end' }}
                    >
                        <Space>
                            <div
                                className="filter"
                                onClick={() => {
                                    setVisibleModal(!visibleModal)
                                }}
                            >
                                <Image
                                    src={ic_filter}
                                    width={18}
                                    preview={false}
                                />
                                <span className="text">Filter</span>
                            </div>
                            <Select
                                size="small"
                                style={{
                                    width: isMobile()
                                        ? 100
                                        : isMiddle()
                                        ? 110
                                        : 100,
                                }}
                                bordered={false}
                                placeholder={translator.t('action.sort')}
                                onChange={handleSorting}
                            >
                                <Option value="latest">
                                    {translator.t('sort.latest')}
                                </Option>
                                <Option value="abjad">
                                    {translator.t('sort.abjad')}
                                </Option>
                            </Select>
                        </Space>
                    </Col>
                    <ModalFIlterSorting
                        setVisibleModal={setVisibleModal}
                        visibleModal={visibleModal}
                        form={form}
                        handleFinish={handleFlter}
                        totalData={totalData}
                        reseltFilter={reseltFilter}
                        hasFilter={hasFilter}
                        fromHome
                        switchActive={switchActive}
                        bookFilterOptions={bookFilterOptions}
                        handleSearch={handleSearch}
                        onClearSearch={onClearSearch}
                        dataCategories={dataCategories}
                        categoryPagination={categoryPagination}
                        hasSearch={hasSearch}
                        loadMoreCategory={loadMoreCategory}
                        loading={loadingCategory}
                        dataOptionAuthor={dataOptionAuthor}
                        hasMoreAuthor={hasMoreAuthor}
                        loadingAuthor={loadingAuthor}
                        loadMoreAuthor={loadMoreAuthor}
                        dataOptionYear={dataOptionYear}
                    />
                </Row>
            ) : (
                <Row
                    // className={
                    //     fromHome ? 'filter-component-home' : 'filter-component'
                    // }
                    className={
                        fromHome
                            ? 'desktop-filter-component'
                            : 'desktop-filter-component-more'
                    }
                    style={{ width: '100%' }}
                >
                    <Col
                        xs={24}
                        sm={24}
                        md={24}
                        lg={22}
                        xl={19}
                        xxl={19}
                        className="category-name"
                    >
                        <Collapse ghost>
                            <Panel
                                showArrow={false}
                                header={
                                    <Row>
                                        <Col
                                            lg={8}
                                            md={8}
                                            sm={8}
                                            xs={11}
                                            className="category-dropdown"
                                        >
                                            <h4>
                                                {translator.t(
                                                    'filter.category'
                                                )}
                                            </h4>
                                            <DownOutlined
                                                style={{ paddingRight: 10 }}
                                            />
                                        </Col>
                                        <Col
                                            lg={8}
                                            md={8}
                                            sm={8}
                                            xs={13}
                                            className="dropdown-filter"
                                        >
                                            <h4>
                                                {translator.t('filter.author')}
                                            </h4>
                                            <DownOutlined />
                                        </Col>
                                        <Col
                                            lg={8}
                                            md={8}
                                            sm={8}
                                            xs={11}
                                            className="dropdown-year-filter"
                                        >
                                            <h4>
                                                {translator.t('filter.year')}
                                            </h4>
                                            <DownOutlined />
                                        </Col>
                                    </Row>
                                }
                                key="1"
                            >
                                <FilterBookDropdown
                                    form={form}
                                    handleFinish={handleFlter}
                                    totalData={totalData}
                                    reseltFilter={reseltFilter}
                                    hasFilter={hasFilter}
                                    fromHome
                                    switchActive={switchActive}
                                    bookFilterOptions={bookFilterOptions}
                                    handleSearch={handleSearch}
                                    onClearSearch={onClearSearch}
                                    dataCategories={dataCategories}
                                    categoryPagination={categoryPagination}
                                    hasSearch={hasSearch}
                                    loadMoreCategory={loadMoreCategory}
                                    loading={loadingCategory}
                                    dataOptionAuthor={dataOptionAuthor}
                                    hasMoreAuthor={hasMoreAuthor}
                                    loadingAuthor={loadingAuthor}
                                    loadMoreAuthor={loadMoreAuthor}
                                    dataOptionYear={dataOptionYear}
                                />
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            )}
        </>
    )
}

export default connect(mapStateToProps)(FilterSorterBookCategory)
