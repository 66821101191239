import store from '../store'
import * as ActionType from './contant'

export default {
    setDataTabKey: (value) => {
        const action = { type: ActionType.SET_TAB_KEY, value }
        store.dispatch(action)
    },
    clearDataTabKey: () => {
        const action = { type: ActionType.CLEAR_TAB_KEY }
        store.dispatch(action)
    },
}
