/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { initializeApp, getApps } from 'firebase/app'
import {
    onMessage,
    isSupported,
    getMessaging as getMessagingFirebase,
    getToken,
    deleteToken,
} from 'firebase/messaging'
import { getDatabase as getDatabaseFirebase } from 'firebase/database'
import { FIREBASE_VALIDKEY } from 'helpers/constants'
import getEnv from './getEnv.js'

const firebaseConfig = getEnv('REACT_APP_FIREBASE_CONFIG')

const getDatabase = () => {
    try {
        if (getApps().length === 0) initializeApp(firebaseConfig)
        return getDatabaseFirebase()
    } catch (err) {
        console.error(err)
        return null
    }
}

const firebaseDatabase = getDatabase()

const getMessaging = async () => {
    try {
        if (getApps().length === 0) initializeApp(firebaseConfig)
        const isSupportedBrowser = await isSupported()
        if (isSupportedBrowser) {
            return getMessagingFirebase()
        }
    } catch (err) {
        console.error(err)
        return null
    }
}

const firebaseCloudMessaging = {
    clearToken: async function () {
        try {
            const messaging = await getMessaging()
            await deleteToken(messaging)
        } catch (error) {
            console.error(error)
        }
    },
    setFCM: async function () {
        try {
            const permission = await Notification.requestPermission().catch(
                (err) => {
                    throw err
                }
            )
            const messaging = await getMessaging()
            if (permission === 'granted')
                return await getToken(messaging, {
                    vapidKey: FIREBASE_VALIDKEY,
                }).catch((err) => {
                    throw err
                })
        } catch (error) {
            console.error(error)
            return null
        }
    },
}

const useMessageListener = (args) => {
    const [messaging, setMessaging] = useState(null)

    useEffect(() => {
        getMessaging().then((value) => {
            setMessaging(value)
        })
    }, [])

    useEffect(() => {
        let onMessageListener
        if (messaging) {
            onMessageListener = onMessage(messaging, (payload) => {
                args.onMessage(payload)
            })
        }
        return () => onMessageListener
    }, [messaging])
}

export { firebaseCloudMessaging, firebaseDatabase, useMessageListener }
