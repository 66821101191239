import React, { useState, useEffect } from 'react'
import { Modal, Skeleton } from 'antd'
import translator from 'i18next'
import BookDonationProgram from '../BookDonationProgram'
// import AudioDonationProgram from '../AudioDonationProgram'
// import VideoDonationProgram from '../VideoDonationProgram'
// import FundraisingDonationProgram from '../FundraisingDonationProgram'
import notificationHandler from 'utils/notificationHandler'
import provider from 'providers/provider'
import url from 'constants/url'
// import BookPackageDonation from '../BookPackageDonation'
// import { useSelector } from 'react-redux'

const ModalDonationProgram = (props) => {
    const { visible, setVisible, getOrderList } = props

    const [tabPosition] = useState('book')

    // const profile = useSelector((state) => state.profile)
    // const schoolId = profile?.school?.id

    const [limitBook, setLimitBook] = useState(10)
    const [bookSearch, setBookSearch] = useState('')
    const [bookDonation, setBookDonation] = useState({
        data: [],
        loading: false,
        total: 0,
    })

    // const [searchBookPackage] = useState('')
    // const [donationPackage] = useState([])
    // const [total] = useState(0)
    // const [loading] = useState(false)
    // const [offset, setOffset] = useState(0)
    // const defaultFilter = {
    //     'bp.school_id': schoolId,
    //     'bp.is_publish': true,
    //     ...(searchBookPackage && {
    //         'bp.package_name': {
    //             ilike: `%${searchBookPackage}%`,
    //         },
    //     }),
    // }
    // const defaultLimit = 25

    const getBookDonationList = async () => {
        setBookDonation({ ...bookDonation, loading: true })

        await provider
            .list(
                encodeURI(
                    `${url.webhookURL_donation_book_list}?limit=${limitBook}&q=${bookSearch}`
                )
            )
            .then((res) => {
                const { data, meta } = res
                setBookDonation({
                    data: data,
                    loading: false,
                    total: meta?.total,
                })
            })
            .catch((err) => {
                setBookDonation({ ...bookDonation, loading: false })
                notificationHandler('dev2', err)
            })
    }

    // const getDonationPackageList = async () => {
    //     setLoading(true)
    //     try {
    //         const data = await provider.list(
    //             encodeURI(
    //                 `${
    //                     url.webhookURL_book_package_list
    //                 }?limit=${defaultLimit}&offset=${offset}&filter=${JSON.stringify(
    //                     defaultFilter
    //                 )}`
    //             )
    //         )
    //         setTotal(data?.meta?.total)
    //         if (offset === 0) {
    //             setDonationPackage(data?.data)
    //         } else {
    //             setDonationPackage(donationPackage.concat(data?.data))
    //         }
    //         setLoading(false)
    //     } catch (err) {
    //         setLoading(false)
    //         notificationHandler('dev2', err)
    //     }
    // }

    useEffect(() => {
        visible && getBookDonationList()
    }, [limitBook, bookSearch, visible])

    // useEffect(() => {
    //     visible && getDonationPackageList()
    // }, [offset, searchBookPackage, visible])

    const contentMenu = () => {
        switch (tabPosition) {
            // case 'fundraising':
            //     return <FundraisingDonationProgram />
            case 'book':
                return (
                    <>
                        {bookDonation?.loading && !bookSearch ? (
                            <div
                                style={{
                                    padding: 15,
                                }}
                            >
                                <Skeleton active />
                                <Skeleton active />
                            </div>
                        ) : (
                            <BookDonationProgram
                                limit={limitBook}
                                setLimit={setLimitBook}
                                setBookSearch={setBookSearch}
                                bookDonation={bookDonation}
                                setVisible={setVisible}
                                getOrderList={getOrderList}
                            />
                        )}
                    </>
                )
            // case 'video':
            //     return <VideoDonationProgram />
            // case 'audio':
            //     return <AudioDonationProgram />
            // case 'book-package':
            //     return (
            //         <BookPackageDonation
            //             setSearchBookPackage={setSearchBookPackage}
            //             total={total}
            //             loading={loading}
            //             setOffset={setOffset}
            //             donationPackage={donationPackage}
            //             setVisible={setVisible}
            //             searchBookPackage={searchBookPackage}
            //             getOrderList={getOrderList}
            //         />
            //     )
            default:
                break
        }
    }

    return (
        <div>
            <Modal
                title={translator.t('Program Donasi')}
                visible={visible}
                onCancel={() => {
                    setVisible(!visible)
                }}
                className="modal-program-donation-wrapper"
                maskClosable={false}
                centered
                // width={550}
                footer={<></>}
            >
                {
                    //       <div className="modal-program-tabs">
                    //       <Radio.Group
                    //           value={tabPosition}
                    //           onChange={(e) => setTabPosition(e?.target?.value)}
                    //       >
                    //           <Space size={[15, 15]} wrap>
                    //               {/* <Radio.Button value="fundraising">
                    //     {translator.t('Galang Dana')}
                    //   </Radio.Button> */}
                    //               <Radio.Button value="book">
                    //                   {translator.t('Buku')}
                    //               </Radio.Button>
                    //               {/* <Radio.Button value="video">
                    //     {translator.t('Video')}
                    //   </Radio.Button>
                    //   <Radio.Button value="audio">
                    //     {translator.t('Audio')}
                    //   </Radio.Button> */}
                    //               {/* <Radio.Button value="book-package">
                    //   {translator.t('title.bookPackage')}
                    // </Radio.Button> */}
                    //           </Space>
                    //       </Radio.Group>
                    //   </div>
                }
                <div className="program-donation-content">{contentMenu()}</div>
            </Modal>
        </div>
    )
}

export default ModalDonationProgram
