import { Input, Select } from 'antd'
import React, { useState, useEffect } from 'react'
import translator from 'i18next'
import reduxState from '../../redux'
import filter from 'lodash/filter'
import get from 'lodash/get'
import provider from 'providers/provider'
import url from 'constants/url'
import { COLORS } from 'constants/constant'

const { Search } = Input
const { Option } = Select

const FilterFriendship = (props) => {
    const {
        handleSorting,
        stateFollow,
        setStateFollow,
        // handleSearch,
        setSearch,
        followAction,
    } = props

    const styleActive = {
        marginBottom: '15px',
        color: COLORS.primaryColor,
        fontWeight: 'bold',
    }

    const { profile = {} } = reduxState.getState()
    const follower_id = get(profile, 'id', '')
    const [followerCount, setFollowerCount] = useState(0)
    const [followingCount, setFollowingCount] = useState(0)

    const getFollowing = async () => {
        await provider
            .list(url.webhookUrl_following + `?limit=9999&offset=0`)
            .then((res) => {
                const { data } = res
                const filterData = filter(data, (e) => e.id !== follower_id)
                setFollowingCount(filterData.length)
            })
    }

    const getFollower = async () => {
        await provider
            .list(url.webhookURL_follower + `?limit=9999&offset=0`)
            .then((res) => {
                const { data } = res
                const filterData = filter(data, (e) => e.id !== follower_id)
                setFollowerCount(filterData.length)
            })
    }

    useEffect(() => {
        getFollowing()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [followAction])

    useEffect(() => {
        getFollower()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [followAction])

    return (
        <div className="follow__header">
            <div className="follow__headerLeft">
                <div
                    style={
                        stateFollow.follower
                            ? styleActive
                            : { marginBottom: '15px' }
                    }
                    onClick={() =>
                        setStateFollow({ ...stateFollow, follower: true })
                    }
                >
                    {translator.t('action.followers')}{' '}
                    <span>({followerCount})</span>
                </div>
                <div
                    style={
                        !stateFollow.follower
                            ? styleActive
                            : { marginBottom: '15px' }
                    }
                    onClick={() =>
                        setStateFollow({ ...stateFollow, follower: false })
                    }
                >
                    {translator.t('action.following')}{' '}
                    <span>({followingCount})</span>
                </div>
            </div>
            <div className="follow__headerRight">
                <Search
                    className="follow__headerRightInput"
                    placeholder={translator.t('action.searchUser')}
                    enterButton
                    onSearch={(e) => setSearch(e)}
                    allowClear
                />
                <div className="follow__headerRightAction">
                    <span className="follow__headerRightActionCount">
                        {translator.t(
                            get(stateFollow, 'follower', true)
                                ? 'action.followers'
                                : 'action.following'
                        )}
                    </span>
                    <Select
                        size="small"
                        bordered={false}
                        placeholder={translator.t('action.sort')}
                        className="follow__headerRightActionDropdown"
                        onChange={handleSorting}
                    >
                        <Option value="latest">
                            {translator.t('sort.latest')}
                        </Option>
                        <Option value="abjad">
                            {translator.t('sort.abjad')}
                        </Option>
                    </Select>
                </div>
            </div>
        </div>
    )
}

export default FilterFriendship
