import React from 'react'
import translator from 'i18next'
import { Col, message, Row, Skeleton, Typography } from 'antd'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Lazy } from 'swiper/core'
import { BASE_TITLE, COLORS } from 'constants/constant'
import { useHistory } from 'react-router'

SwiperCore.use([Pagination, Lazy])
const { Paragraph } = Typography

const ArticleEpustaka = (props) => {
    const history = useHistory()
    const epustakaId = props?.epustakaId
    const loading = props?.loading
    const articleData = props?.articleData
    const dataEpustaka = props?.dataEpustaka

    const handleLinkMore = () => {
        if (dataEpustaka?.epustaka_isprivate) {
            if (
                !dataEpustaka?.has_join ||
                (dataEpustaka?.has_join && dataEpustaka?.member_status !== 1)
            )
                if (dataEpustaka?.member_status === 3) {
                    message.warning(translator.t('message.notifExpand'))
                } else {
                    message.warning(translator.t('message.notif'))
                }
            else {
                history.push({
                    pathname: '/list-article-epustaka',
                    state: dataEpustaka,
                })
            }
        } else {
            history.push({
                pathname: '/list-article-epustaka',
                state: dataEpustaka,
            })
        }
    }

    return (
        <>
            {loading ? (
                <Row wrap gutter={[24, 24]}>
                    <Col lg={12} sm={24} xs={24}>
                        <Skeleton.Input
                            active
                            style={{ height: 230, borderRadius: 10 }}
                        />
                    </Col>
                    <Col lg={12} sm={24} xs={24}>
                        <Skeleton.Input
                            active
                            style={{ height: 230, borderRadius: 10 }}
                        />
                    </Col>
                </Row>
            ) : (
                <>
                    <Row
                        style={{
                            justifyContent: 'space-between',
                            display: 'flex',
                            alignItems: 'center',
                            margin: '10px 0',
                        }}
                    >
                        <Col>
                            <div
                                style={{
                                    fontWeight: '600',
                                    fontSize: '16px',
                                }}
                            >
                                {translator.t('epustka.collectionArticle')}
                            </div>
                        </Col>
                        {articleData.length > 2 && (
                            <Col>
                                <div
                                    style={{
                                        color: COLORS.primaryColor,
                                        fontSize: '15px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => handleLinkMore(dataEpustaka)}
                                >
                                    {translator.t('action.fully')}
                                </div>
                            </Col>
                        )}
                    </Row>
                    <div>
                        <Swiper
                            slidesPerView={1.2}
                            spaceBetween={20}
                            preloadImages={false}
                            lazy={true}
                            pagination={{
                                el: '.swiper-pagination',
                                clickable: true,
                            }}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },
                                360: {
                                    slidesPerView: 1,
                                },
                                411: {
                                    slidesPerView: 1,
                                },
                                420: {
                                    slidesPerView: 1,
                                },
                                540: {
                                    slidesPerView: 1.2,
                                },
                                640: {
                                    slidesPerView: 1.5,
                                },
                                768: {
                                    slidesPerView: 2,
                                },
                                884: {
                                    slidesPerView: 2,
                                },
                                1024: {
                                    slidesPerView: 2,
                                },
                            }}
                            // classNameName="swiper_container"
                        >
                            {articleData?.map((item, key) => {
                                const handleLinkToDetail = async () => {
                                    if (
                                        !dataEpustaka?.has_join ||
                                        (dataEpustaka?.has_join &&
                                            dataEpustaka?.member_status !== 1)
                                    ) {
                                        if (dataEpustaka?.member_status === 3) {
                                            message.warning(
                                                translator.t(
                                                    'message.notifExpand'
                                                )
                                            )
                                        } else {
                                            message.warning(
                                                translator.t('message.notif')
                                            )
                                        }
                                    } else {
                                        history.push(
                                            `/article/${item?.id}/${epustakaId}`
                                        )
                                    }
                                }

                                return (
                                    <SwiperSlide key={key}>
                                        <div className="grid epustaka">
                                            <figure
                                                className="effect-apollo"
                                                onClick={() =>
                                                    handleLinkToDetail()
                                                }
                                            >
                                                <img
                                                    src={
                                                        item?.news_thumbnail_url
                                                    }
                                                    alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                                />

                                                <figcaption>
                                                    <Paragraph
                                                        ellipsis={
                                                            true
                                                                ? {
                                                                      rows: 2,
                                                                      expandable: false,
                                                                  }
                                                                : false
                                                        }
                                                        className="title-h2"
                                                        style={{
                                                            color: 'white',
                                                        }}
                                                    >
                                                        {item?.news_title}
                                                    </Paragraph>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </>
            )}
        </>
    )
}

export default ArticleEpustaka
