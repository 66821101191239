import isUndefined from 'lodash/isUndefined'
import { error } from './notification'
import translator from 'i18next'

const notificationHandler = (
  fromApi,
  messageData,
  position = 'bottomRight'
) => {
  let errorMessagePath

  switch (fromApi) {
    case 'graphql':
      errorMessagePath = messageData?.message
      break
    case 'dev2':
      errorMessagePath = messageData?.response?.data?.error?.message
      break
    case 'storageClient':
      errorMessagePath = messageData?.response?.data?.errorMessage
      break
    case 'feed':
      errorMessagePath = messageData?.response?.data?.data?.message
      break

    default:
  }

  if (!navigator.onLine) {
    return
  }

  if (isUndefined(errorMessagePath)) {
    return error({
      description: translator.t('message.error'),
      placement: position,
    })
  }
  return error({
    description: errorMessagePath,
    placement: position,
  })
}

export default notificationHandler
