import { Avatar, Button, List, Modal, Skeleton, Typography } from 'antd'
import { ic_pustaka_holder } from 'assets/icons'
import { BASE_TITLE } from 'constants/constant'

import translator from 'i18next'
import { filter } from 'lodash'
import { Link, useHistory } from 'react-router-dom'

const { Paragraph } = Typography
const ModalCollectionOwner = ({
    visibleModal,
    setVisibleModal,
    dataCollectionOwner,
    limit,
    setLimit,
    fromAvailableCopy,
}) => {
    const router = useHistory()

    const loadMore = dataCollectionOwner?.total > limit && (
        <div
            style={{
                textAlign: 'center',
                marginTop: 20,
                height: 32,
                lineHeight: '32px',
            }}
        >
            <Button
                loading={dataCollectionOwner?.loading}
                onClick={() => {
                    dataCollectionOwner?.total > limit &&
                        setLimit((e) => e + 25)
                }}
            >
                {translator.t('action.more')}
            </Button>
        </div>
    )

    return (
        <Modal
            centered
            visible={visibleModal}
            onOk={() => setVisibleModal(!visibleModal)}
            onCancel={() => setVisibleModal(!visibleModal)}
            footer={null}
            className="modal-popup-collection"
            width={550}
            title={
                fromAvailableCopy
                    ? translator.t('column.ownerCollAvail')
                    : translator.t('column.ownerColl')
            }
            style={{ overflow: 'hidden' }}
        >
            <List
                style={{
                    maxHeight: 450,
                    overflow: 'scroll',
                }}
                itemLayout="horizontal"
                loadMore={loadMore}
                dataSource={
                    fromAvailableCopy
                        ? filter(
                              dataCollectionOwner?.data,
                              (e) => e?.available_qty > 0
                          )
                        : dataCollectionOwner?.data
                }
                renderItem={(item) => {
                    return (
                        <List.Item
                            key={item?.id}
                            onClick={() => router.push(`/epustaka/${item?.id}`)}
                            style={{ cursor: 'pointer' }}
                        >
                            <Skeleton
                                avatar
                                title={false}
                                loading={dataCollectionOwner?.loading}
                                active
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            size={40}
                                            src={
                                                item?.epustaka_logo ||
                                                ic_pustaka_holder
                                            }
                                            alt={`${BASE_TITLE} Preview Images - Platform Perpustakaan Indonesia`}
                                        />
                                    }
                                    title={
                                        <Link
                                            to={{
                                                pathname: `/article/${item?.id}`,
                                            }}
                                        >
                                            <Paragraph
                                                ellipsis={
                                                    true
                                                        ? {
                                                              rows: 2,
                                                              expandable: false,
                                                          }
                                                        : false
                                                }
                                            >
                                                {item?.epustaka_name}
                                            </Paragraph>
                                        </Link>
                                    }
                                    description={
                                        <div>
                                            <span>
                                                {item?.epustaka_isprivate ===
                                                true
                                                    ? translator.t(
                                                          'ePustaka Private'
                                                      )
                                                    : translator.t(
                                                          'ePustaka Public'
                                                      )}
                                            </span>
                                            ,{' '}
                                            <span>
                                                {fromAvailableCopy
                                                    ? item?.available_qty
                                                    : item?.total_qty || 0}{' '}
                                                Copy
                                            </span>
                                        </div>
                                    }
                                />
                            </Skeleton>
                        </List.Item>
                    )
                }}
            />
        </Modal>
    )
}

export default ModalCollectionOwner
