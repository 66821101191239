import React, { useState } from 'react'
import translator from 'i18next'

const ReadMore = ({ text, length = 75 }) => {
    const [showLess, setShowLess] = useState(true)

    if (text.length < length) {
        return (
            <p
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />
        )
    }

    return (
        <div style={{ textAlign: 'justify' }}>
            <p
                dangerouslySetInnerHTML={{
                    __html: showLess ? `${text.slice(0, length)}...` : text,
                }}
            ></p>
            <span
                className="read-more-less"
                onClick={() => setShowLess(!showLess)}
            >
                {showLess
                    ? translator.t('action.fully')
                    : translator.t('action.readLess')}
            </span>
        </div>
    )
}

export default ReadMore
