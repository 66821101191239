import React, { useEffect, useState } from 'react'
import provider from 'providers/provider'
import get from 'lodash/get'
import url from 'constants/url'
import reduxState from '../../../redux'
import translator from 'i18next'
import { useHistory } from 'react-router-dom'
import { AudioCardComponent } from 'views/home/audio/components'
import { SkeletonAudio } from 'components'
import { COLORS } from 'constants/constant'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Lazy } from 'swiper/core'

SwiperCore.use([Pagination, Lazy])

const HistoryAudio = (props) => {
    const userId = props.userId.id
    const source = props.userId.source
    const { profile = {} } = reduxState.getState()
    const id = get(profile, 'id', '')
    const defaultPagination = `?limit=8&type=audio`
    const filter =
        source === 'userProfil' ? `&user_id=${userId}` : `&user_id=${id}`

    const history = useHistory()

    const [audioLoading, setAudioLoading] = useState(false)
    const [audioData, setAudioData] = useState([])

    const getAudioData = async () => {
        let isMounted = true // note this flag denote mount status

        setAudioLoading(true)
        await provider
            .list(
                url.webhookURL_multimedia_history_list +
                    defaultPagination +
                    filter
            )
            .then((res) => {
                const { data } = res
                if (isMounted) {
                    setAudioData(data)
                    setAudioLoading(false)
                }
            })
        return () => {
            isMounted = false
        }
    }

    useEffect(() => {
        getAudioData()
        // disable warning missing dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            {audioLoading ? (
                <SkeletonAudio />
            ) : (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: 16,
                            marginTop: 10,
                        }}
                    >
                        <p
                            style={{
                                color: 'rgb(87, 87, 87)',
                                fontWeight: 600,
                            }}
                        >
                            {translator.t('column.audio')}
                        </p>
                        {/* {audioData.length > 6 && ( */}
                        <div
                            onClick={() =>
                                history.push({
                                    pathname: '/more-history-audio',
                                    state: { userId, source },
                                })
                            }
                            style={{
                                cursor: 'pointer',
                                color: COLORS.primaryColor,
                            }}
                        >
                            {translator.t('action.fully')}
                        </div>
                        {/* )} */}
                    </div>

                    <div className="swiper_container">
                        <Swiper
                            slidesPerView={1.2}
                            spaceBetween={10}
                            preloadImages={false}
                            lazy={true}
                            pagination={{
                                el: '.swiper-pagination',
                                clickable: true,
                            }}
                            breakpoints={{
                                320: {
                                    slidesPerView: 2,
                                },
                                360: {
                                    slidesPerView: 2.1,
                                },
                                411: {
                                    slidesPerView: 2.2,
                                },
                                420: {
                                    slidesPerView: 2.4,
                                },
                                540: {
                                    slidesPerView: 2.9,
                                },
                                640: {
                                    slidesPerView: 3,
                                },
                                768: {
                                    slidesPerView: 3.8,
                                },
                                884: {
                                    slidesPerView: 4,
                                },
                                1024: {
                                    slidesPerView: 4,
                                },
                                1087: {
                                    slidesPerView: 4.5,
                                },
                            }}
                            className="swiper_container"
                        >
                            {audioData.map((item, key) => (
                                <SwiperSlide key={key}>
                                    <AudioCardComponent
                                        key={id}
                                        data={item}
                                        userId={userId}
                                        getMedia={getAudioData}
                                        source={source ? 'userProfil' : 'audio'}
                                        frombookself="bookself"
                                        fromIndex
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </>
            )}
        </React.Fragment>
    )
}

export default HistoryAudio
