import React, { useState, useEffect } from 'react'
import {
    Modal,
    Image,
    Space,
    Typography,
    InputNumber,
    Input,
    Button,
} from 'antd'
import { BASE_TITLE, COLORS } from 'constants/constant'
import NumberFormat from 'react-number-format'
import { DeleteOutlined } from '@ant-design/icons'
import translator from 'i18next'
import provider from 'providers/provider'
import url from 'constants/url'
import { isEmpty } from 'lodash'
import { ic_choose_active, ic_pustaka_holder } from 'assets/icons'
import reduxState from '../../../redux'
import { success } from 'utils/notification'
import notificationHandler from 'utils/notificationHandler'
import { useHistory } from 'react-router-dom'

const { Search } = Input
const { Paragraph } = Typography
const ModalBookDonation = (props) => {
    const { visible, setVisible, bookDetailData, getOrderList } = props
    const router = useHistory()
    const { profile = {} } = reduxState.getState()

    const profile_id = profile?.id
    const organization_id = profile.organizations.id

    const [copy, setCopy] = useState(0)
    const [limitEpustaka, setLimitEpustaka] = useState(25)
    const [totalEpustaka, setTotalEpustaka] = useState(0)
    const [hasChange, setHasChange] = useState(false)
    const [epustakaExist, setEpustakaExist] = useState(false)
    const [loadingEpustaka, setLoadingEpustaka] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [hasSelect, setHasSelect] = useState('')
    const [querySearchEpustaka, setQuerySearchEpustaka] = useState('')
    const [dataEpustakaDonation, setDataEpustakaDonation] = useState([])

    const getHasEpustaka = async () => {
        await provider
            .singleWebHook(url.webhookURL_has_epustaka)
            .then((res) => {
                setEpustakaExist(res?.data?.message === 'Epustaka tersedia')
            })
    }

    const getDonatioEpustakaList = async () => {
        setLoadingEpustaka(true)
        let filterQuery = {
            'e.organization_id': organization_id,
        }
        if (!isEmpty(querySearchEpustaka)) {
            filterQuery = {
                ...filterQuery,
                'e.epustaka_name': { ilike: `%${querySearchEpustaka}%` },
            }
        }
        await provider
            .list(
                encodeURI(
                    `${
                        url.webhookURL_donation_epustaka_list
                    }?limit=${limitEpustaka}&filter=${JSON.stringify(
                        filterQuery
                    )}`
                )
            )
            .then((res) => {
                setLoadingEpustaka(false)
                const { data, meta } = res
                setTotalEpustaka(meta?.total)
                setDataEpustakaDonation(data)
            })
            .catch((err) => {
                setLoadingEpustaka(false)
                notificationHandler('dev2', err)
            })
    }

    useEffect(() => {
        if (!isEmpty(bookDetailData) && visible) {
            getHasEpustaka()
        }
    }, [bookDetailData, visible])

    useEffect(() => {
        epustakaExist && getDonatioEpustakaList()
    }, [epustakaExist, querySearchEpustaka, limitEpustaka])

    const submitDonationBook = async () => {
        setLoadingSubmit(true)
        const data = {
            order_type: 'BOOK',
            order_type_id: bookDetailData?.id,
            number_of_copy: copy,
            category: 'DONATION',
            buyer_type: 'PERSONAL',
            epustaka_id: hasSelect,
        }

        await provider
            .insert(url.webhookURL_add_order_box, data)
            .then((res) => {
                const { data } = res
                success({
                    description: translator.t('payment.messageOrder'),
                    placement: 'bottomRight',
                })
                setVisible(false)
                setHasChange(false)
                setCopy(0)
                setLoadingSubmit(false)
                if (getOrderList) {
                    getOrderList()
                } else {
                    return router.push(`/cart/${data?.order_box_id}/D`)
                }
            })
            .catch((err) => {
                setLoadingSubmit(false)
                notificationHandler('dev2', err)
            })
    }

    const loadMore = () => {
        if (totalEpustaka > limitEpustaka) {
            return (
                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <Button
                        loading={loadingEpustaka}
                        onClick={() => {
                            totalEpustaka > limitEpustaka &&
                                setLimitEpustaka((e) => e + 25)
                        }}
                    >
                        {translator.t('action.more')}
                    </Button>
                </div>
            )
        } else {
            return <></>
        }
    }

    return (
        <Modal
            title={translator.t('title.book_donation')}
            visible={visible}
            onCancel={() => setVisible(!visible)}
            className="modal-book-donation-wrapper"
            maskClosable={false}
            afterClose={() => {
                setCopy(0)
                setHasSelect('')
                setEpustakaExist(false)
            }}
            style={{ top: 38 }}
            footer={
                <Button
                    style={{
                        borderRadius: 15,
                        width: 130,
                    }}
                    type="primary"
                    loading={loadingSubmit}
                    disabled={
                        !hasChange || !hasSelect || bookDetailData?.price <= 0
                    }
                    onClick={submitDonationBook}
                >
                    {translator.t('title.continue')}
                </Button>
            }
        >
            <div className="book-detail-wrapper">
                <div>
                    <Image
                        src={bookDetailData?.cover_url}
                        alt={`${BASE_TITLE} Preview Images`}
                        preview={false}
                        width={110}
                        className="book-image"
                    />
                </div>
                <div className="book-detail-info">
                    <div>
                        <Paragraph
                            className="book-detail-info-title"
                            ellipsis={
                                true ? { rows: 2, expandable: false } : false
                            }
                        >
                            {bookDetailData?.book_title}
                        </Paragraph>
                        <div className="book-detail-info-subtitle">
                            {bookDetailData?.book_author}
                        </div>
                    </div>
                    <div className="book-price-wrapper">
                        <div className="book-price-input">
                            <div className="price-book-detail">
                                <NumberFormat
                                    value={bookDetailData?.price}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'Rp'}
                                    defaultValue={bookDetailData?.price}
                                />
                            </div>
                            <Space size={[6, 6]}>
                                <DeleteOutlined
                                    style={{ fontSize: 18, cursor: 'pointer' }}
                                    onClick={() => {
                                        setCopy(0)
                                        setHasChange(false)
                                    }}
                                />

                                <InputNumber
                                    min={1}
                                    value={copy}
                                    onChange={(e) => {
                                        setCopy(e)
                                        setHasChange(true)
                                    }}
                                />
                                <span
                                    style={{
                                        color: COLORS.primaryColor,
                                        userSelect: 'none',
                                    }}
                                >
                                    Copy
                                </span>
                            </Space>
                        </div>
                        <div className="total-price">
                            <div className="total-price-label">
                                {translator.t('title.total_price')}
                            </div>
                            <div className="total-price-value">
                                <NumberFormat
                                    value={copy * bookDetailData?.price}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'Rp'}
                                    defaultValue={bookDetailData?.price}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="epustaka-list-wrapper">
                <h4>{translator.t('title.choose_epustaka_purpose')}</h4>

                <Search
                    className="epustaka-list-search"
                    placeholder={translator.t('title.find_ePustaka')}
                    loading={loadingEpustaka}
                    enterButton
                    onSearch={(e) => setQuerySearchEpustaka(e)}
                    allowClear
                />
                <p className="total-epustaka-search">
                    {translator.t('title.total_epustaka_donation', {
                        total_epustaka_donation: totalEpustaka,
                    })}
                </p>

                {dataEpustakaDonation?.map((item, key) => {
                    const my_epustaka = item?.created_by === profile_id

                    return (
                        <div
                            className="epustaka-lists"
                            style={{
                                backgroundColor:
                                    hasSelect === item?.id
                                        ? 'rgb(34 206 192 / 20%)'
                                        : '#f9f9f9',
                                border:
                                    hasSelect === item?.id &&
                                    '1px solid rgb(34 206 192 / 20%)',
                            }}
                            key={key}
                            onClick={() => setHasSelect(item?.id)}
                        >
                            <div
                                style={{
                                    backgroundColor: 'white',
                                    marginRight: 15,
                                    borderRadius: 8,
                                    height: 70,
                                    boxShadow: '0 3px 3px rgba(0,0,0,0.2)',
                                }}
                            >
                                <Image
                                    style={{
                                        width: 70,
                                        height: 70,
                                        borderRadius: 8,
                                        objectFit: 'contain',
                                    }}
                                    preview={false}
                                    src={
                                        item?.epustaka_logo || ic_pustaka_holder
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    alignItems: 'center',
                                }}
                            >
                                <div className="epustaka-lists-info">
                                    <h4 className="epustaka-lists-info-author">
                                        {item?.epustaka_name}
                                    </h4>
                                    <p className="epustaka-lists-info-title">
                                        {my_epustaka
                                            ? translator.t('title.my_account')
                                            : item?.epustaka_category === 'P'
                                            ? translator.t(
                                                  'boolean.epustaka.perorangan'
                                              )
                                            : item?.epustaka_category === 'L'
                                            ? translator.t(
                                                  'boolean.epustaka.lembaga'
                                              )
                                            : null}
                                    </p>
                                </div>
                                <div>
                                    {hasSelect === item?.id && (
                                        <Image
                                            width={20}
                                            preview={false}
                                            src={ic_choose_active}
                                            alt={`${BASE_TITLE} Preview Images`}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                })}
                {loadMore()}
            </div>
        </Modal>
    )
}

export default ModalBookDonation
