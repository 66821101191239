import axios from 'axios'
import get from 'lodash/get'
import includes from 'lodash/includes'
import omit from 'lodash/omit'
import state from '../redux'
import authAction from '../redux/auth/action'
import funcRefreshToken from 'helpers/refreshToken'

const axiosApiInstance = axios.create()

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
    async (config) => {
        let serverCallUrl = new URL(config.url)
        if (
            includes(get(serverCallUrl, 'pathname', ''), '/auth') ||
            includes(get(serverCallUrl, 'pathname', ''), '/services') ||
            includes(get(serverCallUrl, 'pathname', ''), '/borrow') ||
            includes(get(serverCallUrl, 'pathname', ''), '/auth') ||
            config.type === 'upload' ||
            config.type === 'external'
        ) {
            // return config
            if (config.type === 'external') {
                return { ...omit(config, ['type']) }
            } else {
                return config
            }
        }

        const { auth = {} } = state.getState()
        let accessToken = auth?.access_token

        if (accessToken)
            config.headers['Authorization'] = `Bearer ${accessToken}`
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error)
        } else {
            prom.resolve(token)
        }
    })

    failedQueue = []
}

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
    (response) => {
        return response
    },
    (err) => {
        const originalRequest = err.config

        const invalidCodes = [
            'invalid_password',
            'invalid_account',
            'invalid_email',
            'deactivated_account',
            'unverified_account',
        ]

        if (err.response === undefined) {
            const err = {
                message: 'Something went wrong!',
            }
            return Promise.reject(err)
        }
        let error
        if (typeof err?.response?.data?.errors === 'object') {
            error = err.response.data.errors?.shift()
        } else {
            error = err.response.data.error
        }
        if (
            err.response.status === 401 &&
            !includes(invalidCodes, error.code)
            //  && !originalRequest._retry
        ) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject })
                })
                    .then((token) => {
                        originalRequest.headers['Authorization'] =
                            'Bearer ' + token
                        return axios(originalRequest)
                    })
                    .catch((err) => {
                        return Promise.reject(err)
                    })
            }

            originalRequest._retry = true
            isRefreshing = true

            return new Promise(function (resolve, reject) {
                funcRefreshToken()
                    .then((res) => {
                        originalRequest.headers['Authorization'] =
                            'Bearer ' + res
                        processQueue(null, res)
                        resolve(axios(originalRequest))
                    })
                    .catch((err) => {
                        processQueue(err, null)
                        authAction.validatingToken(false)
                        authAction.clearAuth()
                        reject(err)
                    })
                    .finally(() => {
                        isRefreshing = false
                    })
            })
        }

        return Promise.reject(err)
    }
)

export default axiosApiInstance
