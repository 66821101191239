import React, { cloneElement, useState, lazy } from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect, useRouteMatch, Route } from 'react-router-dom'
import reduxState from '../redux'
import { Layout, ConfigProvider } from 'antd'
import Footer from './footer/Footer'
import handleLogout from 'utils/logout'
import idID from 'antd/es/locale/id_ID'
import enUS from 'antd/es/locale/en_US'
import ModalPopup from 'components/ModalPopup'
import ls from 'utils/secureLocalStorage'
import encryption from 'utils/encryption'
import { Helmet } from 'react-helmet'
import includes from 'lodash.includes'
import { BASE_TITLE } from 'constants/constant'
import { useLocation } from 'react-router'
import historyPathAction from 'redux/routeHistory/action'
import isEmpty from 'lodash/isEmpty'
import routes from 'router/router'

const Header = lazy(() => import('./header/Header'))
const HeaderPublic = lazy(() => import('./header/HeaderPublic'))

const { Content } = Layout

const langs = {
    id: idID,
    en: enUS,
}

const LayoutContainer = (props) => {
    const pathname = useLocation()
    const initialPopupModal = ls.get(encryption.encryptWithMD5('popupModal'))
    const { history, children } = props
    const { auth = {} } = reduxState.getState()
    const { access_token, verified } = auth
    const [provider, setProvider] = useState(idID)
    const [popupModal, setPopupModal] = useState(initialPopupModal)
    const handleRedirect = (path) => () => {
        history.push(path)
    }

    const routeCheck = (token) => {
        if (isEmpty(token)) {
            if (pathname !== '/login') {
                historyPathAction.setRoute(
                    pathname?.search
                        ? `${pathname?.pathname + pathname?.search}`
                        : pathname?.pathname
                )
            }
        }
    }

    const handleChangeProvider = (lang) => setProvider(langs[lang])

    if (!access_token || !verified) {
        const pageRoute = routes?.find(
            (route) => useRouteMatch(route?.path)?.isExact
        )

        if (pageRoute?.publicComponent)
            return (
                <Layout className="layout-container">
                    <Helmet>
                        <title>{BASE_TITLE} Website</title>
                        <meta
                            name="og:title"
                            content={`${BASE_TITLE} Website - Platform Edukasi Indonesia`}
                        />
                        <meta
                            name="description"
                            content="Mari bergabung dan berkolaborasi mengembangkan pendidikan Indonesia bersama anak bangsa."
                        />
                        <meta property="image" content="/Banner-Desktop.png" />
                        <meta
                            property="og:image"
                            content="/Banner-Desktop.png"
                        />
                    </Helmet>

                    <Layout.Header>
                        <HeaderPublic
                            history={history}
                            handleChangeProvider={handleChangeProvider}
                        />
                    </Layout.Header>

                    <Content
                        className="site-layout"
                        style={{
                            marginTop: 64,
                        }}
                    >
                        <div style={{ marginTop: 20, minHeight: 380 }}>
                            <ConfigProvider locale={provider}>
                                <Route
                                    path={pageRoute.path}
                                    component={pageRoute.publicComponent}
                                    exact
                                />
                            </ConfigProvider>
                        </div>
                    </Content>
                    <Layout.Footer>
                        <Footer />
                    </Layout.Footer>
                </Layout>
            )

        routeCheck(access_token)

        if (pathname !== '/login') return <Redirect to="/login" />
    }

    return (
        <Layout className="layout-container">
            {/* {profile?.grade_level === null &&
                profile?.role?.role_code === 301 &&
                303 && (
                    <ModalProfilling
                        visible={visibleProfilling}
                        setVisible={setVisibleProfilling}
                        profile={profile}
                    />
                )} */}
            <Helmet>
                <title>{BASE_TITLE} Website</title>
                <meta
                    name="og:title"
                    content={`${BASE_TITLE} Website - Platform Perpustakaan Indonesia`}
                />
                <meta
                    name="description"
                    content="Mari bergabung dan berkolaborasi mengembangkan pendidikan Indonesia bersama anak bangsa."
                />
                <meta property="image" content="/share_link.png" />
                <meta property="og:image" content="/share_link.png" />
            </Helmet>
            {/* <Prompt
                when={pathname === '/end-result'}
                message={() => {
                    return openModalProfilling()
                }}
            /> */}
            {!includes(
                ['/read-book', '/read', '/read-book-epub'],
                window.location.pathname
            ) && (
                <Layout.Header>
                    <Header
                        style={{ position: 'fixed', zIndex: 1, width: '100%' }}
                        handleChangeProvider={handleChangeProvider}
                        onLogout={() => handleLogout(history)}
                        parentDOM={document}
                        handleRedirect={handleRedirect}
                    />
                </Layout.Header>
            )}
            <Content
                className="site-layout"
                style={{
                    // padding: '0 50px',
                    marginTop: !includes(
                        ['/read-book', '/read'],
                        window.location.pathname
                    )
                        ? 64
                        : -20,

                    background:
                        pathname === '/books'
                            ? 'white'
                            : pathname === '/book-history-list' ||
                              pathname === '/epustaka-book-category'
                            ? 'white'
                            : '',
                }}
            >
                <div style={{ marginTop: 20, minHeight: 380 }}>
                    <ModalPopup
                        popupModal={popupModal}
                        setPopupModal={setPopupModal}
                    />
                    <ConfigProvider locale={provider}>
                        {cloneElement(children)}
                    </ConfigProvider>
                </div>
            </Content>
            {!includes(['/read-book', '/read'], window.location.pathname) && (
                <Layout.Footer>
                    <Footer />
                </Layout.Footer>
            )}
        </Layout>
    )
}

const mapStateToProps = (state) => ({
    role: state.auth.role,
})

export default connect(mapStateToProps)(withRouter(LayoutContainer))
