import React, { useState, useLayoutEffect } from 'react'
import './Post.scss'
import { ic_comment } from '../../../assets/icons'
import { Avatar as AvtImage, Button, Rate, Tooltip, Typography } from 'antd'
import { LikeOutlined } from '@ant-design/icons'
import get from 'lodash/get'
import Description from 'views/timeline/components/Description'
import moment from 'moment'
import { error } from 'utils/notification'
import provider from 'providers/provider'
import url from 'constants/url'
import translator from 'i18next'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import action from 'redux/timeLine/action'
import actionFeed from 'redux/feed/action'
import getAcronym from 'components/Acronym'
import { BASE_TITLE, COLORS } from 'constants/constant'
import { FallbackImage } from 'components'

const { Text } = Typography
const Post = (props) => {
    const {
        loading,
        avatar_url,
        total_like,
        total_comment,
        review,
        recommendation,
        imageBook,
        username,
        timestamp,
        message,
        description,
        bookTitle,
        bookRating,
        feed_type,
        has_like,
        id,
        setTimeLine,
        stateTimeLine,
        fromDetails = false,
        userId,
        bookId,
        sender_type,
        senderId,
        author,
    } = props

    const history = useHistory()
    const dateSbt = moment(timestamp)

    const [state, setState] = useState({
        width: window.innerWidth,
    })
    const [disable, setDisable] = useState(false)

    const isSmallMobile = () => state.width <= 360
    const isMobile = () => state.width <= 420

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const { color, acronym } = getAcronym(username, id)

    const submitData = async (url, data) => await provider.insert(url, data)

    const handleSave = async (value) => {
        await submitData(url.webhookURL_like, {
            ...value,
            object_type: 'FEED',
            object_id: id,
            sender_type: sender_type,
            sender_id: userId,
        })
            .then(async () => {
                !fromDetails
                    ? setTimeLine({ refreshLike: true })
                    : actionFeed.setDataFeed({
                          setTimeLine,
                          stateTimeLine,
                          avatar_url,
                          total_like: total_like + 1,
                          total_comment,
                          review,
                          recommendation,
                          imageBook,
                          username,
                          timestamp,
                          message,
                          description,
                          bookTitle,
                          bookRating,
                          feed_type,
                          sender_type,
                          userId,
                          has_like: true,
                          id,
                      })
                setDisable(false)
            })
            .catch((err) => {
                Promise.reject(err)
                setDisable(false)
            })
    }
    const onLike = async () => {
        await handleSave()
    }

    const onDislike = async () => {
        await provider
            .deleteData(url.webhookURL_like, {
                object_type: 'FEED',
                object_id: id,
                sender_type: sender_type,
                sender_id: userId,
            })
            .then(() => {
                !fromDetails
                    ? setTimeLine({ refreshLike: true })
                    : actionFeed.setDataFeed({
                          setTimeLine,
                          stateTimeLine,
                          avatar_url,
                          total_like: total_like - 1,
                          total_comment,
                          review,
                          recommendation,
                          imageBook,
                          username,
                          timestamp,
                          message,
                          description,
                          bookTitle,
                          bookRating,
                          feed_type,
                          has_like: false,
                          id,
                          sender_type,
                          userId,
                          //   sender_type,
                      })
                setDisable(false)
            })
            .catch((err) => {
                error({ description: err.message, placement: 'bottomRight' })
                setDisable(false)
            })
    }

    return (
        <>
            {loading ? (
                'Loading...'
            ) : (
                <div className="post" key={id}>
                    <div className="post-top">
                        <AvtImage
                            style={{
                                backgroundImage: color,
                                cursor: 'pointer',
                                minWidth: 40,
                                maxWidth: 40,
                            }}
                            size="large"
                            src={avatar_url}
                            className="post-avatar"
                            onClick={() =>
                                history.push(`/profil/user/${senderId}`)
                            }
                        >
                            <span className="avatar-name">{acronym}</span>
                        </AvtImage>

                        <div className="post-info">
                            <b
                                onClick={() =>
                                    history.push(`/profil/user/${senderId}`)
                                }
                            >
                                <Text
                                    style={{
                                        width: isSmallMobile()
                                            ? 130
                                            : isMobile()
                                            ? 190
                                            : '100%',
                                    }}
                                    ellipsis={true}
                                >
                                    <Tooltip
                                        title={username}
                                        placement="topLeft"
                                        color="#404040"
                                    >
                                        {username}
                                    </Tooltip>
                                </Text>
                            </b>{' '}
                            <span
                                className="post-info-message"
                                style={{ fontSize: isMobile() && 13 }}
                            >
                                <Tooltip title={message}>
                                    <Text
                                        style={{
                                            width: 200,
                                            color: 'grey',
                                        }}
                                        ellipsis={true}
                                    >
                                        {message}
                                    </Text>
                                </Tooltip>
                            </span>
                            <p className="post-info-time">
                                {timestamp && dateSbt.format('LLLL')}
                            </p>
                        </div>
                    </div>

                    {recommendation ? (
                        <div className="post-bottom">
                            {/* BOOK RECOMMENDATON */}
                            <div
                                style={{ color: 'blue' }}
                                className="book-recommendation"
                            >
                                {recommendation}
                            </div>
                            {/* END BOOK RECOMMENDATON */}
                            <p>{description}</p>
                        </div>
                    ) : (
                        ''
                    )}

                    <div className="post-image">
                        {/* BOOK REVIEW */}
                        <div className="book-review">{review}</div>
                        {/* END BOOK REVIEW */}

                        {/* Posting book */}
                        <div className="post-image-book">
                            <div className="bookImage">
                                {imageBook && (
                                    <FallbackImage
                                        preview={false}
                                        height={130}
                                        width={100}
                                        fallbackWidth={100}
                                        fallbackHeight={130}
                                        src={imageBook}
                                        alt="book"
                                        onClick={() =>
                                            history.push({
                                                pathname: `/book/${bookId}`,
                                                state: bookId,
                                            })
                                        }
                                    />
                                )}
                            </div>

                            <div className="descrition">
                                <h4>
                                    <Tooltip color="#696969" title={bookTitle}>
                                        <b>
                                            {<Description text={bookTitle} />}
                                        </b>
                                    </Tooltip>
                                </h4>
                                <Description text={author} />

                                <div className="bookRating">
                                    <Rate
                                        allowHalf={true}
                                        disabled={true}
                                        value={bookRating}
                                    />{' '}
                                    <span className="rate">
                                        {bookRating === null
                                            ? '0.0'
                                            : `${parseFloat(bookRating).toFixed(
                                                  1
                                              )}`}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* End Posting book */}
                    </div>

                    <div className="post-like-commet">
                        <div className="like">
                            {total_like + ' ' + translator.t('feed.totalLike')}
                        </div>

                        <div className="comment">
                            {fromDetails
                                ? ''
                                : total_comment +
                                  ' ' +
                                  translator.t('feed.totalComment')}
                        </div>
                    </div>

                    <div className="post-options">
                        <div className="post-option">
                            <Button
                                loading={disable}
                                className="card-title"
                                style={{
                                    border: 'none',
                                    boxShadow: 'none',
                                }}
                                onClick={async () => {
                                    setDisable(true)
                                    if (has_like === false) {
                                        await onLike()
                                    }
                                    if (has_like === true) {
                                        await onDislike()
                                    }
                                }}
                                icon={
                                    has_like === false ? (
                                        <>
                                            <LikeOutlined
                                                style={{
                                                    color: 'grey',
                                                    fontSize: 17,
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <LikeOutlined
                                                style={{
                                                    color: COLORS.primaryColor,
                                                }}
                                            />
                                        </>
                                    )
                                }
                            >
                                {has_like === false ? (
                                    <p>{translator.t('feed.like')}</p>
                                ) : (
                                    <p
                                        style={{
                                            color: COLORS.primaryColor,
                                        }}
                                    >
                                        {translator.t('feed.like')}
                                    </p>
                                )}
                            </Button>
                            {/* <div
                               
                                className="card-title"
                            >
                                {has_like === false ? (
                                    <>
                                        <LikeOutlined
                                            style={{
                                                color: 'grey',
                                                fontSize: 17,
                                            }}
                                        />
                                        <p>{translator.t('feed.like')}</p>
                                    </>
                                ) : (
                                    <>
                                        <LikeOutlined
                                            style={{
                                                color: COLORS.primaryColor,
                                            }}
                                        />
                                        <p
                                            style={{
                                                color: COLORS.primaryColor,
                                            }}
                                        >
                                            {translator.t('feed.like')}
                                        </p>
                                    </>
                                )}
                            </div> */}
                        </div>
                        <div className="post-option">
                            <Button
                                icon={
                                    <img
                                        src={ic_comment}
                                        alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                    />
                                }
                                onClick={() => {
                                    history.push(`/timeline/detail/${id}`)
                                    actionFeed.setDataFeed({
                                        setTimeLine,
                                        stateTimeLine,
                                        avatar_url,
                                        total_like,
                                        total_comment,
                                        review,
                                        recommendation,
                                        imageBook,
                                        username,
                                        timestamp,
                                        message,
                                        description,
                                        bookTitle,
                                        bookRating,
                                        feed_type,
                                        has_like,
                                        senderId,
                                        sender_type,
                                        userId,
                                        bookId,
                                        author,
                                        id,
                                        // setTimeLine,
                                    })
                                }}
                                type="text"
                                className="card-title"
                                style={{
                                    border: 'none',
                                    boxShadow: 'none',
                                }}
                            >
                                <p>{translator.t('feed.comment')}</p>
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    stateTimeLine: get(state, 'timeLine', {}),
})

const mapDispatchToProps = () => ({
    setTimeLine: action.setTimeLine,
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Post))
