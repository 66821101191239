import Modal from 'antd/lib/modal/Modal'
import React, { useState } from 'react'
import translator from 'i18next'
import provider from 'providers/provider'
import { error, success } from 'utils/notification'
import { Button, Input, Form, Modal as ModalAntd } from 'antd'
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom'
import url from 'constants/url'
import { COLORS } from 'constants/constant'
import reduxState from '../../../../redux'

export default function ModalPassword({ visibleModal, setVisibleModal }) {
    const history = useHistory()
    const { profile = {} } = reduxState.getState()
    const [form] = Form.useForm()
    const [oPassword, setOPassword] = useState('')
    const [nPassword, setNPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadingReset, setLoadingReset] = useState(false)

    const updateData = async (data) => {
        await provider.update(url.authURL_auth_account, data).then(() => {
            setVisibleModal(false)
            setLoading(false)
            success({
                description:
                    'Berhasil mengubah kata sandi, silahkan login dengan password yang baru',
                placement: 'bottomRight',
            })
            Cookies.remove('ewAccessToken')
            history.push('/login')
            localStorage.clear()
        })
    }

    const handleSave = async () => {
        setLoading(true)
        updateData({
            scope: 'password',
            data: {
                old_password: `${oPassword}`,
                new_password: `${nPassword}`,
            },
        }).catch(() => {
            error({
                description: translator.t('message.errorPass'),
                placement: 'bottomRight',
            })
        })
        setLoading(false)
    }

    const handleResetPassword = async () => {
        setLoadingReset(true)
        await provider
            .insert(url.reset_password_ticket, {
                email: profile?.email,
            })
            .then((res) => {
                success({
                    description:
                        res?.data?.message ||
                        'Link reset password sudah dikirim ke email anda.',
                    placement: 'bottomRight',
                })
                setVisibleModal(false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
        setLoadingReset(false)
    }

    const formPassword = () => (
        <Form form={form} onFinish={handleSave}>
            <div className="m__peSubtitle">
                {translator.t('action.oldPass')}
            </div>
            <Form.Item
                name="oldpass"
                rules={[
                    {
                        required: true,
                        message: 'Masukan kata sandi anda',
                    },
                ]}
            >
                <Input.Password
                    placeholder={translator.t('notification.oldPass')}
                    onChange={(e) => {
                        setOPassword(e.target.value)
                    }}
                />
            </Form.Item>
            <div
                onClick={() => {
                    ModalAntd.confirm({
                        title: 'Pengaturan ulang kata sandi akan dikirim ulang ke email Anda, silahkan konfirmasi melalui email.',
                        okText: 'Kirim',
                        cancelText: 'Batal',
                        maskClosable: false,
                        onOk: handleResetPassword,
                        cancelButtonProps: { disabled: loadingReset },
                        okButtonProps: { laoding: loadingReset },
                    })
                }}
                style={{
                    textAlign: 'right',
                    color: COLORS.primaryColor,
                    cursor: 'pointer',
                }}
            >
                Lupa Kata Sandi ?
            </div>
            <div className="m__peSubtitle">
                {translator.t('action.newPass')}
            </div>
            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Form Password tidak boleh kosong!',
                    },
                    {
                        min: 8,
                        message: 'Password tidak boleh kurang dari 8 karakter',
                    },
                ]}
            >
                <Input.Password
                    name="password"
                    placeholder={translator.t('notification.inputPass')}
                    onChange={(e) => {
                        setNPassword(e.target.value)
                    }}
                />
            </Form.Item>
            <div className="m__peSubtitle">
                {translator.t('action.repPass')}
            </div>
            <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Ulangi password anda',
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve()
                            }

                            return Promise.reject('Password tidak sama!')
                        },
                    }),
                ]}
            >
                <Input.Password
                    placeholder={translator.t('notification.repPass')}
                    onChange={(e) => {
                        setNPassword(e.target.value)
                    }}
                />
            </Form.Item>
            <Form.Item>
                <div className="m__peButton">
                    <Button
                        className="m__peButtonSave"
                        htmlType="submit"
                        loading={loading}
                        type="primary"
                    >
                        {translator.t('action.edit')}
                    </Button>
                </div>
            </Form.Item>
        </Form>
    )

    return (
        <Modal
            visible={visibleModal}
            onOk={() => setVisibleModal(!visibleModal)}
            onCancel={() => setVisibleModal(!visibleModal)}
            className="m__pe"
            footer={null}
            width={400}
        >
            <div className="m__peTitle">
                {translator.t('action.editPassword')}
            </div>
            {formPassword()}
        </Modal>
    )
}
