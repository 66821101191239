import store from '../store'
import * as ActionType from './contant'

export default {
    setSwitchActive: (value) => {
        const action = { type: ActionType.SET_SWITCH_ACTIVE, value }
        store.dispatch(action)
    },
    clearSwitchActive: () => {
        const action = { type: ActionType.CLEAR_SWITCH_ACTIVE }
        store.dispatch(action)
    },
}
