import React, { useEffect, useState } from 'react'
import { Row, List, Avatar, Col, Button } from 'antd'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import provider from 'providers/provider'
import { error } from 'utils/notification'
import translator from 'i18next'
import url from 'constants/url'
import { ArrowDownOutlined, LoadingOutlined } from '@ant-design/icons'
import { BASE_TITLE, COLORS, LAYOUT } from 'constants/constant'
import { useHistory } from 'react-router'
import { notif_placeholder } from '../../assets/icons'
import actionNotifReq from 'redux/notifReq/action'

const nameDummy = BASE_TITLE
const Notifications = () => {
    const history = useHistory()
    const [notification, setNotification] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [limit, setLimit] = useState(25)
    const [loading, setLoading] = useState(false)

    const getNotification = async () => {
        setLoading(true)
        await provider
            .list(url.webhookURL_notifications + `?limit=${limit}&max_age=100`)
            .then((res) => {
                const { data, meta } = res
                setNotification(data)
                setTotalData(meta.total)
                setLoading(false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
            })
    }

    const handleFinish = async (values) => {
        setLoading(true)
        await provider
            .update(`${url.webhookURL_notification}`, {
                notification_id: values?.id,
            })
            .then(async () => {
                actionNotifReq.setNotifReq(new Date().getTime())
                await getNotification()
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
            })
        // if (values.object_type === 'BOOK') {
        //     history.push('/')
        // }
        if (values.object_type === 'FEED') {
            history.push(`/timeline/detail/${values.object_id}`)
        }
        if (values.object_type === 'PERSONAL_EVENT') {
            history.push(`/detail-personal-event/${values.object_id}`)
        }
        if (values.object_type === 'TRANSACTION') {
            history.push('/donation')
        }
        // if (values.object_type === 'TRAINING') {
        //     history.push('/')
        // }
        // if (values.object_type === 'SCHOOL_JOIN') {
        //     history.push('/')
        // }
        if (
            values.object_type === 'EPUSTAKA' &&
            values.sender_type === 'EPUSTAKA'
        ) {
            history.push(`/epustaka/${values.object_id}`)
        }
        if (
            values.object_type === 'EPUSTAKA' &&
            values.sender_type === 'EDOO'
        ) {
            if (values.title?.substring(0, 20) === 'Permintaan bergabung') {
                history.push({
                    pathname: '/membership',
                    state: {
                        type: 'join',
                    },
                })
            } else {
                history.push('/membership')
            }
        }
        if (
            values.object_type === 'BOOK' &&
            values.sender_type === 'EPUSTAKA'
        ) {
            history.push({
                pathname: `/book/${values.object_id}`,
                state: {
                    epustaka_id: values?.sender,
                },
            })
        }
        if (values.object_type === 'BOOK' && values.sender_type === 'USER') {
            history.push({
                pathname: `/book/${values.object_id}`,
            })
        }
        // if (
        //     values.object_type === 'SCHOOL' &&
        //     values.sender_type === 'EDOO'
        // ) {
        //     history.push('/')
        // }
        // if (
        //     values.object_type === 'BOOK' &&
        //     values.sender_type === 'EDOO'
        // ) {
        //     history.push('/')
        // }
        if (values.object_type === 'SCHEDULE') {
            history.push(`/detail-schedule/${values.object_id}`)
        }
        // if (
        //     values.object_type === 'EXTRACURRICULAR' &&
        //     values.sender_type === 'EDOO'
        // ) {
        //     history.push('/')
        // }
        // if (
        //     values.object_type === 'SCHOOL' &&
        //     values.sender_type === 'EDOO'
        // ) {
        //     history.push('/')
        // }
    }

    useEffect(() => {
        getNotification()
        // disable warning missing dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit])

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={loading}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 25)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }

    return (
        <Row
            type="flex"
            style={{ alignItems: 'center', marginTop: '-15px' }}
            justify="center"
        >
            <Col
                span={24}
                style={{ maxWidth: LAYOUT.maxWidth }}
                className="notif"
            >
                <b>{translator.t('title.notification')}</b>
                {loading && isEmpty(notification) ? (
                    <div
                        style={{
                            textAlign: 'center',
                            padding: '24px 0px',
                        }}
                    >
                        <LoadingOutlined style={{ fontSize: '24px' }} />
                    </div>
                ) : (
                    <List className="notification">
                        <div>
                            {map(notification, (data, i) => {
                                const date = moment(data.created_at)
                                    .utc()
                                    .fromNow()
                                const avatar = data.sender.avatar_url
                                const name = data.sender.name || ''
                                const title = data.title
                                const message = data.message

                                return (
                                    <List.Item key={i}>
                                        <Row
                                            className="notification-card"
                                            onClick={() => {
                                                handleFinish(data)
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                                background: data.is_read
                                                    ? '#FFFF'
                                                    : `${COLORS.primaryColor}3D`,
                                            }}
                                        >
                                            <div className="message">
                                                <div className="avatar">
                                                    {' '}
                                                    <Avatar
                                                        src={
                                                            isEmpty(avatar)
                                                                ? notif_placeholder
                                                                : avatar
                                                        }
                                                    />
                                                    <div className="name">
                                                        {isEmpty(name)
                                                            ? nameDummy
                                                            : name}
                                                    </div>
                                                    <div className="time">
                                                        {' '}
                                                        {date}
                                                    </div>
                                                </div>
                                                <div className="titleNotification">
                                                    <div className="titleMessage">
                                                        {title}
                                                    </div>
                                                    <div
                                                        className="description"
                                                        dangerouslySetInnerHTML={{
                                                            __html: message,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Row>
                                    </List.Item>
                                )
                            })}
                        </div>
                    </List>
                )}
                <div className="loadmore-btn">
                    <DispalyButton />
                </div>
            </Col>
        </Row>
    )
}

export default Notifications
