import React, { useEffect, useState } from 'react'
import { BASE_TITLE, LAYOUT } from 'constants/constant'
import { Divider, Image, Skeleton, Button } from 'antd'
import dayjs from 'dayjs'
import translator from 'i18next'
import { LockFilled } from '@ant-design/icons'
import ModalContent from 'components/ModalContent'
import { ModalJoinEpustaka, ModalNoAccess } from 'components'
import provider from 'providers/provider'
import url from 'constants/url'
import { error } from 'utils/notification'
import { isEmpty, isNil, isUndefined } from 'lodash'
import { useHistory } from 'react-router'
import reduxState from '../../../../redux'

const NewsDetail = (props) => {
    const newsId = props?.match?.params?.uuid
    const epustakaId = props?.match?.params?.epustaka

    const { profile = {} } = reduxState.getState()
    const userId = profile?.id

    const router = useHistory()

    const [popupModal, setPopupModal] = useState(false)
    const [popupNoAccess, setPopupNoAccess] = useState(false)
    const [contentPrivate, setContentPrivate] = useState(false)
    const [epustakaAvailableData, setEpustakaAvailableData] = useState({})
    const [visibleModal, setVisibleModal] = useState(false)
    const [epustakaID, setEpustakaID] = useState(epustakaId)
    const [state, setState] = useState({
        data: {},
        loading: false,
    })

    const epustakaCollectionId = epustakaAvailableData?.epustaka_collection_id

    const getNewsDetail = async (data) => {
        const filter = epustakaId || data?.id

        setState({ ...state, loading: true })
        await provider
            .singleWebHook(
                `${url.webhookURL_news_detail}?news_id=${newsId}` +
                    `${!isUndefined(filter) ? '&epustaka_id=' + filter : ''}`
            )
            .then(async ({ data }) => {
                isNil(epustakaId) && setEpustakaID(data?.epustaka?.id)
                const notJoin =
                    (data?.epustaka?.has_join &&
                        data?.epustaka?.member_status !== 1) ||
                    !data?.epustaka?.has_join
                if (notJoin) {
                    // setPopupModal(true)
                    setContentPrivate(true)
                } else {
                    setContentPrivate(false)
                }
                setState({
                    data: data,
                    loading: false,
                })
            })
            .catch((err) => {
                setState({ ...state, loading: false })
                router.replace('/')
                error({
                    description: err.response?.data?.error,
                    placement: 'bottomRight',
                })
            })
    }

    const epustakaAvailable = async () => {
        await provider
            .singleWebHook(
                `${url.webhookURL_epustaka_available}?collection_id=${newsId}`
            )
            .then(({ data }) => {
                setEpustakaAvailableData(data)
                const notJoin = !data?.has_join && data?.member_status !== 1
                if (notJoin) {
                    // setPopupModal(true)
                    getNewsDetail(data)
                    setContentPrivate(true)
                } else {
                    // console.log(data)
                    getNewsDetail(data)
                }
            })
            .catch(async () => {
                await getNewsDetail()
                // setPopupNoAccess(true)
                setContentPrivate(true)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        isNil(epustakaId) ? epustakaAvailable() : getNewsDetail()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newsId])

    useEffect(async () => {
        if (!isEmpty(epustakaCollectionId)) {
            const attribut = {
                epustaka_collection_id: epustakaCollectionId,
                user_id: userId,
                progress: 0,
            }
            await provider.insert(
                url.webhookURL_collection_access_history,
                attribut,
                'webhook'
            )
        }
    }, [epustakaCollectionId])

    return (
        <div
            style={{
                maxWidth: LAYOUT.maxWidth,
                margin: '0px auto',
            }}
            className="news-detail-container"
        >
            <div className="news-detail-header-wrapper">
                <Skeleton
                    loading={state.loading || isEmpty(state?.data)}
                    active
                >
                    <div className="news-detail-header-wrapper-left">
                        <div className="news-detail-link">
                            {dayjs(state?.data?.created_at).format(
                                'DD MMMM YYYY'
                            )}
                        </div>
                        <h1 className="news-detail-title">
                            {state?.data?.news_title}
                        </h1>
                    </div>
                </Skeleton>

                <div className="news-detail-header-wrapper-right">
                    {state?.loading || isEmpty(state?.data) ? (
                        <Skeleton.Input
                            active
                            size={150}
                            style={{ borderRadius: 10, marginLeft: 15 }}
                        />
                    ) : (
                        <Image
                            src={state?.data?.news_thumbnail_url}
                            alt={`${BASE_TITLE} Preview Images - Platform Perpustakaan Indonesia`}
                            className="image-news-card-detail"
                            preview={false}
                        />
                    )}
                </div>
            </div>
            <span style={{ padding: '0 24px', fontSize: '0.79rem' }}>
                {state?.data?.news_author}
            </span>
            <Divider />
            <div className="news-detail-content-wrapper">
                {state?.loading || isEmpty(state?.data) ? (
                    <div>
                        <Skeleton active />
                        <Skeleton active />
                    </div>
                ) : (
                    <div>
                        <div
                            className="news-detail-description"
                            style={
                                contentPrivate
                                    ? {
                                          maxHeight: 200,
                                      }
                                    : { height: '100%' }
                            }
                            dangerouslySetInnerHTML={{
                                __html: state?.data?.news_content,
                            }}
                        />
                        {contentPrivate && (
                            <div className="load-private-content">
                                <Button
                                    type="dashed"
                                    onClick={() => setVisibleModal(true)}
                                >
                                    {translator.t('message.content_private')}
                                    <LockFilled />
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <ModalContent
                visibleModal={popupModal}
                setVisibleModal={setPopupModal}
                data={epustakaAvailableData}
                dataRefresh={epustakaAvailable}
            />
            <ModalNoAccess
                visibleModal={popupNoAccess}
                setVisibleModal={setPopupNoAccess}
                title="title.news_lowercase"
                contentPrivate={contentPrivate}
            />

            <ModalJoinEpustaka
                epustakaId={epustakaID}
                visibleModal={visibleModal}
                setVisibleModal={setVisibleModal}
                profile={profile}
                contentData={state.data}
                requestData={getNewsDetail}
            />
        </div>
    )
}

export default NewsDetail
