import React, { useEffect, useState } from 'react'
import translator from 'i18next'
import { Modal, Row, Col, Rate, Form, Input, Button } from 'antd'
import { FallbackImage } from 'components'
import get from 'lodash/get'
// import toInteger from 'lodash/toInteger'
import provider from 'providers/provider'
import url from 'constants/url'
import { COLORS } from 'constants/constant'
import { success } from 'utils/notification'

const ModalReviewBook = (props) => {
    const { visibleModal, setVisibleModal, itemBook, getDataBook } = props
    const desc = [
        'Hm',
        'Mayan Kaka',
        'Keren Kaka',
        'Luar Biasa Kaka',
        'Istimewa Kaka',
    ]
    const [form] = Form.useForm()
    // const [rating, setRating] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        itemBook?.rating_level &&
            itemBook?.rating_comment &&
            form.setFieldsValue({
                rating_level: itemBook?.rating_level,
                rating_comment: itemBook?.rating_comment,
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemBook])

    useEffect(() => {
        itemBook?.rating_comment &&
            form.setFieldsValue({
                rating_level: itemBook?.rating_level,
                rating_comment: itemBook?.rating_comment,
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemBook])

    const handleReviewEdit = async (e) => {
        const data = {
            rating_id: itemBook?.rating_id,
            rating_level: parseInt(e?.rating_level),
            rating_comment: e?.rating_comment,
        }

        await provider.put(url.webhookURL_book_rating, data).then(() => {
            setLoading(false)
            setVisibleModal()
            getDataBook()
            success({
                description: translator.t('message.successReview'),
                placement: 'bottomRight',
            })
            form.resetFields()
        })
    }

    const handleReviewInsert = async (e) => {
        setLoading(true)
        const data = {
            book_id: itemBook.id,
            rating_level: parseInt(e.rating_level),
            rating_comment: e.rating_comment,
        }
        await provider.insert(url.webhookURL_book_rating, data).then(() => {
            setLoading(false)
            getDataBook()
            setVisibleModal()
            success({
                description: translator.t('message.successReview'),
                placement: 'bottomRight',
            })
            form.resetFields()
        })
    }

    return (
        <Modal
            centered
            visible={visibleModal}
            onOk={() => setVisibleModal(!visibleModal)}
            onCancel={() => {
                form.resetFields()
                setVisibleModal(!visibleModal)
            }}
            footer={null}
            className="modal-popup"
            width={500}
        >
            <Form
                form={form}
                onFinish={
                    get(itemBook, 'has_rating', true)
                        ? handleReviewEdit
                        : handleReviewInsert
                }
            >
                <Row justify="center">
                    <Col
                        span={23}
                        style={{ textAlign: 'center', marginTop: '-1em' }}
                    >
                        <h2 className="epustaka-name">
                            {translator.t('action.giveReview')}
                        </h2>
                    </Col>
                </Row>
                <Row className="mt-md detail-book-modal">
                    <Col lg={8} md={9} sm={10}>
                        <div className="cover-image">
                            <FallbackImage
                                src={get(itemBook, 'cover_url', '')}
                                className="cover-book"
                            />
                        </div>
                    </Col>
                    <Col lg={16} md={15} sm={14} className="description-book">
                        <h3 className="book-title">
                            {get(itemBook, 'book_title', '')}
                        </h3>
                        <Form.Item
                            name="rating_level"
                            rules={[
                                {
                                    required: true,
                                    message: translator.t(
                                        'validation.required'
                                    ),
                                },
                            ]}
                        >
                            <Rate
                                tooltips={desc}
                                className="star"
                                // value={itemBook?.rating_level}
                                // onChange={(e) => setRating(e)}
                            />
                        </Form.Item>
                        <br />
                        <p
                            style={{
                                marginTop: 10,
                                color: COLORS.primaryColor,
                                fontWeight: 'bold',
                            }}
                        >
                            {translator.t('action.addReview')}
                        </p>
                    </Col>
                </Row>

                <Form.Item
                    name="rating_comment"
                    rules={[
                        {
                            required: true,
                            message: translator.t('validation.required'),
                        },
                    ]}
                >
                    <Input
                        placeholder={translator.t('action.writeReview')}
                        bordered={false}
                        style={{ marginTop: 25, marginBottom: 25 }}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        // disabled={itemBook?.has_rating === false ? true : false}
                        type="primary"
                        style={{ width: '100%' }}
                        loading={loading}
                        htmlType="submit"
                    >
                        {translator.t(
                            !get(itemBook, 'has_rating', false)
                                ? 'action.save'
                                : 'action.edit'
                        )}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ModalReviewBook
