import store from '../store'
import * as ActionType from './contant'

export default {
    setMenuActiveDonasi: (value) => {
        const action = { type: ActionType.SET_MENU_ACTIVE_DONASI, value }
        store.dispatch(action)
    },
    clearMenuActiveBook: () => {
        const action = { type: ActionType.CLEAR_MENU_ACTIVE_DONASI }
        store.dispatch(action)
    },
}
