import React, { useState, useEffect } from 'react'
import {
    Button,
    Col,
    Collapse,
    Divider,
    Image,
    Modal,
    Row,
    Skeleton,
    Typography,
} from 'antd'
import translator from 'i18next'
import notificationHandler from 'utils/notificationHandler'
import provider from 'providers/provider'
import url from 'constants/url'
import NumberFormat from 'react-number-format'
import { useHistory } from 'react-router-dom'
import { BASE_TITLE, COLORS } from 'constants/constant'
import { ic_donate, ic_status } from 'assets/icons'
import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { sumBy } from 'lodash'

const { Panel } = Collapse

const ModalSummaryPayment = (props) => {
    const {
        visible,
        setVisible,
        orderBoxId,
        dataSummaryPayment,
        methodPayment,
        fromTryout,
    } = props

    const categoryOrder = fromTryout ? 'TRYOUT' : 'DONATION'
    const router = useHistory()
    const [dataOrder, setDataOrder] = useState([])

    const [collasible, setCollasible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [serviceFeeTotal, setServiceFeeTotal] = useState(0)
    const [taxAmountTotal, setTaxAmountTotal] = useState(0)
    const [totalPayment, setTotalPayment] = useState(0)

    const getOrderBoxDetail = async () => {
        setLoading(true)

        await provider
            .singleWebHook(
                `${
                    url.webhookURL_order_box_detail
                }?order_box_id=${orderBoxId}&limit=${99999}&category=${categoryOrder}`
            )
            .then((res) => {
                const { data } = res
                const price = sumBy(data, (e) => e?.price * e?.number_of_copy)
                const discount = sumBy(data, (e) => e?.discount_amount)

                const serviceFee =
                    (dataSummaryPayment?.admin_fee?.val_percentage / 100) *
                        price +
                    dataSummaryPayment?.admin_fee?.val_currency

                const taxAmount =
                    serviceFee * (dataSummaryPayment?.tax_percentage / 100)

                const totalPayment = parseInt(
                    price - discount + serviceFee + taxAmount
                )
                setTotalPrice(price)
                setTotalDiscount(discount)
                setServiceFeeTotal(serviceFee)
                setTaxAmountTotal(taxAmount)
                setTotalPayment(totalPayment)
                setDataOrder(data)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                notificationHandler('dev2', err)

                return
            })
    }

    useEffect(() => {
        orderBoxId && visible && getOrderBoxDetail()
    }, [orderBoxId, visible])

    const handleSubmitPayment = async () => {
        setLoadingSubmit(true)
        const payloadData = {
            category: categoryOrder,
            payment_type: dataSummaryPayment?.payment_type,
        }
        await provider
            .insert(url.webhookURL_create_transaction, payloadData)
            .then((res) => {
                const { data } = res
                if (data?.invoice_url) {
                    window.location.replace(data?.invoice_url)
                }
                router.replace('/transaction')
                setVisible(false)
                setLoadingSubmit(false)
            })
            .catch((err) => {
                setLoadingSubmit(false)
                setVisible(false)
                notificationHandler('dev2', err)
            })
    }

    const displaySummaryPayment = (item) => {
        switch (item?.order_type) {
            case 'BOOK_PACKAGE':
            case 'BOOK':
                return (
                    <Row
                        style={{
                            marginBottom: 20,
                            marginLeft: -15,
                            marginTop: -15,
                        }}
                    >
                        <Col
                            span={16}
                            style={{
                                display: 'flex',
                                fontSize: '0.8rem',
                                color: '#939393',
                            }}
                        >
                            <div>
                                {`${translator.t('title.book')} (${
                                    item?.order_object?.book_title
                                }) ${item?.number_of_copy} Copy`}{' '}
                                <span>
                                    <NumberFormat
                                        value={
                                            item?.price - item?.discount_amount
                                        }
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'@ Rp'}
                                        suffix=",-"
                                    />
                                </span>
                                <span>{`${translator.t('title.purpose')} : ${
                                    item?.epustaka?.epustaka_name
                                }`}</span>
                            </div>
                        </Col>
                        <Col
                            span={8}
                            style={{
                                textAlign: 'right',
                                fontSize: '0.8rem',
                                color: '#939393',
                            }}
                        >
                            <NumberFormat
                                value={
                                    item?.number_of_copy * item?.price -
                                    item?.discount_amount
                                }
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Rp'}
                                suffix=",-"
                            />
                        </Col>
                    </Row>
                )

            case 'TRYOUT':
                return (
                    <Row
                        style={{
                            marginBottom: 20,
                            marginLeft: -15,
                            marginTop: -15,
                        }}
                    >
                        <Col
                            span={16}
                            style={{
                                display: 'flex',
                                fontSize: '0.8rem',
                                color: '#939393',
                            }}
                        >
                            <div>
                                {` ${item?.order_object?.title} ${item?.number_of_copy} Copy`}{' '}
                                <span>
                                    <NumberFormat
                                        value={item?.price}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'@ Rp'}
                                        suffix=",-"
                                    />
                                </span>{' '}
                                <span>
                                    {`${translator.t('Tipe')} : ${
                                        item?.order_object?.type
                                    }`}
                                </span>
                            </div>
                        </Col>
                        <Col
                            span={8}
                            style={{
                                textAlign: 'right',
                                fontSize: '0.8rem',
                                color: '#939393',
                            }}
                        >
                            <NumberFormat
                                value={item?.number_of_copy * item?.price}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Rp'}
                                suffix=",-"
                            />
                        </Col>
                    </Row>
                )

            default:
                break
        }
    }

    return (
        <Modal
            title={translator.t('title.summary_payment')}
            visible={visible}
            centered
            onCancel={() => setVisible(!visible)}
            className="modal-xendit-donation-wrapper"
            maskClosable={false}
            width={450}
            footer={
                <Button
                    style={{
                        margin: '10px 0',
                    }}
                    type="primary"
                    ghost
                    loading={loadingSubmit}
                    onClick={handleSubmitPayment}
                >
                    {translator.t('payment.pay_now')}
                </Button>
            }
        >
            <>
                <div className="xendit-detail-wrapper">
                    <Row align="top" className="xendit-info-top">
                        <Typography.Paragraph>
                            {translator.t('title.summary_payment_description')}
                        </Typography.Paragraph>
                    </Row>

                    <div className="xendit-info-bottom">
                        <Skeleton loading={loading}>
                            <>
                                <div className="xendit-info-bottom-list">
                                    <Image
                                        src={ic_status}
                                        alt={`${BASE_TITLE} Preview Images`}
                                        preview={false}
                                        className="info-image"
                                    />
                                    <div
                                        className="info-title"
                                        style={{
                                            marginLeft: 12,
                                            width: '100%',
                                        }}
                                    >
                                        <h4 className="info-title-label">
                                            {translator.t(
                                                'title.payment_method'
                                            )}
                                        </h4>

                                        <div className="payment-status">
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                {methodPayment ===
                                                    'bank_transfers' && (
                                                    <h4>
                                                        {translator.t(
                                                            'title.virtual_account'
                                                        )}
                                                    </h4>
                                                )}

                                                <h4
                                                    style={{
                                                        marginTop: '-5px',
                                                    }}
                                                >
                                                    {dataSummaryPayment?.display_name ||
                                                        '-'}
                                                </h4>
                                            </div>

                                            <Image
                                                src={
                                                    dataSummaryPayment?.icon_url
                                                }
                                                alt={`${BASE_TITLE} Preview Images`}
                                                preview={false}
                                                className="payment-icon"
                                                width={50}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="xendit-info-bottom-list">
                                    <Image
                                        src={ic_donate}
                                        alt={`${BASE_TITLE} Preview Images`}
                                        preview={false}
                                        className="info-image"
                                    />
                                    <div
                                        className="info-title"
                                        style={{
                                            marginLeft: 12,
                                            width: '100%',
                                        }}
                                    >
                                        <h4 className="info-title-label">
                                            {translator.t(
                                                'title.billing_information'
                                            )}
                                        </h4>

                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                marginTop: 10,
                                            }}
                                        >
                                            <h4>
                                                {translator.t(
                                                    'payment.boxordertitle'
                                                )}
                                            </h4>
                                            <h4>
                                                <NumberFormat
                                                    value={totalPayment}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'Rp'}
                                                    suffix=",-"
                                                />
                                            </h4>
                                        </div>
                                        <Divider style={{ margin: '5px 0' }} />

                                        <Collapse
                                            ghost
                                            onChange={() =>
                                                setCollasible(!collasible)
                                            }
                                        >
                                            <Panel
                                                style={{
                                                    padding: '0px',
                                                }}
                                                showArrow={false}
                                                header={
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                            userSelect: 'none',
                                                        }}
                                                    >
                                                        <div className="total-payment-label">
                                                            <h4 className="info-title-label">
                                                                {translator.t(
                                                                    'Rincian Pemesanan'
                                                                )}
                                                            </h4>
                                                        </div>
                                                    </div>
                                                }
                                                extra={
                                                    <div>
                                                        {collasible ? (
                                                            <DownOutlined
                                                                style={{
                                                                    fontSize: 13,
                                                                    color: COLORS.primaryColor,
                                                                }}
                                                            />
                                                        ) : (
                                                            <RightOutlined
                                                                style={{
                                                                    fontSize: 13,
                                                                    color: COLORS.primaryColor,
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                }
                                            >
                                                {dataOrder?.map((item, key) => {
                                                    return (
                                                        <div key={key}>
                                                            {displaySummaryPayment(
                                                                item
                                                            )}
                                                        </div>
                                                    )
                                                })}

                                                <Row
                                                    style={{
                                                        marginBottom: 20,
                                                        marginLeft: -15,
                                                        marginTop: 15,
                                                    }}
                                                >
                                                    <Col
                                                        span={16}
                                                        style={{
                                                            display: 'flex',
                                                            fontSize: '0.8rem',
                                                            color: '#939393',
                                                        }}
                                                    >
                                                        {translator.t(
                                                            'title.total_price'
                                                        )}
                                                    </Col>
                                                    <Col
                                                        span={8}
                                                        style={{
                                                            textAlign: 'right',
                                                            fontSize: '0.8rem',
                                                            color: '#939393',
                                                        }}
                                                    >
                                                        <NumberFormat
                                                            value={totalPrice}
                                                            displayType={'text'}
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            prefix={'Rp'}
                                                            suffix=",-"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row
                                                    style={{
                                                        marginBottom: 20,
                                                        marginLeft: -15,
                                                        marginTop: -15,
                                                    }}
                                                >
                                                    <Col
                                                        span={16}
                                                        style={{
                                                            display: 'flex',
                                                            fontSize: '0.8rem',
                                                            color: '#939393',
                                                        }}
                                                    >
                                                        {translator.t(
                                                            'payment.discount_amount'
                                                        )}
                                                    </Col>
                                                    <Col
                                                        span={8}
                                                        style={{
                                                            textAlign: 'right',
                                                            fontSize: '0.8rem',
                                                            color: '#939393',
                                                        }}
                                                    >
                                                        <NumberFormat
                                                            value={
                                                                totalDiscount
                                                            }
                                                            displayType={'text'}
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            prefix={'Rp'}
                                                            suffix=",-"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row
                                                    style={{
                                                        marginBottom: 20,
                                                        marginLeft: -15,
                                                        marginTop: -15,
                                                    }}
                                                >
                                                    <Col
                                                        span={16}
                                                        style={{
                                                            display: 'flex',
                                                            fontSize: '0.8rem',
                                                            color: '#939393',
                                                        }}
                                                    >
                                                        {translator.t(
                                                            'payment.serviceFee'
                                                        )}
                                                    </Col>
                                                    <Col
                                                        span={8}
                                                        style={{
                                                            textAlign: 'right',
                                                            fontSize: '0.8rem',
                                                            color: '#939393',
                                                        }}
                                                    >
                                                        <NumberFormat
                                                            value={parseInt(
                                                                serviceFeeTotal
                                                            )}
                                                            displayType={'text'}
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            prefix={'Rp'}
                                                            suffix=",-"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row
                                                    style={{
                                                        marginBottom: 20,
                                                        marginLeft: -15,
                                                        marginTop: -15,
                                                    }}
                                                >
                                                    <Col
                                                        span={16}
                                                        style={{
                                                            display: 'flex',
                                                            fontSize: '0.8rem',
                                                            color: '#939393',
                                                        }}
                                                    >
                                                        {translator.t(
                                                            'payment.taxAmount'
                                                        )}
                                                    </Col>
                                                    <Col
                                                        span={8}
                                                        style={{
                                                            textAlign: 'right',
                                                            fontSize: '0.8rem',
                                                            color: '#939393',
                                                        }}
                                                    >
                                                        <NumberFormat
                                                            value={parseInt(
                                                                taxAmountTotal
                                                            )}
                                                            displayType={'text'}
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            prefix={'Rp'}
                                                            suffix=",-"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row
                                                    style={{
                                                        marginBottom: 20,
                                                        marginLeft: -15,
                                                        marginTop: -15,
                                                    }}
                                                >
                                                    <Col
                                                        span={16}
                                                        style={{
                                                            display: 'flex',
                                                            fontSize: '0.8rem',
                                                            color: '#3c3a3a',
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        {translator.t(
                                                            'payment.bill'
                                                        )}
                                                    </Col>
                                                    <Col
                                                        span={8}
                                                        style={{
                                                            textAlign: 'right',
                                                            fontSize: '0.8rem',
                                                            color: '#3c3a3a',
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        <NumberFormat
                                                            value={totalPayment}
                                                            displayType={'text'}
                                                            thousandSeparator={
                                                                true
                                                            }
                                                            prefix={'Rp'}
                                                            suffix=",-"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Panel>
                                        </Collapse>
                                    </div>
                                </div>
                            </>
                        </Skeleton>
                    </div>
                </div>
            </>
        </Modal>
    )
}

export default ModalSummaryPayment
