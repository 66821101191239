import React from 'react'
import HistoryVideo from './HistoryVideo'

const ProfileVideo = (props) => {
    const userId = props
    return (
        <div className="pv__container">
            <HistoryVideo userId={userId} />
        </div>
    )
}

export default ProfileVideo
