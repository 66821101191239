import React from 'react'
import translator from 'i18next'
import { VideoCardCategory } from 'views/home/video/components'
import { SkeletonVideo } from 'components'
import { COLORS } from 'constants/constant'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Lazy } from 'swiper/core'
import { message } from 'antd'
import { useHistory } from 'react-router'

SwiperCore.use([Pagination, Lazy])

const VideoEpustka = (props) => {
    const history = useHistory()
    const epustakaId = props?.epustakaId
    const loading = props?.loading
    const videoData = props?.videoData
    const dataEpustaka = props?.dataEpustaka

    const handleLinkMore = () => {
        if (dataEpustaka?.epustaka_isprivate) {
            if (
                !dataEpustaka?.has_join ||
                (dataEpustaka?.has_join && dataEpustaka?.member_status !== 1)
            )
                if (dataEpustaka?.member_status === 3) {
                    message.warning(translator.t('message.notifExpand'))
                } else {
                    message.warning(translator.t('message.notif'))
                }
            else {
                history.push({
                    pathname: '/list-video-epustaka',
                    state: dataEpustaka,
                })
            }
        } else {
            history.push({
                pathname: '/list-video-epustaka',
                state: dataEpustaka,
            })
        }
    }

    return (
        <div className="pv__container">
            {loading ? (
                <SkeletonVideo />
            ) : (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: 16,
                            marginTop: 10,
                        }}
                    >
                        <p
                            style={{
                                color: 'rgb(87, 87, 87)',
                                fontWeight: 600,
                            }}
                        >
                            {translator.t('epustka.collectionVideo')}
                        </p>
                        {videoData.length > 2 && (
                            <div
                                onClick={() => handleLinkMore()}
                                style={{
                                    cursor: 'pointer',
                                    color: COLORS.primaryColor,
                                }}
                            >
                                {translator.t('action.fully')}
                            </div>
                        )}
                    </div>

                    <div className="swiper_container">
                        <Swiper
                            slidesPerView={1.2}
                            spaceBetween={10}
                            preloadImages={false}
                            lazy={true}
                            pagination={{
                                el: '.swiper-pagination',
                                clickable: true,
                            }}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },
                                360: {
                                    slidesPerView: 1,
                                },
                                411: {
                                    slidesPerView: 1,
                                },
                                420: {
                                    slidesPerView: 1,
                                },
                                540: {
                                    slidesPerView: 1.05,
                                },
                                640: {
                                    slidesPerView: 1.5,
                                },
                                768: {
                                    slidesPerView: 1.8,
                                },
                                884: {
                                    slidesPerView: 1.9,
                                },
                                1024: {
                                    slidesPerView: 1.9,
                                },
                                1087: {
                                    slidesPerView: 2.15,
                                },
                            }}
                            className="swiper_container"
                        >
                            {videoData.map((item, key) => (
                                <SwiperSlide key={key}>
                                    <VideoCardCategory
                                        data={item}
                                        epustakaId={epustakaId}
                                        dataEpustaka={dataEpustaka}
                                        source="epustakadetail"
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </>
            )}
        </div>
    )
}

export default VideoEpustka
