import React, { useState } from 'react'
import translator from 'i18next'
import './UploadImage.scss'
import get from 'lodash/get'
import map from 'lodash/map'
import { Form, Upload, Skeleton, Image } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { error } from 'utils/notification'
import { ic_attachment, ic_pict_upload } from 'assets/icons'
import getBase64 from 'utils/getBase64'
import Text from 'antd/lib/typography/Text'
import { BASE_TITLE } from 'constants/constant'

const UploadFile = (props) => {
    const {
        name,
        schema = {},
        fileList = [],
        loading = false,
        skipValidate = false,
        listType,
        type,
        formItemProps = {},
        ModalAddPersonalEvent,
        fromQuestionReport,
    } = props

    const [imageUrl, setImageUrl] = useState('')

    const file = fileList[0]
    const skeletonClassName = listType === 'picture' ? 'skeleton-image' : ''

    const handleChange = async (info) => {
        if (info.file.originFileObj) {
            const imageUrl = await getBase64(info.file.originFileObj)
            setImageUrl(imageUrl)
            return
        }

        setImageUrl('')
    }

    const beforeUpload = (file) => {
        const isTypeImage =
            file.type === 'image/jpeg' ||
            file.type === 'image/png' ||
            file.type === 'application/pdf' ||
            file.type ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        if (skipValidate) {
            return true
        }
        if (!isTypeImage) {
            error({
                description: 'Silahkan upload file JPG/PNG/PDF/DOC',
                placement: 'bottomRight',
            })
        }

        return isTypeImage
    }

    let Component = (
        <>
            {props.text ? <strong>{translator.t(props.text)}</strong> : ''}
            <Upload
                beforeUpload={beforeUpload}
                onChange={handleChange}
                {...props}
            >
                {get(file, 'url', '') || imageUrl ? (
                    <img
                        src={get(file, 'url', '') || imageUrl}
                        alt={`${BASE_TITLE} avatar images - platform perpustakaan indonesia`}
                        style={{ width: '100%' }}
                    />
                ) : (
                    <>
                        {ModalAddPersonalEvent ? (
                            <div
                                style={{
                                    color: '#bdbcbc',
                                    fontSize: 15,
                                    padding: '4px 11px',
                                    cursor: 'pointer',
                                }}
                            >
                                {fromQuestionReport
                                    ? 'Lampirkan File / Gambar'
                                    : 'Tambahkan Lampiran'}
                            </div>
                        ) : (
                            <div className="ant-upload-container">
                                {fromQuestionReport ? (
                                    <div>
                                        {loading ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <Image
                                                preview={false}
                                                style={{
                                                    width: 25,
                                                }}
                                                src={ic_pict_upload}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        {loading ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <Image
                                                preview={false}
                                                style={{
                                                    width: 25,
                                                    transform:
                                                        ' rotateZ(-90deg)',
                                                }}
                                                src={ic_attachment}
                                            />
                                        )}
                                    </div>
                                )}

                                <Text
                                    underline={
                                        fromQuestionReport ? false : true
                                    }
                                    type={type}
                                >
                                    {fromQuestionReport
                                        ? 'Lampirkan File / Gambar'
                                        : 'Lampiran Surat'}
                                </Text>
                            </div>
                        )}
                    </>
                )}
            </Upload>
        </>
    )
    let formProps = {}

    if (name && schema[name]) {
        const fieldSchema = schema[name]
        const label = props.text || fieldSchema.label

        Component = (
            <Upload
                beforeUpload={beforeUpload}
                onChange={handleChange}
                {...props}
            >
                {(get(file, 'url', '') || imageUrl) && listType !== 'text' && (
                    <img
                        src={get(file, 'url', '') || imageUrl}
                        alt={`${BASE_TITLE} avatar images - platform perpustakaan indonesia`}
                        style={{ width: '100%' }}
                    />
                )}
                {!get(file, 'url', '') && (
                    <>
                        {ModalAddPersonalEvent ? (
                            <div
                                style={{
                                    color: '#bdbcbc',
                                    fontSize: 15,
                                    padding: '4px 11px',
                                    cursor: 'pointer',
                                }}
                            >
                                {fromQuestionReport
                                    ? 'Lampirkan File / Gambar'
                                    : 'Tambahkan Lampiran'}
                            </div>
                        ) : (
                            <div className="ant-upload-container">
                                {fromQuestionReport ? (
                                    <div>
                                        {loading ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <Image
                                                preview={false}
                                                style={{
                                                    width: 25,
                                                }}
                                                src={ic_pict_upload}
                                            />
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        {loading ? (
                                            <LoadingOutlined />
                                        ) : (
                                            <Image
                                                preview={false}
                                                style={{
                                                    width: 25,
                                                    transform:
                                                        ' rotateZ(-90deg)',
                                                }}
                                                src={ic_attachment}
                                            />
                                        )}
                                    </div>
                                )}
                                <div
                                    style={{
                                        color: '#636363',
                                        textDecoration:
                                            !fromQuestionReport && 'underline',
                                    }}
                                >
                                    {fromQuestionReport
                                        ? 'Lampirkan File / Gambar'
                                        : 'Lampiran Surat'}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </Upload>
        )
        formProps = {
            name,
            label: translator.t(label),
            rules: map(fieldSchema.rules, (rule) => ({
                ...rule,
                message: translator.t(rule.message),
            })),
        }
    }

    if (loading)
        Component = <Skeleton.Input className={skeletonClassName} active />

    return (
        <Form.Item className="form-item" {...formProps} {...formItemProps}>
            {Component}
        </Form.Item>
    )
}

export default UploadFile
