import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Button, Card, Row, Tooltip } from 'antd'
import provider from 'providers/provider'
import get from 'lodash/get'
import map from 'lodash/map'
import url from 'constants/url'
import reduxState from '../../../redux'
import { FallbackImage } from 'components'
import translator from 'i18next'
import { useHistory } from 'react-router-dom'
import { error } from 'utils/notification'
import { ArrowDownOutlined, LoadingOutlined } from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'
import Meta from 'antd/lib/card/Meta'
import { BASE_TITLE, LAYOUT } from 'constants/constant'

const MoreHistoryRead = (props) => {
    const userId = props.location.state.userId
    const source = props.location.state.source
    const history = useHistory()
    const { profile = {} } = reduxState.getState()
    const id = get(profile, 'id', '')
    const defaultPagination =
        source === 'userProfil' ? `&user_id=${userId}` : `&user_id=${id}`
    const [limit, setLimit] = useState(20)
    const [totalData, setTotalData] = useState(0)
    const [bookData, setBookData] = useState([])
    const [loading, setLoading] = useState(false)

    const [stateWidth, setStateWidth] = useState({
        width: window.innerWidth,
    })

    const isSmallMobile = () => stateWidth.width <= 320
    const isMobile = () => stateWidth.width <= 360
    const isMiddle = () => stateWidth.width <= 375
    const is420dpi = () => stateWidth.width <= 420

    const updateDimensions = () => {
        setStateWidth({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getHistoryBook = async () => {
        setLoading(true)
        await provider
            .list(
                encodeURI(
                    url.webhookUrl_book_history_shelf +
                        `?limit=${limit}` +
                        defaultPagination
                )
            )
            .then((res) => {
                const { data, meta } = res
                setBookData(data)
                setTotalData(meta.total)
                setLoading(false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
                return
            })
    }

    useEffect(() => {
        getHistoryBook()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit])

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={loading}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 20)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }

    return (
        <div
            className="pb__container"
            style={{
                marginTop: '-15px',
                backgroundColor: 'transparent',
                maxWidth: LAYOUT.maxWidth,
                margin: '0 auto',
            }}
        >
            {loading ? (
                <div className="pb__container-loading">
                    <LoadingOutlined />
                </div>
            ) : (
                <>
                    <div
                        className="pb__containerLink"
                        style={{
                            maxWidth: 930,
                            margin: '0 auto',
                        }}
                    >
                        {translator.t('column.historyBook')}
                    </div>

                    <Row justify="center">
                        {map(bookData, (book, key) => {
                            const {
                                cover_url,
                                book_author,
                                book_title,
                                book_id: id,
                            } = book

                            return (
                                <div
                                    key={key}
                                    onClick={() => history.push(`/book/${id}`)}
                                    className="pb__containerGrid"
                                    style={{
                                        margin: isSmallMobile()
                                            ? '1px'
                                            : isMobile()
                                            ? '5px'
                                            : isMiddle()
                                            ? '10px'
                                            : '15px',
                                    }}
                                >
                                    <Card
                                        style={{
                                            width: is420dpi() ? 160 : 150,
                                            background: 'none',
                                            minHeight: is420dpi()
                                                ? '310px'
                                                : '280px',
                                        }}
                                        className="pb__containerGridCard"
                                        cover={
                                            <div className="container-book">
                                                <div className="book">
                                                    <div className="front">
                                                        <div className="cover">
                                                            <FallbackImage
                                                                alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                                                src={cover_url}
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/book/${id}`
                                                                    )
                                                                }
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    width: is420dpi()
                                                                        ? 153
                                                                        : 141,
                                                                    height: is420dpi()
                                                                        ? 220
                                                                        : 190,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="left-side">
                                                        <h2
                                                            style={{
                                                                height: 20,
                                                                width: is420dpi()
                                                                    ? 220
                                                                    : 190,
                                                                marginLeft: -15,
                                                                backgroundImage: `url(${cover_url})`,
                                                            }}
                                                        >
                                                            <span>
                                                                <Text
                                                                    style={{
                                                                        width: 155,
                                                                        fontSize: 10,
                                                                        color: 'white',
                                                                        paddingRight: 10,
                                                                    }}
                                                                    ellipsis={
                                                                        true
                                                                    }
                                                                >
                                                                    {
                                                                        book_author
                                                                    }
                                                                </Text>
                                                            </span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    >
                                        <Meta
                                            title={
                                                <Tooltip
                                                    title={book_title}
                                                    color="#575757"
                                                    placement="topLeft"
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: 600,
                                                        }}
                                                        onClick={() =>
                                                            history.push(
                                                                `/book/${id}`
                                                            )
                                                        }
                                                    >
                                                        {book_title}
                                                    </span>
                                                </Tooltip>
                                            }
                                            description={
                                                <Text
                                                    style={{
                                                        width: 140,
                                                        fontSize: 13,
                                                        color: 'gray',
                                                    }}
                                                    ellipsis={true}
                                                >
                                                    <Tooltip
                                                        title={book_author}
                                                        color="#575757"
                                                        placement="topLeft"
                                                    >
                                                        {book_author}
                                                    </Tooltip>
                                                </Text>
                                            }
                                        />
                                    </Card>
                                </div>
                            )
                        })}
                    </Row>
                    <div className="loadmore-btn" style={{ marginBottom: 20 }}>
                        <DispalyButton />
                    </div>
                </>
            )}
        </div>
    )
}

export default MoreHistoryRead
