import React, { useState, useRef } from 'react'
import ReactJkMusicPlayer from 'react-jinke-music-player'
import 'react-jinke-music-player/assets/index.css'
import get from 'lodash/get'
import { PlayCircleFilled, PauseCircleFilled } from '@ant-design/icons'
import { Button } from 'antd'
import translator from 'i18next'
import isEmpty from 'lodash/isEmpty'
import { ModalJoinEpustaka } from 'components'
import reduxState from '../../../../redux'

const MusicPlayer = ({
    data = {},
    handleHistoryAudio,
    setDurationAudio,
    audioProgress,
    contentPrivate,
    epustakaId,
    requestData,
}) => {
    const [play, setPlay] = useState({})
    const [onPlay, setOnPlay] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const { profile = {} } = reduxState.getState()

    const audioPlayer = useRef(null)

    const options = {
        // audio lists model
        audioLists: [
            {
                name: get(data, 'media_title', ''),
                // singer: props.track.artist,
                cover: get(data, 'media_thumbnail_url', ''),
                musicSrc: get(data, 'media_file_url', ''),
            },
        ],

        showPlayMode: false,
        // showMiniProcessBar: true,
        mode: 'full',
        showDownload: false,
        glassBg: true,
        remember: false,
        remove: false,
        autoHiddenCover: true,
        closeText: 'Close',
        autoPlay: false,
        defaultPosition: { bottom: 15, right: 15 },
        volumeFade: {
            fadeIn: 1000,
            fadeOut: 1000,
        },

        // audio play handle
        onAudioPlay() {
            setOnPlay(!onPlay)
        },

        // audio pause handle
        onAudioPause(e) {
            handleHistoryAudio(e)
            setOnPlay(!onPlay)
        },

        getAudioInstance(audio) {
            setPlay(audio)

            if (!isEmpty(audioProgress)) {
                return (audio.currentTime = audioProgress)
            }
        },

        onAudioProgress(e) {
            setDurationAudio(e?.currentTime)
        },
    }

    const renderCustomUI = () => {
        return (
            <>
                <div className="ad-btn-play">
                    <Button
                        onClick={() => {
                            if (!contentPrivate) {
                                if (play.paused === true) {
                                    return play.play()
                                }
                                if (play.paused === false) {
                                    return play.pause()
                                }
                            } else {
                                if (
                                    data?.epustaka?.has_join &&
                                    data?.epustaka?.member_status === 1
                                ) {
                                    if (play.paused === true) {
                                        return play.play()
                                    }
                                    if (play.paused === false) {
                                        return play.pause()
                                    }
                                } else {
                                    setVisibleModal(true)
                                }
                            }
                        }}
                    >
                        {play.paused === true ? (
                            <span>
                                <PlayCircleFilled />{' '}
                                {translator.t('action.play')}
                            </span>
                        ) : (
                            <span>
                                <PauseCircleFilled />{' '}
                                {translator.t('action.pause')}
                            </span>
                        )}
                    </Button>
                    {/* <button onClick={() => (play.currentTime = 40)}>
                        change current play time
                    </button> */}
                </div>
            </>
        )
    }
    return (
        <div>
            <div className="ad-btn-player">{renderCustomUI(data)}</div>
            {!contentPrivate && (
                <ReactJkMusicPlayer
                    ref={audioPlayer}
                    className="music-player"
                    {...options}
                    showMediaSession
                    clearPriorAudioLists
                />
            )}
            <ModalJoinEpustaka
                epustakaId={epustakaId}
                visibleModal={visibleModal}
                setVisibleModal={setVisibleModal}
                profile={profile}
                contentData={data}
                requestData={requestData}
            />
        </div>
    )
}

export default MusicPlayer
