import React from 'react'
import {
    Card,
    Col,
    Dropdown,
    Image,
    Menu,
    Row,
    Skeleton,
    Typography,
} from 'antd'
import { useHistory } from 'react-router'
import { BASE_TITLE } from 'constants/constant'
import formatDuration from 'utils/formatDuration'
import { ic_more } from 'assets/icons'
import { ic_placeholder_video } from 'assets/icons'
import { useState } from 'react'
import translator from 'i18next'
import { LoadingOutlined } from '@ant-design/icons'
import { ModalShare } from 'components'

const { Text } = Typography

const SearchVideo = (props) => {
    const { searchResult, empty, search, loadingData, loadMore } = props
    const history = useHistory()

    const [isModalVisibleShare, setIsModalVisibleShare] = useState(false)
    const [bookDetailData, setBookDetailData] = useState({})

    const menuAction = (item) => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClickShare(item)}
                    >
                        {translator.t('title.share')}
                    </div>
                </Menu.Item>
            </Menu>
        )
    }

    const handleClickShare = (item) => {
        setBookDetailData(item)
        setIsModalVisibleShare(true)
    }

    return (
        <div className="search-tab">
            {empty ? (
                <div style={{ textAlign: 'center' }}>
                    <div>
                        <p>.......</p>
                        <b>{translator.t('message.filter_not_matches')}</b>
                    </div>
                </div>
            ) : (
                <div>
                    <Row
                        justify="center"
                        className="title-result"
                        style={{
                            marginBottom: 10,
                            marginTop: -5,
                        }}
                    >
                        <Col span={24}>
                            {search && (
                                <span>
                                    {translator.t('column.searchVideo')}
                                    <b>{`"${search}"`}</b>
                                </span>
                            )}
                        </Col>
                    </Row>
                    <div>
                        {searchResult.map((item, i) => (
                            <Row
                                type="flex"
                                style={{
                                    alignItems: 'center',
                                }}
                                justify="center"
                                key={i}
                            >
                                <Col span={24}>
                                    <Card className="search-list-container">
                                        <Row>
                                            <Col span={22}>
                                                <div
                                                    className="search-list"
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {loadingData ? (
                                                        <Skeleton.Input
                                                            active
                                                            style={{
                                                                width: 180,
                                                                height: 100,
                                                                borderRadius: 10,
                                                            }}
                                                            size="default"
                                                        />
                                                    ) : (
                                                        <Image
                                                            className="media-cover-video"
                                                            onClick={() => {
                                                                const { id } =
                                                                    item

                                                                history.replace(
                                                                    `/video/${id}`
                                                                )
                                                            }}
                                                            src={
                                                                item.media_thumbnail_url ===
                                                                null
                                                                    ? ic_placeholder_video
                                                                    : item.media_thumbnail_url
                                                            }
                                                            alt={`${BASE_TITLE} Preview Images`}
                                                            preview={false}
                                                        />
                                                    )}

                                                    <div className="media-title">
                                                        {loadingData ? (
                                                            <Skeleton.Input
                                                                active
                                                                style={{
                                                                    width: 180,
                                                                    height: 30,
                                                                }}
                                                                size="default"
                                                            />
                                                        ) : (
                                                            <>
                                                                <Text
                                                                    className="title"
                                                                    onClick={() => {
                                                                        const {
                                                                            id,
                                                                        } = item

                                                                        history.replace(
                                                                            `/video/${id}`
                                                                        )
                                                                    }}
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                >
                                                                    {item.media_title ===
                                                                    null
                                                                        ? '-'
                                                                        : item.media_title}
                                                                </Text>

                                                                <p className="subtitle">
                                                                    {formatDuration(
                                                                        item.media_duration
                                                                    )}
                                                                </p>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col span={2}>
                                                {loadingData ? (
                                                    <LoadingOutlined />
                                                ) : (
                                                    <Dropdown
                                                        overlay={menuAction(
                                                            item
                                                        )}
                                                        trigger={['click']}
                                                        placement="bottomRight"
                                                        overlayStyle={{
                                                            position:
                                                                'absolute',
                                                            zIndex: 0,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                float: 'right',
                                                            }}
                                                        >
                                                            <img
                                                                src={ic_more}
                                                                alt={`${BASE_TITLE} preview images - platform edukasi indonesia`}
                                                            />
                                                        </div>
                                                    </Dropdown>
                                                )}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        ))}
                        {loadMore}
                    </div>
                </div>
            )}
            <ModalShare
                urlFrom="video"
                data={bookDetailData}
                visibleModal={isModalVisibleShare}
                setVisibleModal={setIsModalVisibleShare}
            />
        </div>
    )
}

export default SearchVideo
