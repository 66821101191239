import React, { useEffect, useState, useRef } from 'react'
import {
    Comment,
    Typography,
    Tooltip,
    Avatar,
    Space,
    Form,
    Button,
    Input,
    Modal,
    Rate,
    message,
    Radio,
    Row,
} from 'antd'
import {
    LoadingOutlined,
    ExclamationCircleOutlined,
    CaretDownOutlined,
} from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import url from 'constants/url'
import action from 'redux/timeLine/action'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import reduxState from '../../../../redux'
import translator from 'i18next'
import provider from 'providers/provider'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import isNull from 'lodash/isNull'
import map from 'lodash/map'
import './CommentList.scss'
import { useHistory } from 'react-router'
import { error } from 'utils/notification'
import getAcronym from 'components/Acronym'
import { COLORS } from 'constants/constant'

const { Paragraph, Text } = Typography
const { TextArea } = Input
const { confirm } = Modal

const ChildComment = ({ setTimeLine, stateTimeLine, ratingId }) => {
    const history = useHistory()
    const { profile = {} } = reduxState.getState()
    const [form] = Form.useForm()
    const senderId = get(profile, 'id', '')

    const [OpenEditComment, setOpenEditComment] = useState(false)
    const [loadMoreLoading, setLoadMoreLoading] = useState(false)

    const [reportComment, setReportComment] = useState('')
    const [loadingEditComment, setLoadingEditComment] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = React.useState(false)

    const [displayLoading, setDisplayLoading] = useState(false)
    const [limit, setLimit] = useState(10)
    const [dataDetailComment, setDataDetailComment] = useState([])
    const [editingCommentDetail, setEditingCommentDetail] = useState('')

    const dataComment = get(dataDetailComment, 'reply', [])

    const getRatingDetail = async () => {
        await provider
            .singleWebHook(
                `${
                    url.webhookURL_book_rating_detail +
                    `?limit=${limit}` +
                    `&rating_id=${ratingId}`
                }`
            )
            .then((result) => {
                const { data, meta } = result
                setDataDetailComment(data)
                setTimeLine({ refreshLike: false })
                setLoadMoreLoading(false)
                setDisplayLoading(meta.total >= meta.limit ? true : false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                return
            })
    }

    useEffect(() => {
        getRatingDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit])

    useEffect(() => {
        if (stateTimeLine.refreshLike) {
            getRatingDetail()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateTimeLine.refreshLike])

    const handleDelete = (value) => {
        confirm({
            title: (
                <span>
                    <b>{`${translator.t('feed.deleteComment')}`}</b>
                </span>
            ),
            icon: <ExclamationCircleOutlined />,
            content: `${translator.t('feed.confirmDeleteComment')}`,
            okText: `${translator.t('message.yes')}`,
            cancelText: `${translator.t('message.no')}`,
            okType: 'danger',

            onOk: async () => {
                await provider
                    .delete(
                        `${url.webhookURL_book_rating}?rating_id=${value.id}`
                    )
                    .then(() => {
                        getRatingDetail()
                        // setTimeLine({ refreshLike: true })
                        setTimeLine({ refreshLike: true, refresh: true })
                        message.success('Ulasan terhapus.')
                    })
            },
            onCancel() {},
        })
    }

    const handleOk = async (value) => {
        setConfirmLoading(true)
        return await provider
            .put(url.webhookURL_book_rating_report, {
                rating_id: `${value.id}`,
                reason: `${reportComment}`,
            })
            .then(() => {
                setTimeLine({ refreshLike: true })
                setConfirmLoading(false)
                setIsModalVisible(false)
                value.has_report === true
                    ? Modal.warning({
                          title: `${translator.t('message.warningReport')}`,
                          width: 550,
                          content: (
                              <div>
                                  <p>
                                      {translator.t(
                                          'message.message_warning_report'
                                      )}
                                  </p>
                              </div>
                          ),
                          onOk() {},
                      })
                    : Modal.info({
                          title: `${translator.t('message.successReport')}`,
                          width: 700,
                          content: (
                              <div>
                                  <p>
                                      {translator.t(
                                          'message.message_success_report'
                                      )}
                                  </p>
                              </div>
                          ),
                          onOk() {},
                      })
            })
            .catch((err) => {
                setIsModalVisible(false)
                setConfirmLoading(false)
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
        marginBottom: '10px',
    }

    const onReportComment = () => {
        setIsModalVisible(true)
    }

    const onEditCommentDetail = async (value) => {
        setLoadingEditComment(true)
        const dataSave = {
            rating_id: value.id,
            rating_level: value.rating_level,
            rating_comment: `${editingCommentDetail}`,
        }

        await provider.put(url.webhookURL_book_rating, dataSave).then(() => {
            getRatingDetail()
            setOpenEditComment(!OpenEditComment)
            setTimeLine({ refreshLike: true })
            setLoadingEditComment(false)
        })
        setEditingCommentDetail('')
    }

    const loadMoreClick = () => {
        setLoadMoreLoading(true)
        setLimit(limit + 10)
    }

    // For Action Comment Detail
    const actionsCommentDetail = (value) => [
        <span onClick={() => handleDelete(value)}>
            {senderId !== value.sender.id
                ? ''
                : `${translator.t('feed.delete')}`}
        </span>,

        <span
            onClick={() => {
                if (value.id === OpenEditComment) {
                    setOpenEditComment('')
                } else {
                    setOpenEditComment(value.id)
                }
                form.setFieldsValue({
                    commentDtl: value.rating_comment,
                })
            }}
        >
            {senderId !== value.sender.id ? '' : `${translator.t('feed.edit')}`}
        </span>,

        <span
            onClick={() => {
                onReportComment(value)
            }}
            style={{ marginLeft: -19 }}
        >
            {senderId === value.sender.id
                ? ''
                : `${translator.t('filter.report')}`}
        </span>,

        <span style={{ cursor: 'text' }}>
            {value.updated_at !== value.created_at ? (
                <Space>
                    <Text type="secondary">
                        · {translator.t('message.edited')}
                    </Text>
                </Space>
            ) : (
                ''
            )}
        </span>,
    ]

    return (
        <React.Fragment>
            {isNull(dataComment) ? (
                <LoadingOutlined
                    style={{ color: COLORS.primaryColor, fontSize: 25 }}
                />
            ) : (
                <>
                    {map(dataComment, (e, key) => {
                        const avatar = e.sender.avatar_url
                        const id = e?.sender?.id
                        const name = e?.sender?.name || ''
                        const { color, acronym } = getAcronym(name, id)

                        return (
                            <div key={key}>
                                <Comment
                                    actions={actionsCommentDetail(e)}
                                    author={
                                        <b
                                            onClick={() =>
                                                history.push(
                                                    `/profil/user/${id}`
                                                )
                                            }
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {e.sender.name}
                                        </b>
                                    }
                                    datetime={
                                        <Tooltip
                                            title={moment(e.updated_at).format(
                                                'LLLL'
                                            )}
                                        >
                                            <span>
                                                {moment(e.updated_at).fromNow()}
                                            </span>
                                        </Tooltip>
                                    }
                                    avatar={
                                        isEmpty(e.sender.avatar_url) ? (
                                            <Avatar
                                                style={{
                                                    cursor: 'pointer',
                                                    backgroundImage: color,
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                }}
                                                src={avatar}
                                                onClick={() =>
                                                    history.push(
                                                        `/profil/user/${e.sender.id}`
                                                    )
                                                }
                                            >
                                                <span>{acronym}</span>
                                            </Avatar>
                                        ) : (
                                            <Avatar
                                                size={29}
                                                src={e.sender.avatar_url}
                                                className="post-avatar"
                                                onClick={() =>
                                                    history.push(
                                                        `/profil/user/${e.sender.id}`
                                                    )
                                                }
                                            />
                                        )
                                    }
                                    content={
                                        OpenEditComment === e.id ? (
                                            <Form form={form}>
                                                <div
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    <div>
                                                        <Form.Item name="commentDtl">
                                                            <TextArea
                                                                className="textArea-edit"
                                                                style={{
                                                                    resize: 'none',
                                                                    borderBottom:
                                                                        '2px solid #ccc',
                                                                }}
                                                                bordered={false}
                                                                autoSize
                                                                value={
                                                                    editingCommentDetail
                                                                }
                                                                onChange={(
                                                                    m
                                                                ) => {
                                                                    setEditingCommentDetail(
                                                                        m.target
                                                                            .value
                                                                    )
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        textAlign: 'end',
                                                        marginTop: '5px',
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    <div className="btn-comment-edit">
                                                        <Button
                                                            style={{
                                                                marginRight: 5,
                                                                fontWeight:
                                                                    'bold',
                                                                color: '#777f8f',
                                                            }}
                                                            onClick={() => {
                                                                setOpenEditComment(
                                                                    !OpenEditComment
                                                                )
                                                            }}
                                                            type="link"
                                                        >
                                                            {translator.t(
                                                                'action.cancell'
                                                            )}
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                onEditCommentDetail(
                                                                    e
                                                                )
                                                            }}
                                                            disabled={
                                                                !editingCommentDetail
                                                            }
                                                            loading={
                                                                loadingEditComment
                                                            }
                                                            type="primary"
                                                        >
                                                            {translator.t(
                                                                'action.edit'
                                                            )}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        ) : (
                                            <Paragraph
                                                style={{
                                                    marginRight: 15,
                                                }}
                                                ellipsis={{
                                                    rows: 2,
                                                    expandable: true,
                                                    symbol: 'more',
                                                }}
                                            >
                                                {e.rating_comment}
                                            </Paragraph>
                                        )
                                    }
                                />
                                <Modal
                                    className="modal-report"
                                    title={`${translator.t(
                                        'title.report_comment'
                                    )}`}
                                    visible={isModalVisible}
                                    onOk={() => handleOk(e)}
                                    onCancel={handleCancel}
                                    okButtonProps={{ disabled: !reportComment }}
                                    confirmLoading={confirmLoading}
                                    okText={`${translator.t('action.report')}`}
                                    cancelText={`${translator.t(
                                        'action.cancell'
                                    )}`}
                                >
                                    <div className="radio">
                                        <span>
                                            <Radio.Group
                                                onChange={(e) => {
                                                    setReportComment(
                                                        e.target.value
                                                    )
                                                }}
                                            >
                                                <Radio
                                                    style={radioStyle}
                                                    value="Materi pornografi atau seksual vulgar"
                                                >
                                                    Materi pornografi atau
                                                    seksual vulgar
                                                </Radio>
                                                <Radio
                                                    style={radioStyle}
                                                    value="Perkataan yang mendorong kebencian atau
                                kekerasan"
                                                >
                                                    Perkataan yang mendorong
                                                    kebencian atau kekerasan
                                                </Radio>
                                                <Radio
                                                    style={radioStyle}
                                                    value="Konten komersial atau spam yang tidak diinginkan"
                                                >
                                                    Konten komersial atau spam
                                                    yang tidak diinginkan
                                                </Radio>
                                                <Radio
                                                    style={radioStyle}
                                                    value="Pelecehan atau bullying"
                                                >
                                                    Pelecehan atau bullying
                                                </Radio>
                                            </Radio.Group>
                                        </span>
                                    </div>
                                </Modal>
                            </div>
                        )
                    })}
                </>
            )}
            <Row style={{ display: displayLoading ? '' : 'none' }}>
                {loadMoreLoading ? (
                    <span style={{ marginLeft: 37 }}>
                        <LoadingOutlined
                            style={{ color: COLORS.primaryColor, fontSize: 25 }}
                        />
                    </span>
                ) : (
                    <div
                        className="btn-load-more"
                        onClick={loadMoreClick}
                        type="link"
                    >
                        <CaretDownOutlined />
                        {translator.t('feed.show_more_replies')}
                    </div>
                )}
            </Row>
        </React.Fragment>
    )
}

const CommentList = ({
    data,
    onLoadMore,
    getReviewData,
    setTimeLine,
    dataId,
    stateTimeLine,
}) => {
    const history = useHistory()
    const [form] = Form.useForm()
    const inputRef = useRef(null)

    const [postReplyComment, setPostReplyComment] = useState('')
    const [editingComment, setEditingComment] = useState('')
    const [visibleComment, setVisibleComment] = useState(false)
    const [OpenReply, setOpenReply] = useState(false)
    const [OpenEditComment, setOpenEditComment] = useState(false)

    const [OpenReplyComments, setOpenReplyComments] = useState(false)

    const [loadingDetailComment, setLoadingDetailComment] = useState(false)
    const [userProfil, setUserProfile] = useState([])

    const user_avatar = get(userProfil, 'avatar_url', '')
    const nameProfil = get(userProfil, 'name', '')
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '')

    const getProfile = async () => {
        await provider
            .singleWebHook(`${url.webhookURL_profile}`)
            .then((res) => {
                setUserProfile(res.data)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    useEffect(() => {
        getProfile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //To Post a reply comment in comment form
    const handleReply = async (comment) => {
        setLoadingDetailComment(true)
        const dataSave = {
            book_id: dataId,
            rating_comment: `${postReplyComment}`,
            rating_level: comment.rating_level,
            parent_id: comment.id,
        }

        await provider
            .insert(url.webhookURL_book_rating, dataSave)
            .then(() => {
                setLoadingDetailComment(false)
                setOpenReply(!OpenReply)
                setTimeLine({ refresh: true })
                form.setFieldsValue({
                    senderChildTextarea: '',
                })
            })
            .catch((err) => {
                setLoadingDetailComment(false)
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
        setPostReplyComment('')
    }

    useEffect(() => {
        if (stateTimeLine.refresh) {
            getReviewData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateTimeLine.refresh])

    // For Action Comment List
    const actions = (value) => [
        <span
            onClick={() =>
                value.id === OpenReply
                    ? setOpenReply('')
                    : setOpenReply(value.id)
            }
            key="comment-basic-reply-to"
        >{`${translator.t('feed.reply')}`}</span>,

        <span style={{ cursor: 'text' }}>
            {value.updated_at !== value.created_at ? (
                <Space>
                    <Text type="secondary">
                        · {translator.t('message.edited')}
                    </Text>
                </Space>
            ) : (
                ''
            )}
        </span>,
    ]

    return (
        <div className="comment-container">
            <InfiniteScroll
                dataLength={data.length}
                next={onLoadMore}
                style={{ overflow: 'hidden' }}
                loader={
                    <div
                        style={{
                            textAlign: 'center',
                            marginTop: 20,
                            height: 32,
                            lineHeight: '32px',
                        }}
                    >
                        <LoadingOutlined
                            style={{ color: COLORS.primaryColor, fontSize: 25 }}
                        />
                    </div>
                }
            >
                {map(data, (e, key) => {
                    const avatar = e?.sender?.avatar_url
                    const id = e?.sender?.id
                    const name = e?.sender?.name || ''
                    const { color, acronym } = getAcronym(name, id)

                    return (
                        <React.Fragment key={key}>
                            <Comment
                                key={id}
                                actions={actions(e)}
                                author={
                                    <b
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            history.push(
                                                `/profil/user/${e.sender.id}`
                                            )
                                        }
                                    >
                                        {e.sender.name}
                                    </b>
                                }
                                datetime={
                                    <div className="reviewRating">
                                        <span
                                            className="rate"
                                            style={{
                                                paddingRight: 10,
                                                paddingLeft: 5,
                                            }}
                                        >
                                            {translator.t(
                                                'message.give_rating'
                                            )}
                                        </span>
                                        <Rate
                                            style={{ fontSize: 17 }}
                                            allowHalf={true}
                                            disabled={true}
                                            value={e.rating_level}
                                        />{' '}
                                    </div>
                                }
                                avatar={
                                    isEmpty(e.sender.avatar_url) ? (
                                        <Avatar
                                            style={{
                                                cursor: 'pointer',
                                                backgroundImage: color,
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                            }}
                                            src={avatar}
                                            onClick={() =>
                                                history.push(
                                                    `/profil/user/${e.sender.id}`
                                                )
                                            }
                                        >
                                            <span>{acronym}</span>
                                        </Avatar>
                                    ) : (
                                        <Avatar
                                            src={e.sender.avatar_url}
                                            className="post-avatar"
                                            onClick={() =>
                                                history.push(
                                                    `/profil/user/${e.sender.id}`
                                                )
                                            }
                                        />
                                    )
                                }
                                content={
                                    OpenEditComment === e.id ? (
                                        <Form form={form}>
                                            <div
                                                style={{ marginRight: '10px' }}
                                            >
                                                <div>
                                                    <Form.Item name="comment">
                                                        <TextArea
                                                            style={{
                                                                resize: 'none',
                                                                borderBottom:
                                                                    '2px solid #ccc',
                                                            }}
                                                            className="textarea-edit"
                                                            bordered={false}
                                                            autoSize
                                                            value={
                                                                editingComment
                                                            }
                                                            onChange={(m) => {
                                                                setEditingComment(
                                                                    m.target
                                                                        .value
                                                                )
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    textAlign: 'end',
                                                    marginTop: '5px',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                <div className="btn-comment-edit">
                                                    <Button
                                                        style={{
                                                            marginRight: 5,
                                                            fontWeight: 'bold',
                                                            color: '#777f8f',
                                                            border: 'none',
                                                        }}
                                                        onClick={() => {
                                                            setOpenEditComment(
                                                                !OpenEditComment
                                                            )
                                                        }}
                                                        type="text"
                                                    >
                                                        {translator.t(
                                                            'action.cancell'
                                                        )}
                                                    </Button>
                                                    <Button
                                                        disabled={
                                                            !editingComment
                                                        }
                                                        type="primary"
                                                    >
                                                        {translator.t(
                                                            'action.comment'
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                    ) : (
                                        <React.Fragment>
                                            <p
                                                style={{
                                                    fontSize: 12,
                                                    color: 'grey',
                                                    marginTop: -2,
                                                    paddingBottom: 12,
                                                }}
                                            >
                                                {moment(e.updated_at).format(
                                                    'LL'
                                                )}{' '}
                                                -{' '}
                                                {moment(e.updated_at).format(
                                                    'LT'
                                                )}{' '}
                                                WIB
                                            </p>
                                            <Paragraph
                                                key={id}
                                                style={{ marginRight: 15 }}
                                                ellipsis={{
                                                    rows: 2,
                                                    expandable: true,
                                                    symbol: 'more',
                                                }}
                                            >
                                                {e.rating_comment}
                                            </Paragraph>
                                        </React.Fragment>
                                    )
                                }
                            ></Comment>

                            {/* For Reply Comment Form */}
                            <div
                                style={{
                                    marginLeft: 30,
                                }}
                            >
                                {OpenReply === e.id && (
                                    <div>
                                        <div className="messageSender-top">
                                            <Avatar
                                                className="post-avatar"
                                                style={{
                                                    cursor: 'pointer',
                                                    backgroundImage: color,
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                }}
                                                src={user_avatar}
                                            >
                                                <span>{nameProfil}</span>
                                            </Avatar>
                                            <Form
                                                form={form}
                                                style={{ width: '100%' }}
                                            >
                                                <div
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    <div>
                                                        <Form.Item name="senderChildTextarea">
                                                            <TextArea
                                                                bordered={false}
                                                                style={{
                                                                    resize: 'none',
                                                                }}
                                                                className="textArea-add"
                                                                placeholder={`${translator.t(
                                                                    'feed.addComment'
                                                                )}`}
                                                                autoSize
                                                                value={
                                                                    postReplyComment
                                                                }
                                                                onChange={(m) =>
                                                                    setPostReplyComment(
                                                                        m.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </Form.Item>
                                                        <div className="focus-border"></div>
                                                    </div>
                                                </div>

                                                <div
                                                    ref={inputRef}
                                                    style={{
                                                        textAlign: 'end',
                                                        marginTop: '5px',
                                                        marginRight: '10px',
                                                        borderTop:
                                                            '2px solid #aaa',
                                                    }}
                                                >
                                                    <div className="btn-comment-edit">
                                                        <Button
                                                            style={{
                                                                marginRight: 5,
                                                                fontWeight:
                                                                    'bold',
                                                                color: '#777f8f',
                                                                border: 'none',
                                                                marginTop:
                                                                    '10px',
                                                            }}
                                                            onClick={() => {
                                                                setVisibleComment(
                                                                    !visibleComment
                                                                )
                                                                setOpenReply(
                                                                    !OpenReply
                                                                )
                                                                form.setFieldsValue(
                                                                    {
                                                                        senderChildTextarea:
                                                                            '',
                                                                    }
                                                                )
                                                            }}
                                                            type="text"
                                                        >
                                                            {translator.t(
                                                                'action.cancell'
                                                            )}
                                                        </Button>
                                                        <Button
                                                            onClick={() =>
                                                                handleReply(e)
                                                            }
                                                            disabled={
                                                                !postReplyComment
                                                            }
                                                            loading={
                                                                loadingDetailComment
                                                            }
                                                            type="primary"
                                                        >
                                                            {translator.t(
                                                                'action.reply'
                                                            )}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* End Reply Comment Form */}

                            {/* Comment Child */}
                            {e.reply_count !== 0 && (
                                <div
                                    style={{
                                        marginLeft: 44,
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: '14px',
                                            marginBottom: 5,
                                            color: COLORS.secondaryColor,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                            e.id === OpenReplyComments
                                                ? setOpenReplyComments('')
                                                : setOpenReplyComments(e.id)
                                        }
                                    >
                                        {e.id === OpenReplyComments
                                            ? `${translator.t(
                                                  `feed.hiddenComment`
                                              )} ${
                                                  e.reply_count
                                              } ${translator.t(
                                                  `feed.replyComment`
                                              )}`
                                            : `${translator.t(
                                                  `feed.viewComment`
                                              )} ${
                                                  e.reply_count
                                              } ${translator.t(
                                                  `feed.replyComment`
                                              )}`}
                                    </p>
                                    {OpenReplyComments === e.id && (
                                        <ChildComment
                                            key={id}
                                            data={e.reply}
                                            setTimeLine={setTimeLine}
                                            ratingId={e.id}
                                            stateTimeLine={stateTimeLine}
                                            getReviewData={getReviewData}
                                        />
                                    )}
                                </div>
                            )}
                        </React.Fragment>
                    )
                })}
            </InfiniteScroll>
        </div>
    )
}

const mapStateToProps = (state) => ({
    stateTimeLine: get(state, 'timeLine', {}),
})

const mapDispatchToProps = () => ({
    setTimeLine: action.setTimeLine,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CommentList))
