import React, { useState } from 'react'
import { Button, Popover, Skeleton } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import translator from 'i18next'
import ModalJoinEpustaka from '../components/ModalJoinEpustaka'
import { ModalShare } from 'components'
import { ic_button_share, ic_pustaka_holder } from 'assets/icons'
import ModalMemberEpustka from '../components/ModalMemberEpustka'
import get from 'lodash/get'
import find from 'lodash/find'
// import { useHistory } from 'react-router-dom'
import ModalJoinEpustakaPrivat from '../components/ModalJoinEpustakaPrivat'
import { Helmet } from 'react-helmet'
import Paragraph from 'antd/lib/typography/Paragraph'
import { BASE_TITLE, COLORS } from 'constants/constant'
import { InfoCircleOutlined } from '@ant-design/icons'

const DetailEpustaka = (props) => {
    const { dataEpustaka, loadingEpustaka, getEpustaka } = props

    const epustakaId = props.id
    // const history = useHistory()
    const [popJoinEpustaka, setPopJoinEpustaka] = useState(false)
    const [popJoinEpustakaPrivat, setPopJoinEpustakaPrivat] = useState(false)
    const [popShareEpustaka, setPopShareEpustaka] = useState(false)
    const [popMember, setPopMember] = useState(false)

    const handleClickShare = () => {
        setPopShareEpustaka(true)
    }

    /* Handle Join when viewing epustaka conditions */
    const handleJoin = async (values) => {
        if (values.epustaka_isprivate === true && values.has_join === false) {
            setPopJoinEpustakaPrivat(true)
        } else if (
            values.epustaka_isprivate === true &&
            values.has_join === true
        ) {
            setPopJoinEpustakaPrivat(false)
        } else if (
            values.epustaka_isprivate === false &&
            values.has_join === true
        ) {
            setPopJoinEpustaka(false)
        } else if (
            values.epustaka_isprivate === false &&
            values.has_join === false
        ) {
            setPopJoinEpustaka(true)
        }
        if (
            values.member_status === 3 &&
            values?.epustaka_isprivate === false
        ) {
            setPopJoinEpustaka(true)
        }
        if (values.member_status === 3 && values?.epustaka_isprivate === true) {
            setPopJoinEpustakaPrivat(true)
        }
    }

    const buttonProps = [
        {
            has_join: true,
            epustaka_isprivate: true,
            member_status: 1,
            title: 'title.hasJoin',
        },
        {
            has_join: false,
            epustaka_isprivate: false,
            member_status: null,
            title: 'title.joinEpustaka',
        },
        {
            has_join: false,
            epustaka_isprivate: true,
            member_status: null,
            title: 'title.askJoin',
        },
        {
            has_join: true,
            epustaka_isprivate: false,
            member_status: 1,
            title: 'title.hasJoin',
        },
        {
            has_join: true,
            epustaka_isprivate: true,
            member_status: 0,
            title: 'title.pending_approval',
        },
        {
            has_join: true,
            epustaka_isprivate: false,
            member_status: 0,
            title: 'title.pending_approval',
        },
    ]

    const buttonTitle = find(buttonProps, {
        has_join: dataEpustaka?.has_join,
        epustaka_isprivate: dataEpustaka?.epustaka_isprivate,
        member_status: dataEpustaka?.member_status,
    })?.title

    /* Block access when viewing epustaka conditions */
    // const handleLinkMore = async (value) => {
    //     if (
    //         value?.epustaka_category === 'L' &&
    //         value?.epustaka_isprivate === true &&
    //         value?.has_join === true &&
    //         value?.member_status === 1
    //     ) {
    //         history.push({
    //             pathname: '/epustaka-book-category',
    //             state: { epustakaId, dataEpustaka },
    //         })
    //     }
    //     if (
    //         value?.epustaka_category === 'L' &&
    //         value?.epustaka_isprivate === true &&
    //         value?.has_join === false &&
    //         value?.member_status === null
    //     ) {
    //         message.warning(translator.t('message.notif'))
    //     }
    //     if (
    //         value?.epustaka_category === 'L' &&
    //         value?.epustaka_isprivate === false
    //     ) {
    //         history.push({
    //             pathname: '/epustaka-book-category',
    //             state: { epustakaId, dataEpustaka },
    //         })
    //     }
    //     if (
    //         value?.epustaka_category === 'P' &&
    //         value?.epustaka_isprivate === true &&
    //         value?.has_join === true &&
    //         value?.member_status === 0
    //     ) {
    //         message.warning(translator.t('message.notif'))
    //     }
    //     if (
    //         value?.epustaka_category === 'P' &&
    //         value?.epustaka_isprivate === false
    //     ) {
    //         history.push({
    //             pathname: '/epustaka-book-category',
    //             state: { epustakaId, dataEpustaka },
    //         })
    //     }
    // }

    return (
        <>
            <Helmet>
                <title>
                    {`${
                        dataEpustaka?.epustaka_name || ''
                    } - ${BASE_TITLE} Website`}
                </title>
                <meta
                    property="og:image"
                    content={dataEpustaka?.epustaka_logo}
                />
                <meta
                    name="og:title"
                    content={`${BASE_TITLE} Website - Platform Perpustakaan Indonesia`}
                />
                <meta
                    name="description"
                    content="Epustaka Page - Mari bergabung dan berkolaborasi mengembangkan pendidikan Indonesia bersama anak bangsa."
                />
            </Helmet>
            <div className="e__containerContent">
                <img
                    alt={`${BASE_TITLE} Epustaka - Platform Perpustakaan Indonesia`}
                    src={dataEpustaka?.epustaka_logo || ic_pustaka_holder}
                    onError={(e) => {
                        e.currentTarget.src = ic_pustaka_holder
                    }}
                />
                <div className="e__containerContentTitle">
                    {loadingEpustaka ? (
                        <Skeleton.Input
                            style={{ width: 300 }}
                            active={true}
                            size="small"
                        />
                    ) : (
                        <span style={{ fontWeight: 600, color: '#4d4c4c' }}>
                            {get(dataEpustaka, 'epustaka_name', '---')}
                        </span>
                    )}
                </div>
                <span style={{ width: 100 }}>
                    {loadingEpustaka ? (
                        <Skeleton.Input
                            style={{ width: 100 }}
                            active={true}
                            size="small"
                        />
                    ) : (
                        translator.t(
                            !get(dataEpustaka, 'epustaka_isprivate', false)
                                ? 'filter.ePublic'
                                : 'filter.ePrivate'
                        )
                    )}
                </span>

                <div className="e__containerContentCount">
                    <div
                        className="left"
                        onClick={() =>
                            dataEpustaka?.member_count > 0 && setPopMember(true)
                        }
                    >
                        <p style={{ color: COLORS.primaryColor }}>
                            {dataEpustaka?.member_count}{' '}
                            {translator.t('title.member')}
                        </p>
                    </div>
                    {dataEpustaka?.collection_count >= 1 && (
                        <>&nbsp; | &nbsp;</>
                    )}
                    {dataEpustaka?.collection_count >= 1 && (
                        <div>
                            <p className="right">
                                {dataEpustaka?.collection_count}{' '}
                                {translator.t('column.book')}{' '}
                                <Popover
                                    className="info"
                                    placement="right"
                                    content={
                                        <div>
                                            {dataEpustaka?.drm_count >= 1 && (
                                                <p style={{ marginBottom: 0 }}>
                                                    {dataEpustaka?.drm_count}{' '}
                                                    {translator.t('title.DRM')}
                                                </p>
                                            )}
                                            {dataEpustaka?.nondrm_count >=
                                                1 && (
                                                <p style={{ marginBottom: 0 }}>
                                                    {dataEpustaka?.nondrm_count}{' '}
                                                    {translator.t(
                                                        'title.noDRM'
                                                    )}
                                                </p>
                                            )}
                                            {dataEpustaka?.drm_copy_count >=
                                                1 && (
                                                <p style={{ marginBottom: 0 }}>
                                                    {
                                                        dataEpustaka?.drm_copy_count
                                                    }{' '}
                                                    {translator.t(
                                                        'title.copyDRM'
                                                    )}
                                                </p>
                                            )}
                                        </div>
                                    }
                                >
                                    <InfoCircleOutlined
                                        color={COLORS.primaryColor}
                                    />
                                </Popover>
                            </p>
                        </div>
                    )}
                </div>

                <div className="e__containerContentBtn">
                    <Button
                        onClick={() => handleJoin(dataEpustaka)}
                        className={
                            !get(dataEpustaka, 'has_join', false) ||
                            dataEpustaka?.member_status === 3
                                ? 'ant-btn'
                                : 'ant-btn-join'
                        }
                    >
                        {dataEpustaka?.member_status === 3
                            ? translator.t('title.extend')
                            : translator.t(buttonTitle)}
                    </Button>

                    <div
                        className="btn-share"
                        onClick={() => handleClickShare(dataEpustaka)}
                    >
                        <img
                            src={ic_button_share}
                            alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                        />
                    </div>
                </div>
                <div className="e__containerContentBio">
                    <Paragraph
                        ellipsis={
                            true
                                ? {
                                      rows: 2,
                                      expandable: true,
                                      symbol: (
                                          <span
                                              style={{
                                                  color: COLORS.primaryColor,
                                              }}
                                          >
                                              {translator.t('action.showMore')}
                                          </span>
                                      ),
                                  }
                                : false
                        }
                    >
                        {dataEpustaka.epustaka_about}
                    </Paragraph>
                </div>
            </div>

            <ModalMemberEpustka
                epustakaId={epustakaId}
                setVisible={setPopMember}
                visible={popMember}
            />

            <Modal
                visible={popJoinEpustaka}
                onOk={() => setPopJoinEpustaka(false)}
                onCancel={() => setPopJoinEpustaka(false)}
                setVisibleModal={setPopJoinEpustaka}
                className="mj__epustaka"
                footer={null}
                width={500}
            >
                <ModalJoinEpustaka
                    id={epustakaId}
                    visibleModal={popJoinEpustaka}
                    setVisibleModal={setPopJoinEpustaka}
                    data={dataEpustaka}
                    getEpustaka={getEpustaka}
                />
            </Modal>

            <Modal
                visible={popJoinEpustakaPrivat}
                onOk={() => setPopJoinEpustakaPrivat(false)}
                onCancel={() => setPopJoinEpustakaPrivat(false)}
                setVisibleModal={setPopJoinEpustakaPrivat}
                className="mj__epustaka"
                footer={null}
                width={500}
                centered
            >
                <ModalJoinEpustakaPrivat
                    id={epustakaId}
                    visibleModal={popJoinEpustakaPrivat}
                    setVisibleModal={setPopJoinEpustakaPrivat}
                    data={dataEpustaka}
                    getEpustaka={getEpustaka}
                />
            </Modal>

            <ModalShare
                data={dataEpustaka}
                urlFrom="epustaka"
                visibleModal={popShareEpustaka}
                setVisibleModal={setPopShareEpustaka}
            />
        </>
    )
}

export default DetailEpustaka
