import React, { useEffect, useState } from 'react'
import translator from 'i18next'
import { Button, Card } from 'antd'
import provider from 'providers/provider'
import url from 'constants/url'
import notificationHandler from 'utils/notificationHandler'
import dayjs from 'dayjs'
import {
    ArrowDownOutlined,
    FolderFilled,
    LoadingOutlined,
} from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

const Status = () => {
    const router = useHistory()
    const [orderList, setOrderList] = useState([])
    const [loading, setLoading] = useState(false)
    const [totalOrder, setTotalOrder] = useState(0)
    const [limit, setLimit] = useState(10)

    const getListOrder = async () => {
        setLoading(true)
        await provider
            .list(
                `${url.webhookURL_order_box_list}?limit=${limit}&status=ongoing&category=TRYOUT`
            )
            .then((res) => {
                const { data, meta } = res
                setTotalOrder(meta.total)
                setOrderList(data)
                setLoading(false)
            })
            .catch((err) => {
                notificationHandler('dev2', err)
                setLoading(false)
            })
    }

    useEffect(() => {
        getListOrder()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit])

    const DispalyButton = () => {
        if (totalOrder > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={loading}
                    onClick={() => {
                        totalOrder > limit && setLimit((e) => e + 10)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }

    return (
        <div className="status">
            <p>{translator.t('payment.paymentStatus')}</p>
            {loading ? (
                <div className="status-loading">
                    <LoadingOutlined />
                </div>
            ) : (
                <>
                    {isEmpty(orderList) && (
                        <div className="status-null">
                            <FolderFilled />
                            <p>{translator.t('payment.messageNodata')}</p>
                            <div>
                                {translator.t('payment.messageNodataDetail')}
                            </div>
                            <Button
                                className="chooseBtn"
                                onClick={() =>
                                    router.push({
                                        pathname: '/',
                                    })
                                }
                            >
                                {translator.t('payment.choosePackage')}
                            </Button>
                        </div>
                    )}
                    {orderList.map((item, key) => {
                        const orderBoxId = item.id
                        const totalPayment =
                            item?.amount -
                            item?.discount_amount +
                            item?.service_fee_amount +
                            item?.tax_amount
                        const formatRupiah = (money) => {
                            return new Intl.NumberFormat({
                                style: 'currency',
                                currency: 'IDR',
                                minimumFractionDigits: 0,
                            }).format(money)
                        }

                        const handleClick = (item) => {
                            if (item.payment_status === 0) {
                                router.push({
                                    pathname: '/detail-order-box',
                                    state: { orderBoxId },
                                })
                            }
                            if (item.payment_status === 1) {
                                router.push({
                                    pathname: '/detail-order-box/payment',
                                    state: { orderBoxId },
                                })
                            }
                        }

                        return (
                            <Card
                                onClick={() => handleClick(item)}
                                key={key}
                                title={
                                    item.payment_status === 0
                                        ? translator.t('payment.box')
                                        : item.payment_status === 1
                                            ? translator.t('payment.idTransaction')
                                            : ''
                                }
                            >
                                <p className="card-paystatus">
                                    {item.transaction_code === null
                                        ? ` ${item.item_count} TRYOUT`
                                        : item.transaction_code}
                                </p>
                                <div className="card-count">
                                    {item.transaction_code !== null && (
                                        <span>
                                            {translator.t('payment.idr')}{' '}
                                            {formatRupiah(totalPayment)}
                                        </span>
                                    )}
                                </div>
                                <div className="card-info">
                                    {dayjs(item.created_at).format(
                                        'D MMMM YYYY - HH:mm'
                                    )}
                                </div>
                                <div className="card-status">
                                    <div>
                                        {translator.t('payment.paymentStatus')}
                                    </div>
                                    <span>
                                        {item.payment_status === 0
                                            ? translator.t(
                                                'payment.proccessPayment'
                                            )
                                            : item.payment_status === 1
                                                ? translator.t(
                                                    'payment.waitPayment'
                                                )
                                                : ''}
                                    </span>
                                </div>
                            </Card>
                        )
                    })}
                </>
            )}
            <div className="loadmore-btn">
                <DispalyButton />
            </div>
        </div>
    )
}

export default Status
