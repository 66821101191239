import React, { useState, useEffect } from 'react'
import { Button, Tabs } from 'antd'
import translator from 'i18next'
import url from 'constants/url'
import { connect } from 'react-redux'
import get from 'lodash/get'
import action from 'redux/search/action'
import actionTimline from 'redux/timeLine/action'
import { withRouter } from 'react-router-dom'
import provider from 'providers/provider'
import ModalDataShare from './ModalDataShare'
import reduxState from '../../redux'
import { error } from 'utils/notification'
import SearchBook from './components/SearchBook'
import SearchAudio from './components/SearchAudio'
import SearchVideo from './components/SearchVideo'
import SearchUser from './components/SearchUser'
import { COLORS } from 'constants/constant'
import { isEmpty } from 'lodash'

const { TabPane } = Tabs
const Search = (props) => {
    const { stateSearch, stateTimeLine, setTimeLine } = props
    const { profile = {} } = reduxState.getState()
    const userId = get(profile, 'id', '')
    const organization_id = get(profile, 'organizations.id', '')
    // const [limitUser, setLimitUser] = useState(25)
    // const [limitUserr] = useState(1)
    const [searchResult, setSearchResult] = useState([])
    const [category, setCategory] = useState('')
    const [empty, setEmpty] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    // const [actionFollow, setActionFollow] = useState(false)

    const defaultLimit = 25
    const [totalData, setTotalData] = useState(0)
    const [offset, setOffset] = useState(0)
    const [limit, setLimit] = useState(25)
    const [loadingPaginasi, setLoadingPaginasi] = useState(false)
    const [trigger, setTrigger] = useState(false)

    const [isModalVisibleShare, setIsModalVisibleShare] = useState(false)

    const [stateFollow, setStateFollow] = useState({ loadings: [] })
    const [state, setState] = useState({
        pagination: true,
        hasMore: true,
        loading: false,
    })

    const search = stateSearch.value

    const handleFollow = async (item, index, isFollowed) => {
        setStateFollow(({ loadings }) => {
            const newLoadings = [...loadings]
            newLoadings[index] = true

            return {
                loadings: newLoadings,
            }
        })

        const data = {
            user_id: item.id,
        }

        if (isFollowed) {
            await provider
                .deleteData(`${url.webhookURL_user_follow}`, {
                    id: item.follow_id,
                })
                .then(() => {
                    setTimeLine({ refreshLike: true })
                    setStateFollow(({ loadings }) => {
                        const newLoadings = [...loadings]
                        newLoadings[index] = false

                        return {
                            loadings: newLoadings,
                        }
                    })
                })
                .catch((err) => {
                    setStateFollow(({ loadings }) => {
                        const newLoadings = [...loadings]
                        newLoadings[index] = false
                        return {
                            loadings: newLoadings,
                        }
                    })
                    error({
                        description: err.message,
                        placement: 'bottomRight',
                    })
                })
        } else {
            await provider
                .insert(url.webhookURL_user_follow, data)
                .then(() => {
                    setTimeLine({ refreshLike: true })
                    setStateFollow(({ loadings }) => {
                        const newLoadings = [...loadings]
                        newLoadings[index] = false

                        return {
                            loadings: newLoadings,
                        }
                    })
                })
                .catch((err) => {
                    setStateFollow(({ loadings }) => {
                        const newLoadings = [...loadings]
                        newLoadings[index] = false
                        return {
                            loadings: newLoadings,
                        }
                    })
                    error({
                        description: err.message,
                        placement: 'bottomRight',
                    })
                })
        }
    }

    const handleSearch = async () => {
        // !actionFollow && setLoadingData(true)

        if (category === 'books') {
            await provider
                .singleWebHook(
                    `${url.webhookURL_search_book}?limit=${limit}&offset=${offset}&q=${search}`
                )
                .then((res) => {
                    const { data, meta } = res
                    // if (meta.total === 0) {
                    //     setEmpty(true)
                    // } else {
                    //     setEmpty(false)
                    // }

                    // setSearchResult(data)
                    // setLoadingData(false)
                    setTotalData(meta?.total)
                    if (offset === 0) {
                        setSearchResult(data)
                    } else {
                        setSearchResult(searchResult?.concat(data))
                    }
                    if (meta?.total === 0) {
                        setEmpty(true)
                    } else {
                        setEmpty(false)
                    }
                    setLoadingData(false)
                    setLoadingPaginasi(false)
                })
                .catch(() => {
                    error({
                        description: translator.t('message.connectionError'),
                        placement: 'bottomRight',
                    })
                })
        } else if (category === 'video' || category === 'audio') {
            await provider
                .singleWebHook(
                    `${url.webhookURL_search_multimedia}?q=${search}&type=${category}&limit=${limit}&offset=${offset}`
                )
                .then((res) => {
                    const { data, meta } = res
                    if (meta.total === 0) {
                        setEmpty(true)
                    } else {
                        setEmpty(false)
                    }

                    setSearchResult(data)
                    setLoadingData(false)
                })
                .catch(() => {
                    error({
                        description: translator.t('message.connectionError'),
                        placement: 'bottomRight',
                    })
                })
        } else if (category === 'user') {
            await provider
                .list(
                    `${url.webhookURL_search_user}?&q=${search}&limit=${limit}&offset=${offset}`
                )
                .then((res) => {
                    const { data, meta } = res

                    setSearchResult(data)

                    if (meta.total === 0) {
                        setEmpty(true)
                    } else {
                        setEmpty(false)
                    }

                    setState({
                        ...state,
                        loading: false,
                    })

                    if (meta.limit >= meta.total) {
                        setState({
                            pagination: false,
                            hasMore: false,
                            loading: false,
                        })
                    }

                    // setTimeLine({ refresh: false })
                    setLoadingData(false)
                    // setActionFollow(false)
                })
                .catch(() => {
                    error({
                        description: translator.t('message.connectionError'),
                        placement: 'bottomRight',
                    })
                })
        }
    }

    const getRefreshUser = async () => {
        await provider
            .list(
                `${url.webhookURL_search_user}?&q=${search}&limit=${limit}&offset=${offset}`
            )
            .then((results) => {
                const { data } = results
                setSearchResult(data)
                setTimeLine({ refreshLike: false })
            })
            .catch(() => {
                error({
                    description: translator.t('message.connectionError'),
                    placement: 'bottomRight',
                })
            })
    }

    useEffect(() => {
        setCategory('books')
    }, [])

    useEffect(() => {
        if (stateTimeLine.refreshLike) {
            getRefreshUser()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateTimeLine.refreshLike])

    useEffect(() => {
        if (!isEmpty(search) && category) {
            setLimit(defaultLimit)
            setOffset(0)
            setSearchResult([])
            setTotalData(0)
            setTrigger(!trigger)
        } else {
            if (!isEmpty(search) && !category) {
                setCategory('books')
            } else {
                setTotalData(0)
                setSearchResult([])
            }
        }
    }, [search])

    useEffect(() => {
        category && !isEmpty(search) && handleSearch()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category, trigger])

    // For React Infinite scroll
    const fetchMoreData = async () => {
        // setLimitUser(limitUser + 10)
        // setState({
        //     ...state,
        //     loading: true,
        // })
        setLoadingPaginasi(true)
        setOffset(offset + defaultLimit)
        if (limit !== defaultLimit) {
            setOffset(limit)
            setLimit(defaultLimit)
        } else {
            setOffset(offset + defaultLimit)
        }
        setTrigger(!trigger)
    }
    const loadMore =
        !loadingData && totalData > searchResult?.length ? (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    margin: '20px 0',
                }}
            >
                <Button
                    loading={loadingPaginasi}
                    style={{
                        padding: '1rem 3rem',
                        backgroundColor: COLORS.primaryColor,
                        borderRadius: '15px',
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={fetchMoreData}
                >
                    {translator.t('action.fully')}
                </Button>
            </div>
        ) : null

    return (
        <div className="search-container">
            <div className="home-container">
                <Tabs
                    defaultActiveKey="books"
                    onChange={(key) => {
                        setLimit(defaultLimit)
                        setOffset(0)
                        setSearchResult([])
                        setTotalData(0)
                        setCategory(key)
                    }}
                    type="card"
                    centered
                    style={{ padding: '0 10px' }}
                >
                    <TabPane tab={translator.t('menu.book')} key="books">
                        <SearchBook
                            searchResult={searchResult}
                            empty={empty}
                            search={search}
                            loadingData={loadingData}
                            profile={profile}
                            organization_id={organization_id}
                            loadMore={loadMore}
                        />
                    </TabPane>
                    <TabPane tab={translator.t('menu.audio')} key="audio">
                        <SearchAudio
                            searchResult={searchResult}
                            empty={empty}
                            search={search}
                            loadingData={loadingData}
                            loadMore={loadMore}
                        />
                    </TabPane>
                    <TabPane tab={translator.t('menu.video')} key="video">
                        <SearchVideo
                            searchResult={searchResult}
                            empty={empty}
                            search={search}
                            loadingData={loadingData}
                            loadMore={loadMore}
                        />
                    </TabPane>
                    <TabPane tab={translator.t('menu.user')} key="user">
                        <SearchUser
                            empty={empty}
                            search={search}
                            fetchMoreData={fetchMoreData}
                            state={searchResult}
                            searchResult={searchResult}
                            loadingData={loadingData}
                            userId={userId}
                            // setActionFollow={setActionFollow}
                            handleFollow={handleFollow}
                            stateFollow={stateFollow}
                            loadMore={loadMore}
                        />
                    </TabPane>
                </Tabs>
            </div>

            {searchResult.map((data, id) => (
                <ModalDataShare
                    data={data}
                    key={id}
                    visibleModal={isModalVisibleShare}
                    setVisibleModal={setIsModalVisibleShare}
                />
            ))}
        </div>
    )
}

const mapStateToProps = (state) => ({
    stateSearch: get(state, 'search', {}),
    stateTimeLine: get(state, 'timeLine', {}),
})

const mapDispatchToProps = () => ({
    setSearch: action.setSearch,
    setTimeLine: actionTimline.setTimeLine,
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Search))
