import React, { useState, useEffect } from 'react'
import Logo from 'assets/icons/ic_shelf@1x.svg'
import translator from 'i18next'
import { Tabs, Empty } from 'antd'
import reduxState from '../../../redux'
import { connect } from 'react-redux'
import tabKeyAction from 'redux/epustakaTabActive/action'
import BorrowBook from '../borrow/book'
// import DonationShelf from '../donation'
import BorrowVideo from '../borrow/video'
import BorrowAudio from '../borrow/audio'
import NewsHistory from '../history/article'
import Queue from '../queue'
import History from '../history'
import Review from '../review'
import { BASE_TITLE } from 'constants/constant'
import provider from 'providers/provider'
import url from 'constants/url'
import { isEmpty } from 'lodash'
import { ic_illus_empty_shelf_content } from 'assets/icons'

const { TabPane } = Tabs

const mapStateToProps = (state) => ({
    tabActive: state.tabActive,
})

const HeaderBookself = (props) => {
    const { tabActive } = props

    const { profile = {} } = reduxState.getState()
    const id = profile?.id
    const [audioData, setAudioData] = useState([])
    const [videoData, setVideoData] = useState([])
    const [bookData, setBookData] = useState([])
    const [newsData, setNewsData] = useState([])

    const callback = (key) => {
        const selectedKey = key.split('bookself-')
        tabKeyAction.setTabActive(selectedKey[1])
    }

    const getAudioData = async () => {
        const defaultPagination = `?type=audio&user_id=${id}`
        await provider
            .list(url.webhookURL_multimedia_history_list + defaultPagination)
            .then((res) => {
                const { data } = res
                setAudioData(data)
                // console.log(data)
            })
    }

    useEffect(() => {
        getAudioData()
    }, [])

    const getVideoData = async () => {
        const defaultPagination = `?type=video&user_id=${id}`
        await provider
            .list(url.webhookURL_multimedia_history_list + defaultPagination)
            .then((res) => {
                const { data } = res
                setVideoData(data)
                // console.log(data)
            })
    }

    useEffect(() => {
        getVideoData()
    }, [])

    const getNewsHistory = async () => {
        await provider
            .list(url.webhookURL_news_history_list + `?user_id=${id}`)
            .then((res) => {
                const { data } = res
                setNewsData(data)
                // console.log(data)
            })
    }

    useEffect(() => {
        getNewsHistory()
    }, [])

    const getBookData = async () => {
        await provider
            .list(url.webhookUrl_book_history_shelf + `?user_id=${id}`)
            .then((res) => {
                const { data } = res
                setBookData(data)
                // console.log(data)
            })
    }

    useEffect(() => {
        getBookData()
    }, [])

    return (
        <>
            <div className="bs__containerHeader">
                <img
                    src={Logo}
                    alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                />
                <div className="bs__containerHeaderTitle">
                    {translator.t('auth.bookshelf')}
                </div>
                <div className="bs__containerHeaderSubt">
                    {translator.t('message.activity')}
                </div>
            </div>
            <Tabs
                defaultActiveKey={`bookself-${tabActive}`}
                onChange={callback}
                centered
            >
                <TabPane tab={translator.t('action.borrow')} key="bookself-1">
                    <BorrowBook />
                </TabPane>
                {/* <TabPane tab={translator.t('Donasi')} key="bookself-2">
                    <DonationShelf />
                </TabPane> */}
                <TabPane tab={translator.t('action.queue')} key="bookself-3">
                    <Queue />
                </TabPane>
                <TabPane tab={translator.t('action.history')} key="bookself-4">
                    {isEmpty(bookData) &&
                    isEmpty(videoData) &&
                    isEmpty(audioData) &&
                    isEmpty(newsData) ? (
                        <div>
                            <Empty
                                image={ic_illus_empty_shelf_content}
                                style={{ padding: 20 }}
                                description={
                                    <div>
                                        <div
                                            style={{
                                                fontWeight: 500,
                                                fontSize: '1rem',
                                                color: 'rgb(74 74 74)',
                                            }}
                                        >
                                            {translator.t(
                                                'bookshelf.empty_book_history_title'
                                            )}
                                        </div>
                                        <div>
                                            {translator.t(
                                                'bookshelf.empty_book_history_desc'
                                            )}
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    ) : (
                        <>
                            {!isEmpty(bookData) && <History />}
                            {!isEmpty(videoData) && (
                                <BorrowVideo
                                    videoData={videoData}
                                    getMedia={getVideoData}
                                />
                            )}
                            {!isEmpty(audioData) && (
                                <BorrowAudio
                                    audioData={audioData}
                                    getMedia={getAudioData}
                                />
                            )}
                            {!isEmpty(newsData) && (
                                <NewsHistory
                                    data={newsData}
                                    getMedia={getNewsHistory}
                                    userId={id}
                                />
                            )}
                        </>
                    )}
                </TabPane>

                <TabPane tab={translator.t('filter.review')} key="bookself-5">
                    <Review />
                </TabPane>
            </Tabs>
        </>
    )
}

export default connect(mapStateToProps)(HeaderBookself)
