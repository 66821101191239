import React, { useState, useEffect } from 'react'
import { BASE_TITLE, COLORS, LAYOUT } from 'constants/constant'
import { Image, List, Button, Skeleton, Divider, Typography, Input } from 'antd'
import { Link } from 'react-router-dom'
import translator from 'i18next'
import reduxState from '../../../../redux'
import isEmpty from 'lodash/isEmpty'
import provider from 'providers/provider'
import url from 'constants/url'
import ModalReturnBook from 'views/bookshelf/components/ModalReturnBook'
import { DeleteOutlined } from '@ant-design/icons'
import { ic_illus_empty_shelf_content } from 'assets/icons'

const { Search } = Input
const { Paragraph } = Typography
const MoreNewsHistory = (props) => {
    const { profile = {} } = reduxState.getState()
    const userId = props?.location?.state

    const [loadingNews, setLoadingNews] = useState(false)
    const [searchNews, setSearchNews] = useState('')
    const [limit, setLimit] = useState(25)
    const [newsList, setNewsList] = useState([])
    const [totalNews, setTotalNews] = useState(0)
    const [visibleDelete, setVisibleDelete] = useState(false)
    const [mediaItem, setMediaItem] = useState({})

    const handleClickDelete = (value) => {
        setMediaItem(value)
        setVisibleDelete(true)
    }

    const loadMore = () => {
        if (totalNews > limit) {
            return (
                <div
                    style={{
                        textAlign: 'center',
                        padding: '12px 0',
                        lineHeight: '32px',
                    }}
                >
                    <Button
                        loading={loadingNews}
                        onClick={() => {
                            totalNews > limit && setLimit((e) => e + 5)
                        }}
                    >
                        {translator.t('action.more')}
                    </Button>
                </div>
            )
        } else {
            return null
        }
    }

    const getNewsList = async () => {
        setLoadingNews(true)

        const search = !isEmpty(searchNews) ? `&q=${searchNews}` : null

        const urls = encodeURI(
            url.webhookURL_news_history_list +
                '?limit=' +
                limit +
                `&user_id=${profile?.id}` +
                (!isEmpty(search) ? search : '')
        )

        await provider.list(urls).then((res) => {
            const { data, meta } = res

            setNewsList(data)
            setTotalNews(meta.total)
            setLoadingNews(false)
        })
    }

    useEffect(() => {
        getNewsList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, searchNews])

    return (
        <div
            style={{
                maxWidth: LAYOUT.maxWidth,
                margin: '0px auto',
            }}
            className="news-list-container"
        >
            <div
                style={{
                    position: 'fixed',
                    top: '64px',
                    left: '0px',
                    right: '0px',
                    zIndex: 1,
                    padding: '20px 5px',
                    backgroundColor: '#f0f2f5',
                    userSelect: 'none',
                    maxWidth: LAYOUT.maxWidth,
                    margin: '0 auto',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Search
                    placeholder={translator.t('action.find')}
                    enterButton={translator.t('action.find')}
                    size="middle"
                    onSearch={(e) => setSearchNews(e)}
                    // loading
                    allowClear
                />
            </div>

            <div
                className="news-content-wrapper"
                style={{ backgroundColor: !isEmpty(newsList) && 'white' }}
            >
                {loadingNews ? (
                    <div
                        style={{
                            padding: '10px 24px',
                            backgroundColor: 'white',
                            borderRadius: 10,
                        }}
                    >
                        <Skeleton active />
                        <Divider />
                        <Skeleton active />
                        <Divider />
                        <Skeleton active />
                    </div>
                ) : (
                    <>
                        {isEmpty(newsList) ? (
                            <div className="news-search-not-found">
                                <img src={ic_illus_empty_shelf_content} />
                                <h1>
                                    {translator.t(
                                        'notification.item_doesnt_exist'
                                    )}
                                </h1>

                                {/* <h3>
                                    {translator.t('message.filter_not_matches')}
                                </h3>
                                <Button
                                    type="primary"
                                    className="btn-edit-filter"
                                    onClick={() => {
                                        getNewsList()
                                    }}
                                >
                                    {translator.t('action.change_filter')}
                                </Button> */}
                            </div>
                        ) : (
                            <List
                                dataSource={newsList}
                                loadMore={loadMore()}
                                renderItem={(item) => {
                                    return (
                                        <List.Item key={item?.id}>
                                            <Skeleton
                                                title={false}
                                                loading={loadingNews}
                                                active
                                            >
                                                <List.Item.Meta
                                                    avatar={null}
                                                    title={
                                                        <Link
                                                            to={{
                                                                pathname: `/article/${item?.collection_id}/${item.epustaka_id}`,
                                                            }}
                                                        >
                                                            <Paragraph
                                                                ellipsis={
                                                                    true
                                                                        ? {
                                                                              rows: 2,
                                                                              expandable: false,
                                                                          }
                                                                        : false
                                                                }
                                                            >
                                                                {
                                                                    item?.news_title
                                                                }
                                                            </Paragraph>
                                                        </Link>
                                                    }
                                                    description={
                                                        item?.news_author || '-'
                                                    }
                                                />

                                                <Link
                                                    to={{
                                                        pathname: `/article/${item?.collection_id}/${item.epustaka_id}`,
                                                    }}
                                                >
                                                    <Image
                                                        src={
                                                            item?.news_thumbnail_url
                                                        }
                                                        alt={`${BASE_TITLE} Preview Images - Platform Perpustakaan Indonesia`}
                                                        width={150}
                                                        className="image-news-card"
                                                        preview={false}
                                                    />
                                                </Link>
                                                {userId === profile?.id && (
                                                    <DeleteOutlined
                                                        style={{
                                                            color: COLORS.basegrey,
                                                            width: '5%',
                                                            fontSize: 16,
                                                        }}
                                                        onClick={() => {
                                                            handleClickDelete(
                                                                item
                                                            )
                                                        }}
                                                    />
                                                )}
                                            </Skeleton>
                                        </List.Item>
                                    )
                                }}
                            />
                        )}
                    </>
                )}
            </div>

            <ModalReturnBook
                visibleModal={visibleDelete}
                setVisibleModal={setVisibleDelete}
                mediaData={mediaItem}
                getMedia={getNewsList}
                frombookself="frombookself"
            />
        </div>
    )
}

export default MoreNewsHistory
