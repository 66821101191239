import React, { useEffect, useState, useRef } from 'react'
import {
    Comment,
    Typography,
    Tooltip,
    Avatar,
    Dropdown,
    Space,
    Form,
    Button,
    Input,
    Menu,
    Modal,
    Row,
} from 'antd'
import {
    LoadingOutlined,
    ExclamationCircleOutlined,
    CaretDownOutlined,
} from '@ant-design/icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import url from 'constants/url'
import { error } from 'utils/notification'
import action from 'redux/timeLine/action'
import { connect } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'
import translator from 'i18next'
import provider from 'providers/provider'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import isNull from 'lodash/isNull'
import map from 'lodash/map'
import { ic_more } from 'assets/icons'
import getAcronym from 'components/Acronym'
import { BASE_TITLE, COLORS } from 'constants/constant'

const { Paragraph, Text } = Typography
const { TextArea } = Input
const { confirm } = Modal

const ChildComment = ({
    setTimeLine,
    // dataId,
    commentId,
    stateTimeLine,
    onReportComment,
    epustaka_id,
    fromEpustakaDetail,
}) => {
    const history = useHistory()
    const [form] = Form.useForm()

    const [OpenEditComment, setOpenEditComment] = useState(false)
    const [loadMoreLoading, setLoadMoreLoading] = useState(false)

    const [displayLoading, setDisplayLoading] = useState(false)
    const [limit, setLimit] = useState(10)
    const [dataDetailComment, setDataDetailComment] = useState([])
    const [editingCommentDetail, setEditingCommentDetail] = useState('')

    const dataComment = get(dataDetailComment, 'reply', [])

    const [userProfil, setUserProfile] = useState([])

    const userId = get(userProfil, 'id', '')

    const getProfile = async () => {
        await provider
            .singleWebHook(`${url.webhookURL_profile}`)
            .then((res) => {
                setUserProfile(res.data)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    useEffect(() => {
        getProfile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCommentListDetail = async () => {
        await provider
            .singleWebHook(
                `${
                    url.webhookURL_comment_detail +
                    `?limit=${limit}` +
                    `&comment_id=${commentId}`
                }`
            )
            .then((result) => {
                const { data, meta } = result
                setDataDetailComment(data)
                setTimeLine({ refreshLike: false })
                setLoadMoreLoading(false)
                setDisplayLoading(meta.total >= meta.limit ? true : false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                return
            })
    }

    useEffect(() => {
        getCommentListDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit])

    useEffect(() => {
        if (stateTimeLine.refreshLike) {
            getCommentListDetail()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateTimeLine.refreshLike])

    const handleDelete = (value) => {
        confirm({
            title: `${translator.t('feed.confirmDeleteComment')}`,
            icon: <ExclamationCircleOutlined />,
            onOk: async () => {
                await provider
                    .deleteData(`${url.webhookURL_comment}`, { id: value.id })
                    .then(() => {
                        setTimeLine({ refreshLike: true, refresh: true })
                        // message.success('Ulasan terhapus.')
                    })
                    .catch((err) => {
                        error({
                            description: err.mesagge,
                            placement: 'bottomRight',
                        })
                    })
            },
            onCancel() {},
        })
    }

    const onEditCommentDetail = async (value) => {
        // TODO COMMENT EPUSTAKA
        // object_type: 'FEED',
        // object_id: `${dataId}`,
        // comment: `${editingCommentDetail}`,
        // sender_id: fromEpustakaDetail ? epustaka_id : userId,
        // sender_type: fromEpustakaDetail ? 'EPUSTAKA' : 'USER',
        const data = {
            id: value.id,
            comment: `${editingCommentDetail}`,
        }

        await provider.put(`${url.webhookURL_comment}`, data).then(() => {
            setTimeLine({ refreshLike: true })
        })
        setEditingCommentDetail('')
    }

    const loadMoreClick = () => {
        setLoadMoreLoading(true)
        setLimit(limit + 10)
    }

    // For Action Comment Detail
    const actionsCommentDetail = (value) => [
        <span onClick={() => handleDelete(value)}>
            {userId !== value.sender.id ? '' : `${translator.t('feed.delete')}`}
        </span>,

        <span
            onClick={() => {
                if (value.id === OpenEditComment) {
                    setOpenEditComment('')
                } else {
                    setOpenEditComment(value.id)
                    form.setFieldsValue({
                        commentDtl: value.comment,
                    })
                }
            }}
        >
            {userId !== value.sender.id ? '' : `${translator.t('feed.edit')}`}
        </span>,

        <span
            onClick={() => {
                onReportComment(value)
            }}
            style={{ marginLeft: -19 }}
        >
            {userId === value.sender.id
                ? ''
                : `${translator.t('filter.report')}`}
        </span>,

        <span style={{ cursor: 'text' }}>
            {value.updated_at !== value.created_at ? (
                <Space>
                    <Text type="secondary">
                        · {translator.t('message.edited')}
                    </Text>
                </Space>
            ) : (
                ''
            )}
        </span>,
    ]

    const actionsCommentDetailEpustaka = (value) => [
        <span onClick={() => handleDelete(value)}>
            {epustaka_id !== value.sender.id
                ? ''
                : `${translator.t('feed.delete')}`}
        </span>,

        <span
            onClick={() => {
                if (value.id === OpenEditComment) {
                    setOpenEditComment('')
                } else {
                    setOpenEditComment(value.id)
                    form.setFieldsValue({
                        commentDtl: value.comment,
                    })
                }
            }}
        >
            {epustaka_id !== value.sender.id
                ? ''
                : `${translator.t('feed.edit')}`}
        </span>,

        <span
            onClick={() => {
                onReportComment(value)
            }}
            style={{ marginLeft: -19 }}
        >
            {epustaka_id === value.sender.id
                ? ''
                : `${translator.t('filter.report')}`}
        </span>,

        <span style={{ cursor: 'text' }}>
            {value.updated_at !== value.created_at ? (
                <Space>
                    <Text type="secondary">
                        · {translator.t('message.edited')}
                    </Text>
                </Space>
            ) : (
                ''
            )}
        </span>,
    ]

    return (
        <React.Fragment>
            {isNull(dataComment) ? (
                <LoadingOutlined
                    style={{ color: COLORS.primaryColor, fontSize: 25 }}
                />
            ) : (
                <>
                    {map(dataComment, (e, key) => {
                        const senderType = get(e, 'sender_type', '')
                        const avatar = get(e, 'sender.avatar_url', '')
                        const senderId = get(e, 'sender.id', '')

                        const name = e?.sender?.name || ''
                        const id = e?.sender?.id || null
                        const { color, acronym } = getAcronym(name, id)

                        return (
                            <div key={key}>
                                <Comment
                                    actions={
                                        fromEpustakaDetail
                                            ? actionsCommentDetailEpustaka(e)
                                            : actionsCommentDetail(e)
                                    }
                                    author={
                                        <b
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                if (senderType === 'USER') {
                                                    history.push(
                                                        `/profil/user/${senderId}`
                                                    )
                                                } else if (
                                                    senderType === 'EPUSTAKA'
                                                ) {
                                                    history.push(
                                                        `/epustaka/${senderId}`
                                                    )
                                                }
                                            }}
                                        >
                                            {get(e, 'sender.name', '')}
                                        </b>
                                    }
                                    datetime={
                                        <Tooltip
                                            title={moment(e.updated_at).format(
                                                'LLLL'
                                            )}
                                        >
                                            <span>
                                                {moment(e.updated_at).fromNow()}
                                            </span>
                                        </Tooltip>
                                    }
                                    avatar={
                                        isEmpty(e.sender.avatar_url) ? (
                                            <Avatar
                                                style={{
                                                    cursor: 'pointer',
                                                    backgroundImage: color,
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                }}
                                                src={avatar}
                                                onClick={() => {
                                                    if (senderType === 'USER') {
                                                        history.push(
                                                            `/profil/user/${senderId}`
                                                        )
                                                    } else if (
                                                        senderType ===
                                                        'EPUSTAKA'
                                                    ) {
                                                        history.push(
                                                            `/epustaka/${senderId}`
                                                        )
                                                    }
                                                }}
                                            >
                                                <span>{acronym}</span>
                                            </Avatar>
                                        ) : (
                                            <Avatar
                                                src={e.sender.avatar_url}
                                                className="post-avatar"
                                                onClick={() => {
                                                    if (senderType === 'USER') {
                                                        history.push(
                                                            `/profil/user/${senderId}`
                                                        )
                                                    } else if (
                                                        senderType ===
                                                        'EPUSTAKA'
                                                    ) {
                                                        history.push(
                                                            `/epustaka/${senderId}`
                                                        )
                                                    }
                                                }}
                                            />
                                        )
                                    }
                                    content={
                                        OpenEditComment === e.id ? (
                                            <Form form={form}>
                                                <div
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    <div>
                                                        <Form.Item name="commentDtl">
                                                            <TextArea
                                                                className="textArea-edit"
                                                                bordered={false}
                                                                autoSize
                                                                value={
                                                                    editingCommentDetail
                                                                }
                                                                onChange={(
                                                                    m
                                                                ) => {
                                                                    setEditingCommentDetail(
                                                                        m.target
                                                                            .value
                                                                    )
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        textAlign: 'end',
                                                        marginTop: '5px',
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    <div className="btn-comment-edit">
                                                        <Button
                                                            style={{
                                                                marginRight: 5,
                                                                fontWeight:
                                                                    'bold',
                                                                color: '#777f8f',
                                                            }}
                                                            onClick={() => {
                                                                setOpenEditComment(
                                                                    !OpenEditComment
                                                                )
                                                            }}
                                                            type="link"
                                                        >
                                                            {translator.t(
                                                                'action.cancell'
                                                            )}
                                                        </Button>
                                                        <Button
                                                            onClick={() => {
                                                                onEditCommentDetail(
                                                                    e
                                                                )
                                                                setOpenEditComment(
                                                                    !OpenEditComment
                                                                )
                                                            }}
                                                            disabled={
                                                                !editingCommentDetail
                                                            }
                                                            type="primary"
                                                        >
                                                            {translator.t(
                                                                'action.edit'
                                                            )}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        ) : (
                                            <Paragraph
                                                style={{
                                                    marginRight: 15,
                                                }}
                                                ellipsis={{
                                                    rows: 2,
                                                    expandable: true,
                                                    symbol: 'more',
                                                }}
                                            >
                                                {e.comment}
                                            </Paragraph>
                                        )
                                    }
                                />
                            </div>
                        )
                    })}
                </>
            )}
            <Row style={{ display: displayLoading ? '' : 'none' }}>
                {loadMoreLoading ? (
                    <span style={{ marginLeft: 37 }}>
                        <LoadingOutlined
                            style={{ color: COLORS.primaryColor, fontSize: 25 }}
                        />
                    </span>
                ) : (
                    <div
                        className="btn-load-more"
                        onClick={loadMoreClick}
                        type="link"
                    >
                        <CaretDownOutlined />
                        {translator.t('feed.show_more_replies')}
                    </div>
                )}
            </Row>
        </React.Fragment>
    )
}

const CommentList = ({
    data,
    onLoadMore,
    paginationComment,
    onReportComment,
    getCommentList,
    setTimeLine,
    dataId,
    stateTimeLine,
    dataEpustaka,
    fromEpustakaDetail,
}) => {
    const history = useHistory()
    const [form] = Form.useForm()
    const inputRef = useRef(null)

    const [postReplyComment, setPostReplyComment] = useState('')
    const [editingComment, setEditingComment] = useState('')
    const [visibleComment, setVisibleComment] = useState(false)
    const [OpenReply, setOpenReply] = useState(false)
    const [OpenEditComment, setOpenEditComment] = useState(false)

    const [loadingEditComment, setLoadingEditComment] = useState(false)
    const [OpenReplyComments, setOpenReplyComments] = useState(false)

    const [loadingDetailComment, setLoadingDetailComment] = useState(false)
    const [userProfil, setUserProfile] = useState([])

    const userId = get(userProfil, 'id', '')
    const epustaka_id = get(dataEpustaka, 'id', '')

    const getProfile = async () => {
        await provider
            .singleWebHook(`${url.webhookURL_profile}`)
            .then((res) => {
                setUserProfile(res.data)
            })
            .catch((err) => {
                return err
            })
    }

    useEffect(() => {
        getProfile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //     // To Post a reply comment in comment form
    const submitComment = async (url, data) => await provider.insert(url, data)
    const onSubmit = async (value) => {
        setLoadingDetailComment(true)
        // TODO comment sender_type 'EPUSTAKA'
        // object_type: 'FEED',
        // object_id: `${dataId}`,
        // comment: `${postReplyComment}`,
        // parent_id: `${value}`,
        // sender_id: fromEpustakaDetail ? epustaka_id : userId,
        // sender_type: fromEpustakaDetail ? 'EPUSTAKA' : 'USER',
        await submitComment(url.webhookURL_comment, {
            object_type: 'FEED',
            object_id: `${dataId}`,
            comment: `${postReplyComment}`,
            parent_id: `${value}`,
        })
            .then(async () => {
                setTimeLine({ refreshLike: true })
                setOpenReply(!OpenReply)
                setLoadingDetailComment(false)
                form.setFieldsValue({
                    senderChildTextarea: '',
                })
            })
            .catch((err) => {
                Promise.reject(err)
                setLoadingDetailComment(false)
            })
        setPostReplyComment('')
    }

    useEffect(() => {
        if (stateTimeLine.refreshLike) {
            getCommentList()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateTimeLine.refreshLike])

    // For delete comment list
    const onDeleteComment = async (value) => {
        confirm({
            title: (
                <span>
                    <b>{`${translator.t('feed.deleteComment')}`}</b>
                </span>
            ),
            icon: <ExclamationCircleOutlined />,
            content: `${translator.t('feed.confirmDeleteComment')}`,
            okText: `${translator.t('message.yes')}`,
            cancelText: `${translator.t('message.no')}`,
            okType: 'danger',

            onOk: async () => {
                await provider
                    .deleteData(`${url.webhookURL_comment}`, { id: value.id })
                    .then(() => {
                        setTimeLine({ refresh: true })
                    })
                    .catch((err) =>
                        error({
                            description: err.message,
                            placement: 'bottomLeft',
                        })
                    )
            },
            onCancel() {},
        })
    }

    // For Edit a Comment
    const onEditCommentList = async (value) => {
        setLoadingEditComment(true)
        // TODO COMMENT EPUSTAKA
        // object_type: 'FEED',
        // object_id: `${dataId}`,
        // comment: `${editingComment}`,
        // sender_id: fromEpustakaDetail ? epustaka_id : userId,
        // sender_type: fromEpustakaDetail ? 'EPUSTAKA' : 'USER',
        const data = {
            id: value.id,
            comment: `${editingComment}`,
        }

        await provider.put(`${url.webhookURL_comment}`, data).then(() => {
            setTimeLine({ refresh: true })
            setLoadingEditComment(false)
        })
        setEditingComment('')
    }

    const menu = (value) => {
        return userId === value.sender.id ? (
            <Menu style={{ width: 150 }}>
                <Menu.Item key="0">
                    <div
                        onClick={() => onDeleteComment(value)}
                    >{`${translator.t('feed.delete')}`}</div>
                </Menu.Item>
                <Menu.Item key="1">
                    <div
                        onClick={() => {
                            if (value.id === OpenEditComment) {
                                setOpenEditComment('')
                            } else {
                                setOpenEditComment(value.id)
                                form.setFieldsValue({
                                    comment: value.comment,
                                })
                            }
                        }}
                    >{`${translator.t('feed.edit')}`}</div>
                </Menu.Item>
            </Menu>
        ) : (
            <Menu style={{ width: 150 }}>
                <Menu.Item key="3">
                    <div
                        onClick={() => {
                            onReportComment(value)
                        }}
                    >{`${translator.t('filter.report')}`}</div>
                </Menu.Item>
            </Menu>
        )
    }

    const menuEpustakaDetail = (value) => {
        return epustaka_id === value.sender.id ? (
            <Menu style={{ width: 150 }}>
                <Menu.Item key="0">
                    <div
                        onClick={() => onDeleteComment(value)}
                    >{`${translator.t('feed.delete')}`}</div>
                </Menu.Item>
                <Menu.Item key="1">
                    <div
                        onClick={() => {
                            if (value.id === OpenEditComment) {
                                setOpenEditComment('')
                            } else {
                                setOpenEditComment(value.id)
                                form.setFieldsValue({
                                    comment: value.comment,
                                })
                            }
                        }}
                    >{`${translator.t('feed.edit')}`}</div>
                </Menu.Item>
            </Menu>
        ) : (
            <Menu style={{ width: 150 }}>
                <Menu.Item key="3">
                    <div
                        onClick={() => {
                            onReportComment(value)
                        }}
                    >{`${translator.t('filter.report')}`}</div>
                </Menu.Item>
            </Menu>
        )
    }

    // For Action Comment List
    const actions = (value) => [
        <span
            onClick={() =>
                value.id === OpenReply
                    ? setOpenReply('')
                    : setOpenReply(value.id)
            }
            key="comment-basic-reply-to"
        >{`${translator.t('feed.reply')}`}</span>,

        <span>
            <Dropdown
                overlay={
                    fromEpustakaDetail ? menuEpustakaDetail(value) : menu(value)
                }
                trigger={['click']}
                style={{ cursor: 'pointer' }}
            >
                <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                >
                    <img
                        src={ic_more}
                        alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                    />
                </a>
            </Dropdown>
        </span>,
        <span style={{ cursor: 'text' }}>
            {value.updated_at !== value.created_at ? (
                <Space>
                    <Text type="secondary">
                        · {translator.t('message.edited')}
                    </Text>
                </Space>
            ) : (
                ''
            )}
        </span>,
    ]
    return (
        <div style={{ paddingLeft: '1rem', marginBottom: 15 }}>
            <InfiniteScroll
                dataLength={data.length}
                next={onLoadMore}
                hasMore={paginationComment.pagination}
                style={{ overflow: 'hidden' }}
                loader={
                    <div
                        style={{
                            textAlign: 'center',
                            marginTop: 20,
                            height: 32,
                            lineHeight: '32px',
                        }}
                    >
                        <LoadingOutlined
                            style={{ color: COLORS.primaryColor, fontSize: 25 }}
                        />
                    </div>
                }
            >
                {map(data, (e, key) => {
                    const senderType = get(e, 'sender_type', '')
                    const avatar = e.sender.avatar_url
                    const senderId = get(e, 'sender.id', '')

                    const name = e?.sender?.name || ''
                    const id = e?.sender?.id || null
                    const { color, acronym } = getAcronym(name, id)
                    return (
                        <React.Fragment key={key}>
                            <Comment
                                key={id}
                                actions={actions(e)}
                                author={
                                    <b
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            if (senderType === 'USER') {
                                                history.push(
                                                    `/profil/user/${senderId}`
                                                )
                                            } else if (
                                                senderType === 'EPUSTAKA'
                                            ) {
                                                history.push(
                                                    `/epustaka/${senderId}`
                                                )
                                            }
                                        }}
                                    >
                                        {e.sender.name}
                                    </b>
                                }
                                datetime={
                                    <Tooltip
                                        title={moment(e.updated_at).format(
                                            'LLLL'
                                        )}
                                    >
                                        <span>
                                            {moment(e.updated_at).fromNow()}
                                        </span>
                                    </Tooltip>
                                }
                                avatar={
                                    isEmpty(e.sender.avatar_url) ? (
                                        <Avatar
                                            style={{
                                                cursor: 'pointer',
                                                backgroundImage: color,
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                            }}
                                            src={avatar}
                                            onClick={() => {
                                                if (senderType === 'USER') {
                                                    history.push(
                                                        `/profil/user/${senderId}`
                                                    )
                                                } else if (
                                                    senderType === 'EPUSTAKA'
                                                ) {
                                                    history.push(
                                                        `/epustaka/${senderId}`
                                                    )
                                                }
                                            }}
                                        >
                                            <span>{acronym}</span>
                                        </Avatar>
                                    ) : (
                                        <Avatar
                                            src={e.sender.avatar_url}
                                            className="post-avatar"
                                            onClick={() => {
                                                if (senderType === 'USER') {
                                                    history.push(
                                                        `/profil/user/${senderId}`
                                                    )
                                                } else if (
                                                    senderType === 'EPUSTAKA'
                                                ) {
                                                    history.push(
                                                        `/epustaka/${senderId}`
                                                    )
                                                }
                                            }}
                                        />
                                    )
                                }
                                content={
                                    OpenEditComment === e.id ? (
                                        <Form form={form}>
                                            <div
                                                style={{ marginRight: '10px' }}
                                            >
                                                <div>
                                                    <Form.Item name="comment">
                                                        <TextArea
                                                            className="textArea-edit"
                                                            bordered={false}
                                                            autoSize
                                                            value={
                                                                editingComment
                                                            }
                                                            onChange={(m) => {
                                                                setEditingComment(
                                                                    m.target
                                                                        .value
                                                                )
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    textAlign: 'end',
                                                    marginTop: '5px',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                <div className="btn-comment-edit">
                                                    <Button
                                                        style={{
                                                            marginRight: 5,
                                                            fontWeight: 'bold',
                                                            color: '#777f8f',
                                                            border: 'none',
                                                        }}
                                                        onClick={() => {
                                                            setOpenEditComment(
                                                                !OpenEditComment
                                                            )
                                                        }}
                                                        loading={
                                                            loadingEditComment
                                                        }
                                                        type="text"
                                                    >
                                                        {translator.t(
                                                            'action.cancell'
                                                        )}
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            onEditCommentList(e)
                                                            setOpenEditComment(
                                                                !OpenEditComment
                                                            )
                                                        }}
                                                        disabled={
                                                            !editingComment
                                                        }
                                                        loading={
                                                            loadingEditComment
                                                        }
                                                        type="primary"
                                                    >
                                                        {translator.t(
                                                            'action.edit'
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>
                                        </Form>
                                    ) : (
                                        <Paragraph
                                            key={id}
                                            style={{ marginRight: 15 }}
                                            ellipsis={{
                                                rows: 2,
                                                expandable: true,
                                                symbol: 'more',
                                            }}
                                        >
                                            {e.comment}
                                        </Paragraph>
                                    )
                                }
                            ></Comment>

                            {/* For Reply Comment Form */}
                            <div
                                style={{
                                    marginLeft: 30,
                                }}
                            >
                                {OpenReply === e.id && (
                                    <div>
                                        <div className="messageSender-top">
                                            <Avatar
                                                className="post-avatar"
                                                size="small"
                                                style={{
                                                    cursor: 'pointer',
                                                    backgroundImage: color,
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                }}
                                                src={
                                                    fromEpustakaDetail
                                                        ? get(
                                                              dataEpustaka,
                                                              'epustaka_logo',
                                                              ''
                                                          )
                                                        : get(
                                                              userProfil,
                                                              'avatar_url',
                                                              ''
                                                          )
                                                }
                                            >
                                                <span>{acronym}</span>
                                            </Avatar>
                                            <Form
                                                form={form}
                                                style={{ width: '100%' }}
                                            >
                                                <div
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    <div>
                                                        <Form.Item name="senderChildTextarea">
                                                            <TextArea
                                                                bordered={false}
                                                                style={{
                                                                    resize: 'none',
                                                                }}
                                                                className="textArea-edit"
                                                                placeholder={`${translator.t(
                                                                    'feed.addComment'
                                                                )}`}
                                                                autoSize
                                                                value={
                                                                    postReplyComment
                                                                }
                                                                onChange={(m) =>
                                                                    setPostReplyComment(
                                                                        m.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </Form.Item>
                                                        <div className="focus-border"></div>
                                                    </div>
                                                </div>

                                                <div
                                                    ref={inputRef}
                                                    style={{
                                                        textAlign: 'end',
                                                        marginTop: '-2px',
                                                        marginRight: '10px',
                                                    }}
                                                >
                                                    <div className="btn-comment-edit">
                                                        <Button
                                                            style={{
                                                                marginRight: 5,
                                                                fontWeight:
                                                                    'bold',
                                                                color: '#777f8f',
                                                                border: 'none',
                                                                marginTop:
                                                                    '5px',
                                                            }}
                                                            onClick={() => {
                                                                setVisibleComment(
                                                                    !visibleComment
                                                                )
                                                                setOpenReply(
                                                                    !OpenReply
                                                                )
                                                                form.setFieldsValue(
                                                                    {
                                                                        senderChildTextarea:
                                                                            '',
                                                                    }
                                                                )
                                                            }}
                                                            loading={
                                                                loadingEditComment
                                                            }
                                                            type="text"
                                                        >
                                                            {translator.t(
                                                                'action.cancell'
                                                            )}
                                                        </Button>
                                                        <Button
                                                            onClick={() =>
                                                                onSubmit(e.id)
                                                            }
                                                            disabled={
                                                                !postReplyComment
                                                            }
                                                            loading={
                                                                loadingDetailComment
                                                            }
                                                            type="primary"
                                                        >
                                                            {translator.t(
                                                                'action.reply'
                                                            )}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* End Reply Comment Form */}

                            {/* Comment Child */}
                            {e.reply_count > 0 && (
                                <div
                                    style={{
                                        marginLeft: 44,
                                    }}
                                >
                                    <p
                                        style={{
                                            fontSize: '14px',
                                            marginBottom: 5,
                                            color: COLORS.secondaryColor,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                            e.id === OpenReplyComments
                                                ? setOpenReplyComments('')
                                                : setOpenReplyComments(e.id)
                                        }
                                    >
                                        {e.id === OpenReplyComments
                                            ? `${translator.t(
                                                  `feed.hiddenComment`
                                              )} ${
                                                  e.reply_count
                                              } ${translator.t(
                                                  `feed.replyComment`
                                              )}`
                                            : `${translator.t(
                                                  `feed.viewComment`
                                              )} ${
                                                  e.reply_count
                                              } ${translator.t(
                                                  `feed.replyComment`
                                              )}`}
                                    </p>

                                    {OpenReplyComments === e.id && (
                                        <ChildComment
                                            commentId={OpenReplyComments}
                                            key={id}
                                            setTimeLine={setTimeLine}
                                            dataId={dataId}
                                            stateTimeLine={stateTimeLine}
                                            onReportComment={onReportComment}
                                            epustaka_id={epustaka_id}
                                            fromEpustakaDetail={
                                                fromEpustakaDetail
                                            }
                                        />
                                    )}
                                </div>
                            )}
                        </React.Fragment>
                    )
                })}
            </InfiniteScroll>
        </div>
    )
}

const mapStateToProps = (state) => ({
    stateTimeLine: get(state, 'timeLine', {}),
})

const mapDispatchToProps = () => ({
    setTimeLine: action.setTimeLine,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CommentList))
