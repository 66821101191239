import React from 'react'
import { Col, Divider, Row, Tabs } from 'antd'
import { ava } from 'assets/icons'
import { History, Status } from 'views/payment'
import translator from 'i18next'

const { TabPane } = Tabs

const Payment = () => {
    return (
        <div className="containerPayment">
            <Row justify="center">
                <Col xs={23} sm={23} md={22} lg={14} xl={14} xxl={18}>
                    <div className="containerPayment-head">
                        <img
                            src={ava}
                            alt="Edoo Website - Platform Edukasi Indonesia"
                        />
                        <p>{translator.t('payment.title')}</p>
                        <Divider />
                    </div>
                </Col>
            </Row>
            <Row justify="center">
                <Col xs={23} sm={23} md={22} lg={14} xl={14} xxl={18}>
                    <Tabs
                        defaultActiveKey="1"
                        className="containerPayment-base"
                    >
                        <TabPane tab={translator.t('payment.status')} key="1">
                            <Status />
                        </TabPane>
                        <TabPane tab={translator.t('payment.history')} key="2">
                            <History />
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </div>
    )
}

export default Payment
