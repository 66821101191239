import * as ActionType from './contant'
import ls from 'utils/secureLocalStorage'
import Encryption from 'utils/encryption'

const STORAGE = Encryption.encryptWithMD5('feed')
const initState = []

const initializeState = () => {
    const feed = ls.get(STORAGE)
    const state = feed ? JSON.parse(feed) : initState
    return state
}

const reducer = (state = initializeState(), action) => {
    const { type, value } = action

    switch (type) {
        case ActionType.SET_FEED:
            ls.set(STORAGE, JSON.stringify(value))
            return value
        case ActionType.UPDATE_FEED:
            ls.set(STORAGE, JSON.stringify(value))
            return value
        case ActionType.CLEAR_FEED:
            ls.remove(STORAGE)
            return initState
        default:
            return state
    }
}

export default reducer
