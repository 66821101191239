import React from 'react'
import { Layout } from 'antd'

const FooterComponent = () => {
    const year = new Date().getFullYear()
    return (
        <Layout.Footer className="footer">
            <span>
                &copy; {year}{' '}
                <a
                    href="https://aksaramaya.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Aksaramaya
                </a>
            </span>
        </Layout.Footer>
    )
}
export default FooterComponent
