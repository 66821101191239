import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import store from './redux/store'
import { Loading } from 'components'
import { initReactI18next, I18nextProvider } from 'react-i18next'
import i18next from 'i18next'

import enTranslations from './locales/en'
import idTranslations from './locales/id'
import * as serviceWorker from './serviceWorker'

import { ApolloProvider } from '@apollo/client'
import apolloClient from 'helpers/graphql'

import { Provider } from 'react-redux'

import 'assets/scss/index.scss'
import 'mathlive/dist/mathlive.core.css'
import 'mathlive/dist/mathlive.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-placeholder/lib/reactPlaceholder.css'
import 'moment/locale/id'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/toolbar/lib/styles/index.css'
import 'react-medium-image-zoom/dist/styles.css'

// Library swiper js
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/lazy/lazy.min.css'

import { GoogleOAuthProvider } from '@react-oauth/google'
import { GOOGLE_CLIENT_ID } from 'helpers/constants'

const langActive = localStorage.getItem('langActive')

const resources = {
    en: { messages: enTranslations },
    id: { messages: idTranslations },
}
const i18n = i18next.use(initReactI18next)
i18n.init({
    react: {
        wait: true,
    },
    resources: resources,
    lng: langActive || 'id',
    fallbackLng: 'en',
    keySeparator: '.',
    interpolation: {
        escapeValue: false,
    },
    ns: ['messages'],
    defaultNS: 'messages',
    fallbackNS: [],
})

const AppComponent = () => {
    return (
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <ApolloProvider client={apolloClient}>
                    <Suspense fallback={<Loading />}>
                        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                            <App />
                        </GoogleOAuthProvider>
                    </Suspense>
                </ApolloProvider>
            </Provider>
        </I18nextProvider>
    )
}

ReactDOM.render(<AppComponent />, document.getElementById('root'))

serviceWorker.unregister()
