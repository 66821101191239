import { BASE_TITLE, PLAYSTORE_URL } from 'constants/constant'
import { BASE_URL_WEB } from 'helpers/constants'

const lang = {
    menu_name: {
        school_list: 'School List',
        banner: 'Banner',
        donation: 'Donation',
        payment_method: 'Payment Method',
        pustaka: 'Pustaka',
        edoo_user: `${BASE_TITLE} User`,
        analysis: 'Analysis',
        epustaka_analysis: 'ePustaka Analysis',
        management_modules: 'Management Modules',
        module_packages: 'Module Packages',
        module_active: 'Module Packages Active',
        content: 'Content',
        epustaka_book: 'ePustaka Book',
        electronic_book: 'Electronic Book',
        book_waiting_verification: 'Book Waiting Verification',
        multimedia_category: 'Multimedia Category',
        video: 'Video',
        audio: 'Audio',
        configuration: 'Configuration',
        exam: 'Exam',
        item_questions: 'Item Questions',
        home: 'Home',
        school: 'School',
        school_year: 'School Year',
        major: 'Major',
        teacher_list: 'Teacher List',
        class: 'Class',
        student_list: 'Student List',
        subject: 'Subject',
        room: 'Room',
        subject_schedule: 'Subject Schedule',
        school_event: 'School Event',
        academic_calendar: 'Academic Calendar',
        student_attendance: 'Student Attendance',
        school_request_join: 'School Request Join',
        mutation: 'Mutation (Teacher/Student)',
        dapodik: 'Dapodik',
        subject_reference_book: 'Subject Reference Book',
        question_bank: 'Question Bank',
        exam_package: 'Exam Package',
        exam_result: 'Exam Result',
        online_training: 'Online Training',
        sub_class: 'Sub Class',
        training_schedule: 'Online Training Schedule',
        popup: 'PopUp',
        video_banner: 'Banner Video',
        running_text: 'Running Text',
        teaching_schedule: 'Teaching Schedule',
        textbook: 'Textbook',
        assignment: 'Assignment',
        score: 'Score',
    },
    title: {
        file_size: 'File Size',
        total_copy: 'Total of Copy',
        available_copy: 'Available Copy',
        available: 'Available',
        there_is: 'There is',
        no_queue: 'No Queue',
        readed_by: 'Has Been Read by',
        borrower_list: 'List of borrowers',
        is_being_read: 'Is Being Read',
        using_drm: 'Always Available',
        users: 'Users',
        description: 'Description',
        detail: 'Detail',
        show: 'Show More',
        review: 'Review',
        notification: 'Notifications',
        new_notification: 'New notification',
        detailnotif: 'See all notifications',
        morenotif: 'See more notifications',
        reader_list: 'Readers List',
        borrowers_list: 'List of borrowers',
        member: 'Member',
        collection: 'Collections',
        joinEpustaka: 'Join ePustaka',
        reqJoin: 'Request Join',
        continue: 'Continue',
        hasJoin: 'Have Joined',
        epustakaMember: 'Member ePustaka',
        video_detail: 'Video Detail',
        book_detail: 'Book Detail',
        audio_detail: 'Audio Detail',
        share: 'Share',
        share_to: 'Share to...',
        report_comment: 'Report Comment',
        deleteBook: 'Retun Book',
        reading: 'Reading',
        queue_list: 'List Queue',
        askJoin: 'Request Join',
        epustaka_member: 'ePustaka Member',
        similiar_book: 'Similar Book',
        teacher: 'Teacher',
        subject: 'Subject',
        subject_time: 'Lesson time',
        room: 'Room',
        online_meeting_url: 'Online Meeting Channel Link',
        student_count: 'Total students',
        student: 'Student',
        attachment: 'Attachment',
        noAttachment: 'No Attachment',
        noAddress: 'No Address',
        book: 'Book',
        assignment: 'Assignment',
        quiz: 'Quiz',
        time: 'Time',
        participant: 'Participant',
        link: 'Link',
        noLink: 'No Link',
        createdBy: 'Created By',
        activityDetail: 'Activity Detail',
        groupWork: 'Group Work',
        school_join_req: `Please search for schools or institutions that have registered with ${BASE_TITLE}.`,
        regency: 'Regency / City',
        school_list: 'School Lists / Institutions',
        status: 'Status',
        nik: 'NIK/NIP',
        nis: 'NIS',
        nisn: 'NISN',
        fullname: 'Full Name According to Identity',
        gender: 'Gender',
        thankyou: 'Thank you',
        join_school: 'Join School',
        level: 'Level',
        yourResult: 'Your Result',
        news: 'Article',
        today_news: `Today's Article`,
        select_news_category: 'Select Article category',
        apply: 'Apply',
        reset: 'Reset',
        emptyEpustaka:
            'Opss... The library you are looking for is not available',

        schedule: 'My Agenda',
        availabelity_copy: 'Copy Availability',
        total_epustaka_donation:
            'There are {{total_epustaka_donation}} eLibraries',
        donation_purpose: 'ePustaka purpose: {{donation_purpose}}',
        book_donation: 'Book Donation',
        choose_epustaka_purpose: 'Select destination ePustaka',
        find_ePustaka: 'Search eLibrary',
        my_account: 'My account',
        total_price: 'Total Price',
        edit_nominal: 'Edit Nominal',
        delete_from_box_donation: 'Remove From Donation Box',
        detail_box_donation: 'Donation Box Details',
        number_of_copy: 'Number of Copy',
        second: 'second',
        id_transaction: 'TRANSACTION ID',
        status_transaction: 'Transaction Status',
        detail_payment: 'Payment Details',
        purpose: 'Purpose',
        cancel_info: 'Cancellation Information',
        sorry: 'Sorry',
        book_title: 'Book title',
        pending_approval: 'Waiting for approval',
        extend: 'Extend',
        reNotif: 'Notification',
        reTitle: `You have borrowed this book from ePustaka '{{ePustaka}}', Please press the read button to continue reading`,
        video_lowercase: 'video',
        audio_lowercase: 'audio',
        news_lowercase: 'article',
        nationalLibrary: 'Layanan Perpustakaan Nasional RI',
        lastBookRead:
            'You are currently reading the book {{title}}, do you want to continue reading the book ?',
        activeReading: 'There is a book being read',
        continueRead: 'Continue reading',
        queue: 'Queue list',
        DRM: 'DRM Book',
        noDRM: 'non DRM Book',
        copyDRM: 'Total copy DRM Book',
    },
    action: {
        language: 'en',
        clear: 'Clear',
        finishing: 'Finishing',
        only_admin: 'Only account owners can change it',
        add_filter: 'Add filter',
        add_member: ' Add Member',
        out_member: 'Remove Member',
        is_out_member: 'Are you sure you want to exclude',
        is_next_out_member: 'from your ePustaka membership?',
        success_remove_member:
            'The user you selected has been successfully logged out',
        take_out: 'Take in Out',
        add: 'Add',
        borrow: 'Borrow',
        send: 'Send',
        download: 'Download',
        read: 'Read',
        queue_borrow: 'Queue',
        donation: 'Donation',
        addEvent: 'Add Schedule',
        addLevel: 'Add Difficulty Level',
        addQuizCategories: 'Add Quiz Categories',
        addScope: 'Add Scope',
        addSubject: 'Add subjects',
        back: 'Go Back',
        bulk_actions: '1 item selected |||| {{smart_count}} items selected',
        cancel: 'Cancel',
        clear_input_value: 'Clear value',
        clone: 'Clone',
        confirm: 'Confirm',
        create: 'Create',
        delete: 'Delete',
        edit: 'Edit',
        export: 'Export',
        find: 'Find',
        list: 'List',
        refresh: 'Refresh',
        remove_filter: 'Remove filter',
        remove: 'Remove',
        save: 'Save',
        aktivasi: 'Activation',
        deaktivasi: 'Deactivation',
        aktivasiMenuDashboard: 'Are you sure you want to activate this item?',
        deaktivasiMenuDashboard:
            'Are you sure you want to deactivate this item?',
        block: 'Block',
        unBlock: 'Unblock',
        blocked: 'Blocked',
        search: 'Search',
        searchAuthor: 'Search by author',
        searchYear: 'Search by release year',
        searchGenre: 'Search by book genre',
        borrowWithoutEntry: 'Borrow Collection Without Queuing',
        borrowWithoutEntryDesc: 'Display loan collections without queuing',
        show: 'Show',
        showMore: 'Show more',
        sort: 'Sorting',
        undo: 'Undo',
        expand: 'Expand',
        close: 'Close',
        viewDetail: 'View Detail',
        bulkInsertStudent: 'Students Bulk Insert',
        bulkInsertTeacher: 'Teachers Bulk Insert',
        bulkInsertDapodik: 'Dapodik Bulk Insert',
        promoteGradeStudent: 'Promote to Next Grade',
        finish: 'Finish',
        preview: 'Preview',
        giveReview: 'Leave a Review',
        editReview: 'Edit Review',
        writeMessage: 'Add Message...',
        addReview: 'Rate this book.',
        addAssigment: 'Add Assignment',
        editAssignment: 'Edit Assignment',
        verification: 'Verification',
        not_verified: 'Not verified',
        status: 'Status',
        editRole: 'Change roles',
        addPaymentMethods: 'Add Methods',
        addBankAccounts: 'Add Bank Accounts',
        addInstructions: 'Add Instructions',
        fully: 'More',
        readLess: 'Read less',
        follow: 'Follow',
        followed: 'Followed',
        recommendation: 'Recommendation',
        searchResult: 'Search Result',
        resultFound: 'was Found',
        followers: 'Followers',
        following: 'Following',
        cancel_follow: 'Unfollow',
        indentity: 'Identity Data',
        changeProfile: 'Change Profile',
        other: 'Other',
        accountData: 'Account Data',
        accountSetting: 'Setting Account',
        firstName: 'First Name',
        lastName: 'Last Name',
        telephon: 'Phone Number',
        bio: 'Bio',
        editPassword: 'Change password?',
        editEmail: 'Update email?',
        emailProfile: 'Email',
        dataUser: 'User Data',
        nisn: 'NISN',
        fullName: 'Full Name',
        placeBorn: 'Place of Birth',
        dateBorn: 'Date of Birth',
        codeUnix: 'Student Unique Code',
        dataSchool: 'School Data',
        nameSchool: 'School Name',
        fullAddress: 'Full Address',
        city: 'City / District',
        province: 'Province',
        codeUnixSchool: 'School Unique Code',
        phone: 'Telephone Number',
        searchUser: 'Search user',
        newPass: 'New Password',
        repPass: 'Repeat New Password',
        confirmPass: 'Confirm Password',
        oldPass: 'Old password',
        cancell: 'CANCEL',
        comment: 'COMMENT',
        reply: 'REPLY',
        report: 'REPORT',
        play: 'Play',
        pause: 'Pause',
        more: 'See more',
        queue: 'Queue',
        history: 'History',
        returnBook: 'Return Book',
        fullKtp: 'Address According to Identity',
        add_attachment: 'Add Attachment',
        upload_assignment: 'Upload Assignments',
        playQuiz: 'Play',
        reviewQuiz: 'Review',
        upload_attachment: 'Upload Attachment',
        join: 'Join',
        change_filter: 'Change Filter',
        buy_quiz_package: 'Buy a Question Pack',
        messageAssigment:
            'Assignments can only be uploaded once, make sure the uploaded file is correct. If you are sure, please click "Upload Task" and if not click "Cancel"',
        later: 'Later',
        reload: 'Reload',
        reloadMessage: 'Please reload or try again later :(',
        errorMessage: 'An error occurred in the internal system...',
        useVouchers: 'Use Vouchers',
        messageVoucher:
            'Enter the voucher code that the admin has sent via email',
        wrongVoucher: 'The voucher code you entered is wrong',
        enter_box_donation: 'Masukan Bok Donasi',
        add_collection: 'Add Collection',
        choose_payment: 'Select Payment',
        input_keyword: 'Enter keywords',
        deleteItem: 'Delete Item',
        messageDelete: 'Will you remove this Item from the borrow list?',
        borrowNow: 'Borrow Now',
    },
    schedule: {
        startTime: 'Start Time',
        endTime: 'End Time',
        startDate: 'Start Date',
        endDate: 'End Date',
        personalEvent: 'Personal Event',
        invitation: 'Invitation',
        next: 'Next',
        extra: 'Extracurricular',
    },
    filter: {
        result: 'Filter result',
        category: 'Category',
        publisher: 'Publisher',
        author: 'Author',
        year: 'Publication Year',
        filter: 'Filter',
        ganjil: 'Odd',
        genap: 'Even',
        numberOfPage: 'Number of Pages',
        following: 'Following',
        followers: 'Followers',
        like: 'Like',
        comment: 'Comment',
        share: 'Share',
        more: 'More',
        history: 'Watch History',
        listFollowers: 'List Followers',
        listFollowing: 'List Following',
        ePublic: 'ePustaka Institution',
        ePrivate: 'ePustaka Private',
        published_by: 'Published by',
        report: 'Report',
        copy_link: 'Copy link',
        donation: 'Donation',
        review: 'Review',
        district: 'District',
    },
    sort: {
        latest: 'Latest',
        abjad: 'Alphabetical order',
    },
    boolean: {
        true: 'Yes',
        false: 'No',
        null: '',
        aktif: 'Active',
        tidakAktif: 'Not Active',
        banner: {
            internal: 'Internal Content',
            website: 'Website',
        },
        epustaka: {
            private: 'Private ePustaka',
            public: 'Public ePustaka',
            perorangan: 'Personal',
            lembaga: 'Institution',
        },
        book: {
            private: 'Private Book',
            public: 'Public Book',
        },
    },
    page: {
        create: 'Create {{name}}',
        dashboard: 'Dashboard',
        edit: '{{name}} #{{id}}',
        error: 'Something went wrong',
        list: '{{name}}',
        loading: 'Loading',
        landing: 'Landing Page',
        landinginfo: 'Sample Landing Page',
        not_found: 'Not Found',
        show: '{{name}} #{{id}}',
        page1: 'Page 1',
        page2: 'Page 2',
        browse: 'Browse Collections',
    },
    feed: {
        endLoadMore: 'Yay! You have seen it all',
        yourMind: 'Whats on your mind',
        imgUrl: 'Image URL (Optional)',
        createPost: 'Create Post',
        totalLike: 'Like',
        totalComment: 'Comment',
        like: 'Like',
        delete: 'Delete',
        edit: 'Edit',
        deleteComment: 'Delete Comments',
        confirmDeleteComment: 'Are you sure you want to delete this comment?',
        viewComment: 'View',
        hiddenComment: 'Hide',
        replyComment: 'comment(s)',
        shareTitle: 'Share with your friends',
        reply: 'Reply to',
        comment: 'Comment',
        addComment: 'Add a comment...',
        book_recommendation: 'Recommend Books',
        show_more_replies: 'Show more replies',
        epustaka_post: 'Post or share something...',
    },
    input: {
        bulk: {
            student: 'Upload student XLSX file',
            teacher: 'Upload teacher XLSX file',
        },
        file: {
            upload_several:
                'Drop some files to upload, or click to select one.',
            upload_single: 'Drop a file to upload, or click to select it.',
        },
        image: {
            upload_several:
                'Drop some pictures to upload, or click to select one.',
            upload_single: 'Drop a picture to upload, or click to select it.',
        },
        references: {
            all_missing: 'Unable to find references data.',
            many_missing:
                'At least one of the associated references no longer appears to be available.',
            single_missing:
                'Associated reference no longer appears to be available.',
        },
        password: {
            toggle_visible: 'Hide password',
            toggle_hidden: 'Show password',
        },
        addAcount: 'Choose Account',
        addAccountTitle: 'Add School Admin',
        addAcountPlaceholder: 'Select an account to be the school admin',
        MigrationUserPlaceholder: 'Select an account to migrate',
        confirmAddAdmin: 'Are you sure you want to add as a school admin?',
        confirmMigration: 'Migrate user data to your school?',
        addProvince: 'Select Province',
        addRegency: 'Select Regency/City',
        addSchools: 'Select Schools / Institutions',
        addStatus: 'Choose Your Status',
        addNIK: 'NIK/NIP',
        addNIS: 'Student Identification Number',
        addNISN: 'National Student Identification Number',
        addName: 'Full name',
        addGender: 'Gender',
        addClass: 'Choose Class',
        addLevel: 'Choose Level',
    },
    message: {
        data_not_found: 'Data not found',
        urlValid:
            'Sorry, the url you accessed is not valid or this ePustaka may have been deleted..',
        successRegisterSchool: 'School Registration Succeed',
        writeReview: 'Please fill your review',
        success_add: 'Added Successfully',
        success_join_epustaka:
            'You have successfully added the user to become a member of your ePustaka',
        day: 'Hari',
        about: 'About',
        phoneFormatNotMatch:
            'Phone numbers can only be numbers, (format) xxxx-xxxx-xxxx',
        are_you_sure: 'Are you sure?',
        bulk_delete_content:
            'Are you sure you want to delete this {{name}}? |||| Are you sure you want to delete these {{smart_count} items?',
        bulk_delete_title:
            'Delete {{name}} |||| Delete {{smart_count}} {{name}}',
        delete_content: 'Are you sure you want to delete this item?',
        delete_title: 'Delete Confirmation',
        details: 'Details',
        error: "A client error occurred and your request couldn't be completed.",
        invalid_form: 'The form is not valid. Please check for errors',
        loading: 'The page is loading, just a moment please',
        no: 'No',
        not_found: 'Either you typed a wrong URL, or you followed a bad link.',
        yes: 'Yes',
        verification_success: 'Verification Successful',
        successAddADmin: 'School admin data added',
        successRemoveADmin: 'School admin data removed',
        successDelete: 'Dapodik data is deleted',
        successMigration: 'User data mutation successful',
        rejectMigration: 'User data mutation was rejected',
        errMigration:
            'There are empty data, please check the completeness of the user data again.',
        warningReport: 'Failed to report comment',
        message_warning_report: 'Sorry. you have reported the content before.',
        successReport: 'Your report has been sent',
        message_success_report:
            'Thank you. We have received your report. If we think this content violates our Community Guidelines, the content will be removed.',
        citationMessage: `{{content}}\n\nThis article was taken from the ${BASE_TITLE} application with the title "{{judul}}" on {{tanggal}} at {{jam}}\n\nAuthor : {{penulis}}\nPublisher : {{penerbit}}\n\nPublication Year : {{tahun}}\nPage : {{halaman}}\n\nDownload ${BASE_TITLE} application to read other collections :\nWebsite : ${BASE_URL_WEB}`,
        underReviewMigration: 'User data mutation was currently under review',
        hour: 'Hour',
        minute: 'Minute',
        successBlock: 'Block user data successfully',
        successUnBlock: 'Successfully unblocked',
        errGrade:
            'Class name cannot be the same / please try inputting other class name.',
        unlimitedCopy: 'Unlimited Copy',
        availableCopy: 'Available Copy',
        message_share: `Distributed through the ${BASE_TITLE} application (School Digital Library) Download the application at ${PLAYSTORE_URL}`,
        copied: 'Copied',
        edited: 'edited',
        no_description: 'No Description',
        notif: 'You are not registered as an ePustaka member yet',
        activity: 'Your activity will be saved on this page',
        messageReturnBook:
            'Are you sure you want to delete this item, do you have to borrow it again if you want to access this item again?',
        give_rating: 'Give a rating',
        errorPass: 'Wrong old password',
        unfollow: 'ou are no longer following the activity',
        confirmUnfollow: 'Confirm Cancel Following',
        confirmFollow: 'Confirm Following',
        makeFriends: 'Make Friend',
        follow: 'Make friends with',
        failedBorrow: `Sorry, at this time the book cannot be read via the ${BASE_TITLE} web, please look for other books or download the ${PLAYSTORE_URL} application on google playstore to read all types of books.`,
        notJoinEpustaka:
            'You have not joined as a member of the ePustaka, please press the Join button to become a member in this ePustaka. By becoming a member you can borrow collections from the ePustaka.',
        waitingJoin:
            'You have not joined as an ePustaka member, please contact the ePustaka admin to accept a join request',
        expiredJoinEpustaka: `Your membership period has ended, please press the "Renew" button to re-join this ePustaka and enjoy the contents in the ePustaka.`,
        book_not_found: 'Book data not found',
        not_yet_started: 'Not yet started',
        ongoing: 'Ongoing',
        attendance_over: 'Attendance Over',
        alpha: 'Alpha',
        present: 'Present',
        permit: 'Permit',
        sick: 'Sick',
        absent: 'Absent',
        not_yet_absent: 'Not Absent',
        not_data_agenda: 'There is no agenda',
        class_not_ready: 'Class hasnt started yet',
        absent_now: 'Absent Now',
        attendance_status: 'Your Attendance Status',
        no_event: 'No Event',
        empty: 'Empty',
        has_upload_assignment: 'You have uploaded an assignment.',
        empty_assignment: 'No assignments.',
        allDay: 'All Day',
        schedule_in_progress: 'Schedule in Progress',
        schedule_is_completed: 'Schedule is Complete',
        class_not_yet_ready:
            'You have not been able to confirm attendance because the learning time has not yet started. Thank you',
        search_not_matches:
            'Please make changes to the filter you are looking for',
        filter_not_matches: 'Oops, No filter results found',
        succes_school_join:
            'The joining request has been sent successfully, then we will carry out a verification process for the data you have entered.',
        successVoucher: 'Voucher code has been used successfully',
        sure_cancel_donation:
            'Are you sure you want to cancel the Donation program?',
        cancel_information:
            'The transaction will be automatically canceled by the system if you have not made a payment until the remaining payment time is up.',
        connectionError:
            'A problem occurred. Please double check your internet connection',
        notifExpand: 'Your membership period has expired, please renew again.',
        no_access_content:
            'You have no rights to access this {{content}} content.',
        reading_book_confirm_title: 'There are books open in other tabs',
        reading_book_confirm_message:
            'Please reload the book to continue reading',
        no_bookmark: 'There is no bookmark',
        no_queue: 'No Queue List yet',
        no_borrow: 'There is no Borrower List yet',
        firstPerson: 'Be the first person to borrow this book',
        content_private: 'Personal Content',
        content_not_found:
            'The content could not be found. The ePustaka administrator has removed the content, rendering it inaccessible.',
    },
    navigation: {
        no_results: 'No results found',
        no_more_results:
            'The page number {{page}} is out of boundaries. Try the previous page.',
        page_out_of_boundaries: 'Page number {{page}} out of boundaries',
        page_out_from_end: 'Cannot go after last page',
        page_out_from_begin: 'Cannot go before page 1',
        page_range_info: '{{offsetBegin}}-{{offsetEnd}} of {{total}}',
        page_rows_per_page: 'Rows per page:',
        next: 'Next',
        prev: 'Prev',
        fillAnswerForm: 'Fill answer form',
        fillQuestionForm: 'Fill question form',
        outQueue: 'Exit from queue',
    },
    auth: {
        auth_check_error: 'Please login to continue',
        user_menu: 'Profile',
        username: 'Username',
        password: 'Password',
        sign_in: 'Sign in',
        sign_in_error: 'Authentication failed, please retry',
        logout: 'Logout',
        lock: 'Lock Account',
        setting: 'Settings',
        profile: 'View profile',
        account: 'Account',
        notification: 'All Notifications',
        notifications: 'Notifications',
        bookshelf: 'Book Shelf',
        notes: 'Notes',
        friend: 'Friends',
        agenda: 'Agenda',
        help: 'Help',
        raport: 'Raport',
        scholarship: 'Scholarship',
        extra: 'Extracurricular',
        virtual: 'Virtual Board',
        forum: 'Forum Q/A',
        bill: 'Bill',
        donation: 'Donation',
        membership: 'Membership',
        noNotif: 'No Notification',
        noBook: 'No Books data',
    },
    notification: {
        activated: 'Activated data',
        deactivated: 'Deactivated data',
        updated: 'Element updated |||| {{smart_count}} elements updated',
        created: 'Element created',
        inputEmail: 'Enter email',
        dataCreated: 'Data created',
        deleted: {
            single: 'Element deleted',
            multiple: '{{smart_count}} elements deleted',
        },
        errorDeleteMenu:
            'The main menu cannot be deleted as long as the submenu is still exist',

        bad_item: 'Incorrect element',
        item_doesnt_exist: 'Element does not exist',
        http_error: 'Server communication error',
        data_provider_error:
            'dataProvider error. Check the console for details.',
        i18n_error: 'Cannot load the translations for the specified language',
        canceled: 'Action cancelled',
        logged_out: 'Your session has ended, please reconnect.',
        loading: 'Loading...',
        inputPass: 'Enter a new password',
        repPass: 'Repeat Password',
        oldPass: 'Enter old passwrod',
        bookNotFound: 'Book not available',
    },
    validation: {
        required: 'Required',
        minLength: 'Must be {{min}} characters at least',
        maxLength: 'Must be {{max}} characters or less',
        maxImage: 'Image must smaller than 2MB!',
        maxAudio: 'Audio must smaller than 50MB!',
        maxVideo: 'Video must smaller than 250MB!',
        maxBook: 'Book file must smaller than 20MB!',
        minValue: 'Must be at least {{min}}',
        maxValue: 'Must be {{max}} or less',
        number: 'Must be a number',
        email: 'Must be a valid email',
        emailExist: 'Email has been used',
        yearExist: `Year academic's name has been used`,
        nisExist: 'NIS has been used',
        nikExist: 'NIK has been used',
        emailBulkExistTeacher: `User already registered as teacher, Please check the user's NIK and Email`,
        emailBulkExistStudent: `User already registered as student, Please check the user's NISN and Email`,
        nisBulkExist: 'There are NIS that have been used in bulk data',
        nikBulkExist: 'There are NIK that have been used in bulk data',
        oneOf: 'Must be one of: {{options}}',
        regex: 'Must match a specific format (regexp): {{pattern}}',
        cantPastCurrentYear: 'Can not past current year.',
        cantBeSmallerThanYearStart: 'Can not be smaller than the start year',
        dataExist: 'Data already exists',
        gradeNotExist: 'Empty Class Data, Please Fill in First Class Data',
        dataBulkNotValid:
            'There is invalid data / file does not use the specified format.',
        dataEmpty: 'Data is invalid or empty',
        wrongFormatEmail: 'Email Format is Wrong',
        require_province: 'Please fill in your Province!',
        require_regency: 'Please fill in your regency/city!',
        require_school: 'Please enter your school/institution!',
        require_status: 'Please enter your status!',
        require_nik: 'Please enter your NIK/NIP!',
        require_nis: 'Please enter your NIS!',
        require_nisn: 'Please enter your NISN!',
        require_name: 'Please enter your full name!',
        require_gender: 'Gender is required!',
        require_level: 'Please enter your level',
        require_class: 'Please enter your class',
    },
    menu: {
        home: 'Home',
        timeline: 'Timeline',
        activities: 'Activities',
        book: 'Book',
        video: 'Video',
        audio: 'Audio',
        radio: 'Radio',
        forum: 'Forum',
        user: 'User',
        article: 'Article',
        borrowed_shelf: 'Borrowed Shelf',
        friendship: 'Friendship',
        donation: 'Donation',
        virtual_board: 'Virtual Board',
        agenda: 'Agenda',
        teacher_list: 'Teacher Lists',
        extracurricular: 'Extracurricular',
        help: 'Help',
        school_join: 'Join School',
        membership: 'Membership',
    },
    help: {
        menu_index: 'Menu order that will appear on the dashboard menu.',
        active_duration: 'Format in the form of days (30 days).',
    },
    column: {
        aksi: 'Action',
        namaGuru: `Teacher's Name`,
        namaSiswa: `Student's Name`,
        lastRead: 'Last Read',
        scheduleToday: 'Schedule today',
        recommendation: 'Recommendation',
        suggestFriends: 'Suggested Friend',
        quiz: 'Quiz',
        epustaka: 'ePustaka',
        audio: 'Hearing History',
        watch: 'Watch History',
        donation: 'Donation Collection',
        read: 'History Read',
        book: 'Books Collection',
        selfBook: 'Book Self',
        historyBook: 'Reading History',
        ownerCollAvail: 'Collections Owner Available',
        ownerColl: 'Collection Owner',
        searchBook: 'Search books results for',
        searchAudio: 'Search audio results for',
        searchVideo: 'Search video results for',
        search: 'Search results for',
    },
    registerSchool: {
        title: 'School Register',
        name: 'School Name',
        npsn: 'NPSN',
        nss: 'NSS',
        phone: 'Phone/WA',
        province: 'Province',
        regency: 'Regency',
        district: 'District',
        village: 'Village',
        postCode: 'Post Code',
        address: 'Address',
        major: 'Major',
        logo: 'Logo',
        password: 'Password',
        retypePassword: 'Re-type Password',
        headMasterData: {
            title: 'Head Master Data',
            fullName: 'Full Name including Title',
            nip: 'NIP',
        },
        schoolAdminData: {
            title: 'School Admin Data',
            fullName: 'Full Name',
            email: 'E-mail',
        },
        error: {
            empty: 'must be filled',
            invalid: 'invalid',
            weak: 'Password At Least Must Be 8 Character Long',
            mismatch: 'Password Mismatch',
        },
    },
    gendder: {
        male: 'Male',
        female: 'Female',
        other: '-',
    },
    status: {
        active: 'Active',
        nonActive: 'Not Active',
        inReview: 'In Review',
        disabled: 'Disabled',
        review: 'Review',
        total: 'Total',
        blokir: 'Disable',
    },
    bookshelf: {
        borrowBook: 'Borrow Buku',
        borrowVideo: 'History Play Video',
        borrowAudio: 'History Play Audio',
        notifBorrow:
            'There is no loan collection yet. Your opportunity to be able to borrow, borrow now ',
        notifReview:
            'No collection reviews yet. Borrow a collection of books, audios, and videos to be able to provide reviews and ratings',
        notifQueue:
            'There is no queue yet. You have not queued the content of the book, audio or video collections',
        notifHistory:
            'There is no history. You havent borrowed, shared programs, reviews and queues',
        returnBook: 'The book has been returned from the loan shelf',
        empty_book_donation_title: 'There are no shared collections yet',
        empty_book_history_title: 'No credit collection yet',
        empty_book_donation_desc:
            'Your chance to be the first to join the sharing program is now.',
        empty_book_history_desc:
            'Your chance to be able to borrow, borrow now.',
        news_history: 'Article Reading History',
        out_queue:
            'Are you sure you want to get out of the queue, you have to queue again if you want to be notified of the availability of this book?',
    },
    epustka: {
        title: 'Request to Join ePustaka Members',
        subtitlePrivat:
            'You are not registered as a member of this ePustaka, please press the Request to Join button to join as a member of this ePustaka. By becoming a member you can borrow collections from the ePustaka.',
        notifPrivat:
            'Your request to join has been submitted, please wait for further notification from the admin',
        notif: 'Thank you for joining our library',
        subtitle:
            'You have not joined as an ePustaka member, please press the Join ePustaka button to join as a member in this ePustaka. By becoming a member you can borrow collections from the ePustaka.',
        subtitleExpired:
            'Your membership status has expired, please press the Renew button to extend your membership in this ePustaka. By becoming a member you can borrow collections in ePustaka.',
        join: 'Join',
        collectionAudio: 'Audio Collection',
        collectionVideo: 'Video Collection',
        collectionArticle: 'Article Collection',
    },
    friendship: {
        firstNotifFollow: 'You have never followed another user.',
        secondNotifFollow: `Immediately click on this link and follow other ${BASE_TITLE} users.`,
        thirdNotifFollow: `Your chance to make more friends on the ${BASE_TITLE} Platform.`,
        firstNotifFollower: `Sorry, there are currently no ${BASE_TITLE} users following you.`,
        secondNotifFollower:
            'Start following other users activities and get followers.',
    },
    profile: {
        notif: `Oops ... No activity yet. Your chance to be the first to enjoy the collection in the ${BASE_TITLE} Library application`,
        userNotfound: 'Sorry user not found',
        gender: 'Gender',
        male: 'Laki-laki',
        female: 'Perempuan',
        notifFollow: 'Success followed',
        notifUnfollow: 'Successfully unfollowed',
    },
    quiz: {
        quizDetail: '{{tryout_name}} Detail',
        detailInfo: 'Info Detail',
        question_package: 'Question Package',
        link_purchased_status: 'Status',
        participant: 'Participant',
        grade: 'Grade',
        creator: 'Created by',
        number_of_question: 'Number of Question',
        duration: 'Duration',
        date_of_publish: 'Date of Publish',
        question: 'Question',
        nis: 'NIS',
        name: 'Name',
        score: 'Score',
        show_all_of_player: 'Show all of player',
        have_previous_quiz: 'ou still have unfinished quiz.',
        finish_previous_quiz: 'Complete the previous quiz',
        finish: 'Finish',
        code_exam_package: 'Enter the exam package question code',
        question_code: 'Question code',
        text_insert_question_code:
            'Please enter the code for the test package that you received',
        wrong_code: 'The code is wrong',
        message_success_title: 'Thank you',
        message_success_report:
            'You have participated and completed this mission',
        message_success_time_out:
            'Because the processing time is up, we will send your answer immediately.',
        number_of_question_from_total: '{{current}} from {{total}} question',
        explanation_of_question_from_total:
            'Question explanation {{current}} from {{total}} question',
        remaining_time: 'Remaining time',
        question_number_information: 'Question number information',
        color_description: 'Color description',
        uncertain: 'Uncertain',
        certain: 'Certain',
        unanswered: 'Unanswered',
        answered: 'Answered',
        closeQuiz: 'sure to leave the quiz ?',
        confirm: 'Confirm',
        instruction_info_filled: 'User-defined options',
        instruction_info_uncertain: 'The choice is still in doubt',
        modal_question_info_total:
            '{{total_unfilled}} Unanswered Question, {{total_filled}} Question Answered, {{total_uncertain}} Questions of Doubt',
        total_unfilled: '{{total_unfilled}} Unanswered Question',
        total_filled: '{{total_filled}} Question Answered',
        total_uncertain: '{{total_uncertain}} Questions of Doubt',
        answer_preview: 'Answer preview',
        send: 'Send',
        cancel: 'Check Again',
        quiz_free: 'Free',
        rate: 'Rate',
        study_field: 'Study Field',
        quiz_reference: 'References',
        quiz_review: 'Review',
        multipleChoice: 'Questions Multiple Choice ',
        no_quiz_participant: 'Prepare Yourself To Be The First To Try',
        info_quiz_rank: `You are in position {{current_position}} from {{total_student}} students based on {{scope}}`,
        quiz_ranking: 'Ranking',
        national: 'National',
        regency: 'City',
        province: 'Province',
        school: 'School',
        send_quiz_review: 'give a review & rating for',
        has_quiz_review: 'has provided a review & rating for',
        give_review: 'Leave a Review',
        give_rating: 'Set Rating',
        more_review: 'See all reviews',
        your_achievements: 'Your achievements',
        congrats_info: 'Congrats',
        passing_grade: 'Passing Grade Anda',
        see_explanation: 'See Discussion Questions',
        correct_answer: 'Correct answer',
        wrong_answer: 'Wrong answer',
        skip_answer: 'Question unanswered',
        your_correct_anwer: 'Your answer is correct',
        your_wrong_anwer: 'Your answer is wrong',
        report_question: 'Report Question',
        answer_correct: 'Correct Answer :',
        more_quiz_participant: 'Load More',
        rate_not_available: 'Rating not yet available',
        not_doing: 'Not working yet',
        thank_info: 'Thank you for participating and completing this quiz.',
        explanation_reference: 'Reference Question',
        placeholderText: 'Tryout Package For You Coming Soon :)',
        title: 'Come on, complete your level and class data, so we can recommend materials and tryouts according to your needs',
        chooseLevel: 'Choose Level',
        chooseClass: 'Choose Class',
        chooseMajor: 'Choose Major',
        seb: 'Please open the Edoo Safe Exam Browser (ESEB) to continue taking the exam. If you dont have ESEB, please download it via the link below.',
    },
    achievement: {
        achievement: 'Achievement',
        message: 'Important Message',
        tryout: 'Try Out',
        lastTest: 'Last Test ',
        startTest: 'Start Test ',
        achievementTryout: 'Try Out Achievement ',
        historyActivity: 'Activity History',
        emptyTitle: 'No activity history',
        link: 'College Passing Grade Data',
        emptyTryout: 'No Achievements Yet',
        detailEmpty:
            'Please select the package of questions that you will work on, on the front page',
        emptyActivity: 'You dont have any activity history yet',
        pg: 'Yout PG',
        score: 'Your Score',
        passingGradeEmpty:
            'Sorry, currently the passing grade list is not available',
        choosePacket: 'Choose Question Package',
    },
    payment: {
        title: 'Purchase of Question Packages',
        status: 'Status',
        history: 'Riwayat',
        paymentStatus: 'Purchase Status:',
        idr: 'IDR',
        cancelPayment: 'Cancel Payment',
        detailPayment: 'See Status Details',
        messageCancel: 'Are you sure you cancel the question package purchase',
        continue: 'Continue Donating',
        confirmCancel: 'Yes, Cancel Donation',
        proccessPayment: 'Continue Payment Process',
        waitPayment: 'Continue Payment Process',
        finishPayment: 'Transaction Complete',
        historyPayment: 'Purchase History',
        cancelTransaction: 'Transaction Canceled',
        payment: 'Purchase',
        warning: 'MAKE PAYMENT IMMEDIATELY BEFORE:',
        idTransaction: 'Transaction ID',
        noPayment: 'Transfer payment to account number:',
        buy: 'Pay',
        bill: 'Total Bill',
        bill_uppercase: 'TOTAL BILL',
        details: 'Details',
        boxordertitle: 'TOTAL PAY',
        notice: 'The total payment above is the result of the sum of the item details order listed below',
        continuePayment: 'Continue Payment',
        messageOrder: 'successfully added collection to box order',
        messageReturn: 'successfully deleted collection from order box',
        box: 'Box Order',
        messageNodata: 'No Transaction yet',
        messageNodataDetail:
            'Please select the package of questions that you will work on on the front page',
        choosePackage: 'Choose a Question Package',
        deleteOrder: 'Delete Order',
        continue_payment: 'Continue Payment',
        waiting_payment: 'Waiting for payment',
        verify_payment: 'Verified',
        cancel_payment: 'Transaction Canceled',
        pay_now: 'Pay Now',
        remaining_payment: 'Remaining Payment Time',
        payment_date: 'Payment Date',
        canceled_info: 'your transaction is cancelled',
        crossed_message:
            'You have crossed the transaction payment limit, thank you',
        add_epustaka_donation:
            'Add ePustaka Collection Through the Donation Feature',
        add_epustaka_donation_info:
            'By participating in the collection sharing program, you have helped change the face of Indonesian literacy, making it easier to access.',
        donation_now: 'Donate Now',
        status_donate: 'Donation Status',
        donation_history: 'Donation History',
        coming_soon_donation_title: `Coming Soon {{donation_name}} For Donations`,
        coming_soon_donation_desc: `We thank you for your participation and soon there will be {{name}} content available for sharing in the application ${BASE_TITLE} Library`,
    },
}

export default lang
