/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import Post from 'views/timeline/components/Post'
import ActivityPost from 'views/timeline/components/ActivityPost'
import NoActivityPost from 'views/timeline/components/NoActivityPost'
import url from 'constants/url'
import { error } from 'utils/notification'
import provider from 'providers/provider'
import get from 'lodash/get'
import concat from 'lodash/concat'
import isEmpty from 'lodash/isEmpty'
import InfiniteScroll from 'react-infinite-scroll-component'
import getPagination from 'constants/getPagination'
import { LoadingOutlined } from '@ant-design/icons'
import translator from 'i18next'
import { connect } from 'react-redux'
import reduxState from '../../../redux'
import { withRouter } from 'react-router-dom'
import action from 'redux/timeLine/action'
import { COLORS } from 'constants/constant'

const Feed = (props) => {
    const {
        stateTimeLine,
        setTimeLine,
        source,
        userProfilId,
        fromEpustakaDetail,
        dataEpustaka,
    } = props

    const { profile = {} } = reduxState.getState()
    const idProfil = get(profile, 'id', '')
    const epustaka_id = get(dataEpustaka, 'id', '')

    const limit = 10
    const [dataFeed, setDataFeed] = useState([])
    const [limitFeed, setLimitFeed] = useState(1)
    const [loading, setLoading] = useState(false)

    const [state, setState] = useState({
        loading: true,
        pagination: true,
        hasMore: true,
    })
    const pagination = getPagination(limitFeed, limit, 'webhook')

    const BOOK_BORROW = 'BOOK_BORROW'
    const BOOK_REVIEW = 'BOOK_REVIEW'
    const VISUAL_POST = 'VISUAL_POST'
    const BOOK_RECOMMENDATION = 'BOOK_RECOMMENDATION'
    // const AUDIO_RECOMMENDATION = 'AUDIO_RECOMMENDATION'
    // const VIDEO_RECOMMENDATION = 'VIDEO_RECOMMENDATION'
    // const AUDIO_CONTENT = 'AUDIO_CONTENT'
    // const VIDEO_CONTENT = 'VIDEO_CONTENT'
    // const PLAY_MULTIMEDIA = 'PLAY_MULTIMEDIA'
    // const QUOTES = 'QUOTES'
    // const ADD_BOOK = 'ADD_BOOK'

    const getFeedActivity = async () => {
        limitFeed === 1 && setLoading(true)
        const filter =
            source === 'profile'
                ? `&user_id=${idProfil}`
                : source === 'userProfil'
                    ? `&user_id=${userProfilId}`
                    : ''

        const filterEpustaka = `&epustaka_id=${epustaka_id}`

        if (fromEpustakaDetail) {
            await provider
                .list(
                    url.webhookURL_epustaka_feeds +
                    pagination.result +
                    filterEpustaka
                )
                .then((results) => {
                    const { data, meta } = results
                    limitFeed === 1
                        ? setDataFeed(data)
                        : setDataFeed(concat(dataFeed, data))

                    setState({
                        ...state,
                        loading: false,
                    })
                    setLoading(false)
                    if (pagination.offset >= meta.total) {
                        setState({ pagination: false, hasMore: false })
                    }
                    setTimeLine({ refresh: false, refreshLike: false })
                    // actionFeed.setDataFeed({ data })
                })
                .catch((err) => {
                    error({
                        description: err.message,
                        placement: 'bottomRight',
                    })
                    setState({ ...state, loading: false })
                    return
                })
        } else {
            await provider
                .list(url.webhookURL_feeds + pagination.result + filter)
                .then((results) => {
                    const { data, meta } = results
                    limitFeed === 1
                        ? setDataFeed(data)
                        : setDataFeed(concat(dataFeed, data))

                    setState({
                        ...state,
                        loading: false,
                    })
                    setLoading(false)
                    if (pagination.offset >= meta.total) {
                        setState({ pagination: false, hasMore: false })
                    }
                    setTimeLine({ refresh: false, refreshLike: false })
                    // actionFeed.setDataFeed({ data })
                })
                .catch((err) => {
                    error({
                        description: err.message,
                        placement: 'bottomRight',
                    })
                    setState({ ...state, loading: false })
                    return
                })
        }
    }

    const getRefreshFeed = async () => {
        const filter =
            source === 'profile'
                ? `&user_id=${idProfil}`
                : source === 'userProfil'
                    ? `&user_id=${userProfilId}`
                    : ''
        await provider
            .list(
                `${url.webhookURL_feeds}?offset=0&limit=${limitFeed * limit
                }&${filter}`
            )
            .then((results) => {
                const { data } = results
                setDataFeed(data)
                setTimeLine({ refreshLike: false })
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    useEffect(() => {
        getFeedActivity()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limitFeed])

    useEffect(() => {
        if (stateTimeLine.refresh) {
            limitFeed === 1 ? getFeedActivity() : setLimitFeed(1)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateTimeLine.refresh])

    useEffect(() => {
        if (stateTimeLine.refreshLike) {
            getRefreshFeed()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateTimeLine.refreshLike])

    const fetchMoreData = async () => {
        setState({
            ...state,
            loading: true,
        })
        setLimitFeed(limitFeed + 1)
    }

    return (
        <div className="feed">
            {loading ? (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '10rem',
                        height: 32,
                        lineHeight: '32px',
                    }}
                >
                    <LoadingOutlined
                        style={{ color: COLORS.primaryColor, fontSize: 25 }}
                    />
                </div>
            ) : isEmpty(dataFeed) ? (
                <NoActivityPost />
            ) : (
                <InfiniteScroll
                    dataLength={dataFeed.length}
                    next={fetchMoreData}
                    hasMore={state.hasMore}
                    style={{ overflow: 'hidden' }}
                    loader={
                        <div
                            style={{
                                textAlign: 'center',
                                marginTop: 20,
                                height: 32,
                                lineHeight: '32px',
                            }}
                        >
                            <LoadingOutlined
                                style={{
                                    color: COLORS.primaryColor,
                                    fontSize: 25,
                                }}
                            />
                        </div>
                    }
                >
                    {dataFeed.map((feed, id) => {
                        if (feed.feed_type === BOOK_BORROW) {
                            return (
                                <div key={id}>
                                    <Post
                                        author={feed?.book?.authors}
                                        id={feed?.id}
                                        has_like={feed?.has_like}
                                        feed_type={feed?.feed_type}
                                        avatar_url={
                                            feed?.sender?.avatar_url || ''
                                        }
                                        message={feed?.message}
                                        timestamp={feed?.updated_at}
                                        username={
                                            feed?.sender?.name || 'Unknown'
                                        }
                                        imageBook={feed?.book?.cover_url}
                                        bookTitle={feed?.book?.book_title}
                                        bookRating={feed?.book?.avg_rating}
                                        total_comment={feed?.total_comment}
                                        total_like={feed?.total_like}
                                        senderId={feed?.sender?.id}
                                        bookId={feed?.book?.id}
                                        object_type={get(
                                            feed,
                                            'object_type',
                                            ''
                                        )}
                                        sender_type={get(
                                            feed,
                                            'sender_type',
                                            ''
                                        )}
                                        userId={idProfil}
                                        fromEpustakaDetail={fromEpustakaDetail}
                                    />
                                </div>
                            )
                        }
                        if (feed.feed_type === BOOK_REVIEW) {
                            return (
                                <div key={id}>
                                    <Post
                                        id={feed?.id}
                                        author={feed?.review?.book.authors}
                                        has_like={feed?.has_like}
                                        feed_type={feed?.feed_type}
                                        avatar_url={feed?.sender?.avatar_url}
                                        username={
                                            feed?.sender?.name || 'Unknown'
                                        }
                                        message={feed?.message}
                                        timestamp={feed?.updated_at}
                                        imageBook={get(
                                            feed,
                                            'review.book.cover_url',
                                            ''
                                        )}
                                        bookTitle={get(
                                            feed,
                                            'review.book.book_title',
                                            ''
                                        )}
                                        bookRating={get(
                                            feed,
                                            'review.book.avg_rating',
                                            ''
                                        )}
                                        review={get(
                                            feed,
                                            'review.rating_comment',
                                            ''
                                        )}
                                        total_comment={feed?.total_comment}
                                        total_like={feed?.total_like}
                                        senderId={feed?.sender?.id}
                                        bookId={feed?.review?.book.id}
                                        object_type={get(
                                            feed,
                                            'object_type',
                                            ''
                                        )}
                                        sender_type={get(
                                            feed,
                                            'sender_type',
                                            ''
                                        )}
                                        userId={idProfil}
                                        fromEpustakaDetail={fromEpustakaDetail}
                                    />
                                </div>
                            )
                        }
                        if (feed.feed_type === VISUAL_POST) {
                            return (
                                <div key={id}>
                                    <ActivityPost
                                        id={feed?.id}
                                        has_like={feed?.has_like}
                                        feed_type={feed?.feed_type}
                                        avatar_url={
                                            feed?.sender?.avatar_url || ''
                                        }
                                        username={
                                            feed?.sender?.name || 'Unknown'
                                        }
                                        timestamp={feed?.updated_at}
                                        message={feed?.message}
                                        total_comment={feed?.total_comment}
                                        total_like={feed?.total_like}
                                        imagePost={feed?.post?.image_url}
                                        description={feed?.post?.message}
                                        senderId={feed?.sender?.id}
                                        object_type={get(
                                            feed,
                                            'object_type',
                                            ''
                                        )}
                                        sender_type={get(
                                            feed,
                                            'sender_type',
                                            ''
                                        )}
                                        userId={idProfil}
                                        fromEpustakaDetail={fromEpustakaDetail}
                                        dataEpustaka={dataEpustaka}
                                    />
                                </div>
                            )
                        }
                        if (feed.feed_type === BOOK_RECOMMENDATION) {
                            return (
                                <div key={id}>
                                    <Post
                                        id={feed?.id}
                                        author={feed?.book?.authors}
                                        has_like={feed?.has_like}
                                        feed_type={feed?.feed_type}
                                        avatar_url={feed?.sender.avatar_url}
                                        message={translator.t(
                                            'feed.book_recommendation'
                                        )}
                                        timestamp={feed?.updated_at}
                                        username={get(feed, 'sender.name', '')}
                                        recommendation={feed.recommendation_target.map(
                                            (e, i) => {
                                                const length =
                                                    feed.recommendation_target
                                                        .length
                                                return i === length - 1
                                                    ? `${e.name}`
                                                    : `${e.name}, `
                                            }
                                        )}
                                        description={feed?.message}
                                        imageBook={get(
                                            feed,
                                            'book.cover_url',
                                            ''
                                        )}
                                        bookTitle={get(
                                            feed,
                                            'book.book_title',
                                            ''
                                        )}
                                        bookRating={get(
                                            feed,
                                            'book.avg_rating',
                                            ''
                                        )}
                                        total_comment={feed?.total_comment}
                                        total_like={feed?.total_like}
                                        senderId={feed?.sender?.id}
                                        bookId={get(feed, 'book.id', '')}
                                        object_type={get(
                                            feed,
                                            'object_type',
                                            ''
                                        )}
                                        sender_type={get(
                                            feed,
                                            'sender_type',
                                            ''
                                        )}
                                        userId={idProfil}
                                        fromEpustakaDetail={fromEpustakaDetail}
                                    />
                                </div>
                            )
                        }
                    })}
                </InfiniteScroll>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    stateTimeLine: get(state, 'timeLine', {}),
})

const mapDispatchToProps = () => ({
    setTimeLine: action.setTimeLine,
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Feed))
