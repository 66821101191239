import React, { useEffect, useState } from 'react'
import translator from 'i18next'
import {
    Modal,
    Row,
    Col,
    Rate,
    Form,
    Input,
    List,
    Button,
    Skeleton,
    Avatar,
    message,
} from 'antd'
import { FallbackImage } from 'components'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import { SearchOutlined } from '@ant-design/icons'
import provider from 'providers/provider'
import url from 'constants/url'
import { useHistory } from 'react-router'
import getAcronym from 'components/Acronym'
import { COLORS } from 'constants/constant'

export default function ModalRecommendation({
    visibleModal,
    setVisibleModal,
    bookDetailData,
    bookData,
    data,
    paginationFollower,
    onLoadMore,
    userId,
    // organization_id,
    setQuerySearchFollower,
    source,
    getDataFollower,
    loadingFollowe,
    totalFollower,
    limitFollower,
}) {
    const [form] = Form.useForm()
    const filter =
        source === 'bookshelf' || source === 'bookshelfdonation'
            ? bookData
            : bookDetailData
    const filterIdBook =
        source === 'bookshelf'
            ? get(bookData, 'book_id', '')
            : source === 'bookshelfdonation'
            ? get(bookData, 'id', '')
            : get(bookDetailData, 'id', '')
    const history = useHistory()
    const [dataFollower, setDataBorrower] = useState([])
    const [messageSend, setMessageSend] = useState('')
    const [state, setState] = useState({
        loadings: [],
    })

    useEffect(() => {
        setDataBorrower(data)
    }, [data])

    const handleRecommendation = async (item, index) => {
        setState(({ loadings }) => {
            const newLoadings = [...loadings]
            newLoadings[index] = true

            return {
                loadings: newLoadings,
            }
        })
        const data = {
            feed_type: 'BOOK_RECOMMENDATION',
            object_id: filterIdBook,
            object_type: 'BOOK',
            feed_message: messageSend,
            recommendation_target: [{ id: item.id, name: item.name }],
        }

        await provider.insert(url.webhookURL_feed, data).then(() => {
            setState(({ loadings }) => {
                const newLoadings = [...loadings]
                newLoadings[index] = false

                return {
                    loadings: newLoadings,
                }
            })
            form.resetFields()
            setVisibleModal(false)
            message.success('Rekomendasi berhasil terkirim.')
        })
    }

    const handleSearch = (e) => {
        setQuerySearchFollower(e.query)
    }

    const onClearSearch = (e) => {
        if (e.target.value === '') {
            setQuerySearchFollower('')
            getDataFollower()
        }
    }

    const DisplayRate = () => {
        if (source === 'bookshelf' || source === 'bookshelfdonation') {
            return <div />
        }
        return (
            <div>
                <Rate
                    disabled
                    defaultValue={get(bookDetailData, 'avg_rating', 0)}
                    value={get(bookDetailData, 'avg_rating', 0)}
                    className="star"
                    allowHalf
                />{' '}
                <span className="rating">
                    {!isNull(get(bookDetailData, 'avg_rating', 67.56)) &&
                        get(bookDetailData, 'avg_rating', 67.56).toFixed(1)}
                </span>
            </div>
        )
    }

    return (
        <Modal
            centered
            title={translator.t('action.recommendation')}
            visible={visibleModal}
            onOk={() => setVisibleModal(!visibleModal)}
            onCancel={() => {
                setVisibleModal(!visibleModal)
                form.resetFields()
            }}
            footer={null}
            className="modal-popup"
            width={600}
            bodyStyle={{
                paddingTop: '0px',
            }}
        >
            <Row className="mt-md detail-book-modal">
                <Col lg={8} md={9} sm={10}>
                    <div className="cover-image">
                        <FallbackImage
                            src={get(filter, 'cover_url', '')}
                            className="cover-book"
                        />
                    </div>
                </Col>
                <Col lg={16} md={15} sm={14} className="description-book">
                    <h3 className="book-title">{get(filter, 'book_title')}</h3>
                    <p className="book-author">
                        {source === 'bookshelfdonation'
                            ? filter?.author_name
                            : filter?.book_author}
                    </p>
                    <DisplayRate />
                </Col>
            </Row>

            <Form form={form}>
                <Form.Item name="recommendMessage">
                    <Input
                        placeholder={translator.t('action.writeMessage')}
                        bordered={false}
                        size="large"
                        onChange={(e) => setMessageSend(e.target.value)}
                        style={{ marginTop: 15 }}
                    />
                </Form.Item>
            </Form>

            <div className="header-container search" style={{ padding: 0 }}>
                <Form className="search-form" onFinish={handleSearch}>
                    <Form.Item
                        name="query"
                        rules={[{ require: true, message: 'Harus diisi' }]}
                    >
                        <Input
                            placeholder={translator.t('action.searchUser')}
                            bordered={false}
                            suffix={
                                <SearchOutlined
                                    style={{
                                        color: COLORS.primaryColor,
                                    }}
                                />
                            }
                            onChange={(e) => onClearSearch(e)}
                            allowClear
                            size="large"
                            // onPressEnter={(e) => handleSearch(e)}
                        />
                    </Form.Item>
                </Form>
            </div>

            <List
                style={{
                    textAlign: 'left',
                    maxHeight: '30vh',
                    overflow: 'auto',
                }}
                className="bc-container"
                itemLayout="horizontal"
                dataSource={dataFollower}
                footer={
                    source === 'bookshelfdonation' ? (
                        totalFollower > limitFollower && (
                            <div style={{ textAlign: 'center' }}>
                                <Button
                                    type="primary"
                                    loading={loadingFollowe}
                                    onClick={onLoadMore}
                                >
                                    {translator.t('filter.more')}
                                </Button>
                            </div>
                        )
                    ) : paginationFollower.pagination &&
                      !paginationFollower.loading &&
                      !isEmpty(dataFollower) ? (
                        <div style={{ textAlign: 'center' }}>
                            <Button type="primary" onClick={onLoadMore}>
                                {translator.t('filter.more')}
                            </Button>
                        </div>
                    ) : (
                        ''
                    )
                }
                renderItem={(item, key) => {
                    const { avatar_url, name, id } = item
                    const { color, acronym } = getAcronym(name || '', id)

                    return (
                        <List.Item
                            actions={[
                                userId !== item.id && (
                                    <Button
                                        type="primary"
                                        onClick={() =>
                                            handleRecommendation(item, key)
                                        }
                                        size="middle"
                                        style={{ width: 90 }}
                                        loading={state.loadings[key]}
                                    >
                                        {translator.t('action.send')}
                                    </Button>
                                ),
                            ]}
                        >
                            <Skeleton
                                avatar
                                title={false}
                                loading={
                                    source !== 'bookshelfdonation' &&
                                    paginationFollower.loading
                                }
                                active
                            >
                                <List.Item.Meta
                                    style={{
                                        alignItems: 'baseline',
                                        cursor: 'pointer',
                                    }}
                                    avatar={
                                        <Avatar
                                            style={{
                                                backgroundImage: color,
                                            }}
                                            src={avatar_url}
                                        >
                                            <span className="avatar-name">
                                                {acronym}
                                            </span>
                                        </Avatar>
                                    }
                                    title={name}
                                    onClick={() =>
                                        history.push(`/profil/user/${id}`)
                                    }
                                />
                            </Skeleton>
                        </List.Item>
                    )
                }}
            />
        </Modal>
    )
}
