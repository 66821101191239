import store from '../store'
import * as ActionType from './contant'

export default {
    setBookmark: (value) => {
        const action = { type: ActionType.SET_BOOKMARK, value }
        store.dispatch(action)
    },
    clearBookmark: () => {
        const action = { type: ActionType.CLEAR_BOOKMARK }
        store.dispatch(action)
    },
}
