import store from '../store'
import * as ActionType from './contant'

export default {
    setTimeLine: (value) => {
        const action = { type: ActionType.SET_TIMELINE, value }
        store.dispatch(action)
    },
    clearTimeLine: () => {
        const action = { type: ActionType.CLEAR_TIMELINE }
        store.dispatch(action)
    },
}
