import React, { useState, useEffect } from 'react'
import { Modal, Button, Avatar, List } from 'antd'

import provider from 'providers/provider'
import url from 'constants/url'
import translator from 'i18next'
import { useHistory } from 'react-router'
import getAcronym from 'components/Acronym'
import { error } from 'utils/notification'
import { get, isEmpty } from 'lodash'

const ModalDownloadBook = ({
    visibleModal,
    setVisibleModal,
    userId,
    bookId,
    onReadBy,
    bookOutOfStock,
    handleBookQueue,
    handleClickRead,
    checkBorrowStatus,
    bookDetailData,
}) => {
    const history = useHistory()

    const [stateFollow, setStateFollow] = useState({})
    const [bookBorrower, setBookBorrower] = useState({
        data: {},
        total: 0,
        loading: false,
    })
    const [loadingFollow, setLoadingFollow] = useState(false)
    const [limitBorrowList, setLimitBorrowList] = useState(15)

    const getDataBorrower = async () => {
        setBookBorrower({ ...bookBorrower, loading: true })
        const all = onReadBy ? `&all=true` : ''
        await provider
            .list(
                `${url.webhookURL_book_borrower_list}?q=&book_id=${bookId}&limit=${limitBorrowList}${all}`
            )
            .then((res) => {
                const { data, meta } = res
                setBookBorrower({
                    data: data,
                    total: meta?.total,
                    loading: false,
                })
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setBookBorrower({ ...bookBorrower, loading: false })
            })
    }

    useEffect(() => {
        getDataBorrower()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleModal, limitBorrowList, loadingFollow, bookId])

    const handleFollow = async (item, index, isFollowed) => {
        setLoadingFollow(true)
        setStateFollow(item)
        const data = {
            user_id: item.id,
        }

        if (isFollowed) {
            await provider
                .deleteData(`${url.webhookURL_user_follow}`, {
                    id: item.follow_id,
                })
                .then(() => {
                    setLoadingFollow(false)
                })
                .catch((err) => {
                    setStateFollow(({ loadings }) => {
                        const newLoadings = [...loadings]
                        newLoadings[index] = false

                        return {
                            loadings: newLoadings,
                        }
                    })
                    error({
                        description: err.message,
                        placement: 'bottomRight',
                    })
                })
        } else {
            await provider
                .insert(url.webhookURL_user_follow, data)
                .then(() => {
                    setLoadingFollow(false)
                })
                .catch((err) => {
                    error({
                        description: err.message,
                        placement: 'bottomRight',
                    })
                })
        }
    }

    const loadMore = bookBorrower?.total > limitBorrowList && (
        <div
            style={{
                textAlign: 'center',
                marginTop: 20,
                height: 32,
                lineHeight: '32px',
            }}
        >
            <Button
                loading={!loadingFollow && bookBorrower?.loading}
                onClick={() => {
                    bookBorrower?.total > limitBorrowList &&
                        setLimitBorrowList((e) => e + 15)
                }}
            >
                {translator.t('action.more')}
            </Button>
        </div>
    )

    return (
        <Modal
            centered
            visible={visibleModal}
            onOk={() => setVisibleModal(!visibleModal)}
            onCancel={() => setVisibleModal(!visibleModal)}
            footer={null}
            className="modal-popup"
            width={500}
            title={translator.t(
                !onReadBy ? 'title.borrowers_list' : 'title.reader_list'
            )}
        >
            {!isEmpty(bookBorrower?.data) ? (
                <List
                    style={{
                        maxHeight: 450,
                        overflow: 'scroll',
                    }}
                    className="bc-container"
                    itemLayout="horizontal"
                    dataSource={bookBorrower?.data}
                    loadMore={loadMore}
                    renderItem={(item, key) => {
                        const {
                            avatar_url,
                            followed,
                            id,
                            name,
                            borrower_type,
                            copy,
                        } = item
                        const { color, acronym } = getAcronym(name || '', id)

                        return (
                            <List.Item
                                actions={[
                                    userId !== item.id &&
                                        borrower_type === 'USER' && (
                                            <Button
                                                type={
                                                    !followed
                                                        ? 'primary'
                                                        : 'default'
                                                }
                                                onClick={(e) => {
                                                    e?.currentTarget?.blur()
                                                    handleFollow(
                                                        item,
                                                        key,
                                                        followed
                                                    )
                                                }}
                                                size="middle"
                                                style={{ width: 'fit-content' }}
                                                loading={
                                                    item.id ===
                                                        stateFollow?.id &&
                                                    loadingFollow
                                                }
                                            >
                                                {followed
                                                    ? translator.t(
                                                          'action.cancel_follow'
                                                      )
                                                    : translator.t(
                                                          'action.follow'
                                                      )}
                                            </Button>
                                        ),
                                ]}
                            >
                                <List.Item.Meta
                                    style={{
                                        alignItems: 'baseline',
                                        cursor:
                                            borrower_type !== 'USER'
                                                ? 'default'
                                                : 'pointer',
                                    }}
                                    avatar={
                                        <Avatar
                                            style={{
                                                backgroundImage: color,
                                            }}
                                            src={avatar_url}
                                        >
                                            <span className="avatar-name">
                                                {acronym}
                                            </span>
                                        </Avatar>
                                    }
                                    title={name}
                                    description={
                                        borrower_type !== 'USER' && (
                                            <p style={{ marginBottom: '0px' }}>
                                                {copy} copy
                                            </p>
                                        )
                                    }
                                    {...(borrower_type === 'USER' && {
                                        onClick: () =>
                                            history.push(`/profil/user/${id}`),
                                    })}
                                />
                            </List.Item>
                        )
                    }}
                />
            ) : (
                <div className="modal-description">
                    <h4 className="desc-title">
                        {translator.t('message.no_borrow', {
                            option: onReadBy ? 'Pembaca' : 'Peminjam',
                        })}
                    </h4>
                    {!checkBorrowStatus.status ? (
                        <>
                            <p>
                                {translator.t('message.firstPerson', {
                                    option: onReadBy ? 'membaca' : 'meminjam',
                                })}
                            </p>
                            <br />
                            <Button
                                className="button-borrow"
                                onClick={() => {
                                    if (bookOutOfStock) {
                                        handleBookQueue()
                                    } else {
                                        handleClickRead(
                                            checkBorrowStatus.status
                                        )
                                        setVisibleModal(false)
                                    }
                                }}
                                disabled={
                                    bookDetailData?.using_drm &&
                                    get(bookDetailData, 'all_qty', 0) <= 0
                                }
                            >
                                {translator.t('action.borrowNow')}
                            </Button>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </Modal>
    )
}

export default ModalDownloadBook
