import oAuth from 'providers/auth'
import Cookies from 'js-cookie'
import { firebaseCloudMessaging } from 'utils/firebase'
import searchAction from 'redux/search/action'

const HandleLogout = async (history) => {
    await oAuth.logout().then(async () => {
        Cookies.remove('ewAccessToken')
        localStorage.clear()
        searchAction.clearSearch()
        await firebaseCloudMessaging.clearToken()
        history.push('/login')
    })
}

export default HandleLogout
