import React, { useEffect, useState } from 'react'
import { Modal, Button, Row, Col, Avatar, List, Skeleton } from 'antd'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import provider from 'providers/provider'
import url from 'constants/url'
import translator from 'i18next'
import getAcronym from 'components/Acronym'
import { error } from 'utils/notification'
import { get } from 'lodash'
import { useHistory } from 'react-router'

const ModalQueueList = ({
    data = {},
    visibleModal,
    setVisibleModal,
    paginationQueue,
    onLoadMore,
    userId,
    getDataQueue,
    setDataQueueChanged,
    bookOutOfStock,
    handleBookQueue,
    handleClickRead,
    checkBorrowStatus,
    bookDetailData,
}) => {
    const [dataQueue, setDataQueue] = useState([])
    const history = useHistory()
    const [state, setState] = useState({
        loadings: [],
    })

    useEffect(() => {
        setDataQueue(data)
    }, [data])

    const handleFollow = async (item, index, isFollowed) => {
        setDataQueueChanged(true)

        setState(({ loadings }) => {
            const newLoadings = [...loadings]
            newLoadings[index] = true

            return {
                loadings: newLoadings,
            }
        })

        const data = {
            user_id: item.id,
        }

        if (isFollowed) {
            await provider
                .deleteData(`${url.webhookURL_user_follow}`, {
                    id: item.follow_id,
                })
                .then(() => {
                    const othersData = filter(
                        dataQueue,
                        (e) => e.id !== item.id
                    )
                    const selectedData = find(dataQueue, { id: item.id })
                    const result = { ...selectedData, followed: false }
                    setDataQueue(concat(othersData, result))
                    getDataQueue()
                    setState(({ loadings }) => {
                        const newLoadings = [...loadings]
                        newLoadings[index] = false

                        return {
                            loadings: newLoadings,
                        }
                    })
                })
                .catch((err) => {
                    setState(({ loadings }) => {
                        const newLoadings = [...loadings]
                        newLoadings[index] = false

                        return {
                            loadings: newLoadings,
                        }
                    })
                    error({
                        description: err.message,
                        placement: 'bottomRight',
                    })
                })
        } else {
            await provider
                .insert(url.webhookURL_user_follow, data)
                .then(() => {
                    const othersData = filter(
                        dataQueue,
                        (e) => e.id !== item.id
                    )
                    const selectedData = find(dataQueue, {
                        id: item.id,
                    })
                    const result = { ...selectedData, followed: true }
                    setDataQueue(concat(othersData, result))
                    getDataQueue()
                    setState(({ loadings }) => {
                        const newLoadings = [...loadings]
                        newLoadings[index] = false

                        return {
                            loadings: newLoadings,
                        }
                    })
                })
                .catch((err) => {
                    setState(({ loadings }) => {
                        const newLoadings = [...loadings]
                        newLoadings[index] = false

                        return {
                            loadings: newLoadings,
                        }
                    })
                    error({
                        description: err.message,
                        placement: 'bottomRight',
                    })
                })
        }
    }

    return (
        <Modal
            centered
            visible={visibleModal}
            onOk={() => setVisibleModal(!visibleModal)}
            onCancel={() => setVisibleModal(!visibleModal)}
            footer={null}
            className="modal-popup"
            width={500}
        >
            <Row justify="center">
                <Col
                    className="mt-lg"
                    span={20}
                    style={{ textAlign: 'center' }}
                >
                    <h3 className="borrow-title">
                        {translator.t('title.queue_list')}
                    </h3>
                    {!isEmpty(dataQueue) ? (
                        <List
                            style={{ textAlign: 'left' }}
                            className="bc-container"
                            itemLayout="horizontal"
                            dataSource={dataQueue}
                            footer={
                                paginationQueue.pagination &&
                                !paginationQueue.loading &&
                                !isEmpty(dataQueue) ? (
                                    <div>
                                        <Button
                                            type="primary"
                                            onClick={onLoadMore}
                                        >
                                            {translator.t('filter.more')}
                                        </Button>
                                    </div>
                                ) : (
                                    ''
                                )
                            }
                            renderItem={(item, key) => {
                                const {
                                    avatar_url,
                                    // follow_id,
                                    followed,
                                    id,
                                    name,
                                } = item
                                const { color, acronym } = getAcronym(
                                    name || '',
                                    id
                                )

                                return (
                                    <List.Item
                                        key={key}
                                        actions={[
                                            userId !== item.id && (
                                                <Button
                                                    type={
                                                        !followed
                                                            ? 'primary'
                                                            : 'default'
                                                    }
                                                    onClick={(e) => {
                                                        e?.currentTarget?.blur()
                                                        handleFollow(
                                                            item,
                                                            key,
                                                            followed
                                                        )
                                                    }}
                                                    size="middle"
                                                    style={{ width: 90 }}
                                                    loading={
                                                        state.loadings[key]
                                                    }
                                                >
                                                    {followed
                                                        ? translator.t(
                                                              'action.followed'
                                                          )
                                                        : translator.t(
                                                              'action.follow'
                                                          )}
                                                </Button>
                                            ),
                                        ]}
                                    >
                                        <Skeleton
                                            avatar
                                            title={false}
                                            loading={paginationQueue.loading}
                                            active
                                        >
                                            <List.Item.Meta
                                                style={{
                                                    alignItems: 'baseline',
                                                    cursor: 'pointer',
                                                }}
                                                avatar={
                                                    <Avatar
                                                        style={{
                                                            backgroundImage:
                                                                color,
                                                        }}
                                                        src={avatar_url}
                                                    >
                                                        <span className="avatar-name">
                                                            {acronym}
                                                        </span>
                                                    </Avatar>
                                                }
                                                title={name}
                                                onClick={() =>
                                                    history.push(
                                                        `/profil/user/${id}`
                                                    )
                                                }
                                            />
                                        </Skeleton>
                                    </List.Item>
                                )
                            }}
                        />
                    ) : (
                        <div className="modal-description">
                            <h4 className="desc-title">
                                {translator.t('message.no_queue')}
                            </h4>
                            {!checkBorrowStatus.status ? (
                                <>
                                    {bookDetailData?.all_user_borrow_count ===
                                    0 ? (
                                        <p>
                                            {translator.t(
                                                'message.firstPerson',
                                                {
                                                    option: 'meminjam',
                                                }
                                            )}
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                    <br />
                                    <Button
                                        className="button-borrow"
                                        onClick={() => {
                                            if (bookOutOfStock) {
                                                handleBookQueue()
                                            } else {
                                                handleClickRead(
                                                    checkBorrowStatus.status
                                                )
                                                setVisibleModal(false)
                                            }
                                        }}
                                        disabled={
                                            bookDetailData?.using_drm &&
                                            get(bookDetailData, 'all_qty', 0) <=
                                                0
                                        }
                                    >
                                        {translator.t('action.borrowNow')}
                                    </Button>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    )}
                </Col>
            </Row>
        </Modal>
    )
}

export default ModalQueueList
