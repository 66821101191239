import dayjs from 'dayjs'
import 'dayjs/locale/id'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'

export const useDay = () => {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.extend(advanced)
    function dateFormatter(date, format) {
        const timeZone = dayjs.tz.guess()
        return dayjs(date)
            .tz(timeZone || 'Asia/Jakarta')
            .locale('id')
            .format(format)
    }

    return { dateFormatter }
}
