const getPaginationGraphQL = (currentPage, limitPage, totalData) => {
    const offset = (currentPage - 1) * limitPage
    const last_offset = limitPage * currentPage
    return {
        offset,
        loadMore: last_offset > totalData ? false : true,
    }
}

export default getPaginationGraphQL
