import React from 'react'
import { Modal, Button, Row, Col } from 'antd'
import translator from 'i18next'
import { useHistory } from 'react-router'

const ModalContent = ({
    visibleModal,
    setVisibleModal,
    title,
    contentPrivate,
}) => {
    const router = useHistory()

    const handleJoinEpustaka = async () => {
        if (contentPrivate) {
            setVisibleModal(!visibleModal)
        } else {
            router.replace('/')
        }
    }

    return (
        <Modal
            centered
            visible={visibleModal}
            onOk={() => setVisibleModal(!visibleModal)}
            // onCancel={() => setVisibleModal(!visibleModal)}
            footer={null}
            className="modal-popup"
            closable={false}
            width={500}
            maskClosable={false}
            maskStyle={{
                backdropFilter: 'blur(3px)',
            }}
        >
            <Row justify="center">
                <Col
                    className="mt-lg"
                    span={20}
                    style={{ textAlign: 'center' }}
                >
                    <p>
                        {contentPrivate
                            ? translator.t('message.no_access_content', {
                                  content: translator.t(title),
                              })
                            : translator.t('message.content_not_found')}
                    </p>

                    <Button
                        className="btn-popup-modal"
                        size="large"
                        onClick={handleJoinEpustaka}
                    >
                        {translator.t('action.close')}
                    </Button>
                </Col>
            </Row>
        </Modal>
    )
}

export default ModalContent
