import React, { useState } from 'react'
import { Modal, Button, Row, Col, Avatar } from 'antd'
import translator from 'i18next'
import provider from 'providers/provider'
import url from 'constants/url'
import { error, success } from 'utils/notification'
import getAcronym from 'components/Acronym'
import { useHistory } from 'react-router'

const ModalContent = ({ data, visibleModal, setVisibleModal, dataRefresh }) => {
    const [loading, setLoading] = useState(false)
    const router = useHistory()

    const name = data?.epustaka_name || ''

    const epustakaId = data?.id || ''

    const expiredData = data?.has_join && data?.member_status === 3
    const waitingData = data?.has_join && data?.member_status === 0

    const { color, acronym } = getAcronym(name, epustakaId)

    const handleJoinEpustaka = async () => {
        setLoading(true)
        const dataReg = {
            epustaka_id: epustakaId,
        }

        await provider
            .insert(url.webhookURL_join_epustaka_member, dataReg)
            .then((res) => {
                setLoading(false)
                dataRefresh()
                setVisibleModal(false)
                success({
                    description: res.data.message,
                    placement: 'bottomRight',
                })
            })
            .catch((err) => {
                setLoading(false)
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    return (
        <Modal
            centered
            visible={visibleModal}
            onOk={() => setVisibleModal(!visibleModal)}
            onCancel={() => {
                router.goBack()
            }}
            footer={null}
            className="modal-popup"
            closable={false}
            width={500}
            maskClosable={false}
            maskStyle={{
                backdropFilter: 'blur(3px)',
            }}
        >
            <Row justify="center">
                <Col
                    className="mt-lg"
                    span={20}
                    style={{ textAlign: 'center' }}
                >
                    <h2 className="epustaka-name">{name}</h2>
                    <Avatar
                        size={100}
                        shape="square"
                        style={{
                            color: '#f56a00',
                            backgroundImage: color,
                        }}
                        src={data?.epustaka_logo}
                    >
                        {acronym}
                    </Avatar>
                    <div className="title" style={{ textAlign: 'center' }}>
                        {expiredData
                            ? translator.t('message.expiredJoinEpustaka')
                            : waitingData
                            ? translator.t('message.waitingJoin')
                            : translator.t('message.notJoinEpustaka')}
                    </div>

                    {!waitingData && (
                        <Button
                            loading={loading}
                            className="btn-popup-modal"
                            size="large"
                            onClick={handleJoinEpustaka}
                        >
                            {expiredData
                                ? translator.t('title.extend')
                                : translator.t('epustka.join')}
                        </Button>
                    )}
                </Col>
            </Row>
        </Modal>
    )
}

export default ModalContent
