import React from 'react'
import { Modal, List, message } from 'antd'
import translator from 'i18next'
import './ModalShare.scss'
import { LinkOutlined } from '@ant-design/icons'
import {
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from 'react-share'
import {
    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon,
} from 'react-share'
import isUndefined from 'lodash/isUndefined'
const ModalVideoShare = ({
    data = {},
    visibleModal,
    setVisibleModal,
    urlFrom,
    epustakaId,
    collectionId,
}) => {
    let title = ''
    if (!isUndefined(data.book_title)) {
        title = data.book_title
    } else if (!isUndefined(data.epustaka_name)) {
        title = data.epustaka_name
    } else if (!isUndefined(data.media_title)) {
        title = data.media_title
    }

    let urlData = ''
    if (!isUndefined(data.book_id)) {
        urlData = data.book_id
    } else {
        urlData = data.id
    }

    const messageShare = `${title} ${'\n'} ${translator.t(
        'message.message_share'
    )}`
    const urlVideo =
        !isUndefined(collectionId) || !isUndefined(epustakaId)
            ? `${window.location.origin}/${urlFrom}/${collectionId}${
                  epustakaId ? '/' + epustakaId : ''
              }`
            : `${window.location.origin}/${urlFrom}/${urlData}`

    const urlAudio =
        !isUndefined(collectionId) || !isUndefined(epustakaId)
            ? `${window.location.origin}/${urlFrom}/${collectionId}${
                  epustakaId ? '/' + epustakaId : ''
              }`
            : `${window.location.origin}/${urlFrom}/${urlData}`

    const urlBook = `${window.location.origin}/${urlFrom}/${urlData}`

    const url =
        urlFrom === 'audio'
            ? urlAudio
            : urlFrom === 'video'
            ? urlVideo
            : urlBook

    const dataSocmed = [
        {
            avatar: <FacebookIcon size={32} round={true} />,
            title: (
                <div>
                    <FacebookShareButton url={url} quote={messageShare}>
                        <div className="share-btn">Facebook</div>
                    </FacebookShareButton>
                </div>
            ),
        },
        {
            avatar: <TwitterIcon size={32} round={true} />,
            title: (
                <div>
                    <TwitterShareButton url={url} title={messageShare}>
                        <div className="share-btn">Twitter</div>
                    </TwitterShareButton>
                </div>
            ),
        },
        {
            avatar: <TelegramIcon size={32} round={true} />,
            title: (
                <div>
                    <TelegramShareButton url={url} title={messageShare}>
                        <div className="share-btn">Telegram</div>
                    </TelegramShareButton>
                </div>
            ),
        },
        {
            avatar: <WhatsappIcon size={32} round={true} />,
            title: (
                <div>
                    <WhatsappShareButton
                        url={url}
                        title={messageShare}
                        separator=":: "
                    >
                        <div className="share-btn">WhatsApp</div>
                    </WhatsappShareButton>
                </div>
            ),
        },
        {
            avatar: <LinkOutlined style={{ fontSize: 32 }} />,
            title: (
                <div
                    style={{ cursor: 'pointer' }}
                    type="link"
                    onClick={() => {
                        navigator.clipboard.writeText(url)
                        message.success(`${translator.t('message.copied')}`)
                    }}
                >
                    <div className="share-btn">
                        {translator.t('filter.copy_link')}
                    </div>
                </div>
            ),
        },
    ]

    return (
        <div>
            <Modal
                className="mvs-container"
                width={370}
                title={
                    <span style={{ textAlign: 'center' }}>
                        {translator.t('title.share_to')}
                    </span>
                }
                visible={visibleModal}
                onOk={() => setVisibleModal(!visibleModal)}
                onCancel={() => setVisibleModal(!visibleModal)}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={dataSocmed}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={item.avatar}
                                title={item.title}
                            />
                        </List.Item>
                    )}
                />
            </Modal>
        </div>
    )
}

export default ModalVideoShare
