import React from 'react'
import { Row, Col } from 'antd'
import Feed from './feed/Feed'
// import MessageSender from './feed/MessageSender'
import getRoleCode from 'utils/getRoleCode'
import { useSelector } from 'react-redux'
import { LAYOUT } from 'constants/constant'

const Guest = getRoleCode('Guest')

export default function Timeline() {
    const profile = useSelector((state) => state.profile)
    const role = profile?.role?.role_code

    return (
        <Row type="flex" style={{ alignItems: 'center' }} justify="center">
            <Col
                xs={24}
                sm={19}
                md={20}
                lg={24}
                xl={24}
                className="site-layout-content"
                style={{ maxWidth: LAYOUT.maxWidth }}
            >
                {role === Guest ? (
                    <Feed />
                ) : (
                    <>
                        {/* {role !== Guest && <MessageSender />} */}
                        <Feed />
                    </>
                )}
            </Col>
        </Row>
    )
}
