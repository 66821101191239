import { Row, Col, Card, Avatar, Menu, Button, Skeleton, Tabs } from 'antd'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import translator from 'i18next'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import { categoryOrderBox } from 'constants/constant'

import React, { useState, useEffect } from 'react'
import { BASE_TITLE, COLORS, LAYOUT } from 'constants/constant'
import menuKeyDonasi from 'redux/donasiMenuActive/action'
import { ic_transaction } from 'assets/icons'
import url from 'constants/url'
import provider from 'providers/provider'
import notificationHandler from 'utils/notificationHandler'
import ModalConfirmPayment from './components/ModalConfirmPayment'
import ModalDonationProgram from './components/ModalDonationProgram'
import tabKeyAction from 'redux/tabKey/action'
import { DAY_MONTH_STRING_FULL_FORMAT } from 'constants/formats'

import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import localeData from 'dayjs/plugin/localeData'
import 'dayjs/locale/id'

dayjs.locale('id')
dayjs.extend(LocalizedFormat)
dayjs.extend(localeData)

const { TabPane } = Tabs

const mapStateToProps = (state) => ({
    menuActiveDonasi: state.menuActiveDonasi,
})
const Donation = (props) => {
    const { menuActiveDonasi } = props
    const history = useHistory()
    const defaultLimit = 15
    const [dataSource, setDataSource] = useState([])
    const [limit, setLimit] = useState(defaultLimit)
    const [total, setTotal] = useState(0)
    const [offset, setOffset] = useState(0)
    const [loading, setLoading] = useState(true)
    const [loadingLoadMore, setLoadingLoadMore] = useState(false)
    const [trigger, setTrigger] = useState(false)

    const [orderBoxId, setOrderBoxId] = useState('')
    const [visibleConfirmPayment, setVisibleConfirmPayment] = useState(false)
    const [visibleDonation, setVisibleDonation] = useState(false)

    //improve from donation to transsaction
    const [activeKey, setActiveKey] = useState('DONATION')

    const checkActiveMenu = (key) => {
        const selectedKey = key.split('menu ')
        menuKeyDonasi.setMenuActiveDonasi(`${selectedKey}`)
    }

    const getDataTransaction = async () => {
        await provider
            .list(
                `${
                    url.webhookURL_order_box_list
                }?limit=${limit}&offset=${offset}&status=${
                    menuActiveDonasi === '1' ? 'ongoing' : 'completed'
                }&category=${activeKey}`
            )
            .then((res) => {
                const { data, meta } = res
                if (limit === defaultLimit) {
                    if (offset === 0) {
                        setDataSource(data)
                    } else {
                        setDataSource(dataSource.concat(data))
                    }
                } else {
                    setDataSource(data)
                }
                setTotal(meta?.total)
                setLoading(false)
                setLoadingLoadMore(false)
            })
            .catch((err) => {
                notificationHandler('dev2', err)
                setLoading(false)
            })
    }

    useEffect(() => {
        getDataTransaction()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger])

    const loadMore = () => {
        if (dataSource?.length < total) {
            return (
                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <Button
                        loading={loadingLoadMore}
                        onClick={() => {
                            setLoadingLoadMore(true)
                            if (limit === defaultLimit) {
                                setOffset(offset + defaultLimit)
                            } else {
                                setLimit(defaultLimit)
                                setOffset(limit)
                            }
                            setTrigger(!trigger)
                        }}
                    >
                        {translator.t('action.more')}
                    </Button>
                </div>
            )
        } else {
            return <></>
        }
    }

    const getCountLabel = (value) => {
        switch (value) {
            case 'DONATION':
                return translator.t('title.book_title')

            case 'PRODUCT':
                return translator.t('title.product')

            default:
                return 'Item'
        }
    }

    const contentMenu = () => {
        return (
            <React.Fragment>
                {loading ? (
                    <div
                        style={{
                            backgroundColor: 'white',
                            borderRadius: 10,
                            padding: 24,
                            marginTop: 15,
                        }}
                    >
                        <Skeleton />
                    </div>
                ) : isEmpty(dataSource) ? (
                    activeKey === 'DONATION' ? (
                        <div
                            style={{
                                textAlign: 'center',
                                padding: 24,
                                maxWidth: 600,
                                margin: '0px auto',
                                marginTop: 20,
                            }}
                        >
                            <h3>
                                {menuActiveDonasi === '1'
                                    ? translator.t(
                                          'message.empty_status_donation'
                                      )
                                    : translator.t(
                                          'message.empty_history_donation'
                                      )}
                            </h3>
                            <p>{translator.t('message.empty_data_donation')}</p>
                            {menuActiveDonasi === '1' && (
                                <div className="donation-now">
                                    <Button
                                        type="primary"
                                        size="large"
                                        style={{
                                            borderRadius: 20,
                                            marginTop: 30,
                                            fontSize: 15,
                                            fontWeight: 500,
                                        }}
                                        onClick={() => setVisibleDonation(true)}
                                    >
                                        {translator.t('action.donate_now')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div
                            style={{
                                textAlign: 'center',
                                padding: 24,
                                maxWidth: 600,
                                margin: '0px auto',
                                marginTop: 20,
                            }}
                        >
                            <h3>
                                {menuActiveDonasi === '1'
                                    ? translator.t('payment.messageNodata')
                                    : translator.t(
                                          'payment.messageNodataHistory'
                                      )}
                            </h3>
                            <p>
                                {translator.t('payment.messageNodataDetail', {
                                    type: categoryOrderBox
                                        ?.find((e) => e?.value === activeKey)
                                        ?.text?.toLowerCase(),
                                    action:
                                        categoryOrderBox?.find(
                                            (e) => e?.value === activeKey
                                        )?.text === 'Tryout'
                                            ? 'kerjakan'
                                            : 'beli',
                                })}
                            </p>
                            {menuActiveDonasi === '1' && (
                                <div className="donation-now">
                                    <Button
                                        type="primary"
                                        size="large"
                                        style={{
                                            borderRadius: 20,
                                            marginTop: 20,
                                            fontSize: 15,
                                            fontWeight: 500,
                                            padding: '8px 25px',
                                            textAlign: 'center',
                                        }}
                                        onClick={() => {
                                            if (
                                                categoryOrderBox?.find(
                                                    (e) =>
                                                        e?.value === activeKey
                                                )?.text === 'Tryout'
                                            ) {
                                                tabKeyAction.setDataTabKey(5)
                                                return history.push({
                                                    pathname: '/',
                                                })
                                            } else {
                                                return history.push({
                                                    pathname: '/more-edoo-toys',
                                                })
                                            }
                                        }}
                                    >
                                        {activeKey === 'TRYOUT'
                                            ? translator.t(
                                                  'payment.choosePackage'
                                              )
                                            : translator.t(
                                                  'title.choose_product'
                                              )}
                                    </Button>
                                </div>
                            )}
                        </div>
                    )
                ) : (
                    <React.Fragment>
                        <h3 className="status-donation-title">
                            {menuActiveDonasi === '1'
                                ? translator.t('title.status_transaction')
                                : translator.t('title.history_transaction')}
                        </h3>
                        {dataSource?.map((item, key) => {
                            const totalPayment =
                                item?.amount -
                                item?.discount_amount +
                                item?.service_fee_amount +
                                item?.tax_amount +
                                item?.shipping_fee_amount

                            let labelStatus, styleStatus
                            switch (item?.payment_status) {
                                case 0:
                                    labelStatus =
                                        activeKey === 'DONATION'
                                            ? translator.t(
                                                  'payment.proccessDonation'
                                              )
                                            : translator.t(
                                                  'payment.proccessPayment'
                                              )
                                    styleStatus = {
                                        color: COLORS.primaryColor,
                                        fontWeight: 500,
                                    }
                                    break

                                case 1:
                                    labelStatus = translator.t(
                                        'payment.waiting_payment'
                                    )
                                    styleStatus = {
                                        color: COLORS.primaryColor,
                                        fontWeight: 500,
                                    }
                                    break

                                case 2:
                                    labelStatus = translator.t(
                                        'payment.verifikasi_process'
                                    )
                                    styleStatus = {
                                        color: COLORS.primaryColor,
                                        fontWeight: 500,
                                    }
                                    break

                                case 3:
                                    labelStatus = translator.t(
                                        'payment.finishPayment'
                                    )
                                    styleStatus = {
                                        color: COLORS.primaryColor,
                                        fontWeight: 500,
                                    }
                                    break

                                case 4:
                                    labelStatus = translator.t(
                                        'payment.paymentFailed'
                                    )
                                    styleStatus = {
                                        color: COLORS.basered,
                                        fontWeight: 500,
                                    }

                                    break

                                case 5:
                                    labelStatus = translator.t(
                                        'payment.cancel_payment'
                                    )
                                    styleStatus = {
                                        color: COLORS.basered,
                                        fontWeight: 500,
                                    }
                                    break

                                case 6:
                                    labelStatus = translator.t(
                                        'payment.payment_verified'
                                    )
                                    styleStatus = {
                                        color: COLORS.primaryColor,
                                        fontWeight: 500,
                                    }
                                    break

                                case 7:
                                    labelStatus =
                                        item?.courier_info?.code ===
                                        'taken_directly'
                                            ? translator.t(
                                                  'payment.waiting_pickup'
                                              )
                                            : translator.t(
                                                  'payment.on_delivery'
                                              )
                                    styleStatus = {
                                        color: COLORS.primaryColor,
                                        fontWeight: 500,
                                    }
                                    break

                                default:
                                    break
                            }
                            return (
                                <div
                                    key={key}
                                    className="donation-history-wrapper"
                                    onClick={() => {
                                        if (item?.payment_status === 0) {
                                            return history.push({
                                                pathname: `/cart/${item?.id}/${
                                                    categoryOrderBox?.find(
                                                        (e) =>
                                                            e?.value ===
                                                            activeKey
                                                    )?.key
                                                }`,
                                            })
                                        } else {
                                            setOrderBoxId(item?.id)
                                            setVisibleConfirmPayment(true)
                                        }
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="history-wrapper-top">
                                        <div className="history-wrapper-top-transaction">
                                            <h4
                                                className="id-transaction-title"
                                                style={{
                                                    marginBottom: '0.2em',
                                                }}
                                            >
                                                {translator.t(
                                                    'title.id_transaction'
                                                )}
                                            </h4>
                                            <h4 className="id-transaction-value">
                                                {item?.transaction_code}
                                            </h4>
                                        </div>
                                        <div className="history-wrapper-top-copy">
                                            <p>
                                                {item?.item_count +
                                                    ' ' +
                                                    getCountLabel(
                                                        item?.category
                                                    )}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="history-wrapper-middle">
                                        <h2 className="total-price">
                                            <NumberFormat
                                                value={totalPayment}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'Rp'}
                                                suffix=",-"
                                            />
                                        </h2>
                                        <div className="donation-status">
                                            {translator.t(
                                                'title.status_transaction'
                                            )}
                                        </div>
                                    </div>

                                    <div className="history-wrapper-bottom">
                                        <div className="history-date">
                                            {dayjs(item?.created_at).format(
                                                DAY_MONTH_STRING_FULL_FORMAT
                                            )}
                                        </div>
                                        <div
                                            className="history-status"
                                            style={styleStatus}
                                        >
                                            {labelStatus}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </React.Fragment>
                )}
                {loadMore()}
            </React.Fragment>
        )
    }

    return (
        <Row
            type="flex"
            style={{
                alignItems: 'center',
                marginTop: '2rem',
            }}
            justify="center"
        >
            <Col
                xs={24}
                sm={19}
                md={20}
                lg={24}
                xl={24}
                className="donation-container"
                style={{
                    maxWidth: LAYOUT.maxWidth,
                }}
            >
                <Card className="donation-card-wrapper">
                    {/* DONATION HEADER */}
                    <div className="donation-card-header">
                        <Avatar
                            size={90}
                            src={ic_transaction}
                            alt={`${BASE_TITLE} preview images - platform edukasi indonesia`}
                            className="donation-logo"
                        />
                        <h3 className="donation-title">
                            {translator.t('menu_name.donation')}
                        </h3>
                    </div>
                    {/* END DONATION HEADER */}

                    {/* TABS */}
                    <div
                        className="donation-card-tabs"
                        style={{
                            borderTop: '1px solid #eff2f5',
                            userSelect: 'none',
                        }}
                    >
                        <Menu
                            className="tab_donation_list"
                            onClick={(e) => {
                                setLoading(true)
                                setDataSource([])
                                checkActiveMenu(e.key)
                                setLimit(defaultLimit)
                                setOffset(0)
                                setTrigger(!trigger)
                            }}
                            mode="horizontal"
                            style={{
                                width: '100%',
                            }}
                            defaultSelectedKeys={`${menuActiveDonasi}`}
                        >
                            <Menu.Item key="1" style={{ marginRight: 5 }}>
                                {translator.t('action.status')}
                            </Menu.Item>

                            <Menu.Item key="2" style={{ marginLeft: 5 }}>
                                {translator.t('action.history')}
                            </Menu.Item>
                        </Menu>
                    </div>
                    {/* END TABS */}

                    {/* CONTENT */}
                    <div className="donation-card-content">
                        <Tabs
                            onChange={(key) => {
                                setActiveKey(`${key}`)
                                setLimit(defaultLimit)
                                setOffset(0)
                                setLoading(true)
                                setDataSource([])
                                setTrigger(!trigger)
                            }}
                            activeKey={`${activeKey}`}
                            type="card"
                            centered={true}
                        >
                            <TabPane
                                tab={translator.t('menu_name.donation')}
                                key="DONATION"
                            >
                                {contentMenu()}
                            </TabPane>
                            {/* <TabPane
                                tab={translator.t('title.quiz')}
                                key="TRYOUT"
                            >
                                {contentMenu()}
                            </TabPane>
                            <TabPane tab={'Edoo Toys'} key="PRODUCT">
                                {contentMenu()}
                            </TabPane> */}
                        </Tabs>
                    </div>
                    {/* END CONTENT */}
                </Card>
            </Col>
            <ModalConfirmPayment
                visible={visibleConfirmPayment}
                setVisible={setVisibleConfirmPayment}
                orderBoxId={orderBoxId}
                transactionType={activeKey}
            />
            <ModalDonationProgram
                visible={visibleDonation}
                setVisible={setVisibleDonation}
                orderBoxId={orderBoxId}
            />
        </Row>
    )
}

export default connect(mapStateToProps)(Donation)
