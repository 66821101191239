import { BASE_TITLE, PLAYSTORE_URL } from 'constants/constant'
import { BASE_URL_WEB } from 'helpers/constants'

const lang = {
    menu_name: {
        school_list: 'Daftar Sekolah',
        banner: 'Banner',
        donation: 'Donasi',
        payment_method: 'Metode Pembayaran',
        pustaka: 'Pustaka',
        edoo_user: `Pengguna ${BASE_TITLE}`,
        analysis: 'Analisis',
        epustaka_analysis: 'Pustaka',
        management_modules: 'Manajemen Modul',
        module_packages: 'Paket Modul',
        module_active: 'Paket Modul Aktif',
        content: 'Konten',
        epustaka_book: 'Buku Epustaka',
        electronic_book: 'Buku Elektronik',
        book_waiting_verification: 'Menunggu Verifikasi',
        multimedia_category: 'Kategori Multimedia',
        video: 'Video',
        audio: 'Audio',
        configuration: 'Konfigurasi',
        exam: 'Ujian',
        item_questions: 'Butiran Soal',
        home: 'Beranda',
        school: 'Sekolah',
        school_year: 'Tahun Ajaran',
        major: 'Jurusan',
        teacher_list: 'Daftar Guru',
        class: 'Kelas',
        student_list: 'Daftar Siswa',
        subject: 'Mata Pelajaran',
        room: 'Ruangan',
        subject_schedule: 'Jadwal Mata Pelajaran',
        school_event: 'Kegiatan Sekolah',
        academic_calendar: 'Kalendar Akademik',
        student_attendance: 'Kehadiran Siswa',
        school_request_join: 'Permintaan Gabung Instansi',
        mutation: 'Mutasi (Guru/Siswa)',
        dapodik: 'Dapodik',
        subject_reference_book: 'Buku Referensi Mata Pelajaran',
        question_bank: 'Bank Soal',
        exam_package: 'Paket Ujian',
        exam_result: 'Hasil Ujian',
        online_training: 'Pelatihan Online',
        sub_class: 'Sub Kelas',
        training_schedule: 'Jadwal Pelatihan Online',
        popup: 'PopUp',
        video_banner: 'Video Banner',
        running_text: 'Teks Berjalan',
        teaching_schedule: 'Jadwal Mengajar',
        textbook: 'Buku Ajar',
        assignment: 'Tugas',
        score: 'Nilai',
    },
    title: {
        file_file: 'Ukuran File',
        there_is: 'Terdapat',
        total_copy: 'Total Copy',
        available_copy: 'Tersedia Copy',
        no_queue: 'Tidak ada Antrian',
        available: 'Tersedia',
        readed_by: 'Telah dibaca Oleh',
        borrower_list: 'Sedang Dipinjam Oleh',
        is_being_read: 'Sedang dibaca',
        queue: 'Antrian',
        using_drm: 'Selalu tersedia',
        users: 'Pengguna',
        show: 'Lihat',
        description: 'Deskripsi',
        detail: 'Detail',
        review: 'Ulasan',
        notification: 'Pemberitahuan',
        new_notification: 'Pemberitahuan baru',
        detailnotif: 'Lihat semua pemberitahuan',
        morenotif: 'Lebih banyak pemberitahuan',
        borrowers_list: 'Daftar Peminjam',
        reader_list: 'Daftar Pembaca',
        member: 'Anggota',
        collection: 'Koleksi',
        joinEpustaka: 'Gabung ePustaka',
        reqJoin: 'Meminta Bergabung',
        continue: 'Lanjutkan',
        hasJoin: 'Sudah Bergabung',
        epustakaMember: 'Anggota ePustaka',
        video_detail: 'Detail Video',
        book_detail: 'Detail Buku',
        audio_detail: 'Detail Audio',
        share: 'Bagikan',
        share_to: 'Bagikan ke...',
        report_comment: 'Laporkan Komentar',
        deleteBook: 'Kembalikan',
        reading: 'Membaca',
        queue_list: 'Daftar Antrian',
        askJoin: 'Meminta Gabung',
        epustaka_member: 'Anggota ePustaka',
        similiar_book: 'Buku Serupa',
        teacher: 'Pengajar',
        subject: 'Mata Pelajaran',
        subject_time: 'Waktu Pelajaran',
        room: 'Ruangan',
        online_meeting_url: 'Tautan Kanal Meeting Online',
        student_count: 'Jumlah Siswa',
        student: 'Siswa',
        attachment: 'Lampiran',
        noAttachment: 'Tidak Ada Lampiran',
        noAddress: 'Tidak Ada Alamat',
        book: 'Buku',
        assignment: 'Tugas',
        quiz: 'Quiz',
        time: 'Waktu',
        participant: 'Partisipan',
        link: 'Link',
        noLink: 'Tidak Ada Link',
        createdBy: 'Dibuat Oleh',
        activityDetail: 'Detail Aktivitas',
        groupWork: 'Kerja Kelompok',
        school_join_req: `Silahkan cari Instansi yang telah terdaftar di ${BASE_TITLE}.`,
        regency: 'Kab / Kota',
        school_list: 'Daftar Instansi',
        status: 'Status',
        nik: 'NIK/NIP',
        nis: 'NIS',
        nisn: 'NISN',
        fullname: 'Nama Lengkap Sesuai Identitas',
        gender: 'Jenis Kelamin',
        thankyou: 'Terimakasih',
        join_school: 'Gabung Instansi',
        level: 'Jenjang',
        yourResult: 'Hasil Anda',
        news: 'Artikel',
        today_news: 'Artikel hari ini',
        select_news_category: 'Pilih kategori berita',
        apply: 'Terapkan',
        reset: 'Atur Ulang',
        schedule: 'Agendaku',
        availabelity_copy: 'Ketersediaan Copy',
        total_epustaka_donation:
            'Terdapat {{total_epustaka_donation}} ePustaka',
        donation_purpose: 'Tujuan ePustaka: {{donation_purpose}}',
        book_donation: 'Donasi Buku',
        choose_epustaka_purpose: 'Pilih tujuan ePustaka',
        find_ePustaka: 'Cari ePustaka',
        my_account: 'Akun Saya',
        total_price: 'Total Harga',
        edit_nominal: 'Sunting Nominal',
        delete_from_box_donation: 'Hapus Dari Box Donasi',
        detail_box_donation: 'Detail Box Donasi',
        number_of_copy: 'Jumlah Copy',
        second: 'detik',
        id_transaction: 'ID TRANSAKSI',
        status_transaction: 'Status Transaksi',
        detail_payment: 'Detail Pembayaran',
        purpose: 'Tujuan',
        emptyEpustaka: 'Opss... Epustaka yang anda cari tidak tersedia',
        sorry: 'Mohon Maaf',
        book_title: 'Judul Buku',
        pending_approval: 'Menunggu Persetujuan',
        extend: 'Perpanjang',
        reNotif: 'Pemberitahuan Ulang',
        reTitle: `Anda telah meminjam buku ini dari ePustaka '{{ePustaka}}', Silahkan tekan tombol baca untuk melanjutkan bacaan`,
        video_lowercase: 'video',
        audio_lowercase: 'audio',
        news_lowercase: 'artikel',
        nationalLibrary: 'Layanan Perpustakaan Nasional RI',
        lastBookRead:
            'Anda sedang membaca buku {{title}}, apakah akan lanjut membaca buku tersebut ?',
        activeReading: 'Ada buku yang sedang dibaca',
        continueRead: 'Lanjut Baca',
        DRM: 'Buku DRM',
        noDRM: 'Buku non DRM',
        copyDRM: 'Salinan buku DRM',
        history_transaction: 'Riwayat Transaksi',
        number_items: 'Jumlah Item',
        order_detail: 'Detail Pesanan',
        choose_payment_method: 'Pilih Metode Pembayaran',
        bank_transfer: 'Transfer Bank',
        ewallet: 'eWallet',
        summary_payment: 'Ringkasan Pembayaran',
        summary_payment_description:
            'Koleksi Donasi yang belum berhasil dibayar, akan tersimpan kedalam rak berbagi Anda. Silahkan selesaikan proses pembayaran.',
        summary_description:
            'Koleksi {{category}} yang belum berhasil dibayar, akan tersimpan kedalam rak berbagi Anda. Silahkan selesaikan proses pembayaran.',
        payment_method: 'Metode Pembayaran',
        virtual_account: 'AKUN VIRTUAL',
        billing_information: 'Informasi Tagihan',
        shipping_cost: 'Biaya Pengiriman',
    },
    action: {
        language: 'id',
        clear: 'Bersihkan Filter',
        finishing: 'Menyelesaikan',
        only_admin: 'Hanya akun pemilik ePustaka yang dapat melakukan ubah',
        add_filter: 'Tambahkan filter',
        add_member: 'Tambahkan Anggota',
        out_member: 'Keluarkan Anggota',
        is_out_member: 'Anda yakin ingin mengeluarkan',
        is_next_out_member: ' dari keanggotaan ePustaka?',
        success_remove_member: 'Pengguna yang anda pilih berhasil dikeluarkan',
        take_out: 'Keluarkan',
        add: 'Tambahkan',
        borrow: 'Pinjam',
        send: 'Kirim',
        download: 'Unduh',
        read: 'Baca',
        queue_borrow: 'Antri',
        donation: 'Donasi',
        addEvent: 'Tambah Jadwal',
        addLevel: 'Tambah Tingkatan Kesulitan',
        addScope: 'Tambah Cakupan',
        addSubject: ' Tambah Mata Pelajaran',
        addQuizCategories: 'Tambah Kategori Quiz',
        back: 'Kembali',
        bulk_actions: '{{smart_count}} terpilih',
        cancel: 'Batal',
        clear_input_value: 'Kosongkan input',
        clone: 'Duplikat',
        close: 'Tutup',
        confirm: 'Konfirmasi',
        create: 'Buat',
        find: 'Cari',
        delete: 'Hapus',
        edit: 'Ubah',
        export: 'Ekspor',
        list: 'Daftar',
        refresh: 'Perbaharui',
        remove_filter: 'Hapus filter',
        remove: 'Hapus',
        save: 'Simpan',
        aktivasi: 'Aktivasi',
        deaktivasi: 'Nonaktifkan',
        aktivasiMenuDashboard: 'Anda yakin ingin mengaktivasi data ini?',
        deaktivasiMenuDashboard: 'Anda yakin ingin menonaktifkan data ini?',
        block: 'Blokir',
        unBlock: 'Buka blokir',
        blocked: 'Diblokir',
        search: 'Mencari',
        searchAuthor: 'Cari berdasarkan penulis',
        searchYear: 'Cari berdasarkan tahun terbit',
        searchGenre: 'Cari berdasarkan genre buku',
        borrowWithoutEntry: 'Pinjam Koleksi Tanpa Antri',
        borrowWithoutEntryDesc: 'Menampilkan koleksi pinjam tanpa mengantri',
        show: 'Tampilkan',
        showMore: 'Tampil lebih banyak',
        sort: 'Urutkan',
        undo: 'Batalkan',
        viewDetail: 'Lihat Detail',
        bulkInsertStudent: 'Bulk Insert Siswa',
        bulkInsertTeacher: 'Bulk Insert Guru',
        bulkInsertDapodik: 'Bulk Insert Dapodik',
        promoteGradeStudent: 'Naik / Pindah Kelas',
        finish: 'Selesai',
        preview: 'Lihat',
        giveReview: 'Berikan Ulasan',
        editReview: 'Ubah Ulasan',
        writeMessage: 'Tambahkan Pesan...',
        writeReview: 'Tuliskan ulasan...',
        addReview: 'Berikan rating buku ini.',
        addAssigment: 'Tambah Tugas',
        editAssignment: 'Edit Tugas',
        confirmAddAdmin: 'Anda yakin akan menambahkan sebagai admin sekolah?',
        confirmMigration: 'Mutasi data pengguna ke sekolah anda?',
        verification: 'Verifikasi',
        not_verified: 'Belum diverifikasi',
        status: 'Status',
        editRole: 'Ubah role',
        addPaymentMethods: 'Tambah Metode',
        addBankAccounts: 'Tambah Akun Bank',
        addInstructions: 'Tambah Intruksi',
        fully: 'Selengkapnya',
        readLess: 'Baca lebih sedikit',
        follow: '+ Ikuti',
        followed: 'Diikuti',
        recommendation: 'Rekomendasi',
        searchResult: 'Hasil Pencarian',
        resultFound: 'Ditemukan',
        followers: 'Pengikut',
        following: 'Mengikuti',
        cancel_follow: 'x Batal Mengikuti',
        searchUser: 'Cari pengguna',
        indentity: 'Data Identitas',
        changeProfile: 'Ubah Profil',
        other: 'Lainnya',
        accountData: 'Data Akun',
        accountSetting: 'Pengaturan Akun',
        firstName: 'Nama Depan',
        lastName: 'Nama Belakang',
        telephon: 'Nomor Ponsel',
        bio: 'Bio',
        editPassword: 'Ubah kata sandi?',
        editEmail: 'Perbaharui email?',
        emailProfile: 'Email',
        dataUser: 'Data Pengguna',
        nisn: 'NISN',
        fullName: 'Nama Lengkap',
        placeBorn: 'Tampat Lahir',
        dateBorn: 'Tanggal Lahir',
        codeUnix: 'Kode Unik Siswa',
        dataSchool: 'Data Sekolah',
        nameSchool: 'Nama Sekolah',
        fullAddress: 'Alamat Lengkap',
        city: 'Kota / Kabupaten',
        province: 'Provinsi',
        codeUnixSchool: 'Kode Unik Sekolah',
        phone: 'Nomor Ponsel',
        newPass: 'Kata Sandi Baru',
        repPass: 'Ulangi Kata Sandi Baru',
        confirmPass: 'Konfirmasi Kata Sandi',
        oldPass: 'Kata sandi lama',
        cancell: 'BATAL',
        comment: 'KOMENTAR',
        reply: 'BALAS',
        report: 'LAPORKAN',
        play: 'Putar',
        pause: 'Jeda',
        more: 'Lihat lebih banyak',
        queue: 'Antrian',
        history: 'Riwayat',
        returnBook: 'Kembalikan Buku',
        fullKtp: 'Alamat Sesuai Identitas',
        add_attachment: 'Tambahkan Lampiran',
        upload_assignment: 'Unggah Tugas',
        playQuiz: 'Kerjakan',
        reviewQuiz: 'Tinjau Ulang',
        upload_attachment: 'Unggah Lampiran',
        join: 'Gabung',
        change_filter: 'Ubah Filter',
        buy_quiz_package: 'Beli Paket Soal',
        messageAssigment:
            'Tugas hanya bisa diunggah satu kali, pastikan file yang diunggah sudah benar Jika sudah yakin silahkan klik "Unggah Tugas" dan jika belum klik "Batalkan"',
        later: 'Nanti Saja',
        reload: 'Muat Ulang',
        reloadMessage: ' Silahkan muat ulang atau coba kembalinanti :(',
        errorMessage: 'Terjadi kesalahan pada sistem internal...',
        useVouchers: 'Gunakan Voucher',
        messageVoucher:
            'Masukan kode voucher yang telah dikirimkan admin melalui email',
        wrongVoucher: 'Kode voucher yang anda masukan salah',
        enter_box_donation: 'Masukan Bok Donasi',
        add_collection: 'Tambah Koleksi',
        choose_payment: 'Pilih Pembayaran',
        input_keyword: 'Masukkan kata kunci',
        deleteItem: 'Hapus Item',
        messageDelete:
            'Apakah Anda akan menghapus Item ini dari daftar pinjam?',
        borrowNow: 'Pinjam Sekarang',
        donate_now: 'Mulai Donasi',
        order_detail: 'Detail Pesanan',
        add_item: 'Tambah Item',
    },
    schedule: {
        startTime: 'Waktu Mulai',
        endTime: 'Waktu Selesai',
        startDate: 'Tanggal Mulai',
        endDate: 'Tanggal Selesai',
        personalEvent: 'Agenda Pribadi',
        invitation: 'Undangan',
        next: 'Selanjutnya',
        extra: 'Extracurricular',
    },
    filter: {
        result: 'Hasil Filter',
        category: 'Kategori',
        publisher: 'Penerbit',
        author: 'Pengarang',
        year: 'Tahun Terbit',
        numberOfPage: 'Jumlah Halaman',
        filter: 'Filter',
        ganjil: 'Ganjil',
        genap: 'Genap',
        following: 'Mengikuti',
        followers: 'Pengikut',
        like: 'Suka',
        comment: 'Komentar',
        share: 'Bagikan',
        more: 'Selengkapnya',
        history: 'Riwayat Tonton',
        listFollowers: 'Daftar Pengikut',
        listFollowing: 'Daftar Mengikuti',
        ePublic: 'ePustaka Lembaga',
        ePrivate: 'ePustaka Privat',
        published_by: 'Diterbitkan oleh',
        report: 'Laporkan',
        copy_link: 'Salin tautan',
        donation: 'Donasi',
        review: 'Ulasan',
        district: 'Kecamatan',
    },
    sort: {
        latest: 'Terbaru',
        abjad: 'Urutan Abjad',
    },
    boolean: {
        true: 'Ya',
        false: 'Tidak',
        aktif: 'Aktif',
        tidakAktif: 'Tidak Aktif',
        banner: {
            internal: 'Konten Internal',
            website: 'Website',
        },
        epustaka: {
            private: 'ePustaka Pribadi',
            public: 'ePustaka Publik',
            perorangan: 'Perorangan',
            lembaga: 'Lembaga',
        },
        book: {
            private: 'Buku Pribadi',
            public: 'Buku Publik',
        },
    },
    page: {
        create: 'Buat {{name}}',
        dashboard: 'Dasbor',
        edit: '{{name}} #{{id}}',
        error: 'Ada yang salah',
        list: '{{name}}',
        loading: 'Pemuatan',
        landing: 'Halaman Depan / Utama',
        landinginfo: 'Contoh Halaman Depan',
        not_found: 'Tidak ditemukan',
        show: '{{name}} #{{id}}',
        page1: 'Halaman 1',
        page2: 'Halaman 2',
        browse: 'Telusuri Koleksi',
    },
    feed: {
        endLoadMore: 'Yay! Anda telah melihat semuanya',
        yourMind: 'Apa yang Anda pikirkan',
        imgUrl: 'URL gambar (opsional)',
        createPost: 'Buat Postingan',
        totalLike: 'Menyukai',
        totalComment: 'Komentar',
        shareTitle: 'Bagikan dengan temanmu',
        like: 'Suka',
        delete: 'Hapus',
        edit: 'Ubah',
        deleteComment: 'Hapus Komentar',
        confirmDeleteComment: 'Anda yakin ingin menghapus komentar ini?',
        viewComment: 'Lihat',
        hiddenComment: 'Sembunyikan',
        replyComment: 'balasan',
        reply: 'Balas',
        comment: 'Komentari',
        addComment: 'Tambahkan Komentar...',
        book_recommendation: 'Merekomendasikan Buku',
        show_more_replies: 'Tampilkan balasan lainnya',
        epustaka_post: 'Posting atau bagikan sesuatu...',
    },
    input: {
        bulk: {
            student: 'Upload data XLSX siswa',
            teacher: 'Upload data XLSX guru',
        },
        file: {
            upload_several:
                'Letakan beberapa file untuk diunggah, atau klik untuk memilihnya.',
            upload_single:
                'Letakan file yang akan diunggah, atau klik untuk memilihnya.',
        },
        image: {
            upload_several:
                'Letakan beberapa gambar untuk diunggah, atau klik untuk memilihnya.',
            upload_single:
                'Letakan gambar untuk diunggah, atau klik untuk memilihnya.',
        },
        references: {
            all_missing: 'Tidak dapat menemukan data referensi.',
            many_missing:
                'Setidaknya satu referensi terkait tidak lagi tersedia.',
            single_missing: 'Referensi terkait tidak lagi tersedia.',
        },
        addAcount: 'Pilih Akun',
        addSchool: 'Pilih Sekolah',
        addAccountTitle: 'Tambah Admin Sekolah',
        addAcountPlaceholder: 'Pilih akun untuk dijadikan admin sekolah',
        addSchoolPlaceholder: 'Pilih sekolah tujuan',
        MigrationUserPlaceholder: 'Pilih akun untuk dimutasi',
        addProvince: 'Pilih Provinsi',
        addRegency: 'Pilih Kab / Kota',
        addSchools: 'Pilih Instansi',
        addStatus: 'Tentukan Status Anda',
        addNIK: 'NIK/NIP',
        addNIS: 'Nomor Induk Siswa',
        addNISN: 'Nomor Induk Siswa Nasional',
        addName: 'Nama Lengkap',
        addGender: 'Jenis Kelamin',
        addClass: 'Pilih Kelas',
        addLevel: 'Pilih Jenjang',
    },
    message: {
        data_not_found: 'Data tidak ditemukan',
        urlValid:
            ' Mohon maaf url yang anda akses tidak valid atau ePustaka ini mungkin telah dihapus.',
        successRegisterSchool: 'Registrasi Sekolah Berhasil',
        writeReview: 'Harap isi ulasan anda',
        success_add: 'Berhasil Ditambahkan',
        success_join_epustaka:
            'Anda berhasil menambahkan user menjadi member ePustaka anda',
        day: 'Hari',
        about: 'Tentang',
        phoneFormatNotMatch:
            'Nomor telepon hanya boleh angka,(format) xxxx-xxxx-xxxx',
        are_you_sure: 'Apakah Anda yakin?',
        bulk_delete_content:
            'Anda yakin ingin menghapus {{name}}? |||| Anda yakin ingin menghapus {{smart_count}} items ini?',
        bulk_delete_title:
            'Hapus {{name}} |||| Hapus {{smart_count}} {{name}} items',
        emailBlank: '{{email}} kosong, email tidak boleh kosong',
        delete_content: 'Anda yakin ingin menghapus item ini?',
        delete_title: 'Konfirmasi Hapus',
        details: 'Detail',
        error: 'Terjadi kesalahan pada klien dan permintaan Anda tidak dapat diselesaikan.',
        invalid_form:
            'Formulir tidak valid, ada kesalahan. Silakan periksa kembali',
        loading: 'Halaman ini sedang memuat, silahkan tunggu sebentar',
        no: 'Tidak',
        not_found:
            'Entah Anda mengetikkan URL yang salah, atau Anda memiliki link yang salah.',
        yes: 'Ya',
        verification_success: 'Verifikasi Berhasil',
        successAddADmin: 'Data admin sekolah ditambahkan',
        successRemoveADmin: 'Data admin sekolah dihapus',
        successDelete: 'Data dapodik dihapus',
        successMigration: 'Mutasi data user berhasil',
        successReview: 'Ulasan tersimpan.',
        warningReport: 'Gagal melaporkan komentar',
        message_warning_report:
            'Mohon maaf. konten tersebut sudah pernah Anda laporkan sebelumnya.',
        successReport: 'Laporan Anda sudah terkirim',
        message_success_report:
            'Terima kasih. Laporan Anda telah kami terima. Jika kami menganggap konten ini melanggar Pedoman Komunitas, konten akan dihapus.',
        rejectMigration: 'Mutasi data user ditolak',
        errMigration:
            'Terdapat data yang kosong, mohon periksa kembali kelengkapan data pengguna.',
        underReviewMigration: 'Permintaan bergabung sedang ditinjau',
        hour: 'Jam',
        minute: 'Menit',
        successBlock: 'Blokir data user berhasil',
        successUnBlock: 'Berhasil buka blokir',
        errGrade:
            'Nama kelas tidak boleh sama / coba masukkan nama kelas lain.',
        unlimitedCopy: 'Tidak Terbatas',
        availableCopy: 'Copy Tersedia',
        message_share: `Dibagikan melalui aplikasi ${BASE_TITLE} (Perpustakaan Digital Sekolah) Download aplikasi di ${PLAYSTORE_URL}`,
        copied: 'Tersalin',
        edited: 'diedit',
        no_description: 'Tidak ada deskripsi',
        notif: 'Anda Belum Tergabung Sebagai Anggota ePustaka',
        activity: 'Aktivitas Anda akan tersimpan di halaman ini',
        messageReturnBook:
            'Anda yakin ingin menghapus item ini, Anda harus meminjam ulang jika ingin mengakses kembali item ini?',
        citationMessage: `{{content}}\n\nTulisan ini diambil di aplikasi ${BASE_TITLE} dengan judul "{{judul}}" pada tanggal {{tanggal}} jam {{jam}}\n\nPenulis : {{penulis}}\nPenerbit : {{penerbit}}\n\nTahun Terbit : {{tahun}}\nHalaman : {{halaman}}\n\nDownload aplikasi ${BASE_TITLE} untuk membaca koleksi lainnya :\nWebsite: ${BASE_URL_WEB}`,
        give_rating: 'Memberikan rating',
        errorPass: 'Kata sandi lama salah',
        unfollow: 'Anda tidak lagi mengikuti aktifitas',
        confirmUnfollow: 'Konfirmasi Batal Mengikuti',
        confirmFollow: 'Konfirmasi Mengikuti',
        makeFriends: 'Jalin Pertemanan',
        follow: 'Jalin pertemanan dengan',
        failedBorrow: `Mohon maaf untuk saat ini buku tersebut belum bisa dibaca melalui ${BASE_TITLE} web, silahkan cari buku lain atau unduh aplikasi ${PLAYSTORE_URL} di google playstore untuk membaca semua jenis buku.`,
        notJoinEpustaka:
            'Anda belum tergabung sebagai anggota ePustaka, silahkan tekan tombol Meminta Gabung untuk menjadi anggota di ePustaka ini. Dengan menjadi anggota Anda dapat meminjam koleksi yang ada di ePustaka.',
        waitingJoin:
            'Anda belum tergabung sebagai anggota ePustaka, silahkan hubungi admin ePustaka untuk menerima permintaan gabung',
        expiredJoinEpustaka: `Masa keanggotaan Anda telah berakhir, silahkan tekan tombol "Perpanjang" untuk kembali bergabung di ePustaka ini dan nikmati konten-konten di dalam ePustaka.`,
        book_not_found: 'Data buku tidak ditemukan',
        not_yet_started: 'Belum Dimulai',
        ongoing: 'Sedang Berlangsung',
        attendance_over: 'Absensi Selesai',
        alpha: 'Alfa',
        present: 'Hadir',
        permit: 'Izin',
        sick: 'Sakit',
        absent: 'Belum Absensi',
        not_yet_absent: 'Tidak ada keterangan',
        not_data_agenda: 'Tidak ada agenda',
        class_not_ready: 'Kelas belum dimulai',
        absent_now: 'Absen Sekarang',
        attendance_status: 'Status Absensi Kehadiran Anda',
        no_event: 'Tidak Ada Event',
        empty: 'Kosong',
        has_upload_assignment: 'Anda sudah mengupload tugas.',
        empty_assignment: 'Tidak ada tugas.',
        allDay: 'Seluruh Hari',
        schedule_in_progress: 'Jadwal Sedang Berlangsung',
        schedule_is_completed: 'Jadwal Sudah Selesai',
        class_not_yet_ready:
            'Anda belum bisa melakukan konfirmasi absensi karena waktu pembelajaran belum dimulai. Terimakasih',
        search_not_matches: 'Silahkan lakukan pengubahan filter yang anda cari',
        filter_not_matches: 'Oops, Data hasil filter tidak ditemukan',
        succes_school_join:
            'Permintaan bergabung berhasil dikirim, selanjutnya kami akan melakukan proses verifikasi atas data yang telah Anda masukkan.',
        successVoucher: 'Kode voucher telah berhasil digunakan',
        sure_cancel_donation: 'Anda yakin ingin membatalkan program Donasi ?',
        cancel_information:
            'Transaksi akan dibatalkan otomatis oleh sistem jika anda belum melakukan pembayaran sampai sisa waktu pembayaran habis.',
        connectionError:
            'Terjadi masalah. Mohon periksa ulang koneksi internet Anda',
        notifExpand:
            'Masa keanggotaan Anda telah berakhir, silahkan perpanjang kembali.',
        no_access_content:
            'Anda tidak memiliki hak untuk mengakses konten {{content}} ini.',
        reading_book_confirm_title: 'Ada buku yang terbuka di tab lain',
        reading_book_confirm_message:
            'Silakan muat ulang buku untuk melanjutkan baca',
        no_bookmark: 'Tidak ada bookmark',
        no_queue: 'Belum Ada Daftar Antrian',
        no_borrow: 'Belum Ada Daftar {{option}}',
        firstPerson: 'Jadilah orang pertama yang {{option}} buku ini',
        content_private: 'Konten Pribadi',
        content_not_found:
            'Konten tidak ditemukan. Admin ePustaka telah menarik konten tersebut sehingga tidak bisa diakses kembali.',
        empty_status_donation: 'Tambahkan koleksi melalui fitur Donasi',
        empty_data_donation:
            'Dengan berpartisipasi dalam program berbagi koleksi anda telah membantu mengubah wajah literasi Indonesia menjadi lebih baik, lebih mudah untuk diakses.',
        empty_history_donation: 'Belum ada riwayat donasi',
    },
    navigation: {
        no_results: 'Tidak ada hasil yang ditemukan',
        no_more_results: 'Halaman {{page}} tidak ada. Coba halaman sebelumnya.',
        page_out_of_boundaries: 'Halaman {{page}} tidak ada',
        page_out_from_end: 'Halaman terakhir',
        page_out_from_begin: 'Halaman harus lebih besar dari pertama',
        page_range_info: '{{offsetBegin}}-{{offsetEnd}} dari {{total}}',
        page_rows_per_page: 'Baris per halaman:',
        next: 'Berikutnya',
        prev: 'Sebelumnya',
        fillAnswerForm: 'Isi form jawaban',
        fillQuestionForm: 'Isi form soal',
        outQueue: 'Keluar Antrian',
    },
    auth: {
        user_menu: 'Profil',
        username: 'Nama pengguna',
        password: 'Kata sandi',
        sign_in: 'Masuk',
        sign_in_error: 'Otentikasi gagal, coba lagi',
        logout: 'Keluar Dari Aplikasi',
        lock: 'Kunci Akun',
        setting: 'Pengaturan',
        profile: 'Lihat profil',
        account: 'Akun',
        notification: 'Lihat Semua Pemberitahuan',
        notifications: 'Pemberitahuan',
        bookshelf: 'Rak Pribadi',
        notes: 'Catatan',
        friend: 'Pertemanan',
        agenda: 'Agenda',
        help: 'Bantuan',
        raport: 'Raport',
        scholarship: 'Beasiswa',
        extra: 'Ekstrakurikuler',
        virtual: 'Papan Virtual',
        forum: 'Forum Q/A',
        bill: 'Tagihan',
        donation: 'Donasi',
        membership: 'Keanggotaan',
        noNotif: 'Tidak Ada Notifikasi',
        noBook: 'Tidak ada Buku',
    },
    notification: {
        activated: 'Data teraktivasi',
        deactivated: 'Data dinonaktifkan',
        updated: 'Data telah diperbaharui',
        inputEmail: 'Masukkan email',
        created: 'Elemen telah dibuat',
        dataCreated: 'Data telah dibuat',
        deleted: {
            single: 'Elemen telah dihapus',
            multiple: '{{smart_count}} elemen telah dihapus',
        },
        errorDeleteMenu:
            'Menu utama tidak dapat dihapus selama sub menu masih ada',
        bad_item: 'Elemen salah',
        item_doesnt_exist: 'Data tidak ada',
        http_error: 'Kesalahan komunikasi peladen',
        data_provider_error:
            'kesalahan pada dataProvider. Periksa konsol untuk detailnya.',
        canceled: 'Telah dibatalkan',
        logged_out: 'Sesi Anda telah berakhir, harap sambungkan kembali.',
        loading: 'Tunggu...',
        inputPass: 'Masukan kata sandi baru',
        repPass: 'Ulangi kata sandi',
        oldPass: 'Masukan kata sandi lama',
        bookNotFound: 'Buku tidak tersedia',
    },
    validation: {
        required: 'Wajib diisi.',
        minLength: 'Minimal {{min}} karakter',
        maxLength: 'Maksimal {{max}} karakter atau kurang',
        maxImage: 'Gambar harus lebih kecil dari 2MB!',
        maxAudio: 'Audio harus lebih kecil dari 50MB!',
        maxVideo: 'Video harus lebih kecil dari 250MB!',
        maxBook: 'File Buku harus lebih kecil dari 20MB!',
        minValue: 'Minimal {{min}}',
        maxValue: 'Maksimal {{max}} atau kurang',
        number: 'Harus Angka',
        email: 'Harus alamat email yang valid',
        emailExist: 'Email telah digunakan',
        yearExist: 'Nama Tahun Ajaran telah digunakan',
        nisExist: 'NIS telah digunakan',
        nikExist: 'NIK telah digunakan',
        emailBulkExistTeacher:
            'Pengguna sudah terdaftar sebagai guru, Silahkan periksa NIK dan Email pengguna tersebut',
        emailBulkExistStudent:
            'Pengguna sudah terdaftar sebagai siswa, Silahkan periksa NISN dan Email pengguna tersebut',
        nisBulkExist: 'Terdapat NIS yang sudah digunakan dalam data bulk',
        nikBulkExist: 'Terdapat NIK yang sudah digunakan dalam data bulk',
        oneOf: 'Harus salah satu dari : {{options}}',
        regex: 'Harus sesuai dengan format tertentu (regexp): {{pattern}}',
        cantPastCurrentYear: 'Tidak dapat melebihi tahun saat ini.',
        cantBeSmallerThanYearStart: 'Tidak dapat lebih kecil dari tahun awal',
        dataExist: 'Data sudah ada',
        gradeNotExist:
            'Data Kelas Kosong, Harap Isi Terlebih Dahulu Data Kelas',
        dataBulkNotValid:
            'Terdapat data yang tidak valid / file tidak menggunakan format yang ditentukan.',
        dataEmpty: 'Data tidak valid atau kosong',
        wrongFormatEmail: 'Format Email Salah',
        require_province: 'Silahkan isi Provinsi Anda!',
        require_regency: 'Silahkan isi Kabupaten / Kota Anda!',
        require_school: 'Silahkan masukkan sekolah / Instansi Anda!',
        require_status: 'Silahkan masukkan status Anda!',
        require_nik: 'Silahkan masukkan NIK/NIP Anda!',
        require_nis: 'Silahkan masukkan NIS Anda!',
        require_nisn: 'Silahkan masukkan NISN Anda!',
        require_name: 'Silahkan masukkan nama lengkap Anda!',
        require_gender: 'Jenis kelamin wajib diisi!',
        require_level: 'Silahkan pilih jenjang anda',
        require_class: 'Silahkan pilih kelas anda',
    },
    menu: {
        home: 'Beranda',
        timeline: 'Lini Masa',
        activities: 'Aktivitas',
        book: 'Buku',
        video: 'Video',
        audio: 'Audio',
        radio: 'Radio',
        forum: 'Forum',
        user: 'Pengguna',
        article: 'Artikel',
        borrowed_shelf: 'Rak Pinjam',
        friendship: 'Pertemanan',
        donation: 'Donasi',
        virtual_board: 'Papan Virtual',
        agenda: 'Agenda',
        teacher_list: 'Daftar Guru',
        extracurricular: 'Ekstrakurikuler',
        help: 'Bantuan',
        school_join: 'Gabung Instansi',
        membership: 'Keanggotaan',
    },
    help: {
        menu_index: 'Urutan menu yang akan tampil pada menu dashboard.',
        active_duration: 'Format dalam bentuk hari (30 hari).',
    },
    column: {
        aksi: 'Aksi',
        namaGuru: 'Nama Guru',
        namaSiswa: 'Nama Siswa',
        lastRead: 'Bacaan Terakhir',
        scheduleToday: 'Jadwal Hari Ini',
        recommendation: 'Rekomendasi',
        suggestFriends: 'Teman yang disarankan',
        quiz: 'Quiz',
        epustaka: 'ePustaka',
        audio: 'Riwayat Dengar',
        watch: 'Riwayat Tonton',
        donation: 'Koleksi Donasi',
        read: 'Riwayat Baca',
        book: 'Koleksi Buku',
        selfBook: 'Pinjam Buku',
        historyBook: 'Riwayat Baca',
        ownerCollAvail: 'Pemilik Koleksi Tersedia',
        ownerColl: 'Pemilik Koleksi',
        searchResult: 'Menampilkan hasil buku untuk',
        searchAudio: 'Menampilkan hasil audio untuk',
        searchVideo: 'Menampilkan hasil video untuk',
        search: 'Menampilkan hasil untuk',
    },
    registerSchool: {
        title: 'Registrasi Sekolah',
        name: 'Nama Sekolah',
        npsn: 'NPSN',
        nss: 'NSS',
        phone: 'Telepon/WA',
        province: 'Provinsi',
        regency: 'Kabupaten',
        district: 'Kota',
        village: 'Desa',
        postCode: 'Kode Pos',
        address: 'Alamat',
        major: 'Jenjang',
        logo: 'Logo',
        password: 'Password',
        retypePassword: 'Ulangi Password',
        headMasterData: {
            title: 'Data Kepala Sekolah',
            fullName: 'Nama Lengkap Beserta Gelar',
            nip: 'NIP',
        },
        schoolAdminData: {
            title: 'Data Admin Sekolah',
            fullName: 'Nama Lengkap',
            email: 'E-mail',
        },
        error: {
            empty: 'harus diisi',
            invalid: 'salah',
            weak: 'Password Harus Lebih Dari 8 Karakter',
            mismatch: 'Password Tidak Sama',
        },
    },
    gendder: {
        male: 'Laki-laki',
        female: 'Perempuan',
        other: '-',
    },
    status: {
        active: 'Aktif',
        nonActive: 'Tidak Aktif',
        inReview: 'Sedang Diulas',
        disabled: 'Tidak Aktif',
        review: 'Diulas',
        total: 'Total',
        blokir: 'Blokir',
    },
    bookshelf: {
        borrowBook: 'Pinjaman Buku',
        borrowVideo: 'Riwayat Putar Video',
        borrowAudio: 'Riwayat Putar Audio',
        notifBorrow:
            'Belum terdapat koleksi pinjaman. Kesempatan anda untuk dapat lakukan peminjaman, pinjam sekarang juga',
        notifReview:
            'Belum terdapat ulasan koleksi. Lakukan peminjaman koleksi buku, audio, dan video untuk dapat memberikan ulasan dan rating',
        notifQueue:
            'Belum terdapat antrian. Anda belum melakukan antrian konten koleksi buku, audio maupun video',
        notifHistory:
            'Belum terdapat riwayat. Anda belum melakukan peminjaman, program berbagi, ulasan dan antrian',
        returnBook: 'Buku telah dikembalikan dari rak pinjaman',
        empty_book_donation_title: 'Belum terdapat koleksi berbagi',
        empty_book_history_title: 'Belum terdapat koleksi pinjaman',
        empty_book_donation_desc:
            'Kesempatan anda jadi yang pertama mengikuti program berbagi sekarang juga.',
        empty_book_history_desc:
            'Kesempatan anda untuk dapat lakukan peminjaman, pinjam sekarang juga.',
        news_history: 'Riwayat Baca Artikel',
        out_queue:
            'Anda yakin ingin keluar dari antrian, anda harus mengantri ulang jika ingin mendapat pemberitahuan ketersediaan buku ini ?',
    },
    epustka: {
        title: 'Permintaan Bergabung Anggota ePustaka',
        subtitlePrivat:
            'Anda belum terdaftar sebagai anggota ePustaka, silahkan tekan tombol Meminta Gabung untuk bergabung menjadi anggota ePustaka ini. Dengan menjadi anggota Anda dapat meminjam koleksi yang ada di ePustaka.',
        notifPrivat:
            'Permintaan anda untuk bergabung telah disampaikan, silahkan tunggu notifikasi pemberitahuan lanjut dari admin',
        notif: 'Terima kasih telah bergabung di ePustaka kami',
        subtitle:
            'Anda belum tergabung sebagai anggota ePustaka, silahkan tekan tombol Gabung ePustaka untuk bergabung menjadi anggota di ePustaka ini. Dengan menjadi anggota Anda dapat meminjam koleksi yang ada di ePustaka.',
        join: 'Gabung',
        subtitleExpired:
            'Status keanggotaan anda sudah kadaluarsa, silahkan tekan tombol Perpanjang untuk memperpanjang keanggota anda di ePustaka ini. Dengan menjadi anggota Anda dapat meminjam koleksi yang ada di ePustaka.',
        collectionAudio: 'Koleksi Audio',
        collectionVideo: 'Koleksi Video',
        collectionArticle: 'Koleksi Artikel',
    },
    friendship: {
        firstNotifFollow: `Anda belum pernah mengikuti pengguna lain.`,
        secondNotifFollow: `Segera klik link ini dan ikuti pengguna ${BASE_TITLE} lain.`,
        thirdNotifFollow: `Kesempatan anda untuk mencari lebih banyak teman di Platform ${BASE_TITLE}.`,
        firstNotifFollower: `Maaf, saat ini belum ada pengguna ${BASE_TITLE} yang mengikuti anda.`,
        secondNotifFollower: `Mulai ikuti aktivitas pengguna lain dan dapatkan follback.`,
    },
    profile: {
        notif: `Oops... Belum Terdapat Aktifitas. Kesempatan anda unutuk jadi yang pertama menikmati koleksi di aplikasi ${BASE_TITLE} Library`,
        userNotfound: 'Maaf pengguna tidak ditemukan',
        gender: 'Jenis Kelamin',
        male: 'Laki-laki',
        female: 'Perempuan',
        notifFollow: 'Berhasil mengikuti',
        notifUnfollow: 'Berhasil berhenti mengikuti',
    },
    quiz: {
        quizDetail: 'Detail {{tryout_name}}',
        detailInfo: 'Info Detail',
        question_package: 'Paket Soal',
        link_purchased_status: 'Status',
        participant: 'Partisipan',
        grade: 'Kelas',
        creator: 'Pembuat',
        number_of_question: 'Jumlah Soal',
        duration: 'Waktu',
        date_of_publish: 'Tanggal Terbit',
        question: 'Soal',
        nis: 'NIS',
        name: 'Nama',
        score: 'Nilai',
        show_all_of_player: 'Lihat semua pemain',
        have_previous_quiz: 'Anda masih memiliki quiz yang belum diselesaikan.',
        finish_previous_quiz: 'Selesaikan quiz sebelumnya',
        finish: 'Selesaikan',
        code_exam_package: 'Masukan Kode soal paket ujian',
        question_code: 'Kode Soal',
        text_insert_question_code:
            'Silahkan masukan kode soal paket ujian yang Anda terima',
        wrong_code: 'Kode yang anda masukan salah',
        message_success_title: 'Terimakasih',
        message_success_report:
            'Anda telah berpartisipasi dan menyelesaikan misi ini.',
        message_success_time_out:
            'Karena waktu pengerjaan sudah habis, jawaban kamu langsung kami kirim.',
        number_of_question_from_total: '{{current}} dari {{total}} soal',
        explanation_of_question_from_total:
            'Pembahasan Soal {{current}} dari {{total}} soal',
        remaining_time: 'Sisa waktu',
        question_number_information: 'Keterangan nomor soal',
        color_description: 'Keterangan warna',
        uncertain: 'Ragu-ragu',
        certain: 'Yakin',
        unanswered: 'Belum Terjawab',
        answered: 'Soal Terjawab',
        closeQuiz: 'Yakin untuk meninggalkan quiz ?',
        confirm: 'Konfirmasi',
        instruction_info_filled: 'Pilihan yang ditentukan oleh pengguna',
        instruction_info_uncertain:
            'Pilihan yang masih dalam kondisi ragu-ragu',
        modal_question_info_total:
            '{{total_unfilled}} Soal Belum Terjawab, {{total_filled}} Soal Terjawab, {{total_uncertain}} Soal Ragu-ragu',
        total_unfilled: '{{total_unfilled}} Soal Belum Terjawab',
        total_filled: '{{total_filled}} Soal Terjawab',
        total_uncertain: '{{total_uncertain}} Soal Ragu-ragu',
        answer_preview: 'Pratinjau Jawaban',
        send: 'Kirimkan',
        cancel: 'Cek Kembali',
        quiz_free: 'Gratis',
        rate: 'Rating',
        study_field: 'Bidang Studi',
        quiz_reference: 'Rujukan',
        quiz_review: 'Ulasan',
        multipleChoice: 'Soal Pilihan Ganda',
        no_quiz_participant: 'Siapkan Diri Anda Menjadi Yang Pertama Mencoba',
        info_quiz_rank: `Anda berada di posisi ke {{current_position}} dari {{total_student}} siswa berdasarkan {{scope}}`,
        quiz_ranking: 'Peringkat',
        national: 'Nasional',
        regency: 'Kota/Kab',
        province: 'Provinsi',
        school: 'Sekolah',
        send_quiz_review: 'berikan ulasan & rating untuk',
        has_quiz_review: 'sudah memberikan ulasan & rating untuk',
        give_review: 'Beri Ulasan',
        give_rating: 'Tentukan Rating',
        more_review: 'Lihat semua ulasan',
        your_achievements: 'Hasil pencapaian Anda',
        congrats_info: 'Selamat',
        passing_grade: 'Passing Grade Anda',
        see_explanation: 'Lihat Pembahasan Soal',
        correct_answer: 'Jawaban Benar',
        wrong_answer: 'Jawaban Salah',
        skip_answer: 'Soal belum terjawab',
        your_correct_anwer: 'Jawaban kamu benar',
        your_wrong_anwer: 'Jawaban kamu salah',
        report_question: 'Laporkan Soal',
        answer_correct: 'Jawaban Benar :',
        more_quiz_participant: 'Muat Lebih Banyak',
        rate_not_available: 'Rating belum tersedia',
        not_doing: 'Belum mengerjakan',
        thank_info:
            'Terimakasih telah berpartisipasi dan menyelesaikan quiz ini.',
        explanation_reference: 'Rujukan Pembahasan',
        placeholderText: 'Segera hadir Paket Tryout Untukmu :)',
        title: ' Yuk Lengkapi data jenjang dan kelas Kamu, supaya Kami bisa rekomendasiin materi dan tryout sesuai kebutuhan kamu',
        chooseLevel: 'Pilih Jenjang',
        chooseClass: 'Pilih Kelas',
        chooseMajor: 'Pilih Jurusan',
        seb: 'Silahkan buka Edoo Safe Exam Browser (ESEB) untuk melanjutkan pengerjaan ujian. Jika belum memiliki ESEB silahkan unduh melalui link dibawah ini.',
    },
    achievement: {
        achievement: 'Capaian',
        message: 'Pesan Penting',
        tryout: 'Try Out',
        lastTest: 'Test Akhir',
        startTest: 'Test Awal',
        achievementTryout: 'Capaian Try Out',
        historyActivity: 'Riwayat Aktifitas',
        emptyTitle: 'Belum ada riwayat aktifitas',
        link: 'Data Passing Grade Perguruan Tinggi',
        emptyTryout: 'Belum Terdapat Capaian',
        detailEmpty:
            'Silahkan pilih paket soal yang akan anda kerjakan, di laman depan',
        emptyActivity: 'Anda belum memiliki riwayat aktivitas apapun',
        pg: 'PG Anda',
        score: 'Nilai Anda',
        passingGradeEmpty: 'Maaf, saat ini daftar passing grade belum tersedia',
        choosePacket: 'Pilih Paket Soal',
    },
    payment: {
        title: 'Pembelian Quiz',
        status: 'Status',
        history: 'Riwayat',
        paymentStatus: 'Status Pembelian:',
        idr: 'Rp',
        cancelPayment: 'Batalkan Pembelian',
        detailPayment: 'Lihat Detail Status',
        messageCancel: 'Anda yakin membatalkan pembelian quiz',
        continue: 'Lanjutkan Donasi',
        confirmCancel: 'Iya, Batalakan Donasi',
        proccessPayment: 'Lanjutkan Proses Pembelian',
        proccessDonation: 'Lanjutkan Proses Donasi',
        waitPayment: 'Menunggu pembayaran',
        payment_verified: 'Terverifikasi',
        verifikasi_process: 'Proses Verifikasi',
        finishPayment: 'Transaksi Selesai',
        paymentFailed: 'Transaksi Gagal',
        onDelivered: 'Sedang Dikirim',
        historyPayment: 'Riwayat Pembelian',
        cancelTransaction: 'Transaksi Dibatalkan',
        payment: 'Pembelian',
        warning: 'SEGERA LAKUKAN PEMBAYARAN SEBELUM:',
        idTransaction: 'ID Transaksi',
        noPayment: 'Transfer pembayaran ke nomor rekening:',
        buy: 'Bayar',
        bill: 'Total Tagihan',
        discount_amount: 'Total Diskon',
        bill_uppercase: 'TOTAL TAGIHAN',
        boxordertitle: 'TOTAL BAYAR',
        paymnet_price_item: 'TOTAL HARGA',
        serviceFee: 'Biaya Layanan',
        taxAmount: 'PPN',
        details: 'Rincian',
        notice: '* Total bayar diatas adalah hasil penjumlahan rincian item pemesanan yang tertera dibawah',
        continuePayment: 'Lanjutkan Pembayaran',
        messageOrder: 'berhasil menambahkan koleksi ke box order',
        messageReturn: 'berhasil menghapus koleksi dari box order',
        box: 'Box Pesanan',
        messageNodataHistory: 'Belum ada riwayat transaksi',
        messageNodata: 'Belum terdapat transaksi',
        messageNodataDetail:
            'Silahkan pilih {{type}} yang akan anda {{action}} di laman depan',
        choosePackage: 'Pilih Quiz',
        deleteOrder: 'Hapus Pesanan',
        continue_payment: 'Lanjut Pembayaran',
        waiting_payment: 'Menunggu Pembayaran',
        verify_payment: 'Terverifikasi',
        cancel_payment: 'Transaksi Dibatalkan',
        pay_now: 'Bayar Sekarang',
        order_received: 'Pesanan Diterima',
        remaining_payment: 'Sisa Waktu Pembayaran',
        cancel_info: 'Informasi Pembatalan',
        payment_purpose: 'Tujuan Pembayaran',
        payment_date: 'Tanggal Pembayaran',
        canceled_info: 'transaksi anda dibatalkan',
        crossed_message:
            'Anda telah melewati batas pembayaran transaksi, terima kasih',
        add_epustaka_donation:
            'Tambahkan Koleksi ePustaka Melalui Fitur Donasi',
        add_epustaka_donation_info:
            'Dengan berpartisipasi dalam program berbagi koleksi anda telah membantu mengubah wajah literasi Indonesia lebih baik lebih mudah untuk diakses.',
        donation_now: 'Donasi Sekarang',
        status_donate: 'Status Donasi',
        donation_history: 'Riwayat Donasi',
        coming_soon_donation_title: `Segera Hadir {{donation_name}} Untuk Donasi`,
        coming_soon_donation_desc: `Kami ucapkan terimakasih atas partisipasi nya dan tidak akan lama lagi tersedia konten {{name}} berbagi hadir di aplikasi ${BASE_TITLE} Library`,
        waiting_delivery: 'Menunggu Dikirim',
        on_delivery: 'Sedang Dikirim',
        waiting_pickup: 'Menunggu Diambil',
        received_confirm_title: 'Nyatakan Pesanan Telah Diterima',
        received_confirm_message:
            'Dengan menekan tombol konfirmasi dibawah maka dapat dinyatakan bahwa barang yang diterima sesuai dengan penawaran. Namun jika barang yang diterima tidak sesuai dengan pesanan anda dapat menekan tombol ajukan komplain dan akan kami arahkan untuk dapat berdiskusi langsung dengan pihak customer center kami.',
        complaint: 'Ajukan Komplain',
        success_update_transaction: 'Transaksi Berhasil Diupdate.',
    },
}

export default lang
