import { useEffect, useState } from 'react'
import './wasm_exec.js'

export const useWasm = (fileName) => {
    const [state, setState] = useState({
        loaded: false,
        instance: null,
        error: null,
        module: null,
    })
    const loadWasm = async () => {
        try {
            const goWasm = new window.Go()
            const { instance } = await WebAssembly.instantiateStreaming(
                fetch(fileName),
                goWasm.importObject
            )
            goWasm.run(instance)
            setState({ instance, loaded: true, error: null })
        } catch (e) {
            setState({ ...state, error: e })
        }
    }

    useEffect(() => {
        loadWasm()
    }, [])

    return state
}
