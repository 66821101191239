import React, { useState, useEffect } from 'react'
import { Row, Col, Tabs, Modal, Button } from 'antd'
import DetailEpustaka from './DetailEpustaka'
import EpustakaBookCollection from './EpustakaBookCollection'
import Feed from 'views/timeline/feed/Feed'
import provider from 'providers/provider'
import url from 'constants/url'
import MessageSender from 'views/timeline/feed/MessageSender'
import reduxState from '../../../../../redux'
import get from 'lodash/get'
import translator from 'i18next'
import { LAYOUT } from 'constants/constant'
import { error } from 'utils/notification'
import tabKeyAction from 'redux/epustakaTabActive/action'
import VideoEpustaka from './components/VideoEpustaka'
import AudioEpustaka from './components/AudioEpustaka'
import ArticleEpustaka from './components/ArticleEpustaka'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'

const { TabPane } = Tabs

const mapStateToProps = (state) => ({
    tabActive: state.tabActive,
})

const Epustaka = (props) => {
    const { tabActive } = props

    const epustakaId = props.match.params.uuid?.split('?')[0]
    const { profile = {} } = reduxState.getState()
    const [activityVisible, setActivityVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [videoData, setVideoData] = useState([])
    const [audioData, setAudioData] = useState([])
    const [articleData, setArticleData] = useState([])
    const [visibleModal, setVisibleModal] = useState(false)

    const [dataEpustaka, setDataEpustaka] = useState({
        status: false,
        data: {},
    })

    const callback = (key) => {
        const selectedKey = key.split('epustaka-')
        tabKeyAction.setTabActive(selectedKey[1])
    }

    const getEpustaka = async () => {
        setLoading(true)
        await provider
            .singleWebHook(
                `${
                    url.webhookURL_epustaka_detail +
                    `?epustaka_id=${epustakaId}`
                }`
            )
            .then((res) => {
                const { data } = res
                setDataEpustaka(data)
                setLoading(false)
            })
            .catch((err) => {
                // setLoading(false)

                if (err.response.status === 404) {
                    setVisibleModal(true)
                } else {
                    error({
                        description: err.message,
                        placement: 'bottomRight',
                    })
                    setTimeout(() => {
                        history.replace('/')
                    }, 1000)
                }
            })
    }

    const getVideoData = async () => {
        const defaultPagination = `?limit=4&type=video&epustaka_id=${epustakaId}`
        setLoading(true)
        await provider
            .list(url.webhookURL_multimedia_list + defaultPagination)
            .then((res) => {
                const { data } = res
                setVideoData(data)
                setLoading(false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
            })
    }

    const getAudioData = async () => {
        const defaultPagination = `?limit=8&type=audio&epustaka_id=${epustakaId}`
        setLoading(true)
        await provider
            .list(url.webhookURL_multimedia_list + defaultPagination)
            .then((res) => {
                const { data } = res
                setAudioData(data)
                setLoading(false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
            })
    }

    const getArticleData = async () => {
        const defaultPagination = `?limit=3&epustaka_id=${epustakaId}`
        const filter = `&filter=${JSON.stringify({
            'n.news_status': 1,
        })}`

        setLoading(true)
        await provider
            .list(
                url.webhookURL_news_list +
                    defaultPagination +
                    (!isEmpty(filter) ? filter : '')
            )
            .then((res) => {
                const { data } = res
                setArticleData(data)
                setLoading(false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
            })
    }

    useEffect(() => {
        getEpustaka()
        getVideoData()
        getAudioData()
        getArticleData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (dataEpustaka && profile) {
            let validate = false
            get(profile, 'epustaka', []).forEach((e) => {
                if (e.id === get(dataEpustaka, 'id', '')) {
                    validate = true
                }
            })
            if (validate && get(dataEpustaka, 'drm_count', 0) > 0) {
                setActivityVisible(true)
            } else {
                setActivityVisible(false)
            }
        }
    }, [dataEpustaka, profile])

    return (
        <>
            <Row
                style={{
                    alignItems: 'center',
                    maxWidth: LAYOUT.maxWidth,
                    margin: '0 auto',
                }}
                justify="center"
            >
                <Col
                    xs={24}
                    sm={19}
                    md={20}
                    lg={24}
                    xl={24}
                    className="e__container"
                >
                    <DetailEpustaka
                        id={epustakaId}
                        dataEpustaka={dataEpustaka}
                        loadingEpustaka={loading}
                        getEpustaka={getEpustaka}
                    />
                </Col>
                <Col
                    xs={24}
                    sm={19}
                    md={20}
                    lg={24}
                    xl={24}
                    className="e__content"
                >
                    <Tabs
                        className="tabsEpustaka"
                        activeKey={`epustaka-${tabActive}`}
                        onChange={callback}
                        centered
                    >
                        <TabPane
                            tab={translator.t('menu.book')}
                            key="epustaka-1"
                        >
                            <EpustakaBookCollection
                                id={epustakaId}
                                dataEpustaka={dataEpustaka}
                            />
                        </TabPane>
                        {!isEmpty(videoData) && (
                            <TabPane
                                tab={translator.t('menu.video')}
                                key="epustaka-2"
                            >
                                <VideoEpustaka
                                    epustakaId={epustakaId}
                                    videoData={videoData}
                                    dataEpustaka={dataEpustaka}
                                    loading={loading}
                                />
                            </TabPane>
                        )}
                        {!isEmpty(audioData) && (
                            <TabPane
                                tab={translator.t('menu.audio')}
                                key="epustaka-3"
                            >
                                <AudioEpustaka
                                    epustakaId={epustakaId}
                                    audioData={audioData}
                                    dataEpustaka={dataEpustaka}
                                    loading={loading}
                                />
                            </TabPane>
                        )}
                        {!isEmpty(articleData) && (
                            <TabPane
                                tab={translator.t('menu.article')}
                                key="epustaka-4"
                            >
                                <ArticleEpustaka
                                    epustakaId={epustakaId}
                                    articleData={articleData}
                                    dataEpustaka={dataEpustaka}
                                    loading={loading}
                                />
                            </TabPane>
                        )}
                        {activityVisible && (
                            <TabPane
                                tab={translator.t('menu.activities')}
                                key="epustaka-5"
                            >
                                <div
                                    style={{
                                        marginTop: 25,
                                        display: !activityVisible && 'none',
                                    }}
                                >
                                    <MessageSender
                                        key={epustakaId}
                                        fromEpustakaDetail
                                        dataEpustaka={dataEpustaka}
                                    />
                                    <Feed
                                        fromEpustakaDetail
                                        dataEpustaka={dataEpustaka}
                                    />
                                </div>
                            </TabPane>
                        )}
                    </Tabs>
                </Col>
            </Row>
            <br />
            <br />

            <Modal
                centered
                visible={visibleModal}
                onOk={() => setVisibleModal(!visibleModal)}
                // onCancel={() => setVisibleModal(!visibleModal)}
                footer={null}
                className="modal-popup"
                closable={false}
                width={500}
                maskClosable={false}
                maskStyle={{
                    backdropFilter: 'blur(3px)',
                }}
            >
                <Row justify="center">
                    <Col
                        className="mt-lg"
                        span={20}
                        style={{ textAlign: 'center' }}
                    >
                        <p>
                            {/* {translator.t('message.no_access_content', {
                            content: translator.t(title),
                        })} */}
                            Mohon maaf url yang anda akses tidak valid atau
                            ePustaka ini mungkin telah dihapus.
                        </p>

                        <Button
                            className="btn-popup-modal"
                            size="large"
                            onClick={() => history.back()}
                        >
                            {translator.t('Kembali')}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}
export default connect(mapStateToProps)(Epustaka)
