import React, { useState, useEffect, useLayoutEffect } from 'react'
import translator from 'i18next'
import { Button, Card, Col, Input, Row, Space, Tooltip } from 'antd'
import provider from 'providers/provider'
import url from 'constants/url'
import { useHistory } from 'react-router-dom'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { FallbackImage, SkeletonBook } from 'components'
import { ModalShare } from 'components'
import getPagination from 'constants/getPagination'
import reduxState from '../../../redux'
import { error } from 'utils/notification'
// import { LoadingOutlined } from '@ant-design/icons'
import ModalRecommendation from 'views/home/books/bookDetail/ModalRecommendation'
import Text from 'antd/lib/typography/Text'
import Meta from 'antd/lib/card/Meta'
import {
    ArrowDownOutlined,
    RetweetOutlined,
    ShareAltOutlined,
} from '@ant-design/icons'
import { BASE_TITLE } from 'constants/constant'

const MoreBookHistory = () => {
    const history = useHistory()
    const { profile = {} } = reduxState.getState()
    const organization_id = profile.organizations.id
    const userId = get(profile, 'id')
    const [paramName, setParamName] = useState('')
    const [limit, setLimit] = useState(25)
    const [bookData, setBookData] = useState([])
    const [totalData, setTotalData] = useState(0)

    const [loading, setLoading] = useState(false)
    const [userFollower, setUserFollower] = useState({})
    const [currentPageFollower, setCurrentPageFollower] = useState(1)
    const [paginationFollower, setPaginationFollower] = useState({
        loading: true,
        pagination: true,
    })
    const [querySearchFollower, setQuerySearchFollower] = useState()
    const [dataFollowerChanged, setDataFollowerChanged] = useState(true)

    const [visibleModalRecommendation, setVisibleModalRecommendation] =
        useState(false)
    const [popShare, setPopShare] = useState(false)
    const [itemBook, setItemBook] = useState({})
    const [bookShare, setBookShare] = useState([])
    const [empty, setEmpty] = useState(false)

    const [stateBook, setStateBook] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => stateBook.width <= 420

    const updateDimensions = () => {
        setStateBook({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getDataBook = async () => {
        setLoading(true)
        await provider
            .list(
                encodeURI(
                    url.webhookUrl_book_history_shelf +
                        `?user_id=${userId}&limit=${limit}`
                )
            )
            .then((res) => {
                const { data, meta } = res
                setBookData(data)
                setLoading(false)
                setTotalData(meta.total)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
            })
    }

    const getDataFollower = async () => {
        const limit = 10
        const pagination = getPagination(currentPageFollower, limit, 'webhook')
        const filterQuery = isEmpty(querySearchFollower)
            ? ''
            : {
                  'users.name': { ilike: `%${querySearchFollower}%` },
              }
        await provider
            .list(
                encodeURI(
                    `${url.webhookURL_follower}${
                        pagination.result
                    }&filter=${JSON.stringify(filterQuery)}&q=&user_id=${
                        profile.id
                    }`
                )
            )
            .then((res) => {
                const { data, meta } = res
                const filterData = filter(data, (e) => e.id !== profile.id)
                if (dataFollowerChanged) {
                    setUserFollower(filterData)
                } else {
                    setUserFollower(
                        isEmpty(userFollower)
                            ? filterData
                            : concat(userFollower, filterData)
                    )
                }

                setPaginationFollower({
                    ...paginationFollower,
                    loading: false,
                })
                if (pagination.offset >= meta.total || meta.total <= limit) {
                    setPaginationFollower({
                        loading: false,
                        pagination: false,
                    })
                }
            })
    }

    useEffect(() => {
        getDataFollower()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [querySearchFollower])

    useEffect(() => {
        getDataBook()
        // disable warning missing dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit])

    const onLoadMoreFollower = async () => {
        setPaginationFollower({
            ...paginationFollower,
            loading: true,
        })
        setCurrentPageFollower(currentPageFollower + 1)
    }

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={loading}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 25)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }

    const handleSearch = async (e) => {
        if (e.key === 'Enter') {
            await provider
                .list(url.webhookUrl_book_history_shelf + `?q=${paramName}`)
                .then((res) => {
                    setBookData(res.data)
                    setLoading(false)

                    if (res.meta.total === 0) {
                        setEmpty(true)
                    } else {
                        setEmpty(false)
                    }
                })
        }
    }

    const handleClickRecommend = () => {
        setVisibleModalRecommendation(true)
    }

    const handleClickShare = () => {
        setPopShare(true)
    }

    return (
        <div
            style={{
                marginTop: '-15px',
                maxWidth: 1030,
                margin: '0 auto',
            }}
        >
            <div className="historyMoreself">
                <div
                    className="historyMoreself-title"
                    style={{
                        textAlign: isMobile() && 'start',
                        marginLeft: !isMobile() && '67px',
                    }}
                >
                    {translator.t('column.historyBook')}
                </div>
                <Input
                    placeholder={translator.t('action.find')}
                    className="historyMoreself-input"
                    onChange={(e) => setParamName(e.target.value)}
                    onKeyPress={handleSearch}
                />
            </div>
            <div className="pb__container">
                {loading ? (
                    <SkeletonBook source="fullskeleton" />
                ) : (
                    <div>
                        {empty ? (
                            <Row
                                style={{
                                    justifyContent: 'center',
                                    color: 'darkgray',
                                    marginBottom: 20,
                                }}
                            >
                                {translator.t('message.data_not_found')}
                            </Row>
                        ) : (
                            <Space
                                style={{ justifyContent: 'flex-start' }}
                                wrap
                                size={[0, 10]}
                            >
                                {map(bookData, (book, key) => {
                                    const {
                                        cover_url,
                                        book_title,
                                        book_author,
                                        book_id: id,
                                    } = book
                                    return (
                                        <Col
                                            key={key}
                                            className="pb__containerGrid"
                                            style={{
                                                margin: !isMobile()
                                                    ? '0px 20px 0px 20px'
                                                    : '0px 10px 0px 10px',
                                            }}
                                        >
                                            <div>
                                                <Card
                                                    style={{
                                                        width: isMobile()
                                                            ? 160
                                                            : 150,
                                                        background: 'none',
                                                        marginBottom: 20,
                                                        minHeight: isMobile()
                                                            ? '310px'
                                                            : '280px',
                                                    }}
                                                    className="pb__containerGridCard"
                                                    cover={
                                                        <div className="container-book">
                                                            <div className="book">
                                                                <div className="front">
                                                                    <div className="cover">
                                                                        <FallbackImage
                                                                            alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                                                            src={
                                                                                cover_url
                                                                            }
                                                                            onClick={() =>
                                                                                history.push(
                                                                                    `/book/${id}`
                                                                                )
                                                                            }
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                width: isMobile()
                                                                                    ? 153
                                                                                    : 141,
                                                                                height: isMobile()
                                                                                    ? 220
                                                                                    : 190,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="left-side">
                                                                    <h2
                                                                        style={{
                                                                            height: 20,
                                                                            width: isMobile()
                                                                                ? 220
                                                                                : 190,
                                                                            marginLeft:
                                                                                -15,
                                                                            backgroundImage: `url(${cover_url})`,
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            <Text
                                                                                style={{
                                                                                    width: 155,
                                                                                    fontSize: 10,
                                                                                    color: 'white',
                                                                                    paddingRight: 10,
                                                                                }}
                                                                                ellipsis={
                                                                                    true
                                                                                }
                                                                            >
                                                                                {
                                                                                    book_author
                                                                                }
                                                                            </Text>
                                                                        </span>
                                                                    </h2>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    actions={[
                                                        <Tooltip
                                                            title={translator.t(
                                                                'action.recommendation'
                                                            )}
                                                            color="#575757"
                                                        >
                                                            <RetweetOutlined
                                                                key="recommendation"
                                                                onClick={() =>
                                                                    handleClickRecommend(
                                                                        setItemBook(
                                                                            book
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                        </Tooltip>,
                                                        <Tooltip
                                                            title={translator.t(
                                                                'title.share'
                                                            )}
                                                            color="#575757"
                                                        >
                                                            <ShareAltOutlined
                                                                key="share"
                                                                onClick={() =>
                                                                    handleClickShare(
                                                                        setBookShare(
                                                                            book
                                                                        )
                                                                    )
                                                                }
                                                            />
                                                        </Tooltip>,
                                                    ]}
                                                >
                                                    <Meta
                                                        title={
                                                            <Tooltip
                                                                title={
                                                                    book_title
                                                                }
                                                                color="#575757"
                                                                placement="topLeft"
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontWeight: 600,
                                                                    }}
                                                                    onClick={() =>
                                                                        history.push(
                                                                            `/book/${id}`
                                                                        )
                                                                    }
                                                                >
                                                                    {book_title}
                                                                </span>
                                                            </Tooltip>
                                                        }
                                                        description={
                                                            <Text
                                                                style={{
                                                                    width: 140,
                                                                    fontSize: 13,
                                                                    color: 'gray',
                                                                }}
                                                                ellipsis={true}
                                                            >
                                                                <Tooltip
                                                                    title={
                                                                        book_author
                                                                    }
                                                                    color="#575757"
                                                                    placement="topLeft"
                                                                >
                                                                    {
                                                                        book_author
                                                                    }
                                                                </Tooltip>
                                                            </Text>
                                                        }
                                                    />
                                                </Card>
                                            </div>
                                        </Col>
                                    )
                                })}

                                <ModalRecommendation
                                    visibleModal={visibleModalRecommendation}
                                    setVisibleModal={
                                        setVisibleModalRecommendation
                                    }
                                    bookData={itemBook}
                                    data={userFollower}
                                    userId={profile.id}
                                    organization_id={organization_id}
                                    currentPageFollower={currentPageFollower}
                                    paginationFollower={paginationFollower}
                                    onLoadMore={onLoadMoreFollower}
                                    getDataFollower={getDataFollower}
                                    setDataFollowerChanged={
                                        setDataFollowerChanged
                                    }
                                    setQuerySearchFollower={
                                        setQuerySearchFollower
                                    }
                                    source="bookshelf"
                                />

                                <ModalShare
                                    urlFrom="book"
                                    data={bookShare}
                                    visibleModal={popShare}
                                    setVisibleModal={setPopShare}
                                />
                            </Space>
                        )}
                        <div className="loadmore-btn">
                            <DispalyButton />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default MoreBookHistory
