import * as ActionType from './contant'


const initState = {}

const reducer = (state = initState, action) => {
    const { type, value } = action

    switch (type) {
        case ActionType.SET_LAST_READING_DURATION:
            return value
        case ActionType.CLEAR_LAST_READING_DURATION:
            return initState
        default:
            return state
    }
}

export default reducer
