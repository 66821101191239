import * as jwt from 'jsonwebtoken'
import * as moment from 'moment'
import * as ActionType from './contant'
import { YEAR_DATETIME_FULL_OFFSET_FORMAT } from 'constants/formats'
import Encryption from 'utils/encryption'

import ls from 'utils/secureLocalStorage'

const STORAGE = Encryption.encryptWithMD5('ewState_auth')
const initState = {}

const initializeState = () => {
    let auth
    try {
        auth = ls.get(STORAGE)
    } catch (error) {
        return error
    }
    const state = auth ? JSON.parse(auth) : initState
    return state
}

const reducer = (state = initializeState(), action) => {
    const { type, value } = action
    let attributes
    let accessToken
    let decode

    switch (type) {
        case 'VALIDATING_TOKEN':
            return {
                ...state,
                validatingToken: value,
            }
        case ActionType.AUTH_SET:
            attributes = value || {}
            accessToken = value.access_token

            if (accessToken) {
                decode = jwt.decode(accessToken)
            }

            if (decode) {
                value.expired_at = moment(decode.exp * 1000).format(
                    YEAR_DATETIME_FULL_OFFSET_FORMAT
                )
            }

            ls.set(STORAGE, JSON.stringify(value))
            return { ...value }
        case ActionType.AUTH_SET_TOKEN:
            attributes =
                {
                    ...state,
                    ...value,
                } || {}

            decode = jwt.decode(value.access_token)
            if (decode) {
                value.expired_at = moment(decode.exp * 1000).format(
                    YEAR_DATETIME_FULL_OFFSET_FORMAT
                )
            }

            // eslint-disable-next-line no-case-declarations
            const auth = { ...state, ...attributes }
            ls.set(STORAGE, JSON.stringify(auth))
            return { ...auth, validatingToken: false }
        case ActionType.AUTH_CLEAR:
            ls.remove(STORAGE)
            return {}
        default:
            return state
    }
}

export default reducer
