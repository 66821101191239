import React, { useState, useLayoutEffect } from 'react'
import { Col, Row } from 'antd'
import { VideoCardComponent } from 'views/home/video/components'
import map from 'lodash/map'
import { NavigationHome } from 'components'
import Carousel from 'nuka-carousel'
import Slider from 'react-slick'

const VideoSliderSection = (props) => {
    const { loading, data, detailUrl, title } = props

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isSmallMobile = () => state.width <= 360
    const isMobile = () => state.width <= 420
    const isTablet = () => state.width <= 768
    const isDesktop = () => state.width <= 1024
    const xlDesktop = () => state.width <= 1440
    const xxlDesktop = () => state.width <= 1920
    const desktop4K = () => state.width <= 2560
    const UHD4k = () => state.width >= 2561

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const settings = {
        infinite: false,
        speed: 400,
        initialSlide: 0,
        dots: false,
        arrows: false,
        slidesToShow: isSmallMobile() ? 1 : 1.03,
        // slidesToScroll: 1,
        adaptiveHeight: false,
        swipeToSlide: true,
    }

    return (
        <div className="v-container">
            <NavigationHome title={title} detailUrl={detailUrl} />
            <Row
                style={{
                    marginTop: '1rem',
                    marginBottom: isMobile() ? '0.5rem' : '2rem',
                }}
            >
                {isMobile() ? (
                    <Col span={24} className="section-slider">
                        <Slider {...settings}>
                            {map(data, (item, id) => {
                                return (
                                    <VideoCardComponent
                                        key={id}
                                        data={item}
                                        loading={loading}
                                    />
                                )
                            })}
                        </Slider>
                    </Col>
                ) : (
                    <Carousel
                        speed={50}
                        withoutControls={true}
                        disableEdgeSwiping={false}
                        slidesToScroll="auto"
                        slideWidth={
                            isMobile()
                                ? 0.55
                                : isTablet()
                                ? 0.67
                                : isDesktop()
                                ? 0.65
                                : xlDesktop()
                                ? 0.4
                                : xxlDesktop()
                                ? 0.3
                                : desktop4K()
                                ? 0.2
                                : UHD4k()
                                ? 0.2
                                : 0.65
                        }
                        cellAlign="left"
                    >
                        {map(data, (item, id) => {
                            return (
                                <VideoCardComponent
                                    key={id}
                                    data={item}
                                    loading={loading}
                                />
                            )
                        })}
                    </Carousel>
                )}
            </Row>
        </div>
    )
}

export default VideoSliderSection
