import React, { useEffect, useState } from 'react'
import { Button, Input, Row, Space } from 'antd'
import provider from 'providers/provider'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import url from 'constants/url'
import reduxState from '../../../redux'
import { error } from 'utils/notification'
import translator from 'i18next'

import { VideoCardCategory } from 'views/home/video/components'
import { ArrowDownOutlined, SearchOutlined } from '@ant-design/icons'
import { SkeletonVideo } from 'components'
import { COLORS } from 'constants/constant'

const MoreHistoryVideo = (props) => {
    const userId = props.location.state.userId
    const source = props.location.state.source
    const { profile = {} } = reduxState.getState()
    const id = get(profile, 'id', '')
    const defaultPagination = `&type=video`
    const filter =
        source === 'userProfil' ? `&user_id=${userId}` : `&user_id=${id}`

    const [limit, setLimit] = useState(20)
    const [totalData, setTotalData] = useState(0)
    const [loading, setLoading] = useState(false)
    const [empty, setEmpty] = useState(false)
    const [videoData, setVideoData] = useState([])
    const [paramName, setParamName] = useState('')

    const getVideoData = async () => {
        setLoading(true)
        await provider
            .list(
                encodeURI(
                    url.webhookURL_multimedia_history_list +
                        `?limit=${limit}` +
                        defaultPagination +
                        filter
                )
            )
            .then((res) => {
                const { data, meta } = res
                setVideoData(data)
                setTotalData(meta)
                setLoading(false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
                return
            })
    }

    useEffect(() => {
        getVideoData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit])

    const handleSearch = async (e) => {
        if (e.key === 'Enter') {
            await provider
                .list(
                    url.webhookURL_multimedia_history_list +
                        `?q=${paramName}&type=video&user_id=${id}&limit=${limit}`
                )
                .then((res) => {
                    const { meta, data } = res
                    if (meta.total === 0) {
                        setEmpty(true)
                    } else {
                        setEmpty(false)
                    }
                    setVideoData(data)
                    setTotalData(meta.total)
                    setLoading(false)
                })
        }
    }

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={loading}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 20)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    marginTop: -20,
                    justifyContent: 'space-between',
                    maxWidth: 900,
                    margin: '1em auto',
                }}
            >
                <div
                    style={{
                        color: COLORS.primaryColor,
                        fontWeight: 600,
                        fontSize: 16,
                    }}
                >
                    {translator.t('column.watch')}
                </div>
                <Input
                    style={{ width: 200, borderRadius: 25 }}
                    placeholder="Cari Vidio"
                    suffix={<SearchOutlined />}
                    onChange={(e) => setParamName(e.target.value)}
                    onKeyPress={handleSearch}
                />
            </div>
            <Row
                type="flex"
                className="vc_container"
                justify="center"
                style={{ marginTop: '-15px' }}
            >
                {loading && isEmpty(videoData) ? (
                    <SkeletonVideo source="fullskeleton" />
                ) : empty ? (
                    <Row
                        style={{
                            justifyContent: 'center',
                            color: 'darkgray',
                        }}
                    >
                        {translator.t('message.data_not_found')}
                    </Row>
                ) : (
                    <div className="mhv-container">
                        <Row
                            style={{
                                justifyContent: 'center',
                                maxWidth: 1100,
                                marginTop: 20,
                            }}
                            justify="center"
                        >
                            <Space
                                style={{ justifyContent: 'center' }}
                                wrap
                                size={[20, 10]}
                            >
                                {videoData.map((item, id) => (
                                    <VideoCardCategory
                                        key={id}
                                        data={item}
                                        source={source ? 'userProfil' : 'video'}
                                        frombookself="bookself"
                                        getMedia={getVideoData}
                                    />
                                ))}
                            </Space>
                            <div className="loadmore-btn">
                                <DispalyButton />
                            </div>
                        </Row>
                    </div>
                )}
            </Row>
        </>
    )
}

export default MoreHistoryVideo
