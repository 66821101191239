import * as ActionType from './contant'
import ls from 'utils/secureLocalStorage'
import Encryption from 'utils/encryption'

const STORAGE = Encryption.encryptWithMD5('ewProfile')
const initState = []

const initializeState = () => {
    const profile = ls.get(STORAGE)
    const state = profile ? JSON.parse(profile) : initState
    return state
}

const reducer = (state = initializeState(), action) => {
    const { type, value } = action

    switch (type) {
        case ActionType.SET_PROFILE:
            ls.set(STORAGE, JSON.stringify(value))
            return value
        case ActionType.UPDATE_PROFILE:
            ls.set(STORAGE, JSON.stringify(value))
            return value
        case ActionType.CLEAR_PROFILE:
            ls.remove(STORAGE)
            return initState
        default:
            return state
    }
}

export default reducer
