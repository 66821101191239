import isEmpty from 'lodash/isEmpty'

const descriptionText = (props) => {
    const { text } = props
    let textResult = ''
    if (!isEmpty(text)) {
        textResult =
            text.length > 40 ? `${text.slice(0, 30)}...` : text.slice(0, 30)
    }
    return textResult
}

export default descriptionText
