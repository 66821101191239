import React, { useEffect, useState } from 'react'
import { Button, Row, Select, Space } from 'antd'
import provider from 'providers/provider'
import isEmpty from 'lodash/isEmpty'
import url from 'constants/url'
import { error } from 'utils/notification'
import translator from 'i18next'

import { VideoCardCategory } from 'views/home/video/components'
import { ArrowDownOutlined } from '@ant-design/icons'
import { SkeletonVideo } from 'components'
import { COLORS } from 'constants/constant'

const { Option } = Select

const ListVideoEpustaka = (props) => {
    const epustakaId = props?.location?.state?.id
    const dataEpustaka = props?.location?.state
    const [limit, setLimit] = useState(20)
    const [totalData, setTotalData] = useState(0)
    const [loading, setLoading] = useState(false)
    const [videoData, setVideoData] = useState([])
    const [sortingVideo, setSortingVideo] = useState('')

    const defaultPagination = `?limit=${limit}&type=video&epustaka_id=${epustakaId}&sort=${sortingVideo}`

    const getVideoData = async () => {
        setLoading(true)
        await provider
            .list(encodeURI(url.webhookURL_multimedia_list + defaultPagination))
            .then((res) => {
                const { data, meta } = res
                setVideoData(data)
                setTotalData(meta)
                setLoading(data)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
                return
            })
    }

    useEffect(() => {
        getVideoData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, sortingVideo])

    const handleSorting = (e) => {
        setSortingVideo(e === 'latest' ? '-created_at' : 'media_title')
    }

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={loading}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 20)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    maxWidth: 900,
                    margin: 'auto',
                }}
            >
                <div
                    style={{
                        color: COLORS.primaryColor,
                        fontWeight: 600,
                        fontSize: 16,
                        padding: '10px',
                        maxWidth: '900px',
                        alignItems: 'center',
                    }}
                >
                    {translator.t('epustka.collectionVideo')}
                </div>
                <Select
                    size="small"
                    bordered={false}
                    placeholder={translator.t('action.sort')}
                    onChange={handleSorting}
                    style={{ alignSelf: 'center' }}
                >
                    <Option value="latest">
                        {translator.t('sort.latest')}
                    </Option>
                    <Option value="abjad">{translator.t('sort.abjad')}</Option>
                </Select>
            </div>
            <Row
                type="flex"
                className="vc_container"
                justify="center"
                style={{ marginTop: '-15px' }}
            >
                {loading && isEmpty(videoData) ? (
                    <SkeletonVideo source="fullskeleton" />
                ) : (
                    <div className="mhv-container">
                        <Row
                            style={{
                                justifyContent: 'center',
                                maxWidth: 1100,
                                marginTop: 20,
                            }}
                            justify="center"
                        >
                            <Space
                                style={{ justifyContent: 'center' }}
                                wrap
                                size={[20, 10]}
                            >
                                {videoData.map((item, id) => (
                                    <VideoCardCategory
                                        key={id}
                                        data={item}
                                        epustakaId={epustakaId}
                                        dataEpustaka={dataEpustaka}
                                        source="epustakadetail"
                                    />
                                ))}
                            </Space>
                            <div className="loadmore-btn">
                                <DispalyButton />
                            </div>
                        </Row>
                    </div>
                )}
            </Row>
        </>
    )
}

export default ListVideoEpustaka
