import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Button, Row, Space } from 'antd'
import { ArrowDownOutlined } from '@ant-design/icons'
import provider from 'providers/provider'
import url from 'constants/url'
import { VideoCardCategory } from '../components'
import translator from 'i18next'
import { MultimediaSorter, SkeletonVideo } from 'components'
import { LAYOUT } from 'constants/constant'

const VideoCategory = () => {
    const [videoCategoryData, setVideoCategoryData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [limit, setLimit] = useState(20)
    const [loading, setLoading] = useState(false)
    const [sortingVideo, setSortingVideo] = useState('')

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 768

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getVideoData = async () => {
        setLoading(true)

        await provider
            .list(
                encodeURI(url.webhookURL_multimedia_list) +
                    `?limit=${limit}` +
                    `&sort=${sortingVideo}` +
                    `&type=video`
            )
            .then((res) => {
                const { data, meta } = res
                setTotalData(meta.total)
                setVideoCategoryData(data)
                setLoading(false)
            })
    }

    useEffect(() => {
        getVideoData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, sortingVideo])

    const handleSorting = (e) => {
        setSortingVideo(e === 'latest' ? '-created_at' : 'media_title')
    }

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={loading}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 15)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }

    return (
        <div>
            <MultimediaSorter
                categoryName={translator.t('action.recommendation')}
                handleSorting={handleSorting}
                fromHome
            />

            <Row
                className="vc_container"
                style={{
                    alignItems: 'center',
                }}
                type="flex"
                justify="center"
            >
                <div style={{ marginTop: 40 }}>
                    {loading ? (
                        <SkeletonVideo source="fullskeleton" />
                    ) : (
                        <>
                            <Row
                                style={{
                                    justifyContent: 'center',
                                }}
                                justify="center"
                            >
                                <Space
                                    wrap
                                    size={[25, 20]}
                                    style={{
                                        maxWidth: LAYOUT.maxWidth,
                                        justifyContent: isMobile() && 'center',
                                    }}
                                    className="video-wrapper-home"
                                >
                                    {videoCategoryData.map((item, id) => (
                                        <VideoCardCategory
                                            key={id}
                                            data={item}
                                            source="homevideo"
                                        />
                                    ))}
                                </Space>
                            </Row>
                            <div className="loadmore-btn">
                                <DispalyButton />
                            </div>
                        </>
                    )}
                </div>
            </Row>
        </div>
    )
}

export default VideoCategory
