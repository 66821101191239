import React, { useState } from 'react'
import Modal from 'antd/lib/modal/Modal'
import translator from 'i18next'
import { Button, Divider, message } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import get from 'lodash/get'
import provider from 'providers/provider'
import { error } from 'utils/notification'
import url from 'constants/url'
// import reduxState from '../redux'
import { useHistory } from 'react-router'
import getAcronym from './Acronym'
import { isUndefined } from 'lodash'

export default function ModalConfirmFollowUnfollow({
    visibleModal,
    setVisibleModal,
    item,
    getFollower,
    getFollowing,
    getSuggestFriends,
    getProfile,
    source,
    setAction,
}) {
    const name = item?.name || ''
    const id = item?.id || null
    const { color, acronym } = getAcronym(name, id)

    const history = useHistory()
    // const { profile = {} } = reduxState.getState()
    // const follower_id = get(profile, 'id', '')
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (followed, user_id, follow_id) => {
        if (!isUndefined(setAction) && setAction !== undefined) setAction(true)
        if (followed) {
            // function to unfollow
            setLoading(true)
            await provider
                .deleteData(url.webhookURL_user_follow, { id: follow_id })
                .then(() => {
                    if (source === 'profil') {
                        getFollower()
                        getFollowing()
                    }
                    if (source === 'friendship') {
                        getFollower()
                    }
                    if (source === 'userProfile') {
                        getProfile()
                    }
                    message.success(translator.t('profile.notifUnfollow'))
                    setLoading(false)
                    setVisibleModal(false)
                })
                .catch((err) => {
                    error({
                        description: err.message,
                    })
                })
        } else {
            // function to follow
            setLoading(true)
            const data = {
                user_id,
            }
            await provider
                .insert(url.webhookURL_user_follow, data)
                .then(() => {
                    if (source === 'profil') {
                        getFollower()
                        getFollowing()
                    }
                    if (source === 'suggestFriend') {
                        getSuggestFriends()
                    }
                    if (source === 'friendship') {
                        getFollower()
                    }
                    if (source === 'userProfile') {
                        getProfile()
                    }
                    setLoading(false)
                    message.success(translator.t('profile.notifFollow'))
                    setVisibleModal(false)
                })
                .catch((err) => {
                    error({
                        description: err.message,
                        placement: 'bottomRight',
                    })
                })
        }
    }

    return (
        <Modal
            centered
            visible={visibleModal}
            onOk={() => setVisibleModal(!visibleModal)}
            onCancel={() => setVisibleModal(!visibleModal)}
            footer={null}
            className="m__unfollow"
        >
            <p style={{ textAlign: 'center' }}>
                {translator.t(
                    !get(item, 'followed', false)
                        ? 'message.makeFriends'
                        : 'action.cancel_follow'
                )}
            </p>
            <Divider />
            <div className="m__unfollowcontent">
                <Avatar
                    className="m__unfollowAvatar"
                    style={{
                        cursor: 'pointer',
                        backgroundImage: color,
                        display: 'inline-flex',
                        alignItems: 'center',
                    }}
                    src={item.avatar_url}
                    onClick={() => history.push(`/profil/user/${item.id}`)}
                >
                    <span style={{ fontSize: 20 }}>{acronym}</span>
                </Avatar>
                <p style={{ padding: 10 }}>
                    {translator.t(
                        !get(item, 'followed', false)
                            ? 'message.follow'
                            : 'message.unfollow'
                    )}{' '}
                    {item.name}
                </p>
                <Button
                    className="m__unfollowBtn"
                    onClick={() => {
                        handleSubmit(item.followed, item.id, item.follow_id)
                    }}
                    htmlType="submit"
                    loading={loading}
                    type="primary"
                >
                    {translator.t(
                        !get(item, 'followed', false)
                            ? 'message.confirmFollow'
                            : 'message.confirmUnfollow'
                    )}
                </Button>
            </div>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <Button
                    style={{
                        border: 'none',
                    }}
                    onClick={() => setVisibleModal(!visibleModal)}
                >
                    {translator.t('action.cancel')}
                </Button>
            </div>
        </Modal>
    )
}
