import store from '../store'
import * as ActionType from './contant'

export default {
    setFromReader: (value) => {
        const action = { type: ActionType.SET_FROM_READER, value }
        store.dispatch(action)
    },
    clearFromReader: () => {
        const action = { type: ActionType.CLEAR_FROM_READER }
        store.dispatch(action)
    },
}
