import { colorPallet } from 'constants/constant'

const getAcronym = (name, id) => {
    const names = name || ''
    const ids = id || ''
    const splittedName = names?.split(' ')
    let acronym = names
    if (splittedName?.length > 1) {
        acronym = splittedName?.reduce(
            (response, word) => response + word?.slice(0, 1),
            ''
        )
    }
    acronym = acronym?.slice(0, 2)?.toUpperCase()

    const randomColor = (ids + names)?.split('')?.reduce((arr, next) => arr + next?.charCodeAt(0), 0) % colorPallet?.length

    return { color: colorPallet[randomColor], acronym }
}

export default getAcronym
