import React, { useState, useEffect, useLayoutEffect } from 'react'
import translator from 'i18next'
import { Button, Card, Input, Row, Space, Tooltip } from 'antd'
import provider from 'providers/provider'
import url from 'constants/url'
import { useHistory } from 'react-router-dom'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { FallbackImage } from 'components'
import ModalReturnBook from 'views/bookshelf/components/ModalReturnBook'
import { ModalShare } from 'components'
import getPagination from 'constants/getPagination'
import reduxState from '../../../../redux'
import ModalRecommendation from 'views/home/books/bookDetail/ModalRecommendation'
import Text from 'antd/lib/typography/Text'
import Meta from 'antd/lib/card/Meta'
import {
    ArrowDownOutlined,
    DeleteOutlined,
    RetweetOutlined,
    ShareAltOutlined,
} from '@ant-design/icons'
import { BASE_TITLE } from 'constants/constant'
import { error } from 'utils/notification'

const MoreBookShelf = () => {
    const history = useHistory()
    const { profile = {} } = reduxState.getState()
    const organization_id = profile.organizations.id
    const [paramName, setParamName] = useState('')
    const [bookData, setBookData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [limit, setLimit] = useState(25)
    const [loading, setLoading] = useState(false)
    const [userFollower, setUserFollower] = useState({})
    const [currentPageFollower, setCurrentPageFollower] = useState(1)
    const [paginationFollower, setPaginationFollower] = useState({
        loading: true,
        pagination: true,
    })
    const [querySearchFollower, setQuerySearchFollower] = useState()
    const [dataFollowerChanged, setDataFollowerChanged] = useState(true)

    const [visibleModalRecommendation, setVisibleModalRecommendation] =
        useState(false)
    const [visibleDelete, setVisibleDelete] = useState(false)
    const [popShare, setPopShare] = useState(false)
    const [itemBook, setItemBook] = useState({})
    const [bookShare, setBookShare] = useState([])
    const [empty, setEmpty] = useState(false)

    const [stateBook, setStateBook] = useState({
        width: window.innerWidth,
    })

    const isXtraSmall = () => stateBook.width <= 320
    const isSmallMobile = () => stateBook.width <= 360
    const isSmalMobile = () => stateBook.width <= 375
    const isMobile = () => stateBook.width <= 420

    const updateDimensions = () => {
        setStateBook({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getDataBook = async () => {
        setLoading(true)
        await provider
            .list(url.webhookURL_book_borrow_shelf + `?limit=${limit}`)
            .then((res) => {
                const { data, meta } = res
                setBookData(data)
                setTotalData(meta.total)
                setLoading(false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
            })
    }

    const getDataFollower = async () => {
        const limit = 10
        const pagination = getPagination(currentPageFollower, limit, 'webhook')
        const filterQuery = isEmpty(querySearchFollower)
            ? ''
            : {
                  'users.name': { ilike: `%${querySearchFollower}%` },
              }
        await provider
            .list(
                encodeURI(
                    `${url.webhookURL_follower}${
                        pagination.result
                    }&filter=${JSON.stringify(filterQuery)}&q=&user_id=${
                        profile.id
                    }`
                )
            )
            .then((res) => {
                const { data, meta } = res
                const filterData = filter(data, (e) => e.id !== profile.id)
                if (dataFollowerChanged) {
                    setUserFollower(filterData)
                } else {
                    setUserFollower(
                        isEmpty(userFollower)
                            ? filterData
                            : concat(userFollower, filterData)
                    )
                }

                setPaginationFollower({
                    ...paginationFollower,
                    loading: false,
                })
                if (pagination.offset >= meta.total || meta.total <= limit) {
                    setPaginationFollower({
                        loading: false,
                        pagination: false,
                    })
                }
            })
    }

    useEffect(() => {
        getDataFollower()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [querySearchFollower])

    useEffect(() => {
        getDataBook()
        // disable warning missing dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit])

    const onLoadMoreFollower = async () => {
        setPaginationFollower({
            ...paginationFollower,
            loading: true,
        })
        setCurrentPageFollower(currentPageFollower + 1)
    }

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={loading}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 25)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }

    const handleSearch = async (e) => {
        if (e.key === 'Enter') {
            await provider
                .list(url.webhookURL_book_borrow_shelf + `?q=${paramName}`)
                .then((res) => {
                    const { meta } = res
                    setBookData(res.data)
                    setLoading(false)

                    if (meta.total === 0) {
                        setEmpty(true)
                    } else {
                        setEmpty(false)
                    }
                })
        }
    }

    const handleClickDelete = () => {
        setVisibleDelete(true)
    }

    const handleClickRecommend = () => {
        setVisibleModalRecommendation(true)
    }

    const handleClickShare = () => {
        setPopShare(true)
    }

    return (
        <div
            style={{
                marginTop: '-15px',
                maxWidth: 1100,
                margin: '0 auto',
            }}
        >
            <div className="historyMoreself">
                <div
                    className="historyMoreself-title"
                    style={{
                        textAlign: isMobile() && 'start',
                        marginLeft: !isMobile() && '77px',
                    }}
                >
                    {translator.t('column.selfBook')}
                </div>

                <Input
                    placeholder={translator.t('action.find')}
                    className="historyMoreself-input"
                    onChange={(e) => setParamName(e.target.value)}
                    onKeyPress={handleSearch}
                />
            </div>
            <div className="pb__container">
                {empty ? (
                    <Row
                        style={{
                            justifyContent: 'center',
                            color: 'darkgray',
                            marginBottom: 20,
                        }}
                    >
                        <p>{translator.t('message.data_not_found')}</p>
                    </Row>
                ) : (
                    <Space
                        style={{ justifyContent: 'flex-start' }}
                        wrap
                        size={[0, 10]}
                    >
                        {map(bookData, (book, key) => {
                            const {
                                cover_url,
                                book_title,
                                book_author,
                                epustaka_id,
                                book_id: id,
                            } = book

                            return (
                                <div
                                    className="pb__containerGrid"
                                    key={key}
                                    style={{
                                        margin: isXtraSmall()
                                            ? '0px'
                                            : isSmallMobile()
                                            ? '0px 10px'
                                            : isSmalMobile()
                                            ? '0px 10px'
                                            : '0px 20px',
                                    }}
                                >
                                    <Card
                                        style={{
                                            width: 160,
                                        }}
                                        className="pb__containerGridCard"
                                        cover={
                                            <div className="container-book">
                                                <div className="book">
                                                    <div className="front">
                                                        <div className="cover">
                                                            <FallbackImage
                                                                alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                                                src={cover_url}
                                                                onClick={() =>
                                                                    history.push(
                                                                        {
                                                                            pathname: `/book/${id}/${
                                                                                epustaka_id ||
                                                                                ''
                                                                            }`,
                                                                            state: {
                                                                                epustaka_id,
                                                                            },
                                                                        }
                                                                    )
                                                                }
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    width: 159,
                                                                    height: 220,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="left-side">
                                                        <h2
                                                            style={{
                                                                height: 20,
                                                                width: 220,
                                                                marginLeft: -15,
                                                                backgroundImage: `url(${cover_url})`,
                                                            }}
                                                        >
                                                            <span>
                                                                <Text
                                                                    style={{
                                                                        width: 155,
                                                                        fontSize: 10,
                                                                        color: 'white',
                                                                        paddingRight: 10,
                                                                    }}
                                                                    ellipsis={
                                                                        true
                                                                    }
                                                                >
                                                                    {
                                                                        book_author
                                                                    }
                                                                </Text>
                                                            </span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        actions={[
                                            <Tooltip
                                                title={translator.t(
                                                    'title.deleteBook'
                                                )}
                                                color="#575757"
                                            >
                                                <DeleteOutlined
                                                    key="delete"
                                                    onClick={() => {
                                                        handleClickDelete(
                                                            setItemBook(book)
                                                        )
                                                    }}
                                                />
                                            </Tooltip>,
                                            <Tooltip
                                                title={translator.t(
                                                    'action.recommendation'
                                                )}
                                                color="#575757"
                                            >
                                                <RetweetOutlined
                                                    key="recommendation"
                                                    onClick={() =>
                                                        handleClickRecommend(
                                                            setItemBook(book)
                                                        )
                                                    }
                                                />
                                            </Tooltip>,
                                            <Tooltip
                                                title={translator.t(
                                                    'title.share'
                                                )}
                                                color="#575757"
                                            >
                                                <ShareAltOutlined
                                                    key="share"
                                                    onClick={() =>
                                                        handleClickShare(
                                                            setBookShare(book)
                                                        )
                                                    }
                                                />
                                            </Tooltip>,
                                        ]}
                                    >
                                        <Meta
                                            title={
                                                <Tooltip
                                                    title={book_title}
                                                    color="#575757"
                                                    placement="topLeft"
                                                >
                                                    <span
                                                        style={{
                                                            fontWeight: 600,
                                                        }}
                                                        onClick={() =>
                                                            history.push({
                                                                pathname: `/book/${id}/${
                                                                    epustaka_id ||
                                                                    ''
                                                                }`,
                                                                state: {
                                                                    epustaka_id,
                                                                },
                                                            })
                                                        }
                                                    >
                                                        {book_title}
                                                    </span>
                                                </Tooltip>
                                            }
                                            description={
                                                <Text
                                                    style={{
                                                        width: 140,
                                                        fontSize: 13,
                                                        color: 'gray',
                                                    }}
                                                    ellipsis={true}
                                                >
                                                    <Tooltip
                                                        title={book_author}
                                                        color="#575757"
                                                        placement="topLeft"
                                                    >
                                                        {book_author}
                                                    </Tooltip>
                                                </Text>
                                            }
                                        />
                                    </Card>
                                </div>
                            )
                        })}
                        <ModalReturnBook
                            data={bookData}
                            visibleModal={visibleDelete}
                            setVisibleModal={setVisibleDelete}
                            bookData={itemBook}
                            getDataBook={getDataBook}
                        />

                        <ModalRecommendation
                            visibleModal={visibleModalRecommendation}
                            setVisibleModal={setVisibleModalRecommendation}
                            bookData={itemBook}
                            data={userFollower}
                            userId={profile.id}
                            organization_id={organization_id}
                            currentPageFollower={currentPageFollower}
                            paginationFollower={paginationFollower}
                            onLoadMore={onLoadMoreFollower}
                            getDataFollower={getDataFollower}
                            setDataFollowerChanged={setDataFollowerChanged}
                            setQuerySearchFollower={setQuerySearchFollower}
                            source="bookshelf"
                        />

                        <ModalShare
                            data={bookShare}
                            urlFrom="book"
                            visibleModal={popShare}
                            setVisibleModal={setPopShare}
                        />
                    </Space>
                )}
                <div className="loadmore-btn">
                    <DispalyButton />
                </div>
            </div>
        </div>
    )
}

export default MoreBookShelf
