import * as ActionType from './contant'
import ls from 'utils/secureLocalStorage'

const STORAGE = 'schools'
const initState = {}

const initializeState = () => {
    const config = ls.get(STORAGE)
    const state = config ? JSON.parse(config) : initState
    return state
}

const reducer = (state = initializeState(), action) => {
    const { type, value } = action

    switch (type) {
        case ActionType.SET_SCHOOLS:
            ls.set(STORAGE, JSON.stringify(value))
            return { ...value }
        case ActionType.UPDATE_SCHOOLS:
            ls.set(STORAGE, JSON.stringify(value))
            return { ...value }
        case ActionType.CLEAR_SCHOOLS:
            return {}
        default:
            return state
    }
}

export default reducer
