import React, { useState, useEffect } from 'react'
import provider from 'providers/provider'
import url from 'constants/url'
import { success } from 'utils/notification'
import notificationHandler from 'utils/notificationHandler'
import { useHistory } from 'react-router'
import { ReactReader, ReaderContainer } from 'epub-react-reader'
import { useSelector } from 'react-redux'
import { useWasm } from 'utils/LoadWasm/useWasm'
import getUrlFromUrlBook from 'utils/getUrlFromUrlBook'
import { message } from 'antd'
import translator from 'i18next'
import { useDay } from 'utils/useDay'
import dayjs from 'dayjs'
import isUndefined from 'lodash/isUndefined'

const BookReadEpub = (props) => {
    const history = useHistory()
    const { dateFormatter } = useDay()

    const {
        bookId,
        url_file,
        bookTitle,
        author,
        borrow_key = null,
        isDrm = false,
        epustakaId,
        file_ext,
        bookDetails,
    } = props?.location?.state

    const { loaded } = useWasm('_wasm/main.wasm')

    const profile = useSelector((state) => state.profile)

    const [file, setFile] = useState(null)

    const drmSetup = async () => {
        const payloadData = isDrm
            ? {
                  bookId: bookId,
                  userid: profile.id,
                  epustakaid: epustakaId,
                  borrowkey: borrow_key,
                  url: url_file,
                  timestamp: 1623688214000,
                  file_ext,
              }
            : null
        await getUrlFromUrlBook(payloadData, setFile)
    }

    useEffect(() => {
        isDrm && loaded && drmSetup()
    }, [url_file, loaded, isDrm])

    // const [createCitation] = useMutation(CREATE_BOOK_CITATIONS)

    const handleFinish = async (payload) => {
        try {
            const res = await provider.insert(
                url.webhookURL_book_reading_progress,
                payload
            )
            localStorage.setItem('book-progress', location)
            success({
                description: res.data.message,
                placement: 'bottomRight',
            })
            history.goBack()
        } catch (err) {
            notificationHandler('dev2', err)
        }
    }

    const onBack = (props) => {
        const { location: cfi, rendition } = props

        const {
            location: {
                start: { href },
            },
        } = rendition

        const epub_read_locator = {
            bookId: bookId,
            href: href,
            created: Date.now(),
            locations: {
                cfi,
                title: '/',
            },
        }

        const payloadData = {
            book_id: bookId,
            total_page: 0,
            last_page: 0,
            platform_info: 'web',
            epub_read_locator: JSON.stringify(epub_read_locator),
        }

        handleFinish(payloadData)
    }

    // const handleBookmark = async (bookmarkCollection) => {
    //   //TODO this payload is imcomplete
    //   const bookmarkPayload = {
    //     book_id: bookId,
    //     bookmark_label: 'bookmark_label',
    //     description: 'Page 4 | geografi',
    //     page: 4,
    //     title: 'geografi',
    //     user_id: '6ccbe89a-8146-45ed-b1a1-e1bd4b14f2be',
    //   }
    // }

    const handleCitation = async (val) => {
        try {
            const textValue = val?.text?.substr(0, 525)
            await provider.insert(url.webhookURL_book_citation, {
                book_id: bookId,
                page: val.page,
                text: textValue,
                epub_read_locator: '-',
                platform_info: 'web',
                user_id: profile.id,
            })
            const today = dayjs().format('DD/MM/YYYY')
            const timezoneMap = {
                'GMT+7': 'WIB',
                'GMT+8': 'WITA',
                'GMT+9': 'WIT',
            }
            const timezone = timezoneMap[dateFormatter(dayjs(), 'z')]
            const timenow = `${dayjs().format('HH:mm')} ${timezone}`

            const fullText = translator.t('message.citationMessage', {
                content: textValue,
                judul: bookTitle,
                tanggal: today,
                jam: timenow,
                penulis: author || '',
                penerbit: !isUndefined(
                    bookDetails?.catalog_info?.organization_name
                )
                    ? bookDetails?.catalog_info?.organization_name
                    : '',
                tahun: !isUndefined(bookDetails?.publish_date)
                    ? bookDetails?.publish_date
                    : '',
                halaman: val?.page,
            })
            navigator.clipboard.writeText(fullText)
            message.info('Teks berhasil dikutip.')
        } catch (err) {
            notificationHandler('graphql', err)
        }
    }

    return (
        <React.Fragment>
            {(isDrm && file) || (!isDrm && url_file) ? (
                <ReaderContainer>
                    <ReactReader
                        url={isDrm ? file : url_file}
                        title={bookTitle}
                        onBack={onBack}
                        author={author}
                        handleCitation={handleCitation}
                        loadingProps={isDrm ? !loaded : false}
                    />
                </ReaderContainer>
            ) : (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '10%',
                        right: '10%',
                        color: '#ccc',
                        textAlign: 'center',
                        margintop: '-.5em',
                    }}
                >
                    Loading…
                </div>
            )}
        </React.Fragment>
    )
}

export default BookReadEpub
