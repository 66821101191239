import React from 'react'
import { Modal, List, message } from 'antd'
import './ModalDataShare.scss'
import { LinkOutlined } from '@ant-design/icons'
import {
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from 'react-share'
import {
    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    WhatsappIcon,
} from 'react-share'
import { BASE_TITLE, PLAYSTORE_URL } from 'constants/constant'

const messageShare = `Dibagikan melalui aplikasi ${BASE_TITLE} (Perpustakaan Digital Sekolah) Download aplikasi di ${PLAYSTORE_URL}`

const ModalDataShare = ({ data = {}, visibleModal, setVisibleModal }) => {
    // eslint-disable-next-line array-callback-return

    const dataSocmed = [
        {
            avatar: <FacebookIcon size={32} round={true} />,
            title: (
                <div>
                    <FacebookShareButton
                        url={data.share_url}
                        quote={messageShare}
                    >
                        <div className="share-btn">Facebook</div>
                    </FacebookShareButton>
                </div>
            ),
        },
        {
            avatar: <TwitterIcon size={32} round={true} />,
            title: (
                <div>
                    <TwitterShareButton
                        url={data.share_url}
                        title={messageShare}
                    >
                        <div className="share-btn">Twitter</div>
                    </TwitterShareButton>
                </div>
            ),
        },
        {
            avatar: <TelegramIcon size={32} round={true} />,
            title: (
                <div>
                    <TelegramShareButton
                        url={data.share_url}
                        title={messageShare}
                    >
                        <div className="share-btn">Telegram</div>
                    </TelegramShareButton>
                </div>
            ),
        },
        {
            avatar: <WhatsappIcon size={32} round={true} />,
            title: (
                <div>
                    <WhatsappShareButton
                        url={data.share_url}
                        title={messageShare}
                        separator=":: "
                    >
                        <div className="share-btn">WhatsApp</div>
                    </WhatsappShareButton>
                </div>
            ),
        },
        {
            avatar: <LinkOutlined style={{ fontSize: 32 }} />,
            title: (
                <div
                    style={{ cursor: 'pointer' }}
                    type="link"
                    onClick={() => {
                        navigator.clipboard.writeText(data.id)
                        message.success('Tersalin')
                    }}
                >
                    <div className="share-btn">Salin tautan</div>
                </div>
            ),
        },
    ]

    return (
        <div>
            <Modal
                className="mvs-container"
                width={370}
                title={
                    <span style={{ textAlign: 'center' }}>Bagikan ke...</span>
                }
                visible={visibleModal}
                onOk={() => setVisibleModal(!visibleModal)}
                onCancel={() => setVisibleModal(!visibleModal)}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={dataSocmed}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={item.avatar}
                                title={item.title}
                            />
                        </List.Item>
                    )}
                />
            </Modal>
        </div>
    )
}

export default ModalDataShare
