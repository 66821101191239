import * as ActionType from './contant'

const initState = {
    filter: '000',
    limit: 12,
}

const initializeState = () => {
    const state = initState
    return state
}

const reducer = (state = initializeState(), action) => {
    const { type, value } = action

    switch (type) {
        case ActionType.SET_DATA:
            return { ...initState, ...value }
        case ActionType.CLEAR_DATA:
            return initState
        default:
            return state
    }
}

export default reducer
