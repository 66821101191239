import * as ActionType from './contant'
import ls from 'utils/secureLocalStorage'

const STORAGE = 'routes'
const initState = null

const initializeState = () => {
    const config = ls.get(STORAGE)
    const state = config ? config : initState
    return state
}

const reducer = (state = initializeState(), action) => {
    const { type, value } = action

    switch (type) {
        case ActionType.SET_ROUTE:
            ls.set(STORAGE, value)
            return value
        case ActionType.UPDATE_ROUTE:
            ls.set(STORAGE, value)
            return value
        case ActionType.CLEAR_ROUTE:
            ls.set(STORAGE, null)
            return null
        default:
            return state
    }
}

export default reducer
