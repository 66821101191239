import React, { useState, useLayoutEffect } from 'react'
import './Post.scss'
import { ic_comment } from '../../../assets/icons'
import { Avatar as AvtImage, Image, Tooltip } from 'antd'
import get from 'lodash/get'
import { CommentOutlined, LikeOutlined } from '@ant-design/icons'
import { error } from 'utils/notification'
import provider from 'providers/provider'
import moment from 'moment'
import translator from 'i18next'
import url from 'constants/url'
import { useHistory } from 'react-router'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import action from 'redux/timeLine/action'
import actionFeed from 'redux/feed/action'
import actionEpustaka from 'redux/epustakaDetail/action'
import Text from 'antd/lib/typography/Text'
import getAcronym from 'components/Acronym'
import { BASE_TITLE, COLORS } from 'constants/constant'

const ActivityPost = ({
    loading,
    avatar_url,
    total_like,
    total_comment,
    imagePost,
    username,
    timestamp,
    message,
    description,
    feed_type,
    has_like,
    has_comment,
    id,
    setTimeLine,
    stateTimeLine,
    fromDetails = false,
    userId,
    sender_type,
    senderId,
    fromEpustakaDetail,
    dataEpustaka,
    timelineDetail,
}) => {
    const history = useHistory()
    const dateSbt = moment(timestamp)

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isSmallMobile = () => state.width <= 360
    const isMobile = () => state.width <= 420

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const { color, acronym } = getAcronym(username, id)

    const submitData = async (url, data) => await provider.insert(url, data)

    const handleSave = async () => {
        await submitData(url.webhookURL_like, {
            object_type: 'FEED',
            object_id: id,
            sender_type: 'USER',
            sender_id: userId,
        })
            .then(async () => {
                !fromDetails
                    ? setTimeLine({ refreshLike: true })
                    : actionFeed.setDataFeed({
                        id,
                        has_like: true,
                        feed_type,
                        avatar_url,
                        username,
                        timestamp,
                        message,
                        total_comment,
                        total_like: total_like + 1,
                        imagePost,
                        description,
                        setTimeLine,
                        stateTimeLine,
                        sender_type,
                        userId,
                    })
            })
            .catch((err) => {
                Promise.reject(err)
            })
    }
    const onLike = async () => {
        await handleSave()
    }

    const onDislike = async () => {
        await provider
            .deleteData(url.webhookURL_like, {
                object_type: 'FEED',
                object_id: id,
                sender_type: 'USER',
                sender_id: userId,
            })
            .then(() => {
                !fromDetails
                    ? setTimeLine({ refreshLike: true })
                    : actionFeed.setDataFeed({
                        id,
                        has_like: false,
                        has_comment,
                        feed_type,
                        avatar_url,
                        username,
                        timestamp,
                        message,
                        total_comment,
                        total_like: total_like - 1,
                        imagePost,
                        description,
                        setTimeLine,
                        stateTimeLine,
                        sender_type,
                        userId,
                    })
            })
            .catch((err) =>
                error({ description: err.message, placement: 'bottomRight' })
            )
    }

    return (
        <>
            {loading ? (
                'Loading...'
            ) : (
                <div className="post" key={id}>
                    <div className="post-top">
                        <AvtImage
                            style={{
                                backgroundImage: color,
                                cursor: sender_type !== 'SCHOOL' && 'pointer',
                                minWidth: 40,
                                maxWidth: 40,
                            }}
                            size="large"
                            src={avatar_url}
                            className="post-avatar"
                            onClick={() => {
                                if (sender_type === 'EPUSTAKA') {
                                    history.push(`/epustaka/${senderId}`)
                                } else if (fromEpustakaDetail) {
                                    history.push(`/epustaka/${senderId}`)
                                } else if (sender_type !== 'SCHOOL') {
                                    history.push(`/profil/user/${senderId}`)
                                }
                            }}
                        >
                            <span className="avatar-name">{acronym}</span>
                        </AvtImage>

                        <div className="post-info">
                            <b
                                onClick={() => {
                                    if (sender_type === 'EPUSTAKA') {
                                        history.push(`/epustaka/${senderId}`)
                                    } else if (fromEpustakaDetail) {
                                        history.push(`/epustaka/${senderId}`)
                                    } else if (sender_type !== 'SCHOOL') {
                                        history.push(`/profil/user/${senderId}`)
                                    }
                                }}
                            >
                                <Text
                                    style={{
                                        width: isSmallMobile()
                                            ? 130
                                            : isMobile()
                                                ? 190
                                                : '100%',
                                    }}
                                    ellipsis={true}
                                >
                                    <Tooltip
                                        title={username}
                                        placement="topLeft"
                                        color="#404040"
                                    >
                                        {username}
                                    </Tooltip>
                                </Text>
                            </b>{' '}
                            <span
                                className="post-info-message"
                                style={{ fontSize: isMobile() && 13 }}
                            >
                                {message}
                            </span>
                            <p className="post-info-time">
                                {timestamp && dateSbt.format('LLLL')}
                            </p>
                        </div>
                    </div>
                    <div className="post-bottom">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                        />
                    </div>
                    {/* For posting image */}
                    <div className="post-image">
                        <center>
                            {imagePost && (
                                <Image
                                    className="post-image-src"
                                    src={imagePost}
                                    alt="banner"
                                />
                            )}
                        </center>
                    </div>
                    <div className="post-like-commet">
                        <div
                            className="like"
                            style={{
                                display: timelineDetail
                                    ? 'block'
                                    : fromEpustakaDetail && 'none',
                            }}
                        >
                            {total_like + ' ' + translator.t('feed.totalLike')}
                        </div>

                        <div
                            className="comment"
                            style={{ display: timelineDetail && 'none' }}
                        >
                            {fromEpustakaDetail ? (
                                <div
                                    style={{ display: 'flex' }}
                                    onClick={() => {
                                        history.push(`/timeline/detail/${id}`)
                                        actionFeed.setDataFeed({
                                            id,
                                            has_like,
                                            has_comment,
                                            feed_type,
                                            avatar_url,
                                            username,
                                            timestamp,
                                            message,
                                            total_comment,
                                            total_like,
                                            imagePost,
                                            description,
                                            sender_type,
                                            userId,
                                            fromEpustakaDetail,
                                        })

                                        actionEpustaka.setDataEpustaka({
                                            dataEpustaka,
                                        })
                                    }}
                                >
                                    <span
                                        style={{
                                            marginRight: 5,
                                        }}
                                    >
                                        <div className="card-title">
                                            <Tooltip
                                                title={translator.t(
                                                    'feed.totalComment'
                                                )}
                                            >
                                                {isMobile() ? (
                                                    <CommentOutlined
                                                        style={{ fontSize: 20 }}
                                                    />
                                                ) : (
                                                    <img
                                                        style={{ width: 21 }}
                                                        src={ic_comment}
                                                        alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                                    />
                                                )}
                                            </Tooltip>
                                        </div>
                                    </span>
                                    <div>
                                        ·{' '}
                                        {total_comment +
                                            ' ' +
                                            translator.t('feed.totalComment')}
                                    </div>
                                </div>
                            ) : fromDetails ? (
                                ''
                            ) : (
                                total_comment +
                                ' ' +
                                translator.t('feed.totalComment')
                            )}
                        </div>
                    </div>

                    <div
                        className="post-options"
                        style={{ display: fromEpustakaDetail && 'none' }}
                    >
                        <div className="post-option">
                            <div
                                onClick={() => {
                                    if (has_like === false) {
                                        onLike()
                                    }
                                    if (has_like === true) {
                                        onDislike()
                                    }
                                }}
                                className="card-title"
                            >
                                {has_like === false ? (
                                    <>
                                        <LikeOutlined
                                            style={{
                                                color: 'grey',
                                                fontSize: 17,
                                            }}
                                        />
                                        <p>{translator.t('feed.like')}</p>
                                    </>
                                ) : (
                                    <>
                                        <LikeOutlined
                                            style={{
                                                color: COLORS.primaryColor,
                                            }}
                                        />
                                        <p
                                            style={{
                                                color: COLORS.primaryColor,
                                            }}
                                        >
                                            {translator.t('feed.like')}
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="post-option">
                            <div
                                onClick={() => {
                                    history.push(`/timeline/detail/${id}`)
                                    actionFeed.setDataFeed({
                                        id,
                                        has_like,
                                        has_comment,
                                        feed_type,
                                        avatar_url,
                                        username,
                                        timestamp,
                                        message,
                                        total_comment,
                                        total_like,
                                        imagePost,
                                        description,
                                        sender_type,
                                        userId,
                                    })

                                    actionEpustaka.setDataEpustaka({
                                        dataEpustaka,
                                    })
                                }}
                                className="card-title"
                            >
                                <img
                                    src={ic_comment}
                                    alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                />
                                <p>{translator.t('feed.comment')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    stateTimeLine: get(state, 'timeLine', {}),
})

const mapDispatchToProps = () => ({
    setTimeLine: action.setTimeLine,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ActivityPost))
