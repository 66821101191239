import React from 'react'
import { Modal } from 'antd'
import translator from 'i18next'
import { BASE_TITLE } from 'constants/constant'
import { FallbackImage } from 'components'
import { isEmpty } from 'lodash'

const ModalInformationBook = ({ visible, setVisible, epustakaBorrow }) => {
    return (
        <Modal
            title={translator.t('title.reNotif')}
            visible={!isEmpty(epustakaBorrow) && visible}
            onOk={() => setVisible(!visible)}
            onCancel={() => setVisible(!visible)}
            className="mj__epustaka"
            footer={null}
        >
            <FallbackImage
                src={epustakaBorrow?.epustaka_logo}
                type="square"
                alt={`${BASE_TITLE} Epustaka - Platform Perpustakaan Indonesia`}
                className="mj__epustakaImg"
            />

            <div className="mj__epustakaName">
                {epustakaBorrow?.epustaka_name}
            </div>
            <div style={{ marginTop: '2.5em' }}>
                {translator.t('title.reTitle', {
                    ePustaka: epustakaBorrow?.epustaka_name,
                })}
            </div>
        </Modal>
    )
}

export default ModalInformationBook
