import {
    Dropdown,
    Card,
    Col,
    Row,
    Rate,
    Space,
    Button,
    Divider,
    Tabs,
    Empty,
    Menu,
    Skeleton,
    message,
    Tooltip,
    Modal,
} from 'antd'
import React, { useEffect, useState, useLayoutEffect } from 'react'
import { FallbackImage, ModalNoAccess, ModalShare } from 'components'
import BookSliderSection from '../components/BookSliderSection'
import CommentList from '../components/CommentList'
import { connect } from 'react-redux'
import action from 'redux/timeLine/action'
import actionRoute from 'redux/routeHistory/action'
import actionRead from 'redux/readProgress/action'
import actionReadingDuration from 'redux/readingDuration/action'
import actionLastReadingDuration from 'redux/lastReadingDuration/action'
import actionBookmark from 'redux/bookmark/action'
import actionFromReader from 'redux/fromReader/action'
import { withRouter } from 'react-router-dom'
import concat from 'lodash/concat'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import capitalize from 'lodash/capitalize'
import toNumber from 'lodash/toNumber'
import replace from 'lodash/replace'
import provider from 'providers/provider'
import url from 'constants/url'
import translator from 'i18next'
import ReadMore from 'utils/ReadMore'
import reduxState from '../../../../redux'
import ModalDownloadBook from './ModalDownloadBook'
import ModalQueueList from './ModalQueueList'
import ModalCollectionOwner from './ModalCollectionOwner'
import ModalBorrowerList from './ModalBorrowerList'
import ModalBookDonation from 'views/donation/components/ModalBookDonation'
import { useHistory } from 'react-router'
import getPagination from 'constants/getPagination'
import ModalRecommendation from './ModalRecommendation'
import ModalReview from './ModalReview'
import { Helmet } from 'react-helmet'
import {
    ic_waiting_list,
    ic_reader_list,
    ic_open_free,
    ic_open_free_skeleton,
    ic_drm,
    ic_borrow_hour,
} from 'assets/icons'
import {
    LoadingOutlined,
    MoreOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons'
import Paragraph from 'antd/lib/typography/Paragraph'
import { BASE_TITLE, COLORS } from 'constants/constant'
import { error } from 'utils/notification'
import { useLocation } from 'react-router-dom'
import ModalInformationBook from '../components/ModalInformationBook'
import omit from 'lodash/omit'
import { v4 as uuidv4 } from 'uuid'
import ls from 'utils/secureLocalStorage'
import Encryption from 'utils/encryption'
import dayjs from 'dayjs'
import * as configModule from 'constants/constant'
import isNil from 'lodash/isNil'

const configApp = { ...configModule }

const { TabPane } = Tabs
const { confirm } = Modal
const BookDetail = (props) => {
    const epustakaIdFromProps = props?.match?.params?.epustaka

    const { setTimeLine } = props

    const location = useLocation()
    const bookEpustaka = location?.state?.bookEpustaka
    const useDonation =
        configApp?.appMenu?.findIndex((e) => e.id === 'donation') > -1
    const history = useHistory()
    const pageCurrent =
        toNumber(replace(history?.location?.search, '?p=', '')) || 0
    const { profile = {} } = reduxState.getState()
    const user_id = profile?.id
    const organization_id = get(profile, 'organizations.id', '')
    const bookId = get(props.match.params, 'uuid', '')
    const getSpecificPage = props?.match?.params?.d
    const [epustakaId, setEpustakaId] = useState(epustakaIdFromProps)
    const [loading, setLoading] = useState(true)
    const [bookDetailData, setBookDetailData] = useState({})
    const [bookReviewData, setBookReviewData] = useState({})
    const [metaData, setMetaData] = useState({})
    const [epustakaBorrow, setEpustakaBorrow] = useState({})
    const [userFollower, setUserFollower] = useState({})
    const [bookQueue, setBookQueue] = useState({})
    const [visibleActiveReading, setVisibleActiveReading] = useState(false)
    const [loadingReading, setLoadingReading] = useState(false)
    const [visibleAccess, setVisibleAccess] = useState(false)

    const [checkBorrowStatus, setCheckBorrowStatus] = useState({
        status: false,
        data: {},
    })

    const [dataCollectionOwner, setDataCollectionOwner] = useState({
        loading: false,
        data: [],
        total: 0,
    })

    const [currentPageQueue, setCurrentPageQueue] = useState(1)
    const [paginationQueue, setPaginationQueue] = useState({
        loading: true,
        pagination: true,
    })
    const [currentPageFollower, setCurrentPageFollower] = useState(1)
    const [paginationFollower, setPaginationFollower] = useState({
        loading: true,
        pagination: true,
    })
    const [limitCollection, setLimitCollection] = useState(25)
    const [visibleInformation, setVisibleInformation] = useState(false)
    const [visibleBookDonation, setVisibleBookDonation] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModalBorrower, setVisibleModalBorrower] = useState(false)
    const [visibleModalQueue, setVisibleModalQueue] = useState(false)
    const [visibleModalCollection, setVisibleModalCollection] = useState(false)
    const [visibleModalReview, setVisibleModalReview] = useState(false)
    const [visibleModalRecommendation, setVisibleModalRecommendation] =
        useState(false)
    const [dataFollowerChanged, setDataFollowerChanged] = useState(true)

    const [dataQueueChanged, setDataQueueChanged] = useState(true)
    const [loadingQueue, setLoadingQueue] = useState(false)
    const [querySearchFollower, setQuerySearchFollower] = useState()
    const [fromAvailableCopy, setAvailableCopy] = useState(false)
    const [onReadBy, setOnReadBy] = useState(false)
    const [count, setCount] = useState(0)

    const [isChangeReview, setIsChangeReview] = useState('')
    const dataComment = filter(
        bookReviewData,
        (e) => !e.has_deleted && !e.has_report
    )
    const [isModalVisibleShare, setIsModalVisibleShare] = useState(false)

    const hasReview = metaData.has_rate === true

    const file_ext = get(bookDetailData, 'file_ext', '')
    const isDrm = bookDetailData?.using_drm

    const bookOutOfStock =
        bookDetailData?.available_qty === 0 &&
        bookDetailData?.all_qty > 0 &&
        checkBorrowStatus.status === false

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const storageReadingProgress = ls.get(
        Encryption.encryptWithMD5('readProgress')
    )
    const storageBookmark = ls.get(Encryption.encryptWithMD5('bookmark'))
    const storageReadingDuration = ls.get(
        Encryption.encryptWithMD5('readingDuration')
    )

    const isMobile = () => state.width <= 360
    const isMiddle = () => state.width <= 485

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const [loadingSkeleton, setLoadingSkeleton] = useState(true)

    const getBookDetail = async () => {
        setLoading(true)
        await provider
            .singleWebHook(
                isEmpty(epustakaId)
                    ? `${url.webhookURL_book_detail}?book_id=${bookId}`
                    : `${url.webhookURL_book_detail}?book_id=${bookId}&epustaka_id=${epustakaId}`
            )
            .then(async ({ data }) => {
                setBookDetailData(data)
                await provider
                    .singleWebHook(
                        `${url.webhookURL_check_borrow_status}?book_id=${bookId}`
                    )
                    .then(async ({ data }) => {
                        setTimeLine({ refresh: false })

                        setCheckBorrowStatus({
                            status: data?.has_borrow,
                            data,
                        })
                        if (
                            data?.has_borrow &&
                            data?.epustaka?.id !== epustakaIdFromProps &&
                            count === 0
                        ) {
                            // Hide re Notification
                            // setVisibleInformation(true)
                            setCount(count + 1)
                        }

                        if (isEmpty(epustakaIdFromProps)) {
                            await provider
                                .singleWebHook(
                                    `${url.webhookURL_epustaka_borrow}?book_id=${bookId}&organization_id=${organization_id}`
                                )
                                .then(({ data }) => {
                                    setEpustakaBorrow(data)
                                })
                                .catch((err) => {
                                    if (
                                        err.message ===
                                        'Request failed with status code 404'
                                    ) {
                                        setVisibleAccess(true)
                                    }
                                    setEpustakaBorrow(bookEpustaka)
                                })
                            setEpustakaId(data?.epustaka?.id)
                        } else {
                            setEpustakaBorrow(bookEpustaka || bookDetailData)
                        }
                        setLoading(false)
                        setLoadingSkeleton(false)
                    })
            })
            .catch(() => {
                message.warning(translator.t('notification.bookNotFound'))
                history.replace('/')
            })
    }

    const handleClickRead = async (has_borrow) => {
        if (!has_borrow) {
            setVisibleModal(true)
            setCount(count + 1)
        } else {
            setLoadingReading(true)
            if (
                !isEmpty(props?.stateReadingDuration) ||
                !isEmpty(props?.stateBookmarkData) ||
                !isEmpty(props?.stateReadingProgress)
            ) {
                const lastBookId = props?.stateReadingProgress?.book_id
                try {
                    if (!isEmpty(props?.stateReadingDuration)) {
                        await handleSaveReadingDuration(
                            props?.stateReadingDuration
                        )
                    }
                    if (!isEmpty(props?.stateBookmarkData)) {
                        await handleSaveBookmark(props?.stateBookmarkData)
                    }
                    if (!isEmpty(props?.stateReadingProgress)) {
                        await handleSaveReadingProgress(
                            props?.stateReadingProgress
                        )
                    }
                    setLoadingReading(false)
                    showConfirm(lastBookId)
                } catch (err) {
                    setLoadingReading(false)
                    error({
                        description: err.message,
                        placement: 'bottomRight',
                    })
                }
            } else {
                if (
                    !isEmpty(storageReadingProgress) ||
                    !isEmpty(storageBookmark) ||
                    !isEmpty(storageReadingDuration)
                ) {
                    const lastTitleBook = JSON.parse(
                        storageReadingProgress
                    )?.book_id
                    try {
                        if (
                            !isEmpty(storageReadingDuration) &&
                            !isUndefined(storageReadingDuration)
                        ) {
                            await handleSaveReadingDuration(
                                JSON.parse(storageReadingDuration)
                            )
                        }
                        if (
                            !isEmpty(storageBookmark) &&
                            !isUndefined(storageBookmark)
                        ) {
                            await handleSaveBookmark(
                                JSON.parse(storageBookmark)
                            )
                        }
                        if (
                            !isEmpty(storageReadingProgress) &&
                            !isUndefined(storageReadingProgress)
                        ) {
                            await handleSaveReadingProgress(
                                JSON.parse(storageReadingProgress)
                            )
                        }
                        setLoadingReading(false)
                        showConfirm(lastTitleBook)
                    } catch (err) {
                        setLoadingReading(false)
                        error({
                            description: err.message,
                            placement: 'bottomRight',
                        })
                    }
                } else {
                    redirectReadBook()
                }
            }
            setLoadingReading(false)
        }

        if (isEmpty(epustakaBorrow) && isEmpty(bookDetailData)) {
            message.warning(translator.t('message.book_not_found'))
            setVisibleModal(false)
        }
    }

    const redirectReadBook = async () => {
        const readingId = uuidv4()
        const { data: bookmarkList } = await provider.list(
            url.webhookURL_bookmark_list + `?limit=9999&book_id=${bookId}`
        )
        const dataBookmark = bookmarkList?.map((e) => ({
            ...e,
            book_id: bookId,
            user_id: user_id,
            bookmark_label: e?.label,
            label: undefined,
        }))
        const { data: bookReadingProgressList } = await provider.singleWebHook(
            url.webhookURL_book_reading_progress_list + `?book_id=${bookId}`
        )
        const { data: bookReadingDurationList } = await provider.list(
            url.webhookURL_book_reading_duration_list +
                `?book_id=${bookId}&limit=9999`
        )

        const specificPage = !isUndefined(getSpecificPage)
            ? getSpecificPage
            : !isUndefined(bookReadingProgressList?.last_page)
            ? bookReadingProgressList?.last_page
            : 1

        const isFromWeb = bookReadingProgressList?.platform_info === 'web'
        const epub_read_locator = bookReadingProgressList?.epub_read_locator

        const isLocatorExist =
            !isNil(epub_read_locator) || !isUndefined(epub_read_locator)

        const locator = isLocatorExist ? JSON.parse(epub_read_locator) : null

        if (file_ext === 'epub') {
            isLocatorExist && isFromWeb
                ? localStorage.setItem('book-progress', locator.locations.cfi)
                : localStorage.removeItem('book-progress')
        }

        file_ext === 'epub'
            ? history.push({
                  pathname: '/read-book-epub',
                  state: {
                      bookTitle: bookDetailData?.book_title,
                      bookDetails: bookDetailData,
                      isDrm: bookDetailData?.using_drm,
                      bookId,
                      progress: specificPage,
                      author: bookDetailData?.book_author,
                      ...checkBorrowStatus.data,
                      epustakaId:
                          epustakaId ||
                          checkBorrowStatus?.data?.epustaka?.id ||
                          checkBorrowStatus?.data?.epustaka_id,
                      file_ext,
                  },
              })
            : history.push({
                  pathname: '/read-book',
                  state: {
                      bookData: {
                          pageCurrent,
                          ...checkBorrowStatus.data,
                          userId: user_id,
                          bookId,
                          epustakaId:
                              epustakaId ||
                              checkBorrowStatus?.data?.epustaka?.id ||
                              checkBorrowStatus?.data?.epustaka_id,
                          bookTitle: bookDetailData?.book_title || '',
                          isDrm: bookDetailData?.using_drm,
                          bookDetails: bookDetailData,
                          lastpage: specificPage,
                          dataBookmark: dataBookmark,
                          dataReadingDuration: bookReadingDurationList,
                          readingId: readingId,
                      },
                  },
              })
    }

    const showConfirm = (lastBookId) => {
        confirm({
            title: translator.t('title.activeReading'),
            icon: <ExclamationCircleOutlined />,
            content: translator.t('title.lastBookRead', {
                title: props?.stateReadingProgress?.book_title,
            }),
            onOk() {
                setEpustakaId(undefined)
                history.push(`/book/${lastBookId}`)
                setVisibleActiveReading(false)
            },
            okText: translator.t('title.continueRead'),
            cancelText: translator.t('boolean.false'),
            onCancel() {
                redirectReadBook()
                setVisibleActiveReading(false)
            },
            visible: visibleActiveReading,
        })
    }

    const handleBookQueue = async () => {
        setLoadingQueue(true)
        const data = {
            book_id: bookId,
            epustaka_id: epustakaId || epustakaBorrow?.id,
        }
        await provider
            .insert(url.webhookURL_book_queue, data)
            .then((res) => {
                message.info(res?.data?.message)
                setLoadingQueue(false)
                getDataQueue()

                res?.data?.message !== 'Anda sudah mengantri buku ini' &&
                    getBookDetail()
            })
            .catch((err) => {
                setLoadingQueue(false)
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    const getDataFollower = async () => {
        const limit = 10
        const pagination = getPagination(currentPageFollower, limit, 'webhook')
        const filterQuery = isEmpty(querySearchFollower)
            ? ''
            : {
                  'users.name': { ilike: `%${querySearchFollower}%` },
              }
        await provider
            .list(
                encodeURI(
                    `${url.webhookURL_follower}${
                        pagination.result
                    }&filter=${JSON.stringify(filterQuery)}&q=&user_id=${
                        profile.id
                    }`
                )
            )
            .then((res) => {
                const { data, meta } = res
                const filterData = filter(data, (e) => e.id !== profile.id)
                if (dataFollowerChanged) {
                    setUserFollower(filterData)
                } else {
                    setUserFollower(
                        isEmpty(userFollower)
                            ? filterData
                            : concat(userFollower, filterData)
                    )
                }

                setPaginationFollower({
                    ...paginationFollower,
                    loading: false,
                })
                if (pagination.offset >= meta.total || meta.total <= limit) {
                    setPaginationFollower({
                        loading: false,
                        pagination: false,
                    })
                }
            })
    }

    const getDataQueue = async () => {
        const limit = 10
        const pagination = getPagination(currentPageQueue, limit, 'webhook')

        await provider
            .list(
                `${url.webhookURL_book_queue}${pagination.result}&q=&book_id=${bookId}`
            )
            .then((res) => {
                const { data, meta } = res
                if (dataQueueChanged) {
                    setBookQueue(data)
                } else {
                    setBookQueue(
                        isEmpty(bookQueue) ? data : concat(bookQueue, data)
                    )
                }
                setPaginationQueue({
                    ...paginationQueue,
                    loading: false,
                })
                if (pagination.offset >= meta.total || meta.total <= limit) {
                    setPaginationQueue({
                        loading: false,
                        pagination: false,
                    })
                }
            })
    }

    const getCollectionOwner = async () => {
        setDataCollectionOwner({ ...dataCollectionOwner, loading: true })
        await provider
            .list(
                `${url.webhookURL_collection_owner}?book_id=${bookId}&limit=${limitCollection}`
            )
            .then((res) => {
                const { data, meta } = res

                setDataCollectionOwner({
                    data: fromAvailableCopy
                        ? filter(data, (e) => e?.available_qty > 0)
                        : data,

                    loading: false,
                    total: meta?.total,
                })
            })
            .catch((err) => {
                setDataCollectionOwner({
                    ...dataCollectionOwner,
                    loading: false,
                })
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    const getReviewData = async () => {
        await provider
            .list(`${url.webhookURL_book_rating}?book_id=${bookId}&limit=99999`)
            .then(async (res) => {
                const { data: dataReview, meta: meta } = res
                setBookReviewData(dataReview)
                setMetaData(meta)
                setTimeLine({ refresh: false, refreshLike: false })
            })
    }

    useEffect(() => {
        getReviewData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChangeReview])

    useEffect(() => {
        getCollectionOwner()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookId, limitCollection])

    useEffect(() => {
        getBookDetail()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookId, checkBorrowStatus.status])

    useEffect(() => {
        getDataFollower()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [querySearchFollower])

    useEffect(() => {
        getDataQueue()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPageQueue, dataQueueChanged, bookId, checkBorrowStatus.status])

    useEffect(() => {
        setLoadingSkeleton(true)
        window.scrollTo({
            top: 0,
            behavior: 'auto',
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookId])

    useEffect(() => {
        if (props.stateFromReader) {
            if (!isEmpty(props?.stateReadingDuration)) {
                handleSaveReadingDuration(props?.stateReadingDuration)
            }
            if (!isEmpty(props?.stateBookmarkData)) {
                handleSaveBookmark(props?.stateBookmarkData)
            }
            if (!isEmpty(props?.stateReadingProgress)) {
                handleSaveReadingProgress(props?.stateReadingProgress)
            }
            props.clearFromReader()
        }
    }, [])

    // useEffect(() => {
    //     if (
    //         checkBorrowStatus?.data?.has_borrow &&
    //         checkBorrowStatus?.data?.epustaka?.id !== epustakaIdFromProps &&
    //         !isEmpty(epustakaBorrow)
    //     ) {
    //         setVisibleInformation(true)
    //     } else {
    //         setVisibleInformation(false)
    //     }
    // }, [
    //     checkBorrowStatus?.data?.has_borrow,
    //     checkBorrowStatus?.data?.epustaka?.id,
    //     epustakaIdFromProps,
    //     epustakaBorrow,
    // ])

    const onLoadMoreFollower = async () => {
        setPaginationFollower({
            ...paginationFollower,
            loading: true,
        })
        setCurrentPageFollower(currentPageFollower + 1)
    }

    const onLoadMoreQueue = async () => {
        setPaginationQueue({
            ...paginationQueue,
            loading: true,
        })
        setCurrentPageQueue(currentPageQueue + 1)
    }

    const DrmImage = () => {
        if (loading) {
            return (
                <img
                    style={{ width: '2.2rem' }}
                    src={ic_open_free_skeleton}
                    alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                />
            )
        } else {
            return (
                <img
                    style={{ width: '2.2rem' }}
                    src={
                        get(bookDetailData, 'using_drm', true) === false
                            ? ic_open_free
                            : ic_drm
                    }
                    alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                />
            )
        }
    }

    const MenuComponent = (handleModal) => {
        const handleClickRecommendation = ({ key }) => {
            if (key === '1') {
                const timer = setTimeout(() => handleModal(true), 200)
                return () => clearTimeout(timer)
            } else {
                setIsModalVisibleShare(true)
            }
        }
        return (
            <Menu className="share-menu" onClick={handleClickRecommendation}>
                <Menu.ItemGroup>
                    <Menu.Item key="1">
                        {translator.t('action.recommendation')}
                    </Menu.Item>
                    <Menu.Item key="2">{translator.t('title.share')}</Menu.Item>
                </Menu.ItemGroup>
            </Menu>
        )
    }

    const buttonBorrow = () => {
        if (isDrm) {
            if (
                (epustakaBorrow?.total_qty &&
                    epustakaBorrow?.total_qty !== 0) ||
                (!isEmpty(epustakaIdFromProps) &&
                    bookDetailData?.all_qty !== 0) ||
                (checkBorrowStatus?.data?.has_borrow &&
                    checkBorrowStatus?.data?.epustaka?.id !==
                        epustakaIdFromProps)
            ) {
                return (
                    <Col
                        style={{
                            display: isMiddle() && 'none',
                        }}
                        className="mt-md"
                    >
                        <Button
                            className={
                                checkBorrowStatus.status
                                    ? 'button-read'
                                    : bookOutOfStock
                                    ? 'button-queue'
                                    : 'button-borrow'
                            }
                            onClick={() => {
                                if (bookOutOfStock) {
                                    handleBookQueue()
                                } else {
                                    handleClickRead(checkBorrowStatus.status)
                                }
                            }}
                            disabled={
                                bookDetailData?.using_drm &&
                                get(bookDetailData, 'all_qty', 0) <= 0
                            }
                            loading={loadingQueue || loadingReading}
                        >
                            {translator.t(
                                checkBorrowStatus.status === true
                                    ? 'action.read'
                                    : (checkBorrowStatus.status === false &&
                                          epustakaBorrow?.available_qty > 0) ||
                                      bookDetailData?.available_qty > 0
                                    ? 'action.borrow'
                                    : bookOutOfStock
                                    ? 'action.queue_borrow'
                                    : 'action.read'
                            )}
                        </Button>
                    </Col>
                )
            }
        } else {
            return (
                <Col
                    style={{
                        display: isMiddle() && 'none',
                    }}
                    className="mt-md"
                >
                    <Button
                        className={
                            checkBorrowStatus.status
                                ? 'button-read'
                                : 'button-borrow'
                        }
                        onClick={() => {
                            handleClickRead(checkBorrowStatus.status)
                        }}
                        disabled={
                            bookDetailData?.using_drm &&
                            get(bookDetailData, 'all_qty', 0) <= 0
                        }
                        loading={loadingQueue || loadingReading}
                    >
                        {translator.t(
                            checkBorrowStatus.status === true
                                ? 'action.read'
                                : 'action.borrow'
                        )}
                    </Button>
                </Col>
            )
        }
    }

    const handleSaveReadingDuration = async (data) => {
        const targetPage =
            props?.stateReadingProgress?.last_page ||
            JSON.parse(storageReadingProgress)?.last_page
        const bookid_lastReading = data[0]?.book_id
        const currentTime = dayjs()
        const tempData = data
        const currentItem = data?.find((e) => e?.page === targetPage) || {}
        const indexCurrent = data?.findIndex((e) => e?.page === targetPage)

        if (
            !isEmpty(props?.stateLastReadingDuration) &&
            props?.stateLastReadingDuration?.init
        ) {
            const duration = currentTime.diff(
                props?.stateLastReadingDuration?.lastTime,
                's'
            )
            if (currentItem['duration']) {
                currentItem['duration'] = currentItem['duration'] + duration
                currentItem['page'] = targetPage
                currentItem['user_id'] = user_id
                currentItem['book_id'] = bookid_lastReading
            } else {
                if (duration >= 5) {
                    currentItem['duration'] = duration
                    currentItem['page'] = targetPage
                    currentItem['user_id'] = user_id
                    currentItem['book_id'] = bookid_lastReading
                }
            }

            if (indexCurrent !== -1) {
                tempData.splice(indexCurrent, 1)
            }
        }
        try {
            const page_duration = props.stateFromReader
                ? !isEmpty(currentItem)
                    ? [...tempData, { ...currentItem }]
                    : data
                : data
            await provider.insert(url.webhookURL_book_reading_durations, {
                book_id: bookid_lastReading,
                page_duration: page_duration?.map((e) => ({
                    ...e,
                    book_id: undefined,
                    user_id: undefined,
                })),
            })
            props.clearReadingDuration()
            props.clearLastReadingDuration()
        } catch (err) {
            error({
                description: err.message,
                placement: 'bottomRight',
            })
        }
    }

    const handleSaveBookmark = async (dataBookmarks) => {
        const data = dataBookmarks?.map((e) => omit(e, ['id', '__typename']))
        try {
            await provider.insert(url.webhookURL_sync_bookmark, { data })
            props.clearBookmarkData()
        } catch (err) {
            error({
                description: err.message,
                placement: 'bottomRight',
            })
        }
    }

    const handleSaveReadingProgress = async (data) => {
        const dataSave = omit(data, ['reading_id', 'book_title'])
        try {
            await provider.insert(
                url.webhookURL_book_reading_progress,
                dataSave
            )
            props.clearReadingProgress()
        } catch (err) {
            error({
                description: err.message,
                placement: 'bottomRight',
            })
        }
    }

    return (
        // detail book container
        <div className="db-container">
            <Helmet>
                <title>
                    {capitalize(get(bookDetailData, 'book_title', ''))} -{' '}
                    {BASE_TITLE}
                    Website
                </title>
                <meta
                    name="og:title"
                    content={`${BASE_TITLE} Website - Platform Perpustakaan Indonesia`}
                />
                <meta
                    name="description"
                    content="Book Page - Mari bergabung dan berkolaborasi mengembangkan pendidikan Indonesia bersama anak bangsa."
                />
                <meta
                    property="image"
                    content={get(bookDetailData, 'cover_url', '')}
                />
            </Helmet>
            <Card
                className="detail-card"
                title={translator.t('title.book_detail')}
                extra={
                    <Dropdown
                        overlay={() =>
                            MenuComponent(setVisibleModalRecommendation)
                        }
                        trigger={['click']}
                        style={{ cursor: 'pointer' }}
                        placement="bottomRight"
                        getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                        }
                    >
                        <MoreOutlined
                            rotate={90}
                            style={{ color: 'black', fontSize: 20 }}
                        />
                    </Dropdown>
                }
            >
                <div style={{ display: 'flex' }}>
                    <div className="cover-image">
                        {!loadingSkeleton ? (
                            <FallbackImage
                                src={get(bookDetailData, 'cover_url', '')}
                                className="cover-book"
                            />
                        ) : (
                            <Skeleton.Input
                                loading={loadingSkeleton}
                                active
                                style={{
                                    height: '220px',
                                    width: '150px',
                                    borderRadius: '15px',
                                }}
                                size="default"
                            />
                        )}
                    </div>

                    <div className="description-book">
                        <Skeleton loading={loadingSkeleton} active>
                            <h2 className="book-title">
                                <Paragraph
                                    style={{ marginBottom: 0 }}
                                    ellipsis={
                                        true
                                            ? {
                                                  rows: isMobile()
                                                      ? 2
                                                      : isMiddle()
                                                      ? 3
                                                      : 2,
                                              }
                                            : false
                                    }
                                >
                                    <Tooltip
                                        title={get(
                                            bookDetailData,
                                            'book_title',
                                            ''
                                        )}
                                    >
                                        {get(bookDetailData, 'book_title', '')}
                                    </Tooltip>
                                </Paragraph>
                            </h2>
                            <Tooltip
                                placement="topLeft"
                                title={get(
                                    bookDetailData,
                                    'book_author',
                                    'Voluptas atque iusto distinctio odit'
                                )}
                            >
                                <Paragraph
                                    className="book-author"
                                    ellipsis={
                                        true
                                            ? {
                                                  rows: 2,
                                              }
                                            : false
                                    }
                                >
                                    {get(
                                        bookDetailData,
                                        'book_author',
                                        'Voluptas atque iusto distinctio odit'
                                    )}
                                </Paragraph>
                            </Tooltip>
                            <div>
                                <Rate
                                    className="rating-book"
                                    disabled
                                    defaultValue={get(
                                        bookDetailData,
                                        'avg_rating',
                                        0
                                    )}
                                    value={get(bookDetailData, 'avg_rating', 0)}
                                    allowHalf
                                />{' '}
                                <span className="rating">
                                    {!isNull(
                                        get(bookDetailData, 'avg_rating', 67.56)
                                    ) &&
                                        get(
                                            bookDetailData,
                                            'avg_rating',
                                            67.56
                                        ).toFixed(1)}
                                </span>
                            </div>
                            <br />
                            <Row className="button-bottom">
                                {buttonBorrow()}
                                {bookDetailData?.using_drm && useDonation && (
                                    <Col
                                        className="mt-md"
                                        style={{
                                            display: isMiddle() && 'none',
                                        }}
                                    >
                                        <Button
                                            className="button-donation"
                                            onClick={() =>
                                                setVisibleBookDonation(true)
                                            }
                                        >
                                            {translator.t('action.donation')}
                                        </Button>
                                    </Col>
                                )}
                                <Col className="mt-md">
                                    {checkBorrowStatus.data
                                        ?.has_borrow_before && (
                                        <Button
                                            className="button-review"
                                            onClick={() =>
                                                setVisibleModalReview(true)
                                            }
                                        >
                                            {translator.t(
                                                hasReview
                                                    ? 'action.editReview'
                                                    : 'action.giveReview'
                                            )}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Skeleton>
                    </div>
                </div>

                <Row className="specification-book">
                    <Col
                        lg={8}
                        md={8}
                        sm={8}
                        xs={8}
                        className="book-specification-detail"
                    >
                        <Space>
                            <DrmImage />
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginLeft: isMiddle() ? 0 : 12,
                                }}
                            >
                                <span className="file-size-title">
                                    {translator.t('title.file_size')}
                                </span>{' '}
                                <br />
                                {loadingSkeleton ? (
                                    <LoadingOutlined />
                                ) : (
                                    <span
                                        className="file-size"
                                        style={{
                                            fontSize: isMiddle() && '9pt',
                                        }}
                                    >
                                        {get(
                                            bookDetailData,
                                            'file_size_info',
                                            '0 MB'
                                        ) ?? '0 MB'}
                                    </span>
                                )}
                            </div>
                        </Space>
                    </Col>
                    <Col
                        lg={8}
                        md={8}
                        sm={8}
                        xs={8}
                        className="book-specification-detail"
                        onClick={() => {
                            setAvailableCopy(true)
                            setVisibleModalCollection(true)
                            getCollectionOwner()
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        <span className="file-size-title">
                            {translator.t('title.total_copy')}
                        </span>{' '}
                        <br />
                        {loadingSkeleton ? (
                            <LoadingOutlined />
                        ) : (
                            <span
                                className="file-size"
                                style={{ fontSize: isMiddle() && '9pt' }}
                            >
                                {get(bookDetailData, 'using_drm', false) ===
                                false
                                    ? translator.t('message.unlimitedCopy')
                                    : get(bookDetailData, 'all_qty', 0)}
                            </span>
                        )}
                    </Col>
                    <Col
                        lg={8}
                        md={8}
                        sm={8}
                        xs={8}
                        className="book-specification-detail"
                        onClick={() => {
                            setAvailableCopy(true)
                            setVisibleModalCollection(true)
                            getCollectionOwner()
                        }}
                        style={{ cursor: 'pointer' }}
                    >
                        <span className="file-size-title">
                            {translator.t('title.available_copy')}
                        </span>
                        <br />
                        {loadingSkeleton ? (
                            <LoadingOutlined />
                        ) : (
                            <span
                                className="file-size"
                                style={{ fontSize: isMiddle() && '9pt' }}
                            >
                                {get(bookDetailData, 'using_drm', false) ===
                                false
                                    ? translator.t('message.unlimitedCopy')
                                    : get(bookDetailData, 'available_qty', 0)}
                            </span>
                        )}
                    </Col>
                </Row>
                <Row
                    className="specification-book-noborder"
                    style={{ display: 'flex', justifyContent: 'space-evenly' }}
                    wrap
                    gutter={[12, 12]}
                >
                    <Col
                        lg={8}
                        md={8}
                        sm={12}
                        xs={12}
                        className="book-specification-detail"
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <Space
                            onClick={() => {
                                setOnReadBy(true)
                                setVisibleModalBorrower(true)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <img
                                src={
                                    loading
                                        ? ic_open_free_skeleton
                                        : ic_reader_list
                                }
                                alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                width={!loading ? 30 : '100%'}
                            />
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginLeft: !isMiddle() && 12,
                                }}
                            >
                                <span className="file-size-title">
                                    {translator.t('title.readed_by')}
                                </span>
                                <br />
                                <span className="file-size">
                                    {bookDetailData.all_user_borrow_count}{' '}
                                    {translator.t('title.users')}
                                </span>
                            </div>
                        </Space>
                    </Col>
                    {isDrm && (
                        <Col
                            lg={8}
                            md={8}
                            sm={12}
                            xs={12}
                            className="book-specification-detail"
                            style={{ textAlign: 'center' }}
                        >
                            <Space
                                onClick={() => setVisibleModalQueue(true)}
                                style={{ cursor: 'pointer' }}
                            >
                                <img
                                    src={
                                        loading
                                            ? ic_open_free_skeleton
                                            : ic_waiting_list
                                    }
                                    alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                    width={!loading ? 23 : '100%'}
                                    height={!loading ? 23 : '100%'}
                                />
                                <div
                                    style={{
                                        textAlign: 'center',
                                        marginLeft: 12,
                                    }}
                                >
                                    <span className="file-size-title">
                                        {translator.t('title.queue')}
                                    </span>{' '}
                                    <br />
                                    <span className="file-size">
                                        {bookDetailData?.queue_count}{' '}
                                        {translator.t('title.users')}
                                    </span>
                                </div>
                            </Space>
                        </Col>
                    )}
                    <Col
                        lg={8}
                        md={8}
                        sm={24}
                        xs={24}
                        className="book-specification-detail"
                        style={{ textAlign: 'center' }}
                    >
                        <Space
                            onClick={() => {
                                setOnReadBy(false)
                                setVisibleModalBorrower(true)
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <img
                                src={
                                    loading
                                        ? ic_open_free_skeleton
                                        : ic_borrow_hour
                                }
                                alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                width={!loading ? 28 : '100%'}
                                height={!loading ? 28 : '100%'}
                            />
                            <div
                                style={{ textAlign: 'center', marginLeft: 12 }}
                            >
                                <span className="file-size-title">
                                    {translator.t('title.borrower_list')}
                                </span>{' '}
                                <br />
                                <span className="file-size">
                                    {bookDetailData?.active_borrow_count}{' '}
                                    {translator.t('title.users')}
                                </span>
                            </div>
                        </Space>
                    </Col>
                </Row>

                <Divider />
                <Row className="description-book-tabs">
                    <Col className="tab" span={24}>
                        <Tabs defaultActiveKey="1" centered>
                            <TabPane
                                tab={translator.t('title.description')}
                                style={{ marginTop: 15 }}
                                key="1"
                            >
                                {loadingSkeleton ? (
                                    <Skeleton.Input active />
                                ) : (
                                    <ReadMore
                                        text={get(
                                            bookDetailData,
                                            'book_description',
                                            'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
                                        )}
                                    />
                                )}
                            </TabPane>
                            <TabPane
                                tab={translator.t('title.detail')}
                                key="2"
                                style={{ marginTop: 15 }}
                            >
                                <table className="table-detail-book">
                                    <tbody>
                                        <tr>
                                            <th className="table-title">
                                                {translator.t('filter.author')}
                                            </th>
                                            <th className="table-title-value active-color">
                                                {get(
                                                    bookDetailData,
                                                    'book_author',
                                                    '-'
                                                )}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="table-title">
                                                {translator.t(
                                                    'filter.publisher'
                                                )}
                                            </th>
                                            <th className="table-title-value active-color">
                                                {get(
                                                    bookDetailData?.catalog_info,
                                                    'organization_name',
                                                    '-'
                                                )}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="table-title">
                                                {translator.t('filter.year')}
                                            </th>
                                            <th className="table-title-value active-color">
                                                {isNull(
                                                    get(
                                                        bookDetailData,
                                                        'publish_date',
                                                        '-'
                                                    )
                                                )
                                                    ? '-'
                                                    : get(
                                                          bookDetailData,
                                                          'publish_date',
                                                          '-'
                                                      )}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="table-title">
                                                ISBN
                                            </th>
                                            <th className="table-title-value">
                                                {isNull(
                                                    get(
                                                        bookDetailData,
                                                        'book_isbn',
                                                        '-'
                                                    )
                                                ) |
                                                (get(
                                                    bookDetailData,
                                                    'book_isbn',
                                                    '-'
                                                ) ===
                                                    '')
                                                    ? '-'
                                                    : get(
                                                          bookDetailData,
                                                          'book_isbn',
                                                          '-'
                                                      )}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className="table-title">
                                                {translator.t(
                                                    'filter.category'
                                                )}
                                            </th>
                                            <th className="table-title-value active-color">
                                                {get(
                                                    bookDetailData,
                                                    'category_name',
                                                    '-'
                                                )}
                                            </th>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPane>
                            <TabPane
                                tab={`${translator.t('title.review')} (${
                                    dataComment.length
                                })`}
                                key="3"
                                style={{ marginTop: 15 }}
                            >
                                <div style={{ marginTop: '25px' }}>
                                    <CommentList
                                        data={bookReviewData}
                                        getReviewData={getReviewData}
                                        dataId={bookId}
                                        setIsChangeReview={setIsChangeReview}
                                    />
                                </div>
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>

                <div
                    style={{
                        display: !isMiddle() && 'none',
                    }}
                    className="fixed"
                >
                    <Row wrap gutter={[8, 8]}>
                        {
                            // change span to 12 when unhide donation button
                        }
                        <Col span={24}>
                            <Button
                                style={{ width: '100%' }}
                                className={
                                    checkBorrowStatus.status
                                        ? 'button-read'
                                        : 'button-borrow'
                                }
                                onClick={() => {
                                    if (bookOutOfStock) {
                                        handleBookQueue()
                                    } else {
                                        handleClickRead(
                                            checkBorrowStatus.status
                                        )
                                    }
                                }}
                                disabled={
                                    bookDetailData?.using_drm &&
                                    get(bookDetailData, 'all_qty', 0) <= 0
                                }
                                loading={loadingQueue || loadingReading}
                            >
                                {loadingSkeleton ? (
                                    <LoadingOutlined />
                                ) : (
                                    translator.t(
                                        checkBorrowStatus.status === true
                                            ? 'action.read'
                                            : bookOutOfStock
                                            ? 'action.queue_borrow'
                                            : 'action.borrow'
                                    )
                                )}
                            </Button>
                        </Col>
                        {bookDetailData?.using_drm && useDonation && (
                            <Col span={12}>
                                <Button
                                    style={{
                                        width: '100%',
                                        background: COLORS.baseorange,
                                        color: 'white',
                                        border: 'none',
                                    }}
                                    type="primary"
                                    className="button-donation"
                                    onClick={() => setVisibleBookDonation(true)}
                                >
                                    {loadingSkeleton ? (
                                        <LoadingOutlined />
                                    ) : (
                                        translator.t('action.donation')
                                    )}
                                </Button>
                            </Col>
                        )}
                    </Row>
                </div>
            </Card>
            <Row className="related-book">
                <Col span={24}>
                    {isEmpty(bookDetailData?.related_books) ? (
                        <div>
                            <Row
                                className="nh-container"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Col>
                                    <h1
                                        className="title"
                                        style={{ marginTop: 8 }}
                                    >
                                        {translator.t('title.similiar_book')}
                                    </h1>
                                </Col>
                                {/* <Col>
                                    <Button className="link">
                                        {translator.t('action.fully')}
                                    </Button>
                                </Col> */}
                            </Row>
                            <br />
                            <Row>
                                <Col span={24}>
                                    <Empty
                                        description={
                                            <span style={{ color: 'silver' }}>
                                                {translator.t('auth.noBook')}
                                            </span>
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <BookSliderSection
                            fromBookDetail
                            data={bookDetailData.related_books}
                            title={translator.t('title.similiar_book')}
                            related_book={true}
                            detailUrl={{
                                url: '/',
                                category_name: bookDetailData.category_name,
                                category_id: bookDetailData.category_id,
                            }}
                            onRedirect={() => {
                                setEpustakaId(undefined)
                            }}
                        />
                    )}
                </Col>
            </Row>

            <ModalNoAccess
                visibleModal={visibleAccess}
                setVisibleModal={setVisibleAccess}
                title="Banner"
            />

            <ModalDownloadBook
                epustakaId={epustakaId}
                isDrm={get(bookDetailData, 'using_drm', true)}
                file_ext={get(bookDetailData, 'file_ext', '')}
                visibleModal={visibleModal}
                setVisibleModal={setVisibleModal}
                data={epustakaBorrow}
                profile={profile}
                bookData={bookDetailData}
                setCheckBorrowStatus={setCheckBorrowStatus}
            />
            <ModalBorrowerList
                visibleModal={visibleModalBorrower}
                setVisibleModal={setVisibleModalBorrower}
                userId={profile.id}
                bookId={bookId}
                onReadBy={onReadBy}
                bookOutOfStock={bookOutOfStock}
                handleBookQueue={handleBookQueue}
                handleClickRead={handleClickRead}
                checkBorrowStatus={checkBorrowStatus}
                bookDetailData={bookDetailData}
            />
            <ModalQueueList
                visibleModal={visibleModalQueue}
                setVisibleModal={setVisibleModalQueue}
                data={bookQueue}
                userId={profile.id}
                currentPageQueue={currentPageQueue}
                paginationQueue={paginationQueue}
                onLoadMore={onLoadMoreQueue}
                getDataQueue={getDataQueue}
                setDataQueueChanged={setDataQueueChanged}
                bookOutOfStock={bookOutOfStock}
                handleBookQueue={handleBookQueue}
                handleClickRead={handleClickRead}
                checkBorrowStatus={checkBorrowStatus}
                bookDetailData={bookDetailData}
            />

            <ModalCollectionOwner
                visibleModal={visibleModalCollection}
                setVisibleModal={setVisibleModalCollection}
                dataCollectionOwner={dataCollectionOwner}
                limit={limitCollection}
                setLimit={setLimitCollection}
                fromAvailableCopy={fromAvailableCopy}
            />

            <ModalRecommendation
                visibleModal={visibleModalRecommendation}
                setVisibleModal={setVisibleModalRecommendation}
                bookDetailData={bookDetailData}
                data={userFollower}
                userId={profile.id}
                organization_id={organization_id}
                currentPageFollower={currentPageFollower}
                paginationFollower={paginationFollower}
                onLoadMore={onLoadMoreFollower}
                getDataFollower={getDataFollower}
                setDataFollowerChanged={setDataFollowerChanged}
                setQuerySearchFollower={setQuerySearchFollower}
            />

            <ModalReview
                getBookDetail={getBookDetail}
                visibleModal={visibleModalReview}
                setVisibleModal={setVisibleModalReview}
                bookDetailData={bookDetailData}
                hasReview={hasReview}
                getReviewData={getReviewData}
            />

            <ModalBookDonation
                visible={visibleBookDonation}
                setVisible={setVisibleBookDonation}
                bookDetailData={bookDetailData}
            />

            <ModalShare
                urlFrom="book"
                data={bookDetailData}
                visibleModal={isModalVisibleShare}
                setVisibleModal={setIsModalVisibleShare}
            />

            <ModalInformationBook
                visible={visibleInformation}
                setVisible={setVisibleInformation}
                epustakaBorrow={epustakaBorrow}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    stateTimeLine: get(state, 'timeLine', {}),
    stateRouteHistory: get(state, 'routeHistory', {}),
    stateReadingProgress: get(state, 'readProgress', null),
    stateBookmarkData: get(state, 'bookmarkData', []),
    stateLastReadingDuration: get(state, 'lastReadingDuration', {}),
    stateReadingDuration: get(state, 'readingDuration', []),

    stateFromReader: get(state, 'fromReader', false),
})

const mapDispatchToProps = () => ({
    setTimeLine: action.setTimeLine,
    setRoute: actionRoute.setRoute,
    setReadingProgress: actionRead.setReadingProgress,
    clearReadingProgress: actionRead.clearReadingProgress,
    clearBookmarkData: actionBookmark.clearBookmark,
    setBookmarkData: actionBookmark.setBookmark,
    setReadingDuration: actionReadingDuration.setReadingDuration,
    clearReadingDuration: actionReadingDuration.clearReadingDuration,
    clearLastReadingDuration:
        actionLastReadingDuration.clearLastReadingDuration,
    clearFromReader: actionFromReader.clearFromReader,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BookDetail))
