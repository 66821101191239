import { Button, List } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import React, { useEffect, useState, useLayoutEffect } from 'react'
import translator from 'i18next'
import provider from 'providers/provider'
import url from 'constants/url'
import Modal from 'antd/lib/modal/Modal'
import { useHistory } from 'react-router-dom'
import Text from 'antd/lib/typography/Text'
import { ModalConfirmFollowUnfollow } from 'components'
import getAcronym from 'components/Acronym'
import { error } from 'utils/notification'
import { COLORS } from 'constants/constant'

const ModalSuggestFriends = ({ visibleModal, setVisibleModal }) => {
    const history = useHistory()
    const [suggestFriendsDetail, setSuggestFriendsDetail] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [limit, setLimit] = useState(20)
    const [visibleConfirmFollow, setVisibleConfirmFollow] = useState(false)
    const [loadingMore, setLoadingMore] = useState(false)
    const [item, setItem] = useState({})

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 420

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getSuggestFriends = async () => {
        setLoadingMore(true)
        await provider
            .list(
                url.webhookURL_suggest_friends +
                    `?limit=${limit}&offset=0&sort=u.name`
            )
            .then((res) => {
                setTotalData(res.meta.total)
                setSuggestFriendsDetail(res.data)
                setLoadingMore(false)
            })
            .catch((err) => {
                setLoadingMore(false)
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    useEffect(() => {
        getSuggestFriends()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit])

    useEffect(() => {
        setLimit(20)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    loading={loadingMore}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 20)
                    }}
                    style={{
                        color: COLORS.primaryColor,
                        borderColor: COLORS.primaryColor,
                    }}
                >
                    {translator.t('action.more')}
                </Button>
            )
        } else {
            return null
        }
    }

    return (
        <>
            <Modal
                visible={visibleModal}
                onOk={() => setVisibleModal(!visibleModal)}
                onCancel={() => setVisibleModal(!visibleModal)}
                footer={null}
                centered
                width={590}
                title={translator.t('column.suggestFriends')}
            >
                <List
                    style={{ maxHeight: '55vh', overflow: 'auto' }}
                    itemLayout="horizontal"
                    dataSource={suggestFriendsDetail}
                    renderItem={(item) => {
                        const { name, avatar_url, id } = item
                        const { color, acronym } = getAcronym(name || '', id)
                        return (
                            <List.Item
                                actions={[
                                    <Button
                                        style={{ width: isMobile() ? 90 : 120 }}
                                        className="btn-follow"
                                        type="primary"
                                        onClick={(e) => {
                                            e?.currentTarget?.blur()
                                            setVisibleConfirmFollow(true) ||
                                                setItem(item)
                                        }}
                                    >
                                        {translator.t('action.follow')}
                                    </Button>,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={
                                        <Avatar
                                            size="large"
                                            style={{
                                                cursor: 'pointer',
                                                backgroundImage: color,
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                            }}
                                            src={avatar_url}
                                            onClick={() =>
                                                history.push(
                                                    `/profil/user/${id}`
                                                )
                                            }
                                        >
                                            <span style={{ fontSize: 13 }}>
                                                {acronym}
                                            </span>
                                        </Avatar>
                                    }
                                    title={
                                        <Text
                                            style={{
                                                width: isMobile() && 110,
                                            }}
                                            ellipsis={{
                                                tooltip: item?.name,
                                            }}
                                        >
                                            {item?.name === null
                                                ? 'unknown'
                                                : item?.name}
                                        </Text>
                                    }
                                />
                            </List.Item>
                        )
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <DispalyButton />
                    </div>
                </List>
            </Modal>

            <ModalConfirmFollowUnfollow
                visibleModal={visibleConfirmFollow}
                setVisibleModal={setVisibleConfirmFollow}
                item={item}
                getSuggestFriends={getSuggestFriends}
                source="suggestFriend"
            />
        </>
    )
}

export default ModalSuggestFriends
