import includes from 'lodash/includes'
import {
    API_BASE_URL,
    API_BASE_URL_2,
    API_MCCP,
    API_STORAGE_URL,
} from './constants'
import headers from './headers'

const options = (
    method,
    url,
    data,
    source = '',
    mccp = false,
    fileType = '',
    extraParams
) => {
    let tempUrl
    let baseUrl

    switch (true) {
        case mccp:
            baseUrl = API_MCCP
            break
        case (url?.includes('webhook') || url?.includes('auth')) &&
            !!API_BASE_URL_2:
            baseUrl = API_BASE_URL_2
            break
        default:
            baseUrl = API_BASE_URL
            break
    }

    switch (true) {
        case includes(['customeheader', 'storageclient'], source):
            tempUrl = `${API_STORAGE_URL}/${url}`
            break
        case includes(['upload', 'external'], source):
            tempUrl = url
            break
        default:
            tempUrl = `${baseUrl}/${url}`
            break
    }

    let options = {
        headers: headers(source, fileType, data, mccp),
        method,
        data,
        url: tempUrl,
    }

    if (extraParams) options = { ...options, ...extraParams }
    if (includes(source, ['external', 'customeheader', 'notoken'])) {
        delete options.headers
        delete options.data
    }

    if (includes(['storageclient'], source) && includes(['delete'], method)) {
        delete options.headers
    }

    return options
}

export default options
