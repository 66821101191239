const getCode = (params) => {
    const routeObject = {
        default: [],
        Guest: 301,
        Parent: 302,
        Student: 303,
        Teacher: 304,
        Admin: 305,
        SA: 999,
    }

    return routeObject[params || 'default']
}
export default getCode
