import url from 'constants/url'
import provider from 'providers/provider'
import React, { useEffect, useState, useLayoutEffect } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { Col, Input, Row } from 'antd'
import reduxState from '../../../redux'
import getPagination from 'constants/getPagination'
import { error } from 'utils/notification'
import InfiniteScroll from 'react-infinite-scroll-component'
import { AudioCardComponent } from 'views/home/audio/components'
import {
    DeleteOutlined,
    SearchOutlined,
    ShareAltOutlined,
} from '@ant-design/icons'
import { ModalShare, SkeletonAudio } from 'components'
import { FallbackImage } from 'components'
import { useHistory } from 'react-router-dom'
import Paragraph from 'antd/lib/typography/Paragraph'
import { COLORS } from 'constants/constant'
import translator from 'i18next'
import ModalReturnBook from 'views/bookshelf/components/ModalReturnBook'
import { noimages } from '../../../assets/icons'

const MoreHistoryAudio = (props) => {
    const userId = props.location.state.userId
    const source = props.location.state.source
    const { profile = {} } = reduxState.getState()
    const history = useHistory()
    const id = get(profile, 'id', '')
    const defaultPagination = `&type=audio`
    const filter =
        source === 'userProfil' ? `&user_id=${userId}` : `&user_id=${id}`

    const limit = 12
    const [currentPage, setCurrentPage] = useState(1)
    const [state, setState] = useState({
        loading: true,
        pagination: true,
    })
    const [audioData, setAudioData] = useState([])
    const [paramName, setParamName] = useState('')
    const [empty, setEmpty] = useState(false)
    const [popShare, setPopShare] = useState(false)
    const [visibleDelete, setVisibleDelete] = useState(false)
    const [mediaItem, setMediaItem] = useState({})

    const [stateAudio, setStateAudio] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => stateAudio.width <= 420

    const updateDimensions = () => {
        setStateAudio({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getAudioData = async () => {
        const pagination = getPagination(currentPage, limit, 'webhook')
        await provider
            .list(
                encodeURI(
                    url.webhookURL_multimedia_history_list +
                        pagination.result +
                        defaultPagination +
                        filter
                )
            )
            .then((res) => {
                const { data, meta } = res
                setAudioData(data)
                setState({
                    ...state,
                    loading: false,
                })
                if (pagination.offset >= meta.total) {
                    setState({ pagination: false, loading: false })
                }
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setState({ ...state, loading: false })
                return
            })
    }

    useEffect(() => {
        getAudioData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onLoadMore = async () => {
        setState({
            ...state,
            loading: true,
        })
        setCurrentPage(currentPage + 1)
    }

    const handleSearch = async (e) => {
        if (e.key === 'Enter') {
            await provider
                .list(
                    url.webhookURL_multimedia_history_list +
                        `?q=${paramName}&type=audio&user_id=${id}`
                )
                .then((res) => {
                    const { data, meta } = res
                    setAudioData(data)
                    if (meta.total === 0) {
                        setEmpty(true)
                    } else {
                        setEmpty(false)
                    }
                    setState({ ...state, loading: false })
                    setState({ ...state, pagination: false })
                })
        }
    }

    const handleClickShare = (value) => {
        setMediaItem(value)
        setPopShare(true)
    }

    const handleClickDelete = (value) => {
        setMediaItem(value)
        setVisibleDelete(true)
    }

    return (
        <div
            className="pa__container"
            style={{
                marginTop: '-15px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    marginTop: -20,
                    justifyContent: 'space-between',
                    maxWidth: 900,
                    margin: '3em auto',
                }}
            >
                <div
                    style={{
                        color: COLORS.primaryColor,
                        fontWeight: 600,
                        fontSize: 16,
                    }}
                >
                    {translator.t('column.audio')}
                </div>
                <Input
                    style={{ width: 200, borderRadius: 25 }}
                    placeholder="Cari Audio"
                    suffix={<SearchOutlined />}
                    onChange={(e) => setParamName(e.target.value)}
                    onKeyPress={handleSearch}
                />
            </div>
            <Row
                type="flex"
                className="vc_container"
                justify="center"
                style={{ marginTop: '-15px' }}
            >
                {state.loading && isEmpty(audioData) ? (
                    <SkeletonAudio source="fullskeleton" />
                ) : (
                    <InfiniteScroll
                        dataLength={audioData.length}
                        next={onLoadMore}
                        hasMore={state.pagination}
                        style={{ overflow: 'hidden' }}
                    >
                        {empty ? (
                            <Row
                                style={{
                                    justifyContent: 'center',
                                    color: 'darkgray',
                                }}
                            >
                                {translator.t('message.data_not_found')}
                            </Row>
                        ) : (
                            <div className="a-container">
                                <Row
                                    style={{
                                        justifyContent: 'center',
                                        maxWidth: 1100,
                                        marginTop: 30,
                                    }}
                                >
                                    {audioData.map((item, id) => {
                                        const {
                                            media_thumbnail_url,
                                            media_title,
                                            collection_id,
                                            epustaka_id,
                                        } = item

                                        return (
                                            <div style={{ zIndex: 1 }} key={id}>
                                                {isMobile() ? (
                                                    <>
                                                        <Col
                                                            span={24}
                                                            className="songRow"
                                                        >
                                                            <FallbackImage
                                                                className="songRow__album"
                                                                src={
                                                                    media_thumbnail_url ===
                                                                    null
                                                                        ? noimages
                                                                        : media_thumbnail_url
                                                                }
                                                                alt="Mari bergabung dan berkolaborasi mengembangkan pendidikan Indonesia bersama anak bangsa."
                                                                onClick={() =>
                                                                    history.push(
                                                                        `/audio/${collection_id}/${epustaka_id}`
                                                                    )
                                                                }
                                                            />
                                                            <div
                                                                className="songRow__info"
                                                                style={{
                                                                    textAlign:
                                                                        'start',
                                                                }}
                                                            >
                                                                <Paragraph
                                                                    ellipsis={
                                                                        true
                                                                            ? {
                                                                                  rows: 2,
                                                                              }
                                                                            : false
                                                                    }
                                                                    style={{
                                                                        fontWeight: 600,
                                                                    }}
                                                                >
                                                                    {
                                                                        media_title
                                                                    }
                                                                </Paragraph>
                                                            </div>
                                                            <DeleteOutlined
                                                                style={{
                                                                    color: COLORS.basegrey,
                                                                    marginLeft:
                                                                        '1em',
                                                                }}
                                                                onClick={() => {
                                                                    handleClickDelete(
                                                                        item
                                                                    )
                                                                }}
                                                            />
                                                            <ShareAltOutlined
                                                                style={{
                                                                    color: COLORS.basegrey,
                                                                    marginLeft:
                                                                        '1em',
                                                                }}
                                                                onClick={() =>
                                                                    handleClickShare(
                                                                        item
                                                                    )
                                                                }
                                                            />
                                                        </Col>
                                                    </>
                                                ) : (
                                                    <AudioCardComponent
                                                        data={item}
                                                        frombookself="bookself"
                                                        getMedia={getAudioData}
                                                        source={
                                                            source
                                                                ? 'userProfil'
                                                                : 'audio'
                                                        }
                                                    />
                                                )}
                                            </div>
                                        )
                                    })}
                                </Row>
                            </div>
                        )}
                    </InfiniteScroll>
                )}
            </Row>

            <ModalShare
                urlFrom="audio"
                data={mediaItem}
                visibleModal={popShare}
                setVisibleModal={setPopShare}
            />

            <ModalReturnBook
                visibleModal={visibleDelete}
                setVisibleModal={setVisibleDelete}
                mediaData={mediaItem}
                getMedia={getAudioData}
                frombookself="frombookself"
            />
        </div>
    )
}

export default MoreHistoryAudio
