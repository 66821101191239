import store from '../store'
import * as ActionType from './contant'

export default {
    setDataEpustaka: (value) => {
        const action = { type: ActionType.SET_EPUSTAKA, value }
        store.dispatch(action)
    },
    updateDataEpustaka: (value) => {
        const action = { type: ActionType.UPDATE_EPUSTAKA, value }
        store.dispatch(action)
    },
    clearDataEpustaka: () => {
        const action = { type: ActionType.CLEAR_EPUSTAKA }
        store.dispatch(action)
    },
}
