import React, { useState, useEffect } from 'react'
import 'views/timeline/feed/MessageSender.scss'
import translator from 'i18next'
import get from 'lodash/get'
import concat from 'lodash/concat'
import url from 'constants/url'
import provider from 'providers/provider'
import reduxState from '../../../redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { error } from 'utils/notification'
import getPagination from 'constants/getPagination'
import action from 'redux/timeLine/action'
import CommentList from '../components/CommentList'

import { SendOutlined } from '@ant-design/icons'
import { Avatar, Button, Input, Modal, Radio } from 'antd'
import getAcronym from 'components/Acronym'
import { BASE_TITLE, COLORS } from 'constants/constant'

const { TextArea } = Input
const Comments = (props) => {
    const {
        setTimeLine,
        stateTimeLine,
        fromEpustakaDetail,
        dataEpustaka,
        sender_type,
    } = props

    const [loading, setLoading] = useState(false)
    const [OpenReply, setOpenReply] = useState(false)
    const [reportComment, setReportComment] = useState('')
    const [postComment, setPostComment] = useState('')
    const [dataComment, setDataComment] = useState([])
    const [totalComment, setTotalComment] = useState(0)
    const [userProfil, setUserProfile] = useState([])
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [item, setItem] = useState({})

    const [paginationComment, setPaginationComment] = useState({
        loading: true,
        pagination: true,
    })

    const limit = 10
    const [limitComment, setLimitComment] = useState(1)
    const pagination = getPagination(limitComment, limit, 'webhook')

    const { feed = {} } = reduxState.getState()

    // const epustaka_id = get(dataEpustaka, 'id', '')

    const name = userProfil?.name || ''
    const id = userProfil?.id
    const { color, acronym } = getAcronym(name, id)

    // const userId = get(userProfil, 'id', '')
    const object_id = get(feed, 'id', '')

    const object_type = '&object_type=FEED'
    const objId = `&object_id=${object_id}`

    const getProfile = async () => {
        await provider
            .singleWebHook(`${url.webhookURL_profile}`)
            .then((res) => {
                setUserProfile(res.data)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    useEffect(() => {
        getProfile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCommentList = async () => {
        setLoading(true)
        await provider
            .list(
                `${
                    url.webhookURL_comment_list +
                    pagination.result +
                    object_type +
                    objId
                }`
            )
            .then((results) => {
                const { data, meta } = results

                limitComment === 1
                    ? setDataComment(data)
                    : setDataComment(concat(dataComment, data))

                if (pagination.offset >= meta.total || meta.total <= limit) {
                    setPaginationComment({
                        loading: false,
                        pagination: false,
                    })
                } else {
                    setPaginationComment({
                        ...paginationComment,
                        loading: false,
                    })
                }

                setTotalComment(meta.total)
                setLoading(false)
                setTimeLine({ refresh: false })
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })

                return
            })
    }

    useEffect(() => {
        getCommentList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limitComment])

    useEffect(() => {
        if (stateTimeLine.refresh) {
            limitComment === 1 ? getCommentList() : setLimitComment(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateTimeLine.refresh])

    const onLoadMoreComment = async () => {
        setPaginationComment({
            ...paginationComment,
            loading: true,
        })
        setLimitComment(limitComment + 1)
    }

    const radioStyle = {
        display: 'flex',
        height: '30px',
        lineHeight: '22px',
        marginBottom: '10px',
    }

    const handleOk = async (value) => {
        setConfirmLoading(true)
        return await provider
            .put(url.webhookURL_comment_report, {
                comment_id: `${value.id}`,
                reason: `${reportComment}`,
            })
            .then(() => {
                setTimeLine({ refreshLike: true })
                setConfirmLoading(false)
                setIsModalVisible(false)
                value.has_report === true
                    ? Modal.warning({
                          title: `${translator.t('message.warningReport')}`,
                          width: 550,
                          content: (
                              <div>
                                  <p>
                                      {translator.t(
                                          'message.message_warning_report'
                                      )}
                                  </p>
                              </div>
                          ),
                          onOk() {},
                      })
                    : Modal.info({
                          title: `${translator.t('message.successReport')}`,
                          width: 700,
                          content: (
                              <div>
                                  <p>
                                      {translator.t(
                                          'message.message_success_report'
                                      )}
                                  </p>
                              </div>
                          ),
                          onOk() {},
                      })
            })
            .catch((err) => {
                setIsModalVisible(false)
                setConfirmLoading(false)
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const onReportComment = (value) => {
        setIsModalVisible(true)
        setItem(value)
    }

    // To Post a comment in comment form
    const submitData = async (url, data) => await provider.insert(url, data)
    const handleSave = async () => {
        setLoading(true)
        // TODO comment sender_type 'EPUSTAKA'
        // object_type: 'FEED',
        // object_id: `${object_id}`,
        // comment: `${postComment}`,
        // sender_id: fromEpustakaDetail ? epustaka_id : userId,
        // sender_type: fromEpustakaDetail ? 'EPUSTAKA' : 'USER',
        await submitData(url.webhookURL_comment, {
            object_type: 'FEED',
            object_id: `${object_id}`,
            comment: `${postComment}`,
        })
            .then(async () => {
                setTimeLine({ refresh: true })
                setOpenReply(!OpenReply)
                setLoading(false)
                // setFileList([])
            })
            .catch((err) => {
                Promise.reject(err)
                setLoading(false)
            })
        setPostComment('')
    }
    const handleFinish = async (values) => {
        const data = values.submit_values
        await handleSave({
            ...data,
        })
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter' || e.shiftKey === false) {
            handleFinish(e)
        }
    }

    return (
        <div>
            <div className="detailMessageSender">
                <div
                    style={{
                        borderTop: '1px solid #eff2f5',
                        marginTop: 7,
                    }}
                />

                <div className="messageSender-ttop">
                    <Avatar
                        className="post-avatar"
                        alt={`$${BASE_TITLE} image preview - Platform Perpustakaan Indonesia`}
                        style={{
                            cursor: 'pointer',
                            backgroundImage: color,
                            display: 'inline-flex',
                            alignItems: 'center',
                        }}
                        src={
                            fromEpustakaDetail
                                ? get(dataEpustaka, 'epustaka_logo', '')
                                : get(userProfil, 'avatar_url', '')
                        }
                    >
                        <span
                            style={{
                                fontSize: 15,
                            }}
                        >
                            {acronym}
                        </span>
                    </Avatar>

                    <form>
                        <TextArea
                            id="foo"
                            className="placeholder"
                            placeholder={`${translator.t('feed.addComment')}`}
                            bordered={false}
                            autoSize
                            value={postComment}
                            onChange={(e) => setPostComment(e.target.value)}
                            onPressEnter={handleEnter}
                        />

                        <div className="sender">
                            <Button
                                onClick={handleFinish}
                                disabled={!postComment}
                                loading={loading}
                                className="btn-sender"
                                type="primary"
                                shape="circle"
                                icon={<SendOutlined className="icon-sender" />}
                            />
                        </div>
                    </form>
                </div>

                {dataComment.length === 0 ? (
                    ''
                ) : (
                    <div className="container-comment-list">
                        <div
                            style={{
                                borderTop: '1px solid #eff2f5',
                            }}
                        />
                        <div
                            style={{
                                marginTop: '5px',
                                marginLeft: '15px',
                                color: COLORS.secondaryColor,
                                fontSize: '16px',
                            }}
                        >
                            {dataComment.length === 0
                                ? ''
                                : `${totalComment} Komentar`}
                        </div>

                        <Modal
                            className="modal-report"
                            title={`${translator.t('title.report_comment')}`}
                            visible={isModalVisible}
                            onOk={() => handleOk(item)}
                            onCancel={handleCancel}
                            okButtonProps={{ disabled: !reportComment }}
                            confirmLoading={confirmLoading}
                            okText={`${translator.t('action.report')}`}
                            cancelText={`${translator.t('action.cancell')}`}
                        >
                            <div className="radio">
                                <span>
                                    <Radio.Group
                                        onChange={(e) => {
                                            setReportComment(e.target.value)
                                        }}
                                    >
                                        <Radio
                                            style={radioStyle}
                                            value="Materi pornografi atau seksual vulgar"
                                        >
                                            Materi pornografi atau seksual
                                            vulgar
                                        </Radio>
                                        <Radio
                                            style={radioStyle}
                                            value="Perkataan yang mendorong kebencian atau
                                kekerasan"
                                        >
                                            Perkataan yang mendorong kebencian
                                            atau kekerasan
                                        </Radio>
                                        <Radio
                                            style={radioStyle}
                                            value="Konten komersial atau spam yang tidak diinginkan"
                                        >
                                            Konten komersial atau spam yang
                                            tidak diinginkan
                                        </Radio>
                                        <Radio
                                            style={radioStyle}
                                            value="Pelecehan atau bullying"
                                        >
                                            Pelecehan atau bullying
                                        </Radio>
                                    </Radio.Group>
                                </span>
                            </div>
                        </Modal>

                        <CommentList
                            data={dataComment}
                            onLoadMore={onLoadMoreComment}
                            paginationComment={paginationComment}
                            getCommentList={getCommentList}
                            dataId={object_id}
                            onReportComment={onReportComment}
                            fromEpustakaDetail={fromEpustakaDetail}
                            dataEpustaka={dataEpustaka}
                            sender_type={sender_type}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    stateTimeLine: get(state, 'timeLine', {}),
})

const mapDispatchToProps = () => ({
    setTimeLine: action.setTimeLine,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Comments))
