import React, { useEffect, useState } from 'react'
import translator from 'i18next'
import { Button, Card } from 'antd'
import provider from 'providers/provider'
import url from 'constants/url'
import notificationHandler from 'utils/notificationHandler'
import dayjs from 'dayjs'
import {
  ArrowDownOutlined,
  FolderFilled,
  LoadingOutlined,
} from '@ant-design/icons'
import isEmpty from 'lodash/isEmpty'
import { useHistory } from 'react-router-dom'
import { COLORS } from 'constants/constant'

const History = () => {
  const router = useHistory()
  const [orderList, setOrderList] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalOrder, setTotalOrder] = useState(0)
  const [limit, setLimit] = useState(10)

  const getListOrder = async () => {
    setLoading(true)
    await provider
      .list(
        `${url.webhookURL_order_box_list}?limit=10&offset=0&status=completed&category=TRYOUT`
      )
      .then((res) => {
        const { data, meta } = res
        setTotalOrder(meta.total)
        setOrderList(data)
        setLoading(false)
      })
      .catch((err) => {
        notificationHandler('dev2', err)
        setLoading(false)
      })
  }

  useEffect(() => {
    getListOrder()
  }, [limit])

  const DispalyButton = () => {
    if (totalOrder > limit) {
      return (
        <Button
          className="animate fadeInBottom"
          icon={<ArrowDownOutlined />}
          loading={loading}
          onClick={() => {
            totalOrder > limit && setLimit((e) => e + 10)
          }}
        >
          {translator.t('action.fully')}
        </Button>
      )
    } else {
      return null
    }
  }

  return (
    <div className="status">
      {loading ? (
        <div className="status-loading">
          <LoadingOutlined />
        </div>
      ) : (
        <>
          <p>{translator.t('payment.historyPayment')}</p>
          {isEmpty(orderList) && (
            <div className="status-null">
              <FolderFilled />
              <p>{translator.t('payment.messageNodata')}</p>
              <div>{translator.t('payment.messageNodataDetail')}</div>
              <Button
                className="chooseBtn"
                onClick={() =>
                  router.push({
                    pathname: '/',
                  })
                }
              >
                {translator.t('payment.choosePackage')}
              </Button>
            </div>
          )}
          {orderList.map((item, key) => {
            const orderBoxId = item?.id
            const totalPayment =
              item?.amount -
              item?.discount_amount +
              item?.service_fee_amount +
              item?.tax_amount
            const formatRupiah = (money) => {
              return new Intl.NumberFormat({
                style: 'currency',
                currency: 'IDR',
                minimumFractionDigits: 0,
              }).format(money)
            }

            // KONDISI STATUS TRANSAKSI
            let labelStatus, styleStatus
            switch (item?.payment_status) {
              case 1:
                labelStatus = translator.t('payment.waiting_payment')
                styleStatus = {
                  color: COLORS.primaryColor,
                }
                break

              case 3:
                labelStatus = translator.t('payment.finishPayment')
                styleStatus = {
                  color: COLORS.primaryColor,
                }
                break

              case 4:
                labelStatus = translator.t('payment.paymentFailed')
                styleStatus = {
                  color: COLORS.basered,
                }
                break

              case 5:
                labelStatus = translator.t('payment.cancel_payment')
                styleStatus = {
                  color: COLORS.basered,
                }
                break

              default:
                break
            }

            return (
              <Card
                className="card-history"
                key={key}
                title={
                  item.payment_status === 5 || item.payment_status === 3
                    ? translator.t('payment.idTransaction')
                    : ''
                }
                onClick={() => {
                  router.push({
                    pathname: '/detail-order-box/payment',
                    state: { orderBoxId },
                  })
                }}
              >
                <p
                  className={
                    item.payment_status === 5 || item.payment_status === 3
                      ? 'card-basecolor'
                      : 'card-color'
                  }
                >
                  {item.transaction_code}
                </p>
                <div className="card-counthistory">
                  {translator.t('payment.idr')} {formatRupiah(totalPayment)}
                </div>
                <div className="card-datehistory">
                  {dayjs(item.created_at).format('D MMMM YYYY - HH:mm')}
                </div>
                <div className="card-status">
                  {translator.t('payment.status')}
                  <span style={styleStatus}>{labelStatus}</span>
                </div>
              </Card>
            )
          })}
        </>
      )}
      <div className="loadmore-btn">
        <DispalyButton />
      </div>
    </div>
  )
}

export default History
