import store from '../store'
import * as ActionType from './contant'

export default {
    setSearch: (value) => {
        const action = { type: ActionType.SET_SEARCH, value }
        store.dispatch(action)
    },
    clearSearch: () => {
        const action = { type: ActionType.CLEAR_SEARCH }
        store.dispatch(action)
    },
}
