import store from '../store'
import * as ActionType from './contant'

export default {
    setDataCategoryBookFilter: (value) => {
        const action = { type: ActionType.SET_DATA, value }
        store.dispatch(action)
    },
    clearDataCategoryBookFilter: () => {
        const action = { type: ActionType.CLEAR_DATA }
        store.dispatch(action)
    },
}
