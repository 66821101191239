import React from 'react'
import translator from 'i18next'
import { Skeleton, List } from 'antd'
import isEmpty from 'lodash/isEmpty'
// import { withApollo } from '@apollo/client/react/hoc'
import { toLower } from 'lodash'
import displayBio from 'utils/displayBio'

const Identity = (props) => {
    const { state, isLoading } = props

    const genderList = [
        {
            code: 'L',
            gender: 'male',
        },
        {
            code: 'P',
            gender: 'female',
        },
        {
            code: 'laki-laki',
            gender: 'male',
        },
        {
            code: 'Laki-laki',
            gender: 'male',
        },
        {
            code: 'perempuan',
            gender: 'female',
        },
        {
            code: 'Perempuan',
            gender: 'female',
        },
        {
            code: 'male',
            gender: 'male',
        },
        {
            code: 'female',
            gender: 'female',
        },
        {
            code: 'null',
            gender: '',
        },
        {
            code: 'M',
            gender: 'male',
        },
        {
            code: null,
            gender: '',
        },
    ]

    const profileUser = [
        // User Data
        {
            title: translator.t('action.fullName'),
            subtitle: state?.name || '-',
            style: true,
        },
        {
            title: translator.t('profile.gender'),
            subtitle: isEmpty(state?.gender)
                ? '-'
                : toLower(state?.gender) === 'null'
                ? '-'
                : translator.t(
                      `gendder.${
                          genderList.find((e) => e?.code === state?.gender)
                              .gender
                      }`
                  ),
            style: true,
        },
        {
            title: translator.t('action.phone'),
            subtitle: state?.phone || '-',
            style: true,
        },
        {
            title: translator.t('action.fullAddress'),
            subtitle: state?.address || '-',
            style: true,
        },
        {
            title: translator.t('filter.district'),
            subtitle: state?.district_name || '-',
            style: true,
        },
        {
            title: translator.t('action.bio'),
            subtitle: displayBio(state?.bio),
            style: true,
        },
        {
            title: translator.t('action.emailProfile'),
            subtitle: state?.email || '-',
            style: false,
        },
    ]

    // const dataSchool = [
    //     // School Data
    //     {
    //         title: translator.t('action.nameSchool'),
    //         subtitle: dataUser?.school?.school_name || '-',
    //         style: true,
    //     },
    //     {
    //         title: translator.t('action.fullAddress'),
    //         subtitle: dataUser?.school?.address || '-',
    //         style: true,
    //     },
    //     {
    //         title: translator.t('action.city'),
    //         subtitle: dataUser?.school?.regency || '-',
    //         style: true,
    //     },
    //     {
    //         title: translator.t('action.province'),
    //         subtitle: dataUser?.school?.province || '-',
    //         style: true,
    //     },
    // ]
    return (
        <>
            {/* User Data */}
            <div className="setting__containerMiddle">
                {isLoading ? (
                    <Skeleton active />
                ) : (
                    <>
                        <div className="setting__containerMiddleTitle">
                            {translator.t('action.dataUser')}
                        </div>
                        <List
                            itemLayout="horizontal"
                            dataSource={profileUser}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <div
                                                style={{
                                                    color: '#808080cc',
                                                }}
                                            >
                                                {item.title}
                                            </div>
                                        }
                                        description={
                                            <span
                                                style={{
                                                    textTransform: item.style,
                                                }}
                                            >
                                                {item.subtitle}
                                            </span>
                                        }
                                    />
                                </List.Item>
                            )}
                        />{' '}
                    </>
                )}
            </div>

            {/* School Data */}
            {/* <div className="setting__containerMiddle">
                {isLoading ? (
                    <Skeleton active />
                ) : (
                    <>
                        {' '}
                        <div className="setting__containerMiddleTitle">
                            {translator.t('action.dataSchool')}
                        </div>
                        <List
                            itemLayout="horizontal"
                            dataSource={dataSchool}
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <div
                                                style={{
                                                    color: '#808080cc',
                                                }}
                                            >
                                                {item.title}
                                            </div>
                                        }
                                        description={
                                            <span
                                                style={{
                                                    textTransform:
                                                        item.style &&
                                                        'capitalize',
                                                }}
                                            >
                                                {item.subtitle}
                                            </span>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </>
                )}
            </div> */}
        </>
    )
}

export default Identity
