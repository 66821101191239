import React, { useEffect, useState } from 'react'
import { Button, Col, Image, Row, Typography } from 'antd'
import translator from 'i18next'
import { ceklist } from 'assets/icons'
import provider from 'providers/provider'
import url from 'constants/url'
import notificationHandler from 'utils/notificationHandler'
import dayjs from 'dayjs'
import { LoadingOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'
import { BASE_TITLE, COLORS } from 'constants/constant'
const { Paragraph } = Typography

const DetailPayment = (props) => {
    const orderBoxId = props.location.state.orderBoxId
    const [dataOrder, setDataOrder] = useState([])
    const [dataInvoice, setDataInvoice] = useState({})
    const [loadingAll, setLoadingAll] = useState(false)

    const getOrderBoxDetail = async () => {
        await provider
            .singleWebHook(
                `${
                    url.webhookURL_order_box_detail
                }?order_box_id=${orderBoxId}&limit=${9999}&category=TRYOUT`
            )
            .then((res) => {
                const { data } = res
                setDataOrder(data)
            })
            .catch((err) => {
                notificationHandler('dev2', err)
                return
            })
    }

    const getInvoiceStatus = async () => {
        await provider
            .singleWebHook(
                `${url.webhookURL_invoice_status}?order_box_id=${orderBoxId}`
            )
            .then((res) => {
                const { data } = res
                setDataInvoice(data)
            })
    }

    const getAllData = async () => {
        setLoadingAll(true)
        await Promise.allSettled([
            getOrderBoxDetail(),
            getInvoiceStatus(),
        ]).then(() => setLoadingAll(false))
    }

    useEffect(() => {
        orderBoxId && getAllData()
    }, [orderBoxId])

    // KONDISI STATUS TRANSAKSI
    let labelStatus, styleStatus, titleStatus, dateStatus
    switch (dataInvoice?.payment_status) {
        case 1:
            labelStatus = translator.t('payment.waiting_payment')
            titleStatus = translator.t('payment.warning')
            dateStatus = dayjs(dataInvoice?.expired_at).format(
                'D MMMM YYYY HH:mm'
            )
            styleStatus = {
                color: COLORS.primaryColor,
            }
            break

        case 3:
            labelStatus = translator.t('payment.finishPayment')
            titleStatus = translator.t('payment.payment_date')
            dateStatus = dayjs(dataInvoice?.created_at).format(
                'D MMMM YYYY HH:mm'
            )
            styleStatus = {
                color: COLORS.primaryColor,
            }
            break

        case 4:
            labelStatus = translator.t('payment.paymentFailed')
            titleStatus = translator.t('payment.payment_date')
            dateStatus = dayjs(dataInvoice?.created_at).format(
                'D MMMM YYYY HH:mm'
            )
            styleStatus = {
                color: COLORS.basered,
            }
            break

        case 5:
            labelStatus = translator.t('payment.cancel_payment')
            titleStatus = translator.t('payment.remaining_payment')
            dateStatus = '00:00:00' + ' ' + translator.t('title.second')
            styleStatus = {
                color: COLORS.basered,
            }
            break

        default:
            break
    }

    // Notes Payment Cash
    const notesPaymentCash = [
        translator.t('message.payment_with_cash'),
        translator.t('message.payment_tips'),
    ]

    return (
        <Row justify="center">
            <Col xs={24} sm={25} md={20} xl={14} className="detailPayment">
                <div className="warning">{titleStatus}</div>
                <div className="time" style={styleStatus}>
                    {dateStatus}
                </div>
                <div className="paymentid">
                    <p>{translator.t('payment.idTransaction')}</p>
                    <span>{dataInvoice?.transaction_code}</span>
                </div>
                <div
                    className="paymentid"
                    style={{
                        marginTop: '-2px',
                    }}
                >
                    <div
                        className="info-title"
                        style={{
                            width: '100%',
                        }}
                    >
                        <h4 className="info-title-label">
                            {translator.t('payment.payment_purpose')}
                        </h4>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '-5px',
                            }}
                        >
                            <div
                                style={{
                                    color: 'rgba(0, 0, 0, 0.85)',
                                    fontWeight: 600,
                                    fontSize: '18px',
                                }}
                            >
                                {dataInvoice?.display_name}
                            </div>
                            <div>
                                <Image
                                    src={dataInvoice?.icon_url}
                                    alt={`${BASE_TITLE} Preview Images`}
                                    preview={false}
                                    className="payment-icon"
                                    width={50}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="status">
                    <p>{translator.t('payment.status')}:</p>
                    <span style={styleStatus}>{labelStatus}</span>
                </div>
                <div className="detail-footer">
                    {dataOrder.map((item, key) => {
                        return (
                            <div key={key}>
                                <div
                                    className="list-order"
                                    style={{
                                        marginBottom: '5px',
                                    }}
                                >
                                    <p>{item?.order_object?.title}</p>
                                    <NumberFormat
                                        value={item.price}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'Rp'}
                                        suffix=",-"
                                    />
                                </div>
                            </div>
                        )
                    })}
                    <div
                        style={{
                            marginTop: '10px',
                        }}
                    >
                        <div
                            className="list-order"
                            style={{
                                marginBottom: '5px',
                            }}
                        >
                            <p> {translator.t('title.total_price')}</p>
                            <NumberFormat
                                value={dataInvoice?.amount}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Rp'}
                                suffix=",-"
                            />
                        </div>
                        <div
                            className="list-order"
                            style={{
                                marginBottom: '5px',
                            }}
                        >
                            <p>{translator.t('payment.discount_amount')}</p>
                            <NumberFormat
                                value={dataInvoice?.discount_amount}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Rp'}
                                suffix=",-"
                            />
                        </div>
                        <div
                            className="list-order"
                            style={{
                                marginBottom: '5px',
                            }}
                        >
                            <p>{translator.t('payment.serviceFee')}</p>
                            <NumberFormat
                                value={dataInvoice?.service_fee_amount}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Rp'}
                                suffix=",-"
                            />
                        </div>
                        <div
                            className="list-order"
                            style={{
                                marginBottom: '5px',
                            }}
                        >
                            <p>{translator.t('payment.taxAmount')}</p>
                            <NumberFormat
                                value={dataInvoice?.tax_amount}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Rp'}
                                suffix=",-"
                            />
                        </div>
                    </div>

                    <div className="count">
                        <p>{translator.t('payment.bill')}</p>
                        {loadingAll ? (
                            <LoadingOutlined />
                        ) : (
                            <NumberFormat
                                value={
                                    dataInvoice?.amount -
                                    dataInvoice?.discount_amount +
                                    dataInvoice?.service_fee_amount +
                                    dataInvoice?.tax_amount
                                }
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Rp'}
                                suffix=",-"
                            />
                        )}
                    </div>
                    {dataInvoice?.payment_status === 1 && (
                        <div
                            style={{
                                backgroundColor: '#F9F9F9',
                                padding: 10,
                                marginTop: 12,
                                border: `1px dashed gray`,
                                borderRadius: 5,
                            }}
                        >
                            <div
                                style={{
                                    fontSize: 13,
                                    color: 'gray',
                                }}
                            >
                                {translator.t('message.note')}:
                            </div>
                            {notesPaymentCash.map((value, key) => {
                                return (
                                    <Paragraph
                                        key={key}
                                        style={{
                                            fontSize: 12,
                                            margin: 0,
                                            textAlign: 'left',
                                            color: 'gray',
                                        }}
                                    >
                                        *{value}
                                    </Paragraph>
                                )
                            })}
                        </div>
                    )}
                    {dataInvoice?.payment_status === 1 && (
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                onClick={() =>
                                    window.location.replace(
                                        dataInvoice?.invoice_url
                                    )
                                }
                                disabled={dataInvoice?.payment_status !== 1}
                            >
                                <img
                                    src={ceklist}
                                    alt="Edoo Website - Platform Edukasi Indonesia"
                                />
                                {translator.t('payment.buy')}
                            </Button>
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    )
}

export default DetailPayment
