import React, { useState, useLayoutEffect } from 'react'
import { Col, Typography, Card, Tooltip } from 'antd'
import { FallbackImage } from 'components'
import { Link, useHistory } from 'react-router-dom'
import moment from 'moment'
import { ic_placeholder_audio, ic_headphone } from 'assets/icons'
import isEmpty from 'lodash/isEmpty'
import { BASE_TITLE } from 'constants/constant'

const { Text } = Typography
const AudioCardCategory = (props) => {
    const { data } = props
    const history = useHistory()
    const {
        media_thumbnail_url,
        media_title,
        created_at,
        // updated_at,
        id,
    } = data

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 420

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    return (
        <div>
            {!isMobile() ? (
                <div className="audio-grid" key={id} data-value={id}>
                    <Card
                        className="audio-card"
                        onClick={() => history.push(`/audio/${id}`)}
                        style={{ cursor: 'pointer' }}
                    >
                        <img
                            className="cover"
                            src={
                                media_thumbnail_url === null
                                    ? ic_placeholder_audio
                                    : media_thumbnail_url
                            }
                            alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                        />
                        <div className="card-overlay card-overlay-dark">
                            <div
                                className="card-overlay-content"
                                style={{ marginTop: isMobile() && '4.5rem' }}
                            >
                                <div
                                    style={{
                                        display:
                                            isEmpty(media_thumbnail_url) &&
                                            'none',
                                    }}
                                    className={
                                        isMobile()
                                            ? 'play-icon-mobile'
                                            : 'play-icon'
                                    }
                                >
                                    <img
                                        className="icon"
                                        src={ic_headphone}
                                        alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                    />
                                </div>
                            </div>
                        </div>
                    </Card>
                    <h2 className="audio-title">
                        <Link style={{ color: 'black' }} to={`/audio/${id}`}>
                            <Text
                                className="au-title"
                                style={{
                                    width: 150,
                                }}
                                ellipsis={true}
                            >
                                <Tooltip
                                    title={media_title}
                                    placement="topLeft"
                                    color="#404040"
                                >
                                    {media_title}
                                </Tooltip>
                            </Text>
                        </Link>
                    </h2>
                    <p className="audio-subtitle">
                        {moment(created_at).format('DD MMMM YYYY')}
                    </p>
                </div>
            ) : (
                <>
                    <Col
                        span={24}
                        className="songRow"
                        onClick={() => history.push(`/audio/${id}`)}
                    >
                        <FallbackImage
                            className="songRow__album"
                            src={
                                media_thumbnail_url === null
                                    ? ic_placeholder_audio
                                    : media_thumbnail_url
                            }
                            alt=""
                        />
                        <div className="songRow__info">
                            <h1>{media_title}</h1>
                            {/* <p>
                            {media_duration === undefined
                                ? ''
                                : formatDuration(media_duration)}
                        </p> */}
                        </div>
                    </Col>
                </>
            )}
        </div>
    )
}

export default AudioCardCategory
