import url from 'constants/url'
import provider from 'providers/provider'
import React, { useEffect, useState, useLayoutEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Button, Col, message, Row, Select } from 'antd'
import { error } from 'utils/notification'
import { AudioCardComponent } from 'views/home/audio/components'
import { SkeletonAudio } from 'components'
import translator from 'i18next'
import { FallbackImage } from 'components'
import Paragraph from 'antd/lib/typography/Paragraph'
import { COLORS } from 'constants/constant'
import { ArrowDownOutlined } from '@ant-design/icons'
import { noimages } from '../../../../../../assets/icons'

const { Option } = Select

const ListAudioEpustaka = (props) => {
    const epustakaId = props?.location?.state?.id
    const dataEpustaka = props?.location?.state
    const [limit, setLimit] = useState(20)
    const [totalData, setTotalData] = useState(0)
    const [loading, setLoading] = useState(false)
    const [audioData, setAudioData] = useState([])
    const [sortingAudio, setSortingAudio] = useState('')

    const defaultPagination = `?limit=${limit}&type=audio&epustaka_id=${epustakaId}&sort=${sortingAudio}`

    const [stateAudio, setStateAudio] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => stateAudio.width <= 420

    const updateDimensions = () => {
        setStateAudio({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getAudioData = async () => {
        setLoading(true)
        await provider
            .list(encodeURI(url.webhookURL_multimedia_list + defaultPagination))
            .then((res) => {
                const { data, meta } = res
                setAudioData(data)
                setTotalData(meta)
                setLoading(data)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
                return
            })
    }

    useEffect(() => {
        getAudioData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, sortingAudio])

    const handleSorting = (e) => {
        setSortingAudio(e === 'latest' ? '-created_at' : 'media_title')
    }

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={loading}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 20)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }

    return (
        <div
            className="pa__container"
            style={{
                marginTop: '-15px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    maxWidth: 900,
                    margin: 'auto',
                }}
            >
                <div
                    style={{
                        color: COLORS.primaryColor,
                        fontWeight: 600,
                        fontSize: 16,
                        padding: '10px',
                        maxWidth: '900px',
                        alignItems: 'center',
                    }}
                >
                    {translator.t('epustka.collectionAudio')}
                </div>
                <Select
                    size="small"
                    bordered={false}
                    placeholder={translator.t('action.sort')}
                    onChange={handleSorting}
                    style={{ alignSelf: 'center' }}
                >
                    <Option value="latest">
                        {translator.t('sort.latest')}
                    </Option>
                    <Option value="abjad">{translator.t('sort.abjad')}</Option>
                </Select>
            </div>
            <Row
                type="flex"
                className="vc_container"
                justify="center"
                style={{ marginTop: '-15px' }}
            >
                {loading && isEmpty(audioData) ? (
                    <SkeletonAudio source="fullskeleton" />
                ) : (
                    <div className="a-container">
                        <Row
                            style={{
                                justifyContent: 'center',
                                maxWidth: 1100,
                                marginTop: 30,
                            }}
                        >
                            {audioData.map((item, id) => {
                                const handleLinkToDetail = () => {
                                    if (
                                        !dataEpustaka?.has_join ||
                                        (dataEpustaka?.has_join &&
                                            dataEpustaka?.member_status !== 1)
                                    ) {
                                        if (dataEpustaka?.member_status === 3) {
                                            message.warning(
                                                translator.t(
                                                    'message.notifExpand'
                                                )
                                            )
                                        } else {
                                            message.warning(
                                                translator.t('message.notif')
                                            )
                                        }
                                    } else {
                                        history.push(
                                            `/audio/${item?.id}/${epustakaId}`
                                        )
                                    }
                                }

                                return (
                                    <div style={{ zIndex: 1 }} key={id}>
                                        {isMobile() ? (
                                            <>
                                                <Col
                                                    span={24}
                                                    className="songRow"
                                                    onClick={() =>
                                                        handleLinkToDetail()
                                                    }
                                                >
                                                    <FallbackImage
                                                        className="songRow__album"
                                                        src={
                                                            item?.media_thumbnail_url ===
                                                            null
                                                                ? noimages
                                                                : item?.media_thumbnail_url
                                                        }
                                                        alt=""
                                                    />
                                                    <div
                                                        className="songRow__info"
                                                        style={{
                                                            textAlign: 'start',
                                                        }}
                                                    >
                                                        <Paragraph
                                                            ellipsis={
                                                                true
                                                                    ? {
                                                                          rows: 2,
                                                                      }
                                                                    : false
                                                            }
                                                            style={{
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {item?.media_title}
                                                        </Paragraph>
                                                    </div>
                                                </Col>
                                            </>
                                        ) : (
                                            <AudioCardComponent
                                                data={item}
                                                epustakaId={epustakaId}
                                                dataEpustaka={dataEpustaka}
                                            />
                                        )}
                                        <div className="loadmore-btn">
                                            <DispalyButton />
                                        </div>
                                    </div>
                                )
                            })}
                        </Row>
                    </div>
                )}
            </Row>
        </div>
    )
}

export default ListAudioEpustaka
