import store from '../store'
import * as ActionType from './contant'

export default {
    setTabActive: (value) => {
        const action = { type: ActionType.SET_TAB_ACTIVE, value }
        store.dispatch(action)
    },
    clearTabActive: () => {
        const action = { type: ActionType.CLEAR_TAB_ACTIVE }
        store.dispatch(action)
    },
}
