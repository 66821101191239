import * as ActionType from './contant'
import ls from 'utils/secureLocalStorage'
import Encryption from 'utils/encryption'

const STORAGE = Encryption.encryptWithMD5('menuApp')
const initState = []

const initializeState = () => {
    const config = ls.get(STORAGE)
    const state = config ? JSON.parse(config) : initState
    return state
}

const reducer = (state = initializeState(), action) => {
    const { type, value } = action

    switch (type) {
        case ActionType.SET_MENU_APPS:
            ls.set(STORAGE, JSON.stringify(value))
            return [...value]

        case ActionType.CLEAR_MENU_APPS:
            return []
        default:
            return state
    }
}

export default reducer
