import React, { useState } from 'react'
import translator from 'i18next'
import { Button, Form, message } from 'antd'
import provider from 'providers/provider'
import url from 'constants/url'
import { error } from 'utils/notification'
import get from 'lodash/get'
import reduxState from '../../../../../redux'
import { FallbackImage } from 'components'
import Paragraph from 'antd/lib/typography/Paragraph'
import { BASE_TITLE, COLORS } from 'constants/constant'

const ModalJoinEpustaka = ({ data = {}, setVisibleModal, getEpustaka }) => {
    const epustakaId = data.id
    const [form] = Form.useForm()
    const { profile = {} } = reduxState.getState()
    const idProfile = get(profile, 'id')
    const [loading, setLoading] = useState(false)

    const handleJoinEpustaka = async () => {
        setLoading(true)
        const data = {
            epustaka_id: epustakaId,
            user_ids: [idProfile],
        }
        await provider
            .insert(url.webhookURL_join_epustaka_member, data)
            .then(() => {
                getEpustaka()
                // window.location.replace(`/epustaka/${epustakaId}`)
                setVisibleModal()
                message.success(translator.t('epustka.notif'))
                setLoading(false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    return (
        <>
            <Form form={form} onFinish={handleJoinEpustaka}>
                <Form.Item>
                    <div className="mj__epustakaTitle">
                        {translator.t('title.joinEpustaka')}
                    </div>

                    <FallbackImage
                        src={data?.epustaka_logo}
                        type="square"
                        alt={`${BASE_TITLE} Epustaka - Platform Perpustakaan Indonesia`}
                        className="mj__epustakaImg"
                    />

                    <div className="mj__epustakaName">
                        {data?.epustaka_name}
                    </div>
                    <div className="mj__epustakaSubName">
                        {translator.t(
                            !get(data, 'epustaka_isprivate', false)
                                ? 'filter.ePublic'
                                : 'filter.ePrivate'
                        )}
                    </div>
                    <span className="mj__epustakaAbout">
                        <Paragraph
                            ellipsis={
                                true
                                    ? {
                                          rows: 2,
                                          expandable: true,
                                          symbol: (
                                              <span
                                                  style={{
                                                      color: COLORS.primaryColor,
                                                  }}
                                              >
                                                  {translator.t(
                                                      'action.showMore'
                                                  )}
                                              </span>
                                          ),
                                      }
                                    : false
                            }
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: data.epustaka_about,
                                }}
                            />
                            {/* {data.epustaka_about} */}
                        </Paragraph>
                    </span>
                    {data?.member_status === 3 ? (
                        <p>{translator.t('epustka.subtitleExpired')}</p>
                    ) : (
                        <p>{translator.t('epustka.subtitle')}</p>
                    )}

                    <Button
                        className="mj__epustakaBtn"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                    >
                        {data?.member_status === 3
                            ? translator.t('title.extend')
                            : translator.t('title.joinEpustaka')}
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default ModalJoinEpustaka
