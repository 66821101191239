import { notification } from 'antd'

const MESSAGE_DURATION = 3

const triggerNotification = (
    type,
    props,
    description = '[ no description ]',
    message = 'Info',
    duration = MESSAGE_DURATION
) => notification[type]({ description, message, duration, ...props })

const error = (props) => {
    return (
        props?.description !== 'Request failed with status code 401' &&
        triggerNotification('error', props)
    )
}
const info = (props) => triggerNotification('info', props)
const success = (props) => triggerNotification('success', props)
const warning = (props) => triggerNotification('warning', props)

export { error, info, success, warning }
