import React, { useState, useLayoutEffect } from 'react'
import './Post.scss'
import { illus_emptyfeeds } from '../../../assets/icons'
import { Image } from 'antd'

const ActivityPost = ({ loading, id }) => {
    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 420

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])
    return (
        <>
            {loading ? (
                'loading..'
            ) : (
                <div
                    className="post"
                    key={id}
                    style={{
                        textAlign: 'center',
                        padding: '5em',
                    }}
                >
                    <Image
                        style={{
                            width: !isMobile() ? '19rem' : '100%',
                            height: !isMobile() ? '24rem' : '',
                        }}
                        src={illus_emptyfeeds}
                        alt="no_activity"
                        preview={false}
                    />
                    <div
                        style={{
                            fontSize: isMobile() ? '13pt' : '15pt',
                            fontWeight: 600,
                            lineHeight: isMobile() ? '2rem' : '3rem',
                        }}
                    >
                        Oops... Belum Terdapat Aktifitas
                    </div>
                    <div
                        style={{
                            fontSize: isMobile() ? '8pt' : '12pt',
                            // paddingBottom: '4rem',
                        }}
                    >
                        Silahkan lakukan interaksi dengan mengakses koleksi kami
                        mulai dari baca buku hingga berinteraksi menjalin
                        pertemanan.
                    </div>
                </div>
            )}
        </>
    )
}

export default ActivityPost
