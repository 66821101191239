import React, { useEffect, useState } from 'react'
import translator from 'i18next'
import get from 'lodash/get'
import isNull from 'lodash/isNull'
import moment from 'moment'
import { useHistory, useParams } from 'react-router-dom'
import { Card, Col, Row, Tabs, Skeleton, Image, message, List } from 'antd'
import { isEmpty } from 'lodash'
import provider from 'providers/provider'
import url from 'constants/url'
import { Helmet } from 'react-helmet'
import { BASE_TITLE } from 'constants/constant'
import capitalize from 'lodash/capitalize'

const { TabPane } = Tabs

const VideoDetail = () => {
    const history = useHistory()
    const params = useParams()
    const videoId = params?.uuid

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})

    const getData = async () => {
        setLoading(true)
        await provider
            .singleWebHook(
                `${url.webhookURL_public_multimedia_detail}?media_id=${videoId}`
            )
            .then((res) => {
                setData(res?.data)
            })
            .catch(() => {
                message.warning(translator.t('notification.item_doesnt_exist'))
                history.replace('/')
            })
        setLoading(false)
    }

    useEffect(() => {
        if (videoId) getData()
    }, [videoId])

    const dataDescription = [
        {
            title: data.media_title,
            description: (
                <span>Di Unggah {moment(data.created_at).format('ll')}</span>
            ),
        },
    ]

    return (
        // detail video container
        <div className="dv-body-container">
            <Helmet>
                <title>
                    {capitalize(data.media_title)} - {BASE_TITLE} Website
                </title>
                <meta property="og:image" content={data.media_thumbnail_url} />
                <meta
                    name="og:title"
                    content={`${BASE_TITLE} Website - Platform Edukasi Indonesia`}
                />
                <meta
                    name="description"
                    content="Video Page - Mari bergabung dan berkolaborasi mengembangkan pendidikan Indonesia bersama anak bangsa."
                />
            </Helmet>
            <Row
                type="flex"
                style={{ alignItems: 'center', marginTop: '2rem' }}
                justify="center"
            >
                <Col
                    xs={24}
                    sm={24}
                    md={20}
                    lg={15}
                    xl={15}
                    className="dv-container"
                >
                    <Card
                        className="detail-card"
                        title={translator.t('title.video_detail')}
                    >
                        <div
                            className={
                                data?.media_extension !== 'youtube' &&
                                'video-wrapper'
                            }
                        >
                            {/* ReactPlayer Section */}
                            <Row
                                type="flex"
                                style={{ alignItems: 'center' }}
                                justify="center"
                                className={'video-visible'}
                            >
                                <Col span={24}>
                                    <div
                                        className={
                                            loading
                                                ? 'test'
                                                : 'content-private-video'
                                        }
                                    >
                                        {loading ? (
                                            <Skeleton.Input
                                                style={{
                                                    width: '100%',
                                                    paddingTop: '56.25%',
                                                }}
                                                active={true}
                                                size="large"
                                            />
                                        ) : (
                                            <Image
                                                preview={false}
                                                src={data?.media_thumbnail_url}
                                                width="100%"
                                            />
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div
                            className={
                                data?.media_extension !== 'youtube' &&
                                'description-wrapper'
                            }
                        >
                            {/* Share Section */}
                            <Row>
                                <Col span={24} className="vd-description">
                                    <List
                                        className="vd-description-title"
                                        itemLayout="horizontal"
                                        dataSource={dataDescription}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    // avatar={item.avatar}
                                                    title={
                                                        <div className="vd-title">
                                                            {loading ? (
                                                                <Skeleton.Input
                                                                    style={{
                                                                        width: 210,
                                                                    }}
                                                                    active={
                                                                        true
                                                                    }
                                                                    size="small"
                                                                />
                                                            ) : (
                                                                item.title
                                                            )}
                                                        </div>
                                                    }
                                                    description={
                                                        <div className="vd-subtitle">
                                                            {loading ? (
                                                                <Skeleton.Input
                                                                    style={{
                                                                        width: 150,
                                                                        height: 15,
                                                                    }}
                                                                    active={
                                                                        true
                                                                    }
                                                                    size="small"
                                                                />
                                                            ) : (
                                                                item.description
                                                            )}
                                                        </div>
                                                    }
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            </Row>
                            {/* Description Section */}
                            <Row
                                className="description-video-tabs"
                                style={{ borderTop: '1px solid #eff2f5' }}
                            >
                                <Col className="tab" span={24}>
                                    <Tabs defaultActiveKey="1" centered>
                                        <TabPane
                                            tab={translator.t(
                                                'title.description'
                                            )}
                                            className="tab-info"
                                            key="1"
                                        >
                                            {loading ? (
                                                <React.Fragment>
                                                    <Skeleton.Input
                                                        style={{
                                                            width: 200,
                                                            height: 15,
                                                        }}
                                                        active={true}
                                                        size="small"
                                                    />
                                                    <Skeleton.Input
                                                        style={{
                                                            width: 250,
                                                            height: 15,
                                                        }}
                                                        active={true}
                                                        size="small"
                                                    />
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    {!isEmpty(
                                                        data.media_description
                                                    ) && (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: data.media_description,
                                                            }}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </TabPane>
                                        <TabPane
                                            tab={translator.t('title.detail')}
                                            key="2"
                                        >
                                            <table className="table-detail-video">
                                                <tbody>
                                                    <tr>
                                                        <th className="table-title">
                                                            {translator.t(
                                                                'filter.category'
                                                            )}
                                                        </th>
                                                        <th className="table-title-value active-color">
                                                            {isNull(
                                                                get(
                                                                    data,
                                                                    'category_name',
                                                                    '-'
                                                                )
                                                            )
                                                                ? '-'
                                                                : get(
                                                                      data,
                                                                      'category_name',
                                                                      '-'
                                                                  )}
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th className="table-title">
                                                            {translator.t(
                                                                'filter.published_by'
                                                            )}
                                                        </th>
                                                        <th className="table-title-value">
                                                            -
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th className="table-title">
                                                            {translator.t(
                                                                'filter.year'
                                                            )}
                                                        </th>
                                                        <th className="table-title-value active-color">
                                                            {moment(
                                                                get(
                                                                    data,
                                                                    'created_at',
                                                                    '-'
                                                                )
                                                            ).format('LLLL')}
                                                        </th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </TabPane>
                                    </Tabs>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default VideoDetail
