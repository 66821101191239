import React from 'react'
import { Col, Row, Skeleton, Space } from 'antd'

export default function SkeletonVideo(props) {
    const source = props.source
    if (source === 'fullskeleton') {
        return (
            <>
                <Row
                    style={{
                        marginTop: 30,
                        textAlign: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Space size={[20, 20]} wrap>
                        <Col xs={24} sm={24} md={16} lg={12} xl={12}>
                            <Skeleton.Input
                                style={{
                                    width: 410,
                                    height: 200,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{
                                        width: 410,
                                        height: 20,
                                    }}
                                    active
                                />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Skeleton.Input
                                style={{
                                    width: 410,
                                    height: 200,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{
                                        width: 410,
                                        height: 20,
                                    }}
                                    active
                                />
                            </div>
                        </Col>
                    </Space>
                </Row>
                <Row style={{ marginTop: 30, textAlign: 'center' }}>
                    <Space size={[20, 20]} wrap>
                        <Col xs={24} sm={24} md={16} lg={12} xl={12}>
                            <Skeleton.Input
                                style={{
                                    width: 410,
                                    height: 200,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{
                                        width: 410,
                                        height: 20,
                                    }}
                                    active
                                />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Skeleton.Input
                                style={{
                                    width: 410,
                                    height: 200,
                                }}
                                active
                            />
                            <div style={{ marginTop: 10 }}>
                                <Skeleton.Input
                                    style={{
                                        width: 410,
                                        height: 20,
                                    }}
                                    active
                                />
                            </div>
                        </Col>
                    </Space>
                </Row>
            </>
        )
    }
    return (
        <Row style={{ marginTop: 30 }}>
            <Col xs={24} sm={24} md={16} lg={12} xl={12}>
                <Skeleton.Input style={{ width: 430, height: 300 }} active />
                <div style={{ marginTop: 10 }}>
                    <Skeleton.Input style={{ width: 350, height: 20 }} active />
                </div>
            </Col>
            <Col xs={24} sm={24} md={16} lg={12} xl={12}>
                <Skeleton.Input style={{ width: 430, height: 300 }} active />
                <div style={{ marginTop: 10 }}>
                    <Skeleton.Input style={{ width: 350, height: 20 }} active />
                </div>
            </Col>
        </Row>
    )
}
