import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Button, Card, Col, Row, Skeleton, Typography } from 'antd'
import Avatar from 'antd/lib/avatar/avatar'
import InfiniteScroll from 'react-infinite-scroll-component'
import getAcronym from 'components/Acronym'
import { useHistory } from 'react-router'
import translator from 'i18next'
import { COLORS } from 'constants/constant'

const { Text } = Typography

const SearchUser = (props) => {
    const history = useHistory()
    const {
        empty,
        search,
        fetchMoreData,
        state,
        searchResult,
        loadingData,
        userId,
        // setActionFollow,
        handleFollow,
        stateFollow,
        loadMore,
    } = props
    return (
        <div className="search-tab">
            {empty ? (
                <div style={{ textAlign: 'center' }}>
                    <div>
                        <p>........</p>
                        <b>{translator.t('message.filter_not_matches')}</b>
                    </div>
                </div>
            ) : (
                <div>
                    <Row
                        justify="center"
                        className="title-result"
                        style={{
                            marginBottom: 10,
                            marginTop: -5,
                        }}
                    >
                        <Col span={24}>
                            {search && (
                                <span>
                                    {translator.t('column.search')}
                                    <b>{`"${search}"`}</b>
                                </span>
                            )}
                        </Col>
                    </Row>
                    <div>
                        <InfiniteScroll
                            dataLength={searchResult.length}
                            next={fetchMoreData}
                            style={{ overflow: 'hidden' }}
                            hasMore={state.hasMore}
                            loader={
                                <div
                                    style={{
                                        textAlign: 'center',
                                        marginTop: 20,
                                        height: 32,
                                        lineHeight: '32px',
                                    }}
                                >
                                    <LoadingOutlined
                                        style={{
                                            color: COLORS.primaryColor,
                                            fontSize: 25,
                                        }}
                                    />
                                </div>
                            }
                        >
                            <span />
                            {searchResult.map((item, key) => {
                                const { followed, name, id } = item

                                const { color, acronym } = getAcronym(
                                    name || '',
                                    id
                                )
                                return (
                                    <Row
                                        type="flex"
                                        style={{
                                            alignItems: 'center',
                                        }}
                                        justify="center"
                                        key={key}
                                    >
                                        <Col span={24}>
                                            <Card className="user-list-container">
                                                <Row>
                                                    <Col span={19}>
                                                        <div
                                                            style={{
                                                                cursor: 'pointer',
                                                            }}
                                                            className="search-list"
                                                            onClick={() =>
                                                                history.push({
                                                                    pathname: `/profil/user/${item.id}`,
                                                                    state: item.id,
                                                                })
                                                            }
                                                        >
                                                            {loadingData ? (
                                                                <Skeleton.Avatar
                                                                    size="large"
                                                                    shape="circle"
                                                                />
                                                            ) : (
                                                                <Avatar
                                                                    size="large"
                                                                    style={{
                                                                        backgroundImage:
                                                                            color,
                                                                        display:
                                                                            'inline-flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                    src={
                                                                        item.avatar_url
                                                                    }
                                                                >
                                                                    <span>
                                                                        {
                                                                            acronym
                                                                        }
                                                                    </span>
                                                                </Avatar>
                                                            )}

                                                            <div className="media-title-user">
                                                                {loadingData ? (
                                                                    <Skeleton.Input
                                                                        style={{
                                                                            width: 200,
                                                                            height: 20,
                                                                        }}
                                                                        size="default"
                                                                    />
                                                                ) : (
                                                                    <Text className="title">
                                                                        {item.name ===
                                                                        null
                                                                            ? 'Unknown'
                                                                            : item.name}
                                                                    </Text>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col span={5}>
                                                        <div className="btn-follow">
                                                            {userId !==
                                                                item.id && (
                                                                <Button
                                                                    type={
                                                                        followed
                                                                            ? 'default'
                                                                            : 'primary'
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e?.currentTarget?.blur()
                                                                        // setActionFollow(
                                                                        //     true
                                                                        // )
                                                                        handleFollow(
                                                                            item,
                                                                            key,
                                                                            followed
                                                                        )
                                                                    }}
                                                                    size="middle"
                                                                    style={{
                                                                        width: 150,
                                                                    }}
                                                                    loading={
                                                                        stateFollow
                                                                            .loadings[
                                                                            key
                                                                        ]
                                                                    }
                                                                >
                                                                    {followed ? (
                                                                        <span
                                                                            style={{
                                                                                fontSize: 12,
                                                                                marginLeft:
                                                                                    -9,
                                                                            }}
                                                                        >
                                                                            {translator.t(
                                                                                'action.cancel_follow'
                                                                            )}
                                                                        </span>
                                                                    ) : (
                                                                        <span>
                                                                            {translator.t(
                                                                                'action.follow'
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    </Row>
                                )
                            })}
                            {loadMore}
                        </InfiniteScroll>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SearchUser
