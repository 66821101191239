import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import translator from 'i18next'
import { Col, Dropdown, Menu, Row, Typography } from 'antd'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Lazy } from 'swiper/core'
import { BASE_TITLE } from 'constants/constant'
import ModalReturnBook from 'views/bookshelf/components/ModalReturnBook'
import { MoreOutlined } from '@ant-design/icons'
import reduxState from '../../../../redux'
import { useHistory } from 'react-router-dom'

SwiperCore.use([Pagination, Lazy])

const { Paragraph } = Typography
const NewsHistory = (props) => {
    const { userId } = props

    const router = useHistory()
    const data = props?.data
    const getMedia = props?.getMedia
    const { profile = {} } = reduxState.getState()
    const [visibleDelete, setVisibleDelete] = useState(false)
    const [mediaItem, setMediaItem] = useState({})

    const handleClickDelete = (value) => {
        setMediaItem(value)
        setVisibleDelete(true)
    }

    const MenuComponent = (item) => {
        return (
            <Menu className="share-menu">
                <Menu.ItemGroup>
                    <Menu.Item key="1" onClick={() => handleClickDelete(item)}>
                        {translator.t('feed.delete')}
                    </Menu.Item>
                </Menu.ItemGroup>
            </Menu>
        )
    }

    return (
        <div style={{ marginBottom: 20 }}>
            <Row className="nh-container">
                <Col>
                    <h1 className="title" style={{ marginBottom: 0 }}>
                        {translator.t('bookshelf.news_history')}
                    </h1>
                </Col>

                <Col>
                    {/* {data?.length > 2 && ( */}
                    <div
                        onClick={() =>
                            router.push({
                                pathname: '/more-article-history',
                                state: userId,
                            })
                        }
                    >
                        <button className="link">
                            {translator.t('action.fully')}
                        </button>
                    </div>
                    {/* )} */}
                </Col>
            </Row>
            <div>
                <Swiper
                    slidesPerView={1.2}
                    spaceBetween={20}
                    preloadImages={false}
                    lazy={true}
                    pagination={{
                        el: '.swiper-pagination',
                        clickable: true,
                    }}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        360: {
                            slidesPerView: 1,
                        },
                        411: {
                            slidesPerView: 1,
                        },
                        420: {
                            slidesPerView: 1,
                        },
                        540: {
                            slidesPerView: 1.2,
                        },
                        640: {
                            slidesPerView: 1.5,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        884: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 2,
                        },
                    }}
                    // classNameName="swiper_container"
                >
                    {data?.map((item, key) => {
                        return (
                            <SwiperSlide key={key}>
                                <div className="grid">
                                    {userId === profile?.id && (
                                        <Dropdown
                                            overlay={() => MenuComponent(item)}
                                            className="dropdownMore"
                                        >
                                            <span>
                                                <MoreOutlined
                                                    rotate={90}
                                                    style={{
                                                        color: 'white',
                                                        width: 25,
                                                        fontSize: 22,
                                                    }}
                                                />
                                            </span>
                                        </Dropdown>
                                    )}

                                    <figure className="effect-apollo">
                                        <img
                                            src={item?.news_thumbnail_url}
                                            alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                        />
                                        <figcaption>
                                            <Paragraph
                                                ellipsis={
                                                    true
                                                        ? {
                                                              rows: 2,
                                                              expandable: false,
                                                          }
                                                        : false
                                                }
                                                className="title-h2"
                                                style={{
                                                    color: 'white',
                                                }}
                                            >
                                                {item?.news_title}
                                            </Paragraph>
                                            <Link
                                                to={{
                                                    pathname: `/article/${item?.collection_id}/${item.epustaka_id}`,
                                                }}
                                            >
                                                View more
                                            </Link>
                                        </figcaption>
                                    </figure>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>

            <ModalReturnBook
                visibleModal={visibleDelete}
                setVisibleModal={setVisibleDelete}
                mediaData={mediaItem}
                getMedia={getMedia}
                frombookself="frombookself"
            />
        </div>
    )
}

export default NewsHistory
