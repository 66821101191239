import React, { useEffect, useState, useLayoutEffect } from 'react'
import { Row, Button, Rate, Typography } from 'antd'
import provider from 'providers/provider'
import url from 'constants/url'
import { FallbackImage, SkeletonBook } from 'components'
import translator from 'i18next'
import { useHistory } from 'react-router-dom'
import ModalReviewBook from '../components/ModalReviewBook'
import { error } from 'utils/notification'
import Text from 'antd/lib/typography/Text'
import { BASE_TITLE } from 'constants/constant'
import { ArrowDownOutlined } from '@ant-design/icons'

const { Paragraph } = Typography

const MoreBookReview = () => {
    const history = useHistory()
    const [visibleModalReview, setVisibleModalReview] = useState(false)
    const [itemBook, setItemBook] = useState({})
    const [totalData, setTotalData] = useState(0)
    const [limit, setLimit] = useState(25)
    const [loading, setLoading] = useState(false)
    const [bookData, setBookData] = useState([])

    const [stateBook, setStateBook] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => stateBook.width <= 420

    const updateDimensions = () => {
        setStateBook({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getDataBook = async () => {
        setLoading(true)
        await provider
            .list(url.webhookUrl_review_shelf + `?limit=${limit}`)
            .then((res) => {
                const { data, meta } = res
                setBookData(data)
                setLoading(false)
                setTotalData(meta.total)
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
                setLoading(false)
            })
    }

    useEffect(() => {
        getDataBook()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit])

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={loading}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 25)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }

    return (
        <div
            className="pb__container"
            style={{ backgroundColor: 'white', marginTop: '-15px' }}
        >
            {loading ? (
                <SkeletonBook />
            ) : (
                <div
                    style={{
                        maxWidth: 1000,
                        margin: '0 auto',
                        padding: '0 10px',
                    }}
                >
                    <div
                        className="pb__containerLink"
                        style={{
                            marginBottom: '20px',
                            marginLeft: -10,
                        }}
                    >
                        {translator.t('filter.review')}
                    </div>
                    {bookData.map((book, key) => {
                        const {
                            id,
                            cover_url,
                            book_title,
                            rating_comment,
                            rating_level,
                            has_rating,
                            book_author,
                        } = book

                        return (
                            <Row key={key}>
                                <div
                                    className="pb__containerGrid"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: 20,
                                    }}
                                >
                                    <div className="container-book">
                                        <div className="book">
                                            <div className="front">
                                                <div className="cover">
                                                    <FallbackImage
                                                        alt={`${BASE_TITLE} preview images - platform perpustakaan indonesia`}
                                                        src={cover_url}
                                                        onClick={() =>
                                                            history.push(
                                                                `/book/${id}`
                                                            )
                                                        }
                                                        style={{
                                                            cursor: 'pointer',
                                                            width: isMobile()
                                                                ? 153
                                                                : 141,
                                                            height: isMobile()
                                                                ? 220
                                                                : 190,
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="left-side">
                                                <h2
                                                    style={{
                                                        height: 20,
                                                        width: isMobile()
                                                            ? 220
                                                            : 190,
                                                        marginLeft: -15,
                                                        backgroundImage: `url(${cover_url})`,
                                                    }}
                                                >
                                                    <span>
                                                        <Text
                                                            style={{
                                                                width: 155,
                                                                fontSize: 10,
                                                                color: 'white',
                                                                paddingRight: 10,
                                                            }}
                                                            ellipsis={true}
                                                        >
                                                            {book_title}
                                                        </Text>
                                                    </span>
                                                </h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="review"
                                        style={{ marginLeft: 30 }}
                                    >
                                        <Paragraph
                                            style={{
                                                fontSize: '1rem',
                                                fontWeight: 500,
                                                marginBottom: 0,
                                            }}
                                            ellipsis={
                                                true
                                                    ? {
                                                          rows: 2,
                                                          expandable: false,
                                                      }
                                                    : false
                                            }
                                        >
                                            {book_title === null
                                                ? ''
                                                : book_title}
                                        </Paragraph>
                                        <Paragraph
                                            style={{
                                                fontSize: '0.9rem',
                                                marginBottom: 0,
                                            }}
                                            ellipsis={
                                                true
                                                    ? {
                                                          rows: 2,
                                                          expandable: false,
                                                      }
                                                    : false
                                            }
                                        >
                                            {book_author === null
                                                ? ''
                                                : book_author}
                                        </Paragraph>

                                        <div className="review__rating">
                                            <Rate
                                                disabled
                                                value={rating_level}
                                            />{' '}
                                            <span>{rating_level}</span>
                                        </div>
                                        <Button
                                            onClick={() => {
                                                setVisibleModalReview(true)
                                                setItemBook(book)
                                            }}
                                        >
                                            {has_rating === false
                                                ? 'Berikan Ulasan'
                                                : 'Ubah Ulasan'}
                                        </Button>
                                        <p>{rating_comment}</p>
                                    </div>
                                </div>
                            </Row>
                        )
                    })}
                    <div className="loadmore-btn">
                        <DispalyButton />
                    </div>
                </div>
            )}

            <ModalReviewBook
                visibleModal={visibleModalReview}
                setVisibleModal={setVisibleModalReview}
                itemBook={itemBook}
                getDataBook={getDataBook}
            />
        </div>
    )
}

export default MoreBookReview
