import { Avatar, Button, Col, Modal, Row } from 'antd'
import React, { useState, useLayoutEffect } from 'react'
import ls from 'utils/secureLocalStorage'
import encryption from 'utils/encryption'
import { BASE_TITLE } from 'constants/constant'
import reduxState from '../redux'

const ModalPopup = (props) => {
    const { popupModal, setPopupModal } = props
    const { profile = {} } = reduxState.getState()
    const { greeting = {} } = profile

    const [state, setState] = useState({
        lang: 'id',
        collapsed: false,
        width: window.innerWidth,
    })
    const isMobile = () => state.width <= 360
    const xlMobile = () => state.width <= 420

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const closeModalPopup = () => {
        ls.set(encryption.encryptWithMD5('popupModal'), false)
        setPopupModal(false)
    }

    return (
        <div onKeyPress={closeModalPopup}>
            <Modal
                centered={isMobile() ? false : true}
                closeIcon={true}
                visible={popupModal}
                onOk={closeModalPopup}
                onCancel={closeModalPopup}
                footer={null}
                className="modal-popup"
                width={isMobile() ? '100%' : xlMobile() ? 450 : 450}
                height={isMobile() && 500}
            >
                <Row justify="center">
                    <Col
                        className="mt-lg"
                        span={20}
                        style={{ textAlign: 'center' }}
                    >
                        <Avatar
                            size={150}
                            src={profile?.organizations?.logo_url}
                            alt={`${BASE_TITLE} Preview Images`}
                            style={{
                                marginTop: isMobile() ? '-100px' : '-120px',
                                marginBottom: 20,
                                borderRadius: 20,
                            }}
                        />

                        <div>
                            <h1>{greeting?.greeting_title}</h1>
                            <div style={{ fontSize: '0.98rem' }}>
                                {greeting?.greeting_message ? (
                                    greeting?.greeting_message
                                ) : (
                                    <>
                                        Selamat datang dilayanan {BASE_TITLE}.
                                        {'\n'}
                                        Nikmati koleksi menarik sekarang juga :)
                                    </>
                                )}
                            </div>
                            <Button
                                onClick={closeModalPopup}
                                className="btn-popup-modal"
                                size="large"
                                style={{
                                    margin: 0,
                                    position: 'relative',
                                    top: 42,
                                }}
                            >
                                Tutup
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default ModalPopup
