import React from 'react'
import { Row, Col } from 'antd'
import EpustakaBookCategory from './EpustakaBookCategory'
import { useLocation } from 'react-router-dom'

export default function EpustakaBook(props) {
    const id = props.location.state.epustakaId

    const data = useLocation()
    const dataEpustaka = data?.state?.dataEpustaka

    return (
        <>
            <Row
                type="flex"
                style={{
                    alignItems: 'center',
                }}
                justify="center"
            >
                <Col xs={24} sm={19} md={24} lg={22} xl={22}>
                    <EpustakaBookCategory id={id} dataEpustaka={dataEpustaka} />
                </Col>
            </Row>
        </>
    )
}
