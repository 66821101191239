import {
    Button,
    Checkbox,
    Switch,
    Col,
    Form,
    Modal,
    Row,
    Space,
    List,
    Spin,
    Input,
    Collapse,
    Image,
} from 'antd'
import React, { useLayoutEffect, useState } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import translator from 'i18next'
import isEmpty from 'lodash/isEmpty'
import { ic_category, ic_author, ic_years } from 'assets/icons'
import { connect } from 'react-redux'
import switchAction from 'redux/bookFilterSwitch/action'

const mapStateToProps = (state) => ({
    switchActive: state.switchActive,
})

const { Panel } = Collapse

const ModalFIlterSorting = (props) => {
    const {
        visibleModal,
        setVisibleModal,
        handleFinish,
        form,
        totalData,
        reseltFilter,
        hasFilter,
        fromHome,
        switchActive,
        handleSearch,
        onClearSearch,
        dataCategories,
        categoryPagination,
        hasSearch,
        loadMoreCategory,
        loading,
        dataOptionAuthor,
        hasMoreAuthor,
        loadingAuthor,
        loadMoreAuthor,
        dataOptionYear,
    } = props

    const [state, setState] = useState({
        width: window.innerWidth,
    })
    const isMobile = () => state.width <= 576

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    return (
        <>
            {isMobile() ? (
                <Modal
                    title="Filter"
                    style={{ top: 20 }}
                    bodyStyle={{
                        padding: '5px 5px 20px 5px',
                        background: '#f0f2f5',
                        borderRadius: 10,
                    }}
                    visible={visibleModal}
                    onCancel={() => setVisibleModal(false)}
                    className="modal-filter"
                    footer={null}
                >
                    <>
                        <Collapse
                            accordion
                            expandIconPosition="right"
                            style={{ marginBottom: 15 }}
                            bordered={false}
                        >
                            <Panel
                                header={
                                    <div
                                        style={{
                                            display: 'flex',
                                            // alignItems: 'center',
                                        }}
                                    >
                                        <Image
                                            src={ic_category}
                                            preview={false}
                                            width={30}
                                            alt
                                        />

                                        <div style={{ marginLeft: 10 }}>
                                            <div style={{ fontWeight: 500 }}>
                                                {translator.t(
                                                    'filter.category'
                                                )}
                                            </div>
                                            <div style={{ fontSize: 13 }}>
                                                {translator.t(
                                                    'action.searchGenre'
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                }
                                key="1"
                            >
                                <Input
                                    placeholder={`${translator.t(
                                        'action.find'
                                    )}...`}
                                    onPressEnter={(e) =>
                                        handleSearch('category', e)
                                    }
                                    suffix={<SearchOutlined />}
                                    allowClear
                                    onChange={(e) =>
                                        onClearSearch('category', e)
                                    }
                                    size="large"
                                />

                                <Form onFinish={handleFinish} form={form}>
                                    <Col span={24} style={{ marginTop: 5 }}>
                                        <Form.Item name="category_id">
                                            <Checkbox.Group className="checkbox-group">
                                                <List
                                                    bordered
                                                    dataSource={
                                                        dataCategories?.list
                                                    }
                                                    renderItem={(item, key) => (
                                                        <List.Item key={key}>
                                                            <Checkbox
                                                                value={item?.id}
                                                            >
                                                                {
                                                                    item?.category_name
                                                                }
                                                            </Checkbox>
                                                        </List.Item>
                                                    )}
                                                    footer={
                                                        categoryPagination?.loadMore &&
                                                        !isEmpty(
                                                            dataCategories?.list
                                                        ) &&
                                                        dataCategories?.total >
                                                            dataCategories?.list
                                                                ?.length ? (
                                                            <div
                                                                style={{
                                                                    display:
                                                                        !hasSearch &&
                                                                        'none',
                                                                }}
                                                            >
                                                                <Button
                                                                    type="primary"
                                                                    onClick={
                                                                        loadMoreCategory
                                                                    }
                                                                    loading={
                                                                        loading
                                                                    }
                                                                >
                                                                    Load More
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )
                                                    }
                                                    className="list-scroll"
                                                    loadMore
                                                ></List>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </Col>
                                </Form>
                            </Panel>
                            <Panel
                                header={
                                    <div
                                        style={{
                                            display: 'flex',
                                            // alignItems: 'center',
                                        }}
                                    >
                                        <Image
                                            src={ic_author}
                                            preview={false}
                                            width={30}
                                            alt
                                        />

                                        <div style={{ marginLeft: 10 }}>
                                            <div style={{ fontWeight: 500 }}>
                                                {translator.t('filter.author')}
                                            </div>
                                            <div style={{ fontSize: 13 }}>
                                                {translator.t(
                                                    'action.searchAuthor'
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                }
                                key="2"
                            >
                                <Input
                                    placeholder={`${translator.t(
                                        'action.find'
                                    )}...`}
                                    onPressEnter={(e) =>
                                        handleSearch('author', e)
                                    }
                                    suffix={<SearchOutlined />}
                                    allowClear
                                    onChange={(e) => onClearSearch('author', e)}
                                    size="large"
                                />
                                <Form onFinish={handleFinish} form={form}>
                                    <Col span={24} style={{ marginTop: 5 }}>
                                        <Form.Item name="author_name">
                                            <Checkbox.Group className="checkbox-group">
                                                <List
                                                    bordered
                                                    dataSource={
                                                        dataOptionAuthor
                                                    }
                                                    renderItem={(item, key) => (
                                                        <List.Item key={key}>
                                                            <Checkbox
                                                                value={
                                                                    item.text
                                                                }
                                                            >
                                                                {item.text}
                                                            </Checkbox>
                                                        </List.Item>
                                                    )}
                                                    footer={
                                                        hasMoreAuthor &&
                                                        !loadingAuthor &&
                                                        !isEmpty(
                                                            dataOptionAuthor
                                                        ) ? (
                                                            <div>
                                                                <Button
                                                                    type="primary"
                                                                    onClick={
                                                                        loadMoreAuthor
                                                                    }
                                                                >
                                                                    Load More
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )
                                                    }
                                                    className="list-scroll"
                                                    loadMore
                                                >
                                                    {loadingAuthor && (
                                                        <div className="demo-loading-container">
                                                            <Spin />
                                                        </div>
                                                    )}
                                                </List>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </Col>
                                </Form>
                            </Panel>
                            <Panel
                                header={
                                    <div
                                        style={{
                                            display: 'flex',
                                            // alignItems: 'center',
                                        }}
                                    >
                                        <Image
                                            src={ic_years}
                                            preview={false}
                                            width={30}
                                            alt
                                        />

                                        <div style={{ marginLeft: 10 }}>
                                            <div style={{ fontWeight: 500 }}>
                                                {translator.t('filter.year')}
                                            </div>
                                            <div style={{ fontSize: 13 }}>
                                                {translator.t(
                                                    'action.searchYear'
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                }
                                key="3"
                            >
                                <Input
                                    placeholder={`${translator.t(
                                        'action.find'
                                    )}...`}
                                    onPressEnter={(e) =>
                                        handleSearch('year', e)
                                    }
                                    suffix={<SearchOutlined />}
                                    allowClear
                                    onChange={(e) => onClearSearch('year', e)}
                                    size="large"
                                />

                                <Form onFinish={handleFinish} form={form}>
                                    <Col span={24} style={{ marginTop: 5 }}>
                                        <Form.Item name="catalog_publish_date">
                                            <Checkbox.Group className="checkbox-group">
                                                <List
                                                    bordered
                                                    dataSource={dataOptionYear}
                                                    renderItem={(item, key) => (
                                                        <List.Item key={key}>
                                                            <Checkbox
                                                                value={item}
                                                            >
                                                                {item}
                                                            </Checkbox>
                                                        </List.Item>
                                                    )}
                                                    className="list-scroll"
                                                    loadMore
                                                ></List>
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </Col>
                                </Form>
                            </Panel>
                        </Collapse>
                        <>
                            <Form onFinish={handleFinish} form={form}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        background: '#fff',
                                        padding: '15px',
                                        marginBottom: 10,
                                    }}
                                >
                                    <div>
                                        <div
                                            style={{
                                                fontSize: 15,
                                                fontWeight: 500,
                                            }}
                                        >
                                            {translator.t(
                                                'action.borrowWithoutEntry'
                                            )}
                                        </div>
                                        <div>
                                            {translator.t(
                                                'action.borrowWithoutEntryDesc'
                                            )}
                                        </div>
                                    </div>
                                    <div>
                                        <Form.Item
                                            name="entry"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                defaultChecked={
                                                    switchActive?.entry || false
                                                }
                                                onChange={(e) => {
                                                    switchAction.setSwitchActive(
                                                        e
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>

                                <Row>
                                    <Col
                                        span={12}
                                        style={{ textAlign: 'start' }}
                                    >
                                        {translator.t('filter.result')}:{' '}
                                        {totalData === 0 ||
                                        (!hasFilter && fromHome)
                                            ? '0'
                                            : `${totalData} ${translator.t(
                                                  'action.resultFound'
                                              )}`}
                                    </Col>
                                    <Col
                                        span={12}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <span>
                                            <Button onClick={reseltFilter}>
                                                Reset Filter
                                            </Button>
                                        </span>
                                        <span style={{ marginLeft: 5 }}>
                                            <Form.Item>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Ok
                                                </Button>
                                            </Form.Item>
                                        </span>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    </>
                </Modal>
            ) : (
                <Modal
                    title="Filter"
                    style={{ top: 50 }}
                    width={!isMobile() && '90%'}
                    visible={visibleModal}
                    onCancel={() => setVisibleModal(false)}
                    className="modal-filter"
                    footer={null}
                >
                    <Row>
                        <Col lg={8} md={8} sm={8} xs={11}>
                            <Input
                                placeholder={translator.t('action.searchGenre')}
                                onPressEnter={(e) =>
                                    handleSearch('category', e)
                                }
                                suffix={<SearchOutlined />}
                                allowClear
                                onChange={(e) => onClearSearch('category', e)}
                            />
                        </Col>
                        <Col lg={8} md={8} sm={8} xs={13}>
                            <Input
                                placeholder={translator.t(
                                    'action.searchAuthor'
                                )}
                                onPressEnter={(e) => handleSearch('author', e)}
                                suffix={<SearchOutlined />}
                                allowClear
                                onChange={(e) => onClearSearch('author', e)}
                            />
                        </Col>
                        <Col lg={8} md={8} sm={8} xs={11}>
                            <Input
                                placeholder={translator.t('action.searchYear')}
                                onPressEnter={(e) => handleSearch('year', e)}
                                suffix={<SearchOutlined />}
                                allowClear
                                onChange={(e) => onClearSearch('year', e)}
                            />
                        </Col>
                    </Row>
                    <Form onFinish={handleFinish} form={form}>
                        <Row>
                            <Col lg={8} md={8} sm={8} xs={13}>
                                <Form.Item name="category_id">
                                    <Checkbox.Group className="checkbox-group">
                                        <List
                                            bordered
                                            dataSource={dataCategories?.list}
                                            renderItem={(item, key) => (
                                                <List.Item key={key}>
                                                    <Checkbox value={item?.id}>
                                                        {item?.category_name}
                                                    </Checkbox>
                                                </List.Item>
                                            )}
                                            footer={
                                                categoryPagination?.loadMore &&
                                                !isEmpty(
                                                    dataCategories?.list
                                                ) &&
                                                dataCategories?.total >
                                                    dataCategories?.list
                                                        ?.length ? (
                                                    <div
                                                        style={{
                                                            display:
                                                                !hasSearch &&
                                                                'none',
                                                        }}
                                                    >
                                                        <Button
                                                            type="primary"
                                                            onClick={
                                                                loadMoreCategory
                                                            }
                                                            loading={loading}
                                                        >
                                                            Load More
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    ''
                                                )
                                            }
                                            className="list-scroll"
                                            loadMore
                                        ></List>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            <Col lg={8} md={8} sm={8} xs={13}>
                                <Form.Item name="author_name">
                                    <Checkbox.Group className="checkbox-group">
                                        <List
                                            bordered
                                            dataSource={dataOptionAuthor}
                                            renderItem={(item, key) => (
                                                <List.Item key={key}>
                                                    <Checkbox value={item.text}>
                                                        {item.text}
                                                    </Checkbox>
                                                </List.Item>
                                            )}
                                            footer={
                                                hasMoreAuthor &&
                                                !loadingAuthor &&
                                                !isEmpty(dataOptionAuthor) ? (
                                                    <div>
                                                        <Button
                                                            type="primary"
                                                            onClick={
                                                                loadMoreAuthor
                                                            }
                                                        >
                                                            Load More
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    ''
                                                )
                                            }
                                            className="list-scroll"
                                            loadMore
                                        >
                                            {loadingAuthor && (
                                                <div className="demo-loading-container">
                                                    <Spin />
                                                </div>
                                            )}
                                        </List>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>

                            <Col lg={8} md={8} sm={8} xs={11}>
                                <Form.Item name="catalog_publish_date">
                                    <Checkbox.Group className="checkbox-group">
                                        <List
                                            bordered
                                            dataSource={dataOptionYear}
                                            renderItem={(item, key) => (
                                                <List.Item key={key}>
                                                    <Checkbox value={item}>
                                                        {item}
                                                    </Checkbox>
                                                </List.Item>
                                            )}
                                            className="list-scroll"
                                            loadMore
                                        ></List>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <div className="entry">
                                    <Form.Item
                                        name="entry"
                                        label={
                                            <span className="title">
                                                {translator.t(
                                                    'action.borrowWithoutEntry'
                                                )}
                                            </span>
                                        }
                                        valuePropName="checked"
                                    >
                                        <Switch
                                            defaultChecked={
                                                switchActive?.entry || false
                                            }
                                            onChange={(e) => {
                                                switchAction.setSwitchActive(e)
                                            }}
                                        />
                                    </Form.Item>
                                    <span className="subtitle">
                                        {translator.t(
                                            'action.borrowWithoutEntryDesc'
                                        )}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} style={{ textAlign: 'start' }}>
                                {translator.t('filter.result')}:{' '}
                                {totalData === 0 || (!hasFilter && fromHome)
                                    ? '0'
                                    : `${totalData} ${translator.t(
                                          'action.resultFound'
                                      )}`}
                            </Col>
                            <Col span={12} style={{ textAlign: 'end' }}>
                                <Space>
                                    <Button onClick={reseltFilter}>
                                        Reset Filter
                                    </Button>
                                    <Form.Item>
                                        <Button htmlType="submit">Ok</Button>
                                    </Form.Item>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )}
        </>
    )
}

export default connect(mapStateToProps)(ModalFIlterSorting)
