import request from 'helpers/request'
import includes from 'lodash/includes'
import { toList, toSingle } from './transformer'
import { ORGANIZATION_ID } from 'helpers/constants'

export default {
    list: async (url, type = '') => {
        const resp = await request.get(url, type)
        const { meta = {}, data = [], pagination = {}, included = [] } = resp
        const mapped = toList(data, included)
        return type === 'menu' || type === 'feed'
            ? resp
            : {
                  meta,
                  pagination,
                  data: mapped,
              }
    },
    getWithoutToken: async (url) => {
        const resp = await request.getnotoken(url, 'notoken')
        return resp
    },

    single: async (url, type = '') => {
        //api from tables
        const resp = await request.get(url, type)
        const { meta = {}, data = {}, included = [] } = resp
        const mapped = toSingle(data, included)

        return type === 'feed'
            ? resp
            : {
                  meta,
                  data: mapped,
              }
    },
    singleWebHook: async (url) => {
        //api from webhook
        const resp = await request.get(url)
        const { meta = {}, data = {} } = resp
        return resp.data !== undefined
            ? {
                  meta,
                  data: data,
              }
            : resp
    },
    update: async (url, data, type = '') => {
        const dataParam =
            includes(url, 'api/') ||
            includes(url, 'webhook/') ||
            type === 'feed' ||
            includes(url, 'auth/')
                ? { ...data }
                : { data }
        return await request.patch(
            `${url}`,
            type === 'socmed' ? data : dataParam,
            type
        )
    },

    insert: async (url, data, type = '') => {
        //spreaded data, ...data, for /api endpoint
        const dataParam =
            includes(url, 'api/') ||
            includes(url, 'webhook/') ||
            type === 'feed' ||
            includes(url, 'auth/')
                ? { ...data }
                : { data }
        return await request.post(`${url}`, dataParam, type)
    },

    borrow: async (url, data) => {
        const dataParam =
            includes(url, 'api/') || includes(url, 'webhook/')
                ? { ...data }
                : { data }
        return await request.borrow(url, dataParam)
    },

    register: async (url, params) => {
        const data = {
            provider: 'local',
            data: {
                email: params.email,
                password: params.password,
                extras: {
                    name: params.name,
                    address: params.address,
                    phone: params.phone,
                    regency: params.regency,
                    organization_id: ORGANIZATION_ID,
                },
            },
        }

        const result = await request.post(`${url}`, data)
        return result
    },
    upload: async (url, data, type, extraParams) =>
        await request.put(url, data, 'upload', type, extraParams),
    put: async (url, data) => await request.put(url, data, '', ''),
    delete: async (url) => await request.delete(url),
    deleteData: async (url, data, type = '') =>
        await request.deleteData(url, data, type),
    external: async (url) => await request.get(url, 'external'),
    getStorage: async ({ url, headers = {}, body = {} }) =>
        await request.getStorage({
            url: url,
            headers: headers,
            body: body,
            source: 'customeheader',
        }),
    uploadStorage: async ({ url, body = {}, extraParams = {} }) =>
        await request.postStorage({
            url: url,
            body: body,
            source: 'storageclient',
            type: 'multipart/form-data',
            extraParams: extraParams,
        }),
    deleteStorage: async ({ url, body = {} }) =>
        await request.deleteStorage({
            url: url,
            body: body,
            source: 'storageclient',
        }),
}
