import React, { useLayoutEffect, useState } from 'react'
import { Col, Row } from 'antd'
import AudioCardComponent from './AudioCardComponent'
import map from 'lodash/map'
import { NavigationHome } from 'components'
import Carousel from 'nuka-carousel'
import Slider from 'react-slick'

const AudioSliderSection = (props) => {
    const { data, detailUrl, title } = props

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isSmallMobile = () => state.width <= 360
    const isMobile = () => state.width <= 420
    const isTablet = () => state.width <= 768
    const isDesktop = () => state.width <= 1024
    const S10inch = () => state.width <= 1270
    const xlDesktop = () => state.width <= 1440
    const xlDesktopp = () => state.width <= 1600
    const xxlDesktop = () => state.width <= 1920
    const desktop4K = () => state.width <= 2560
    const UHD4k = () => state.width >= 2561

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const settings = {
        infinite: false,
        speed: 400,
        initialSlide: 0,
        dots: false,
        arrows: false,
        slidesToShow: isSmallMobile() ? 1.6 : 1.7,
        adaptiveHeight: false,
        swipeToSlide: true,
    }

    return (
        <div className="section-container">
            <NavigationHome title={title} detailUrl={detailUrl} />
            <Row style={{ marginTop: '1rem' }}>
                <Col span={24} className="section-slider">
                    {isMobile() ? (
                        <Col span={24} className="section-slider">
                            <Slider
                                className={
                                    data.length === 1
                                        ? 'react-slick single-react-slick'
                                        : 'react-slick'
                                }
                                {...settings}
                            >
                                {map(data, (item, id) => {
                                    return (
                                        <AudioCardComponent
                                            key={id}
                                            data={item}
                                        />
                                    )
                                })}
                            </Slider>
                        </Col>
                    ) : (
                        <Carousel
                            speed={50}
                            withoutControls={true}
                            disableEdgeSwiping={false}
                            slidesToScroll="auto"
                            slideWidth={
                                isMobile()
                                    ? 0.55
                                    : isTablet()
                                    ? 0.34
                                    : isDesktop()
                                    ? 0.25
                                    : S10inch()
                                    ? 0.25
                                    : xlDesktop()
                                    ? 0.18
                                    : xlDesktopp()
                                    ? 0.16
                                    : xxlDesktop()
                                    ? 0.13
                                    : desktop4K()
                                    ? 0.1
                                    : UHD4k()
                                    ? 0.1
                                    : 0.25
                            }
                            cellAlign="left"
                        >
                            {map(data, (item, id) => {
                                return (
                                    <AudioCardComponent key={id} data={item} />
                                )
                            })}
                        </Carousel>
                    )}
                </Col>
            </Row>
            <br />
        </div>
    )
}

export default AudioSliderSection
