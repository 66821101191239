import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Tooltip } from 'antd'
import translator from 'i18next'
import Image from 'assets/icons/quiz_package_drm.png'
import provider from 'providers/provider'
import url from 'constants/url'
import { success } from 'utils/notification'
import notificationHandler from 'utils/notificationHandler'
import map from 'lodash/map'
import {
    ArrowDownOutlined,
    DeleteOutlined,
    LoadingOutlined,
} from '@ant-design/icons'
import isEmpty from 'lodash/isEmpty'
import { useHistory } from 'react-router-dom'
import sumBy from 'lodash/sumBy'
import ModalPaymentMethodList from 'views/donation/components/ModalPaymentMethodList'

const DetailBoxOrder = (props) => {
    const router = useHistory()
    const orderBoxId = props.location.state.orderBoxId
    const quizId = props.location.state.quizId
    const [state, setState] = useState({
        loading: true,
    })
    const [dataOrder, setDataOrder] = useState([])
    const [dataPaymentMethod, setDataPaymentMethod] = useState({
        data: [],
        loading: false,
    })
    const [totalData, setTotalData] = useState(0)
    const [limit, setLimit] = useState(10)
    const [visiblePaymentMethod, setVisiblePaymentMethod] = useState(false)
    const [fromTryout, setFromTryout] = useState(false)

    const getOrderBoxDetail = async () => {
        setState({ ...state, loading: true })
        await provider
            .singleWebHook(
                `${url.webhookURL_order_box_detail}?order_box_id=${orderBoxId}&limit=${limit}&category=TRYOUT`
            )
            .then((res) => {
                const { data, meta } = res
                setDataOrder(data)
                setTotalData(meta)
                setState({
                    ...state,
                    loading: false,
                })
                if (isEmpty(data)) {
                    router.goBack(`/quiz/detail/${quizId}`)
                }
            })
            .catch((err) => {
                notificationHandler('dev2', err)
                setState({ ...state, initLoading: false, loading: false })
                return
            })
    }

    const getPaymentMethodList = async () => {
        setDataPaymentMethod({
            ...dataPaymentMethod,
            loading: true,
        })
        await provider
            .singleWebHook(`${url.webhookURL_payment_method_list}`)
            .then((res) => {
                const { data } = res
                setDataPaymentMethod({
                    data,
                    loading: false,
                })
            })
            .catch((err) => {
                setDataPaymentMethod({
                    ...dataPaymentMethod,
                    loading: false,
                })
                notificationHandler('dev2', err)
            })
    }

    useEffect(() => {
        getPaymentMethodList()
    }, [])

    const handleDeleteOrder = async (idOrder) => {
        try {
            await provider.deleteData(url.webhookURL_order_box_detail, {
                order_box_detail_id: idOrder,
            })
            getOrderBoxDetail()
            success({
                description: translator.t('payment.messageReturn'),
                placement: 'bottomRight',
            })
        } catch (err) {
            notificationHandler('graphql', err)
        }
    }

    useEffect(() => {
        getOrderBoxDetail()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit])

    const DispalyButton = () => {
        if (totalData > limit) {
            return (
                <Button
                    className="animate fadeInBottom"
                    icon={<ArrowDownOutlined />}
                    loading={state.loading}
                    onClick={() => {
                        totalData > limit && setLimit((e) => e + 10)
                    }}
                >
                    {translator.t('action.fully')}
                </Button>
            )
        } else {
            return null
        }
    }

    const formatRupiah = (money) => {
        return new Intl.NumberFormat({
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
        }).format(money)
    }

    const handleCountPay = (dataOrder) =>
        formatRupiah(sumBy(dataOrder, (e) => e.price - e.discount_amount))

    return (
        <Row
            justify="center"
            style={{
                marginBottom: '5vh',
            }}
        >
            <Col sm={24} xs={24} md={20} xl={14}>
                {state.loading ? (
                    <div className="order-loading">
                        <LoadingOutlined />
                    </div>
                ) : (
                    <>
                        <div className="boxorder">
                            <div className="boxorder-title">
                                {translator.t('payment.boxordertitle')}
                            </div>
                            <div className="boxorder-price">
                                {translator.t('payment.idr')}
                                {handleCountPay(dataOrder)}
                            </div>
                        </div>
                        <p className="boxorder-notice">
                            {translator.t('payment.notice')}
                        </p>
                        {map(dataOrder, (item, key) => {
                            const priceDiscount =
                                item.price - item?.discount_amount
                            const idOrder = item?.id

                            return (
                                <Card className="boxorder-item" key={key}>
                                    <Tooltip
                                        title={translator.t(
                                            'payment.deleteOrder'
                                        )}
                                    >
                                        <DeleteOutlined
                                            onClick={() =>
                                                handleDeleteOrder(idOrder)
                                            }
                                        />
                                    </Tooltip>
                                    <div className="boxorder-content">
                                        <img
                                            src={
                                                item?.order_object
                                                    ?.image_url === null
                                                    ? Image
                                                    : item?.order_object
                                                          ?.image_url
                                            }
                                            alt="Mari bergabung dan berkolaborasi mengembangkan pendidikan Indonesia bersama anak bangsa."
                                            className="images"
                                        />
                                        <div className="boxorder-item-title">
                                            <div className="boxorder-item-title-title">
                                                {item?.order_object?.title}
                                            </div>
                                            <div className="boxorder-item-title-price">
                                                {translator.t('payment.idr')}
                                                {formatRupiah(priceDiscount)}
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            )
                        })}
                        <div className="continue-payment">
                            <Button
                                onClick={() => {
                                    setVisiblePaymentMethod(true)
                                    setFromTryout(true)
                                }}
                            >
                                {translator.t('payment.continuePayment')}
                            </Button>
                        </div>
                        <div className="loadmore-btn">
                            <DispalyButton />
                        </div>
                    </>
                )}
            </Col>

            <ModalPaymentMethodList
                visible={visiblePaymentMethod}
                setVisible={setVisiblePaymentMethod}
                dataPaymentMethod={dataPaymentMethod}
                orderBoxId={orderBoxId}
                dataOrder={dataOrder}
                fromTryout={fromTryout}
            />
        </Row>
    )
}

export default DetailBoxOrder
