import React from 'react'
import translator from 'i18next'
import reduxState from '../../../../redux'
import get from 'lodash/get'
import { useHistory } from 'react-router-dom'
import { VideoCardCategory } from 'views/home/video/components'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Lazy } from 'swiper/core'
import { Col, Row } from 'antd'

SwiperCore.use([Pagination, Lazy])

const BorrowVideo = (props) => {
    const { getMedia, videoData } = props
    const history = useHistory()
    const { profile = {} } = reduxState.getState()
    const id = get(profile, 'id', '')

    return (
        <React.Fragment>
            <div
                className="v-container vb-container"
                style={{ marginBottom: 20 }}
            >
                <Row className="nh-container">
                    <Col>
                        <h1 className="title">
                            {translator.t('bookshelf.borrowVideo')}
                        </h1>
                    </Col>

                    <Col>
                        {/* {videoData?.length > 2 && ( */}
                        <button
                            className="link"
                            onClick={() =>
                                history.push({
                                    pathname: '/more-history-video',
                                    state: { id },
                                })
                            }
                        >
                            {translator.t('action.fully')}
                        </button>
                        {/* )} */}
                    </Col>
                </Row>

                <div className="swiper_container">
                    <Swiper
                        slidesPerView={1.2}
                        spaceBetween={10}
                        preloadImages={false}
                        lazy={true}
                        pagination={{
                            el: '.swiper-pagination',
                            clickable: true,
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            360: {
                                slidesPerView: 1,
                            },
                            411: {
                                slidesPerView: 1,
                            },
                            420: {
                                slidesPerView: 1,
                            },
                            540: {
                                slidesPerView: 1.1,
                            },
                            640: {
                                slidesPerView: 1.5,
                            },
                            768: {
                                slidesPerView: 1.8,
                            },
                            884: {
                                slidesPerView: 1.9,
                            },
                            1024: {
                                slidesPerView: 1.9,
                            },
                            1087: {
                                slidesPerView: 2.15,
                            },
                        }}
                        className="swiper_container"
                    >
                        {videoData.map((item, key) => (
                            <SwiperSlide key={key}>
                                <VideoCardCategory
                                    key={id}
                                    data={item}
                                    source="video"
                                    frombookself="bookself"
                                    getMedia={getMedia}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BorrowVideo
