import store from '../store'
import * as ActionType from './contant'

export default {
    setDataProfile: (value) => {
        const action = { type: ActionType.SET_PROFILE, value }
        store.dispatch(action)
    },
    updateDataProfile: (value) => {
        const action = { type: ActionType.UPDATE_PROFILE, value }
        store.dispatch(action)
    },
    clearDataProfile: () => {
        const action = { type: ActionType.CLEAR_PROFILE }
        store.dispatch(action)
    },
}
