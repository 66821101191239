import store from '../store'
import * as ActionType from './contant'

export default {
    setMenuApps: (value) => {
        const action = { type: ActionType.SET_MENU_APPS, value }
        store.dispatch(action)
    },
    clearMenuApps: () => {
        const action = { type: ActionType.CLEAR_MENU_APPS }
        store.dispatch(action)
    },
}
