import React, { useState, useEffect } from 'react'
import { Button, Input, Form, Select } from 'antd'
import translator from 'i18next'
import TextArea from 'antd/lib/input/TextArea'
import provider from 'providers/provider'
import { error, success } from 'utils/notification'
import ModalPassword from './components/ModalPassword'
import { InputMultipleSearchSelect } from 'components'
import toLower from 'lodash/toLower'
import url from 'constants/url'
import { uniqBy } from 'lodash'
// import getDistrictById from './queries/getDistrictById'
// import { useLazyQuery } from '@apollo/client/react/hooks'
// import { isEmpty } from 'lodash'

const { Option } = Select

const ChangeProfile = (props) => {
    const { state, refreshUser } = props

    const [form] = Form.useForm()
    const [popPassword, setPopPassword] = useState(false)
    const [district, setDistrict] = useState([])
    const [limitDistrict, setLimitDisctrict] = useState(25)
    const [searchDistrict, setSearchDistrict] = useState('')
    const [gender, setGender] = useState('')
    const [loading, setLoading] = useState(false)
    const genderList = [
        {
            code: 'M',
            gender: 'male',
        },
        {
            code: 'F',
            gender: 'female',
        },
        {
            code: 'L',
            gender: 'male',
        },
        {
            code: 'P',
            gender: 'female',
        },
        {
            code: 'laki-laki',
            gender: 'male',
        },
        {
            code: 'Laki-laki',
            gender: 'male',
        },
        {
            code: 'perempuan',
            gender: 'female',
        },
        {
            code: 'Perempuan',
            gender: 'female',
        },
        {
            code: 'male',
            gender: 'male',
        },
        {
            code: 'female',
            gender: 'female',
        },
        {
            code: 'null',
            gender: '',
        },
        {
            code: '',
            gender: '',
        },
        {
            code: null,
            gender: '',
        },
        {
            code: 'M',
            gender: 'male',
        },
    ]

    const updateData = async (data) => {
        await provider
            .update(url.authURL_auth_account, { ...data })
            .then(() => {
                setLoading(false)
                refreshUser()
                success({
                    description: 'Berhasil menyimpan data',
                    placement: 'bottomRight',
                })
            })
            .catch((err) => {
                error({
                    description: err.message,
                })
            })
    }

    const getDistricts = async () => {
        setLoading(true)
        const filter = searchDistrict
            ? `&filter={"d.name":{"ilike":"%${searchDistrict}%"}}`
            : ``

        await provider
            .getWithoutToken(
                `${encodeURI(
                    `${url.webhookURL_district_list}?limit=${limitDistrict}&offset=0${filter}`
                )}`
            )
            .then((res) => {
                const temp = []
                if (
                    !searchDistrict &&
                    state?.district_id &&
                    state?.district_name
                )
                    temp.push({
                        value: state?.district_id,
                        text: state?.district_name,
                    })
                res?.data?.forEach((district) => {
                    temp.push({
                        value: district.id,
                        text: district.name,
                        regency: district?.regency_name,
                        province: district?.province_name,
                    })
                })
                setDistrict(uniqBy(temp, 'value'))
                setLoading(false)
            })
            .catch((err) => {
                error({
                    description: err.message,
                })
                setLoading(false)
            })
    }

    const handleSubmit = async (values) => {
        const data = {
            ...values,
        }

        setLoading(true)
        updateData({
            scope: 'profile',
            data: data,
        }).catch((err) => {
            error({
                description: err.message,
                placement: 'bottomRight',
            })
            setLoading(false)
        })
    }

    const onChangeGender = (value) => {
        setGender(value)
    }

    useEffect(() => {
        form.setFieldsValue({
            name: state?.name,
            gender:
                genderList.find((e) => e.code === state?.gender).gender || '',
            phone: state?.phone,
            address: state?.address,
            district_id: state?.district_id,
            bio: toLower(state?.bio) === 'null' ? '-' : state?.bio || '-',
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    useEffect(() => {
        getDistricts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limitDistrict, searchDistrict])

    const schema = {
        district_id: {
            label: '',
            rules: [
                {
                    required: true,
                    message: 'Form tidak boleh kosong!',
                },
            ],
        },
    }

    const renderForm = () => (
        <Form form={form} onFinish={handleSubmit} name="profleForm">
            <div className="setting__containerMiddle">
                <div className="setting__containerMiddleTitle">
                    {translator.t('action.accountData')}
                </div>

                {translator.t('action.fullName')}
                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Form Nama tidak boleh kosong!',
                        },
                    ]}
                >
                    <Input name="name" className="ant-input__input" />
                </Form.Item>

                {translator.t('profile.gender')}
                <Form.Item name="gender">
                    <Select
                        name="gender"
                        onChange={onChangeGender}
                        className="ant-select-selector"
                        gender={gender}
                    >
                        <Option value="female">
                            {translator.t('profile.female')}
                        </Option>
                        <Option value="male">
                            {translator.t('profile.male')}
                        </Option>
                    </Select>
                </Form.Item>

                {translator.t('action.telephon')}
                <Form.Item
                    name="phone"
                    rules={[
                        {
                            required: false,
                            message: 'Form Telepon tidak boleh kosong!',
                        },
                        {
                            pattern: /^-?[-,+,0-9]*(\.[0-9]*)?$/,
                            message: 'Nomor telepon hanya boleh angka',
                        },
                        {
                            max: 13,
                            message: 'Nomor telepon hanya boleh 13 karakter',
                        },
                    ]}
                >
                    <Input name="phone" className="ant-input__input" />
                </Form.Item>

                {translator.t('action.fullKtp')}
                <Form.Item name="address">
                    <Input name="address" className="ant-input__input" />
                </Form.Item>

                {translator.t('filter.district')}

                <InputMultipleSearchSelect
                    schema={schema}
                    data={district}
                    loading={loading}
                    name="district_id"
                    placeholder="Kecamatan"
                    limit={limitDistrict}
                    setLimit={setLimitDisctrict}
                    setSearch={setSearchDistrict}
                />

                {translator.t('action.bio')}
                <Form.Item name="bio">
                    <TextArea name="bio" className="ant-input__input" />
                </Form.Item>
                <Form.Item>
                    <div className="setting__containerMiddleButton">
                        <Button
                            className="setting__button"
                            loading={loading}
                            htmlType="submit"
                            type="primary"
                        >
                            {translator.t('action.save')}
                        </Button>
                    </div>
                </Form.Item>
            </div>
        </Form>
    )

    return (
        <>
            {renderForm()}
            <div className="setting__containerBottom">
                <div className="setting__containerBottomTitle">
                    {translator.t('action.accountSetting')}
                </div>
                <div>
                    {translator.t('auth.password')}
                    <Input
                        style={{ borderRadius: '5px' }}
                        readOnly={true}
                        placeholder={translator.t('auth.password')}
                        value="***************"
                    />
                    <div
                        className="setting__containerBottomSubtitle"
                        onClick={() => {
                            setPopPassword(true)
                        }}
                    >
                        {translator.t('action.editPassword')}{' '}
                    </div>
                </div>
            </div>

            <ModalPassword
                visibleModal={popPassword}
                setVisibleModal={setPopPassword}
            />
        </>
    )
}

export default ChangeProfile
