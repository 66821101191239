import React, { useState, useEffect, useLayoutEffect } from 'react'
import {
    Modal,
    Button,
    Input,
    Row,
    Col,
    Form,
    Checkbox,
    List,
    Spin,
} from 'antd'
import isEmpty from 'lodash/isEmpty'
import translator from 'i18next'
import getPagination from 'constants/getPagination'
import provider from 'providers/provider'
import url from 'constants/url'
import Avatar from 'antd/lib/avatar/avatar'
import { SearchOutlined } from '@ant-design/icons'
import get from 'lodash/get'
import action from 'redux/timeLine/action'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { success, error } from 'utils/notification'
import getAcronym from 'components/Acronym'
import { BASE_TITLE } from 'constants/constant'

const ModalFilterEpustaka = (props) => {
    const {
        setVisibleShow,
        stateTimeLine,
        getMemberlist,
        visibleShow,
        epustaka_id,
        setTimeLine,
        form,
    } = props

    const limit = 20
    const [currentPage, setCurrentPage] = useState(1)
    const [formSearch] = Form.useForm()
    const [loadingFollower, setLoadingFollower] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const [laodingSubmit, setLoadingSubmit] = useState(false)
    const [search, setSearch] = useState('')
    const [totalChecked, setTotalChecked] = useState(0)

    const [dataOption, setDataOption] = useState([])
    const [dataChecked, setDataChecked] = useState([])

    const [state, setState] = useState({
        width: window.innerWidth,
    })

    const isMobile = () => state.width <= 460

    const updateDimensions = () => {
        setState({ width: window.innerWidth })
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', updateDimensions)
        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [])

    const getFollowerOption = async () => {
        setLoadingFollower(true)
        const pagination = getPagination(currentPage, limit, 'webhook')
        await provider
            .list(
                encodeURI(
                    `${url.webhookURL_epustaka_nonmember_list}${
                        pagination.result
                    }&q=${
                        search !== '' ? search : ''
                    }&epustaka_id=${epustaka_id}`
                )
            )
            .then((res) => {
                const { data: epustakaResData, meta: epustakaResMeta } = res

                if (
                    pagination.offset >= epustakaResMeta.total ||
                    epustakaResMeta.total <= limit
                ) {
                    setHasMore(false)
                    setLoadingFollower(false)
                }

                setTimeLine({ refresh: false })
                setDataOption(epustakaResData)
                setLoadingFollower(false)
            })
    }

    const onChangeChecklist = (checked) => {
        setTotalChecked(checked.length)
        setDataChecked(checked)
    }

    const handleSubmit = async () => {
        setLoadingSubmit(true)
        const data = {
            epustaka_id: epustaka_id,
            user_ids: dataChecked,
        }
        await provider
            .insert(url.webhookURL_join_epustaka_member, data)
            .then(() => {
                setVisibleShow()
                getMemberlist()
                getFollowerOption()
                setTotalChecked(0)
                setLoadingSubmit(false)
                success({
                    description: `${translator.t(
                        'Anda berhasil menambahkan user menjadi member ePustaka anda'
                    )}`,
                    placement: 'topRight',
                })
            })
            .catch((err) => {
                error({
                    description: err.message,
                    placement: 'bottomRight',
                })
            })
    }

    const loadMore = () => {
        setLoadingFollower(true)

        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        getFollowerOption()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, epustaka_id, search])

    useEffect(() => {
        if (stateTimeLine.refresh) {
            getFollowerOption()
            setTotalChecked(0)
            setDataChecked([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateTimeLine.refresh])

    return (
        <div>
            <Modal
                title={
                    <span style={{ fontWeight: 600 }}>
                        {translator.t('action.add_member')}
                    </span>
                }
                style={{
                    top: 50,
                    overflow: 'hidden',
                    height: isMobile() ? 489 : '',
                }}
                visible={visibleShow}
                onCancel={() => setVisibleShow(false)}
                className="modal-filter"
                footer={[
                    <Button
                        key="back"
                        onClick={() => {
                            setVisibleShow(false)
                        }}
                    >
                        {translator.t('action.undo')}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        disabled={totalChecked === 0}
                        loading={laodingSubmit}
                        onClick={handleSubmit}
                    >
                        {translator.t('action.add_member')}
                    </Button>,
                ]}
            >
                <Row>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <Form
                            form={formSearch}
                            onFinish={(e) => {
                                setSearch(e?.search_member)
                            }}
                        >
                            <Form.Item
                                name="search_member"
                                className="search-form"
                            >
                                <Input
                                    placeholder={translator.t('action.find')}
                                    addonAfter={
                                        <SearchOutlined
                                            onClick={() => {
                                                formSearch.submit()
                                            }}
                                        />
                                    }
                                    size="default"
                                    enterButton
                                    allowClear
                                    onPressEnter={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    onChange={(e) => {
                                        if (e?.target?.value === '') {
                                            setSearch('')
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <Form
                    style={{
                        marginBottom: '-1.5rem',
                    }}
                    form={form}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item>
                                <Checkbox.Group
                                    className="checkbox-group"
                                    onChange={onChangeChecklist}
                                    value={[...dataChecked]}
                                    name="checkbox"
                                >
                                    <List
                                        style={{
                                            borderRadius: 10,
                                        }}
                                        bordered
                                        dataSource={dataOption}
                                        renderItem={(item, key) => {
                                            const { name, id } = item
                                            const { color, acronym } =
                                                getAcronym(name || '', id)
                                            return (
                                                <List.Item key={key}>
                                                    <Checkbox value={item.id}>
                                                        <span
                                                            style={{
                                                                marginLeft: 10,
                                                            }}
                                                        >
                                                            <Avatar
                                                                style={{
                                                                    backgroundImage:
                                                                        color,
                                                                }}
                                                                size="large"
                                                                alt={`${BASE_TITLE} image preview - Platform Perpustakaan Indonesia`}
                                                                src={
                                                                    item.avatar_url
                                                                }
                                                            >
                                                                {acronym}
                                                            </Avatar>
                                                            <span
                                                                style={{
                                                                    marginLeft: 10,
                                                                }}
                                                            >
                                                                {item.name}
                                                            </span>
                                                        </span>
                                                    </Checkbox>
                                                </List.Item>
                                            )
                                        }}
                                        footer={
                                            hasMore &&
                                            !loadingFollower &&
                                            !isEmpty(dataOption) ? (
                                                <div>
                                                    <Button
                                                        type="primary"
                                                        onClick={loadMore}
                                                    >
                                                        {translator.t('load')}
                                                    </Button>
                                                </div>
                                            ) : (
                                                ''
                                            )
                                        }
                                        className="list-scroll"
                                        loadMore
                                    >
                                        {loadingFollower && (
                                            <div className="demo-loading-container">
                                                <Spin />
                                            </div>
                                        )}
                                    </List>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => ({
    stateTimeLine: get(state, 'timeLine', {}),
})

const mapDispatchToProps = () => ({
    setTimeLine: action.setTimeLine,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ModalFilterEpustaka))
