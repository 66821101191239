import { BASE_TITLE } from 'constants/constant'
import { logo_color } from '../assets/icons'

const LogoComponent = (props) => {
    const { preview, width = 100, height } = props

    return (
        <img
            preview={preview}
            src={logo_color}
            width={width}
            height={height}
            alt={`${BASE_TITLE} Preview Images`}
        />
    )
}
export default LogoComponent
